import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { configActions } from './index';
import { api, ProviderType } from '@frontegg/rest-api';
import { PaymentProvider } from '../interfaces';
import { errorHandler } from '../../utils';
export function* configSagas() {
  yield takeEvery(configActions.loadPaymentConfiguration, loadPaymentConfiguration);
}
function* loadPaymentConfiguration() {
  yield put(configActions.setLoading(true));
  try {
    const response = yield call(api.subscriptions.getPaymentProviders) || [];
    const stripePaymentProvider = response.find(paymentProvider => paymentProvider.status === '1' && paymentProvider.providerType === ProviderType.Stripe);
    if (stripePaymentProvider) {
      yield loadStripePaymentConfiguration();
    } else {
      yield put(configActions.setError('Payment provider not configured'));
    }
  } catch (e) {
    yield put(configActions.setError(errorHandler(e)));
  }
}
function* loadStripePaymentConfiguration() {
  yield put(configActions.setLoading(true));
  try {
    const response = yield call(api.subscriptions.getStripePaymentProviderConfiguration);
    yield put(configActions.setState({
      loading: false,
      fetching: false,
      config: {
        paymentProvider: PaymentProvider.STRIPE,
        apiKey: response.publishableKey
      }
    }));
  } catch (e) {
    yield put(configActions.setError(errorHandler(e)));
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadPaymentConfigurationMock() {
  yield put(configActions.setLoading(true));
  yield delay(500);
  yield put(configActions.setState({
    loading: false,
    fetching: false,
    config: {
      paymentProvider: PaymentProvider.STRIPE,
      apiKey: ''
    }
  }));
}
export function* configSagasMock() {
  yield takeEvery(configActions.loadPaymentConfiguration, loadPaymentConfigurationMock);
}