
export class TooltipModel {
    name?: any;
    email?: any;
    picture?: any;
    employer?: any;
    provider?: any;
    work?: any;
    mobile?: any;
    style?: any;
    roleType?: any;
    typicalUser?: any;
    message?: any;
    displayRoleType? : any
    newDescription?: any;
    customer? : any;
    constructor() {
        this.name = null;
        this.email = null;
        this.picture = null;
        this.employer = null;
        this.provider = null;
        this.work = null;
        this.mobile = null;
        this.message = null;
        this.style = new TooltipStyleModel();
        this.displayRoleType = null;
        this.newDescription = null;
        this.customer = null
    }

}

export class TooltipStyleModel {
    top?: any;
    left?: any;
    constructor() {
        this.left = null;
        this.top = null;
    }
}