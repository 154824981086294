/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CreateSiteComponent } from '../create-site/create-site.component';
import * as _ from 'lodash';
import { CreatePlanComponent } from '../create-plan/create-plan.component';
import { CreateBuildingComponent } from '../create-building/create-building.component';
import { EventEmitterType, EventService } from '@SiteOwl/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'so-select-site-building-popup',
  templateUrl: './select-site-building-popup.component.html',
  styleUrls: ['./select-site-building-popup.component.scss'],
})
export class SelectSiteBuildingPopupComponent implements OnInit, OnDestroy {
  sites!: any;
  siteId!: any;
  isBuilding!: boolean
  isPlan!: boolean
  isEdit!: boolean;
  editBuildingForm!: FormGroup;
  canGoNext = true;
  isFormSubmitted!: boolean;
  selectedSite!: any;
  title!: string;
  message!: string;
  newFloors: any = [];
  PreviousModalDTO: any = {
    closeAllModal: false,
    newFloors: [],
    buildings: [],
    site: {},
    buildingId: 0
  };
  viewOnlyClass!: string;
  buildingObj: any;
  bsModalRefSite!: BsModalRef;
  subscription!: Subscription;
  ngOnInit(): void {
    if (this.isBuilding) {
      this.creatbuildingForm();
      this.title = 'Select Building Location';
      this.message = `Select the Site that your new Building belongs in. Select Create New Site if you don't see the Site that you're looking for.`
    }
    else if (this.isPlan) {
      this.createForm();
      this.title = 'Select Plan Location';
      this.message = `Select the Site and Building that your new Plan belongs in. Select Create New Site or Create New Building if you don't see the Site or Building you're looking for.`
    }
  }
  constructor(private fb: FormBuilder, private eventService: EventService,
    public bsModalRefBuilding: BsModalRef, private modalService: BsModalService) {
    this.subscription = this.eventService.subscribe(EventEmitterType.ClosePopup, () => {
      this.closeModal();
    })
  }

  createForm() {
    this.viewOnlyClass = ''
    this.editBuildingForm = this.fb.group({
      site: ['', Validators.required],
      building: ['', Validators.required],
    });
    this.editBuildingForm.controls['building'].disable();
    this.viewOnlyClass = 'read-only-form'
  }
  creatbuildingForm() {
    this.editBuildingForm = this.fb.group({
      site: ['', Validators.required],
    });
  }

  createSite() {
    const initialState: ModalOptions = {
      initialState: {
        isFromSelect: true,
      }, ignoreBackdropClick: true, class: 'newplanM'
    };
    this.bsModalRefSite = this.modalService.show(CreateSiteComponent, initialState);
    this.bsModalRefBuilding.hide();
  }

  buildingChange() {
    if (this.editBuildingForm.controls['building'].value !== '') {
      this.canGoNext = false;
    } else {
      this.canGoNext = true;
    }
  }
  siteChanged() {
    this.selectedSite = null;
    if (this.isPlan) {
      if (this.editBuildingForm.controls['site'].value !== '') {
        this.editBuildingForm.controls['building'].enable();
        this.selectedSite = this.sites.find((site: any) => site.id === this.editBuildingForm.controls['site'].value) || [];
      } else {
        this.editBuildingForm.controls['building'].disable();
      }
      this.editBuildingForm.controls['building'].setValue('');
      this.buildingChange();
    } else if (this.isBuilding) {
      this.selectedSite = this.sites.find((site: any) => site.id === this.editBuildingForm.controls['site'].value) || [];
      if (this.editBuildingForm.controls['site'].value !== '') {
        this.canGoNext = false;
      } else {
        this.canGoNext = true;
      }
    }
  }

  closeModal() {
    this.bsModalRefBuilding?.hide();
    this.bsModalRefSite?.hide();
  }
  getMaxOrderNo(sites: any) {
    if (sites.buildings.length === 0) {
      return 1;
    } else {
      const no: any = _.maxBy(sites.buildings, 'orderNumber');
      return (no.orderNumber + 1);
    }
  }
  addBuilding() {
    if (this.editBuildingForm.controls['site'].value !== '') {
      this.startEditingBuilding(true, true)
    }
  }


  startEditingBuilding(isAddBuilding?: any, isTypeBuilding?: any) {
    this.isFormSubmitted = true;
    if (this.editBuildingForm.invalid && !isAddBuilding) {
      return;
    }

    if (this.isPlan && !isTypeBuilding) {
      this.bsModalRefBuilding.setClass('d-none');
      const buildings = [];
      if (this.selectedSite.buildings.find((e: any) => e.id === this.editBuildingForm.controls['building'].value)) {
        buildings.push(this.selectedSite.buildings.find((e: any) => e.id === this.editBuildingForm.controls['building'].value));
      }
      const modalData = {
        siteId: this.editBuildingForm.controls['site'].value,
        buildingId: this.editBuildingForm.controls['building'].value,
        sites: this.selectedSite,
        buildings: buildings,
      }
      const initialState: ModalOptions = {
        initialState: modalData, ignoreBackdropClick: true, class: 'newplanM'
      };
      this.bsModalRefSite = this.modalService.show(CreatePlanComponent, initialState);
      this.bsModalRefSite.content.addPlans.subscribe((item: any) => {
        this.closeModal();
      })
      this.bsModalRefSite.content.closeAllModal.subscribe((item: any) => {
        if (item) {
          this.bsModalRefBuilding.setClass('');
          this.bsModalRefSite.hide();
        } else {
          this.closeModal();
        }
      })
      this.bsModalRefSite.content.backToSite.subscribe(()=>{
        this.bsModalRefBuilding.setClass('');
        this.bsModalRefSite.hide();
      })
    } else if (this.isBuilding || isTypeBuilding) {
      this.bsModalRefBuilding.setClass('d-none');
      const modalData = {
        siteId: this.editBuildingForm.controls['site'].value,
        site: this.selectedSite,
        buildingsOrderNo: this.getMaxOrderNo(this.selectedSite),
        buildingObj: this.buildingObj,
      }

      const initialState: ModalOptions = {
        initialState: modalData, ignoreBackdropClick: true, class: 'newplanM'
      };
      this.bsModalRefSite = this.modalService.show(CreateBuildingComponent, initialState);
      this.bsModalRefSite.content.backToSite.subscribe((r: any) => {
        this.bsModalRefBuilding.setClass('');
        this.bsModalRefSite.hide();
        this.buildingObj = r && r.buildingObj ? r.buildingObj : null;
      })
      this.bsModalRefSite.content.closeAll.subscribe((item: any) => {
        if (item) {
          this.bsModalRefBuilding.setClass('');
          this.bsModalRefSite.hide();
        } else {
          this.closeModal();
        }
      })
    }
  }
  ngOnDestroy() {
    this.newFloors = []
    if (this.subscription && this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
