import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "deviceId"],
  _excluded2 = ["callback", "deviceId"];
import { put, call } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { errorHandler } from '../../../utils';
import { MFAStep } from '../../MfaState/interfaces';
import { actions } from '../../reducer';
import { handleVerifyMFAResponse } from './handleVerifyMFAResponse.saga';

/**
 * Shared logic for MFA SMS pre-verify step
 * @param payload.callback callback function to be called after the verification is done
 * @param payload.deviceId
 * @param payload.mfaToken
 * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
 */
export function* preVerifyMFASMS(_ref, setLoadingAction) {
  let {
      callback,
      deviceId
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref, _excluded);
  yield put(setLoadingAction({
    loading: false,
    error: undefined
  }));
  try {
    const data = yield call(api.auth.preVerifyMFASMSForLogin, deviceId, payload);
    yield put(actions.setMfaState({
      otcToken: data.otcToken,
      step: MFAStep.smsVerifyCode,
      phoneNumber: data.phoneNumber
    }));
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(setLoadingAction({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}

/**
 * Shared logic for MFA SMS verify step
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.otcToken
 * @param payload.code 6 digits code input by the user
 * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
 * @param isStepUp true when it's step up flow
 */
export function* verifyMFASMS(_ref2, setLoadingAction, isStepUp = false) {
  let {
      callback,
      deviceId
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  yield put(setLoadingAction({
    loading: true
  }));
  try {
    const data = yield call(api.auth.verifyMFASMSForLoginV2, deviceId, payload);
    yield handleVerifyMFAResponse(data, isStepUp);
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(setLoadingAction({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}

/**
 * Pre verify step for MFA SMS login
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.mfaToken
 */
export function* preVerifyMFASMSForLogin({
  payload
}) {
  yield preVerifyMFASMS(payload, actions.setLoginState);
}

/**
 * Verify step for MFA SMS login
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.otcToken
 * @param payload.code 6 digits code input by the user
 */
export function* verifyMFASMSForLogin({
  payload
}) {
  yield verifyMFASMS(payload, actions.setLoginState, false);
}