/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'so-textbox',
  templateUrl: './textbox.component.html',
  styleUrls: ['./textbox.component.scss'],
})
export class TextboxComponent {
  @Input() placeHolder = '';
  @Input() min!: number;
  @Input() max!: number;
  @Input() minLength!: number;
  @Input() maxLength!: number;
  @Input() isPassword = false;
  @Input() className: any = {};
  @Input() style: any = {};
  @Input() id = '';
  @Input() disabled = false;
  @Input() inputControlName!: FormControl;
  @Input() directive: any
  @Input() customDirective!: any;
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() input = new EventEmitter();
  @Output() keyEnter = new EventEmitter();


  _focusChanged($event: Event) {
    this.focus.emit($event)
  }
  _blur($event: Event) {
    this.blur.emit($event)
  }
  _onInput($event: Event) {
    this.input.emit($event)
  }
  _keyEnter($event: Event) {
    this.keyEnter.emit($event);
  }

}
