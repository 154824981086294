<div class="modal-header">
    <h1 class="modal-title pull-left">Configure Columns</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body">
    <p>
        Select which columns you want to display on the table and any exported CSVs. Selecting a preset configuration
        will automatically set the columns.
    </p>
    <form [formGroup]="deviceFieldForm" novalidate>
        <div class="row" *ngIf="!(isAMT || isTicket)">
            <div class="pe-2 col" (click)="changeTab(false, false,false,false)">
                <div class="configure-tab card" [class.active]="!isProjectDeviceFlagReview && !isProjectDevicePunchList && !isProjectDevicePhotoReview &&
          !isProjectDeviceCompletedWork">
                    <div class="tab-icon">

                        <em class="far fa-save "></em>
                    </div>
                    <div class="tab-content">
                        <h2>Custom Search</h2>
                        <p>
                            Configure columns to your own custom view, saved for easy access
                        </p>
                    </div>
                </div>
            </div>
            <div class="ps-2 pe-2 col" (click)="changeTab(true, false,false,false)">
                <div class="configure-tab card" [class.active]="isProjectDevicePunchList">
                    <div class="tab-icon">
                        <em class="far fa-clipboard-list-check "></em>
                    </div>
                    <div class="tab-content">
                        <h2>Punch List</h2>
                        <p>
                            Configure columns to be ideal for viewing ongoing Devices & Tasks in a Project
                        </p>
                    </div>
                </div>
            </div>
            <div class="ps-2 pe-2 col" (click)="changeTab(false, false,true,false)">
                <div class="configure-tab card" [class.active]="isProjectDeviceCompletedWork">
                    <div class="tab-icon">
                        <em class="far fa-clipboard-check"></em>
                    </div>
                    <div class="tab-content">
                        <h2>Completed Work</h2>
                        <p>
                            Configure columns to be ideal for viewing installed Devices & completed Tasks in a Project
                        </p>
                    </div>
                </div>
            </div>
            <div class="ps-2 pe-2 col" (click)="changeTab(false, true,false,false)">
                <div class="configure-tab card" [class.active]="isProjectDevicePhotoReview">
                    <div class="tab-icon">
                        <em class="far fa-image "></em>
                    </div>
                    <div class="tab-content">
                        <h2>Photo Review</h2>
                        <p>
                            Configure columns to review images uploaded to Devices
                        </p>
                    </div>
                </div>
            </div>
            <div class="ps-2 col" (click)="changeTab(false, false,false,true)">
                <div class="configure-tab card" [class.active]="isProjectDeviceFlagReview">
                    <div class="tab-icon">
                        <em class="far fa-flag-alt"></em>
                    </div>
                    <div class="tab-content">
                        <h2>Flag Review</h2>
                        <p>
                            Configure columns to review Devices and tasks with flagged issues
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isAMT">
            <div class="col-sm-6" (click)="changeTab(false, false,false,false)">
                <div class="configure-tab card" [class.active]="!isAmtDevicePhotoReview">
                    <div class="tab-icon">
                        <em class="far fa-save "></em>
                    </div>
                    <div class="tab-content">
                        <h2>Custom Search</h2>
                        <p>
                            Configure columns to your own custom view, saved for easy access
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-6" (click)="changeTab(false, true,false,false)" *ngIf="isAMT">
                <div class="configure-tab card" [class.active]="isAmtDevicePhotoReview">
                    <div class="tab-icon">
                        <em class="far fa-image"></em>
                    </div>
                    <div class="tab-content">
                        <h2>Photo Review</h2>
                        <p>
                            Configure columns to review images uploaded to Devices
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-2">
            <ul class="device-info-list list-unstyled" [ngClass]="{ 'ticket-info-list': isTicket }">
                <ng-container *ngFor="let deviceField of deviceFields; let i = index">
                    <li class="checkbox-box" *ngIf="deviceField.key !== 'isFlag' && deviceField.key !== 'coverageDirection' &&
                         deviceField.key !== 'coverageAngle' && deviceField.key !== 'coverageRange' && deviceField.key !== 'cableCoordinates'">
                        <div class="checkbox-label" *ngIf="i < 10">
                            <so-checkbox [id]="deviceField.key" [name]="deviceField.key"
                                [inputControlName]="$any(deviceFieldForm).controls[deviceField.key]"
                                [className]="'lbl-check'" [label]="deviceField.label">
                            </so-checkbox>
                        </div>
                        <div class="checkbox-label" *ngIf="i > 9 && i < 20">
                            <so-checkbox [id]="deviceField.key" [name]="deviceField.key"
                                [inputControlName]="$any(deviceFieldForm).controls[deviceField.key]"
                                [className]="'lbl-check'" [label]="deviceField.label">
                            </so-checkbox>
                        </div>
                        <div class="checkbox-label"
                            *ngIf="i > 19 ">
                            <so-checkbox [id]="deviceField.key" [name]="deviceField.key"
                                [inputControlName]="$any(deviceFieldForm).controls[deviceField.key]"
                                [className]="'lbl-check'" [label]="deviceField.label">
                            </so-checkbox>
                        </div>
                    </li>
                </ng-container>
            </ul>

            <div class="text-end configure-links clearfix">
                <a (click)="selectAllColumns()">All</a>|<a (click)="selectNoneColumns()">None</a>|<a
                    (click)="resetColumns(isAMT ? resetObjForDevices : resetObjForProjectDevices)">Default</a>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer">
    <button type="button" (click)="closeModal()" class="btn btn-secondary">
        Cancel
    </button>
    <button type="submit" [disabled]="btnService.getStatus()" (click)="configureColumns()" class="btn btn-primary m-0">
        Update
    </button>
</div>