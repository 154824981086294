<div class="modal-header">
    <h1 class="modal-title pull-left">Replace Plan</h1>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal($event,'Cancel')">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body replace-plan-body">
    <p> You are currently attempting to replace the Plan <strong>{{floorName}}</strong> with a PDF with multiple
        pages. Please pick the page number of the PDF that your replacement plan is on.</p>
    <div class="form-group">
        <label>PDF Page Number<sup>*</sup></label>
        <select class="form-control form-control-sm" (change)="pageSelected($event)">
            <option *ngFor="let item of pdfPageArray" [ngValue]="item.id">{{item.value}}</option>
        </select>
    </div>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal($event,'Cancel')">Cancel</button>
    <button type="button" class="btn btn-primary me-0" (click)="closeModal($event,'replace')">Replace</button>
</div>