import { urls } from "../constants";
import { extractHeadersFromOptions, Get, Put } from "../fetch";
export async function updateSettings(body, options) {
  return Put(urls.tenants.accountSettings.v1, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getSettings(options) {
  return Get(urls.tenants.accountSettings.v1, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getPublicSettings() {
  return Get(`${urls.tenants.accountSettings.v1}/public`);
}