<svg:g #ticksel>
  <ng-container *ngFor="let tick of tickValues">
    <svg:g class="tick" [attr.transform]="transform(tick.name)">

      <ng-container *ngIf="tickFormat(tick.name) as tickFormatted">
        <!-- <foreignObject width="20" height="20">
          <em class="{{tick.iconName}}"></em>
        </foreignObject> -->
        <title>{{ tick.name }}</title>

       
  
        <!-- Define the text inside the rectangle -->
        <svg:text stroke-width="0.01" [attr.dy]="dy" [attr.x]="x1" [attr.y]="y1" [attr.text-anchor]="textAnchor"
        [style.font-size]="'14px'" [style.opacity]="0"
        [style.font-weight]="(tick.deviceType === null  || tick.deviceType === 'Install' || tick.deviceType === 'Remove' || tick.deviceType === 'Move' || tick.deviceType === 'Document' || tick.deviceType === 'Repair' || tick.deviceType === 'Tasks') ? '500' : '400'"
        [style.fill]="(tick.deviceType === null || tick.deviceType === 'Install'  || tick.deviceType === 'Remove' || tick.deviceType === 'Move' || tick.deviceType === 'Document' || tick.deviceType === 'Repair' || tick.deviceType === 'Tasks')  ? '#003F77 ' : '#262626'">
       ZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZZ
      </svg:text>
        <svg:text stroke-width="0.01" [attr.dy]="dy" [attr.x]="x1" [attr.y]="y1" [attr.text-anchor]="textAnchor"
          [style.font-size]="'14px'"
          [style.font-weight]="(tick.deviceType === null  || tick.deviceType === 'Install' || tick.deviceType === 'Remove' || tick.deviceType === 'Move' || tick.deviceType === 'Document' || tick.deviceType === 'Repair' || tick.deviceType === 'Tasks') ? '500' : '400'"
          [style.fill]="(tick.deviceType === null || tick.deviceType === 'Install'  || tick.deviceType === 'Remove' || tick.deviceType === 'Move' || tick.deviceType === 'Document' || tick.deviceType === 'Repair' || tick.deviceType === 'Tasks')  ? '#003F77 ' : '#262626'">
          <!-- <tspan *ngIf="tick.deviceType !== null" [innerHTML]="translateUniCodeToHexCode(tick.iconUnicode)" [attr.dy]="dy" class="equipment-icon progress-equipment-icon">
        </tspan> -->
          <!-- <tspan [attr.dy]="tick.deviceType !== null ? '-' + dy : ''"> -->

          <ng-container *ngIf="wrapTicks; then tmplMultilineTick; else tmplSinglelineTick"></ng-container>
          <!-- </tspan> -->
          <!-- <tspan>
        </tspan> -->
        </svg:text>

        <ng-template #tmplMultilineTick>
          <ng-container *ngIf="tickChunks(tick.name) as tickLines">
            <ng-container *ngIf="tickLines.length > 1; else tmplSinglelineTick">
              <svg:tspan *ngFor="let tickLine of tickLines; let i = index" x="0" [attr.y]="i * (8 + tickSpacing)">
                {{ tickLine }}
              </svg:tspan>
            </ng-container>
          </ng-container>
        </ng-template>

        <ng-template #tmplSinglelineTick>

          {{ tick.name }}
        </ng-template>
      </ng-container>
    </svg:g>
  </ng-container>
</svg:g>

<svg:path *ngIf="referenceLineLength > 1 && refMax && refMin && showRefLines" class="reference-area"
  [attr.d]="referenceAreaPath" [attr.transform]="gridLineTransform()" />
<svg:g *ngFor="let tick of ticks" [attr.transform]="transform(tick.name)">
  <svg:g *ngIf="showGridLines" [attr.transform]="gridLineTransform()">
    <svg:line *ngIf="orient === Orientation.Left" class="gridline-path gridline-path-horizontal" x1="0"
      [attr.x2]="gridLineWidth" />
    <svg:line *ngIf="orient === Orientation.Right" class="gridline-path gridline-path-horizontal" x1="0"
      [attr.x2]="-gridLineWidth" />
  </svg:g>
</svg:g>

<svg:g *ngFor="let refLine of referenceLines">
  <svg:g *ngIf="showRefLines" [attr.transform]="transform(refLine.value)">
    <svg:line class="refline-path gridline-path-horizontal" x1="0" [attr.x2]="gridLineWidth"
      [attr.transform]="gridLineTransform()" />
    <svg:g *ngIf="showRefLabels">
      <title>{{ tickTrim(tickFormat(refLine.value)) }}</title>
      <svg:text class="refline-label" [attr.dy]="dy" [attr.y]="-6" [attr.x]="gridLineWidth"
        [attr.text-anchor]="textAnchor">
        {{ refLine.name }}
      </svg:text>
    </svg:g>
  </svg:g>
</svg:g>