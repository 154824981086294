<form [formGroup]="siteForm" (ngSubmit)="createSite()" novalidate class="create-site-modal">
    <div class="modal-header">
        <h1 class="modal-title pull-left">Site Information</h1>
        <button (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Site Name <sup>*</sup></label>
                            <input class="form-control" removeWhiteSpace formControlName="name" name="name"
                                maxlength="80" placeholder="Site Name">
                            <so-control-messages [name]="'Name'" [isFormSubmitted]="isFormSubmitted"
                                [control]="$any(siteForm).controls['name']">
                            </so-control-messages>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-3 ">
                        <div class="form-group">
                            <label>Main Address</label>
                            <input class="form-control" formControlName="address" name="address" maxlength="120"
                                placeholder="Main Address">
                            <so-control-messages [name]="'Main Address'" [isFormSubmitted]="isFormSubmitted"
                                [control]="$any(siteForm).controls['address']">
                            </so-control-messages>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-8 clearfix">
                        <div class="form-group">
                            <label>Site Notes</label>
                            <textarea class="form-control" maxlength="4000" formControlName="notes" name="name" placeholder="Site Notes" 
                                rows="5"></textarea>
                            <so-control-messages [name]="'Site Note'" [isFormSubmitted]="isFormSubmitted"
                                [control]="$any(siteForm).controls['notes']">
                            </so-control-messages>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal-footer mrg-t-40 ps-0">
        <div class="row p-0" style="width: 100%">
            <div class="col-sm-6 p-0">
                <div class="progress-container">
                    <ul class="progressbar">
                        <li class="active">Site Information</li>
                        <li>Buildings</li>
                        <li>Plans</li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-end">
                <button type="button" class="btn btn-secondary" (click)="closeModal()"
                    data-dismiss="modal">Cancel</button>
                <button [disabled]="canGoNext" [ngStyle]="{'cursor': canGoNext ? 'not-allowed': 'pointer'}"
                    type="submit" class="btn btn-primary m-0">Save and Continue</button>
            </div>
        </div>
    </div>
</form>