export let SSOStateKeys;
(function (SSOStateKeys) {
  SSOStateKeys["LOAD_SSO_CONFIGURATIONS"] = "LOAD_SSO_CONFIGURATIONS";
  SSOStateKeys["SAVE_SSO_CONFIGURATION"] = "SAVE_SSO_CONFIGURATION";
  SSOStateKeys["UPDATE_SSO_CONFIGURATION"] = "UPDATE_SSO_CONFIGURATION";
  SSOStateKeys["SAVE_SSO_CONFIGURATION_BY_METADATA"] = "SAVE_SSO_CONFIGURATION_BY_METADATA";
  SSOStateKeys["UPDATE_SSO_CONFIGURATION_BY_METADATA"] = "UPDATE_SSO_CONFIGURATION";
  SSOStateKeys["DELETE_SSO_CONFIGURATION"] = "DELETE_SSO_CONFIGURATION";
  SSOStateKeys["SAVE_SSO_DOMAIN"] = "SAVE_SSO_DOMAIN";
  SSOStateKeys["DELETE_SSO_DOMAIN"] = "DELETE_SSO_DOMAIN";
  SSOStateKeys["VALIDATE_SSO_DOMAIN"] = "VALIDATE_SSO_DOMAIN";
  SSOStateKeys["UPDATE_SSO_DEFAULT_ROLES"] = "UPDATE_SSO_DEFAULT_ROLES";
  SSOStateKeys["DELETE_SSO_GROUPS"] = "DELETE_SSO_GROUPS";
  SSOStateKeys["SAVE_SSO_GROUPS"] = "SAVE_SSO_GROUPS";
  SSOStateKeys["GET_SSO_AUTHORIZATION_ROLES"] = "GET_SSO_AUTHORIZATION_ROLES";
})(SSOStateKeys || (SSOStateKeys = {}));
export let SamlVendors;
(function (SamlVendors) {
  SamlVendors["Saml"] = "saml";
  SamlVendors["Okta"] = "okta";
  SamlVendors["Azure"] = "azure";
  SamlVendors["Google"] = "google";
  SamlVendors["Oidc"] = "oidc";
})(SamlVendors || (SamlVendors = {}));