import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { subscriptionsStoreName } from '../../constants';
import { createModuleCaseReducers } from '../utils';
export const vendorPublicConfigInitialState = {
  loading: false,
  fetching: true,
  vendorPublicConfig: null
};
const {
  actions: sliceActions,
  reducer,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/vendorPublicConfig`,
  initialState: vendorPublicConfigInitialState,
  reducers: _extends({}, createModuleCaseReducers())
});
const actions = _extends({
  loadVendorPublicConfiguration: createAction(`${name}/loadVendorPublicConfiguration`)
}, sliceActions);
export { actions as vendorPublicConfigActions, reducer as vendorPublicConfigReducer };