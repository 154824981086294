/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationNotification, AuthService, ButtonEnableDisableService, ChipsOptions, Customer, CustomerService, DefaultValues, Environment, EventEmitterType, EventService, MessageService, Pattern, ProviderRole, UserService, Utilities } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Component({
    selector: 'so-associated-admin',
    templateUrl: './associated-admin.component.html',
    styleUrls: ['./associated-admin.component.scss'],
})
export class AssociatedAdminComponent implements OnInit {

    customerId!: number;
    customerName!: string;
    defaultUsersForList: any;
    usersForList: any = [];
    usersForAutoComplete!: any[];
    defaultUsersAutoComplete!: any[];
    filteredUsers: any;
    saveBtnDisable = false;
    addAnimationIndex = {
        ui: -1,
        li: -1
    }
    selectedData: any = [];
    associatedAdminForm!: FormGroup;
    adminLevels = ['Administrator', 'Coordinator', 'Staff Member'];
    setData: any;
    isInvalidEmail = false;
    chipsOption = ChipsOptions;
    defaultValues = DefaultValues;
    role: any = {
        "id": 5,
        "name": "Manager",
        "description": "Create, edit, and remove Plans in the Plan Library|Create, edit, and remove Live Sites Devices|Create, edit, assign and remove Tickets",
        "roleKey": "amt_Manager"
    };
    projectRole: any = {
        "id": 5,
        "name": "Manager",
        "description": "create, delete, and fully manage projects",
        "roleKey": "project_Manager"
    }
    showSaveAndContinue = false;
    providerId!: number;
    emailsCnt = 0;
    userProviderDetails: any;
    closeAllModalSubscription: any;
    backModalSubscription: any;
    isSaveRemainForAssociateAdmin = false;
    providerName!: string;
    user: any
    cdnUrl = this.env.cdnUrl;
    customers: any;
    backData!: any;
    emailPattern = Pattern.EMAIL;
    public event: EventEmitter<any> = new EventEmitter();
    private backEvent: EventEmitter<any> = new EventEmitter();
    private closeAllModalEvent: EventEmitter<any> = new EventEmitter();
    constructor(
        private messageService: MessageService,
        private fb: FormBuilder,
        public btnService: ButtonEnableDisableService,
        private renderer: Renderer2,
        private authService: AuthService,
        private customerService: CustomerService,
        private readonly env: Environment
    ) { }

    private getUserData() {
        this.user = this.authService.getUserKeyData();
        this.providerName = this.user.user.providerName;
    }

    ngOnInit(): void {
        this.usersForAutoComplete = [];
        this.getUserData();
        this.checkUsersList();
        this.checkLabel();
    }

    checkUsersList() {
        if (this.customerId && this.customerId > 0) {
            this.usersForList = this.defaultUsersForList = this.usersForAutoComplete = this.defaultUsersAutoComplete = [];
            this.createForm();
            this.userProviderDetails = this.authService.getUserKeyData();
            if (this.providerId !== null || this.providerId !== undefined) {
                this.providerId = this.userProviderDetails.user.providerId;
            }
            this.setData = '';
            const sortedUsers: any = [];
            this.adminLevels.forEach(adminRole => {
                const roleValue: any = [];
                this.filteredUsers.forEach((element: any) => {
                    if (element.role.name === adminRole) {
                        const userValue = this.filteredUsers.filter((user: any) => user.id === element.id)[0];
                        if (userValue) {
                            roleValue.push(userValue)
                        }
                    }
                });
                sortedUsers.push({
                    key: this.checkKeyLabel(adminRole),
                    keyMatch: adminRole,
                    value: roleValue
                });
            });
            this.usersForList = _.orderBy(sortedUsers, ['key'], ['asc']);
            this.defaultUsersForList = _.cloneDeep(this.usersForList);
            this.filteredUsers.forEach((x: any) => {
                this.usersForAutoComplete.push({ value: x, display: x.email });
            });
            this.defaultUsersAutoComplete = _.cloneDeep(this.usersForAutoComplete);
            if (this.userProviderDetails.user.providerRole?.name === ProviderRole.Administrator || this.userProviderDetails.user.providerRole?.name === ProviderRole.Coordinator) {
                const tmpUserToAdd = this.userProviderDetails.user.providerRole.name === ProviderRole.Administrator ? sortedUsers[0].value.filter((x: any) => x.user.id === this.userProviderDetails.user.id) : sortedUsers[1].value.filter((x: any) => x.user.id === this.userProviderDetails.user.id);
                this.addUserToEmails(0, 1, tmpUserToAdd[0]);
            }
            this.filteredUsers.forEach((elementUsers: any) => {
                if (elementUsers.defaultCustomerAdmin === 1 && elementUsers.user.id !== this.userProviderDetails.user.id) {
                    this.addUserToEmails(0, 1, elementUsers);
                }
            });

        }
    }

    closeModal(isBack?: any) {
        if (this.associatedAdminForm.dirty) {
            if (window.confirm(ApplicationNotification.pageReload)) {
                this.inviteUserToCustomer(true);
            } else {
                this.associatedAdminForm.reset();
                this.isSaveRemainForAssociateAdmin = false;
                this.closeAllModalEvent.emit();
            }
        } else {
            this.closeAllModalEvent.emit();
        }
    }

    addUserToEmails(lIndex: any, uIndex: any, user: any) {
        if (lIndex > -1 && uIndex > -1) {
            let data = this.associatedAdminForm.get('emails')?.value;
            this.isSaveRemainForAssociateAdmin = true;
            if (user) {
                if (data === null || data === undefined) {
                    data = [];
                }
                data.push(user);

            }
            this.associatedAdminForm.markAsDirty();
            this.emailsCnt = this.emailsCnt + 1;
            this.checkLabel();
            if (!this.associatedAdminForm.get('emails')?.value) {
                this.associatedAdminForm.controls['emails'].setValue(data);
            }
            this.toUpdateAutoCompleteData();
        }
    }

    toUpdateAutoCompleteData() {

        const realData = this.associatedAdminForm.get('emails')?.value || [];

        this.usersForAutoComplete = _.cloneDeep(this.defaultUsersAutoComplete);
        this.usersForList = _.cloneDeep(this.defaultUsersForList);
        realData.forEach((element: any) => {
            if (Utilities.isNull(element.email)) {
                const realDataIndex = this.defaultUsersAutoComplete.findIndex(x => x.value.email === element);
                if (realDataIndex > -1) {
                    // this.usersForAutoComplete.splice(this.defaultUsersAutoComplete.findIndex(x => x.value.email === element), 1);
                    this.usersForAutoComplete.forEach((v, i) => {
                        if (v.value.email === element) {
                            this.usersForAutoComplete.splice(i, 1);
                        }
                    });
                    const levelIndex = this.usersForList.findIndex((x: any) => x.keyMatch === this.defaultUsersAutoComplete[realDataIndex].value.role.name);
                    if (levelIndex > -1) {
                        const listIndex = this.usersForList[levelIndex].value.findIndex((x: any) => x.email === element);
                        this.usersForList[levelIndex].value.splice(listIndex, 1);
                    }

                }

            } else {
                const realDataIndex = this.defaultUsersAutoComplete.findIndex((x: any) => x.value.email === element.email);
                if (realDataIndex > -1) {
                    this.usersForAutoComplete.forEach((v, i) => {
                        if (v.value.email === element.email) {
                            this.usersForAutoComplete.splice(i, 1);
                        }
                    });
                    const levelIndex = this.usersForList.findIndex((x: any) => x.keyMatch === this.defaultUsersAutoComplete[realDataIndex].value.role.name);
                    if (levelIndex > -1) {
                        const listIndex = this.usersForList[levelIndex].value.findIndex((x: any) => x.email === element.email);
                        this.usersForList[levelIndex].value.splice(listIndex, 1);
                    }

                }
            }
        });

    }

    checkKeyLabel(role: any) {
        if (role === 'Staff Member') {
            return 'Staff Members';
        } else if (role === 'Administrator') {
            return 'Administrators';
        } else if (role === 'Coordinator') {
            return 'Coordinators';
        } else {
            return 'No Access';
        }
    }

    onInputBlurred() {
        const data = this.associatedAdminForm.get('emails')?.value;
        this.isInvalidEmail = data.some((x: any) => Utilities.isNull(x.email) ? this.toCheckInvalidInput(x) : this.toCheckInvalidInput(x.email));

        if (!Utilities.isEmpty(this.setData)) {
            const datas = this.associatedAdminForm.get('emails')?.value;
            data.forEach((x: any, k: any) => {
                if (Utilities.isNull(x.email)) {
                    if (x === this.setData) {
                        datas.splice(k, 1);
                    }
                } else {
                    if (x.email === this.setData) {
                        datas.splice(k, 1);
                    }
                }
            });
            this.associatedAdminForm.get('emails')?.setValue(datas);
            this.setData = '';
        }
        this.toUpdateAutoCompleteData();
        this.checkLabel();
        this.isInvalidEmail = data.some((x: any) => Utilities.isNull(x.email) ? this.toCheckInvalidInput(x) : this.toCheckInvalidInput(x.email));
    }

    toCheckInvalidInput(item: any) {
        if (!item || item.match(Pattern.EMAIL)) {
            return false;
        } else {
            return true;
        }
    }
    toAddBorderOnInvaidSpanTag(data: any) {
        if (document.querySelectorAll('tag').length > 0) {
            for (let index = 0; index < document.querySelectorAll('tag').length; index++) {
                const element = document.querySelectorAll('tag')[index];
                if (this.toCheckInvalidInput(data)) {
                    this.renderer.setStyle(element, 'border-color', '#e00b0b');
                } else {
                    this.renderer.setStyle(element, 'border-color', '#003F77');
                }
            }
        }
    }

    checkLabel() {
        if (this.emailsCnt > 0) {
            this.showSaveAndContinue = true;
        } else {
            this.showSaveAndContinue = false;
        }
    }

    inviteUserToCustomer(isClose?: any) {
        if (this.emailsCnt > 0) {
            const emailTobesend: any = [];
            this.associatedAdminForm.value.emails.forEach((element: any) => {
                emailTobesend.push(element.email);
            });
            const providerUserData: any = this.associatedAdminForm.value;
            providerUserData.emails = emailTobesend;
            this.btnService.disable()
            const customerId = providerUserData.customerId
            delete providerUserData.customerId
            this.customerService.addCustomerUserFromManage(providerUserData, customerId).subscribe({
                next: (result: any) => {
                    if (result && result.length > 0) {
                        result.forEach((element: any) => {
                            this.selectedData.push(element);
                        });
                    } else {
                        if (result && (result.length !== 0 || !Array.isArray(result))) {
                            this.selectedData.push(result);
                        }
                    }
                    this.selectedData.forEach((selectedUser: any) => {
                        this.filteredUsers.forEach((element: any, i: any) => {
                            if (selectedUser.user.id === element.user.id) {
                                this.filteredUsers.splice(i, 1);
                                this.checkUsersList();
                                this.checkLabel();
                            }
                        });
                    });
                    this.emailsCnt = 0;
                    this.associatedAdminForm.reset();
                    this.messageService.successMessage('User(s):', 'Invited Successfully');
                    this.isSaveRemainForAssociateAdmin = false;
                    this.btnService.enable()
                    this.checkLabel();

                    if (this.customers.length > 0 && !isClose) {
                        const modalData: any = {
                            customerId: this.customerId,
                            customerName: this.customerName,
                            providerId: this.providerId,
                            providerName: this.providerName,
                            customersList: this.customers
                        };
                        this.event.emit(modalData);
                    } else {
                        this.event.emit(
                            {
                                customerId: this.customerId,
                                customerName: this.customerName,
                                providerId: this.providerId,
                                providerName: this.providerName,
                                customersList: []
                            }
                        );
                    }
                }, error: (error: any) => {
                    this.btnService.enable()
                    if (error.status === 409 || error.status === 422) {
                        this.messageService.errorMessage(`User`, error.error.message);
                    } else {
                        this.closeAllModalEvent.emit();
                        this.messageService.errorMessage(`Error`, 'Error while saving');
                    }
                }
            });
        } else {
            this.associatedAdminForm.reset();
            this.isSaveRemainForAssociateAdmin = false;
            if (this.customers.length > 0 && !isClose) {
                const modalData: any = {
                    customerId: this.customerId,
                    customerName: this.customerName,
                    providerId: this.providerId,
                    providerName: this.providerName,
                    customersList: this.customers
                };
                this.event.emit(modalData);
            }

        }
    }


    private createForm() {
        this.associatedAdminForm = this.fb.group({
            emails: [[]],
            customerId: "",
            role: [],
            projectRole: [],
            isAdmin: true

        });
        this.setFormValue();

    }
    setFormValue() {
        this.associatedAdminForm.controls['role'].setValue(this.role);
        this.associatedAdminForm.controls['projectRole'].setValue(this.projectRole);
        this.associatedAdminForm.controls['isAdmin'].setValue(true);
        this.associatedAdminForm.controls['customerId'].setValue(this.customerId);
    }

    editItem(item: any) {
        const timer = setTimeout(() => {
            this.setData = item;
            clearTimeout(timer);
        }, 100);
    }

    removeTags(elem: any, item: any, i: any) {
        const email = item && item.email ? item.email : item;
        if (this.usersForList.length > 0 && this.filteredUsers.some((x: any) => x.email === email)) {
            elem.removeItem(item, i);
        } else {
            elem.removeItem(item, i);
        }
        this.emailsCnt = this.emailsCnt - 1;
        this.checkLabel();
    }

    ngOnDestroy() {
        this.btnService.enable()
    }
    backClick() {
        this.backData = this.selectedData;
        this.backEvent.emit(this.backData);
    }
}

