import { Delete, Get, Patch, Post } from '../../fetch';
import { urls } from '../../constants';
export async function getGlobalSecurityPolicy() {
  return Get(urls.identity.configurations.v1);
}
export async function getMfaPolicy() {
  return Get(`${urls.identity.configurations.v1}/mfa-policy`);
}
export async function getVendorMfaPolicy() {
  return Get(`${urls.identity.configurations.v1}/mfa-policy/vendor`);
}
export async function saveMfaPolicy(body) {
  if (body.id) {
    return Patch(`${urls.identity.configurations.v1}/mfa-policy`, body);
  } else {
    return Post(`${urls.identity.configurations.v1}/mfa-policy`, body);
  }
}
export async function getLockoutPolicy() {
  return Get(`${urls.identity.configurations.v1}/lockout-policy`);
}
export async function getVendorLockoutPolicy() {
  return Get(`${urls.identity.configurations.v1}/lockout-policy/vendor`);
}
export async function saveLockoutPolicy(body) {
  if (body.id) {
    return Patch(`${urls.identity.configurations.v1}/lockout-policy`, body);
  } else {
    return Post(`${urls.identity.configurations.v1}/lockout-policy`, body);
  }
}
export async function getCaptchaPolicy() {
  try {
    return await Get(`${urls.identity.configurations.v1}/captcha-policy/public`);
  } catch {
    return null;
  }
}
export async function getPasswordHistoryPolicy() {
  return Get(`${urls.identity.configurations.v1}/password-history-policy`);
}
export async function getVendorPasswordHistoryPolicy() {
  return Get(`${urls.identity.configurations.v1}/password-history-policy/vendor`);
}
export async function savePasswordHistoryPolicy(body) {
  if (body.id) {
    return Patch(`${urls.identity.configurations.v1}/password-history-policy`, body);
  } else {
    return Post(`${urls.identity.configurations.v1}/password-history-policy`, body);
  }
}
export async function getPasswordConfigPolicy() {
  return Get(`${urls.identity.configurations.v1}/password`);
}
export async function getDomainRestrictions() {
  return Get(`${urls.identity.restrictions.emailDomain.v1}`);
}
export async function getDomainRestrictionsConfig() {
  return Get(`${urls.identity.restrictions.emailDomain.v1}/config`);
}
export async function createDomainRestriction(body) {
  return Post(`${urls.identity.restrictions.emailDomain.v1}`, body);
}
export async function updateDomainRestrictionConfig(body) {
  return Post(`${urls.identity.restrictions.emailDomain.v1}/config`, body);
}
export async function deleteDomainRestriction(id) {
  return Delete(`${urls.identity.restrictions.emailDomain.v1}/${id}`);
}
export async function getIPRestrictions(params) {
  return Get(`${urls.identity.restrictions.ip.v1}`, params);
}
export async function getIPRestrictionsConfig() {
  return Get(`${urls.identity.restrictions.ip.v1}/config`);
}
export async function createIPRestriction(body) {
  return Post(`${urls.identity.restrictions.ip.v1}`, body);
}
export async function bulkCreateIPRestriction(body) {
  return Post(`${urls.identity.restrictions.ip.v1}/bulk`, body);
}
export async function updateIPRestrictionConfig(body) {
  return Post(`${urls.identity.restrictions.ip.v1}/config`, body);
}
export async function deleteIPRestriction(id) {
  return Delete(`${urls.identity.restrictions.ip.v1}/${id}`);
}
export async function testCurrentIp() {
  return Post(`${urls.identity.restrictions.ip.v1}/verify`);
}
export async function testCurrentIpInAllowList() {
  return Post(`${urls.identity.restrictions.ip.v1}/verify/allow`);
}