import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["authorizationUrl"],
  _excluded2 = ["events", "url"];
import { api, fetch } from '@frontegg/rest-api';
import { call, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { refreshTokenForSocialLogins } from '../LoginState/saga';
import { actions } from '../reducer';
import { AuthenticationTypes } from '../interfaces';
import { UserVeirifedOriginTypes } from '../interfaces';
import { errorHandler, GTMEventAction, reportGTMEvent } from '../../utils';
export function* loadSocialLoginsConfigurations() {
  try {
    yield put(actions.setSocialLoginsState({
      loading: true
    }));
    const socialLoginsConfig = yield call(api.auth.getSocialLoginProviders);
    yield put(actions.setSocialLoginsState({
      socialLoginsConfig,
      loading: false,
      firstLoad: false
    }));
  } catch (e) {
    yield put(actions.setSocialLoginsState({
      error: errorHandler(e),
      loading: false,
      firstLoad: false
    }));
  }
}
export function* getSocialLoginsConfigurationsV2() {
  const isAuthenticated = yield select(({
    auth
  }) => auth.isAuthenticated);
  let socialLoginsConfigV2;
  if (isAuthenticated) {
    socialLoginsConfigV2 = yield call(api.auth.getSocialLoginProvidersV2ForAuthenticatedUser);
  } else {
    socialLoginsConfigV2 = yield call(api.auth.getSocialLoginProvidersV2);
  }
  return socialLoginsConfigV2;
}
export function* loadSocialLoginsConfigurationsV2(action) {
  try {
    var _action$payload, _action$payload$callb;
    const context = yield select(state => state.root.context);
    yield put(actions.setSocialLoginsState({
      loading: true
    }));
    const socialLoginsConfigV2 = yield call(getSocialLoginsConfigurationsV2);
    const {
      providers: customSocialLoginsConfig
    } = yield call(api.auth.getCustomSocialLoginProvidersV1);
    const socialLoginsConfigWithFullUrl = socialLoginsConfigV2.map(_ref => {
      let {
          authorizationUrl
        } = _ref,
        config = _objectWithoutPropertiesLoose(_ref, _excluded);
      const baseUrl = fetch.getBaseUrl(context, authorizationUrl != null ? authorizationUrl : '');
      return _extends({}, config, {
        authorizationUrl: authorizationUrl ? `${baseUrl}${authorizationUrl}` : null
      });
    });
    const newState = {
      socialLoginsConfigV2: socialLoginsConfigWithFullUrl,
      customSocialLoginsConfig,
      loading: false,
      firstLoad: false
    };
    yield put(actions.setSocialLoginsState(newState));
    action == null ? void 0 : (_action$payload = action.payload) == null ? void 0 : (_action$payload$callb = _action$payload.callback) == null ? void 0 : _action$payload$callb.call(_action$payload, newState);
  } catch (e) {
    var _action$payload2, _action$payload2$call;
    action == null ? void 0 : (_action$payload2 = action.payload) == null ? void 0 : (_action$payload2$call = _action$payload2.callback) == null ? void 0 : _action$payload2$call.call(_action$payload2, null, e);
    yield put(actions.setSocialLoginsState({
      error: errorHandler(e),
      loading: false,
      firstLoad: false
    }));
  }
}
function* loginViaSocialLogin(_ref2) {
  let {
      payload: {
        events,
        url
      }
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  try {
    yield put(actions.setSocialLoginsState({
      loading: true
    }));
    const {
      email,
      isNewUser,
      userId,
      tenantId,
      name
    } = yield call(api.auth.loginViaSocialLogin, payload);
    if (isNewUser) {
      var _events$signUpComplet, _events$userVerified;
      const basePayload = {
        email,
        url,
        createdAt: new Date(),
        id: userId,
        tenantId
      };
      const signUpCompletePayload = _extends({}, basePayload, {
        socialProvider: payload.provider,
        authenticationType: AuthenticationTypes.SOCIAL_LOGIN
      });
      events == null ? void 0 : (_events$signUpComplet = events.signUpComplete) == null ? void 0 : _events$signUpComplet.call(events, signUpCompletePayload);
      reportGTMEvent(GTMEventAction.SIGNUP_COMPLETED, signUpCompletePayload);
      const userVerifiedPayload = _extends({}, basePayload, {
        origin: UserVeirifedOriginTypes.SOCIAL_LOGIN,
        name
      });
      events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, userVerifiedPayload);
      reportGTMEvent(GTMEventAction.USER_VERIFIED, userVerifiedPayload);
    }
    if (userId) {
      localStorage.setItem('userId', userId);
    }
    yield put(actions.setLoginState({
      email,
      isNewUser
    }));
    yield refreshTokenForSocialLogins();
    localStorage.removeItem('register-quick-login');
    yield put(actions.setSocialLoginsState({
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSocialLoginsState({
      loading: false,
      error: errorHandler(e, 'Failed to authenticate'),
      firstLoad: false
    }));
  }
}
function* setSocialLoginError({
  payload
}) {
  yield put(actions.setSocialLoginsState({
    error: payload.error,
    loading: false,
    firstLoad: false
  }));
}
export function* socialLoginsSaga() {
  yield takeLeading(actions.loadSocialLoginsConfiguration, loadSocialLoginsConfigurations);
  yield takeEvery(actions.loadSocialLoginsConfigurationV2, loadSocialLoginsConfigurationsV2);
  yield takeLeading(actions.loginViaSocialLogin, loginViaSocialLogin);
  yield takeLatest(actions.setSocialLoginError, setSocialLoginError);
}