import _extends from "@babel/runtime/helpers/esm/extends";
// update node name
export const updateNodeName = (treeNode, id, newName) => {
  var _treeNode$children$ma, _treeNode$children;
  if (treeNode.tenantId === id) {
    return _extends({}, treeNode, {
      name: newName
    });
  }
  return _extends({}, treeNode, {
    children: (_treeNode$children$ma = treeNode == null ? void 0 : (_treeNode$children = treeNode.children) == null ? void 0 : _treeNode$children.map(child => updateNodeName(child, id, newName))) != null ? _treeNode$children$ma : []
  });
};