<div *ngIf="isShowLoader">
    <so-loader [smallloader]='true'></so-loader>
</div>
<div class="modal-header align-items-start" (click)="$event.stopPropagation()">
    <div class="row">
        <h1 class="modal-title pull-left">{{headerName}}</h1>
        <span>Select up to eight columns to be included on the {{!isProject ? 'Device' : 'Device/Task'}} List pages of
            the report.</span>
    </div>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body m-0 configure-card-body" (click)="$event.stopPropagation()">
    <div class="card configure-card ">
        <div class="row">
            <ng-container *ngFor="let item of columnList; let index = index">
                <div class="col">
                    <label class="device-card mt-2 mb-1">{{item.key}}</label>
                    <form [formGroup]="reportConfigFieldForm" novalidate>
                        <ul>
                            <li *ngFor="let columnValue of item.value">
                                <so-checkbox [isChecked]="columnValue.isDefault"
                                    [inputControlName]="$any(reportConfigFieldForm).controls[columnValue.name]"
                                    (onChange)="onChange(columnValue,$event)" [label]="columnValue.displayName"
                                    [id]="columnValue.name"
                                    [title]="(selectedCheckBox > 7 && !columnValue.isDefault) ?'You cannot select more than 8 Columns at a time.' :''"
                                    [parentTitle]="(selectedCheckBox > 7 && !columnValue.isDefault) ?'You cannot select more than 8 Columns at a time.' :''"
                                    [ngClass]="'lbl-check'" [disabled]="selectedCheckBox > 7 && !columnValue.isDefault">
                                </so-checkbox>
                            </li>
                        </ul>
                    </form>
                </div>
            </ng-container>
        </div>
        <div class="text-end configure-links clearfix mt-2" style="cursor:pointer">
            <a (click)="setNoneColumns()">None</a> |
            <a (click)="setDefaultColumns()">Default</a>
        </div>
    </div>
</div>
<div class="modal-footer ps-0" (click)="$event.stopPropagation()">
    <div class="row p-0 align-items-center" style="width: 100%">
        <div class="col-auto note-text ps-0" *ngIf="reportName === 'Multi-Plan' || reportName === 'Project Overview'">
            <sup class="asterik">*</sup> This may take several minutes for larger {{!isProject ? 'Sites' : 'Projects'}}.
            The PDF will be generated in the background while you continue working on other pages in SiteOwl.
        </div>
        <div class="col justify-content-end d-flex">
            <button type="button" class="btn btn-secondary" (click)="backClick(true)" data-dismiss="modal">Back</button>
            <button type="button" class="btn btn-primary me-0" (click)="downloadPdf()"> <em class="fa fa-download"></em>
                Download </button>
        </div>
    </div>
</div>