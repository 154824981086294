/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-prototype-builtins */
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChange,
} from "@angular/core";
import { CommonService, EventEmitterType, EventService, Utilities } from "@SiteOwl/core";
import moment from "moment";
import { fromEvent, Subject, Subscription } from "rxjs";
import { debounceTime, map, switchMap, take, takeUntil } from "rxjs/operators";

@Directive({
  selector: '[soDraggableImage]'
})
export class DraggableImageDirective implements AfterViewInit, OnChanges, OnDestroy, OnInit {


  handleElement!: HTMLElement;
  stopImageEvents = false;
  @Input() imageZoom = 100;
  @Input() isFitToScreen = true;
  @Input() zoomFactor = 1;
  @Input() syncCompleted = false;
  @Output() imageLoaded = new EventEmitter();
  @Output() wheelEvent = new EventEmitter();
  @Output() imageDragged = new EventEmitter();
  @Output() imageLoadedForFit = new EventEmitter();
  scrollZoomX!: number;
  scrollZoom!: boolean;
  scrollZoomY!: number;
  oldZoom: any = 100;
  parent: any;
  planImageWidth: any;
  planImageHeight: any;
  parentHeight: any;
  parentWidth: any;
  imageWidth: any;
  imageHeight: any;
  afterViewPercent = 0;
  isDrag: any;
  coordX!: number;
  coordY!: number;
  offsetX!: number;
  offsetY!: number;
  scrollDebounce = new Subject();
  tmpImageZoom: any;
  tmpImageZoomFactor: any = 1;
  floorContainer: any;
  private readonly destroy$ = new Subject<void>();
  dragStart!: boolean;
  imageMove$: any = new Subject<void>();
  private readonly init$ = new Subject<void>();
  imageZoomSubscribe!: Subscription;
  equipSub!: Subscription;
  deviceEventExecution = false;
  dragging = false;

  constructor(private elementRef: ElementRef,
    private commonService: CommonService,
    private eventService: EventService) {
    this.scrollDebounce.pipe(
      debounceTime(50), takeUntil(this.destroy$))
      .subscribe((value: any) => this.wheelEvent.emit({ isZoomIn: value, tmpImageZoomFactor: this.tmpImageZoomFactor, tmpImageZoom: this.tmpImageZoom }));
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (changes.hasOwnProperty('imageZoom')) {
      if ((this.isFitToScreen || (changes.hasOwnProperty('isFitToScreen') && changes['isFitToScreen'].currentValue) && changes['isFitToScreen'].currentValue !== changes['isFitToScreen'].previousValue) && isFinite(changes['imageZoom'].currentValue)) {
        this.onImageLoad({
          zoom: changes['imageZoom'].currentValue,
          header: true,
          fit: true,
          isFitToCalled: changes['imageZoom'].previousValue !== changes['imageZoom'].currentValue && changes['imageZoom'].currentValue
        });
      } else if (changes['imageZoom'].previousValue !== changes['imageZoom'].currentValue && isFinite(changes['imageZoom'].currentValue)) {
        this.onImageLoad({
          zoom: changes['imageZoom'].currentValue,
          header: false,
          fit: false,
        });
      }
      this.tmpImageZoom = changes['imageZoom'].currentValue;
    } else if ((changes.hasOwnProperty('isFitToScreen') && changes['isFitToScreen'].currentValue !== changes['isFitToScreen'].previousValue)) {
      this.onImageLoad({
        zoom: this.imageZoom,
        header: true,
        fit: changes['isFitToScreen'].currentValue,
        isFitToCalled: true
      });
    }
    if (changes['zoomFactor'] && changes['zoomFactor'].previousValue !== changes['zoomFactor'].currentValue && isFinite(changes['zoomFactor'].currentValue)) {
      this.tmpImageZoomFactor = changes['zoomFactor'].currentValue;
    }
    if(changes['syncCompleted'] && changes['syncCompleted'].currentValue !== this.syncCompleted) {
      this.syncCompleted = changes['syncCompleted'].currentValue;
    }
  }
  ngOnInit(): void {
    this.equipSub = this.eventService.subscribe(EventEmitterType.floorEquipmentSelection, (data: boolean) => {
      this.deviceEventExecution = data;
      if (this.dragStart) {
        if (this.dragging) {
          this.imageDragged.emit();
        }
        this.dragging = false;
        this.dragStart = false;
      }
    });
  }
  ngAfterViewInit(): void {
    const floorParent: any = document.querySelector('#parentContainer');
    this.parent = document.querySelector('#floorImageShadow');
    this.floorContainer = document.querySelector('#floorImageContainer');
    this.parent.addEventListener('load', (e: any) => {
      e.preventDefault();
      const timeout = setTimeout(() => {
        if (this.zoomFactor === 1) {
          // Original Image height and width
          this.planImageWidth = this.parent.naturalWidth;
          this.planImageHeight = this.parent.naturalHeight;
          // Parent container height and width
          this.parentHeight = floorParent.clientHeight;
          this.parentWidth = floorParent.clientWidth;
          // Image rendered height and width
          this.imageWidth = this.parent.clientWidth
          this.imageHeight = this.parent.clientHeight

          this.afterViewPercent = this.elementRef.nativeElement.clientWidth * 100 / this.planImageWidth;
          this.elementRef.nativeElement.style.left = `${(this.parentWidth / 2) - (this.imageWidth / 2)}px`;
          this.elementRef.nativeElement.style.top = `${(this.parentHeight / 2) - (this.imageHeight / 2)}px`;
          this.elementRef.nativeElement.style.userSelect = 'none';
        }
        this.init$.next();
        this.init$.complete();
        this.initDrag();
        this.initZoom();
        clearTimeout(timeout);
      }, 0);
      if (this.zoomFactor === 1 && !this.syncCompleted) {
        this.imageLoaded.emit();
      }
    }, { passive: true });
  }

  initDrag(): void {
    const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown");
    const dragEnd$ = fromEvent<MouseEvent>(window, "mouseup");
    const drag$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousemove").pipe(
      takeUntil(dragEnd$)
    );
    dragStart$.pipe(
      takeUntil(this.destroy$),
      switchMap(
        (start) => {
          this.imageDragStart(start)
          return drag$.pipe(map((move: any) => {
            this.imageDragging(move);
            return move;
          }),
            takeUntil(dragEnd$));
        }

      )).subscribe()
    dragEnd$.pipe(takeUntil(this.destroy$))
      .subscribe((event: any) => {
        if (this.deviceEventExecution) {
          return;
        }
        if (this.dragStart) {
          event.stopPropagation();
          event.preventDefault();
          if (this.dragging) {
            this.imageDragged.emit();
          }
          this.dragging = false;
          this.dragStart = false;
        }
      });
  }

  imageDragging(event: any) {
    if (this.deviceEventExecution) {
      return;
    }
    if (this.dragStart) {
      event.stopPropagation();
      const targ = this.elementRef.nativeElement;
      if (parseFloat(targ.style.left) !== (this.coordX + event.clientX - this.offsetX) || parseFloat(targ.style.top) !== (this.coordY + event.clientY - this.offsetY)) {
        const animationId = requestAnimationFrame(() => {
          targ.style.left = this.coordX + event.clientX - this.offsetX + 'px';
          targ.style.top = this.coordY + event.clientY - this.offsetY + 'px';
          cancelAnimationFrame(animationId)
        });
        this.dragging = true
      }
    }
  }

  imageDragStart(event: any) {
    if (this.deviceEventExecution) {
      return;
    }
    event.stopPropagation();
    this.dragStart = true;
    const targ = this.elementRef.nativeElement;
    this.coordX = parseInt(targ.style.left);
    this.coordY = parseInt(targ.style.top);
    this.offsetX = event.clientX;
    this.offsetY = event.clientY;
  }

  initZoom() {
    if (this.imageZoomSubscribe) {
      this.imageZoomSubscribe.unsubscribe();
    }
    if (this.commonService.checkBrowserIsFireFox()) {
      this.imageMove$ = fromEvent<WheelEvent>(this.elementRef.nativeElement, "DOMMouseScroll", { passive: true }).pipe(takeUntil(this.destroy$), takeUntil(this.init$));
    } else if (this.commonService.checkBrowser()) {
      this.imageMove$ = fromEvent<WheelEvent>(this.elementRef.nativeElement, "wheel", { passive: true }).pipe(takeUntil(this.destroy$), takeUntil(this.init$));
    } else {
      this.imageMove$ = fromEvent<WheelEvent>(this.elementRef.nativeElement, "mousewheel", { passive: true }).pipe(takeUntil(this.destroy$), takeUntil(this.init$));
    }
    this.imageZoomSubscribe = this.imageMove$.pipe(takeUntil(this.destroy$), takeUntil(this.init$)).subscribe((event: WheelEvent) => {
      this.imageZoomEvent(event);
    });
  }
  onZoomWidthChange() {
    this.scrollZoom = true;
    this.onImageLoad({
      zoom: this.tmpImageZoom,
      header: false,
      fit: false,
    })
    requestAnimationFrame(() => {
      this.floorContainer.style.width = (this.tmpImageZoom * this.parent.naturalWidth) / 100 + "px";
    });
  }
  onImageLoad(data: any) {
    const floorParent: any = document.querySelector('#parentContainer');
    if (!Utilities.isNull(floorParent)) {
      if (this.parentWidth == undefined || this.parentWidth == 0 || this.parentHeight == undefined || this.parentHeight == 0) {
        this.parentHeight = floorParent.clientHeight;
        this.parentWidth = floorParent.clientWidth;
      }
    }
    if (!Utilities.isNull(this.parent)) {
      if (this.planImageHeight == undefined || this.planImageHeight == 0 || this.planImageWidth == undefined || this.planImageWidth == 0) {
        this.planImageHeight = this.parent.naturalHeight;
        this.planImageWidth = this.parent.naturalWidth;
        if (this.imageWidth == undefined || this.imageWidth == 0 || this.imageHeight == undefined || this.imageHeight == 0) {
          this.imageWidth = this.parent.clientWidth
          this.imageHeight = this.parent.clientHeight
        }
      }
    }
    const newPlanImageWidth = this.planImageWidth * Math.round(data.zoom === 0 ? this.afterViewPercent : data.zoom) / 100;
    const newPlanImageHeight = newPlanImageWidth * this.planImageHeight / this.planImageWidth;
    if (!isNaN(newPlanImageWidth) && !isNaN(newPlanImageHeight)) {
      if (data.header) { // zoom in zoom out using buttons.
        if (data.fit) {
          this.elementRef.nativeElement.style.top = `0px`;
          this.elementRef.nativeElement.style.left = `${(this.parentWidth / 2) - (this.imageWidth / 2)}px`
          this.elementRef.nativeElement.style.top = `${(this.parentHeight / 2) - (this.imageHeight / 2)}px`;
          if (data.isFitToCalled) {
            this.imageLoadedForFit.emit()
          } else {
            this.imageLoaded.emit();
          }
        } else {
          this.calcForScroll(newPlanImageWidth, newPlanImageHeight, data);
        }
      } else { // Mouse scroll zoom in zoom out calculation.
        this.calcForScroll(newPlanImageWidth, newPlanImageHeight, data);
      }
      this.oldZoom = data.zoom === 0 ? this.afterViewPercent : data.zoom;
      this.scrollZoom = false;
    }
  }

  private calcForScroll(newPlanImageWidth: number, newPlanImageHeight: number, data: { zoom: number; }) {
    let modifyLeft = 0, modifyTop = 0;
    const x = Number(this.elementRef.nativeElement.style.left.replace('px', ''));
    const y = Number(this.elementRef.nativeElement.style.top.replace('px', ''));

    if (this.scrollZoom) {
      if (this.oldZoom !== 0) {
        modifyLeft = this.scrollZoomX - ((this.scrollZoomX - x) * data.zoom) / this.oldZoom;
        modifyTop = this.scrollZoomY - ((this.scrollZoomY - y) * data.zoom) / this.oldZoom;
      }
    } else {
      modifyLeft = (this.parentWidth / 2) - (((this.parentWidth / 2) - x) * data.zoom) / this.oldZoom;
      modifyTop = (this.parentHeight / 2) - (((this.parentHeight / 2) - y) * data.zoom) / this.oldZoom;
    }

    if (newPlanImageWidth < (0 - modifyLeft)) {
      this.elementRef.nativeElement.style.left = `-${(newPlanImageWidth - this.parentWidth) / 2}px`;
    } else {
      this.elementRef.nativeElement.style.left = `${modifyLeft}px`;
    }
    if (newPlanImageHeight < (0 - modifyTop)) {
      this.elementRef.nativeElement.style.top = `-${(newPlanImageHeight - this.parentHeight) / 2}px`;
    } else {
      this.elementRef.nativeElement.style.top = `${modifyTop}px`;
    }
  }
  imageZoomEvent(event: any) {
    if (this.deviceEventExecution) {
      return;
    }
    if (this.stopImageEvents) return
    this.scrollZoom = true;
    this.scrollZoomX = event.clientX - 80;
    this.scrollZoomY = event.clientY - 145;
    const condition = this.commonService.checkBrowserIsFireFox() ? event.detail : event.deltaY;
    if (condition < 0) {
      this.tmpImageZoom = this.tmpImageZoom * 1.08;
      this.tmpImageZoomFactor = this.tmpImageZoomFactor * 1.08;
      document.querySelectorAll(`[id^="coveragesvg1_"]`).forEach((element: any) => {
        element.style.transform = 'scale(' + this.tmpImageZoomFactor + ')';
      })
      this.onZoomWidthChange();
      this.scrollDebounce.next(true);
    } else if (condition > 0) {
      this.tmpImageZoom = this.tmpImageZoom / 1.08;
      this.tmpImageZoomFactor = this.tmpImageZoomFactor / 1.08;
      document.querySelectorAll(`[id^="coveragesvg1_"]`).forEach((element: any) => {
        element.style.transform = 'scale(' + this.tmpImageZoomFactor + ')';
      })
      this.onZoomWidthChange();
      this.scrollDebounce.next(false);
    }
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.scrollDebounce) {
      this.scrollDebounce.unsubscribe();
    }
    if (this.equipSub) {
      this.equipSub.unsubscribe();
    }
  }
}

