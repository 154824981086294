import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { createModuleCaseReducers } from '../../utils';
import { subscriptionsStoreName } from '../../../constants';
import { subscriptionActions } from '../Subscription';
export const initialBillingInformationState = {
  loading: false,
  error: null,
  fetching: true
};
const reducers = _extends({}, createModuleCaseReducers());
const {
  reducer,
  actions: overviewActions,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/billing/information`,
  initialState: initialBillingInformationState,
  reducers
});
const actions = _extends({
  loadBillingInformation: createAction(`${name}/loadBillingInformation`),
  cancelSubscription: subscriptionActions.cancelSubscription,
  renewSubscription: subscriptionActions.renewSubscription
}, overviewActions);
export { reducer as informationReducer, actions as informationActions };