import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["email"];
import { Get, Patch } from "../fetch";
import { urls } from "../constants";
export async function getPaymentMethods() {
  return Get(urls.subscriptions.billing.paymentMethods.v1);
}
export async function getPaymentMethod(paymentMethodId) {
  return Get(`${urls.subscriptions.billing.paymentMethods.v1}/${paymentMethodId}`);
}
export async function updatePaymentMethodBillingDetails(paymentMethodId, _ref) {
  let {
      email
    } = _ref,
    address = _objectWithoutPropertiesLoose(_ref, _excluded);
  return Patch(`${urls.subscriptions.billing.paymentMethods.v1}/${paymentMethodId}/billing-details`, {
    email,
    address
  });
}