import _extends from "@babel/runtime/helpers/esm/extends";
// needs for pagination - load more to append new items to the tree
export function appendChildrenToNode(treeNode, id, newChildren) {
  var _treeNode$children$ma, _treeNode$children2;
  if (treeNode.tenantId === id) {
    var _treeNode$children;
    const combinedChildren = [...((_treeNode$children = treeNode == null ? void 0 : treeNode.children) != null ? _treeNode$children : []), ...newChildren];

    // Filter duplicates
    const uniqueChildren = combinedChildren.filter((child, index, self) => index === self.findIndex(t => t.tenantId === child.tenantId));
    return _extends({}, treeNode, {
      children: uniqueChildren
    });
  }
  return _extends({}, treeNode, {
    children: (_treeNode$children$ma = treeNode == null ? void 0 : (_treeNode$children2 = treeNode.children) == null ? void 0 : _treeNode$children2.map(child => appendChildrenToNode(child, id, newChildren))) != null ? _treeNode$children$ma : []
  });
}