import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { ForgotPasswordStep } from './interfaces';
import { authStoreName } from '../../constants';
const forgotPasswordState = {
  step: ForgotPasswordStep.forgotPassword,
  loading: false,
  email: '',
  passwordConfig: null
};
const reducers = {
  setForgotPasswordState: typeReducerForKey('forgotPasswordState'),
  resetForgotPasswordState: resetStateByKey('forgotPasswordState', {
    forgotPasswordState
  })
};
const actions = {
  forgotPassword: createAction(`${authStoreName}/forgotPassword`, payload => ({
    payload
  })),
  resetPassword: createAction(`${authStoreName}/resetPassword`, payload => ({
    payload
  })),
  loadPasswordConfig: createAction(`${authStoreName}/loadPasswordConfig`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { forgotPasswordState, reducers as forgotPasswordReducers, actions as forgotPasswordActions };