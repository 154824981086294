import _extends from "@babel/runtime/helpers/esm/extends";
import { typeReducerForKey } from '../utils';
import { createAction } from '@reduxjs/toolkit';
import { auditsStoreName } from '../../constants';
const auditsMetadataState = {
  loading: true
};
const reducers = {
  setAuditsMetadataState: typeReducerForKey('auditsMetadataState'),
  resetAuditsMetadataState: state => _extends({}, state, {
    auditsMetadataState
  })
};
const actions = {
  loadAuditsMetadata: createAction(`${auditsStoreName}/loadAuditsMetadata`)
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { auditsMetadataState, reducers as auditsMetadataReducers, actions as auditsMetadataActions };