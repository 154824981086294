import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const customLoginState = {
  loading: true,
  error: null
};
const reducers = {
  setCustomLoginState: typeReducerForKey('customLoginState'),
  resetCustomLoginState: resetStateByKey('customLoginState', {
    customLoginState
  })
};
const actions = {
  loadTenantMetadata: createAction(`${authStoreName}/loadTenantMetadata`),
  updateTenantMetadata: createAction(`${authStoreName}/updateTenantMetadata`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { customLoginState, reducers as customLoginReducers, actions as customLoginActions };