"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createPermissionCheckRegex = exports.checkPermission = void 0;
function checkPermission(permissions, requiredPermission) {
  return Object.keys(permissions).some(permissionKey => createPermissionCheckRegex(permissionKey).test(requiredPermission));
}
exports.checkPermission = checkPermission;
function createPermissionCheckRegex(permissionKey) {
  return new RegExp('^' + permissionKey.replace(/\./g, '\\.').replace(/\*/g, '.*') + '$', 'gs');
}
exports.createPermissionCheckRegex = createPermissionCheckRegex;
