function b64toBlob(base64, contentType) {
  const sliceSize = 512;
  const byteCharacters = atob(base64);
  const byteArrays = [];
  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    byteArrays.push(new Uint8Array(byteNumbers));
  }
  return new Blob(byteArrays, {
    type: contentType
  });
}
export function base64ToFormData(base64, key = 'file') {
  const matchResult = base64.match(/^data:image\/([A-Za-z-+\/]+);base64,(.+)$/);
  if (matchResult == null) {
    return null;
  }

  // Get the content type of the image
  const contentType = `image/${matchResult[1]}`;
  // get the real base64 content of the file
  const data = matchResult[2];

  // Convert it to a blob to upload
  const blob = b64toBlob(data, contentType);

  // Create a FormData and append the file with "image" as parameter name
  const formDataToUpload = new FormData();
  formDataToUpload.append(key, new File([blob], key, {
    type: contentType
  }));
  return formDataToUpload;
}