/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-move-building',
  templateUrl: './move-building.component.html',
  styleUrls: ['./move-building.component.scss'],
})
export class MoveBuildingComponent {
  isProject = false;
  projects!: any;
  currentProject!: any;
  currentBuilding!: any;
  selectedSiteIdForMove!: any;
  errorMovingProject!:any;
  errorMoveBuilding!: any;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(public moveModelRef: BsModalRef) {

  }

  moveBuidlingToOtherProject() {
    this.event.emit(this.selectedSiteIdForMove);
  }
}
