import _extends from "@babel/runtime/helpers/esm/extends";
import { extractHeadersFromOptions, Get, Post, Put } from '../fetch';
import { urls } from '../constants';
export async function GetUserJwt(body) {
  return Post(`${urls.identity.users.v3}/me/token`, body);
}
export async function getUsersV2(queryParams, options) {
  return Get(urls.identity.users.v2, queryParams, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getUsersV3(queryParams, options) {
  return Get(urls.identity.users.v3, queryParams, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getUsersRoles(queryParams, options) {
  return Get(urls.identity.users.roles.v3, _extends({}, queryParams, {
    ids: queryParams.ids.join(',')
  }), {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getUsersGroups(queryParams, options) {
  return Get(urls.identity.users.groups.v3, _extends({}, queryParams, {
    ids: queryParams.ids.join(",")
  }), {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getCurrentUserTenantsV3(options) {
  return Get(urls.identity.users.tenants.me.v3, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getCurrentUserTenantsV1(options) {
  return Get(urls.identity.users.tenants.me.v1, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function sendResetBreachedPasswordEmails() {
  return Post(urls.identity.users.passwords.resetBreachedPasswords.v1);
}
export async function updateUserProfileV2(body) {
  return Put(`${urls.identity.users.v2}/me`, body);
}