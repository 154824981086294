import { Utilities } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from "lodash";

@Pipe({
  name: 'floorActiveInActive',
  pure: false
})
export class FloorActiveInactivePipe implements PipeTransform {

  transform(items: any[], showArchiveFloors: boolean): any {
    if (Utilities.isNull(items)) {
      return items || [];
    }
    if (showArchiveFloors) {
      items = items.filter(x => x.isArchived === false);
    }
    if (items.length > 0) {
      const sortedSiteData = _.orderBy(items, ['orderNumber'], ['asc']);
      return sortedSiteData;
    } else {
      return ['NODATA'];
    }
  }
}
