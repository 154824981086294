class FronteggNativeModule {
  constructor() {
    this.loginWithSSO = email => {
      if (this.isWebkitAvailable()) {
        var _window$webkit, _window$webkit$messag, _window$webkit$messag2;
        (_window$webkit = window.webkit) == null ? void 0 : (_window$webkit$messag = _window$webkit.messageHandlers) == null ? void 0 : (_window$webkit$messag2 = _window$webkit$messag.FronteggNativeBridge) == null ? void 0 : _window$webkit$messag2.postMessage(JSON.stringify({
          action: 'loginWithSSO',
          payload: email
        }));
      } else if (this.isJsInterfaceAvailable()) {
        var _window$FronteggNativ;
        (_window$FronteggNativ = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ.loginWithSSO(email);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.loginWithSocialLogin = url => {
      if (this.isWebkitAvailable()) {
        var _window$webkit2, _window$webkit2$messa, _window$webkit2$messa2;
        (_window$webkit2 = window.webkit) == null ? void 0 : (_window$webkit2$messa = _window$webkit2.messageHandlers) == null ? void 0 : (_window$webkit2$messa2 = _window$webkit2$messa.FronteggNativeBridge) == null ? void 0 : _window$webkit2$messa2.postMessage(JSON.stringify({
          action: 'loginWithSocialLogin',
          payload: url
        }));
      } else if (this.isJsInterfaceAvailable()) {
        var _window$FronteggNativ2;
        (_window$FronteggNativ2 = window.FronteggNativeBridge) == null ? void 0 : _window$FronteggNativ2.loginWithSocialLogin(url);
      } else {
        throw new Error('FronteggNativeBridge is not available');
      }
    };
    this.promptSocialLoginConsent = () => {
      var _window$FronteggNativ3, _window$FronteggNativ4;
      return (_window$FronteggNativ3 = (_window$FronteggNativ4 = window.FronteggNativeBridgeFunctions) == null ? void 0 : _window$FronteggNativ4['shouldPromptSocialLoginConsent']) != null ? _window$FronteggNativ3 : false;
    };
  }
  isWebkitAvailable() {
    var _window$webkit3, _window$webkit3$messa;
    return ((_window$webkit3 = window.webkit) == null ? void 0 : (_window$webkit3$messa = _window$webkit3.messageHandlers) == null ? void 0 : _window$webkit3$messa.FronteggNativeBridge) != null;
  }
  isJsInterfaceAvailable() {
    return window.FronteggNativeBridge != null;
  }
  isAvailable(method) {
    if (this.isWebkitAvailable() || this.isJsInterfaceAvailable()) {
      var _window$FronteggNativ5, _window$FronteggNativ6;
      return (_window$FronteggNativ5 = (_window$FronteggNativ6 = window.FronteggNativeBridgeFunctions) == null ? void 0 : _window$FronteggNativ6[method]) != null ? _window$FronteggNativ5 : false;
    }
    return false;
  }
}
export default new FronteggNativeModule();