<div class="modal-header">
    <h1 class="modal-title pull-left"> Monitoring</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>
<form [formGroup]="userForm" (ngSubmit)="testDomotz()" novalidate>
    <div class="modal-body">
        <div class="form-group">
            <label>Monitoring User ID
                <sup>*</sup>
            </label>

            <input maxlength="80" type="text" formControlName="domotzUserId" class="form-control"
                placeholder="Monitoring User ID ">
            <so-control-messages [name]="'Monitoring User ID'" [isFormSubmitted]="isFormSubmitted"
                [control]="$any(userForm).controls.domotzUserId"></so-control-messages>
        </div>
        <div class="form-group">
            <label>Monitoring API Key
                <sup>*</sup>
            </label>
            <input maxlength="80" type="text" class="form-control" formControlName="domotzAuthenticationKey"
                placeholder="Monitoring API Key">
            <so-control-messages [name]="'Monitoring API Key'" [isFormSubmitted]="isFormSubmitted"
                [control]="$any(userForm).controls.domotzAuthenticationKey"></so-control-messages>
        </div>
        <div class="spinner-block">
            <i class="fa fa-spinner popup-spinner" *ngIf="showSpinner" [ngClass]="{'fa-animated':showSpinner}"> </i>
            <div class="success-message" *ngIf="canSave && !showSpinner">Test successful!</div>
            <div class="erorr-message" *ngIf="code === 'Fail' && !showSpinner">{{message}}.</div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="closeModal()" class="btn btn-secondary ">Cancel</button>
        <button type="submit" [ngStyle]="{'cursor': showSpinner ? 'not-allowed': 'pointer'}" [disabled]="showSpinner"
            class="btn btn-primary m-0">{{!canSave?'Test':'Save'}}</button>
    </div>
</form>