/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListService, CustomerService, MessageService, ButtonEnableDisableService, Roles, AuthService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'so-user-site-access',
  templateUrl: './user-site-access.component.html',
  styleUrls: ['./user-site-access.component.scss'],
})
export class UserSiteAccessComponent implements OnInit {

  isEditMode = false;
  isProjectRole!: boolean
  isProvider!: boolean
  nullValue: any = null;
  providerDetail: any;
  providers!: any[];
  users!: any[];
  customers: any[] = []
  roles = {
    amt: [],
    project: []
  };
  currentUser: any;
  userCount!: number;
  isSelected = true
  tempProviderProjectName!: string;
  tempProviderAMTName!: string
  previouslyCustomerId!: number;
  accesslevelAscending: any;
  userDetails: any;
  resourceForm!: FormGroup<any>;
  sites: any = [];
  isFormSubmitted!: boolean;
  noCuatomers!: boolean;
  isAllManager!: boolean;
  canShow = false
  providerId: any;
  user: any;
  selectedCustomerId: any = false;
  totalSites!: number;
  selectedAllSitesCount!: number;
  selectedDesignProjectCount!: number;
  selectedInstallProjectCount!: number;
  totalDesignProjectCount!: number;
  totalInstallProjectCount!: number;
  selectedAllSites = false;
  siteLoad!: boolean;
  customerWillSelected: any;
  selectedCustomerInfo: any;
  siteAvailableForCustomer: any;
  pageLoader = true;
  customerSelectedName: any;
  loggedInUserCustomerList: any;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private customerService: CustomerService,
    private fb: FormBuilder,public bsModalRef: BsModalRef,
    private messageService: MessageService,
    public btnService: ButtonEnableDisableService,
    private listService: ListService,
    private authService: AuthService) {}

  ngOnInit(): void {
    this.user = this.authService.getUserData();
    this.providerId = this.user.providerId;
    this.createForm();
    if (this.authService.getUserKeyData().userRole === Roles.Administrator) {
      this.getAllCustomers();
    } else {
      this.getAllCustomersByUser();
    }
  }
  createForm() {
    this.resourceForm = this.fb.group({
      customer: [null, Validators.required],
    })
  }
  getCustomers() {
    this.listService.getProviderCustomerById(this.providerId).subscribe({
      next: (res:any) => {
        let providerCustomers = null;
        providerCustomers = res;
        this.setPopup(providerCustomers);
        this.canShow = true;
      }, error: () => {
        this.pageLoader = false;
      }
    })
  }
  getAllCustomers() {
    this.listService.getActiveCustomers().subscribe({
      next: (result: any) => {
        this.setPopup(result);
        this.canShow = true;
      }  
    });
  }
  getAllCustomersByUser() {
    this.setPopup(this.loggedInUserCustomerList);
    this.canShow = true;
  }
  setPopup(customers: any) {
      this.customers = [];
      this.userDetails.customerUserList.forEach((customer: any) => {
        if (customer.role.name !== 'Manager') {
          customer['customerName']= customer?.customer?.name;
          this.customers.push(customer)
        }
      });
      if (this.customers.length > 0 && this.authService.getUserKeyData().userRole !== Roles.Administrator) {
        this.customers.forEach((tempCustomer, index) => {
          if (customers.filter((x: any) => x.customer?.id === tempCustomer.customer.id).length === 0 || customers.filter((x: any) => x.customer?.id === tempCustomer.customer.id && x.role.name !== Roles.Manager).length > 0) {
            this.customers.splice(index, 1)
          }
        })
      }
      this.customers = _.sortBy(this.customers, (a) => {
        return a.customer?.name?.toLowerCase();
      });
      this.selectedCustomerInfo = this.authService.getCustomerId().customerId;
      if (this.selectedCustomerInfo > 0 && this.customerWillSelected) {
        const customer = this.customers.filter(e => e.customer?.id === this.selectedCustomerInfo);
        if (this.userDetails.customerUserList.filter((e: any) => e.customer?.id === this.selectedCustomerInfo).length > 0) {
          this.customerSelectedName = this.userDetails.customerUserList.filter((e: any) => e.customer.id === this.selectedCustomerInfo)[0].customer.name;
        }
        this.selectedCustomerId = true;
        if (customer.length > 0) {
          if (this.resourceForm) {
            this.resourceForm.controls['customer'].setValue(customer[0]);
          }
          this.customerSelected();
        } else {
          this.pageLoader = false;
        }
      } else {
        this.selectedCustomerId = false;
        this.pageLoader = false;
      }
      if (this.userDetails.customerUserList.length > 0 && this.userDetails.customerUserList.every((cust: any) => cust.role.name === 'Manager')) {
        this.isAllManager = true
        this.pageLoader = false;

      } else if (this.customers.length === 0) {
        this.noCuatomers = true
        this.pageLoader = false;
      }
  }
  customerSelected() {
    this.sites = [];
    this.siteLoad = true;
    if (this.resourceForm.controls['customer'].value === null) {
      return;
    }
    this.customerService.getCustomerSites(this.resourceForm.controls['customer'].value.customer.id, this.userDetails.userId).subscribe({
      next: (result: any) => {
        const tempSites: any = result['sites'];
        this.siteAvailableForCustomer = result['isSitesAvailable'];
        this.sites = _.cloneDeep(tempSites);
        this.totalSites = this.sites.length;
        this.selectedAllSites = false;
        this.selectedAllSitesCount = 0;
        this.siteLoad = false;
        this.pageLoader = false;
        this.sites.forEach((element: any) => {
          element.isSelectedSite = false;
        });
      }, error: () => {
        this.pageLoader = false;
      }  
    })

  }
  closeModal() {
    this.bsModalRef.hide();
  }
  disableButton() {
    return (this.isAllManager || this.noCuatomers || (this.resourceForm.invalid))
  }
  addUserToSites() {
    this.isFormSubmitted = true;
    if (this.resourceForm.invalid) {
      return;
    }
    this.btnService.disable()
    const siteIds: any = [];
    this.sites.filter((e: any) => e.isSelectedSite).forEach((element:any) => {
      siteIds.push(element.id);
    })
    const resource = {
      permissionLevelDTO: {
        emails: [this.userDetails.email],
      },
      siteIds: siteIds,
      customerId: this.resourceForm.controls['customer'].value.customer.id,
      role: this.resourceForm.controls['customer'].value.role,
      projectRole: this.resourceForm.controls['customer'].value.projectRole
    }
    this.customerService.addSitesToUser(resource).subscribe({
      next: (result: any) => {
        this.event.emit(result);
        this.btnService.enable()
        this.bsModalRef.hide();
        let saveUser = this.userDetails.name;
        if (saveUser === null || saveUser === undefined) {
          saveUser = this.userDetails.email
        }
        this.messageService.successMessage(`User: ${saveUser}`, 'Added Successfully')
      }
    })
  }
  selectAllSites() {
    this.selectedAllSites = !this.selectedAllSites;
    this.sites.forEach((element: any) => {
      element.isSelectedSite = this.selectedAllSites;
    });
    this.selectedAllSitesCount = this.sites.filter((e:any) => e.isSelectedSite).length;
  }
  selectSites(event: any, site: any) {
    site.isSelectedSite = event.target.checked;
    if (this.sites.filter((e: any) => !e.isSelectedSite).length > 0) {
      this.selectedAllSites = false;
    } else {
      this.selectedAllSites = true;
    }
    this.selectedAllSitesCount = this.sites.filter((e: any) => e.isSelectedSite).length;
  }
}

