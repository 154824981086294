import { AuthService, ButtonEnableDisableService, Environment } from '@SiteOwl/core';
import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-device-import',
  templateUrl: './device-import.component.html',
  styleUrls: ['./device-import.component.scss'],
})
export class DeviceImportComponent implements OnInit {
  isFromSite!: any;
  @ViewChild('fileUpload', { static: true }) fileUpload?: ElementRef;
  public event: EventEmitter<any> = new EventEmitter();
  cdnUrl = this.env.cdnUrl;
  unitOfMeasurements: any = 'Feet'
  constructor(private authService: AuthService, public bsModalRef: BsModalRef, public buttonEnableDisableService: ButtonEnableDisableService, public readonly env: Environment) { }
  ngOnInit(): void {
    this.unitOfMeasurements = this.authService.getUserKeyData().unitOfMeasurements;
  }

  uploadCSVModalBrowse() {
    this.fileUpload?.nativeElement.click();
  }

  _action(event: any) {
    this.event.emit(event);
    this.bsModalRef.hide();
  }

  _actionCancel(event: any) {
    this.event.emit('Cancel');
    this.bsModalRef.hide();
  }
}
