export const mapMetaDataObjectToActions = (obj, path = []) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (typeof value === 'object') {
      return [...acc, ...mapMetaDataObjectToActions(value, [...path, key])];
    }
    return [...acc, {
      type: [...path, key].join('/'),
      payload: value
    }];
  }, []);
};