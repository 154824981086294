import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'so-range-picker',
  templateUrl: './range-picker.component.html',
  styleUrls: ['./range-picker.component.scss'],
})
export class RangePickerComponent implements OnInit {
  @Input() inputControlName!: FormControl;
  @Input() className!: any;
  @Input() bsConfig: any = {};
  @Input() id: any;
  @Input() disabled = false;
  @Output() datePickerChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  @ViewChild('dp') dp!: ElementRef;
  isOpen = false;

  ngOnInit() {
    this.bsConfig['showWeekNumbers'] = false;
    this.bsConfig['containerClass'] = 'theme-default';
    this.bsConfig['adaptivePosition'] = 'true';
  }

  hideDatePicker() {
    this.isOpen = false;
  }
  onBlur(event: any) {
    this.blur.emit(event);
  }
  clear() {
    this.inputControlName.setValue(null);
  }
  onValueChange(event: any) {
    this.datePickerChange.emit({ data: event });
  }
}
