/* eslint-disable @typescript-eslint/member-ordering */
import { trigger, transition, style, animate } from '@angular/animations';
import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, TrackByFunction } from '@angular/core';
import { BarChartType, BaseChartComponent, ColorHelper, LegendOptions, LegendPosition, Orientation, ScaleType, Series, ViewDimensions, calculateViewDimensions } from '@swimlane/ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';

@Component({
  selector: 'so-bar-horizontal-stacked',
  templateUrl: './bar-horizontal-stacked.component.html',
  styleUrls: ['./bar-horizontal-stacked.component.scss'],
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})
export class BarHorizontalStackedComponent extends BaseChartComponent implements OnInit {
  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition: any = LegendPosition.Right;
  @Input() xAxis: any;
  @Input() yAxis: any;
  @Input() showXAxisLabel!: boolean;
  @Input() showYAxisLabel!: boolean;
  @Input() xAxisLabel!: string;
  @Input() yAxisLabel!: string;
  @Input() tooltipDisabled = false;
  @Input() gradient!: boolean;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() override schemeType!: ScaleType;
  @Input() trimXAxisTicks = true;
  @Input() trimYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks!: any[];
  @Input() yAxisTicks!: any[];
  @Input() barPadding = 8;
  @Input() roundDomains = false;
  @Input() xScaleMax!: number;
  @Input() showDataLabel = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero = true;
  @Input() wrapTicks = false;
  @Input() isProgressChart = false;
  @Input() chartData: any;
  @Input() showPercentage = true;
  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate!: TemplateRef<any>;

  dims!: ViewDimensions;
  groupDomain!: string[];
  innerDomain!: string[];
  valueDomain!: [number, number];
  xScale: any;
  yScale: any;
  transform!: string;
  colors!: ColorHelper;
  margin = [10, 20, 10, 20];
  xAxisHeight = 0;
  yAxisWidth = 0;
  legendOptions!: LegendOptions;
  dataLabelMaxWidth: any = { negative: 0, positive: 0 };

  barChartType = BarChartType;
  isSSR = false;
  @Input() heightBase: any;
  override update(): void {
    super.update();

    if (!this.showDataLabel) {
      this.dataLabelMaxWidth = { negative: 0, positive: 0 };
    }

    this.margin = [10, 20 + this.dataLabelMaxWidth.positive, 10, 20 + this.dataLabelMaxWidth.negative];

    this.dims = calculateViewDimensions({
      width: this.width,
      height: (this.heightBase - 30),
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    this.formatDates();

    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    this.transform = `translate(${this.dims.xOffset} , ${this.margin[0]})`;
  }

  getGroupDomain(): string[] {
    const domain: any[] = [];

    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }

    return domain;
  }

  getInnerDomain(): string[] {
    const domain: any[] = [];

    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label) && d.value > 0) {
          domain.push(d.label);
        }
      }
    }

    return domain;
  }

  getValueDomain(): [number, number] {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
      let smallestSum = 0;
      let biggestSum = 0;
      for (const d of group.series) {
        if (d.value < 0) {
          smallestSum += d.value;
        } else {
          biggestSum += d.value;
        }
        smallest = d.value < smallest ? d.value : smallest;
        biggest = d.value > biggest ? d.value : biggest;
      }
      domain.push(smallestSum);
      domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);

    const min = Math.min(0, ...domain);
    const max = this.xScaleMax ? Math.max(this.xScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }

  getYScale(): any {
    const spacing = this.groupDomain.length / (this.dims.height / this.barPadding + 1);

    return scaleBand().rangeRound([0, this.dims.height]).paddingInner(spacing).domain(this.groupDomain);
  }

  getXScale(): any {
    const scale = scaleLinear().range([0, this.dims.width]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  groupTransform(group: Series): string {
    return `translate(0, ${this.yScale(group.name)})`;
  }

  onClick(data: any, group?: Series): void {
    if (group) {
      data.series = group.name;
    }

    this.select.emit({ group: group, data: data });
  }

  trackBy: TrackByFunction<Series> = (index: number, item: Series) => {
    return item.name;
  };

  setColors(): void {
    let domain;
    if (this.schemeType === ScaleType.Ordinal) {
      domain = this.innerDomain;
    } else {
      domain = this.valueDomain;
    }
    domain = ['Planned', 'In Progress', 'Installed', 'Operational'];
    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions(): LegendOptions {
    const opts: any = {
      scaleType: this.schemeType as any,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === ScaleType.Ordinal) {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valueDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({ width }: { width: number }): void {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }: { height: number }): void {
    this.xAxisHeight = height;
    this.update();
  }

  onDataLabelMaxWidthChanged(event: any, groupIndex: number) {
    if (event.size.negative) {
      this.dataLabelMaxWidth.negative = Math.max(this.dataLabelMaxWidth.negative, event.size.width);
    } else {
      this.dataLabelMaxWidth.positive = Math.max(this.dataLabelMaxWidth.positive, event.size.width);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  onActivate(event: any, group: Series | undefined, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map((g: any) => g.series)
      .flat()
      .filter((i: any) => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: group });
  }

  onDeactivate(event: any, group: Series | undefined, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter(i => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }
  tickTransform(tick: any): string {
    const xaxis = this.xScale(tick.name) || 0;
    if (window.innerWidth > 1600) {
      return `translate(${xaxis + 20}, 230)`;
    }
    return `translate(${xaxis + 15}, 230)`;
  }
  formatXAxis(value: any) {
    return value + '%';
  }
  formatWithoutPercentageXAxis(value: any) {
    return value;
  }
  protected readonly BarChartType = BarChartType;
  protected readonly Orientation = Orientation;
}
