import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../../utils';
import { authStoreName } from '../../../constants';
const sessionsPolicyState = {
  loading: false,
  configurations: {}
};
const reducers = {
  setSessionsPolicyState: typeReducerForKey('sessionsPolicyState'),
  resetSessionsPolicyState: resetStateByKey('sessionsPolicyState', {
    sessionsPolicyState
  })
};
const actions = {
  loadSessionsPolicy: createAction(`${authStoreName}/loadSessionsPolicy`),
  createOrUpdateSessionsPolicy: createAction(`${authStoreName}/createOrUpdateSessionsPolicy`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { sessionsPolicyState, reducers as sessionsPolicyReducers, actions as sessionsPolicyActions };