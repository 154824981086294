/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Utilities } from '@SiteOwl/core';
import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TooltipModel } from '../tool-tip/tool-tip.model';
import moment from 'moment';
import b64toBlob from 'b64-to-blob';

@Component({
  selector: 'so-pixie',
  templateUrl: './pixie.component.html',
  styleUrls: ['./pixie.component.scss'],
})
export class PixieComponent implements OnInit, AfterViewInit, OnDestroy {

  pixie?: any;
  image: any;
  projectId?: any;
  isFromFloorPlan?: any;
  isFromCapture?: any;
  showImageEditorForBuilding?: any;
  siteId?: any;
  file?: any;
  caption: any;
  captionDetailsForm!: FormGroup;
  extension!: string;
  readyImage!: any;
  doNotHide: any;
  canResizePlanMarkup: any;
  public saveImage: EventEmitter<any> = new EventEmitter();
  public retakePicture: EventEmitter<any> = new EventEmitter();
  script: any;
  timer: any;
  updatedTime: any;
  createdTime: any;
  createdBy!: string;
  updatedBy!: string;
  toolTipObj: TooltipModel = new TooltipModel();
  isShowLoader!: boolean;
  constructor(private fb: FormBuilder,
    public bsModalRef: BsModalRef,
    private renderer: Renderer2) {

  }
  ngOnInit(): void {
    this.script = this.renderer.createElement('script');
    this.script.src = './assets/pixie.umd.js';
    this.script.type = 'text/javascript';
    this.renderer.appendChild(document.head, this.script);
    this.captionDetailsForm = this.fb.group({
      name: [null, [Validators.maxLength(80)]],
    });
    this.setFormValue();
    this.extension = 'jpg';
    if (this.image) {
      this.extension = this.getFileExtension(this.image);
      if (this.extension === undefined) {
        this.extension = 'jpg';
      } else if (this.extension === null) {
        this.extension = this.image.substring("data:image/".length, this.image.indexOf(";base64"))
      }
      this.preserveLogs(this.file)
    }
    document.addEventListener('closeWithoutSaveFromPixie', () => {
      this.bsModalRef.hide();
    })
    document.addEventListener('openExportNewImageCustomEvent', this.openExportNewImage.bind(this), { once: true })
    document.addEventListener('retakePicture', () => {
      this.retakePicture.emit();
      this.bsModalRef.hide();
    });
  }
  openExportNewImage = (data: any) => {
    this.isShowLoader = true;
    this.convertURIToImageData(data.detail).then((r: any) => {
      const blob = this.dataURItoBlob(r);
      const form = this.blobToFile(blob, `edit-image-${Math.round(Math.random() * 10000)}.${this.extension}`);
      const fileObject = {
        fileData: form,
        size: form.size,
        type: 'image/' + this.extension,
        name: Utilities.getCurrentDateTime() + '.' + this.extension,
        extension: '.' + this.extension
      };
      this.readyImage = {
        awsImageURL: r,
        fileData: fileObject,
        fileName: Utilities.getCurrentDateTime() + '.' + this.extension,
        fileLocation: Utilities.getCurrentDateTime() + '.' + this.extension,
        isDeleted: false,
        isNewFile: true,
        isEdited: true
      }
      this.saveImage.emit({ image: this.readyImage, captionDetails: this.captionDetailsForm.value });
      this.isShowLoader = false;
      if (!this.doNotHide) {
        this.removePixieScript();
        this.bsModalRef.hide();
      }
    });
  }
  ngAfterViewInit(): void {
    this.script.onload = () => {
      this.getBase64Image(this.image, (image: any) => {
        this.startPixie(image);
      })
    }
  }
  private startPixie(src: any): void {
    // @ts-ignore
    Pixie.init({
      selector: "#editor-container",
      baseUrl: 'assets',
      image: src,
      retakePicture: this.isFromCapture,
      isDeviceAvailable: !this.canResizePlanMarkup,
      isMobile: false,
      exportExtension: this.extension
    }).then((pixie: any) => {
      this.pixie = pixie;
    }).catch((err: any) => {
      console.log(err)
    })

  }
  private setFormValue() {
    this.captionDetailsForm.controls['name'].setValue(this.file.fileName);
  }
  closeModal() {
    this.removePixieScript();
    this.bsModalRef.hide();
  }
  getBase64Image(imgUrl: any, callback: any) {
    const img = new Image();
    // onload fires when the image is fully loadded, and has width and height

    img.onload = function () {
      const canvas = document.createElement("canvas");
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx: any = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL("image/png");
      callback(dataURL); // the base64 string
    };

    // set attributes and src
    img.setAttribute('crossOrigin', 'Anonymous'); //
    if (imgUrl.includes('data:image/')) {
      img.src = imgUrl
    } else {
      img.src = imgUrl //+ "?not-from-cache-please";
    }

  }
  getFileExtension(file: any) {
    const regexp = /\.([0-9a-z]+)(?:[\?#]|$)/i;
    const extension = file.match(regexp);
    return extension && extension[1];
  }
  private dataURItoBlob(dataURI: any) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    const blob = new Blob([ab], { type: mimeString });
    return blob;

  }

  private blobToFile(theBlob: any, fileName: any) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
  removePixieScript() {
    if (this.script) {
      this.renderer.removeChild(document.head, this.script)
      this.script = null;
    }
  }
  ngOnDestroy(): void {
    document.removeEventListener('openExportNewImageCustomEvent', this.openExportNewImage.bind(this))
    this.removePixieScript();
  }

  convertURIToImageData(URI: any) {
    return new Promise((resolve, reject) => {
      if (this.showImageEditorForBuilding) {
        resolve(URI);
      }
      if (URI == null) return reject();
      const canvas: any = document.createElement('canvas'),
        context = canvas.getContext('2d'),
        image = new Image();
      image.addEventListener('load', async () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, canvas.width, canvas.height);
        await this.convertBlob(canvas.toDataURL('image/jpeg'), 'image/jpeg').then(async (resBlob: any) => {
          await Utilities.getCompressedImage(resBlob, 3840, 2160).then((compressed: any) => {
            if (compressed !== undefined) {
              compressed = compressed.toString();
            }
            resolve(compressed);
          });
        });
      }, false);
      image.src = URI;
    });
  }
  fillToolTipObj(event: any, user: any) {
    this.timer = setTimeout(() => {
      this.toolTipObj.name = user.name;
      if (this.toolTipObj.name === null || this.toolTipObj.name === undefined) {
        this.toolTipObj.name = "(" + user.email + ")";
      }
      if (!user.providerId) {
        this.toolTipObj.employer = user.employer;
      } else {
        this.toolTipObj.provider = user.providerName;
      }
      this.toolTipObj.picture = user.thumbnailAwsImageURL;
      if (user.workNumber) {
        this.toolTipObj.work = user.workNumber;
      }
      if (user.cellNumber) {
        this.toolTipObj.mobile = user.cellNumber;
      }
      this.toolTipObj.email = user.email;
    }, 300);
    this.toolTipObj = new TooltipModel();

  }
  out() {
    clearTimeout(this.timer)
  }
  preserveLogs(image: any) {
    this.createdTime = !Utilities.isEmpty(image.createdDateTime) ?
      moment(new Date(image.createdDateTime)).format('MM/DD/YYYY') : '';
    this.updatedTime = !Utilities.isEmpty(image.modifiedDateTime) ?
      moment(new Date(image.modifiedDateTime)).format('MM/DD/YYYY') : '';
    this.createdBy = image.createdBy || '';
    this.updatedBy = image.updatedBy || '';
  }
  convertBlob(res: any, type: any) {
    return new Promise(resolve => {
      let blob;
      if (res) {
        const contentType = type;
        res = res.toString().replace(/data:image\/([a-z]*)?;base64,/, '');
        blob = (b64toBlob as any)(res, contentType);
        resolve(blob);
      }
    });
  }
}

