import { Get, Post } from "../../../fetch";
import { urls } from "../../../constants";
export async function createSubscription(request) {
  return Post(urls.subscriptions.billing.paymentProviders.stripe.subscriptions.v1, request);
}
export async function getStripeCustomer(tenantId) {
  return Get(`${urls.subscriptions.billing.paymentProviders.stripe.customers.v1}/${tenantId}`);
}
export async function createStripeCustomer(request) {
  return Post(`${urls.subscriptions.billing.paymentProviders.stripe.customers.v1}`, request);
}
export async function getStripePaymentProviderConfiguration() {
  return Get(`${urls.subscriptions.billing.paymentProviders.stripe.publicConfigurations.v1}`);
}
export async function createStripePaymentMethodSetupIntentSecret(request) {
  return Post(`${urls.subscriptions.billing.paymentProviders.stripe.setupIntents.v1}`, request);
}