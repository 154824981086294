import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"];
import { ContextHolder, api } from '@frontegg/rest-api';
import { call, put, takeLeading, select } from 'redux-saga/effects';
import { actions } from '../reducer';
import { delay } from '../utils';
import { mapMetaDataObjectToActions } from './utils';
import { errorHandler } from '../../utils';
import { getSearchParamsFromUrl } from '../LoginState/utils';
export function* loadTenantMetadata() {
  yield put(actions.setCustomLoginState({
    loading: true
  }));
  try {
    const tenantMetadata = yield call(api.metadata.getAdminBoxMetadata);
    yield put(actions.setCustomLoginState({
      tenantMetadata,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setCustomLoginState({
      loading: false,
      error: errorHandler(e)
    }));
  }
}
function* updateTenantMetadata(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    state = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  try {
    yield put(actions.setCustomLoginState({
      error: null,
      saving: true
    }));
    const requestActions = mapMetaDataObjectToActions(state);
    if (requestActions.length === 0) {
      throw new Error('No changes to update');
    }
    yield call(api.metadata.updateAdminBoxMetadata, {
      actions: requestActions
    });
    const tenantMetadata = yield call(api.metadata.getAdminBoxMetadata);
    yield put(actions.setCustomLoginState({
      tenantMetadata,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setCustomLoginState({
      error: errorHandler(e, 'No changes to update'),
      saving: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
export function* customLoginEnabled() {
  const customLoginAlias = yield call(getCustomLoginAlias);
  return !!customLoginAlias;
}
export function* getCustomLoginAlias() {
  const context = ContextHolder.getContext();
  if (!(context != null && context.tenantResolver)) {
    return;
  }
  const resolvedTenantResult = yield context.tenantResolver();
  if (resolvedTenantResult != null && resolvedTenantResult.tenant) {
    return resolvedTenantResult.tenant;
  }
  const {
    customLoginAlias,
    hasCustomLogin
  } = yield select(state => {
    var _state$auth, _state$auth$tenantsSt;
    const activeTenant = (_state$auth = state.auth) == null ? void 0 : (_state$auth$tenantsSt = _state$auth.tenantsState) == null ? void 0 : _state$auth$tenantsSt.activeTenant;
    return {
      customLoginAlias: activeTenant == null ? void 0 : activeTenant.alias,
      hasCustomLogin: activeTenant == null ? void 0 : activeTenant.hasCustomLogin
    };
  });
  if (hasCustomLogin) {
    return customLoginAlias;
  }
}
export function* loadCustomLoginRoutes() {
  try {
    var _getSearchParamsFromU;
    const isCustomLoginEnabled = yield call(customLoginEnabled);
    if (!isCustomLoginEnabled) {
      return;
    }
    const isAuthenticated = yield select(state => state.auth.isAuthenticated);
    const {
      getSettings,
      getPublicSettings
    } = api.accountSettings;
    const {
      applicationUrl
    } = yield call(isAuthenticated ? getSettings : getPublicSettings);
    if (!applicationUrl) {
      return;
    }
    const {
      routes
    } = yield select(state => state.auth);
    const searchParams = (_getSearchParamsFromU = getSearchParamsFromUrl(applicationUrl)) != null ? _getSearchParamsFromU : '';
    yield put(actions.setState({
      routes: _extends({}, routes, {
        customLoginAuthenticatedUrl: applicationUrl,
        customLoginUrl: routes.loginUrl + searchParams
      })
    }));
    yield put(actions.setCustomLoginState({
      customLoginSearchParams: searchParams != null ? searchParams : undefined
    }));
  } catch {}
}
export function* customLoginSagas() {
  yield takeLeading(actions.loadTenantMetadata, loadTenantMetadata);
  yield takeLeading(actions.updateTenantMetadata, updateTenantMetadata);
}

// /*********************************
//  *  Preview Sagas
//  *********************************/

function* loadCustomLoginStateMock() {
  yield put(actions.setCustomLoginState({
    loading: true
  }));
  yield delay();
  yield put(actions.setCustomLoginState({
    loading: false
  }));
}
function* updateCustomLoginMetadataMock(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setCustomLoginState({
    loading: true,
    error: null
  }));
  yield delay();
  yield put(actions.setCustomLoginState({
    loading: false,
    error: null
  }));
  callback == null ? void 0 : callback(true);
}
export function* customLoginSagasMock() {
  yield takeLeading(actions.loadTenantMetadata, loadCustomLoginStateMock);
  yield takeLeading(actions.updateTenantMetadata, updateCustomLoginMetadataMock);
}