import { accountType, CustomerService, MessageService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-license-account',
  templateUrl: './license-account.component.html',
  styleUrls: ['./license-account.component.scss'],
})
export class LicenseAccountComponent implements OnInit {

  accountForm!: FormGroup;
  customerId: any;
  customerName: any;
  showSpinner!: boolean;
  accountType = accountType;
  accountSelection: any;
  public save: EventEmitter<any> = new EventEmitter();
  constructor(
    private customerService: CustomerService,public bsModalRef: BsModalRef,
    private messageService: MessageService,) {}

  ngOnInit() {
    this.accountForm = new FormGroup({
      accountType: new FormControl(this.accountSelection, Validators.required)
    })
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  updateLicense() {
    this.customerService.setConfiguration(this.customerId, "accountType", this.accountForm.value.accountType).subscribe({
      next: () => {
        this.save.emit(this.accountForm.value.accountType);
        this.bsModalRef.hide();
        this.showSpinner = false;
        this.messageService.successMessage('Account: ' + this.customerName, 'Saved successfully');
      }
    });
  }
  accountChanged(item: any, eventType: any) {
    if (eventType === 'account') {
      this.accountSelection = item.accountTypeKey;
      this.accountForm.value.accountType = item.accountTypeKey;
      this.accountForm.controls['accountType'].setValue(this.accountType.find(x => x.id === item.id), { onlySelf: true });
    }
  }
}

