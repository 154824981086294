import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const profileState = {
  loading: false,
  error: null
};
const reducers = {
  setProfileState: typeReducerForKey('profileState'),
  resetProfileState: resetStateByKey('profileState', {
    profileState
  })
};
const actions = {
  loadProfile: createAction(`${authStoreName}/loadProfile`),
  saveProfile: createAction(`${authStoreName}/saveProfile`, payload => ({
    payload
  })),
  changePassword: createAction(`${authStoreName}/changePassword`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { profileState, reducers as profileReducers, actions as profileActions };