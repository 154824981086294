const isFronteggApiError = error => Boolean(error == null ? void 0 : error.statusCode);
export const isError = error => {
  if (error instanceof Error) {
    return true;
  }

  //using 'instanceof Error' wont work if the error is thrown in different window/frame/iframe than where the check is happening https://stackoverflow.com/a/30469297
  return (error == null ? void 0 : error.message) && (error == null ? void 0 : error.stack) && (error == null ? void 0 : error.name) === 'Error';
};
const GENERIC_ERROR_MESSAGE = `We're facing some difficulties, Please try again`;
export const errorHandler = (error, fallback) => {
  if (!error) {
    return fallback;
  }
  if (isFronteggApiError(error)) {
    return error.message;
  }
  if (isError(error)) {
    console.error(error.message);
  } else if (typeof error === 'string') {
    console.error(error);
  }
  return fallback != null ? fallback : GENERIC_ERROR_MESSAGE;
};