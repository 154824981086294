"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.planTargetingRulesEvalutor = void 0;
const types_1 = require("../../types");
const attributes_utils_1 = require("../../utils/attributes.utils");
const rules_1 = require("../../../rules");
const plans_1 = require("../../../plans");
function planTargetingRulesEvalutor(featureKey, userEntitlementsContext, attributes = {}) {
  const feature = userEntitlementsContext.features[featureKey];
  if (feature && feature.planIds && feature.planIds.length > 0) {
    const preparedAttributes = (0, attributes_utils_1.prepareAttributes)(attributes);
    const plans = userEntitlementsContext.plans;
    for (const planId of feature.planIds) {
      const plan = plans[planId];
      if (plan) {
        const {
          treatment
        } = (0, plans_1.evaluatePlan)(plan, preparedAttributes);
        if (treatment === rules_1.TreatmentEnum.True) {
          return {
            isEntitled: true
          };
        }
      }
    }
  }
  return {
    isEntitled: false,
    justification: types_1.NotEntitledJustification.MISSING_FEATURE
  };
}
exports.planTargetingRulesEvalutor = planTargetingRulesEvalutor;
