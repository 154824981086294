import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'so-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
  @Input() pageloader!: boolean
  @Input() tableloader!: boolean
  @Input() smallloader!: boolean
  @Input() mainpageloader!: boolean
  @Input() xsloader!: boolean
  @Input() downloadloader!: boolean
  @Input() fullpageloader!: boolean
  @Input() pixieLoader!: boolean

 
}