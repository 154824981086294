import { takeLeading } from 'redux-saga/effects';
import { actions } from '../reducer';
import { mfaWithAuthenticator } from '../LoginState/saga';
import { preVerifyMFASMS, verifyMFASMS } from '../LoginState/sagas/mfaWithSMS.saga';
import { preVerifyMFAWebAuthn, verifyMFAWebAuthn } from '../LoginState/sagas/mfaWithWebAuthn.saga';
import { verifyMFAEmailCode, preVerifyMFAEmailCode } from '../LoginState/sagas/mfaWithEmailCode.saga';
import { generateStepUpSession } from './generateStepUpSession.saga';
import { stepUpHostedLogin } from './stepUpHostedLogin.saga';

/**
 * Step up with authenticator app
 * @param payload.callback - callback function to be called after the verification is done
 * @param payload.mfaToken
 * @param payload.value - 6 digits code input by the user
 */
export function* stepUpWithAuthenticator({
  payload
}) {
  yield mfaWithAuthenticator(payload, actions.setStepUpState, true);
}

/**
 * Pre verify step for MFA Webauthn step up
 * @param payload.callback - callback function to be called after the verification is done
 * @param payload.deviceId
 * @param payload.mfaToken
 */
export function* preVerifyMFAWebAuthnForStepUp({
  payload
}) {
  yield preVerifyMFAWebAuthn(payload, actions.setStepUpState);
}

/**
 * Verify step for MFA Webauthn step up
 * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.webauthnToken
 * @param payload.mfaToken
 * @param payload.publicKey - public key object from browser navigator credentials
 * @param payload.code - 6 digits code input by the user
 */
export function* verifyMFAWebAuthnForStepUp({
  payload
}) {
  yield verifyMFAWebAuthn(payload, actions.setStepUpState, true);
}

/**
 * Pre verify step for MFA SMS step up
 * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.mfaToken
 */
export function* preVerifyMFASMSForStepUp({
  payload
}) {
  yield preVerifyMFASMS(payload, actions.setStepUpState);
}

/**
 * Verify step for MFA SMS step up
 * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.otcToken
 * @param payload.code - 6 digits code input by the user
 */
export function* verifyMFASMSForStepUp({
  payload
}) {
  yield verifyMFASMS(payload, actions.setStepUpState, true);
}

/**
 * Verify step for MFA Email code step up
 * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
 * @param payload.otcToken
 * @param payload.code - 6 digits code input by the user
 */
export function* verifyMFAEmailCodeForStepUp({
  payload
}) {
  yield verifyMFAEmailCode(payload, actions.setStepUpState);
}

/**
 * Pre verify step for MFA Email code step up
 * @param payload.callback - callback function to be called after the verification is done with true for success, o.w false
 * @param payload.mfaToken
 */
export function* preVerifyMFAEmailCodeForStepUp({
  payload
}) {
  yield preVerifyMFAEmailCode(payload, actions.setStepUpState);
}
export function* stepUpSagas() {
  yield takeLeading(actions.stepUpHostedLogin, stepUpHostedLogin);
  yield takeLeading(actions.generateStepUpSession, generateStepUpSession);
  yield takeLeading(actions.stepUpWithAuthenticator, stepUpWithAuthenticator);
  yield takeLeading(actions.preVerifyMFASMSForStepUp, preVerifyMFASMSForStepUp);
  yield takeLeading(actions.verifyMFASMSForStepUp, verifyMFASMSForStepUp);
  yield takeLeading(actions.preVerifyMFAWebAuthnForStepUp, preVerifyMFAWebAuthnForStepUp);
  yield takeLeading(actions.verifyMFAWebAuthnForStepUp, verifyMFAWebAuthnForStepUp);
  yield takeLeading(actions.verifyMFAEmailCodeForStepUp, verifyMFAEmailCodeForStepUp);
  yield takeLeading(actions.preVerifyMFAEmailCodeForStepUp, preVerifyMFAEmailCodeForStepUp);
}