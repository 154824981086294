import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["id"];
import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { errorHandler } from '../../utils';
function* loadCountForConnection(connection) {
  const {
    count
  } = yield call(api.directory.countConfigurationUsers, connection.id);
  return _extends({}, connection, {
    count
  });
}
function* loadScim2Connections() {
  yield put(actions.setProvisioningState({
    loading: true,
    error: null
  }));
  try {
    const connections = yield call(api.directory.getConfigs);
    const connectionsWithCount = yield all(connections.map(connection => loadCountForConnection(connection)));
    yield put(actions.setProvisioningState({
      connections: connectionsWithCount,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setProvisioningState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* createScim2Connection(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    newConnectionBody = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setProvisioningState({
    loading: true,
    error: null
  }));
  try {
    const newConnectionResponse = yield call(api.directory.createConfiguration, newConnectionBody);
    const connections = yield call(api.directory.getConfigs);
    const connectionsWithCount = yield all(connections.map(connection => loadCountForConnection(connection)));
    yield put(actions.setProvisioningState({
      connections: connectionsWithCount,
      loading: false
    }));
    callback == null ? void 0 : callback(newConnectionResponse);
  } catch (e) {
    yield put(actions.setProvisioningState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* updateScim2Connection(_ref2) {
  let {
      payload: {
        id
      }
    } = _ref2,
    updateRequest = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setProvisioningState({
    loading: true,
    error: null
  }));
  try {
    yield call(api.directory.updateConfiguration, id, updateRequest);
    const connections = yield call(api.directory.getConfigs);
    const connectionsWithCount = yield all(connections.map(connection => loadCountForConnection(connection)));
    yield put(actions.setProvisioningState({
      connections: connectionsWithCount,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setProvisioningState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* deleteScim2Connection({
  payload: {
    id,
    callback
  }
}) {
  yield put(actions.setProvisioningState({
    loading: true,
    error: null
  }));
  try {
    yield call(api.directory.deleteConfiguration, id);
    const connections = yield call(api.directory.getConfigs);
    const connectionsWithCount = yield all(connections.map(connection => loadCountForConnection(connection)));
    yield put(actions.setProvisioningState({
      connections: connectionsWithCount,
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setProvisioningState({
      error: errorHandler(e),
      loading: false
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
export function* provisionSagas() {
  yield takeLeading(actions.loadProvisionConnections, loadScim2Connections);
  yield takeEvery(actions.createProvisionConnection, createScim2Connection);
  yield takeEvery(actions.deleteProvisionConnection, deleteScim2Connection);
  yield takeEvery(actions.updateProvisionConnection, updateScim2Connection);
}