/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input } from '@angular/core';
import { CommonService, Environment, ListService, Utilities } from '@SiteOwl/core';
import { TooltipModel } from './tool-tip.model';

@Component({
  selector: 'so-tool-tip',
  templateUrl: './tool-tip.component.html',
  styleUrls: ['./tool-tip.component.scss'],
})
export class ToolTipComponent {

  @Input() toolTipObj!: TooltipModel;
  @Input() isRoleToolTip!: boolean;
  @Input() displayRight!: boolean;
  @Input() displayLeft!: boolean;
  @Input() displayTop!: boolean;
  @Input() isCustomerToolTip!: boolean;
  @Input() isAdminToolTip!: boolean;
  @Input() noLiveSystemAccess!: boolean;
  @Input() customMessage!: boolean;
  @Input() defaultAdminTooltip!: boolean;
  @Input() customConnectionToolTip!: any;
  displayLeftTooltip = false;
  cdnUrl = this.env.cdnUrl;
  adminRole: any;
  constructor(private readonly env: Environment, private listService: ListService, private commonService: CommonService) {
    this.getAdminRole()
  }
  removePos(e: any) {
    e.stopPropagation();
  }

  getAdminRole() {
    this.adminRole = this.commonService.getLocalStorageObject('adminRole');
    if (this.adminRole === undefined || this.adminRole === null) {
      this.listService.getAdminSpecificRoleList().subscribe({
        next: ((r: any) => {
          this.adminRole = Utilities.seperateRoleDescription(r);
          this.commonService.setLocalStorageObject('adminRole', this.adminRole);
        })
      })
    }
  }
}

