<div class="table-responsive custom-table overflow-visible" [class]="className" [id]="id ? id : ''" [ngStyle]="style">
    <table aria-describedby="" class="table">
        <thead>
            <tr>
                <ng-container *ngFor="let header of headerData">
                    <th *ngIf="header?.isDisplay" [style]="header?.styles" [class]="header?.className">
                        {{header.label}}
                    </th>
                </ng-container>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let item of records;let i = index">
                <tr [style]="item?.style">
                    <ng-container *ngFor="let header of headerData">
                        <td *ngIf="header?.isDisplay && header?.dataType==='TEXTCLICKABLEWITHINFO'"
                            [style]="header?.styles" [class]="header?.className">
                            <div class="custom-tooltip">
                                <a *ngIf="item[header.link]"
                                    (mouseout)="_actionClick($event,'TEXTCLICKABLEWITHINFO',item)"
                                    (mouseover)="_actionClick($event,'TEXTCLICKABLEWITHINFO',item)"
                                    (click)="_actionClick($event,'TEXTCLICKABLEWITHINFO',item)"> {{item[header.value]
                                    === "" || item[header.value] === null ? '(' +
                                    item[header.alternateKey] + ')' : item[header.value]}}
                                </a>
                                <span *ngIf="!item[header.link]"
                                    (mouseout)="_actionClick($event,'TEXTCLICKABLEWITHINFO',item)"
                                    (mouseover)="_actionClick($event,'TEXTCLICKABLEWITHINFO',item)">
                                    {{item[header.value] === "" || item[header.value] === null ? '(' +
                                    item[header.alternateKey] + ')' : item[header.value]}}
                                </span>
                                <so-tool-tip class="text-start" [toolTipObj]="toolTipObj"></so-tool-tip>
                            </div>
                        </td>
                        <!-- <td *ngIf="header?.isDisplay && header?.dataType==='TEXTCLICKABLEWITHINFO'" 
                            [style]="header?.styles" [class]="header?.className">
                            <a> {{item[header.value]}}</a>
                        </td> -->
                        <td *ngIf="header?.isDisplay && header?.dataType==='TEXTTITLE'" [style]="header?.styles"
                            [class]="header?.className" title="{{item[header.value]}}">
                            {{item[header.value] === "" || item[header.value] ===
                            null ? item[header.alternateKey] : item[header.value]}}
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='TEXT'" [style]="header?.styles"
                            [class]="header?.className">
                            {{item[header.value] === "" || item[header.value] === null ? item[header.alternateKey] :
                            item[header.value]}}
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='ROLE'" [style]="header?.styles"
                            [class]="header?.className">
                            {{item[header.value]?.name === 'View All'?'View Only': item[header.value]?.name}}
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='ROLEINFOEDIT'" [style]="header?.styles"
                            [class]="header?.className">
                            <div class="d-flex justify-content-between">
                                <div>
                                    {{item[header.value]?.name === 'View All'?'View Only':
                                    item[header.value]?.name}}
                                </div>
                                <div>
                                    <em class="fas fa-info-circle custom-tooltip role-tooltip-icon administrator-info-icon"
                                        (mouseout)="_actionClick($event,'ROLEINFOEDIT',item)"
                                        (mouseover)="_actionClick($event,'ROLEINFOEDIT',item, header?.type)">
                                        <so-tool-tip class="text-start" [isRoleToolTip]="true"
                                            [toolTipObj]="toolRoleTipObj"></so-tool-tip>
                                    </em>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='ROLEWITHINFOEDIT'" [style]="header?.styles"
                            [class]="header?.className">
                            <div class="d-flex justify-content-between">
                                <div>
                                    {{item[header.value]?.name === 'View All'?'View Only': item[header.value]?.name}}
                                </div>
                                <div>
                                    <em class="far fa-info-circle custom-tooltip role-tooltip-icon"
                                        (mouseout)="_actionClick($event, 'ROLEWITHINFOEDIT',item)"
                                        (mouseover)="_actionClick($event, 'ROLEWITHINFOEDIT',item, header?.type)">
                                        <so-tool-tip class="text-start" [isRoleToolTip]="true"
                                            [toolTipObj]="toolRoleTipObj"></so-tool-tip>
                                    </em>

                                    <a class="btn-edit-cust" href="javascript:void(0);" *ngIf="item[header.showEdit]"
                                        (click)="_actionClick($event,'ROLEWITHINFOEDIT',item, header)">
                                        <em class="fas fa-edit"></em>
                                    </a>
                                </div>
                            </div>
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='DATETIME'" [style]="header?.styles"
                            [class]="header?.className">
                            <span title="{{item[header.value] | date: header?.dateFormat}}"> {{item[header.value] |
                                date: header?.dateFormat}}</span>
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='EDIT'" [style]="header?.styles"
                            [class]="header?.className">
                            <a class="btn-edit-role" href="javascript:void(0);"
                                (click)="_actionClick($event,'EDIT',item)">
                                <em class="fas fa-edit"></em>
                            </a>
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='CHECKBOX'" [style]="header?.styles"
                            [class]="header?.className">
                            <so-checkbox *ngIf="item[header.hideCheckbox];else hideCheckbox"
                                [id]="'checkbox_'+ id +(i+1)" [isChecked]="item[header.defaultChecked]"
                                [className]="'lbl-check'" (onChange)="_actionClick($event,'CHECKBOX',item)"
                                [disabled]="item[header.disableCheckbox]"
                                [title]="item[header.disableCheckbox] ? header?.title : '' ">
                            </so-checkbox>
                            <ng-template #hideCheckbox>
                                <so-checkbox [id]="'checkbox_'+ id +(i+1)" [isChecked]="item[header.defaultChecked]"
                                    [className]="'lbl-check'" (onChange)="_actionClick($event,'CHECKBOX',item)"
                                    [disabled]="item[header.disableCheckbox]"
                                    [title]="item[header.disableCheckbox] ? header?.title : '' ">
                                </so-checkbox>
                            </ng-template>
                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='CHECKBOXINFO'" [style]="header?.styles"
                            [class]="header?.className">

                            <so-checkbox [id]="'checkbox_info_'+ id +(i+1)" [isChecked]="item[header.defaultChecked]"
                                [className]="'lbl-check'" (onChange)="_actionClick($event,'CHECKBOXINFO',item)">
                            </so-checkbox>

                            <em class="fas fa-info-circle custom-tooltip role-tooltip-icon administrator-info-icon"
                                (mouseout)="_actionClick($event,'CHECKBOXINFO',item)"
                                (mouseover)="_actionClick($event,'CHECKBOXINFO',item,header?.type)">
                                <so-tool-tip class="text-start" [isAdminToolTip]="true"
                                    [toolTipObj]="toolRoleTipObj"></so-tool-tip>
                            </em>

                        </td>
                        <td *ngIf="header?.isDisplay && header?.dataType==='DELETE'" [style]="header?.styles"
                            [class]="header?.className">
                            <a *ngIf="item[header.showDelete]" class="" href="javascript:void(0);"
                                (click)="_actionClick($event,'DELETE',item)">
                                <em [class]="header.iconClass"></em>
                            </a>
                            <!-- <so-button [id]="'btn_'" [className]="'btn-remove'"></so-button> -->
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
            <tr *ngIf="records?.length === 0">
                <td [attr.colspan]="headerData?.length">
                    <span>{{noRecordsMessage}}</span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="row mrg-t-15">
    <div class="col-xl-12 text-end" *ngIf="records?.length > 0 && showPagination">
        <div class="row">
            <div class="col-sm-6 d-flex align-self-center">
                <ol class="breadcrumb">
                    <li>
                        <div class="page-sorting" *ngIf="records?.length > 0">
                            Showing {{showResult.start}}-{{showResult.end}} of {{showResult.total}} results
                        </div>
                    </li>
                    <li>
                        <select class="form-control" [(ngModel)]="searchParams.pageSize"
                            (change)="_actionClick($event,'pageSizeChanged',searchParams.pageSize)">
                            <option *ngFor="let itemPageSize of pageSizes" [ngValue]="itemPageSize">{{itemPageSize}} per
                                page</option>
                        </select>
                    </li>
                </ol>
            </div>
            <div class="col-sm-6">
                <div *ngIf="records?.length > 0">
                    <pagination [totalItems]="showResult.total" [(ngModel)]="searchParams.page" [maxSize]="5"
                        class="mrg-0" [boundaryLinks]="true" (pageChanged)="pageChanged($event)"
                        [itemsPerPage]="searchParams.pageSize" [previousText]="'Back'" firstText="&laquo;"
                        lastText="&raquo;"></pagination>
                </div>
            </div>
        </div>

    </div>
</div>