import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["roleId"],
  _excluded2 = ["roleId"],
  _excluded3 = ["permissionId"];
import { Delete, Get, Patch, Post, Put } from '../fetch';
import { urls } from '../constants';
export async function getRoles() {
  return Get(urls.identity.roles.v1);
}
export async function addRole(body) {
  return Post(urls.identity.roles.v1, body);
}
export async function deleteRole({
  roleId
}) {
  return Delete(`${urls.identity.roles.v1}/${roleId}`);
}
export async function updateRole(_ref) {
  let {
      roleId
    } = _ref,
    body = _objectWithoutPropertiesLoose(_ref, _excluded);
  return Patch(`${urls.identity.roles.v1}/${roleId}`, body);
}
export async function attachPermissionsToRole(_ref2) {
  let {
      roleId
    } = _ref2,
    body = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  return Put(`${urls.identity.roles.v1}/${roleId}/permissions`, body);
}
export async function getPermissions() {
  return Get(urls.identity.permissions.v1);
}
export async function attachPermissionToRoles(_ref3) {
  let {
      permissionId
    } = _ref3,
    body = _objectWithoutPropertiesLoose(_ref3, _excluded3);
  return Put(`${urls.identity.permissions.v1}/${permissionId}/roles`, body);
}
export async function getPermissionCategories() {
  return Get(`${urls.identity.permissions.v1}/categories`);
}