import { combineReducers } from '@reduxjs/toolkit';
import { subscriptionsStoreName } from '../constants';
import { plansActions, plansInitialState, plansReducer } from './Plans';
import { configActions, configInitialState, configReducer } from './Config';
import { billingActions, billingInitialState, billingReducer } from './Billing';
import { checkoutActions, checkoutInitialState, checkoutReducer } from './Checkout';
import { initialSubscriptionStripeState, stripeActions, stripeReducer } from './Stripe';
import { vendorPublicConfigActions, vendorPublicConfigInitialState, vendorPublicConfigReducer } from './VendorPublicConfig';
export const initialState = {
  config: configInitialState,
  plans: plansInitialState,
  checkout: checkoutInitialState,
  billing: billingInitialState,
  stripe: initialSubscriptionStripeState,
  vendorPublicConfig: vendorPublicConfigInitialState
};
const actions = {
  config: configActions,
  billing: billingActions,
  plans: plansActions,
  checkout: checkoutActions,
  stripe: stripeActions,
  vendorPublicConfig: vendorPublicConfigActions
};
const reducer = combineReducers({
  config: configReducer,
  billing: billingReducer,
  plans: plansReducer,
  checkout: checkoutReducer,
  stripe: stripeReducer,
  vendorPublicConfig: vendorPublicConfigReducer
});
export { subscriptionsStoreName as name, reducer, actions };