import _extends from "@babel/runtime/helpers/esm/extends";
export function createModuleCaseReducers() {
  return {
    setLoading: {
      prepare: payload => ({
        payload
      }),
      reducer: (state, action) => _extends({}, state, {
        loading: action.payload
      }, action.payload ? {
        error: null
      } : {})
    },
    setError: {
      prepare: payload => ({
        payload
      }),
      reducer: (state, action) => _extends({}, state, {
        error: action.payload,
        loading: false,
        fetching: false
      })
    },
    setState: {
      prepare: payload => ({
        payload
      }),
      reducer: (state, action) => _extends({}, state, action.payload)
    }
  };
}
export function createKeyCaseReducer(key, setState) {
  return {
    prepare: payload => ({
      payload
    }),
    reducer: (state, action) => _extends({}, state, {
      [key]: setState(state[key], action)
    })
  };
}
export function createKeyCaseLoadingReducer(key) {
  return createKeyCaseReducer(key, (state, action) => _extends({}, state, {
    loading: action.payload
  }));
}
export function createKeyCaseErrorReducer(key) {
  return createKeyCaseReducer(key, (state, action) => _extends({}, state, {
    error: action.payload,
    loading: false
  }));
}