<div class="modal-header d-flex align-items-center" (click)="$event.stopPropagation();">
    <ul class="modal-breadcrumb d-flex align-items-center">
        <li>
            <h1 class="modal-title pull-left">Take Picture</h1>
        </li>
    </ul>
    <button type="button" class="close justify-content-end" aria-label="Close" (click)="dismiss()">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="">
    <div class="modal-body d-flex align-items-center">
        <div class="edit-upload-img">
            <div class="upload-main-img">
                <div class="div-table">
                    <div class="div-table-cell">
                        <span class="text-center messageFromCamera"
                            [ngClass]="{'elementHide': isOpen !== 'default'}">{{errorText.error}} :
                            {{errorText.message}}</span>
                        <video #cameraStream id="camera-stream"
                            [ngClass]="{'elementHide': isOpen !== 'camera'}"></video>
                        <img #cameraImage src="" alt="" [ngClass]="{'elementHide': isOpen !== 'image'}"
                            class="fallback img-responsive center-block elementHide">
                        <img #cameraImage src="" alt="" [ngClass]="{'elementHide': isOpen !== 'defaultImage'}"
                            class="fallback img-responsive center-block elementHide">
                        <canvas #cameraCanvas hidden></canvas>
                        <input type="file" accept="image/*" capture="camera" #fileUpload style="display: none"
                            (change)="onchange($event)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-footer">
    <div class="d-flex justify-content-end">
        <button class="btn btn-secondary" (click)="dismiss()"> Cancel </button>
        <button class="btn btn-primary" (click)="takePicture($event)"> Take Picture </button>
    </div>
</div>