<form [formGroup]="providerForm" novalidate>
  <div class="modal-header">
    <h1 *ngIf="!isSecurityTeam" class="modal-title pull-left">
      {{isEditMode ? (isProjectRole?'Edit Project Role':'Edit Live Site Role') : 'Invite'}} {{ isFromSettingPage ?
      'Integrator' :'Headquarters'}}</h1>
    <h1 *ngIf="isSecurityTeam" class="modal-title pull-left">
      {{isEditMode ? (isProjectRole?'Edit Project Role':'Edit Live Site Role') : 'Invite'}} Security Team</h1>
    <button (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
      <i class="fas fa-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group" *ngIf="!isEditMode">
      <label *ngIf="!isSecurityTeam">{{ isFromSettingPage ? 'Integrator' : 'Headquarters'}}
        <sup>*</sup>
      </label>
      <label *ngIf="isSecurityTeam">Security Team
        <sup>*</sup>
      </label>
      <so-dropdown [id]="'providerUser'" [options]="providers" [value]="'name'" [key]="'id'"
        [inputControlName]="$any(providerForm).controls['provider']"
        (onChange)="providerChange(providerForm.controls.provider.value)">
      </so-dropdown>
      <so-control-messages *ngIf="isSecurityTeam" [name]="'Security Team'"
        [isFormSubmitted]="isFormSubmitted" [control]="$any(providerForm).controls.provider"></so-control-messages>
      <so-control-messages *ngIf="isFromSettingPage && !isSecurityTeam" [name]="'Integrator'"
        [isFormSubmitted]="isFormSubmitted" [control]="$any(providerForm).controls.provider"></so-control-messages>
      <so-control-messages *ngIf="!isFromSettingPage  && !isSecurityTeam" [name]="'Headquarters'"
        [isFormSubmitted]="isFormSubmitted" [control]="$any(providerForm).controls.provider"></so-control-messages>
    </div>

    <div class="form-group" *ngIf="!isEditMode">
      <label>Email Address
      </label>
      <p class="label-message" *ngIf="!isSecurityTeam && providerForm.controls.provider.value === ''">No
        {{isFromSettingPage ?'Integrator'
        :'Headquarters'}} selected.</p>
      <p class="label-message" *ngIf="isSecurityTeam && providerForm.controls.provider.value === ''">No
        Security Team selected.</p>
      <p class="label-message"
        *ngIf="!isSecurityTeam && providerForm.controls.provider.value !== '' && providerDetail?.email  === null">No
        {{isFromSettingPage ?'Integrator'
        :'Headquarters'}} email available.
      </p>
      <p class="label-message"
        *ngIf="isSecurityTeam && providerForm.controls.provider.value !== '' && providerDetail?.email  === null">No
        Security Team email available.
      </p>
      <p class="mrg-0" *ngIf="providerDetail?.email !== null && providerForm.controls.provider.value !== ''">
        {{providerDetail?.email}}</p>
    </div>
    <div class="form-group" *ngIf="!isEditMode">
      <label>Phone Number
      </label>
      <p class="label-message" *ngIf="!isSecurityTeam && providerForm.controls.provider.value === ''">No
        {{isFromSettingPage ?'Integrator'
        :'Headquarters'}} selected.</p>
      <p class="label-message" *ngIf="isSecurityTeam && providerForm.controls.provider.value === ''">No
        Security Team selected.</p>
      <p class="label-message"
        *ngIf="!isSecurityTeam && providerForm.controls.provider.value !== '' && providerDetail?.phoneNumber === null">
        No
        {{isFromSettingPage ?'Integrator'
        :'Headquarters'}}
        phone
        number available.</p>
      <p class="label-message"
        *ngIf="isSecurityTeam && providerForm.controls.provider.value !== '' && providerDetail?.phoneNumber === null">
        No Security Team phone number available.</p>
      <p class="mrg-0" *ngIf="providerDetail?.phoneNumber !== null && providerForm.controls.provider.value !== ''">
        {{providerDetail?.phoneNumber}}</p>
    </div>
    <span *ngIf="isEditMode">
      <div *ngIf="userCount > 0 ;else textOnly">
        <div class="checkbox-label">
          <so-checkbox [isChecked]="isSelected" (onChange)="changeStatus()" [id]="'chkcust'" [ngClass]="'lbl-check'">
          </so-checkbox>
        </div>
        <span>Also demote
          <strong>{{userCount}} </strong> existing {{userCount === 1 ? 'user':'users'}} working for
          <strong>{{providerDetail.name}}</strong> to
          <strong>{{isProjectRole? tempProviderProjectName : tempProviderAMTName}}.</strong>
        </span>
      </div>
      <ng-template #textOnly>
        <span>All users working for
          <strong>{{providerDetail.name}}</strong> have
          <strong>{{isProjectRole? tempProviderProjectName : tempProviderAMTName}}
          </strong> access or below.</span>
      </ng-template>
    </span>
  </div>
  <div class="modal-footer">
    <so-button (onClick)="closeModal()" [className]="'btn btn-secondary'" [text]="'Cancel'"></so-button>
    <so-button [type]="'submit'" (onClick)="saveProvider()" [disabled]="btnService.getStatus()"
      [style]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}" [className]="'btn btn-primary m-0'"
      [text]="isEditMode ? 'Update Role' : 'Invite'">
    </so-button>
  </div>
</form>