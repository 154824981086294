<ngx-charts-chart [view]="[width, height]" [showLegend]="legend" [legendOptions]="legendOptions" [activeEntries]="activeEntries" [animations]="animations" (legendLabelClick)="onClick($event)" (legendLabelActivate)="onActivate($event, true)" (legendLabelDeactivate)="onDeactivate($event, true)">
    <svg:g [attr.transform]="transform" class="bar-chart chart">
        <svg:g ngx-charts-x-axis *ngIf="xAxis" [xScale]="xScale" [dims]="dims" [showGridLines]="showGridLines" [showLabel]="showXAxisLabel" [labelText]="xAxisLabel" [trimTicks]="trimXAxisTicks" [rotateTicks]="rotateXAxisTicks" [maxTickLength]="maxXAxisTickLength"
            [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks" (dimensionsChanged)="updateXAxisHeight($event)" [xOrient]="Orientation.Bottom"></svg:g>

        <svg:g ngx-charts-y-axis *ngIf="yAxis" [yScale]="yScale" [dims]="dims" [showLabel]="showYAxisLabel" [labelText]="yAxisLabel" [trimTicks]="trimYAxisTicks" [maxTickLength]="maxYAxisTickLength" [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks"
            [yAxisOffset]="dataLabelMaxWidth.negative" (dimensionsChanged)="updateYAxisWidth($event)" (yAxisTick)="yAxisTickClick($event)"></svg:g>
        <svg:g ngx-charts-series-horizontal [xScale]="xScale" [yScale]="yScale" [colors]="colors" [series]="results" [dims]="dims" [gradient]="gradient" [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate" [activeEntries]="activeEntries" [roundEdges]="false"
            [animations]="animations" [showDataLabel]="showDataLabel" [dataLabelFormatting]="dataLabelFormatting" [noBarWhenZero]="noBarWhenZero" (select)="onClick($event)" (activate)="onActivate($event)" (deactivate)="onDeactivate($event)" (dataLabelWidthChanged)="onDataLabelMaxWidthChanged($event)"></svg:g>
    </svg:g>
</ngx-charts-chart>