import { deviceBelongTo } from "./common.constant";

export const deviceFields = [
  {
    key: "parentName",
    label: "Primary Device Name",
    sortName: "parentName",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "name",
    label: "Device Name",
    sortName: "name",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.all,
    isEditable: true,
    isDisplay: true
  },
  {
    key: "abbreviatedName",
    label: "Abbreviated Name",
    sortName: "abbreviatedName",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.all,
    isEditable: true,
    isDisplay: true
  },
  {
    key: "type",
    label: "Device / Task",
    sortName: "type",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "categoryName",
    label: "System Type",
    sortName: "equipment.category.name",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "typeName",
    label: "Device Type",
    sortName: "equipment.name",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "partNumber",
    sortName: "partNumber",
    label: "Part Number",
    maxLength: 80,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "manufacturer",
    label: "Manufacturer",
    sortName: "manufacturer",
    maxLength: 80,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "installHours",
    label: "Budgeted Hours",
    sortName: "installHours",
    maxLength: 5,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "budgetedCost",
    label: "Budgeted Cost",
    sortName: "budgetedCost",
    maxLength: 15,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "assignedToUser",
    label: "Assigned To",
    sortName: "assignedTo",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "dueDate",
    label: "Date Due",
    sortName: "dueDate",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "installedByUser",
    label: "Installed By",
    sortName: "installedBy",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "installationDate",
    label: "Date Installed",
    sortName: "installationDate",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "priority",
    label: "Priority",
    sortName: "priority",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "installStatus",
    label: "Install Status",
    sortName: "installStatus",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "priority",
    label: "Priority",
    sortName: "priority",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.amt,
    isDisplay: true
  },
  {
    key: "status",
    label: "Operational Status",
    sortName: "status",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "monitoredStatus",
    label: "Monitored Status",
    sortName: "monitoredStatus",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.amt,
    isDisplay: true
  },
  {
    key: "autoUpdateDeviceStatus",
    label: "Auto-Update Operational Status",
    sortName: "autoUpdateDeviceStatus",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.amt,
    isDisplay: true
  },
  {
    key: "images",
    label: "Images",
    sortName: "images",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "uniqueId",
    label: "Unique ID",
    sortName: "uniqueId",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.csv,
    isDisplay: true
  },
  {
    key: "serialNumber",
    label: "Serial Number",
    sortName: "serialNumber",
    maxLength: 80,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "ipAddress",
    label: "IP Address",
    sortName: "ipAddress",
    maxLength: 80,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "macAddress",
    label: "MAC Address",
    sortName: "macAddress",
    maxLength: 17,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "barcode",
    label: "Barcode",
    sortName: "barcode",
    maxLength: 80,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "dataType",
    label: "IP / Analog",
    sortName: "dataType",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "referenceProjectName",
    label: "Project",
    sortName: "project",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.amt,
    isDisplay: true
  },
  {
    key: "deviceLocation",
    label: "Interior / Exterior",
    sortName: "deviceLocation",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "coverageArea",
    label: "Coverage Area",
    sortName: "coverageArea",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "coverageDirection",
    label: "Coverage Direction",
    sortName: "coverageDirection",
    isEditable: true,
    maxLength: 3,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "coverageAngle",
    label: "Coverage Angle",
    sortName: "coverageAngle",
    isEditable: true,
    maxLength: 3,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },

  {
    key: "coverageRange",
    label: "Coverage Range",
    sortName: "coverageRange",
    isEditable: true,
    maxLength: 3,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },

  {
    key: "height",
    label: "Height (ft)",
    sortName: "height",
    maxLength: 15,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "deviceConnectedTo",
    label: "IDF / MDF",
    sortName: "deviceConnectedTo",
    maxLength: 80,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "networkSwitchLinkName",
    label: "Hub",
    sortName: "networkSwitchLinkName",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "port",
    label: "Port",
    sortName: "port",
    maxLength: 20,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "connectionLength",
    label: "Connection Length",
    sortName: "connectionLength",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "description",
    label: "Description",
    sortName: "description",
    maxLength: 4000,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "isFlag",
    label: "Flagged",
    sortName: "isFlag",
    maxLength: 4000,
    isEditable: false,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: false
  },
  {
    key: "flagNotes",
    label: "Flag Notes",
    sortName: "flagNotes",
    maxLength: 4000,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: true
  },
  {
    key: "installationNotes",
    label: "Installation Notes",
    sortName: "installationNotes",
    maxLength: 4000,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },

  {
    key: "laborWarrantyExpirationDate",
    label: "Labor Warranty Expiration",
    sortName: "laborWarrantyExpirationDate",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "deviceWarrantyExpirationDate",
    label: "Device Warranty Expiration",
    sortName: "deviceWarrantyExpirationDate",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "price",
    label: "Replacement Cost",
    sortName: "price",
    maxLength: 15,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "customDeviceId",
    label: "Custom Device ID",
    sortName: "customDeviceId",
    maxLength: 60,
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "serviceTicketCount",
    label: "Tickets (Open/Close)",
    sortName: "serviceTicketCount",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.amt,
    isDisplay: true
  },
  {
    key: "parentId",
    label: "Primary Device ID",
    sortName: "parentId",
    isEditable: false,
    deviceBelongTo: false,
    isDisplay: false
  },

  {
    key: "projectName",
    label: "Project",
    sortName: "projectName",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.project,
    isDisplay: false
  },
  {
    key: "siteName",
    label: "Site",
    sortName: "siteName",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: false
  },
  {
    key: "buildingName",
    label: "Building",
    sortName: "buildingName",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: false
  },
  {
    key: "floorName",
    label: "Plan",
    sortName: "floorName",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: false
  },
  {
    key: "coordinates",
    label: "Coordinates",
    sortName: "coordinates",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: false
  },
  {
    key: "isArchived",
    label: "Archived",
    sortName: "Archived",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: true
  },
  {
    key: "imagesCount",
    label: "Images",
    sortName: "Images",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.history,
    isDisplay: false
  }, {
    key: "cableCoordinates",
    label: "cableCoordinates",
    sortName: "cableCoordinates",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: false
  }, {
    key: "shareableLink",
    label: "Shareable Link",
    sortName: "shareableLink",
    isEditable: false,
    deviceBelongTo: deviceBelongTo.all,
    isDisplay: false
  }
];