<form [formGroup]="inviteUserForm" (ngSubmit)="saveUser()" novalidate>
    <div class="modal-header">
        <h1 class="modal-title pull-left">{{isEdit ? 'Edit Headquarters Role' : 'Invite Headquarters User'}}</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group" *ngIf="!isEdit">
            <label>Email Address
                <sup>*</sup>
            </label>
            <tag-input name="emails" [animationDuration]="chipsOption.animationDuration" formControlName="emails"
                [placeholder]="'+Add Email'" [secondaryPlaceholder]="'Email Address'"
                [secondaryPlaceholder]="'Email Address'" [addOnPaste]="chipsOption.addOnPaste"
                [addOnBlur]="chipsOption.addOnBlur" (onAdd)="onInputBlurred()" (onPaste)="onInputBlurred()"
                (onRemove)="onInputBlurred()" [modelAsStrings]="chipsOption.modelAsStrings" [theme]="chipsOption.theme"
                [editable]="chipsOption.editable" [separatorKeyCodes]="chipsOption.separatorKeyCodes"
                [separatorKeys]="chipsOption.separatorKeys" [pasteSplitPattern]="chipsOption.pasteSplitPattern"
                [inputText]="setData" #input>
                <ng-template let-item="item" let-index="index">
                    <span [ngClass]="{'invalid': toAddBorderOnInvaidSpanTag(item)}"
                        (click)="editItem(item)">{{item}}</span>
                    <em class="fa fa-close" (click)="input.removeItem(item, index)"></em>
                </ng-template>
            </tag-input>
            <div class="help-block text-danger"
                *ngIf="(isFormSubmitted && inviteUserForm.controls['emails'].value.length < 1)">
                Email Address is required</div>
            <div class="text-danger invalid-block"
                *ngIf="inviteUserForm.controls['emails'].value.length > 0 && isInvalidEmail">
                Invalid Email Address</div>
        </div>
        <div class="form-group mb-0">
            <div class="row">
                <div class="col">
                    <label class="mb-2">Business Users</label>
                    <div class="role-card user-card"
                        [ngClass]="{'active': (techRole.roleKey !== newIntegratorRoleSelection)}">
                        <label class="mt-2" for="project_{{item.name}}"
                            *ngFor="let item of businessRole; let i = index;"
                            [ngClass]="{'active': (item.roleKey === newIntegratorRoleSelection)}">
                            <div class="role-card-user-radio action-col">
                                <so-radio [id]="'project'+ item.name" [name]="'project_role'" (change)="roleChanged(item,'role')"
                                    [isChecked]="item.roleKey === roleSelection" [label]="item.name">
                                </so-radio>
                            </div>
                            <div class="role-card-user-description">
                                <ul class="role-card-list cursor-pointer">
                                    <li *ngFor="let roleDescProject of item?.newDescription">
                                        {{roleDescProject}}
                                    </li>
                                </ul>
                            </div>
                        </label>
                    </div>
                </div>
                <div class="col-1 text-center mx-1 p-0 align-items-center d-flex or-devider">
                    <span>
                        or
                    </span>
                </div>
                <div class="col">
                    <label class="mb-2">Technician Users</label>
                    <div class="role-card user-card"
                        [ngClass]="{'active': (techRole.roleKey === newIntegratorRoleSelection)}">
                        <label for="project_tech" class="align-items-center"
                            [ngClass]="{'active': (techRole.roleKey === newIntegratorRoleSelection)}">

                            <div class="role-card-user-radio action-col">

                                <so-radio [id]="'project_tech'" [name]="'project_role'" (change)="roleChanged(techRole,'role')"
                                    [isChecked]="techRole.roleKey === roleSelection"
                                    [label]="techRole.name">
                                </so-radio>

                                <!-- <input type="radio" id="project_tech" name="project_role"
                                    (change)="roleChanged(techRole,'role')"
                                    [checked]="techRole.roleKey === roleSelection">
                                <label class="mt-2" for="project_tech">{{techRole.name}}</label> -->
                            </div>
                            <div class="role-card-user-description">
                                <ul class="role-card-list cursor-pointer">
                                    <li *ngFor="let roleDescProject of techRole?.newDescription">
                                        {{techRole?.newDescription}}
                                    </li>
                                </ul>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="closeModal()" class="btn btn-secondary">Cancel</button>
        <button [disabled]="btnService.getStatus()"
            [ngStyle]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}" type="submit"
            class="btn btn-primary m-0">{{isEdit ? 'Update Role' : 'Invite'}}</button>
    </div>
</form>