/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UiService } from '../../service/ui.service';
import * as _ from "lodash";

@Component({
  selector: 'so-multi-select-plan',
  templateUrl: './multi-select-plan.component.html',
  styleUrls: ['./multi-select-plan.component.scss'],
})
export class MultiSelectPlanComponent implements OnInit {
  @Input() isUnlimitedPlan!: any;
  @Input() isFromSite!: any;
  @Input() isFromFloorPlan = false;
  @Input() defaultbuildings!: any
  @Input() showFromImageDeviceExport = false;
  @Input() id!: any;
  @Output() selectedFloor: EventEmitter<any> = new EventEmitter();
  totalPlans: any = 0;
  selectedAllPlans: any = 0
  isSelectedAll!: any
  isShowLoader!: boolean;
  selectedFloorsToDownload!: any[];
  buildings!: any;

  constructor(public uiService: UiService) { }

  ngOnInit() {
    if (this.defaultbuildings) {
      this.getTotalPlans();
    }
  }

  getTotalPlans() {

    this.totalPlans = 0;
    this.isShowLoader = false;
    this.buildings = [];
    this.defaultbuildings.forEach((element: any) => {
      const floors: any = [];
      let buildingFloorcnt = 0;

      // if (element.floors && element.floors.length > 0) {
      //   element.floors.forEach((elementFloor: any) => {
      //     if (!elementFloor.isDeleted) {
      //       this.totalPlans = this.totalPlans + 1;
      //       buildingFloorcnt = buildingFloorcnt + 1;
      //       floors.push({
      //         floorId: elementFloor.id,
      //         floorName: elementFloor.name,
      //         isFloorSelected: false
      //       })
      //     }
      //   });
      // }


      if (this.isFromSite || this.isFromFloorPlan) {
        if (element.floors && element.floors.length > 0) {
          element.floors.forEach((elementFloor: any) => {
            elementFloor.name = _.cloneDeep(_.escape(elementFloor.name));
            if (!elementFloor.isDeleted) {
              this.totalPlans = this.totalPlans + 1;
              buildingFloorcnt = buildingFloorcnt + 1;
              floors.push({
                floorId: elementFloor.id,
                floorName: elementFloor.name,
                isFloorSelected: false
              })
            }
          });
        }
      } else {
        if (element.projectFloorsDTO && element.projectFloorsDTO.length > 0) {
          element.projectFloorsDTO.forEach((elementFloor: any) => {
            elementFloor.name = _.cloneDeep(_.escape(elementFloor.name));
            if (elementFloor.isActive && !elementFloor.isDeleted) {
              this.totalPlans = this.totalPlans + 1;
              buildingFloorcnt = buildingFloorcnt + 1;
              floors.push({
                floorId: elementFloor.id,
                floorName: elementFloor.name,
                isFloorSelected: false
              })
            }
          });
        }
      }
      this.buildings.push({
        id: element.id,
        name: element.name,
        buildingFloorcnt: buildingFloorcnt,
        totalBuildingFloorCnt: buildingFloorcnt,
        isSelectedAllBuilding: false,
        floors: floors,
      })

      this.buildings = _.sortBy(this.buildings, ['name']);
    });
    this.getDownloadFloors();

  }
  getDownloadFloors() {
    this.selectedFloorsToDownload = [];
    this.isSelectedAll = true;
    this.buildings.forEach((element: any) => {
      const selectedFloor = element.floors.filter((y: any) => y.isFloorSelected === true)
      element.buildingFloorcnt = selectedFloor.length;
      selectedFloor.forEach((elementFloors: any) => {
        this.selectedFloorsToDownload.push(elementFloors.floorId)
      });
      // TO Check All building selected
      element.isSelectedAllBuilding = false;
      if (selectedFloor.length === element.floors.length) {
        element.isSelectedAllBuilding = true;
      }
    });
    this.selectedAllPlans = this.selectedFloorsToDownload.length;
    // TO Check All building and plans selected
    if (this.totalPlans !== this.selectedFloorsToDownload.length) {
      this.isSelectedAll = false;
    }
    this.selectedFloor.emit(this.selectedFloorsToDownload);
  }



  selectPlan(building: any, floor: any, event: any) {
    building.isSelectedAllBuilding = true;
    building.floors.filter((x: any) => x.floorId === floor.floorId).isFloorSelected = event.target.checked;
    if (!event.target.checked) {
      building.isSelectedAllBuilding = false;
    }
    let limitTo20 = this.selectedAllPlans;
    this.buildings.forEach((element: any) => {
      if (element.id === building.id) {
        element.floors.forEach((elementFloor: any) => {
          if (floor.floorId === elementFloor.floorId) {
            if ((limitTo20 < 20 || this.isUnlimitedPlan) && event.target.checked) {
              limitTo20++;
              elementFloor.isFloorSelected = event.target.checked;
            } else if (!event.target.checked) {
              elementFloor.isFloorSelected = event.target.checked;
              limitTo20--;
            }
          }
        });
      }
    });
    this.getDownloadFloors();
  }

  selectAllPlan(event?: any) {
    this.isSelectedAll = event.target.checked;
    if (!this.isSelectedAll) {
      this.selectedAllPlans = 0;
    }
    let limitTo20 = this.selectedAllPlans;
    this.buildings.forEach((element: any) => {
      element.isSelectedAllBuilding = event.target.checked;
      element.floors.forEach((elementFloor: any) => {
        if ((limitTo20 < 20 || this.isUnlimitedPlan) && event.target.checked) {
          limitTo20++;
          elementFloor.isFloorSelected = event.target.checked;
        } else if (!event.target.checked) {
          elementFloor.isFloorSelected = event.target.checked;
        }
      });
    });
    this.getDownloadFloors();
  }

  selectAllBuildingPlan(building: any, event: any) {
    let limitTo20 = this.selectedAllPlans;
    this.buildings.forEach((element: any) => {
      if (building.id === element.id) {
        element.isSelectedAllBuilding = event.target.checked;
        element.floors.forEach((elementFloor: any) => {
          if ((limitTo20 < 20 || this.isUnlimitedPlan) && event.target.checked) {
            limitTo20++;
            elementFloor.isFloorSelected = event.target.checked;
          } else if (!event.target.checked) {
            elementFloor.isFloorSelected = event.target.checked;
            limitTo20--;
          }
        });
      }
    });
    this.getDownloadFloors();
  }
}
