/* eslint-disable no-prototype-builtins */
import { resetObjForProjectDevices, MessageService, ReportService, deviceFields, deviceBelongTo, Utilities, resetObjForProjectPunchList, resetObjForProjectPhotoReview, resetObjForProjectCompletedWork, resetObjForProjectFlagReview, resetObjForDevices, resetObjForTicketDevices, resetObjForGlobalTicketDevices, resetObjForAmtDevicePhotoReview, dashboardTicketFields, ticketFields, AuthService, ButtonEnableDisableService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';

const renameFields = ['connectionLength', 'Name', 'Device/Task Type', 'Primary Device/Task Name', 'Date Installed/Completed', 'Installed/Completed By']
const renameKeys = ['connectionLength', 'name', 'typeName', 'parentName', 'installationDate', 'installedByUser', 'height']
const re = /\|/;

@Component({
    selector: 'so-filter-column',
    templateUrl: './filter-column.component.html',
    styleUrls: ['./filter-column.component.scss'],
})
export class FilterColumnComponent implements OnInit {
    deviceFieldForm!: FormGroup;
    deviceFields!: any[];
    resetObjForDevices = resetObjForDevices;
    resetObjForProjectDevices = resetObjForProjectDevices;
    resetObjForTicketDevices = resetObjForTicketDevices;
    isAMT: any;
    isDomotzIntegrated: any;
    isTicket: any;
    filteredFields: any;
    selectedTab: any;
    projectCustomHeaders: any;
    projectDevicePunchListField: any;
    isProjectDevicePunchList: any;
    isProjectDevicePhotoReview: any;
    projectDevicePhotoReviewField: any;
    isAmtDevicePhotoReview: any;
    amtDevicePhotoReview: any;
    amtDeviceField: any;
    isProjectDeviceCompletedWork: any;
    projectDeviceCompletedWorkField: any;
    isAddModalClass = false;
    isGlobalTicket: any;
    isProjectDeviceFlagReview: any
    projectDeviceFlagReviewField: any;
    unitOfMeasurements: any = 'Feet'
    public event: EventEmitter<any> = new EventEmitter();
    constructor(private messageService: MessageService, public btnService: ButtonEnableDisableService,
        private reportService: ReportService, private authService: AuthService,
        public bsModalRef: BsModalRef, public options: ModalOptions) {
    }

    ngOnInit() {
        this.unitOfMeasurements = this.authService.getUserKeyData().unitOfMeasurements;
        if (this.isTicket) {
            if (this.isGlobalTicket) {
                this.deviceFields = dashboardTicketFields;
            } else {
                this.deviceFields = ticketFields;
            }
        } else {
            this.deviceFields = _.cloneDeep(deviceFields);
            if (!this.isDomotzIntegrated) {
                _.remove(this.deviceFields, (field) => {
                    return field.key === 'monitoredStatus' || field.key === 'autoUpdateDeviceStatus';
                });
            }
        }
        this.setDevicesFields();
        this.setFormValues();
    }

    createForm() {
        let obj: any = {};
        this.deviceFields.forEach((deviceField: any) => {
            obj[deviceField.key] = new FormControl({ value: false, disabled: false });
        });
        this.deviceFieldForm = new FormGroup(obj);
    }

    setDevicesFields() {
        if (this.isAMT) {
            this.deviceFields = this.deviceFields.filter(x => (x.deviceBelongTo === deviceBelongTo.all ||
                x.deviceBelongTo === deviceBelongTo.amt));
            this.deviceFields.forEach(x => {
                if (x.key === 'height') {
                    x.label = this.unitOfMeasurements === 'Meters' ? 'Height (m)' : 'Height (ft)';
                } else if (x.key === 'connectionLength') {
                    x.label = this.unitOfMeasurements === 'Meters' ? 'Connection Length (meters)' : 'Connection Length (feet)';
                }
            })
        } else if (this.isTicket) {
            this.deviceFields = this.deviceFields.filter(x => (
                x.deviceBelongTo === deviceBelongTo.ticket));
        } else {

            this.deviceFields = this.deviceFields.filter(x => (x.deviceBelongTo === deviceBelongTo.all ||
                x.deviceBelongTo === deviceBelongTo.project));

            this.deviceFields.forEach(x => {
                if (renameKeys.indexOf(x.key) >= 0) {
                    let i = renameKeys.indexOf(x.key)
                    x.label = renameFields[i]
                    if (x.key === 'height') {
                        x.label = this.unitOfMeasurements === 'Meters' ? 'Height (m)' : 'Height (ft)';
                    } else if (x.key === 'connectionLength') {
                        x.label = this.unitOfMeasurements === 'Meters' ? 'Connection Length (meters)' : 'Connection Length (feet)';
                    }
                }
            })
        }

        this.createForm();
    }

    setFormValues(tab?: any, selectedColumns?: any) {
        if (Utilities.isNull(tab)) {
            this.deviceFields.forEach(item => {
                if (this.filteredFields.some((x: any) => x.key === item.key)) {
                    if (item.key === 'coverageArea') {
                        {
                            this.deviceFieldForm.controls['coverageDirection'].setValue(true);
                            this.deviceFieldForm.controls['coverageAngle'].setValue(true);
                            this.deviceFieldForm.controls['coverageRange'].setValue(true);
                        }
                    }
                    this.deviceFieldForm.controls[item.key].setValue(true);
                }
            })
        } else {
            const list = selectedColumns.split(re)
            this.deviceFields.forEach(item => {
                if (list.some((x: any) => x === item.key)) {
                    if (item.key === 'coverageArea') {
                        {
                            this.deviceFieldForm.controls['coverageDirection'].setValue(true);
                            this.deviceFieldForm.controls['coverageAngle'].setValue(true);
                            this.deviceFieldForm.controls['coverageRange'].setValue(true);
                        }
                    }
                    this.deviceFieldForm.controls[item.key].setValue(true);
                }
            })
        }
    }


    configureColumns() {
        this.btnService.disable();
        const tempObj: any = {};
        const Obj: any = {};
        let str = '';
        for (const field in this.deviceFieldForm.value) {
            if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                tempObj[field] = field;
                str = str ? `${field}|${str}` : field;
            }

        }
        if (this.isAMT) {
            Obj['key'] = deviceBelongTo.amt;
            if (this.isAmtDevicePhotoReview) {
                Obj['key'] = deviceBelongTo.amtDevicePhotoReviewField;
            }
        } else if (this.isTicket) {
            Obj['key'] = 'serviceTicketField';
        } else {
            if (this.isProjectDevicePunchList) {
                Obj['key'] = deviceBelongTo.projectDevicePunchListField;

            } else if (this.isProjectDevicePhotoReview) {
                Obj['key'] = deviceBelongTo.projectDevicePhotoReviewField;
            } else if (this.isProjectDeviceCompletedWork) {
                Obj['key'] = deviceBelongTo.projectDeviceCompletedWorkField;
            } else if (this.isProjectDeviceFlagReview) {
                Obj['key'] = deviceBelongTo.projectDeviceFlagReviewField;
            } else {
                Obj['key'] = deviceBelongTo.project;
            }
        }
        Obj['value'] = str;
        if (Obj['value'] === '') {
            this.messageService.errorMessage('Error', 'Select at least one column');
            return;
        }
        this.updateColumns(Obj);

    }

    updateColumns(columns: any) {
        if (!(this.isAMT || this.isTicket)) {
            if (this.isProjectDevicePunchList) {
                columns['projectFieldView'] = 'punch list';
            }
            if (this.isProjectDeviceCompletedWork) {
                columns['projectFieldView'] = 'completed work';
            }
            if (this.isProjectDevicePhotoReview) {
                columns['projectFieldView'] = 'photo review';
            }
            if (this.isProjectDeviceFlagReview) {
                columns['projectFieldView'] = 'flag review';
            }
            if (!this.isProjectDeviceFlagReview && !this.isProjectDevicePhotoReview && !this.isProjectDevicePunchList && !this.isProjectDeviceCompletedWork) {
                columns['projectFieldView'] = 'default';
            }
        } else if (this.isAMT) {
            if (this.isAmtDevicePhotoReview) {
                columns['amtFieldView'] = 'photo review'
            } else {
                columns['amtFieldView'] = 'default'
            }
        }
        let checkFlagNotes = columns.value.split("|");
        if (checkFlagNotes && checkFlagNotes.indexOf('flagNotes') !== -1) {
            checkFlagNotes.push('isFlag');
            columns.value = checkFlagNotes.join('|')
            columns.value = columns.value.toString();
        } else {
            const index = checkFlagNotes.indexOf('isFlag');
            if (index > -1) {
                checkFlagNotes.splice(index, 1);
            }
        }
        let checkCoverageArea = columns.value.split("|");
        if (checkCoverageArea && checkCoverageArea.indexOf('coverageArea') !== -1) {
            checkCoverageArea.push('coverageDirection');
            checkCoverageArea.push('coverageAngle');
            checkCoverageArea.push('coverageRange');
            columns.value = checkCoverageArea.join('|')
            columns.value = columns.value.toString();
        } else {
            const index = checkCoverageArea.indexOf('coverageDirection');
            if (index > -1) {
                checkCoverageArea.splice(index, 1);
            }
            const indexAngle = checkCoverageArea.indexOf('coverageAngle');
            if (indexAngle > -1) {
                checkCoverageArea.splice(indexAngle, 1);
            }
            const indexRange = checkCoverageArea.indexOf('coverageRange');
            if (indexRange > -1) {
                checkCoverageArea.splice(indexRange, 1);
            }
            columns.value = checkCoverageArea.join('|')
            columns.value = columns.value.toString();
        }

        if (this.isGlobalTicket) {
            this.reportService.setGlobalTicket(columns).subscribe({
                next: (result: any) => { this.getUpdatedFields(); }
            })
        } else {
            this.reportService.setDevicefields(columns).subscribe({
                next: (result: any) => { this.getUpdatedFields(); }
            })
        }

    }

    getUpdatedFields() {
        if (this.isGlobalTicket) {
            this.reportService.getTicketFeilds().subscribe((result: any) => {
                this.splitString(result.serviceTicketField);
            });
        } else {
            this.reportService.getDevicefields().subscribe({
                next: (result: any) => {
                    if (this.isAMT) {
                        if (this.isAmtDevicePhotoReview) {
                            this.splitString(result.amtDevicePhotoReviewField);
                        } else {
                            this.splitString(result.amtDeviceField);
                        }
                    } else if (this.isTicket) {
                        this.splitString(result.serviceTicketField);
                    } else {
                        if (this.isProjectDevicePunchList) {
                            this.splitString(result.projectDevicePunchListField);
                        } else if (this.isProjectDevicePhotoReview) {
                            this.splitString(result.projectDevicePhotoReviewField);
                        } else if (this.isProjectDeviceCompletedWork) {
                            this.splitString(result.projectDeviceCompletedWorkField);
                        } else if (this.isProjectDeviceFlagReview) {
                            this.splitString(result.projectDeviceFlagReviewField);
                        } else {
                            this.splitString(result.projectDeviceField);
                        }
                    }
                }
            })
        }
        const timeOut = setTimeout(() => {
            this.btnService.enable();
            clearTimeout(timeOut);
        }, 500);
    }
    splitString(devicesColumnString: any) {
        const list = devicesColumnString.split(re);
        const obj: any = {};
        list.forEach((item: any) => {
            obj[item] = item;
        })
        obj['updateList'] = devicesColumnString;
        if (!Utilities.isNull(this.isProjectDevicePunchList)) {
            obj['isProjectDevicePunchList'] = this.isProjectDevicePunchList
        }
        if (!Utilities.isNull(this.isProjectDevicePhotoReview)) {
            obj['isProjectDevicePhotoReview'] = this.isProjectDevicePhotoReview;
        }
        if (!Utilities.isNull(this.isProjectDeviceFlagReview)) {
            obj['isProjectDeviceFlagReview'] = this.isProjectDeviceFlagReview;
        }
        if (!Utilities.isNull(this.isProjectDeviceCompletedWork)) {
            obj['isProjectDeviceCompletedWork'] = this.isProjectDeviceCompletedWork;
        }
        if (!Utilities.isNull(this.isAmtDevicePhotoReview)) {
            obj['isAmtDevicePhotoReview'] = this.isAmtDevicePhotoReview;
        }
        this.event.emit(obj);
        this.bsModalRef.hide();
    }
    resetColumns(columns: any) {

        if (this.isTicket) {
            if (this.isGlobalTicket) {
                columns = resetObjForGlobalTicketDevices;
            } else {
                columns = resetObjForTicketDevices;
            }
        } else if (this.isProjectDevicePunchList) {
            columns = resetObjForProjectPunchList
        } else if (this.isProjectDevicePhotoReview) {
            columns = resetObjForProjectPhotoReview;
        } else if (this.isProjectDeviceCompletedWork) {
            columns = resetObjForProjectCompletedWork;
        } else if (this.isAmtDevicePhotoReview) {
            columns = resetObjForAmtDevicePhotoReview;
        } else if (this.isProjectDeviceFlagReview) {
            columns = resetObjForProjectFlagReview;
        }
        const list = columns.value.split(re);

        this.deviceFields.forEach(item => {
            if (list.some((x: any) => x === item.key)) {
                this.deviceFieldForm.controls[item.key].setValue(true);
            } else {
                if (item.key === 'coverageArea') {
                    {
                        this.deviceFieldForm.controls['coverageDirection'].setValue(false);
                        this.deviceFieldForm.controls['coverageAngle'].setValue(false);
                        this.deviceFieldForm.controls['coverageRange'].setValue(false);
                    }
                }
                this.deviceFieldForm.controls[item.key].setValue(false);
            }
        })
    }
    closeModal() {
        this.bsModalRef.hide();
    }
    changeTab(isPunchList: any, isPhotoReview: any, isCompletedWork: any, isFlagReview: any) {
        if (this.isAMT) {
            if (this.isAmtDevicePhotoReview !== isPhotoReview) {
                const tempObj: any = {};
                let str = '';
                for (const field in this.deviceFieldForm.value) {
                    if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                        tempObj[field] = field;
                        str = str ? `${field}|${str}` : field;
                    }
                }
                this.isAmtDevicePhotoReview = isPhotoReview;
                this.deviceFieldForm.reset();

                if (this.isAmtDevicePhotoReview) {
                    this.amtDeviceField = str;
                    if (Utilities.isNull(this.amtDevicePhotoReview)) {
                        this.amtDevicePhotoReview = resetObjForAmtDevicePhotoReview.value
                    }
                    this.setFormValues(isPhotoReview, this.amtDevicePhotoReview)

                } else {
                    if (Utilities.isNull(this.amtDeviceField)) {
                        this.amtDeviceField = resetObjForDevices.value;
                    }
                    this.amtDevicePhotoReview = str
                    this.setFormValues(isPhotoReview, this.amtDeviceField)

                }
            }
        } else {

            if (!isPunchList && !isPhotoReview && !isFlagReview && !isCompletedWork) {
                const tempObj: any = {};
                let str = '';
                for (const field in this.deviceFieldForm.value) {
                    if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                        tempObj[field] = field;
                        str = str ? `${field}|${str}` : field;
                    }
                }
                this.deviceFieldForm.reset();
                if (this.isProjectDevicePhotoReview) {
                    this.projectDevicePhotoReviewField = str;
                }
                if (this.isProjectDeviceFlagReview) {
                    this.projectDeviceFlagReviewField = str;
                }
                if (this.isProjectDevicePunchList) {
                    this.projectDevicePunchListField = str;
                }
                if (Utilities.isNull(this.projectCustomHeaders)) {
                    this.projectCustomHeaders = resetObjForProjectDevices.value
                }
                this.setFormValues(isPunchList, this.projectCustomHeaders)
                this.isProjectDevicePhotoReview = isPhotoReview;
                this.isProjectDevicePunchList = isPunchList;
                this.isProjectDeviceCompletedWork = isCompletedWork;
                this.isProjectDeviceFlagReview = isFlagReview
            } else if (isPunchList && !isPhotoReview && !isFlagReview && !isCompletedWork) {
                if (this.isProjectDevicePunchList !== isPunchList) {
                    const tempObj: any = {};
                    let str = '';
                    for (const field in this.deviceFieldForm.value) {
                        if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                            tempObj[field] = field;
                            str = str ? `${field}|${str}` : field;
                        }
                    }
                    this.isProjectDevicePunchList = isPunchList;
                    this.deviceFieldForm.reset();
                    if (this.isProjectDevicePhotoReview) {
                        this.projectDevicePhotoReviewField = str;
                    }
                    if (!this.isProjectDevicePhotoReview) {
                        this.projectCustomHeaders = str;
                    }
                    if (this.isProjectDeviceFlagReview) {
                        this.projectDeviceFlagReviewField = str;
                    }
                    if (!this.isProjectDeviceFlagReview) {
                        this.projectCustomHeaders = str;
                    }
                    if (Utilities.isNull(this.projectDevicePunchListField)) {
                        this.projectDevicePunchListField = resetObjForProjectPunchList.value;
                    }
                    this.setFormValues(isPunchList, this.projectDevicePunchListField);
                    this.isProjectDevicePhotoReview = isPhotoReview;
                    this.isProjectDeviceCompletedWork = isCompletedWork;
                    this.isProjectDeviceFlagReview = isFlagReview;
                }
            } else if (isPhotoReview && !isPunchList && !isFlagReview && !isCompletedWork) {
                if (this.isProjectDevicePhotoReview !== isPhotoReview) {
                    const tempObj: any = {};
                    let str = '';
                    for (const field in this.deviceFieldForm.value) {
                        if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                            tempObj[field] = field;
                            str = str ? `${field}|${str}` : field;
                        }
                    }
                    this.isProjectDevicePhotoReview = isPhotoReview;
                    this.deviceFieldForm.reset();
                    if (this.isProjectDevicePunchList) {
                        this.projectDevicePunchListField = str;
                    }
                    if (!this.isProjectDevicePunchList) {
                        this.projectCustomHeaders = str;
                    }
                    if (Utilities.isNull(this.projectDevicePhotoReviewField)) {
                        this.projectDevicePhotoReviewField = resetObjForProjectPhotoReview.value;
                    }
                    this.setFormValues(isPunchList, this.projectDevicePhotoReviewField);
                    this.isProjectDevicePunchList = isPunchList;
                }
                this.isProjectDeviceCompletedWork = isCompletedWork;
                this.isProjectDeviceFlagReview = isFlagReview
            } else if (!isFlagReview && !isPhotoReview && !isPunchList && isCompletedWork) {
                if (this.isProjectDeviceCompletedWork !== isCompletedWork) {
                    const tempObj: any = {};
                    let str = '';
                    for (const field in this.deviceFieldForm.value) {
                        if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                            tempObj[field] = field;
                            str = str ? `${field}|${str}` : field;
                        }
                    }
                    this.isProjectDeviceCompletedWork = isCompletedWork;
                    this.deviceFieldForm.reset();

                    if (!this.isProjectDevicePunchList) {
                        this.projectCustomHeaders = str;
                    }
                    if (Utilities.isNull(this.projectDeviceCompletedWorkField)) {
                        this.projectDeviceCompletedWorkField = resetObjForProjectCompletedWork.value;
                    }
                    this.setFormValues(isPunchList, this.projectDeviceCompletedWorkField);
                    this.isProjectDevicePunchList = isPunchList;
                    this.isProjectDevicePhotoReview = isPhotoReview;
                    this.isProjectDeviceFlagReview = isFlagReview
                }
            } else if (!isPhotoReview && !isPunchList && isFlagReview && !isCompletedWork) {
                if (this.isProjectDeviceFlagReview !== isFlagReview) {
                    const tempObj: any = {};
                    let str = '';
                    for (const field in this.deviceFieldForm.value) {
                        if (this.deviceFieldForm.value.hasOwnProperty(field) && this.deviceFieldForm.value[field]) {
                            tempObj[field] = field;
                            str = str ? `${field}|${str}` : field;
                        }
                    }
                    this.isProjectDeviceFlagReview = isFlagReview;
                    this.deviceFieldForm.reset();

                    if (Utilities.isNull(this.projectDeviceFlagReviewField)) {
                        this.projectDeviceFlagReviewField = resetObjForProjectFlagReview.value;
                    }
                    this.setFormValues(isFlagReview, this.projectDeviceFlagReviewField);
                    this.isProjectDevicePunchList = false;
                    this.isProjectDeviceCompletedWork = false;
                    this.isProjectDevicePhotoReview = false;

                }
                this.isProjectDeviceCompletedWork = isCompletedWork;
            }
        }
    }
    ngOnDestroy() {
        this.isProjectDevicePunchList = false
        this.isProjectDeviceCompletedWork = false
    }

    selectAllColumns() {
        this.deviceFields.forEach(item => {
            this.deviceFieldForm.controls[item.key].setValue(true);
        })
    }

    selectNoneColumns() {
        this.deviceFields.forEach(item => {
            this.deviceFieldForm.controls[item.key].setValue(false);
        })
    }
}

