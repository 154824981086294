import { deviceBelongTo } from "./common.constant";

export const ticketFields = [
  {
    key: "id",
    label: "Ticket #",
    sortName: "id",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "subject",
    label: "Subject",
    sortName: "subject",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "requesterById",
    label: "Requester",
    sortName: "requesterById",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "assignedToId",
    label: "Assignee",
    sortName: "assignedToId",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "status",
    label: "Status",
    sortName: "status",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "priority",
    label: "Priority",
    sortName: "priority",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "cost",
    label: "Cost",
    sortName: "cost",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "createdDate",
    label: "Date Added",
    sortName: "createdDateTime",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "dueDate",
    label: "Date Due",
    sortName: "dueDate",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "scheduledDate",
    label: "Date Scheduled",
    sortName: "scheduledDate",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "closedDateStr",
    label: "Date Closed",
    sortName: "closedDateStr",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "watchers",
    label: "Watchers",
    sortName: "watchers",
    maxLength: 8800,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "description",
    label: "Description",
    sortName: "description",
    maxLength: 4000,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "siteName",
    label: "Site",
    sortName: "siteName",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "buildingName",
    label: "Building",
    sortName: "buildingName",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "floorName",
    label: "Plan",
    sortName: "floorName",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "isArchived",
    label: "Archived",
    sortName: "Archived",
    isEditable: true,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "buildingAddress",
    label: "Address",
    sortName: "buildingAddress",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  },
  {
    key: "associatedDevices",
    label: "Associated Devices",
    sortName: "associatedDevice",
    maxLength: 80,
    deviceBelongTo: deviceBelongTo.ticket
  }
];