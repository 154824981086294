import { requestHostedLoginAuthorize } from '../LoginState/saga';
import { ACR_VALUE, SHOULD_STEP_UP_KEY } from './consts';
import { setAfterAuthRedirectUrlForStepUp } from './utils';
import { select } from 'redux-saga/effects';

/**
 * Step up for hosted login apps
 * @param payload.maxAge
 */
export function* stepUpHostedLogin({
  payload
}) {
  const params = {
    acr_values: ACR_VALUE
  };
  const {
    maxAge
  } = payload || {};
  if (maxAge !== undefined) {
    params.max_age = maxAge.toString();
  }
  const alias = yield select(({
    auth
  }) => {
    var _auth$tenantsState, _auth$tenantsState$ac;
    return (_auth$tenantsState = auth.tenantsState) == null ? void 0 : (_auth$tenantsState$ac = _auth$tenantsState.activeTenant) == null ? void 0 : _auth$tenantsState$ac.alias;
  });
  if (alias) {
    params.organization = alias;
  }
  setAfterAuthRedirectUrlForStepUp();
  window.localStorage.setItem(SHOULD_STEP_UP_KEY, 'true');
  yield requestHostedLoginAuthorize(params);
}