/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'so-select-pdf-page',
  templateUrl: './select-pdf-page.component.html',
  styleUrls: ['./select-pdf-page.component.scss'],
})
export class SelectPdfPageComponent {
  floorName!: any;
  pdfPageArray!: any;
  public selectedPage: EventEmitter<any> = new EventEmitter();
  pageSelect: any = ''
  pageSelected(e: any) {
    this.pageSelect = ''
    this.pageSelect = this.pdfPageArray.filter((x:any)=>x.value===e.target.value)[0]['id'];
  }

  closeModal(e: any, action: any) {
    if (action === 'replace' && this.pageSelect === '') {
      this.pageSelect = this.pdfPageArray[0]['id'];
    }
    this.selectedPage.emit({ action: action, pageSelect: parseInt(this.pageSelect) })
  }
}
