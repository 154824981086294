import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"];
import { call, put } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { errorHandler } from '../../../utils';
import { MFAStep } from '../../MfaState/interfaces';
import { actions } from '../../reducer';
import { handleVerifyMFAResponse } from './handleVerifyMFAResponse.saga';
/**
 * Shared logic for MFA Email code pre-verify step
 * @param payload.callback callback function to be called after the verification is done
 * @param payload.mfaToken
 * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
 */
export function* preVerifyMFAEmailCode(_ref, setLoadingAction) {
  let {
      callback
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref, _excluded);
  yield put(setLoadingAction({
    loading: true,
    error: undefined
  }));
  try {
    const data = yield call(api.auth.preVerifyMFAEmailCode, payload);
    yield put(actions.setMfaState({
      otcToken: data.otcToken,
      step: MFAStep.emailVerifyCode
    }));
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(setLoadingAction({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}

/**
 * Shared logic for MFA Email code verify step
 * @param payload.otcToken
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.code 6 digits code input by the user
 */
export function* verifyMFAEmailCode(_ref2, setLoadingAction) {
  let {
      callback
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  yield put(setLoadingAction({
    loading: true
  }));
  try {
    const data = yield call(api.auth.verifyMFAEmailCodeV2, payload);
    yield handleVerifyMFAResponse(data);
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(setLoadingAction({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}

/**
 * Pre verify step for MFA Email login
 * @param payload.mfaToken
 * @param payload.callback callback function to be called after the verification is done
 */
export function* preVerifyMFAEmailCodeForLogin({
  payload
}) {
  yield preVerifyMFAEmailCode(payload, actions.setLoginState);
}

/**
 * Verify step for MFA Email login
 * @param payload.otcToken
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.code 6 digits code input by the user
 */
export function* verifyMFAEmailCodeForLogin({
  payload
}) {
  yield verifyMFAEmailCode(payload, actions.setLoginState);
}