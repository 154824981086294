/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonEnableDisableService, CustomerService, ListService, Utilities, ProviderRole, AuthService } from '@SiteOwl/core';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'so-edit-provider-role',
  templateUrl: './edit-provider-role.component.html',
  styleUrls: ['./edit-provider-role.component.scss'],
})
export class EditProviderRoleComponent implements OnInit, OnDestroy {

  projectRole: any
  siteRole: any
  customerDetails: any;
  canDisplay = false
  isSiteRole!: boolean;
  userDetail: any;
  roles = {
    amt: [],
    project: []
  }

  providerRole: any;
  providerResult: any;
  providerDetail: any = null;
  providerId: any;
  user: any;
  userRoles!: any[];
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private authService: AuthService,private sanitizer: DomSanitizer,
    public btnService: ButtonEnableDisableService,public bsModalRef: BsModalRef,
    private customerService: CustomerService, private listService: ListService
  ) {}

  ngOnInit() {
    this.user = this.authService.getUserData();
    this.providerId = this.user.providerId;
    this.getRoles();
    this.getProjectRoles();

  }

  private getRoles() {
    this.listService.getCustomerSpecificRoleList().subscribe({
      next: (result: any) => {
        this.roles.amt = result;
        this.roles.amt = Utilities.seperateRoleDescription(this.roles.amt)
        this.siteRole = this.roles.amt

        if (this.isSiteRole) {
          this.userRoles = this.roles.amt
          this.userRoles.forEach(x => {
            x.isDefaultSelected = false;
          })
          this.userRoles.find(role => role.name === this.customerDetails.role.name).isDefaultSelected = true;
          this.getRoleChanges()
        }
      }  
    });
  }
  private getProjectRoles() {
    this.listService.getCustomerSpecificProjectRoleList().subscribe({
      next: (result: any) => {
        this.roles.project = result;
        this.roles.project = Utilities.seperateRoleDescription(this.roles.project)
        this.projectRole = this.roles.project
        if (!this.isSiteRole) {
          this.userRoles = this.roles.project
          this.userRoles.find(role => role.name === this.customerDetails.projectRole.name).isDefaultSelected = true;
          this.getRoleChanges()
        }
      }  
    });
  }

  updateUser() {
    const selectedRole = this.userRoles.find(x => x.isDefaultSelected)
    let role: any;

    const user = this.userDetail;
    user.id = this.customerDetails.id;
    user['customer'] = this.customerDetails.customer
    user.customerId = null;
    user.name = this.userDetail.name;
    user.email = this.userDetail.email;
    user.employer = this.userDetail.employer;
    user.emailReport = this.userDetail.emailReport;
    if (this.isSiteRole) {
      role = this.roles.amt.find((x: any) => x.name === selectedRole.name)
      user.role = role
      user.projectRole = this.userDetail.customerUserList.find((x: any) => x.customer.id === this.customerDetails.customer.id).projectRole || this.providerDetail[0].projectRole

    } else {
      role = this.roles.project.find((x: any) => x.name === selectedRole.name)
      user.projectRole = role
      user.role = this.userDetail.customerUserList.find((x: any) => x.customer.id === this.customerDetails.customer.id).role || this.providerDetail[0].projectRole

    }
    this.btnService.disable();

    this.customerService.updateCustomerSpecificUser(user).subscribe({
      next: () => {
        this.btnService.enable();
        this.event.emit({ selectedRole: role });
        this.bsModalRef.hide();
      },error: () => {
        this.btnService.enable();
      }  
    });
  }
  onItemChange(item: any) {
    this.userRoles.forEach(x => {
      x.isDefaultSelected = false;
    })
    if (this.isSiteRole) {
      this.userRoles.find(role => role.roleKey === item.roleKey).isDefaultSelected = true;
      return;
    }
    this.userRoles.find(role => role.roleKey === item.roleKey).isDefaultSelected = true;
  }
  closeModal() {
    this.userRoles = [];
    this.customerDetails = [];
    this.bsModalRef.hide();
  }
  getValue(title: any) {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }
  ngOnDestroy() {
    this.userRoles.forEach(x => {
      this.providerDetail = []
      x.isDefaultSelected = false;
    })
    this.btnService.enable();
  }
  getRoleChanges() {
    const timer = setTimeout(() => {
      if (this.userRoles && this.userRoles.length > 0) {
        let tempIndex: any
        this.userRoles = Object.assign([], this.userRoles)
        this.customerService.getAssociatedProviderById(this.customerDetails.customer.id).subscribe({
          next: (result: any) => {
            const tempProviders = result
            this.providerDetail = tempProviders.filter((x: any) => x.provider.id === this.providerId);
            this.canDisplay = true;
            clearTimeout(timer);
          }  
        })
      }
    }, 0);
  }
}

