import _extends from "@babel/runtime/helpers/esm/extends";
import { auditsStoreName } from '../constants';
import { initialState } from './initialState';
import { createSlice } from '@reduxjs/toolkit';
import { auditLogsActions, auditLogsReducers } from './AuditLogsState';
import { auditsMetadataActions, auditsMetadataReducers } from './AuditsMetadataState';
const {
  reducer,
  actions: sliceActions
} = createSlice({
  name: auditsStoreName,
  initialState,
  reducers: _extends({
    resetState: state => _extends({}, state, initialState),
    setState: (state, {
      payload
    }) => _extends({}, state, payload)
  }, auditLogsReducers, auditsMetadataReducers)
});
const actions = _extends({}, sliceActions, auditLogsActions, auditsMetadataActions);
export { reducer, actions };