"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useIsOperation = void 0;
function useIsOperation(payload) {
  return attribute => ({
    isValid: attribute === payload.boolean
  });
}
exports.useIsOperation = useIsOperation;
