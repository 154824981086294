/* eslint-disable @typescript-eslint/no-explicit-any */
import { Customer, accountType, CustomerService, ButtonEnableDisableService, MessageService, ValidationService, Pattern, Utilities } from '@SiteOwl/core';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-customer-create-edit',
  templateUrl: './customer-create-edit.component.html',
  styleUrls: ['./customer-create-edit.component.scss'],
})
export class CustomerCreateEditComponent implements OnInit, OnDestroy {

  isEditMode = false;
  customerDetail!: Customer;
  customerForm!: FormGroup;
  isFormSubmitted = false;
  accountSelection: any = 'Trial';
  accountType = accountType;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private customerService: CustomerService,
      public btnService: ButtonEnableDisableService,public bsModalRef: BsModalRef,
      private fb: FormBuilder, private messageService: MessageService) {
  }

  private setFormValue() {
      this.customerForm.patchValue(this.customerDetail);
  }

  private createForm() {
      this.customerForm = this.fb.group({
          name: ['', [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
          email: ['', [Validators.pattern(Pattern.EMAIL)]],
          phoneNumber: ['', []],
          accountType: [this.customerDetail.accountType ? this.customerDetail.accountType : 'Trial', []]
      });
  }

  private addCustomer() {
      const customer = this.customerForm.value;
      this.btnService.disable();
      this.customerService.addCustomer(customer).subscribe({
            next: (addCustomerResult: any) => {
              this.btnService.enable();

              if (addCustomerResult.status === "error") {
                  this.messageService.errorMessage(`Error`, `Account: ${addCustomerResult.name === '' || addCustomerResult.name === null ? addCustomerResult.email : addCustomerResult.name} already exsits.`);
              } else {
                  this.messageService.successMessage(`Account: ${addCustomerResult.name === '' || addCustomerResult.name === null ? addCustomerResult.email : addCustomerResult.name}`, 'Saved Successfully');
                  this.bsModalRef.hide();
                  this.event.emit(addCustomerResult);
              }
            }, error: (error: any) => {
              if (error.status === 422) {
                  this.messageService.errorMessage('Account', error.error.message);
              } else {
                  this.errorMessage();
              }
              this.btnService.enable();
            }   
          });
  }

  private updateCustomer() {
      const customer: Customer = this.customerForm.value;
      customer.id = this.customerDetail.id;
      customer.isActive = this.customerDetail.isActive;
      this.btnService.disable();
      this.customerService.updateCustomer(customer).subscribe({
        next: (result: any) => {
              this.btnService.enable();
              if (result.status === "error") {
                  this.messageService.errorMessage(`Error`, `Account: ${result.name === '' || result.name === null ? result.email : result.name} already exsits.`);
              } else {
                  this.messageService.successMessage(`Account: ${result.name === '' || result.name === null ? result.email : result.name}`, 'Saved Successfully');
                  this.event.emit(result);
                  this.bsModalRef.hide();
              }
          }, errror: (error: any) => {
              if (error.status === 422) {
                  this.messageService.errorMessage('Account', error.error.message);
              } else {
                  this.errorMessage();
              }
            }   
        });
  }
  errorMessage() {
      this.btnService.enable();
      this.messageService.errorMessage(`Error`, 'Error while saving');
  }
  ngOnInit(): void {
    this.accountSelection = this.customerDetail.accountType ? this.customerDetail.accountType : 'Trial';
      this.createForm();
      if (this.isEditMode) {
          this.setFormValue();
      }
  }

  saveCustomer() {
      if (this.customerForm.invalid) {
          this.isFormSubmitted = true;
          return;
      }
      if (this.isEditMode) {
          this.updateCustomer();
      } else {
          this.addCustomer();
      }
  }
  accountChanged(item: any, eventType: any) {
      if (eventType === 'account') {
          this.customerForm.value.accountType = item.accountTypeKey;
          this.accountSelection = item.accountTypeKey;
          this.customerForm.controls['accountType'].setValue(this.accountType.find(x => x.id === item.id)?.accountTypeKey, { onlySelf: true });
      }
  }
  closeModal() {
      this.bsModalRef.hide();
  }
  ngOnDestroy() {
      this.btnService.enable();
  }
  checkCustomerName() {
      this.customerForm.controls['name'].setValue(Utilities.removeWhiteSpace(this.customerForm.controls['name'].value));
  }
}

