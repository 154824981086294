import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'so-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss'],
})
export class RadioComponent {
  @Input() id!: string;
  @Input() name!: string;
  @Input() isChecked!: boolean;
  @Input() label!: string;
  @Input() disabled = false;
  @Input() className: any = {};
  @Input() style: any = {};
  @Input() value: any;
  @Input() inputControlName!: FormControl;
  @Output() onChange = new EventEmitter();


  _valueChanged($event: any) {
    this.onChange.emit($event);
  }
}
