import { put, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from './reducer';
import { delay } from '../auth/utils';
export function* loadVendorPublicInfo() {
  yield put(actions.setVendorState({
    loading: true
  }));
  try {
    const {
      whiteLabelMode = false
    } = yield api.vendor.getVendorPublicInfo();
    yield put(actions.setVendorState({
      whiteLabelMode,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setVendorState({
      loading: false
    }));
    console.error('failed to getVendorPublicInfo - ', e);
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadVendorPublicInfoMock() {
  try {
    yield put(actions.setVendorState({
      whiteLabelMode: false
    }));
    yield delay();
  } catch (e) {
    console.error('failed to getVendorPublicInfo - ', e);
  }
}
export function* vendorSagasMock() {
  yield takeEvery(actions.loadVendorPublicInfo, loadVendorPublicInfoMock);
}
export function* sagas() {
  yield takeEvery(actions.loadVendorPublicInfo, loadVendorPublicInfo);
}