<div class="modal-header">
    <h1 class="modal-title pull-left">Edit Responsible Headquarters</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
      <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <p>Select the Headquarter responsible for <strong>{{customerDetail?.name}}</strong></p>
        <label class="mb-1">Headquarters</label>
        <select class="form-control" [(ngModel)]="responsibleIntegratorId"
            (change)="setSelectedStatus(responsibleIntegratorId)">
            <option value="null">None</option>
            <option *ngFor="let userProviderActive of providerDP" [value]="userProviderActive.id">
                {{userProviderActive.name}}
            </option>
        </select>
    </div>
</div>
<div class="modal-footer">
    <button type="button" (click)="responsibleIntergratorSave(false)" class="btn btn-secondary ">Cancel</button>
    <button type="submit" (click)="responsibleIntergratorSave(true)" class="btn btn-primary m-0">
        Update
    </button>
</div>