import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "events"];
import { api } from '@frontegg/rest-api';
import { call, delay, put, takeLeading } from 'redux-saga/effects';
import { afterAuthNavigation } from '../LoginState/saga';
import { actions } from '../reducer';
import { ImpersonateStep } from './interfaces';
import { errorHandler } from '../../utils';
function* impersonate(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setImpersonateState({
    loading: true
  }));
  try {
    const data = yield call(api.impersonate.impersonate, payload);
    yield put(actions.setImpersonateState({
      step: ImpersonateStep.success
    }));
    yield delay(1000);
    yield afterAuthNavigation();
    yield put(actions.resetImpersonateState());
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setImpersonateState({
      loading: false,
      error: errorHandler(e)
    }));
  }
}
export function* impersonateSagas() {
  yield takeLeading(actions.impersonate, impersonate);
}