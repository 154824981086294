/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-migrate-project',
  templateUrl: './migrate-project.component.html',
  styleUrls: ['./migrate-project.component.scss'],
})
export class MigrateProjectComponent {
  selectedSiteIdForMove!: any;
  projects!: any;
  currentBuilding!: any;

  public event: EventEmitter<any> = new EventEmitter();

  constructor(public moveModelRef: BsModalRef) {

  }

  migrateProject() {
    this.event.emit(this.selectedSiteIdForMove);
  }
}
