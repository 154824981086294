import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction } from '@reduxjs/toolkit';
import { connectivityStoreName } from '../../constants';
const initialState = {
  isLoading: false,
  isSaving: false,
  list: [],
  processIds: [],
  slackChannels: {
    isLoading: false
  }
};
const reducers = {
  initData: () => _extends({}, initialState),
  // Deprecated: use initData instead;
  // cleanData: () => ({ ...initialState }),
  setConnectivityState: (state, {
    payload
  }) => _extends({}, state, payload),
  cleanWebhookTestData: state => _extends({}, state, {
    testResult: undefined
  }),
  cleanWebhookLogsData: state => _extends({}, state, {
    webhookLogs: undefined
  }),
  cleanWebhookTestMessage: state => {
    var _state$testResult;
    return _extends({}, state, {
      testResult: {
        status: (_state$testResult = state.testResult) == null ? void 0 : _state$testResult.status,
        message: undefined
      }
    });
  },
  cleanError: state => _extends({}, state, {
    error: undefined
  }),
  cleanSlackData: state => _extends({}, state, {
    slackChannels: {
      isLoading: false
    }
  }),
  postWebhookRetryAction: {
    prepare: logId => ({
      payload: logId
    }),
    reducer: (state, {
      payload
    }) => _extends({}, state, {
      retryResult: _extends({}, state.retryResult, {
        [payload]: {
          isProcess: true,
          success: false
        }
      })
    })
  },
  postWebhookRetryResult: {
    prepare: payload => ({
      payload
    }),
    reducer: (state, {
      payload
    }) => _extends({}, state, {
      retryResult: _extends({}, state.retryResult, payload)
    })
  }
};
const actions = {
  loadSlackActions: createAction(`${connectivityStoreName}/loadSlackActions`),
  loadDataAction: createAction(`${connectivityStoreName}/loadDataAction`, payload => ({
    payload
  })),
  postDataAction: createAction(`${connectivityStoreName}/postDataAction`, payload => ({
    payload
  })),
  postCodeAction: createAction(`${connectivityStoreName}/postCodeAction`, payload => ({
    payload
  })),
  loadScope: createAction(`${connectivityStoreName}/loadScope`),
  deleteWebhookConfigAction: createAction(`${connectivityStoreName}/deleteWebhookConfigAction`, payload => ({
    payload
  })),
  postWebhookTestAction: createAction(`${connectivityStoreName}/postWebhookTestAction`, payload => ({
    payload
  })),
  loadWebhookLogsAction: createAction(`${connectivityStoreName}/loadWebhookLogsAction`, (id, offset = 0, limit = 10) => ({
    payload: {
      id,
      offset,
      limit
    }
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

export { reducers as connectivityReducers, actions as connectivityActions };