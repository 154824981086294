import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback"],
  _excluded7 = ["callback"],
  _excluded8 = ["callback"];
import { all, call, put, select as sagaSelect, takeEvery, takeLeading } from 'redux-saga/effects';
import { api, RestrictionType } from '@frontegg/rest-api';
import { actions } from '../../reducer';
import { v4 as uuid } from 'uuid';
import { authStoreName } from '../../../constants';
import { dummyIpConfig, dummyIps } from '../../dummy';
import { delay } from '../../utils';
import { errorHandler } from '../../../utils';
import { securityCenterSagaWrapper } from '../SecurityCenterState/saga';
const selectIpRestrictionsState = () => sagaSelect(_ => _[authStoreName].restrictionsState.ipRestrictions);
const selectUserIp = () => sagaSelect(_ => _[authStoreName].userIp);
const selectEmailDomainRestrictionsState = () => sagaSelect(_ => _[authStoreName].restrictionsState.emailDomainRestrictions);
function* loadEmailDomainRestrictions() {
  yield put(actions.setEmailDomainRestrictionsState({
    loading: true,
    error: null
  }));
  try {
    const restrictionsConfig = yield call(api.auth.getDomainRestrictionsConfig);
    const restrictions = yield call(api.auth.getDomainRestrictions);
    const filteredRestrictions = restrictions.items.filter(restriction => restriction.type === restrictionsConfig.listType);
    yield put(actions.setEmailDomainRestrictionsState({
      data: {
        restrictions: filteredRestrictions,
        config: restrictionsConfig
      },
      loading: false
    }));
  } catch (e) {
    yield put(actions.setEmailDomainRestrictionsState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* saveEmailDomainRestriction(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    newRestriction = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setEmailDomainRestrictionsState({
    saving: true,
    error: null
  }));
  const emailDomainRestrictionsState = yield selectEmailDomainRestrictionsState();
  try {
    var _emailDomainRestricti2;
    yield call(api.auth.createDomainRestriction, newRestriction);
    const restrictions = yield call(api.auth.getDomainRestrictions);
    const filteredRestrictions = restrictions.items.filter(restriction => {
      var _emailDomainRestricti;
      return restriction.type === ((_emailDomainRestricti = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti.config.listType);
    });
    yield put(actions.setEmailDomainRestrictionsState({
      data: {
        config: ((_emailDomainRestricti2 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti2.config) || {
          active: false,
          blockPublicDomains: false,
          listType: newRestriction.type
        },
        restrictions: filteredRestrictions
      },
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setEmailDomainRestrictionsState({
      saving: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* saveEmailDomainRestrictionConfig(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    config = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setEmailDomainRestrictionsState({
    saving: true,
    error: null
  }));
  try {
    const updatedConfig = yield call(api.auth.updateDomainRestrictionConfig, config);
    const restrictions = yield call(api.auth.getDomainRestrictions);
    const filteredRestrictions = restrictions.items.filter(restriction => restriction.type === updatedConfig.listType);
    yield put(actions.setEmailDomainRestrictionsState({
      data: {
        config: updatedConfig,
        restrictions: filteredRestrictions
      },
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setEmailDomainRestrictionsState({
      saving: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteEmailDomainRestriction({
  payload: {
    id
  }
}) {
  yield put(actions.setEmailDomainRestrictionsState({
    saving: true,
    error: null
  }));
  const emailDomainRestrictionsState = yield selectEmailDomainRestrictionsState();
  try {
    var _emailDomainRestricti4;
    yield call(api.auth.deleteDomainRestriction, id);
    const restrictions = yield call(api.auth.getDomainRestrictions);
    const filteredRestrictions = restrictions.items.filter(restriction => {
      var _emailDomainRestricti3;
      return restriction.type === ((_emailDomainRestricti3 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti3.config.listType);
    });
    yield put(actions.setEmailDomainRestrictionsState({
      data: {
        config: ((_emailDomainRestricti4 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti4.config) || {
          active: false,
          blockPublicDomains: false,
          listType: RestrictionType.ALLOW
        },
        restrictions: filteredRestrictions
      },
      saving: false
    }));
  } catch (e) {
    yield put(actions.setEmailDomainRestrictionsState({
      saving: false,
      error: errorHandler(e)
    }));
  }
}
const getPagination = statePagination => {
  var _statePagination$_fil, _statePagination$_lim, _statePagination$_off;
  return {
    _filter: (_statePagination$_fil = statePagination == null ? void 0 : statePagination._filter) != null ? _statePagination$_fil : RestrictionType.ALLOW,
    _limit: (_statePagination$_lim = statePagination == null ? void 0 : statePagination._limit) != null ? _statePagination$_lim : 10,
    _offset: (_statePagination$_off = statePagination == null ? void 0 : statePagination._offset) != null ? _statePagination$_off : 0
  };
};
function* loadIpRestrictions() {
  yield put(actions.setIpRestrictionsState({
    loading: true,
    error: null
  }));
  try {
    const ipRestrictionState = yield selectIpRestrictionsState();
    const restrictionsConfig = yield call(api.auth.getIPRestrictionsConfig);
    const pagination = getPagination({
      _filter: restrictionsConfig.strategy,
      _limit: ipRestrictionState.pagination._limit,
      _offset: ipRestrictionState.pagination._offset
    });
    const {
      items: restrictions,
      _metadata: {
        totalPages
      }
    } = yield call(api.auth.getIPRestrictions, pagination);
    yield put(actions.setIpRestrictionsState({
      data: {
        restrictions: restrictions,
        config: restrictionsConfig
      },
      totalPages,
      pagination,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* checkIfUserIpValid() {
  try {
    const {
      valid: userIpValid
    } = yield call(api.auth.testCurrentIpInAllowList);
    yield put(actions.setIpRestrictionsState({
      userIpValid
    }));
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      error: errorHandler(e)
    }));
  }
}
function* addCurrentUserIpAndActivate({
  payload: {
    callback
  }
}) {
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  try {
    const userIp = yield selectUserIp();
    yield all([call(api.auth.createIPRestriction, {
      ip: userIp,
      isActive: true,
      description: 'My IP',
      strategy: RestrictionType.ALLOW
    }), call(api.auth.updateIPRestrictionConfig, {
      isActive: true
    })]);
    const ipRestrictionState = yield selectIpRestrictionsState();
    const pagination = getPagination(ipRestrictionState.pagination);
    const {
      items: restrictions,
      _metadata: {
        totalPages
      }
    } = yield call(api.auth.getIPRestrictions, pagination);
    yield put(actions.setIpRestrictionsState({
      data: {
        config: {
          isActive: true,
          strategy: RestrictionType.ALLOW
        },
        restrictions
      },
      userIpValid: true,
      pagination,
      totalPages,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      saving: false,
      error: e
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* saveIpRestriction(_ref3) {
  let {
      payload: {
        callback
      }
    } = _ref3,
    newRestriction = _objectWithoutPropertiesLoose(_ref3.payload, _excluded3);
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  const ipRestrictionsState = yield selectIpRestrictionsState();
  try {
    var _ipRestrictionsState$, _ipRestrictionsState$2;
    yield call(api.auth.createIPRestriction, newRestriction);
    const ipRestrictionState = yield selectIpRestrictionsState();
    const pagination = getPagination(ipRestrictionState.pagination);
    const {
      items: restrictions,
      _metadata: {
        totalPages
      }
    } = yield call(api.auth.getIPRestrictions, pagination);
    yield checkIfUserIpValid();
    yield put(actions.setIpRestrictionsState({
      data: {
        config: (_ipRestrictionsState$ = (_ipRestrictionsState$2 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$2.config) != null ? _ipRestrictionsState$ : {
          isActive: false,
          strategy: RestrictionType.ALLOW
        },
        restrictions
      },
      pagination,
      totalPages,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      saving: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* saveIpRestrictionsConfig(_ref4) {
  let {
      payload: {
        callback
      }
    } = _ref4,
    config = _objectWithoutPropertiesLoose(_ref4.payload, _excluded4);
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  try {
    yield call(api.auth.updateIPRestrictionConfig, config);
    const ipRestrictionState = yield selectIpRestrictionsState();
    const restrictionsConfig = yield call(api.auth.getIPRestrictionsConfig);
    const pagination = getPagination(_extends({}, ipRestrictionState.pagination, {
      _filter: restrictionsConfig.strategy
    }));
    const {
      items: restrictions,
      _metadata: {
        totalPages
      }
    } = yield call(api.auth.getIPRestrictions, pagination);
    yield checkIfUserIpValid();
    yield put(actions.setIpRestrictionsState({
      data: {
        config: restrictionsConfig,
        restrictions
      },
      pagination,
      totalPages,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      saving: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteIpRestriction({
  payload: {
    id,
    callback
  }
}) {
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  const ipRestrictionsState = yield selectIpRestrictionsState();
  try {
    var _ipRestrictionsState$3;
    yield call(api.auth.deleteIPRestriction, id);
    const ipRestrictionState = yield selectIpRestrictionsState();
    const pagination = getPagination(ipRestrictionState.pagination);
    const {
      items: restrictions,
      _metadata: {
        totalPages
      }
    } = yield call(api.auth.getIPRestrictions, pagination);
    yield checkIfUserIpValid();
    yield put(actions.setIpRestrictionsState({
      data: {
        config: ((_ipRestrictionsState$3 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$3.config) || {
          isActive: false,
          strategy: RestrictionType.ALLOW
        },
        restrictions
      },
      pagination,
      totalPages,
      saving: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      saving: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
export function* restrictionsSagas() {
  yield takeLeading(actions.loadIpRestrictions, securityCenterSagaWrapper(loadIpRestrictions));
  yield takeLeading(actions.loadEmailDomainRestrictions, securityCenterSagaWrapper(loadEmailDomainRestrictions));
  yield takeEvery(actions.saveIpRestriction, securityCenterSagaWrapper(saveIpRestriction));
  yield takeEvery(actions.saveIpRestrictionsConfig, securityCenterSagaWrapper(saveIpRestrictionsConfig));
  yield takeEvery(actions.deleteIpRestriction, securityCenterSagaWrapper(deleteIpRestriction));
  yield takeEvery(actions.checkIfUserIpValid, securityCenterSagaWrapper(checkIfUserIpValid));
  yield takeEvery(actions.addCurrentUserIpAndActivate, securityCenterSagaWrapper(addCurrentUserIpAndActivate));
  yield takeEvery(actions.saveEmailDomainRestriction, securityCenterSagaWrapper(saveEmailDomainRestriction));
  yield takeEvery(actions.saveEmailDomainRestrictionsConfig, securityCenterSagaWrapper(saveEmailDomainRestrictionConfig));
  yield takeEvery(actions.deleteEmailDomainRestriction, securityCenterSagaWrapper(deleteEmailDomainRestriction));
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadIpRestrictionsMock() {
  yield put(actions.setIpRestrictionsState({
    loading: true,
    error: null
  }));
  yield put(actions.setIpRestrictionsState({
    data: {
      restrictions: dummyIps.filter(ip => ip.strategy === dummyIpConfig.strategy),
      config: dummyIpConfig
    },
    totalPages: 1,
    pagination: {
      _filter: RestrictionType.ALLOW,
      _limit: 50,
      _offset: 0
    },
    loading: false
  }));
}
function* checkIfUserIpValidMock() {
  yield put(actions.setIpRestrictionsState({
    loading: true,
    error: null
  }));
  try {
    const ipRestrictionState = yield selectIpRestrictionsState();
    const userIp = dummyIps[0].ip;
    const userIpValid = !!ipRestrictionState.data.restrictions.find(restriction => restriction.isActive && restriction.ip === userIp);
    yield put(actions.setIpRestrictionsState({
      userIpValid,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* saveIpRestrictionMock(_ref5) {
  var _ipRestrictionsState$4, _ipRestrictionsState$5, _newRestriction$isAct;
  let {
      payload: {
        callback
      }
    } = _ref5,
    newRestriction = _objectWithoutPropertiesLoose(_ref5.payload, _excluded5);
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  const ipRestrictionsState = yield selectIpRestrictionsState();
  const filteredRestrictions = ipRestrictionsState.data.restrictions.filter(r => r.ip !== newRestriction.ip);
  yield put(actions.setIpRestrictionsState({
    data: {
      config: (_ipRestrictionsState$4 = (_ipRestrictionsState$5 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$5.config) != null ? _ipRestrictionsState$4 : {
        isActive: false,
        strategy: RestrictionType.ALLOW
      },
      restrictions: [...filteredRestrictions, _extends({}, newRestriction, {
        createdAt: new Date(),
        updatedAt: new Date(),
        id: uuid(),
        isActive: (_newRestriction$isAct = newRestriction.isActive) != null ? _newRestriction$isAct : true,
        strategy: ipRestrictionsState.data.config.strategy
      })]
    },
    totalPages: 1,
    pagination: {
      _filter: RestrictionType.ALLOW,
      _limit: 50,
      _offset: 0
    },
    saving: false
  }));
  callback == null ? void 0 : callback(true);
}
function* saveIpRestrictionsConfigMock(_ref6) {
  var _ipRestrictionsState$6, _ipRestrictionsState$7, _ipRestrictionsState$8;
  let {
      payload: {
        callback
      }
    } = _ref6,
    config = _objectWithoutPropertiesLoose(_ref6.payload, _excluded6);
  const ipRestrictionsState = yield selectIpRestrictionsState();
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  yield put(actions.setIpRestrictionsState({
    data: {
      config: _extends({}, (_ipRestrictionsState$6 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$6.config, config),
      restrictions: (_ipRestrictionsState$7 = (_ipRestrictionsState$8 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$8.restrictions) != null ? _ipRestrictionsState$7 : []
    },
    pagination: {
      _filter: RestrictionType.ALLOW,
      _limit: 50,
      _offset: 0
    },
    totalPages: 1,
    saving: false
  }));
  yield put(actions.setIpRestrictionsState({
    saving: false,
    error: null
  }));
  callback == null ? void 0 : callback(true);
}
function* deleteIpRestrictionMock({
  payload: {
    id,
    callback
  }
}) {
  var _ipRestrictionsState$9;
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  const ipRestrictionsState = yield selectIpRestrictionsState();
  yield put(actions.setIpRestrictionsState({
    data: {
      config: ((_ipRestrictionsState$9 = ipRestrictionsState.data) == null ? void 0 : _ipRestrictionsState$9.config) || {
        isActive: false,
        strategy: RestrictionType.ALLOW
      },
      restrictions: ipRestrictionsState.data.restrictions.filter(ip => ip.id !== id)
    },
    pagination: {
      _filter: RestrictionType.ALLOW,
      _limit: 50,
      _offset: 0
    },
    totalPages: 1,
    saving: false,
    error: null
  }));
  callback == null ? void 0 : callback(true);
}
function* addCurrentUserIpAndActivateMock({
  payload: {
    callback
  }
}) {
  yield put(actions.setIpRestrictionsState({
    saving: true,
    error: null
  }));
  try {
    const userIp = yield selectUserIp();
    yield put(actions.saveIpRestriction({
      ip: userIp,
      description: 'My IP',
      isActive: true
    }));
    yield put(actions.saveIpRestrictionsConfig({
      isActive: true
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setIpRestrictionsState({
      saving: false,
      error: e
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* loadEmailDomainRestrictionsMock() {
  yield put(actions.setEmailDomainRestrictionsState({
    loading: true,
    error: null
  }));
  yield put(actions.setEmailDomainRestrictionsState({
    loading: false,
    data: {
      restrictions: [{
        domain: 'mock.com',
        id: '1',
        type: RestrictionType.ALLOW
      }],
      config: {
        active: true,
        listType: RestrictionType.ALLOW,
        blockPublicDomains: false
      }
    }
  }));
}
function* saveEmailDomainRestrictionMock(_ref7) {
  var _emailDomainRestricti5, _emailDomainRestricti6;
  let {
      payload: {
        callback
      }
    } = _ref7,
    restriction = _objectWithoutPropertiesLoose(_ref7.payload, _excluded7);
  const emailDomainRestrictionsState = yield selectEmailDomainRestrictionsState();
  yield put(actions.setEmailDomainRestrictionsState({
    saving: true,
    error: null
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setEmailDomainRestrictionsState({
    data: {
      config: ((_emailDomainRestricti5 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti5.config) || {
        active: false,
        blockPublicDomains: false,
        listType: restriction.type
      },
      restrictions: [...(((_emailDomainRestricti6 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti6.restrictions) || []), _extends({}, restriction, {
        id: Date.now().toString()
      })]
    },
    saving: false
  }));
}
function* saveEmailDomainRestrictionConfigMock(_ref8) {
  var _emailDomainRestricti7;
  let {
      payload: {
        callback
      }
    } = _ref8,
    config = _objectWithoutPropertiesLoose(_ref8.payload, _excluded8);
  const emailDomainRestrictionsState = yield selectEmailDomainRestrictionsState();
  yield put(actions.setEmailDomainRestrictionsState({
    saving: true,
    error: null
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setEmailDomainRestrictionsState({
    data: {
      config: {
        active: config.active,
        blockPublicDomains: config.blockPublicDomains || false,
        listType: config.type || RestrictionType.ALLOW
      },
      restrictions: [...(((_emailDomainRestricti7 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti7.restrictions) || [])]
    },
    saving: false
  }));
}
function* deleteEmailDomainRestrictionMock({
  payload: {
    id
  }
}) {
  var _emailDomainRestricti8, _emailDomainRestricti9;
  const emailDomainRestrictionsState = yield selectEmailDomainRestrictionsState();
  yield put(actions.setEmailDomainRestrictionsState({
    saving: true,
    error: null
  }));
  yield delay();
  yield put(actions.setEmailDomainRestrictionsState({
    data: {
      config: ((_emailDomainRestricti8 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti8.config) || {
        active: false,
        blockPublicDomains: false,
        listType: RestrictionType.ALLOW
      },
      restrictions: (((_emailDomainRestricti9 = emailDomainRestrictionsState.data) == null ? void 0 : _emailDomainRestricti9.restrictions) || []).filter(restriction => restriction.id === id)
    },
    saving: false
  }));
}
export function* restrictionsSagaMock() {
  yield takeLeading(actions.loadIpRestrictions, loadIpRestrictionsMock);
  yield takeLeading(actions.loadEmailDomainRestrictions, loadEmailDomainRestrictionsMock);
  yield takeEvery(actions.saveIpRestriction, saveIpRestrictionMock);
  yield takeEvery(actions.saveIpRestrictionsConfig, saveIpRestrictionsConfigMock);
  yield takeEvery(actions.deleteIpRestriction, deleteIpRestrictionMock);
  yield takeEvery(actions.checkIfUserIpValid, checkIfUserIpValidMock);
  yield takeEvery(actions.saveEmailDomainRestriction, saveEmailDomainRestrictionMock);
  yield takeEvery(actions.saveEmailDomainRestrictionsConfig, saveEmailDomainRestrictionConfigMock);
  yield takeEvery(actions.deleteEmailDomainRestriction, deleteEmailDomainRestrictionMock);
}

// export function* restrictionsSagas() {
//   yield takeLeading(actions.loadEmailDomainRestrictions, loadEmailDomainRestrictions);
//   yield takeEvery(actions.saveEmailDomainRestriction, saveEmailDomainRestriction);
//   yield takeEvery(actions.saveEmailDomainRestrictionsConfig, saveEmailDomainRestrictionConfig);
//   yield takeEvery(actions.deleteEmailDomainRestriction, deleteEmailDomainRestriction);
// }

// /*********************************
//  *  Preview Sagas
//  *********************************/