<div class="row">
    <ng-container *ngFor="let item of selectionRecord;let i = index">
        <div class="col-sm-2" *ngIf="item?.dataType === 'TEXTBOX' && item?.isDisplay">
            <div class="form-group">
                <label>{{item?.label}}</label>
                <so-textbox [id]="item?.formName" [maxLength]="100"
                    [inputControlName]="$any(filterForm).controls[item.formName]" [placeHolder]="item?.label"
                    [style]="item?.styles" [class]="item?.className" (keyEnter)="_filterBtn()">
                </so-textbox>
            </div>
        </div>
        <div class="col-sm-2" *ngIf="item?.dataType === 'DROPDOWN' && item?.isDisplay && item?.options?.length > 0">
            <div class="form-group">
                <label>{{item?.label}}</label>
                <so-dropdown [id]="item.formName" [options]="item.options" [value]="item.value" [key]="item.key"
                    (onChange)="_dpFilterChange()" [inputControlName]="$any(filterForm).controls[item.formName]"
                    [style]="item?.styles" [class]="item?.className">
                </so-dropdown>
            </div>
        </div>
    </ng-container>
    <div class="col-sm-4 d-flex align-items-end" *ngIf="isShowFilterBtn">
        <div class="form-group">
            <so-button [text]="'Filter'" [style]="" [className]="'btn btn-primary'" (click)="_filterBtn()"></so-button>
            <so-button [text]="'Reset'" [style]="" [className]="'btn btn-secondary'" (click)="_filterBtn('Reset')"></so-button>
        </div>
    </div>
</div>