import { call, put, select, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { errorHandler } from '../../utils';
export function* deleteWebAuthnDevice({
  payload
}) {
  const {
    callback,
    deviceId
  } = payload;
  yield put(actions.setPasskeysState({
    loading: true
  }));
  try {
    yield call(api.auth.deleteWebAuthnDevice, deviceId);
    const {
      devices
    } = yield select(state => state.auth.passkeysState);
    const newDevices = devices.filter(device => device.id !== deviceId);
    yield put(actions.setPasskeysState({
      devices: newDevices,
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setPasskeysState({
      loading: false,
      error: errorHandler(e, null)
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
export function* loadWebAuthnDevices() {
  yield put(actions.setPasskeysState({
    loading: true
  }));
  try {
    const {
      devices
    } = yield call(api.auth.getWebAuthnDevices);
    yield put(actions.setPasskeysState({
      devices: devices,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setPasskeysState({
      loading: false,
      error: errorHandler(e, null)
    }));
  }
}
export function* passkeysSagas() {
  yield takeEvery(actions.loadWebAuthnDevices, loadWebAuthnDevices);
  yield takeEvery(actions.deleteWebAuthnDevice, deleteWebAuthnDevice);
}