export const InlineArchiveDevice = {
    dialogId: 'Archive Device',
    header: 'Archive Device',
    content: 'Are you sure you want to archive <strong class="text-break"> {{#name}}? </strong>',
    btnSuccessText: 'Archive',
    btnCancelText: 'Cancel',
    btnSuccessClass: 'btn-danger',
    btnCancelClass: 'btn-secondary',
    btnSuccessStyle: '',
    bodyClass: '',
    bodyStyle: '',
    dialogClass: '',
    dialogStyle: '',
}