/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitterType, EventService, MessageService, SiteService, Utilities, ValidationService } from '@SiteOwl/core';
import { Component, EventEmitter, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CreateBuildingComponent } from '../create-building/create-building.component';
@Component({
  selector: 'so-create-site',
  templateUrl: './create-site.component.html',
  styleUrls: ['./create-site.component.scss'],
})
export class CreateSiteComponent implements OnInit {
  siteForm!: FormGroup;
  canGoNext = false;
  isFormSubmitted!: boolean;
  isEditMode = false;
  site: any;
  isFromSelect!: any;
  PreviousModalDTO: any = {
    closeAllModal: false,
    newFloors: [],
    buildings: [],
    site: {},
    buildingId: 0
  };
  buildingObj!: any;
  bsModalRefBuilding!: BsModalRef;
  @HostListener('window:beforeunload', ['$event'])
  doSomething($event: any) {
    if ((this.siteForm.dirty)) {
      $event.returnValue = false;
    }
  }

  constructor(private modalService: BsModalService, private eventService: EventService,
    public bsModalRefSite: BsModalRef,
    private messageService: MessageService,
    private siteService: SiteService, private fb: FormBuilder,) { }


  createForm() {
    this.siteForm = this.fb.group({
      name: [null, [Validators.required, ValidationService.onlyWhiteSpaceValidator]],
      address: [null],
      notes: [null, [Validators.maxLength(4000)]],
    });
  }

  createSite(isUT?: any) {
    this.isFormSubmitted = true;
    if (this.siteForm.invalid) {
      return;
    }
    const site = this.siteForm.value;
    site['domotzAgent'] = null;
    if (!this.isEditMode) {
      this.canGoNext = true;
      this.siteService.addSite(site).subscribe((result: any) => {
        this.site = result;
        this.PreviousModalDTO['site'] = this.site
        if (!isUT) {
          this.canGoNext = false;
          this.messageService.successMessage(`Site: ${result.name}`, 'Saved Successfully');
          this.openBuilding({ siteId: result.id, site: result, buildingsOrderNo: 1 });
        }
      })
    } else {
      site['id'] = this.site.id;
      this.siteService.updateSite(site).subscribe(() => {
        this.site = this.PreviousModalDTO.site;
        this.site['name'] = site.name;
        this.PreviousModalDTO.site = _.cloneDeep(this.PreviousModalDTO.site)
        this.messageService.successMessage(`Site: ${site.name}`, 'Saved Successfully');
        const no: any = _.maxBy(this.site.buildings, 'orderNumber');
        const modalData = {
          siteId: this.site.id,
          site: this.site,
          buildingsOrderNo: no && no.orderNumber ? (no.orderNumber + 1) : 1,
          buildingObj: this.buildingObj
        }
        this.openBuilding(modalData)
      })
    }

  }
  openBuilding(data: any) {
    const initialState: ModalOptions = {
      initialState: data, ignoreBackdropClick: true, class: 'newplanM'
    };
    this.bsModalRefBuilding = this.modalService.show(CreateBuildingComponent, initialState);
    this.bsModalRefSite?.setClass('newplanM d-none');
    this.bsModalRefBuilding.content.backToSite.subscribe((r: any) => {
      this.bsModalRefSite?.setClass('newplanM');
      this.bsModalRefBuilding?.hide();
      this.isEditMode = true;
      this.buildingObj = r && r.buildingObj ? r.buildingObj : null;
      this.updateModalDTO(r);
    })
    this.bsModalRefBuilding.content.closeAll.subscribe((r: any) => {
      this.bsModalRefBuilding?.hide();
      this.bsModalRefSite?.hide();
      this.eventService.broadcast(EventEmitterType.callListApi, {});
    })
  }
  updateModalDTO(item: any) {
    if (!Utilities.isNull(item)) {
      if (item.flag || item.closeAllModal) {
        this.bsModalRefBuilding?.hide();
        this.bsModalRefSite.hide();
      } else {
        this.isEditMode = true;
      }
    } else {
      this.isEditMode = true;
    }

  }

  closeModal() {
    this.isFormSubmitted = true;
    const site = this.siteForm.value;
    site['domotzAgent'] = null;
    if (this.siteForm.value && this.siteForm.dirty) {

      if (window.confirm('You have unsaved changes on the current page. Would you like to save your changes?')) {
        if (this.siteForm.invalid) {
          return;
        }
        if (!this.isEditMode) {
          this.siteService.addSite(site)
            .subscribe((result: any) => {
              this.site = result;
              this.PreviousModalDTO.site = this.site
              this.messageService.successMessage(`Site: ${result.name}`, 'Saved Successfully');
              // this.eventService.broadcast(EventEmitterType.SiteViewChanged, null);
              this.PreviousModalDTO.site = result
              this.bsModalRefBuilding?.hide();
              this.bsModalRefSite.hide();
            })
        }
        else {
          site['id'] = this.site.id;
          this.siteService.updateSite(site)
            .subscribe((result: any) => {
              this.site = result;
              this.messageService.successMessage(`Site: ${result.name}`, 'Saved Successfully');
              this.PreviousModalDTO.site = result
              this.bsModalRefBuilding?.hide();
              this.bsModalRefSite.hide();
            })

        }
        this.eventService.broadcast(EventEmitterType.callListApi, {});
      }
      else {
        this.bsModalRefSite.hide();
      }
    }
    else {
      this.bsModalRefSite.hide();
    }

    if (this.isFromSelect) {
      this.eventService.broadcast(EventEmitterType.ClosePopup, {})
    }
  }
  ngOnInit(): void {
    this.createForm();
  }
  ngOnDestroy() {
    this.isEditMode = false;
  }
}
