/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, EventEmitter, HostBinding, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[soDragDropFileUpload]'
})
export class DragDropFileUploadDirective {
  @Input() allowedExtensions: any[] = [];
  @Input() maxSize!: number;
  @Input() isViewOnly!: boolean;
  @Output() filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() filesInvalidEmiter: EventEmitter<File[]> = new EventEmitter();
  @HostBinding('style.border') private border = 'none';

  constructor() { }
  @HostListener('dragover', ['$event'])
  onDragOver(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.isViewOnly) {
      this.border = '2px dotted #999';
    }
  }

  @HostListener('dragleave', ['$event']) onDragLeave(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    this.border = 'none'
  }

  @HostListener('drop', ['$event']) onDrop(evt: any) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.isViewOnly) {
      this.border = 'none';
      return;
    }

    this.border = 'none';
    const files = evt.dataTransfer.files;
    const valid_files: Array<File> = [];
    const invalid_files: Array<File> = [];
    if (files.length > 0) {
      for (let index = 0; index < files.length; index++) {
        const ext = files[index].name.split('.')[files[index].name.split('.').length - 1].toLowerCase();
        if (this.allowedExtensions.lastIndexOf(ext) !== -1 && (!this.maxSize || files[index].size <= (this.maxSize * 1048576))) {
          valid_files.push(files[index]);
        } else {
          invalid_files.push(files[index]);
        }
      }

      this.filesChangeEmiter.emit(valid_files);
      if (invalid_files && invalid_files.length > 0) {
        this.filesInvalidEmiter.emit(invalid_files);
      }
    }
  }
}
