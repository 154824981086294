import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { AcceptInvitationStep } from './interfaces';
import { authStoreName } from '../../constants';
const acceptInvitationState = {
  step: AcceptInvitationStep.validate
};
const reducers = {
  setAcceptInvitationState: typeReducerForKey('acceptInvitationState'),
  resetAcceptInvitationState: resetStateByKey('acceptInvitationState', {
    acceptInvitationState
  })
};
const actions = {
  acceptInvitation: createAction(`${authStoreName}/acceptInvitation`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { acceptInvitationState, reducers as acceptInvitationReducers, actions as acceptInvitationActions };