import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { subscriptionsStoreName } from '../../constants';
import { createModuleCaseReducers } from '../utils';
export const plansInitialState = {
  loading: false,
  error: null,
  fetching: true,
  plans: []
};
const {
  actions: sliceActions,
  reducer,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/plans`,
  initialState: plansInitialState,
  reducers: _extends({}, createModuleCaseReducers())
});
const actions = _extends({
  loadPlans: createAction(`${name}/loadPlans`)
}, sliceActions);
export { actions as plansActions, reducer as plansReducer };