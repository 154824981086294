import _extends from "@babel/runtime/helpers/esm/extends";
import { createModuleCaseReducers } from '../../utils';
import { createAction, createSlice } from '@reduxjs/toolkit';
import { subscriptionsStoreName } from '../../../constants';
export const initialInvoicesState = {
  loading: false,
  error: null,
  fetching: true,
  invoices: [],
  invoiceDownload: {
    loading: false,
    error: null
  }
};
const reducers = _extends({}, createModuleCaseReducers(), {
  setInvoiceDownloadState: {
    prepare: payload => ({
      payload
    }),
    reducer: (state, action) => _extends({}, state, {
      invoiceDownload: _extends({}, state.invoiceDownload, action.payload)
    })
  }
});
const {
  reducer,
  actions: sliceActions,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/billing/invoices`,
  initialState: initialInvoicesState,
  reducers
});
const actions = _extends({
  loadInvoices: createAction(`${name}/loadInvoices`),
  downloadInvoice: createAction(`${name}/downloadInvoice`, payload => ({
    payload
  }))
}, sliceActions);
export { reducer as invoicesReducer, actions as invoicesActions };