<div class="modal-header">
    <h1 class="modal-title pull-left"> Assign Account Administrators</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="associatedAdminForm" (ngSubmit)="inviteUserToCustomer()" novalidate class="add-resource">
        <div class="add-resource-block">
            <div class="form-group" *ngIf="usersForList.length > 0">
                <p>Select core team members from <strong>{{providerName}}</strong> to be added to
                    <strong>{{customerName}}</strong> as Administrators. Default Account Administrators are
                    automatically selected.
                </p>
                <p>You can still add additional team members with other roles after creating the Account.</p>
                <div class="form-group" *ngIf="usersForList.length > 0">
                    <label>All Team Members</label>
                    <div class="add-resource-block">
                        <div class="resource-listing">
                            <div *ngFor="let level of usersForList;let levelIndex = index;">
                                <p *ngIf="level.value.length > 0">{{level.key}}</p>
                                <ul>
                                    <li *ngFor="let user of level.value;let userIndex = index;"
                                        (click)="addUserToEmails(levelIndex, userIndex,user);">
                                        {{user.name === '' || user.name === null ? "(" + user?.email +")" : user.name}}
                                        ({{user.email}})
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Selected Team Members</label>
                <tag-input class="watcher-field" name="emails" [animationDuration]="chipsOption.animationDuration"
                    formControlName="emails" [placeholder]="''" [secondaryPlaceholder]="'Team Members'"
                    [addOnPaste]="chipsOption.addOnPaste" [addOnBlur]="chipsOption.addOnBlur"
                    (onAdd)="onInputBlurred()" (onPaste)="onInputBlurred()"
                    (onRemove)="onInputBlurred()" [modelAsStrings]="chipsOption.modelAsStrings"
                    [theme]="chipsOption.theme" [editable]="chipsOption.editable"
                    [separatorKeyCodes]="chipsOption.separatorKeyCodes" [separatorKeys]="chipsOption.separatorKeys"
                    [pasteSplitPattern]="chipsOption.pasteSplitPattern" [inputText]="setData"  #input>
                    <ng-template let-item="item" let-index="index">
                        <span [ngClass]="{'invalid': toCheckInvalidInput(item.user.email)}"
                            *ngIf="item.user;else emailNotAvilable">
                            {{item.user.name}} ({{item.user.email}})
                        </span>
                        <ng-template #emailNotAvilable>
                            <span [ngClass]="{'invalid': toCheckInvalidInput(item)}">
                                {{item}}</span>
                        </ng-template>
                        <em class="fa fa-close cursor-pointer" (click)="removeTags(input, item, index);"></em>
                    </ng-template>
                    <tag-input-dropdown [appendToBody]="true" [autocompleteItems]="usersForAutoComplete">
                        <ng-template let-item="item" let-index="index">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="user-profile-block clearfix">
                                        <div class="user-profile-img">
                                            <img alt=""
                                                [src]="item.value.user.thumbnailAwsImageURL || (cdnUrl + 'assets/images/user-image.png')"
                                                class="img-responsive">
                                        </div>
                                        <div class="user-desc">
                                            <div class="user-name user-name-resource"
                                                title="{{item.value.user.name || '(' + item?.value?.user?.email +')'}}">
                                                {{item.value.user.name || "(" + item?.value?.user?.email +")" }}
                                            </div>
                                            <a class="user-email user-email-resource" title="{{item.value.user.email}}"
                                                href="javascript:void(0);">{{item.value.user.email}}</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </tag-input-dropdown>
                </tag-input>
            </div>
        </div>
        <div class="modal-footer p-0">
            <div class="row ">
                <div class="col-sm-6">
                    <div class="progress-container">
                        <ul class="progressbar">
                            <li class="active">Account Information</li>
                            <li class="active">Assign Administrators</li>
                            <li *ngIf="customers.length > 0">Import Favorites</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 d-flex align-items-center justify-content-end">
                    <button type="button" class="btn btn-secondary" (click)="backClick()">Back</button>
                    <button type="button" class="btn btn-primary m-0"
                        [ngStyle]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}"
                        [disabled]="btnService.getStatus()" (click)="inviteUserToCustomer()">{{customers.length > 0 ?
                        'Save and Continue' : 'Complete'}}</button>
                </div>
            </div>
        </div>
    </form>
</div>