/* eslint-disable @typescript-eslint/no-explicit-any */
import { ListService } from '@SiteOwl/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

@Component({
  selector: 'so-multi-select-site',
  templateUrl: './multi-select-site.component.html',
  styleUrls: ['./multi-select-site.component.scss'],
})
export class MultiSelectSiteComponent implements OnInit {
  @Input() sites!: any;
  @Input() addedfloorIds!: any;
  @Output() updateFloorIds: EventEmitter<any> = new EventEmitter();
  selectedAllPlans = 0;
  totalPlans = 0;
  isSelectedAll = false;
  canToggle = true;
  navigate!: boolean;
  selectedFloors: any;
  constructor(public bsModalRef: BsModalRef, private listService: ListService) {

  }
  ngOnInit(): void {

    this.getSites();

  }
  checkSelectedSite() {
    let tmp;
    this.sites.forEach((site: any) => {
      site.buildings.forEach((building: any) => {
        building.floors.forEach((floor: any) => {
          if (!floor.isFloorSelected) {
            tmp = true;
          }
        })
      })
    })
    if (tmp) {
      this.canToggle = true
    } else {
      this.canToggle = false
    }
    if (this.sites.every((x: any) => x.isSelectedAllBuilding)) {
      this.isSelectedAll = true
    }


    let checkbuilding;
    let checksite;
    this.sites.forEach((site: any) => {
      checksite = false;
      site.buildings.forEach((building: any) => {
        checkbuilding = false
        building.floors.forEach((floor: any) => {
          if (this.addedfloorIds.some((x: any) => x == floor.id)) {
            checkbuilding = true
            floor.isFloorSelected = true
          } else {
            checkbuilding = false
            floor.isFloorSelected = false
          }
        })
        building.isSelectedAllFloor = checkbuilding;
      })
      if (site.buildings.every((x: any) => x.isSelectedAllFloor)) {
        site.isSelectedAllBuilding = true;
      }
    })
    this.getUpdatedFloors()
  }
  getSites() {
    if (this.sites && this.sites.length === 0) {
      this.listService.getSitesIdAndName().subscribe((result: any) => {
        this.sites = result.filter((x: any) => !x.isArchived);
      });
    } else {
      this.sites.forEach((element: any) => {
        if (!element.isArchived) {
          element['totalSiteFloorCnt'] = 0
          element.buildings.forEach((elementBuild: any) => {
            if (!elementBuild.isArchived) {
              this.totalPlans = this.totalPlans + elementBuild.floors.length;
              element['totalSiteFloorCnt'] = element['totalSiteFloorCnt'] + elementBuild.floors.length
            }
            elementBuild.floors.forEach((floor: any) => {
              floor.name = _.cloneDeep(_.escape(floor.name));
            });
          });
        }
      });
      this.sites = this.sites.filter((x: any) => !x.isArchived);
      if (this.addedfloorIds && this.addedfloorIds.length > 0) {
        this.checkSelectedSite();
      }
    }
  }

  selectAllSitePlan(site: any, event: any) {
    this.navigate = true;
    this.sites.forEach((element: any) => {
      if (site.id === element.id) {
        element.isSelectedAllBuilding = event.target.checked;
        element.buildings.forEach((elementBuilding: any) => {
          if (elementBuilding.floors.length > 0) {
            elementBuilding.isSelectedAllFloor = event.target.checked;
            elementBuilding.floors.forEach((elementSelectionFloor: any) => {
              if (this.canEditFloor(elementSelectionFloor)) {
                elementSelectionFloor.isFloorSelected = event.target.checked;
              }
            })
          }
        });

      }
    });
    this.getUpdatedFloors();
  }

  getUpdatedFloors() {
    this.selectedFloors = [];
    this.isSelectedAll = true;
    this.selectedAllPlans = 0;
    this.sites.forEach((site: any) => {
      site.siteFloorcnt = 0;
      site.buildings.forEach((building: any) => {
        const selectedFloor = building.floors.filter((y: any) => y.isFloorSelected === true)
        building.buildingFloorcnt = selectedFloor ? selectedFloor.length : 0;
        site.siteFloorcnt = site.siteFloorcnt + building.buildingFloorcnt;
        building.floors.forEach((floor: any) => {
          if (floor.isFloorSelected) {
            this.selectedFloors.push(floor.id)
          }
        })
      });
      this.selectedAllPlans = site.siteFloorcnt + this.selectedAllPlans
    })

    if (this.sites.some((x: any) => !x.isSelectedAllBuilding)) {
      this.isSelectedAll = false;
    } else {
      this.isSelectedAll = true;
    }
    this.updateFloorIds.emit(this.selectedFloors)
  }
  checkFloorsIsAvailable(floor: any, building?: any, site?: any, main?: any) {
    if (site && site.buildings.length === 0) {
      return false
    }
    if (site && site.buildings.length > 0) {
      let cntB = 0
      site.buildings.forEach((b: any) => {
        if (b.floors.length > 0) {
          cntB = cntB + 1
        }
      })
      if (cntB === 0) {
        return false
      }
    }
    if (building && building.floors.length == 0) {
      return false
    }

    return true
  }
  checkBuilding(building: any) {
    if (building.floors.length === 0) {
      return false
    }
    return building.floors.some((floor: any) => this.addedfloorIds.every((id: any) => floor.id !== id))
  }
  canEditFloor(floor: any, building?: any, site?: any, main?: any) {
    if (!this.checkFloorsIsAvailable(floor, building, site, main)) {
      return false
    }

    if (this.addedfloorIds && this.addedfloorIds.length > 0) {
      if (site) {
        return site.buildings.some((building: any) => this.checkBuilding(building))
      }
      else if (building) {
        return this.checkBuilding(building)
      } else if (floor) {
        if (this.addedfloorIds.every((addedFloor: any) => addedFloor !== floor.id)) {
          return true;
        }
        return false;
      }
    } else {
      return true
    }
  }

  selectPlan(site: any, building: any, floor: any, event: any) {
    building.isSelectedAllFloor = true;
    this.navigate = true;
    site.isSelectedAllBuilding = true;
    building.floors.find((x: any) => x.id === floor.id).isFloorSelected = event.target.checked;
    if (!event.target.checked || building.floors.some((x: any) => !x.isFloorSelected)) {
      building.isSelectedAllFloor = false;
    }
    if (site.buildings.some((x: any) => !x.isSelectedAllFloor)) {
      site.isSelectedAllBuilding = false;
    }
    this.sites.forEach((element: any) => {
      if (element.id === site.id) {
        element.buildings.forEach((elementBuilding: any) => {
          if (elementBuilding.id === building.id) {
            elementBuilding.floors.forEach((elementFloor: any) => {
              if (floor.id === elementFloor.id) {
                elementFloor.isFloorSelected = event.target.checked;
              }
            });
          }
        })
      }
    });
    this.getUpdatedFloors();
  }


  selectAllBuildingPlan(site: any, building: any, event: any) {
    site.isSelectedAllBuilding = true;
    this.navigate = true;
    site.buildings.find((x: any) => x.id === building.id).isSelectedAllFloor = event.target.checked;
    if (!event.target.checked || site.buildings.some((x: any) => !x.isSelectedAllFloor)) {
      site.isSelectedAllBuilding = false;
    }
    this.sites.forEach((element: any) => {
      if (site.id === element.id) {
        element.buildings.forEach((elementBuilding: any) => {
          if (building.id === elementBuilding.id) {
            elementBuilding.isSelectedAllFloor = event.target.checked;
            elementBuilding.floors.forEach((elementFloor: any) => {
              if (this.canEditFloor(elementFloor)) {
                elementFloor.isFloorSelected = event.target.checked;
              } else {
                elementFloor.isFloorSelected = true;
              }
            })
          }
        });
      }
    });
    this.getUpdatedFloors();
  }

  selectAllPlan(event?: any) {
    this.navigate = true;
    this.isSelectedAll = event.target.checked;
    if (!this.isSelectedAll) {
      this.selectedAllPlans = 0;
    }
    this.sites.forEach((site: any) => {
      if (this.checkFloorsIsAvailable(null, null, site, null)) {
        site.isSelectedAllBuilding = event.target.checked;
        site.buildings.forEach((building: any) => {
          if (building.floors.length > 0) {
            building.isSelectedAllFloor = event.target.checked;
            building.floors.forEach((elementFloor: any) => {
              if (this.canEditFloor(elementFloor)) {
                elementFloor.isFloorSelected = event.target.checked;
              }
            });
          }
        });
      }
    })
    this.getUpdatedFloors();
  }
}
