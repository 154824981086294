import _extends from "@babel/runtime/helpers/esm/extends";
import { call, delay, put, takeEvery } from 'redux-saga/effects';
import { invoicesActions } from './index';
import { api } from '@frontegg/rest-api';
import { invoicesMock } from '../../dummy';
import { errorHandler } from '../../../utils';
export function* subscriptionInvoicesSagas() {
  yield takeEvery(invoicesActions.loadInvoices, loadInvoices);
  yield takeEvery(invoicesActions.downloadInvoice, downloadInvoice);
}
export function* loadInvoices() {
  yield put(invoicesActions.setLoading(true));
  try {
    const responseInvoices = yield call(api.subscriptions.getSubscriptionInvoices);
    const invoices = responseInvoices.map(invoice => ({
      id: invoice.id,
      externalId: invoice.externalId,
      subscriptionId: invoice.subscriptionId,
      paymentDate: new Date(Date.parse(invoice.paymentDate)),
      totalAmount: +((invoice.totalAmount || 0) / 100).toFixed(2),
      currency: invoice.currency || 'usd',
      paid: invoice.paid || false,
      receiptNumber: invoice.receiptNumber
    }));
    yield put(invoicesActions.setState({
      loading: false,
      fetching: false,
      invoices
    }));
  } catch (e) {
    yield put(invoicesActions.setError(errorHandler(e)));
  }
}
function* downloadInvoice({
  payload
}) {
  yield put(invoicesActions.setInvoiceDownloadState({
    loading: true,
    error: null
  }));
  try {
    yield call(api.subscriptions.getSubscriptionInvoicePdf, payload.invoiceId, payload.filename);
    yield put(invoicesActions.setInvoiceDownloadState({
      loading: false,
      error: null
    }));
  } catch (e) {
    yield put(invoicesActions.setInvoiceDownloadState({
      loading: false,
      error: errorHandler(e, null)
    }));
  }
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadInvoicesMock() {
  yield put(invoicesActions.setLoading(true));
  yield delay(500);
  const selectPlanTitle = 'Premium';
  yield put(invoicesActions.setState({
    loading: false,
    fetching: false,
    invoices: invoicesMock.map(invoice => _extends({}, invoice, {
      selectedPlan: selectPlanTitle,
      paymentDate: new Date(Date.parse(invoice.paymentDate)),
      totalAmount: +((invoice.totalAmount || 0) / 100).toFixed(2)
    }))
  }));
  yield put(invoicesActions.setLoading(false));
}
export function* subscriptionInvoicesSagasMock() {
  yield takeEvery(invoicesActions.loadInvoices, loadInvoicesMock);
}