/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utilities } from '@SiteOwl/core';

@Directive({ selector: '[soImgOrientation]' })
export class ImageOrientationDirective implements OnInit {


    @Input() parent!: string;
    @Input() index!: any;
    @Input() last: any;
    @Input() image: any;
    @Input() isPixie!: any
    @Input() isFloorPlanDevices!: any;


    @Input() apply: any = null;

    @Output() loaderOff = new EventEmitter<boolean>();
    @Output() loaderDone = new EventEmitter<boolean>();


    classNames = ['carousel slide'];
    parentStyleChange: any = null;

    constructor(private elementRef: ElementRef, private cd: ChangeDetectorRef) { }

    private checkOrientation(data: any) {
        this.getOrientation(this.dataURLtoFile(data), (orientation: any) => {
            if (orientation === 6) {

                if (this.elementRef.nativeElement.clientWidth > 0) {
                    if (this.parent === 'slide') {
                        this.parentStyleChange.style.height = `${this.elementRef.nativeElement.clientWidth}px`;

                    } else if (this.parent === 'modal-profile') {
                        this.parentStyleChange.style.height = `${this.elementRef.nativeElement.clientWidth + 40}px`;
                    } else if (this.parent === 'equipment-thumb-img') {
                        this.parentStyleChange.style.overflow = 'unset';

                    }
                }
                if (this.parent === 'profile-pic') {
                    this.setTransform('rotate(90deg) scale(0.55) ');

                } else {
                    this.setTransform('rotate(90deg) ');
                }
                this.loaderOff.emit(false);
                this.loaderDone.emit(false);




                this.elementRef.nativeElement.style.opacity = 1;

            } else {
                this.loaderOff.emit(false);
                this.loaderDone.emit(false);


                this.elementRef.nativeElement.style.opacity = 1;
            }

        });
    }
    ngOnChanges() {
        if (this.image && !this.isFloorPlanDevices) {

            this.elementRef.nativeElement.addEventListener('load', (event: any) => {
                event.preventDefault();
                if (this.isPixie) {

                    this.loaderOff.emit(false);

                }
            }, { passive: true })
            this.parentStyleChange = Utilities.isNull(this.parent) ? null : document.getElementsByClassName(this.parent)[this.index];
            if (this.image && this.image.indexOf('data:image') > -1) {

                this.checkOrientation(this.image);

                if (!Utilities.isNull(this.apply) && this.apply.isNewFile && Utilities.isNull(this.apply.modifiedDateTime)) {
                    this.setTransform('unset');
                }

            } else {
                const request = new XMLHttpRequest();
                request.open('GET', this.image);

                // request.setRequestHeader('Cache-Control', 'No-cache');
                request.responseType = 'blob';

                request.onload = (e) => {
                    if (request.status === 200) {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            this.loaderDone.emit(false);

                            this.checkOrientation(reader.result);
                            this.elementRef.nativeElement.src = reader.result as string;
                            this.image = reader.result as string;

                            this.loaderOff.emit(false);

                        }
                        reader.readAsDataURL(request.response);
                    } /* else {
                        if (this.parent === 'equipment-thumb-img') {
                            this.thumbnailCheck();
                        }
                    } */
                };

                request.onerror = () => {
                    // this.elementRef.nativeElement.src = this.apply.awsImageURL;
                };
                request.send();
            }

            // });
        }

    }
    ngOnInit() {
        if (this.isFloorPlanDevices) {

            this.elementRef.nativeElement.style.opacity = 0;

            this.loaderOff.emit(true);
            this.elementRef.nativeElement.addEventListener('load', (event: any) => {
                event.preventDefault();
                this.parentStyleChange = Utilities.isNull(this.parent) ? null : document.getElementsByClassName(this.parent)[this.index];

                if (this.elementRef && this.elementRef.nativeElement.src.indexOf('data:image') > -1) {

                    this.checkOrientation(this.elementRef.nativeElement.src);

                    if (!Utilities.isNull(this.apply) && this.apply.isNewFile && Utilities.isNull(this.apply.modifiedDateTime)) {
                        this.setTransform('unset');
                    }

                } else {
                    const request = new XMLHttpRequest();
                    request.open('GET', this.elementRef.nativeElement.src);
                    request.setRequestHeader('Cache-Control', 'No-cache');
                    request.responseType = 'blob';

                    request.onload = (e) => {
                        if (request.status === 200) {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                this.checkOrientation(reader.result);
                            }
                            reader.readAsDataURL(request.response);
                        } /* else {
                        if (this.parent === 'equipment-thumb-img') {
                            this.thumbnailCheck();
                        }
                    } */
                    };

                    request.onerror = () => {
                        // this.elementRef.nativeElement.src = this.apply.awsImageURL;
                    };
                    request.send();
                }

            }, { passive: true });
        }
    }

    thumbnailCheck() {
        const request = new XMLHttpRequest();
        request.open('GET', this.apply.awsImageURL);
        request.setRequestHeader('Cache-Control', 'No-cache');
        request.responseType = 'blob';

        request.onload = (e) => {
            if (request.status === 200) {
                const reader = new FileReader();
                reader.onloadend = () => {
                    this.elementRef.nativeElement.src = reader.result as string;
                    this.checkOrientation(reader.result);
                    this.loaderOff.emit(true);

                }
                reader.readAsDataURL(request.response);
            }
            this.cd.detectChanges();
        };
        request.send();

    }

    dataURLtoFile(dataurl: any) {
        const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n)
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        if (window.navigator.userAgent.indexOf("Edge") > -1) {
            return new Blob([u8arr], { type: mime });
        } else {
            return new File([u8arr], 'filename', { type: mime });
        }
    }

    getOrientation(file: any, callback: any) {
        const reader = new FileReader();

        reader.onload = function (e: any) {
            const view = new DataView(e.target['result']);

            // check for jpeg marker '0xffd8', return if not img
            if (view.getUint16(0, false) !== 0xFFD8) {
                return callback(-2)
            };

            const length = view.byteLength;
            let offset = 2;

            while (offset < length) {
                const marker = view.getUint16(offset, false);
                offset += 2;

                // check for EXIF marker '0xFFE1'
                if (marker === 0xFFE1) {
                    if (view.getUint32(offset += 2, false) !== 0x45786966) {
                        return callback(-1)
                    }
                    const little = view.getUint16(offset += 6, false) === 0x4949;
                    offset += view.getUint32(offset + 4, little);
                    const tags = view.getUint16(offset, little);
                    offset += 2;
                    for (let i = 0; i < tags; i++) {
                        if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                            return callback(view.getUint16(offset + (i * 12) + 8, little));
                        }
                    }
                } else if ((marker && 0xFF00) !== 0xFF00) {
                    break;
                } else {
                    offset += view.getUint16(offset, false);
                }
            }
            return callback(-1);
        };
        reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
    }

    setTransform(transform: any) {
        this.elementRef.nativeElement.style['-ms-transform'] = transform;
        this.elementRef.nativeElement.style['-webkit-transform'] = transform;
        this.elementRef.nativeElement.style['-moz-transform'] = transform;
        this.elementRef.nativeElement.style['transform'] = transform;
    }
}
