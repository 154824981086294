/* eslint-disable @angular-eslint/component-selector */
import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  Input,
  Output,
  OnChanges,
  ElementRef,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  ChangeDetectionStrategy,
  SimpleChanges,
  PLATFORM_ID,
  Inject
} from '@angular/core';
import { Orientation, TextAnchor } from '@swimlane/ngx-charts';

@Component({
  selector: 'g[so-charts-y-axis-ticks]',
  templateUrl: './yaxis-ticks-component.component.html',
  styleUrls: ['./yaxis-ticks-component.component.scss'],
})
export class YAxisTicksComponentComponent {
  @Input() scale: any;
  @Input() orient!: Orientation;
  @Input() tickArguments: number[] = [5];
  @Input() tickValues!: any;
  @Input() tickStroke = '#ccc';
  @Input() trimTicks = true;
  @Input() maxTickLength = 16;
  @Input() tickFormatting: any;
  @Input() showGridLines = false;
  @Input() gridLineWidth!: number;
  @Input() height!: number;
  @Input() referenceLines: any;
  @Input() showRefLabels = false;
  @Input() showRefLines = false;
  @Input() wrapTicks = false;

  @Output() dimensionsChanged = new EventEmitter();

  innerTickSize = 6;
  tickPadding = 3;
  tickSpacing!: number;
  verticalSpacing = 20;
  textAnchor: TextAnchor = TextAnchor.Middle;
  dy!: string;
  x1!: number;
  x2!: number;
  y1!: number;
  y2!: number;
  adjustedScale: any;
  transform!: (o: any) => string;
  tickFormat!: (o: any) => string;
  ticks!: any[];
  width = 0;
  outerTickSize = 6;
  rotateLabels = false;
  refMax!: number;
  refMin!: number;
  referenceLineLength = 0;
  referenceAreaPath!: string;

  readonly Orientation = Orientation;

  @ViewChild('ticksel') ticksElement!: ElementRef;

  constructor(@Inject(PLATFORM_ID) private platformId: any) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.update();
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.updateDims());
  }

  updateDims(): void {
    if (!isPlatformBrowser(this.platformId)) {
      // for SSR, use approximate value instead of measured
      this.width = this.getApproximateAxisWidth();
      this.dimensionsChanged.emit({ width: this.width });
      return;
    }

    const width = parseInt(this.ticksElement.nativeElement.getBoundingClientRect().width, 10);
    if (width !== this.width) {
      this.width = width;
      this.dimensionsChanged.emit({ width });
      setTimeout(() => this.updateDims());
    }
  }

  update(): void {
    const scale = this.scale;
    const sign = this.orient === Orientation.Top || this.orient === Orientation.Right ? -1 : 1;
    this.tickSpacing = Math.max(this.innerTickSize, 0) + this.tickPadding;

    // this.ticks = this.getTicks();

    if (this.tickFormatting) {
      this.tickFormat = this.tickFormatting;
    } else if (scale.tickFormat) {
      // eslint-disable-next-line prefer-spread
      this.tickFormat = scale.tickFormat.apply(scale, this.tickArguments);
    } else {
      this.tickFormat = function (d) {
        if (d.constructor.name === 'Date') {
          return d.toLocaleDateString();
        }
        return d.toLocaleString();
      };
    }

    this.adjustedScale = scale.bandwidth
      ? (d: any) => {
        // position the tick to middle considering number of lines of the tick
        const positionMiddle = scale(d) + scale.bandwidth() * 0.5;
        if (this.wrapTicks && d.toString().length > this.maxTickLength) {
          const chunksLength = this.tickChunks(d).length;

          if (chunksLength === 1) {
            return positionMiddle;
          }

          const bandWidth = scale.bandwidth();
          const heightOfLines = chunksLength * 8;
          const availableFreeSpace = bandWidth * 0.5 - heightOfLines * 0.5;

          return scale(d) + availableFreeSpace;
        }

        return positionMiddle;
      }
      : scale;

    if (this.showRefLines && this.referenceLines) {
      this.setReferencelines();
    }

    switch (this.orient) {
      case Orientation.Top:
        this.transform = function (tick) {
          return 'translate(' + this.adjustedScale(tick) + ',0)';
        };
        this.textAnchor = TextAnchor.Middle;
        this.y2 = this.innerTickSize * sign;
        this.y1 = this.tickSpacing * sign;
        this.dy = sign < 0 ? '0em' : '.71em';
        break;
      case Orientation.Bottom:
        this.transform = function (tick) {
          return 'translate(' + this.adjustedScale(tick) + ',0)';
        };
        this.textAnchor = TextAnchor.Middle;
        this.y2 = this.innerTickSize * sign;
        this.y1 = this.tickSpacing * sign;
        this.dy = sign < 0 ? '0em' : '.71em';
        break;
      case Orientation.Left:
        this.transform = function (tick) {
          return 'translate(0,' + this.adjustedScale(tick) + ')';
        };
        this.textAnchor = TextAnchor.End;
        this.x2 = this.innerTickSize * -sign;
        this.x1 = this.tickSpacing * -sign;
        this.dy = '.32em';
        break;
      case Orientation.Right:
        this.transform = function (tick) {
          return 'translate(0,' + this.adjustedScale(tick) + ')';
        };
        this.textAnchor = TextAnchor.Start;
        this.x2 = this.innerTickSize * -sign;
        this.x1 = this.tickSpacing * -sign;
        this.dy = '.32em';
        break;
      default:
    }
    setTimeout(() => this.updateDims());
  }

  setReferencelines(): void {
    this.refMin = this.adjustedScale(
      Math.min.apply(
        null,
        this.referenceLines.map((item: any) => item.value)
      )
    );
    this.refMax = this.adjustedScale(
      Math.max.apply(
        null,
        this.referenceLines.map((item: any) => item.value)
      )
    );
    this.referenceLineLength = this.referenceLines.length;

    this.referenceAreaPath = roundedRect(0, this.refMax, this.gridLineWidth, this.refMin - this.refMax, 0, [
      false,
      false,
      false,
      false
    ]);
  }

  getTicks(): any[] {
    let ticks;
    const maxTicks = this.getMaxTicks(20);
    const maxScaleTicks = this.getMaxTicks(50);

    if (this.tickValues) {
      ticks = this.tickValues;
    } else if (this.scale.ticks) {
      ticks = this.scale.ticks.apply(this.scale, [maxScaleTicks]);
    } else {
      ticks = this.scale.domain();
      ticks = reduceTicks(ticks, maxTicks);
    }

    return ticks;
  }

  getMaxTicks(tickHeight: number): number {
    return Math.floor(this.height / tickHeight);
  }

  tickTransform(tick: number): string {
    return `translate(${this.adjustedScale(tick)},${this.verticalSpacing})`;
  }

  gridLineTransform(): string {
    return `translate(5,0)`;
  }

  tickTrim(label: string): string {
    return this.trimTicks ? trimLabel(label, this.maxTickLength) : label;
  }

  getApproximateAxisWidth(): number {
    const maxChars = this.ticks.length;
    const charWidth = 7;
    return maxChars * charWidth;
  }

  tickChunks(label: string): string[] {
    if (label.toString().length > this.maxTickLength && this.scale.bandwidth) {
      // for y-axis the width of the tick is fixed
      const preferredWidth = this.maxTickLength;
      const maxLines = Math.floor(this.scale.bandwidth() / 15);

      if (maxLines <= 1) {
        return [this.tickTrim(label)];
      }

      return getTickLines(label, preferredWidth, Math.min(maxLines, 5));
    }

    return [this.tickFormat(label)];
  }
  translateUniCodeToHexCode(content: any) {
    const convertToHex = content.slice(2);
    return JSON.parse(`["&#x${convertToHex};"]`)[0];
  }
}
export function reduceTicks(ticks: any[], maxTicks: number): any[] {
  if (ticks.length > maxTicks) {
    const reduced = [];
    const modulus = Math.floor(ticks.length / maxTicks);
    for (let i = 0; i < ticks.length; i++) {
      if (i % modulus === 0) {
        reduced.push(ticks[i]);
      }
    }
    ticks = reduced;
  }

  return ticks;
}

export function getTickLines(label: string, maxLength: number, maxLines: number): string[] {
  const labelString = (label || '').toString();
  let totalLines = [];

  if (/\s/.test(labelString)) {
    totalLines = labelString.split(/\s+/).reduce((lines: string[], line: string) => {
      const last = (lines.pop() || '') + ' ';
      return last.length + line.length > maxLength ? [...lines, last.trim(), line.trim()] : [...lines, last + line];
    }, []);
  } else {
    let startIndex = 0;
    while (startIndex < labelString.length) {
      totalLines.push(labelString.substring(startIndex, startIndex + maxLength));
      startIndex += maxLength;
    }
  }

  if (totalLines.length > maxLines) {
    totalLines = totalLines.splice(0, maxLines);
    totalLines[totalLines.length - 1] += '...';
  }

  return totalLines;
}
export function trimLabel(s: any, max: number = 16): string {
  if (typeof s !== 'string') {
    if (typeof s === 'number') {
      return s + '';
    } else {
      return '';
    }
  }

  s = s.trim();
  if (s.length <= max) {
    return s;
  } else {
    return `${s.slice(0, max)}...`;
  }
}
export function roundedRect(
  x: number,
  y: number,
  w: number,
  h: number,
  r: number,
  [tl, tr, bl, br]: boolean[]
): string {
  let retval = '';

  w = Math.floor(w);
  h = Math.floor(h);

  w = w === 0 ? 1 : w;
  h = h === 0 ? 1 : h;

  retval = `M${[x + r, y]}`;
  retval += `h${w - 2 * r}`;

  if (tr) {
    retval += `a${[r, r]} 0 0 1 ${[r, r]}`;
  } else {
    retval += `h${r}v${r}`;
  }

  retval += `v${h - 2 * r}`;

  if (br) {
    retval += `a${[r, r]} 0 0 1 ${[-r, r]}`;
  } else {
    retval += `v${r}h${-r}`;
  }

  retval += `h${2 * r - w}`;

  if (bl) {
    retval += `a${[r, r]} 0 0 1 ${[-r, -r]}`;
  } else {
    retval += `h${-r}v${-r}`;
  }

  retval += `v${2 * r - h}`;

  if (tl) {
    retval += `a${[r, r]} 0 0 1 ${[r, -r]}`;
  } else {
    retval += `v${-r}h${r}`;
  }

  retval += `z`;

  return retval;
}