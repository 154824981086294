/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import {
  BaseChartComponent,
  ColorHelper,
  TextAnchor,
  ViewDimensions,
  calculateViewDimensions,
  reduceTicks,
  trimLabel,
  BarChartType, Orientation
} from '@swimlane/ngx-charts';
import { scaleBand, scaleLinear } from 'd3-scale';



@Component({
  selector: 'so-bar-vertical-stacked',
  templateUrl: './bar-vertical-stacked.component.html',
  styleUrls: ['./bar-vertical-stacked.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition(':leave', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({ opacity: 0, transform: 'scale(0)' }))
      ])
    ])
  ]
})
export class BarVerticalStackedComponent extends BaseChartComponent {

  @Input() legend = false;
  @Input() legendTitle = 'Legend';
  @Input() legendPosition: any = 'right';
  @Input() xAxis: any;
  @Input() yAxis: any;
  @Input() showXAxisLabel: any;
  @Input() showYAxisLabel: any;
  @Input() xAxisLabel: any;
  @Input() yAxisLabel: any;
  @Input() tooltipDisabled = false;
  @Input() gradient!: boolean;
  @Input() showGridLines = true;
  @Input() activeEntries: any[] = [];
  @Input() override schemeType!: any;
  @Input() trimXAxisTicks = true;
  @Input() trimYAxisTicks = true;
  @Input() rotateXAxisTicks = true;
  @Input() maxXAxisTickLength = 16;
  @Input() maxYAxisTickLength = 16;
  @Input() xAxisTickFormatting: any;
  @Input() yAxisTickFormatting: any;
  @Input() xAxisTicks!: any[];
  @Input() yAxisTicks!: any[];
  @Input() barPadding = 8;
  @Input() roundDomains = false;
  @Input() yScaleMax!: number;
  @Input() showDataLabel = false;
  @Input() dataLabelFormatting: any;
  @Input() noBarWhenZero = true;
  @Input() from: any = '';
  @Input() maxTickLength = 16;
  @Input() tickValues!: string[] | number[];
  @Input() isLivePlanning = false;
  @Input() trimTicks = true;
  @Output() activate: EventEmitter<any> = new EventEmitter();
  @Output() deactivate: EventEmitter<any> = new EventEmitter();

  barCharType: BarChartType = BarChartType.Stacked;
  @ContentChild('tooltipTemplate') tooltipTemplate!: TemplateRef<any>;

  dims!: ViewDimensions;
  groupDomain!: any[];
  innerDomain!: any[];
  valueDomain!: any[];
  xScale: any;
  yScale: any;
  transform!: string;
  tickFormatting!: (label: string) => string;
  colors!: ColorHelper;
  margin = [10, 20, 10, 20];
  xAxisHeight = 30;
  yAxisWidth = 0;
  legendOptions: any;
  dataLabelMaxHeight: any = { negative: 0, positive: 0 };
  verticalSpacing = 10;
  scale: any = 1;
  textTransform!: string;
  textAnchor: TextAnchor = TextAnchor.Middle;

  override update(): void {
    super.update();


    if (!this.showDataLabel) {
      this.dataLabelMaxHeight = { negative: 0, positive: 0 };
    }
    this.margin = [10 + this.dataLabelMaxHeight.positive, 20, 10 + this.dataLabelMaxHeight.negative, 20];

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margin,
      showXAxis: this.xAxis,
      showYAxis: this.yAxis,
      xAxisHeight: this.xAxisHeight,
      yAxisWidth: this.yAxisWidth,
      showXLabel: this.showXAxisLabel,
      showYLabel: this.showYAxisLabel,
      showLegend: this.legend,
      legendType: this.schemeType,
      legendPosition: this.legendPosition
    });

    if (this.showDataLabel) {
      this.dims.height -= this.dataLabelMaxHeight.negative;
    }

    this.formatDates();

    this.groupDomain = this.getGroupDomain();
    this.innerDomain = this.getInnerDomain();
    this.valueDomain = this.getValueDomain();

    this.xScale = this.getXScale();
    this.yScale = this.getYScale();

    this.setColors();
    this.legendOptions = this.getLegendOptions();

    if (this.from === 'live-planning') {
      if (window.innerWidth > 1600) {
        this.transform = `translate(${this.dims.xOffset !== undefined ? this.dims.xOffset : 0 + 45} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
      } else {
        this.transform = `translate(${this.dims.xOffset !== undefined ? this.dims.xOffset : 0 + 35} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
      }
    } else {
      this.transform = `translate(${this.dims.xOffset} , ${this.margin[0] + this.dataLabelMaxHeight.negative})`;
    }

    this.textAnchor = TextAnchor.Middle;
  }

  getGroupDomain() {
    const domain: any = [];
    for (const group of this.results) {
      if (!domain.includes(group.label)) {
        domain.push(group.label);
      }
    }
    return domain;
  }

  getInnerDomain() {
    const domain: any = [];
    for (const group of this.results) {
      for (const d of group.series) {
        if (!domain.includes(d.label)) {
          domain.push(d.label);
        }
      }
    }
    return domain;
  }

  getValueDomain() {
    const domain = [];
    let smallest = 0;
    let biggest = 0;
    for (const group of this.results) {
      let smallestSum = 0;
      let biggestSum = 0;
      for (const d of group.series) {
        if (d.value < 0) {
          smallestSum += d.value;
        } else {
          biggestSum += d.value;
        }
        smallest = d.value < smallest ? d.value : smallest;
        biggest = d.value > biggest ? d.value : biggest;
      }
      domain.push(smallestSum);
      domain.push(biggestSum);
    }
    domain.push(smallest);
    domain.push(biggest);

    const min = Math.min(0, ...domain);
    const max = this.yScaleMax ? Math.max(this.yScaleMax, ...domain) : Math.max(...domain);
    return [min, max];
  }

  getXScale(): any {
    const dims: any = this.dims !== undefined ? this.dims : 0
    const spacing = this.groupDomain.length / (dims.width / this.barPadding + 1);
    if (!this.isLivePlanning) {
      return scaleBand().rangeRound([0, dims.width]).paddingInner(spacing).domain(this.groupDomain);
    } else {
      return scaleBand().range([0, dims.width]).paddingInner(spacing).domain(this.groupDomain);
    }
  }

  getYScale(): any {
    const dims: any = this.dims !== undefined ? this.dims : 0
    const scale = scaleLinear().range([dims.height, 0]).domain(this.valueDomain);
    return this.roundDomains ? scale.nice() : scale;
  }

  onDataLabelMaxHeightChanged(event: any, groupIndex: any) {
    if (event.size.negative) {
      this.dataLabelMaxHeight.negative = Math.max(this.dataLabelMaxHeight.negative, event.size.height);
    } else {
      this.dataLabelMaxHeight.positive = Math.max(this.dataLabelMaxHeight.positive, event.size.height);
    }
    if (groupIndex === this.results.length - 1) {
      setTimeout(() => this.update());
    }
  }

  groupTransform(group: any) {
    return `translate(${this.xScale(group.name) || 0}, 0)`;
  }

  onClick(data: any, group?: any) {
    if (group) {
      data.series = group.name;
    }

    this.select.emit(data);
  }

  trackBy(index: any, item: any) {
    return item.name;
  }

  setColors(): void {
    let domain;
    if (this.schemeType === 'ordinal') {
      domain = this.innerDomain;
    } else {
      domain = this.valueDomain;
    }

    this.colors = new ColorHelper(this.scheme, this.schemeType, domain, this.customColors);
  }

  getLegendOptions() {
    const opts: any = {
      scaleType: this.schemeType,
      colors: undefined,
      domain: [],
      title: undefined,
      position: this.legendPosition
    };
    if (opts.scaleType === 'ordinal') {
      opts.domain = this.innerDomain;
      opts.colors = this.colors;
      opts.title = this.legendTitle;
    } else {
      opts.domain = this.valueDomain;
      opts.colors = this.colors.scale;
    }

    return opts;
  }

  updateYAxisWidth({ width }: any) {
    this.yAxisWidth = width;
    this.update();
  }

  updateXAxisHeight({ height }: any) {
    if (height < 30) {
      this.xAxisHeight = 30;
    } else {
      this.xAxisHeight = height;
    }
    this.update();
  }

  onActivate(event: any, group: any, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    const items = this.results
      .map((g: any) => g.series)
      .flat()
      .filter((i: any) => {
        if (fromLegend) {
          return i.label === item.name;
        } else {
          return i.name === item.name && i.series === item.series;
        }
      });

    this.activeEntries = [...items];
    this.activate.emit({ value: item, entries: this.activeEntries });
  }

  onDeactivate(event: any, group: any, fromLegend = false) {
    const item = Object.assign({}, event);
    if (group) {
      item.series = group.name;
    }

    this.activeEntries = this.activeEntries.filter((i: any) => {
      if (fromLegend) {
        return i.label !== item.name;
      } else {
        return !(i.name === item.name && i.series === item.series);
      }
    });

    this.deactivate.emit({ value: item, entries: this.activeEntries });
  }


  getTicks(): any[] {
    let ticks;
    const maxTicks = this.getMaxTicks(20);
    const maxScaleTicks = this.getMaxTicks(100);

    if (this.tickValues) {
      ticks = this.tickValues;
    } else if (this.scale.ticks) {
      ticks = this.scale.ticks.apply(this.scale, [maxScaleTicks]);
    } else {
      ticks = this.scale.domain();
      ticks = reduceTicks(ticks, maxTicks);
    }

    return ticks;
  }

  getMaxTicks(tickWidth: number): number {
    return Math.floor(this.width / tickWidth);
  }

  tickTransform(tick: any): string {
    const xaxis = this.xScale(tick.name) || 0;
    if (window.innerWidth > 1600) {
      return `translate(${xaxis + 20}, 230)`;
    }
    return `translate(${xaxis + 15}, 230)`;
  }

  gridLineTransform(): string {
    return `translate(0,25)`;
  }

  tickTrim(label: string): string {
    return this.trimTicks ? trimLabel(label, this.maxTickLength) : label;
  }

  getXaxisLabel(name: any, key: any) {
    const stringArray = name.split(/(\s+)/);
    if (window.innerWidth > 1600) {
      return stringArray[key];
    } else {
      if (key == 0) {
        return stringArray[key].slice(0, 7) + (stringArray[key].length > 7 ? "..." : "");
      }
      return stringArray[key];
    }

  }

  protected readonly BarChartType = BarChartType;
  protected readonly Orientation = Orientation;
}
