import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"];
import { select, put, call } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../../reducer';
import { LoginFlow, LoginStep } from '../interfaces';
import { shouldShowPromptPasskeys, afterAuthenticationStateUpdate } from '../saga.utils';
import { afterAuthNavigation, afterStepUpAuthNavigation } from './afterAuthNavigation.saga';
import { getFeatureFlags } from '../../../helpers';
import { errorHandler } from '../../../utils';

/**
 * @param tenants
 * @param isAuthenticated
 * @param isStepUp
 * @returns additional update object for the afterAuthenticationStateUpdate saga as part of MFA auth with authenticator app
 */
function* buildPostAuthStateUpdate(tenants, isAuthenticated, isStepUp) {
  const {
    loginState
  } = yield select(state => state.auth);
  let additionalUpdate = {};
  if (!isStepUp) {
    const step = loginState.flow === LoginFlow.Login ? LoginStep.success : loginState.step;
    additionalUpdate = {
      loginState: {
        flow: loginState.flow,
        quickLoginToRegister: loginState.quickLoginToRegister,
        loading: false,
        step,
        error: undefined,
        tenants
      }
    };
  }
  return _extends({}, additionalUpdate, {
    isAuthenticated
  });
}

/**
 * Handle after MFA authentication with authenticator app for login
 */
function* postLoginMfaAuthenticator(isAuthenticated, callback) {
  const {
    loginState
  } = yield select(state => state.auth);
  if (loginState.flow !== LoginFlow.Login) return;
  const [securityCenterLoginFlows] = yield call(getFeatureFlags, ['security-center-show-login-flows']);
  if (securityCenterLoginFlows && loginState.isBreachedPassword && !isAuthenticated) {
    yield put(actions.setLoginState({
      step: LoginStep.breachedPassword,
      loading: false
    }));
  } else {
    const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
    if (shouldShowPrompt) {
      yield put(actions.setLoginState({
        step: LoginStep.promptPasskeys,
        loading: false
      }));
    } else {
      yield afterAuthNavigation();
    }
  }
  callback == null ? void 0 : callback(true);
}

/**
 * Handle after MFA authentication with authenticator app for step up
 */
function* postStepUpMfaAuthenticator(callback) {
  yield afterStepUpAuthNavigation();
  callback == null ? void 0 : callback(true);
}

/**
 * Utility function to handle MFA authentication with authenticator app
 * @param payload MFA with authenticator action payload
 * @param setLoadingAction set loading action
 * @param isStepUp true if this is a step up authentication
 * @returns
 */
export function* mfaWithAuthenticator(_ref, setLoadingAction, isStepUp) {
  let {
      callback
    } = _ref,
    loginWithMfaPayload = _objectWithoutPropertiesLoose(_ref, _excluded);
  yield put(setLoadingAction({
    loading: true,
    error: undefined
  }));
  try {
    const {
      user,
      tenants = [],
      activeTenant
    } = yield call(api.auth.loginWithMfaV2, loginWithMfaPayload);
    const isAuthenticated = !!user.accessToken;
    const additionalUpdate = yield call(buildPostAuthStateUpdate, tenants, isAuthenticated, isStepUp);
    yield call(afterAuthenticationStateUpdate, {
      user,
      tenants,
      activeTenant
    }, additionalUpdate);
    if (user.id) {
      localStorage.setItem('userId', user.id);
    }
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    if (isStepUp) {
      yield call(postStepUpMfaAuthenticator, callback);
      return;
    }
    yield call(postLoginMfaAuthenticator, isAuthenticated, callback);
  } catch (e) {
    yield put(setLoadingAction({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false, e);
  }
}

/**
 * Login with MFA with authenticator app - external saga
 * @param payload.mfaToken
 * @param payload.mfaDevices
 * @param payload.rememberDevice
 * @param payload.invitationToken
 * @param payload.callback - The callback function to be called after the request is done
 */
export function* loginWithMfa({
  payload
}) {
  yield mfaWithAuthenticator(payload, actions.setLoginState, false);
}