<div class="modal-header">
    <h1 class="modal-title pull-left">Migrate to Project</h1>
    <button data-dismiss="modal" aria-label="Close" class="close pull-right" type="button"
        (click)="moveModelRef.hide()">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body">
    <div class="form-group">
        <label>Projects<sup>*</sup></label>
        <select class="form-control select-control" [(ngModel)]="selectedSiteIdForMove">
            <option style="display:none" [ngValue]="null">Select Project</option>
            <option *ngFor="let item of projects" [ngValue]="item?.projectId">{{item?.projectName}}</option>
        </select>

    </div>
    <span>All <strong>{{currentBuilding.managedCount}}</strong> site {{currentBuilding?.managedCount !== 1 ?
        'devices':'device'}}
        in
        <strong>{{currentBuilding.name}}</strong>
        will be migrated to the selected project.
    </span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary " data-dismiss="modal"
        (click)="moveModelRef.hide()">Cancel</button>
    <button type="button" class="btn btn-primary m-0"
        [ngStyle]="{cursor :(!selectedSiteIdForMove) ? 'not-allowed' : 'pointer' }"
        [disabled]="!selectedSiteIdForMove" (click)="migrateProject()">Migrate</button>

</div>