import { CustomerService, MessageService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-monitoring',
  templateUrl: './monitoring.component.html',
  styleUrls: ['./monitoring.component.scss'],
})
export class MonitoringComponent implements OnInit {
  userForm!: FormGroup;
  isFormSubmitted!: boolean;
  showSave!: boolean;
  canSave!: boolean;
  showSpinner!: boolean;
  message!: string;
  customerId: any;
  code!: string;
  isEdit: any;
  userId: any;
  public save: EventEmitter<any> = new EventEmitter();

  constructor(
      private fb: FormBuilder,public bsModalRef: BsModalRef,
      private customerService: CustomerService,
      private messageService: MessageService,
  ) {}
  
  ngOnInit() {
      this.createForm();
      this.onFormChanges();
  }
  closeModal() {
      this.bsModalRef.hide();
  }
  onFormChanges() {
      this.userForm.valueChanges.subscribe(res => {
          if (this.canSave) {
              this.canSave = false;
          }
      })
  }
  createForm() {
      this.userForm = this.fb.group({
          domotzAuthenticationKey: ['', [Validators.required]],
          domotzUserId: ['', [Validators.required]],
      });
      if (this.isEdit) {
          this.userForm.controls['domotzUserId'].setValue(this.userId)
      }
  }
  testDomotz() {
      this.isFormSubmitted = true;
      if (this.userForm.invalid) {
          return;
      }
      this.code = '';
      this.message = '';
      this.showSpinner = true;
      if (this.canSave) {
          const domotzPayload = this.userForm.value
          domotzPayload.customerId = this.customerId;
          this.customerService.domotzSave(domotzPayload).subscribe({
            next: (result: any) => {
              this.messageService.successMessage('Monitoring Integration', result['message'])
              this.save.emit(this.userForm.value);
              this.bsModalRef.hide();
            }  
          })
          return;
      }
      this.customerService.domotzTest(this.userForm.value).subscribe({
        next: (result: any) => {
          const res = result;
          this.showSpinner = false;
          if (res['code'] === 'Success') {
              this.canSave = true;
              this.code = 'Success';
              this.message = 'Test was successful'
          } else if (res['code'] === 'Fail') {
              this.canSave = false;
              this.code = 'Fail';
              this.message = `${res['message']}`
          }
        }  
      });
  }
}

