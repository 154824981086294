<div [id]="dialogId" [ngClass]="dialogClass" [ngStyle]="dialogStyle" (click)="$event.stopPropagation();">
    <div class="modal-header">
        <h1 class="modal-title pull-left">{{header}} {{subHeaderContent!==''? subHeaderContent : ''}}</h1>
        <button type="button" class="close pull-right" aria-label="Close" (click)="_actionCancel($event);bsModalRef.hide();">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body" [innerHTML]="content" [ngClass]="bodyClass" [ngStyle]="bodyStyle">

    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="_actionCancel($event)" [ngClass]="btnCancelClass"
        [ngStyle]="btnCancelStyle">{{btnCancelText}}</button>
        <button type="button" class="btn me-0" [ngClass]="btnSuccessClass" [ngStyle]="btnSuccessStyle"
            (click)="_action($event)">{{btnSuccessText}}</button>
    </div>
</div>