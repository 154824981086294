<div class="creat-new-modal">
    <div class="modal-header">

        <h1 class="modal-title pull-left">Rename
            {{typeOfDetail === 'site'? 'Site' : typeOfDetail === 'building' ? 'Building' : 'Plan'}}</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <em class="fas fa-close"></em>
        </button>


    </div>
    <div class="modal-body ">
        <p>Please enter a new name for the
            {{typeOfDetail === 'site'? 'site' : typeOfDetail === 'building' ? 'building' : 'plan'}}
            <strong>{{formValue?.name}}</strong>.
        </p>

        <form [formGroup]="detailForm" class="edit-new-modal" novalidate>
            <div class="col-sm-12 p-0">
                <div class="form-group">
                    <label>{{label}}
                        <sup>*</sup>
                    </label>
                    <input maxlength="80" removeWhiteSpace type="text" class="form-control" formControlName="name"
                        placeholder="{{label}}">
                    <so-control-messages [name]="label" [control]="$any(detailForm)?.controls['name']">
                    </so-control-messages>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <span>
            <button type="button" (click)="closeModal()" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="submit" (click)="renameDetail()" class="btn btn-primary m-0">{{titleBaseOnType}}</button>

        </span>
    </div>
</div>