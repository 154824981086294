<div class="modal-header">
    <h1 class="modal-title pull-left">Replace Plan</h1>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body">
    <p> This Plan has existing Devices. If the new Plan has different dimensions or scale, these Devices may appear
        in incorrect locations and will need to be repositioned.</p>
    <div class="form-group">
        <p class="mb-0">The Plan <strong>{{floorName}}</strong> currently has:</p>
        <table class="table replace-plan-table">
            <thead>
                <tr>
                    <th style='width:150px'></th>
                    <th style='width:90px'>Active</th>
                    <th style='width:90px'>Archived</th>
                </tr>

            </thead>
            <tbody>
                <tr>
                    <td>Project Devices</td>
                    <td><strong>{{floorWiseDetails.projectActiveCnt}}</strong></td>
                    <td><strong>{{floorWiseDetails.projectArchivedCnt}}</strong></td>
                </tr>
                <tr>
                    <td>Live Site Devices</td>
                    <td><strong>{{floorWiseDetails.siteActiveCnt}}</strong></td>
                    <td><strong>{{floorWiseDetails.siteArchivedCnt}}</strong></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>


<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="_actionCancel($event)">Cancel</button>
    <button type="button" class="btn me-0 btn-primary" (click)="_action($event)">Continue</button>
</div>