import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'so-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {

  @Input() selectionRecord!:any ;
  @Input() isShowFilterBtn = false;

  filterForm!: FormGroup;
  @Output() filterAction = new EventEmitter();

  constructor(private fb: FormBuilder) { }
  
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.filterForm = this.fb.group({})
    this.selectionRecord.forEach((val: any) => {
      if (val.formName)
      this.filterForm.addControl(val.formName, new FormControl(val.defaultValue))
    })
  }

  _filterBtn(name?: any) {
    if (name === 'Reset') {
      this._resetFilterBtn();
    }
    this.filterAction.emit(this.filterForm.value);
  }

  _resetFilterBtn() {
    this.selectionRecord.forEach((val: any) => {
      if (val.formName)
      this.filterForm.get(val.formName)?.setValue(val.defaultValue);
    })
  }

  _dpFilterChange() {
    if (this.isShowFilterBtn) {
      return;
    }
    this.filterAction.emit(this.filterForm.value);
  }


}
