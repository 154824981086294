import _extends from "@babel/runtime/helpers/esm/extends";
export const typeReducerForKey = key => ({
  prepare: payload => ({
    payload
  }),
  reducer: (state, {
    payload
  }) => {
    return _extends({}, state, {
      [key]: _extends({}, state[key], payload)
    });
  }
});