<form [formGroup]="userForm" novalidate>
    <div class="modal-header">
        <h1 class="modal-title pull-left">
            {{isEditMode ? (isEditProject?'Edit Project Role':'Edit Live Site Role') : providerUser.length === 0 ?
            'Invite User ' : 'Add User'}}
        </h1>
        <button (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body">
        <p *ngIf="isEditMode">
            Select what {{!isEditProject? 'Live Site': 'project'}} role
            <strong>{{userDetail?.name || userDetail?.email || '(empty)'}}</strong> should have for
            <strong>{{!customerId ? userData?.customerName :customerDetail?.name}}</strong>.
        </p>

        <div class="form-group add-resource-block" *ngIf="usersForList.length > 0">
            <label>Your Team</label>
            <div class="resource-listing">
                <div *ngFor="let level of usersForList;let levelIndex = index;">
                    <p *ngIf="level.value.length > 0">{{level.key}}</p>
                    <ul>
                        <li *ngFor="let user of level.value;let userIndex = index;"
                            (click)="addUserToEmails(levelIndex, userIndex,user);"
                            [ngClass]="{'addAnimation': addAnimationIndex.li === levelIndex && addAnimationIndex.ui === userIndex}">
                            {{user.name === '' || user.name === null ? '': user.name}} ({{user.email}})
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="!isEditMode">
            <label>{{users && users.length > 0 ? 'Add Users':'Email Address'}}
                <sup>*</sup>
            </label>
            <tag-input name="emails" [animationDuration]="chipsOption.animationDuration" formControlName="emails"
                [placeholder]="'+Add Email'" [secondaryPlaceholder]="'Email Address'"
                [addOnPaste]="chipsOption.addOnPaste" [addOnBlur]="chipsOption.addOnBlur"
                (onAdd)="onInputBlurred($event)" (onPaste)="onInputBlurred($event)"
                (onRemove)="onInputBlurred($event,'remove')" [modelAsStrings]="chipsOption.modelAsStrings"
                [theme]="chipsOption.theme" [editable]="chipsOption.editable"
                [separatorKeyCodes]="chipsOption.separatorKeyCodes" [separatorKeys]="chipsOption.separatorKeys"
                [pasteSplitPattern]="chipsOption.pasteSplitPattern" [inputText]="setData" #input>
                <ng-template let-item="item" let-index="index">
                    <span title=" {{item.user.name}} ({{item.user.email}})"
                        [ngClass]="{'invalid': toAddBorderOnInvaidSpanTag(item.user.email)}"
                        *ngIf="item.user;else emailNotAvilable" (click)="editItem(item.user.email)">
                        {{item.user.name}} ({{item.user.email}})
                    </span>
                    <ng-template #emailNotAvilable>
                        <span [ngClass]="{'invalid': toAddBorderOnInvaidSpanTag(item)}" (click)="editItem(item)">
                            {{item}}</span>
                    </ng-template>
                    <em class="fa fa-close" (click)="removeTags(input, item, index);"></em>
                </ng-template>
                <tag-input-dropdown [appendToBody]="true" [autocompleteItems]="usersForAutoComplete">
                    <ng-template let-item="item" let-index="index">

                        <div class="row">
                            <div class="col-sm-12">
                                <div class="user-profile-block clearfix">
                                    <div class="user-profile-img">
                                        <img [src]="item.value.user.thumbnailAwsImageURL || (cdnUrl + 'assets/images/user-image.png')"
                                            class="img-responsive" alt="User Profile">
                                    </div>
                                    <div class="user-desc">
                                        <div class="user-name">
                                            {{item.value.user.name || "(" + item?.value?.user?.email +")"}}</div>
                                        <a href="javascript:void(0);">{{item.value.user.email}}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </tag-input-dropdown>
            </tag-input>

            <div class="help-block text-danger"
                *ngIf="(isFormSubmitted && $any(userForm).controls.emails.value.length < 1)">Email address
                is required
            </div>
            <div class="text-danger invalid-block"
                *ngIf="$any(userForm).controls.emails.value.length > 0 && isInvalidEmail">
                Invalid Email Address</div>

        </div>

        <div class="form-group" *ngIf="!isEditMode">
            <div class="mt-2 ">
                <label for="isAdmin" class="role-card align-items-center d-flex"
                    [ngClass]="{'active': userDetail.isAdmin}">
                    <div class="role-card-radio checkbox-label action-col pointer-events-none">
                        <so-checkbox [isChecked]="userDetail.isAdmin" (onChange)="isAdminChanged($event)"
                            [label]="adminRole.name" [id]="'isAdmin'" [ngClass]="'lbl-check'">
                        </so-checkbox>
                    </div>
                    <div class="role-card-description">
                        <ul class="role-card-list">
                            <li *ngFor="let roleDesc of adminRole?.newDescription">
                                {{roleDesc}}
                            </li>
                        </ul>

                    </div>
                    <div class="role-typical-user">
                        <span class="user-title"> Typical User</span>
                        <span class="user">{{adminRole?.typicalUser}}</span>
                    </div>
                </label>

            </div>
        </div>
        <div class="form-group" *ngIf="isEditProject || !isEditMode">
            <label>Project Role
                <sup>*</sup>
            </label>
            <div class="mt-2">
                <label for="project_{{item.name}}" class="role-card align-items-center"
                    *ngFor="let item of roles?.projectRole; let i = index;"
                    [hidden]=" (i < providerEmployer?.projectId && currentUser?.role !== 'Administrator') || (userDetail.isAdmin && item?.roleKey !== 'project_Manager')"
                    [ngClass]="{'active': (item.roleKey === newProjectRoleSelection || (userDetail.isAdmin && item?.roleKey === 'project_Manager')), 'd-flex': !((i < providerEmployer?.projectId && currentUser?.role !== 'Administrator') || (userDetail.isAdmin && item?.roleKey !== 'project_Manager'))}">

                    <div class=" role-card-radio action-col">
                        <so-radio *ngIf="!userDetail.isAdmin" [className]="'lbl-check'" [id]="'project_' + item.name"
                            [name]="'project_role'" (change)="roleChanged(item,'projectRole',$event)"
                            [label]="item.name === 'View All' ? 'View Only' : item.name"
                            [isChecked]="(item.roleKey === projectRoleSelection) || ( !isEditMode && i === providerEmployer?.projectId)">
                        </so-radio>
                        <label *ngIf="userDetail.isAdmin">{{item.name}}</label>
                    </div>
                    <div class="role-card-description">
                        <ul class="role-card-list">
                            <li *ngFor="let roleDescProject of item?.newDescription">
                                {{roleDescProject}}
                            </li>
                        </ul>
                    </div>
                    <div class="role-typical-user">
                        <span class="user-title"> Typical User</span>
                        <span class="user"> {{item.typicalUser}}</span>
                    </div>
                </label>
            </div>

        </div>
        <div class="form-group" *ngIf="!isEditProject && isLiveSystemAccessForCustomer">
            <label>Live Site Role
                <sup>*</sup>
            </label>
            <div class="mt-2 ">
                <label for="amt_{{item.name}}" class="role-card align-items-center"
                    *ngFor="let item of roles?.siteRole; let i = index;"
                    [hidden]="(i < providerEmployer?.amtId && currentUser?.role !== 'Administrator') || (userDetail.isAdmin && item?.roleKey !== 'amt_Manager')"
                    [ngClass]="{'active': (item.roleKey === newRoleSelection || (userDetail.isAdmin && item?.roleKey === 'amt_Manager')), 'd-flex': !((i < providerEmployer?.amtId && currentUser?.role !== 'Administrator') || (userDetail.isAdmin && item?.roleKey !== 'amt_Manager'))}">
                    <div class="role-card-radio action-col">
                        <so-radio *ngIf="!userDetail.isAdmin" [id]="'amt_' + item.name" [name]="'role'"
                            (change)="roleChanged(item,'siteRole',$event)"
                            [label]="item.name === 'View All' ? 'View Only' : item.name"
                            [isChecked]="(item.roleKey === amtRoleSelection) || ( !isEditMode && i === providerEmployer?.amtId)">
                        </so-radio>
                        <label *ngIf="userDetail.isAdmin">{{item.name}}</label>
                    </div>
                    <div class="role-card-description">
                        <ul class="role-card-list">
                            <li *ngFor="let roleDesc of item?.newDescription">
                                {{roleDesc}}
                            </li>
                        </ul>
                    </div>
                    <div class="role-typical-user">
                        <span class="user-title"> Typical User</span>
                        <span class="user">{{item.typicalUser}}</span>
                    </div>
            </label>
        </div>
    </div>
    </div>
    <div class="modal-footer">
        <so-button (onClick)="closeModal()" [className]="'btn btn-secondary'" [text]="'Cancel'"></so-button>
        <so-button (onClick)="saveUser()" [disabled]="btnService.getStatus()"
            [style]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}" [className]="'btn btn-primary m-0'"
            [text]="isEditMode ? 'Update Role' : providerUser.length !== 0 ? 'Add' : 'Invite'">
        </so-button>
    </div>

</form>