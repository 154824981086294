import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { SignUpStage } from './interfaces';
import { authStoreName } from '../../constants';
const signUpState = {
  loading: false,
  allowSignUps: false,
  allowNotVerifiedUsersLogin: false,
  firstLoad: true,
  stage: SignUpStage.SignUp
};
const reducers = {
  setSignUpState: typeReducerForKey('signUpState'),
  resetSignUpState: resetStateByKey('signUpState', {
    signUpState
  })
};
const actions = {
  signUpUser: createAction(`${authStoreName}/signUpUser`, payload => ({
    payload
  })),
  resetSignUpStateSoft: createAction(`${authStoreName}/resetSignUpStateSoft`)
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { signUpState, reducers as signUpReducers, actions as signUpActions };