import { createAction } from '@reduxjs/toolkit';
import { authStoreName } from '../../constants';
import { resetStateByKey, typeReducerForKey } from '../utils';
const smsState = {
  loading: false,
  error: null,
  phoneNumbers: undefined
};
const reducers = {
  setSmsState: typeReducerForKey('smsState'),
  resetSmsState: resetStateByKey('smsState', {
    smsState
  })
};
const actions = {
  loadPhoneNumbers: createAction(`${authStoreName}/loadPhoneNumbers`),
  setUpPhoneNumber: createAction(`${authStoreName}/setUpPhoneNumber`, payload => ({
    payload
  })),
  verifyPhoneNumber: createAction(`${authStoreName}/verifyPhoneNumber`, payload => ({
    payload
  })),
  deletePhoneNumber: createAction(`${authStoreName}/deletePhoneNumber`, payload => ({
    payload
  })),
  verifyDeletePhoneNumber: createAction(`${authStoreName}/verifyDeletePhoneNumber`, payload => ({
    payload
  })),
  preVerifyPhoneNumber: createAction(`${authStoreName}/preVerifyPhoneNumber`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { smsState, reducers as smsReducers, actions as smsActions };