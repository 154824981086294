import { FRONTEGG_AFTER_AUTH_REDIRECT_URL } from '../../constants';
import { ACR_VALUE, AMR_MFA_VALUE, AMR_ADDITIONAL_VALUE, STEP_UP_MAX_AGE_PARAM_NAME } from './consts';
/**
 * @param options.amr
 * @param options.acr
 * @param options.auth_time - auth time of the user (last login time)
 * @param options.maxAge - max age of step up
 * @returns true when the user is stepped up, false otherwise
 */
export const isSteppedUp = (user, {
  maxAge
} = {}) => {
  if (!user) return false;
  const {
    amr = [],
    acr = '',
    auth_time
  } = user;
  if (maxAge && auth_time) {
    // when user is logged in for a long time (more than maxAge, but jwt is still valid because it's not refreshed yet)
    const isMaxAgeValid = Date.now() / 1000 - auth_time <= maxAge;
    if (!isMaxAgeValid) return false;
  }
  const isACRValid = acr === ACR_VALUE;
  const isAMRIncludesMFA = amr.indexOf(AMR_MFA_VALUE) !== -1;
  const isAMRIncludesMethod = AMR_ADDITIONAL_VALUE.find(method => amr.indexOf(method)) !== undefined;
  return isACRValid && isAMRIncludesMFA && isAMRIncludesMethod;
};

/**
 * Set the url and query params in the local storage FRONTEGG_AFTER_AUTH_REDIRECT_URL value
 */
export function setAfterAuthRedirectUrlForStepUp() {
  const encodedRedirectUrl = window.location.pathname + window.location.search;
  window.localStorage.setItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL, encodedRedirectUrl);
}

/**
 * Redirects to the step up url with the max age param and set the redirect url in the local storage
 * The redirect url will be used after the step up flow is done
 * @param stepUpUrl - step up url to redirect to
 * @param onRedirectTo - redirect to function
 * @param maxAge - max age of step up
 */
export const redirectByStepUpUrl = (stepUpUrl, onRedirectTo, maxAge) => {
  setAfterAuthRedirectUrlForStepUp();
  const searchParams = new URLSearchParams(window.location.search);
  if (maxAge) {
    searchParams.set(STEP_UP_MAX_AGE_PARAM_NAME, maxAge.toString());
  }
  onRedirectTo(`${stepUpUrl}${searchParams ? `?${searchParams}` : ''}`, {
    refresh: false
  });
};