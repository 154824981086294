import { all, call } from 'redux-saga/effects';
import { billingSagas, billingSagasMock } from './Billing/saga';
import { checkoutSagas, checkoutSagasMock } from './Checkout/saga';
import { plansSagas, plansSagasMock } from './Plans/saga';
import { configSagas, configSagasMock } from './Config/saga';
import { subscriptionStripeSagas } from './Stripe/saga';
import { vendorPublicConfigSagas, vendorPublicConfigSagasMock } from './VendorPublicConfig/saga';
export function* sagas() {
  yield all([call(billingSagas), call(checkoutSagas), call(plansSagas), call(configSagas), call(subscriptionStripeSagas), call(vendorPublicConfigSagas)]);
}
export function* mockSagas() {
  yield all([call(billingSagasMock), call(checkoutSagasMock), call(plansSagasMock), call(configSagasMock), call(vendorPublicConfigSagasMock)]);
}