import { Injectable } from '@angular/core';
import { Toast, ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(private toasterService: ToastrService) {
  }


  successMessage(title: string, message: string) {
      this.toasterService.success( message,title );
  }

  errorMessage(title: string, message: string) {
      this.toasterService.error(message,title);
  }

  infoMessage(title: string, message: string) {
      this.toasterService.info(message,title);
  }
  warningMessage(title: string, message: string) {
      this.toasterService.warning(message,title);
  }
}
