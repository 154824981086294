import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback"],
  _excluded5 = ["callback", "events"],
  _excluded6 = ["callback"],
  _excluded7 = ["callback"],
  _excluded8 = ["callback"],
  _excluded9 = ["callback"],
  _excluded10 = ["user"],
  _excluded11 = ["callback"],
  _excluded12 = ["callback"],
  _excluded13 = ["callback"],
  _excluded14 = ["callback"],
  _excluded15 = ["callback"];
import { all, call, put, select, takeLeading } from 'redux-saga/effects';
import { api, AuthStrategyEnum, ContextHolder, fetch, WebAuthnDeviceType } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { HOSTED_LOGIN_VERIFIER_KEY } from '../../constants';
import { UserVeirifedOriginTypes } from '../interfaces';
import { LoginFlow, LoginStep } from './interfaces';
import { getMfaRequiredState } from './sagas/mfaRequiredState.saga';
import { loadAllowSignUps } from '../SignUp/saga';
import { MFAStep } from '../MfaState/interfaces';
import { dummyIps, userDemo } from '../dummy';
import { SamlVendors } from '../SSOState/interfaces';
import { loadVendorPublicInfo } from '../../vendor/saga';
import { createRandomString, generateCodeChallenge, getFeatureFlags } from '../../helpers';
import { base64urlDecode, publicKeyCredentialToJSON } from '../utils';
import { loadPublicAuthStrategiesPolicy } from '../Security/SecurityPolicyState/saga';
import { getSearchParam, TENANT_ID_PARAM_KEY, isMfaRequired, isOauthCallbackRoute, isEmailPayload } from './utils';
import { errorHandler, GTMEventAction, reportGTMEvent } from '../../utils';
import { authStrategyLoginStepMap } from './consts';
import { isEntitlementsDeeplyEqual } from '../Entitlements';
import { getCustomLoginAlias, loadCustomLoginRoutes } from '../CustomLoginState/saga';
import { FronteggNativeModule } from '../../toolkit';
import { afterAuthenticationStateUpdate, shouldShowPromptPasskeys } from './saga.utils';
import { isSteppedUp } from '../StepUpState/utils';
import { afterAuthNavigation, loginWithMfa, preVerifyMFASMSForLogin, verifyMFASMSForLogin, preVerifyMFAWebAuthnForLogin, verifyMFAWebAuthnForLogin, preVerifyMFAEmailCodeForLogin, verifyMFAEmailCodeForLogin, afterStepUpAuthNavigation } from './sagas';
import { SHOULD_STEP_UP_KEY } from '../StepUpState/consts';

/******************************************************************
 ***                                                             ****
 *** PLEASE DO NOT ADD CODE TO THIS FILE                         ****
 *** NEW SAGAS - ADD AS A NEW FILE IN THE SAGAS FOLDER           ****
 *** EXISTING SAGAS - MOVE TO THE SAGAS FOLDER WHEN TOUCHING IT  ****
 ***                                                             ****
 *******************************************************************/

/**
 * Prevent unnecessary entitlements reference update inside the user object by
 * deep equality with the stored user.entitlements
 * This function should not be used for first login because no stored entitlements.
 * @param updatedUser new user response from the BE
 * @returns final user object with the correct entitlements reference
 */
function* handleUnnecessaryEntitlementsUpdate(updatedUser) {
  const oldEntitlements = yield select(state => {
    var _state$auth$user;
    return (_state$auth$user = state.auth.user) == null ? void 0 : _state$auth$user.entitlements;
  });
  if (isEntitlementsDeeplyEqual(oldEntitlements, updatedUser == null ? void 0 : updatedUser.entitlements)) {
    // set the previous entitlemenets object
    return _extends({}, updatedUser, {
      entitlements: oldEntitlements
    });
  }
  return updatedUser;
}
export function* refreshMetadata() {
  let ssoACS;
  try {
    var _metadata$configurati;
    const metadata = yield call(api.metadata.getSamlMetadata);
    ssoACS = metadata == null ? void 0 : (_metadata$configurati = metadata.configuration) == null ? void 0 : _metadata$configurati.acsUrl;
  } catch (e) {
    console.error(e);
  }
  yield put(actions.setState({
    ssoACS
  }));
}
export function* loadSSOPublicConfigurationFunction() {
  try {
    const {
      isActive
    } = yield call(api.auth.getSSOPublicConfiguration);
    yield put(actions.setState({
      isSSOAuth: isActive
    }));
  } catch (e) {
    console.error(e);
  }
}
function* shouldNevigateToRegisterQuickLogin(user) {
  var _ref;
  const {
    routes,
    loginState
  } = yield select(state => state.auth);
  const quickLoginToRegister = (_ref = localStorage.getItem('register-quick-login')) != null ? _ref : loginState.quickLoginToRegister;
  return quickLoginToRegister && localStorage.getItem(`${user.id}-${quickLoginToRegister}`) !== 'true' && !window.location.pathname.endsWith(routes.logoutUrl);
}
export function* refreshToken() {
  const {
    hostedLoginBox
  } = yield select(state => state.auth);
  if (hostedLoginBox) {
    yield call(refreshTokenHosted);
  } else {
    yield call(refreshTokenEmbedded);
  }
}
export function* refreshTokenForSocialLogins() {
  const {
    hostedLoginBox
  } = yield select(state => state.auth);
  if (hostedLoginBox) {
    yield call(refreshTokenHostedSocialLogins);
  } else {
    yield call(refreshTokenEmbeddedSocialLogins);
  }
}
function* refreshTokenHosted() {
  const {
    user
  } = yield select(state => state.auth);
  if (!(user != null && user.refreshToken)) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
    return;
  }
  try {
    const body = {
      grant_type: 'refresh_token',
      refresh_token: user == null ? void 0 : user.refreshToken
    };
    const response = yield call(api.auth.exchangeOAuthTokensV2, body);
    const updatedUser = yield call(handleUnnecessaryEntitlementsUpdate, response.user);
    yield call(afterAuthenticationStateUpdate, _extends({}, response, {
      user: updatedUser
    }), {
      isAuthenticated: true
    });
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
  }
}
function* refreshTokenHostedSocialLogins() {
  const {
    user: currentUser
  } = yield select(state => state.auth);
  if (!(currentUser != null && currentUser.refreshToken)) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
    return;
  }
  try {
    const body = {
      grant_type: 'refresh_token',
      refresh_token: currentUser == null ? void 0 : currentUser.refreshToken
    };
    const response = yield call(api.auth.exchangeOAuthTokensV2, body);
    const updatedUser = yield call(handleUnnecessaryEntitlementsUpdate, response.user);
    yield call(afterAuthenticationStateUpdate, _extends({}, response, {
      user: updatedUser
    }), {
      isAuthenticated: true
    });
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: null,
      isAuthenticated: false
    }));
  }
}
function* refreshTokenEmbedded() {
  try {
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes,
      loginState
    } = yield select(state => state.auth);
    const {
      user,
      tenants = [],
      activeTenant
    } = yield call(api.auth.refreshTokenV3);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      var _ref2;
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      const quickLoginToRegister = (_ref2 = localStorage.getItem('register-quick-login')) != null ? _ref2 : loginState.quickLoginToRegister;
      const shouldNavigateToRegisterQuickLogin = yield shouldNevigateToRegisterQuickLogin(user);
      const updatedUser = yield call(handleUnnecessaryEntitlementsUpdate, user);
      yield call(afterAuthenticationStateUpdate, {
        user: updatedUser,
        tenants,
        activeTenant
      }, {
        loginState: _extends({}, loginState, {
          quickLoginToRegister,
          flow: shouldNavigateToRegisterQuickLogin ? LoginFlow.RegisterQuickLogin : LoginFlow.Login
        }),
        isAuthenticated: true
      });
      yield handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin);
    }
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
  }
}
function* refreshTokenEmbeddedSocialLogins() {
  try {
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes,
      loginState
    } = yield select(state => state.auth);
    const {
      user,
      tenants = [],
      activeTenant
    } = yield call(api.auth.refreshTokenV3);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const updatedUser = yield call(handleUnnecessaryEntitlementsUpdate, user);
      const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
      if (shouldShowPrompt) {
        yield put(actions.setLoginState({
          step: LoginStep.promptPasskeys,
          loading: false
        }));
        yield call(afterAuthenticationStateUpdate, {
          user: updatedUser,
          tenants,
          activeTenant
        }, {
          isLoading: false
        });
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      } else {
        var _ref3;
        if (user.id) {
          localStorage.setItem('userId', user.id);
        }
        const quickLoginToRegister = (_ref3 = localStorage.getItem('register-quick-login')) != null ? _ref3 : loginState.quickLoginToRegister;
        const shouldNavigateToRegisterQuickLogin = yield shouldNevigateToRegisterQuickLogin(user);
        yield call(afterAuthenticationStateUpdate, {
          user: updatedUser,
          tenants,
          activeTenant
        }, {
          loginState: _extends({}, loginState, {
            quickLoginToRegister,
            flow: shouldNavigateToRegisterQuickLogin ? LoginFlow.RegisterQuickLogin : LoginFlow.Login
          }),
          isAuthenticated: true
        });
        yield handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin);
      }
    }
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
  }
}
function* handleRedirectRefreshToken(shouldNavigateToRegisterQuickLogin) {
  var _window;
  const url = new URL((_window = window) == null ? void 0 : _window.location.href);
  const onRedirectTo = ContextHolder.onRedirectTo;
  const {
    routes,
    loginState
  } = yield select(state => state.auth);
  const invitationToken = url.searchParams.get('invitationToken');
  const redirectRoutes = [routes.socialLoginCallbackUrl, routes.oidcRedirectUrl, routes.samlCallbackUrl];
  if (!invitationToken) {
    redirectRoutes.push(routes.loginUrl, routes.signUpUrl);
  }
  if (shouldNavigateToRegisterQuickLogin) {
    onRedirectTo(routes.loginUrl);
  } else if (redirectRoutes.some(url => url && window.location.pathname.endsWith(url))) {
    if (loginState.isNewUser && routes.signUpSuccessUrl && routes.socialLoginCallbackUrl === window.location.pathname) {
      onRedirectTo(routes.signUpSuccessUrl, {
        refresh: routes.signUpSuccessUrl.startsWith('http')
      });
    } else {
      yield afterAuthNavigation();
    }
  }
}
function* getUserIP({
  payload: {
    callback
  }
}) {
  try {
    const {
      ip
    } = yield call(api.metadata.getCurrentUserIpMetadata);
    yield put(actions.setState({
      userIp: ip
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    callback == null ? void 0 : callback(false);
  }
}
function* requestAuthorize({
  payload: firstTime
}) {
  const calls = [];
  const callsAfterRefresh = [];
  calls.push(call(refreshToken));
  if (firstTime) {
    yield put(actions.setState({
      isLoading: true
    }));
    yield put(actions.loadSocialLoginsConfigurationV2());
    calls.push(call(loadAllowSignUps));
    calls.push(call(loadPublicAuthStrategiesPolicy));
    calls.push(call(loadSSOPublicConfigurationFunction));
    calls.push(call(loadVendorPublicInfo));
    calls.push(call(refreshMetadata));
    /*
      We will load custom login routes only if custom login is enabled
      In order to check if custom login is enabled without the tenant alias (search-param/sub-domain)
      we have to wait for the user state (refreshToken request)
    */
    callsAfterRefresh.push(call(loadCustomLoginRoutes));
  }
  yield all(calls);
  if (callsAfterRefresh.length > 0) {
    yield all(callsAfterRefresh);
  }
  yield put(actions.setState({
    isLoading: false
  }));
}
function* isMFARequiredSSR({
  accessToken,
  user
}) {
  if (!accessToken) {
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
    return;
  }
  const onRedirectTo = ContextHolder.onRedirectTo;
  const {
    routes
  } = yield select(state => state.auth);
  if (isMfaRequired(user)) {
    const mfaRequiredState = yield getMfaRequiredState(user);
    yield put(actions.setState(mfaRequiredState));
    onRedirectTo(routes.loginUrl, {
      preserveQueryParams: true
    });
  }
}
function* requestAuthorizeSSR({
  payload
}) {
  const calls = [];
  yield put(actions.loadSocialLoginsConfigurationV2());
  calls.push(call(loadAllowSignUps));
  calls.push(call(loadSSOPublicConfigurationFunction));
  calls.push(call(loadVendorPublicInfo));
  calls.push(call(refreshMetadata));
  calls.push(call(isMFARequiredSSR, payload));
  calls.push(call(loadCustomLoginRoutes));
  yield all(calls);
}
const getUri = urlStrategy => {
  if (urlStrategy === 'path') {
    var _window2;
    return ((_window2 = window) != null ? _window2 : document).location.pathname;
  } else {
    var _window3;
    const uri = (((_window3 = window) != null ? _window3 : document).location.hash || '#').substring(1);
    if (uri.indexOf('?') !== -1) {
      return uri.substring(0, uri.indexOf('?'));
    } else {
      return uri;
    }
  }
};
function* requestHostedLoginSilentAuthorize() {
  const response = yield call(api.auth.silentOAuthRefreshTokenV2);
  if (response != null && response.user) {
    yield call(afterAuthenticationStateUpdate, response, {
      isAuthenticated: true,
      isLoading: false
    });
  } else {
    throw new Error(`couldn't refresh user token with oauth service`);
  }
}
function* refreshOrRequestHostedLoginAuthorize({
  payload: additionalParams
}) {
  var _localStorage;
  // when the user pause the step up flow we may still have the key in the local storage in hosted - Remove it
  (_localStorage = localStorage) == null ? void 0 : _localStorage.removeItem(SHOULD_STEP_UP_KEY);
  const {
    disableSilentRefresh
  } = yield select(state => ({
    disableSilentRefresh: state.auth.disableSilentRefresh
  }));
  if ((additionalParams == null ? void 0 : additionalParams.prompt) === 'login') {
    yield requestHostedLoginAuthorize(additionalParams);
    return;
  }
  try {
    if (disableSilentRefresh) {
      throw new Error('silent refresh is disabled');
    }
    yield requestHostedLoginSilentAuthorize();
  } catch (e) {
    yield requestHostedLoginAuthorize(additionalParams);
  }
}
function* refreshOrRequestHostedLoginAuthorizeV2({
  payload: {
    additionalParams,
    shouldRedirectToLogin,
    firstTime,
    loginDirectAction
  }
}) {
  var _localStorage2, _additionalParams;
  // when the user pause the step up flow we may still have the key in the local storage in hosted - Remove it
  (_localStorage2 = localStorage) == null ? void 0 : _localStorage2.removeItem(SHOULD_STEP_UP_KEY);
  if (firstTime) {
    const {
      urlStrategy
    } = yield select(state => ({
      urlStrategy: state.root.urlStrategy
    }));
    const activeUri = getUri(urlStrategy);
    yield put(actions.setState({
      isLoading: true
    }));
    if (isOauthCallbackRoute(activeUri)) {
      return;
    }
  }
  const {
    disableSilentRefresh
  } = yield select(state => ({
    disableSilentRefresh: state.auth.disableSilentRefresh
  }));
  if (loginDirectAction) {
    additionalParams = additionalParams || {};
    additionalParams['login_direct_action'] = btoa(JSON.stringify(loginDirectAction));
  }
  if (((_additionalParams = additionalParams) == null ? void 0 : _additionalParams.prompt) === 'login') {
    yield requestHostedLoginAuthorize(additionalParams);
    return;
  }
  try {
    if (disableSilentRefresh) {
      throw new Error('silent refresh is disabled');
    }
    yield requestHostedLoginSilentAuthorize();
  } catch (e) {
    if (!shouldRedirectToLogin) {
      yield put(actions.setState({
        isLoading: false
      }));
      return;
    }
    yield requestHostedLoginAuthorize(additionalParams);
  }
}
export function* requestHostedLoginAuthorize(additionalParams) {
  const {
    routes,
    context,
    onRedirectTo,
    urlStrategy
  } = yield select(state => ({
    routes: state.auth.routes,
    onRedirectTo: state.auth.onRedirectTo,
    context: state.root.context,
    urlStrategy: state.root.urlStrategy
  }));
  const activeUri = getUri(urlStrategy);
  if (activeUri === routes.hostedLoginRedirectUrl) {
    console.debug('Calling loginWithRedirect while in hostedLoginCallback route');
    return;
  }
  yield put(actions.setState({
    isLoading: true
  }));
  // Generate the relevant params for the redirect
  const nonce = createRandomString();
  const code_verifier = createRandomString();
  const code_challenge = yield call(generateCodeChallenge, code_verifier);

  // We are saving the verifier in session storage to be able to validate the response
  localStorage.setItem(HOSTED_LOGIN_VERIFIER_KEY, code_verifier);
  let redirectUrl = `${window.location.origin}${urlStrategy === 'path' ? '' : '#'}${routes.hostedLoginRedirectUrl}`;
  yield call(loadCustomLoginRoutes);
  const customLoginSearchParam = yield select(state => {
    var _state$auth$customLog;
    return (_state$auth$customLog = state.auth.customLoginState) == null ? void 0 : _state$auth$customLog.customLoginSearchParams;
  });
  if (customLoginSearchParam) {
    if (redirectUrl.endsWith('/')) {
      redirectUrl = redirectUrl.slice(0, -1);
    }
    redirectUrl += customLoginSearchParam;
  }
  const baseUrl = fetch.getBaseUrl(context, '/oauth/authorize');
  // Hard coded for now
  const oauthUrl = `${baseUrl}/oauth/authorize`;
  const params = _extends({
    response_type: 'code',
    client_id: context.clientId || 'INVALID-CLIENT-ID',
    scope: 'openid email profile',
    redirect_uri: redirectUrl,
    code_challenge: code_challenge,
    code_challenge_method: 'S256',
    nonce
  }, additionalParams);
  if (!(additionalParams != null && additionalParams.organization)) {
    const customLoginAlias = yield call(getCustomLoginAlias);
    if (customLoginAlias) {
      params['organization'] = customLoginAlias;
    }
  }
  const tenantId = getSearchParam(TENANT_ID_PARAM_KEY);
  if (tenantId) {
    params['tenantId'] = tenantId;
  }
  const searchParams = new URLSearchParams(params);
  const url = `${oauthUrl}?${searchParams.toString()}`;
  onRedirectTo(url, {
    refresh: true
  });
}
function* handleHostedLoginCallback({
  payload
}) {
  // Hard coded for now
  const code_verifier = localStorage.getItem(HOSTED_LOGIN_VERIFIER_KEY) || 'INVALID-CODE-VERIFIER';
  const {
    routes,
    urlStrategy
  } = yield select(state => ({
    routes: state.auth.routes,
    urlStrategy: state.root.urlStrategy
  }));
  let redirectUrl = `${window.location.origin}${urlStrategy === 'path' ? '' : '#'}${routes.hostedLoginRedirectUrl}`;
  yield call(loadCustomLoginRoutes);
  const customLoginSearchParam = yield select(state => {
    var _state$auth$customLog2;
    return (_state$auth$customLog2 = state.auth.customLoginState) == null ? void 0 : _state$auth$customLog2.customLoginSearchParams;
  });
  if (customLoginSearchParam) {
    if (redirectUrl.endsWith('/')) {
      redirectUrl = redirectUrl.slice(0, -1);
    }
    redirectUrl += customLoginSearchParam;
  }
  const body = {
    code: payload.code,
    redirect_uri: redirectUrl,
    code_verifier,
    grant_type: 'authorization_code'
  };
  try {
    const response = yield call(api.auth.exchangeOAuthTokensV2, body);
    yield call(afterAuthenticationStateUpdate, response, {
      isAuthenticated: true,
      isLoading: false
    });
  } catch (e) {
    yield put(actions.setState({
      isLoading: false
    }));
    console.error('Failed to exchangeOAuthTokens', e);
  } finally {
    const user = yield select(({
      auth
    }) => auth.user);
    const isStepUpFlow = window.localStorage.getItem(SHOULD_STEP_UP_KEY);
    window.localStorage.removeItem(SHOULD_STEP_UP_KEY);

    // when the user pause the step up flow we may still have the key in the local storage in hosted. Ignore it.
    if (isStepUpFlow && isSteppedUp(user)) {
      yield afterStepUpAuthNavigation();
    } else {
      yield afterAuthNavigation();
    }
  }
}
function* changePhoneNumberWithVerification(_ref4) {
  let {
      payload: {
        callback
      }
    } = _ref4,
    payload = _objectWithoutPropertiesLoose(_ref4.payload, _excluded);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const changePhoneRes = yield call(api.auth.changePhoneNumberWithVerification, payload);
    yield put(actions.setLoginState({
      phoneNumber: payload.phoneNumber,
      loading: false,
      changePhoneId: changePhoneRes.changePhoneId,
      step: LoginStep.loginWithQuickSmsOtc,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* verifyChangePhoneNumber(_ref5) {
  let {
      payload: {
        callback
      }
    } = _ref5,
    payload = _objectWithoutPropertiesLoose(_ref5.payload, _excluded2);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    yield call(api.auth.verifyChangePhoneNumber, payload);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* quickSmsPasswordlessPreLogin(_ref6) {
  let {
      payload: {
        callback
      }
    } = _ref6,
    payload = _objectWithoutPropertiesLoose(_ref6.payload, _excluded3);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));

    // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
    // @ts-ignore
    const preloginRes = yield call(api.auth.passwordlessPreLogin, _extends({}, payload, {
      type: AuthStrategyEnum.SmsCode
    }));
    yield put(actions.setLoginState({
      step: LoginStep.loginWithQuickSmsOtc,
      loading: false,
      phoneNumber: preloginRes == null ? void 0 : preloginRes.phoneNumber,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e),
      loading: false
    }));
    callback == null ? void 0 : callback(e);
  }
}
function* passwordlessPreLogin(_ref7) {
  let {
      payload: {
        callback
      }
    } = _ref7,
    payload = _objectWithoutPropertiesLoose(_ref7.payload, _excluded4);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    let email;
    if (isEmailPayload(payload)) {
      email = payload.email;
    }

    // TODO: [Typescript 4.8] fix @frontegg/rest-api return value
    // @ts-ignore
    const preloginRes = yield call(api.auth.passwordlessPreLogin, payload);
    const step = authStrategyLoginStepMap[payload.type];
    yield put(actions.setLoginState({
      step,
      loading: false,
      phoneNumber: preloginRes == null ? void 0 : preloginRes.phoneNumber,
      email,
      error: undefined
    }));
    callback == null ? void 0 : callback();
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e),
      loading: false
    }));
    callback == null ? void 0 : callback();
  }
}
function* passwordlessPostLogin(_ref8) {
  let {
      payload: {
        callback,
        events
      }
    } = _ref8,
    payload = _objectWithoutPropertiesLoose(_ref8.payload, _excluded5);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      user,
      tenants = [],
      activeTenant
    } = yield call(api.auth.passwordlessPostLoginV2, payload);
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes
    } = yield select(state => state.auth);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const {
        loginState
      } = yield select(state => state.auth);
      if (user.emailVerified) {
        var _events$userVerified;
        const userVerifiedPayload = {
          email: user.email,
          origin: UserVeirifedOriginTypes.PASSWORDLESS,
          id: user.id,
          tenantId: user.tenantId,
          createdAt: new Date(),
          name: user.name
        };
        events == null ? void 0 : (_events$userVerified = events.userVerified) == null ? void 0 : _events$userVerified.call(events, userVerifiedPayload);
        reportGTMEvent(GTMEventAction.USER_VERIFIED, userVerifiedPayload);
      }
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      yield call(afterAuthenticationStateUpdate, {
        user,
        tenants,
        activeTenant
      });
      yield put(actions.setLoginState({
        error: undefined
      }));
      yield put(actions.setState({
        isLoading: false
      }));
      if (loginState.flow === LoginFlow.Login) {
        const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
        if (shouldShowPrompt) {
          yield put(actions.setLoginState({
            step: LoginStep.promptPasskeys,
            loading: false
          }));
          onRedirectTo(routes.loginUrl, {
            preserveQueryParams: true
          });
        } else {
          yield put(actions.setState({
            isAuthenticated: true
          }));
          yield afterAuthNavigation();
        }
      } else {
        onRedirectTo(routes.loginUrl, {
          preserveQueryParams: true
        });
      }
      localStorage.removeItem('register-quick-login');
      callback == null ? void 0 : callback(true);
    }
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e, 'Failed to authenticate')
    }));
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* verifyInviteToken({
  payload
}) {
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      name: inviteTokenTenantName
    } = yield call(api.auth.verifyInviteToken, payload);
    yield put(actions.setLoginState({
      inviteTokenTenantName
    }));
  } catch (e) {
    yield put(actions.setLoginState({
      inviteTokenError: errorHandler(e, `We couldn't verify your invitation`)
    }));
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* preLogin({
  payload: {
    email,
    recaptchaToken,
    invitationToken,
    callback
  }
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const onRedirectTo = yield select(({
      auth: {
        onRedirectTo
      }
    }) => onRedirectTo);
    const tenantId = getSearchParam(TENANT_ID_PARAM_KEY);
    const {
      address,
      idpType
    } = yield call(api.auth.preLoginV2, {
      email,
      tenantId
    });
    if (address) {
      let ssoRedirectUrl = address;
      if (idpType === SamlVendors.Oidc && !ssoRedirectUrl.includes('redirect_uri')) {
        const {
          routes: {
            oidcRedirectUrl
          }
        } = yield select(({
          auth: {
            routes
          }
        }) => ({
          routes
        }));
        ssoRedirectUrl += `&redirect_uri=${window.location.origin}${oidcRedirectUrl}`;
      }
      if (FronteggNativeModule.isAvailable('loginWithSSO')) {
        FronteggNativeModule.loginWithSSO(email);
        yield put(actions.setLoginState({
          loading: false
        }));
        callback == null ? void 0 : callback();
      } else {
        yield put(actions.setLoginState({
          step: LoginStep.redirectToSSO,
          loading: false,
          ssoRedirectUrl
        }));
        setTimeout(() => {
          onRedirectTo(ssoRedirectUrl, {
            refresh: true
          });
        }, 2000);
      }
    } else {
      yield ssoPreloginFailed({
        email,
        recaptchaToken,
        callback,
        invitationToken
      });
    }
  } catch (e) {
    yield ssoPreloginFailed({
      email,
      recaptchaToken,
      callback,
      invitationToken
    });
  }
}
function* ssoPreloginFailed(_ref9) {
  let {
      callback
    } = _ref9,
    body = _objectWithoutPropertiesLoose(_ref9, _excluded6);
  const publicPolicy = yield select(({
    auth: {
      securityPolicyState: {
        publicPolicy: {
          policy: publicPolicy
        }
      }
    }
  }) => publicPolicy);
  if (!(publicPolicy != null && publicPolicy.authStrategy)) {
    yield put(actions.setLoginState({
      step: LoginStep.loginWithPassword,
      loading: false
    }));
    callback == null ? void 0 : callback();
    return;
  }
  if ((publicPolicy == null ? void 0 : publicPolicy.authStrategy) === AuthStrategyEnum.EmailAndPassword) {
    yield put(actions.setLoginState({
      step: LoginStep.loginWithPassword,
      loading: false
    }));
    callback == null ? void 0 : callback();
  } else if ([AuthStrategyEnum.MagicLink, AuthStrategyEnum.Code, AuthStrategyEnum.SmsCode].includes(publicPolicy == null ? void 0 : publicPolicy.authStrategy)) {
    yield put(actions.passwordlessPreLogin(_extends({}, body, {
      type: publicPolicy == null ? void 0 : publicPolicy.authStrategy,
      callback
    })));
  } else {
    yield put(actions.setLoginState({
      step: LoginStep.loginWithPassword,
      loading: false
    }));
    callback == null ? void 0 : callback();
  }
}
function* webAuthnCreateNewDeviceSession({
  payload: {
    callback
  }
}) {
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      options
    } = yield call(api.auth.webAuthnCreateNewDeviceSession);
    options.user.id = base64urlDecode(options.user.id);
    options.challenge = base64urlDecode(options.challenge);
    options.excludeCredentials = [];
    callback == null ? void 0 : callback(options);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* webAuthnVerifyNewDeviceSession(_ref10) {
  let {
      payload: {
        callback
      }
    } = _ref10,
    body = _objectWithoutPropertiesLoose(_ref10.payload, _excluded7);
  try {
    yield put(actions.setLoginState({
      loading: true
    }));
    const publicKey = publicKeyCredentialToJSON(body.publicKey);
    const deviceType = (publicKey == null ? void 0 : publicKey.authenticatorAttachment) === 'platform' ? WebAuthnDeviceType.Platform : WebAuthnDeviceType.CrossPlatform;
    yield call(api.auth.verifyNewDeviceSession, {
      id: publicKey.id,
      response: publicKey.response,
      deviceType: deviceType
    });
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* webAuthnPrelogin(_ref11) {
  let {
      payload: {
        callback
      }
    } = _ref11,
    body = _objectWithoutPropertiesLoose(_ref11.payload, _excluded8);
  try {
    var _options$allowCredent;
    yield put(actions.setPasskeysState({
      loading: true
    }));
    yield put(actions.setLoginState({
      loading: true
    }));
    const {
      options
    } = yield call(api.auth.webAuthnPreLogin, body);
    options.challenge = base64urlDecode(options.challenge);
    options.allowCredentials = (_options$allowCredent = options.allowCredentials) == null ? void 0 : _options$allowCredent.map(credentials => _extends({}, credentials, {
      id: base64urlDecode(credentials.id)
    }));
    yield put(actions.setLoginState({
      error: undefined
    }));
    callback == null ? void 0 : callback(options);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setPasskeysState({
      loading: false
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* webAuthnPostLogin(_ref12) {
  let {
      payload: {
        callback
      }
    } = _ref12,
    body = _objectWithoutPropertiesLoose(_ref12.payload, _excluded9);
  try {
    var _publicKey$response$u;
    yield put(actions.setPasskeysState({
      loading: true
    }));
    yield put(actions.setLoginState({
      loading: true
    }));
    const publicKey = publicKeyCredentialToJSON(body.publicKey);
    const _yield$call = yield call(api.auth.webAuthnPostLoginV2, _extends({}, publicKey, {
        response: _extends({}, publicKey.response, {
          userHandle: (_publicKey$response$u = publicKey.response.userHandle) != null ? _publicKey$response$u : undefined
        }),
        recaptchaToken: body.recaptchaToken,
        invitationToken: body.invitationToken
      })),
      {
        user
      } = _yield$call,
      rest = _objectWithoutPropertiesLoose(_yield$call, _excluded10);
    if (isMfaRequired(user)) {
      const onRedirectTo = ContextHolder.onRedirectTo;
      const {
        routes
      } = yield select(state => state.auth);
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      yield call(afterAuthenticationStateUpdate, _extends({
        user
      }, rest), {
        isAuthenticated: true
      });
      yield put(actions.setLoginState({
        error: undefined
      }));
      yield afterAuthNavigation();
      // TODO: Itamar why moving callback to inside the else block
      callback == null ? void 0 : callback(true);
    }
    // callback?.(true);
  } catch (e) {
    yield put(actions.setLoginState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  } finally {
    yield put(actions.setPasskeysState({
      loading: false
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
  }
}
function* postLogin({
  payload
}) {
  const {
    onRedirectTo,
    routes
  } = yield select(({
    auth: {
      onRedirectTo,
      routes
    }
  }) => ({
    onRedirectTo,
    routes
  }));
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const user = yield call(api.auth.postLogin, payload);
    yield put(actions.setState({
      user: !!user.accessToken ? user : undefined,
      isAuthenticated: !!user.accessToken
    }));
    yield afterAuthNavigation();
  } catch (e) {
    setTimeout(() => {
      onRedirectTo(routes.authenticatedUrl);
    }, 1000);
    yield put(actions.setLoginState({
      step: LoginStep.loginWithSSOFailed,
      loading: false
    }));
  }
}
function* login({
  payload: {
    email,
    password,
    recaptchaToken,
    invitationToken,
    callback
  }
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const {
      user,
      tenants = [],
      activeTenant
    } = yield call(api.auth.loginv2, {
      email,
      password,
      recaptchaToken,
      invitationToken
    });
    const onRedirectTo = ContextHolder.onRedirectTo;
    const {
      routes
    } = yield select(state => state.auth);
    if (isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl, {
        preserveQueryParams: true
      });
    } else {
      const {
        loginState
      } = yield select(state => state.auth);
      const isAuthenticated = !!user.accessToken;
      if (user.id) {
        localStorage.setItem('userId', user.id);
      }
      yield call(afterAuthenticationStateUpdate, {
        user,
        tenants,
        activeTenant
      }, {
        loginState: {
          flow: loginState.flow,
          quickLoginToRegister: loginState.quickLoginToRegister,
          email,
          loading: false,
          error: undefined,
          mfaToken: user.mfaToken,
          step: loginState.flow === LoginFlow.Login ? LoginStep.success : loginState.step,
          tenants,
          tenantsLoading: true,
          isBreachedPassword: user.isBreachedPassword
        },
        isAuthenticated
      });
      const [securityCenterLoginFlows] = yield call(getFeatureFlags, ['security-center-show-login-flows']);
      if (loginState.flow === LoginFlow.Login) {
        if (securityCenterLoginFlows && user.isBreachedPassword && !isAuthenticated) {
          yield put(actions.setLoginState({
            step: LoginStep.breachedPassword,
            loading: false
          }));
        } else {
          if (isAuthenticated) {
            const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
            if (shouldShowPrompt) {
              yield put(actions.setLoginState({
                step: LoginStep.promptPasskeys,
                loading: false
              }));
              onRedirectTo(routes.loginUrl, {
                preserveQueryParams: true
              });
            } else {
              yield afterAuthNavigation();
            }
          }
        }
      }
    }

    // TODO: extract item name to constants
    localStorage.removeItem('register-quick-login');
    callback == null ? void 0 : callback(true);
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    callback == null ? void 0 : callback(false, e);
    yield put(actions.setLoginState({
      email,
      error: errorHandler(e),
      loading: false
    }));
  }
}
function* recoverMfa({
  payload
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    var _payload$callback;
    yield call(api.auth.recoverMfaToken, payload);
    yield put(actions.setLoginState({
      loading: false,
      error: undefined,
      step: LoginStep.preLogin
    }));
    yield put(actions.setState({
      user: undefined,
      isAuthenticated: false
    }));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setLoginState({
      error: errorHandler(e),
      loading: false
    }));
    (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, false, e);
  }
}
function* logout({
  payload
}) {
  const {
    hostedLoginBox
  } = yield select(state => state.auth);
  yield put(actions.setState({
    isLoading: true
  }));
  try {
    if (hostedLoginBox) {
      yield call(api.auth.OAuthLogout);
    } else {
      yield call(api.auth.logout);
    }
  } catch {}
  yield put(actions.resetState());
  yield put(actions.requestAuthorize(true));
  payload == null ? void 0 : payload();
}
function* silentLogout({
  payload
}) {
  var _payload$callbackTime;
  try {
    yield call(api.auth.logout);
  } catch {}
  setTimeout(() => {
    var _payload$callback3;
    return payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  }, (_payload$callbackTime = payload == null ? void 0 : payload.callbackTimeout) != null ? _payload$callbackTime : 500);
}
function* handleEnrollMFAResponse({
  user,
  tenants,
  activeTenant
}) {
  const mfaState = {
    step: MFAStep.recoveryCode,
    loading: false,
    error: undefined,
    saving: false
  };
  if (user != null && user.recoveryCode) {
    mfaState.recoveryCode = user.recoveryCode;
  }
  yield put(actions.setMfaState(mfaState));
  yield put(actions.setUser(user));
  yield put(actions.setTenantsState({
    tenants,
    activeTenant,
    loading: false
  }));
  if (user.id) {
    localStorage.setItem('userId', user.id);
  }
}
function* preEnrollMFASMSForLogin(_ref13) {
  let {
      payload: {
        callback
      }
    } = _ref13,
    payload = _objectWithoutPropertiesLoose(_ref13.payload, _excluded11);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.preEnrollMFASMSForLogin, payload);
    yield put(actions.setMfaState({
      otcToken: data.otcToken,
      step: MFAStep.smsVerifyCode,
      phoneNumber: data.phoneNumber
    }));
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* enrollMFASMSForLogin(_ref14) {
  let {
      payload: {
        callback
      }
    } = _ref14,
    payload = _objectWithoutPropertiesLoose(_ref14.payload, _excluded12);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.enrollMFASMSForLoginV2, payload);
    yield handleEnrollMFAResponse(data);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* preEnrollMFAWebAuthnForLogin(_ref15) {
  let {
      payload: {
        callback
      }
    } = _ref15,
    payload = _objectWithoutPropertiesLoose(_ref15.payload, _excluded13);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    var _data$options$exclude;
    const data = yield call(api.auth.preEnrollMFAWebAuthnForLogin, payload);
    const options = _extends({}, data.options, {
      challenge: base64urlDecode(data.options.challenge),
      user: _extends({}, data.options.user, {
        id: base64urlDecode(data.options.user.id)
      }),
      excludeCredentials: (_data$options$exclude = data.options.excludeCredentials) == null ? void 0 : _data$options$exclude.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }))
    });
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback({
      options,
      webauthnToken: data.webauthnToken
    });
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* enrollMFAWebAuthnForLogin(_ref16) {
  let {
      payload: {
        callback
      }
    } = _ref16,
    payload = _objectWithoutPropertiesLoose(_ref16.payload, _excluded14);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const publicKey = publicKeyCredentialToJSON(payload.publicKey);
    const data = yield call(api.auth.enrollMFAWebAuthnForLoginV2, _extends({}, payload, {
      options: publicKey
    }));
    yield handleEnrollMFAResponse(data);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* enrollMFAAuthenticatorAppForLogin(_ref17) {
  let {
      payload: {
        callback
      }
    } = _ref17,
    payload = _objectWithoutPropertiesLoose(_ref17.payload, _excluded15);
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    const data = yield call(api.auth.enrollMFAAuthenticatorAppForLoginV2, payload);
    yield handleEnrollMFAResponse(data);
    yield put(actions.setLoginState({
      loading: false
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}
function* resetBreachedPassword({
  payload
}) {
  yield put(actions.setLoginState({
    loading: true
  }));
  try {
    yield call(api.auth.forgotPassword, payload);
    yield put(actions.setLoginState({
      loading: false,
      error: undefined,
      step: LoginStep.breachedPasswordSuccess
    }));
    yield put(actions.setState({
      isAuthenticated: false
    }));
  } catch (e) {
    yield put(actions.setLoginState({
      loading: false,
      error: errorHandler(e)
    }));
  }
}
export function* loginSagas() {
  yield takeLeading(actions.requestAuthorize, requestAuthorize);
  yield takeLeading(actions.requestAuthorizeSSR, requestAuthorizeSSR);
  yield takeLeading(actions.requestHostedLoginAuthorize, refreshOrRequestHostedLoginAuthorize);
  yield takeLeading(actions.requestHostedLoginAuthorizeV2, refreshOrRequestHostedLoginAuthorizeV2);
  yield takeLeading(actions.handleHostedLoginCallback, handleHostedLoginCallback);
  yield takeLeading(actions.preLogin, preLogin);
  yield takeLeading(actions.postLogin, postLogin);
  yield takeLeading(actions.login, login);
  yield takeLeading(actions.logout, logout);
  yield takeLeading(actions.silentLogout, silentLogout);
  yield takeLeading(actions.loginWithMfa, loginWithMfa);
  yield takeLeading(actions.recoverMfa, recoverMfa);
  yield takeLeading(actions.quickSmsPasswordlessPreLogin, quickSmsPasswordlessPreLogin);
  yield takeLeading(actions.changePhoneNumberWithVerification, changePhoneNumberWithVerification);
  yield takeLeading(actions.verifyChangePhoneNumber, verifyChangePhoneNumber);
  yield takeLeading(actions.passwordlessPreLogin, passwordlessPreLogin);
  yield takeLeading(actions.passwordlessPostLogin, passwordlessPostLogin);
  yield takeLeading(actions.verifyInviteToken, verifyInviteToken);
  yield takeLeading(actions.webAuthnPrelogin, webAuthnPrelogin);
  yield takeLeading(actions.webAuthnPostLogin, webAuthnPostLogin);
  yield takeLeading(actions.webAuthnCreateNewDeviceSession, webAuthnCreateNewDeviceSession);
  yield takeLeading(actions.webAuthnVerifyNewDeviceSession, webAuthnVerifyNewDeviceSession);
  yield takeLeading(actions.afterAuthNavigation, afterAuthNavigation);
  yield takeLeading(actions.getUserIP, getUserIP);
  yield takeLeading(actions.preEnrollMFASMSForLogin, preEnrollMFASMSForLogin);
  yield takeLeading(actions.enrollMFASMSForLogin, enrollMFASMSForLogin);
  yield takeLeading(actions.preEnrollMFAWebAuthnForLogin, preEnrollMFAWebAuthnForLogin);
  yield takeLeading(actions.enrollMFAWebAuthnForLogin, enrollMFAWebAuthnForLogin);
  yield takeLeading(actions.enrollMFAAuthenticatorAppForLogin, enrollMFAAuthenticatorAppForLogin);
  yield takeLeading(actions.preVerifyMFASMSForLogin, preVerifyMFASMSForLogin);
  yield takeLeading(actions.verifyMFASMSForLogin, verifyMFASMSForLogin);
  yield takeLeading(actions.preVerifyMFAWebAuthnForLogin, preVerifyMFAWebAuthnForLogin);
  yield takeLeading(actions.verifyMFAWebAuthnForLogin, verifyMFAWebAuthnForLogin);
  yield takeLeading(actions.preVerifyMFAEmailCode, preVerifyMFAEmailCodeForLogin);
  yield takeLeading(actions.verifyMFAEmailCode, verifyMFAEmailCodeForLogin);
  yield takeLeading(actions.resetBreachedPassword, resetBreachedPassword);
}
export { afterAuthNavigation } from './sagas/afterAuthNavigation.saga';
export { mfaWithAuthenticator } from './sagas/mfaWithAuthenticator.saga';

/*********************************
 *  Preview Sagas
 *********************************/

function* requestAuthorizeMock({
  payload: firstTime
}) {
  if (firstTime) {
    yield put(actions.setState({
      isLoading: true
    }));
  }
  const user = userDemo;
  yield put(actions.loadTenants());
  yield put(actions.setState({
    user,
    isAuthenticated: true,
    isLoading: false
  }));
}
function* getUserIPMock({
  payload: {
    callback
  }
}) {
  try {
    const {
      ip
    } = dummyIps[0];
    yield put(actions.setState({
      userIp: ip
    }));
    callback == null ? void 0 : callback(true, ip);
  } catch (e) {
    callback == null ? void 0 : callback(false, e);
  }
}
export function* loginSagasMock() {
  yield takeLeading(actions.requestAuthorize, requestAuthorizeMock);
  yield takeLeading(actions.afterAuthNavigation, afterAuthNavigation);
  yield takeLeading(actions.getUserIP, getUserIPMock);
}