import _extends from "@babel/runtime/helpers/esm/extends";
import { extractHeadersFromOptions, Get, Post, Put } from "../fetch";
import { urls } from "../constants";
import { setTabTenantInSessionStorage } from '../auth/utils';
import { ContextHolder } from '../ContextHolder';
export async function switchTenant(body) {
  if (ContextHolder.isSessionPerTenantEnabled()) {
    setTabTenantInSessionStorage(body.tenantId);
  }
  return Put(`${urls.identity.users.v1}/tenant`, body);
}
export async function getTenants() {
  return Get(`${urls.identity.users.v2}/me/tenants`);
}
export async function getSubTenants(options) {
  return Get(urls.tenants.hierarchy.v1, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getSubTenantsAsTree(options) {
  return Get(urls.tenants.hierarchy.tree.v1, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getParentTenants(options) {
  return Get(urls.tenants.hierarchy.parents.v1, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getParentTenantsById(tenantId, options) {
  return Get(`${urls.tenants.hierarchy.parents.v1}/${tenantId}`, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getTenantsUsersCount(body, options) {
  return Post(`${urls.identity.tenants.users.v1}/count`, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function searchSubTenants(params, options) {
  return Get(urls.tenants.hierarchy.v2, params, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function getHierarchyMetadata(params, options) {
  return Get(urls.tenants.hierarchy.metadata.v2, _extends({}, params, {
    _tenantIds: params._tenantIds.join(',')
  }), {
    headers: extractHeadersFromOptions(options)
  });
}