<div [class]="'checkbox-label ' + parentClassName" title="{{parentTitle}}" [ngClass]="{'disabled-label': disabled}">
        <input type="checkbox" *ngIf="!inputControlName" [id]="id" [style]="style"
                [attr.formControl]="inputControlName ? inputControlName : null " [class]="className" class="lbl-check"
                [attr.name]="name" [attr.disabled]="disabled ? 'disabled' : null" [attr.title]="title" (change)="_valueChanged($event)" [checked]="isChecked">
        <input type="checkbox" *ngIf="inputControlName" [attr.title]="title" [id]="id" [style]="style" [formControl]="inputControlName"
                [class]="className" class="lbl-check" [attr.name]="name" [attr.disabled]="disabled ? 'disabled' : null"
                (change)="_valueChanged($event)" [checked]="isChecked">
        <label *ngIf="!showSubTitle" for="{{id}}" [innerHTML]="label" [title]="title ? title : '' "></label>
        <label *ngIf="showSubTitle" for="{{id}}" [title]="title ? title : '' "> {{label}}
                <span class="blue-text">({{extraParams.buildingFloorCnt}}</span> of
                {{extraParams.totalBuildingFloorCnt}} {{extraParams.totalBuildingFloorCnt > 1 ?'Plans':'Plan'}} selected)
        </label>
</div>