<div class="modal-header">
    <h1 class="modal-title pull-left">Create New {{tab === 'Ticket'?'Ticket':'Project'}}</h1>
    <button data-dismiss="modal" (click)="closeModal($event)" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body preview-modal-body">

    <div *ngIf="customers.length > 0;else noRights">
        <p>
            Select the Account that you would like to create a new{{tab === 'Ticket'? '' : ' '+ tab}} {{tab ===
            'Ticket'?'Ticket':'Project'}} for:</p>
        <div class="row">
            <div class="col-xl-12 d-flex justify-content-center">
                <div class="form-group">
                    <i *ngIf="isSpinner" class="fa fa-spinner  fa-animated"></i>
                    <select *ngIf="!isSpinner" name="customer" id="account" class="form-control"
                        style="min-width: 250px" [ngModel]="selectedCustomer"
                        (ngModelChange)="selectedCustomer = $event ? $event : null">
                        <option [ngValue]="''">Select Account</option>
                        <option *ngFor="let cat of customers" [ngValue]="cat">{{cat.name}}</option>
                    </select>

                </div>
            </div>
        </div>

    </div>
    <ng-template #noRights>
        <div *ngIf="!isSpinner">
            You don't have access to create a {{tab === 'Ticket'?'ticket':'project'}} for any customer. Please contact
            your coordinator to confirm your access.
        </div>
    </ng-template>

</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal($event)" data-dismiss="modal">Cancel</button>
    <button *ngIf="customers.length > 0" [ngStyle]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}"
        [disabled]="customers.length === 0 || selectedCustomer?.id === '' || selectedCustomer?.id === null || btnService.getStatus()"
        type="button" (click)="create()" class="btn btn-primary m-0">Select</button>


</div>