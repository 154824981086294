<div class="d-flex align-items-center">
    <div class="me-auto">
        <ol class="align-self-center breadcrumb">
            <li>
                <h1 class="me-0">{{header}}</h1>
            </li>
            <li *ngIf="partialHeader">
                <ng-container>
                    {{partialHeader}}
                </ng-container>
            </li>
        </ol>
        <p class="m-0" *ngIf="subHeader && text===''"><strong>{{header}}</strong> {{subHeader}}</p>

        <p class="m-0" *ngIf="subHeader && text!==''"><strong>{{header}}</strong> {{subHeader}} <strong>{{subTitleHeader}}</strong> {{text}}</p>
    </div>
</div>