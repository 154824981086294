import { createAction } from '@reduxjs/toolkit';
import { LoginFlow, LoginStep } from './interfaces';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const loginState = {
  flow: LoginFlow.Login,
  step: LoginStep.preLogin,
  loading: false,
  email: '',
  tenants: []
};
const reducers = {
  setLoginState: typeReducerForKey('loginState'),
  resetLoginState: resetStateByKey('loginState', {
    loginState
  })
};
const actions = {
  requestAuthorize: createAction(`${authStoreName}/requestAuthorize`, (payload = false) => ({
    payload
  })),
  requestAuthorizeSSR: createAction(`${authStoreName}/requestAuthorizeSSR`, payload => ({
    payload
  })),
  requestHostedLoginAuthorize: createAction(`${authStoreName}/requestHostedLoginAuthorize`, payload => ({
    payload
  })),
  requestHostedLoginAuthorizeV2: createAction(`${authStoreName}/requestHostedLoginAuthorizeV2`, payload => ({
    payload
  })),
  handleHostedLoginCallback: createAction(`${authStoreName}/handleHostedLoginCallback`, payload => ({
    payload
  })),
  afterAuthNavigation: createAction(`${authStoreName}/afterAuthNavigation`),
  preLogin: createAction(`${authStoreName}/preLogin`, payload => ({
    payload
  })),
  postLogin: createAction(`${authStoreName}/postLogin`, payload => ({
    payload
  })),
  login: createAction(`${authStoreName}/login`, payload => ({
    payload
  })),
  loginWithMfa: createAction(`${authStoreName}/loginWithMfa`, payload => ({
    payload
  })),
  recoverMfa: createAction(`${authStoreName}/recoverMfa`, payload => ({
    payload
  })),
  logout: createAction(`${authStoreName}/logout`, payload => ({
    payload
  })),
  silentLogout: createAction(`${authStoreName}/silentLogout`, payload => ({
    payload
  })),
  checkIfAllowToRememberMfaDevice: createAction(`${authStoreName}/checkIfAllowToRememberMfaDevice`, payload => ({
    payload
  })),
  changePhoneNumberWithVerification: createAction(`${authStoreName}/changePhoneNumberWithVerification`, payload => ({
    payload
  })),
  verifyChangePhoneNumber: createAction(`${authStoreName}/verifyChangePhoneNumber`, payload => ({
    payload
  })),
  quickSmsPasswordlessPreLogin: createAction(`${authStoreName}/quickSmsPasswordlessPreLogin`, payload => ({
    payload
  })),
  passwordlessPreLogin: createAction(`${authStoreName}/passwordlessPreLogin`, payload => ({
    payload
  })),
  passwordlessPostLogin: createAction(`${authStoreName}/passwordlessPostLogin`, payload => ({
    payload
  })),
  verifyInviteToken: createAction(`${authStoreName}/verifyInviteToken`, payload => ({
    payload
  })),
  webAuthnPrelogin: createAction(`${authStoreName}/webAuthnPrelogin`, payload => ({
    payload
  })),
  webAuthnPostLogin: createAction(`${authStoreName}/webAuthnPostLogin`, payload => ({
    payload
  })),
  webAuthnCreateNewDeviceSession: createAction(`${authStoreName}/webAuthnCreateNewDeviceSession`, payload => ({
    payload
  })),
  webAuthnVerifyNewDeviceSession: createAction(`${authStoreName}/webAuthnVerifyNewDeviceSession`, payload => ({
    payload
  })),
  getUserIP: createAction(`${authStoreName}/getUserIP`, payload => ({
    payload
  })),
  preEnrollMFAWebAuthnForLogin: createAction(`${authStoreName}/preEnrollWebAuthNForLogin`, payload => ({
    payload
  })),
  enrollMFAWebAuthnForLogin: createAction(`${authStoreName}/enrollWebAuthNForLogin`, payload => ({
    payload
  })),
  enrollMFAAuthenticatorAppForLogin: createAction(`${authStoreName}/enrollMFAAuthenticatorAppForLogin`, payload => ({
    payload
  })),
  preEnrollMFASMSForLogin: createAction(`${authStoreName}/preEnrollMFASMSForLogin`, payload => ({
    payload
  })),
  enrollMFASMSForLogin: createAction(`${authStoreName}/enrollMFASMSForLogin`, payload => ({
    payload
  })),
  preVerifyMFAWebAuthnForLogin: createAction(`${authStoreName}/preVerifyMFAWebAuthnForLogin`, payload => ({
    payload
  })),
  verifyMFAWebAuthnForLogin: createAction(`${authStoreName}/verifyMFAWebAuthnForLogin`, payload => ({
    payload
  })),
  preVerifyMFASMSForLogin: createAction(`${authStoreName}/preVerifyMFASMSForLogin`, payload => ({
    payload
  })),
  verifyMFASMSForLogin: createAction(`${authStoreName}/verifyMFASMSForLogin`, payload => ({
    payload
  })),
  preVerifyMFAEmailCode: createAction(`${authStoreName}/preVerifyMFAEmailCode`, payload => ({
    payload
  })),
  verifyMFAEmailCode: createAction(`${authStoreName}/verifyMFAEmailCode`, payload => ({
    payload
  })),
  resetBreachedPassword: createAction(`${authStoreName}/resetBreachedPassword`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { loginState, reducers as loginReducers, actions as loginActions };
export { getRedirectUrl, getSearchParam } from './utils';
export { defaultFronteggRoutes } from './consts';