import { Utilities } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'flooractivefilter'
})
export class FlooractivefilterPipe implements PipeTransform {

  transform(items: any[], filter: any): any[] {
    if (!Utilities.isNull(filter.buildings) && filter.flag) {
      if (items && items.length > 0) {
        items = items.filter(x => x.projectFloorsDTO !== undefined && x.projectFloorsDTO.some((y: any) => y.isActive));
      }
    }
    return items;
  }

}
