/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, ButtonEnableDisableService, CommonService, Customer, CustomerService, MessageService, Pattern, Roles, User, Utilities, ValidationService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-user-create-edit',
  templateUrl: './user-create-edit.component.html',
  styleUrls: ['./user-create-edit.component.scss'],
})
export class UserCreateEditComponent implements OnInit {

  isEditMode = false;
  nullValue: any = null;
  userDetail!: User;
  userForm!: FormGroup;
  customers!: Customer[];
  roles!: any[];
  isFormSubmitted = false;
  currentUser: any;
  roleSelection: any = 'global_Administrator';
  isTextFieldType = false;
  shoHidePassword = false;
  isTextFieldTypeSetPassword = false;
  public event: EventEmitter<any> = new EventEmitter();
  strMessage = `Don't change the password`;

  constructor(
      public btnService: ButtonEnableDisableService,
      private customerService: CustomerService,
      private fb: FormBuilder,
      private messageService: MessageService,
      private commonService: CommonService,
      private authenticationService: AuthService,
      public bsModalRef: BsModalRef,
      public bsModalRef1: BsModalRef
  ) {}

  private getCustomers() {
        this.customerService.getCustomers().subscribe({
          next: (result: any) => {
                this.customers = result;
          }     
        });
  }



  private setFormValue() {
      this.userForm.patchValue(this.userDetail, { onlySelf: true });
      const keySelection: any = this.roles.find(x => x.id === this.userDetail?.role?.id);
      this.roleSelection = keySelection.roleKey;
  }

  private createForm() {
      this.userForm = this.fb.group({
          name: ['', [Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
          email: ['', [Validators.required, Validators.pattern(Pattern.EMAIL)]],
          role: [{ value: '', disabled: this.currentUser.email === this.userDetail.email }, [Validators.required]],
          password: ['', [Validators.required, ValidationService.passwordValidator]],
          employer: ['', [Validators.maxLength(80)]],
          isPasswordSetByAdmin: [false, []],
          passwordUpdateFlag: ['', []]
      });
      const keySelection: any = this.roles.find(x => x.id === 1);
      this.roleSelection = keySelection.roleKey;
      this.userForm.controls['role'].setValue(this.roles.find(x => x.id === 1), { onlySelf: true });
      this.passwordSetChanged();
  }

  private addUser(exit?: boolean) {
      const user = this.userForm.getRawValue();
      this.btnService.disable()

      this.customerService.addUser(user).subscribe({
          next: (result: any) => {
              this.btnService.enable();
              this.messageService.successMessage(`User:  ${Utilities.isEmpty(user.name) ? user.email : user.name}`, 'Invited Successfully');
              this.event.emit(result);
              this.bsModalRef.hide();
          }, error: (error: any) => {
              this.btnService.enable()
              this.bsModalRef.hide();
              if (error.status === 409 || error.status === 422) {
                  this.messageService.errorMessage(`User`, error.error.message);
              } else {
                  this.messageService.errorMessage(`Error`, 'Error while saving');
              }
          }   
        });
  }

  private updateUser(exit?: boolean) {
      this.btnService.disable()
      const user: User = this.userForm.value;
      user.id = this.userDetail.id;
      user.isActive = this.userDetail.isActive;
      if (this.currentUser.email === this.userDetail.email) {
          user.role = this.userDetail.role;
      }
      user.name = Utilities.isEmpty(user.name && user.name.trim()) ? this.userDetail.name : user.name;
      this.customerService.updateUser(user).subscribe({
          next: (result: any) => {
              this.btnService.enable()
              const data = this.commonService.getLocalStorageObject('userData');
              data.name = user.name;
              data.user.name = user.name;
              data.user.email = user.email;
              data.email = user.email;
              this.commonService.setLocalStorageObject('userData', data);
              this.messageService.successMessage(`User:  ${Utilities.isEmpty(user.name) ? user.email : user.name}`, 'Invited Successfully');
              this.event.emit(data);
              this.bsModalRef.hide();
          },error: (updateError: any) => {
              this.btnService.enable()
              this.bsModalRef.hide();
              if (updateError.status === 409 || updateError.status === 422) {
                  this.messageService.errorMessage(`User`, updateError.error.message);
              } else {
                  this.messageService.errorMessage(`Error`, 'Error while saving');
              }
          } 
          });
  }

  ngOnInit() {
      this.currentUser = this.authenticationService.getUserKeyData();
      this.getCustomers();
      this.createForm();
      if (this.isEditMode) {
          this.setFormValue();
      }
  }


  saveUser() {
      if (!this.userForm.controls["isPasswordSetByAdmin"].value && !this.isEditMode) {
          this.passwordSetChanged();
      }
      if (this.userForm.invalid) {
          this.isFormSubmitted = true;
          return
      }
      const user: User = this.userForm.value;
      const name = Utilities.isEmpty((user.name && user.name.trim())) ? this.userDetail.name : user.name;
      this.userForm.controls['name'].setValue(name);
      if (this.userForm.controls['password'].invalid && !this.userForm.controls['isPasswordSetByAdmin'].value) {
          this.userForm.controls['password'].clearValidators();
          this.userForm.controls['password'].updateValueAndValidity();
          this.userForm.controls['password'].reset();
      }
      if (this.userForm.invalid) {
          this.isFormSubmitted = true;
          return;
      }
      if (this.isEditMode) {
          this.updateUser();
      } else {
          this.addUser();
      }
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  roleChanged(item?: any, type?: any): void {

      if (type === 'role') {
          this.userForm.value.role = item;
          this.userForm.controls['role'].setValue(this.roles.find(x => x.id === item.id), { onlySelf: true });
      }
  }

  roleChanged1(): void {
      switch (this.userForm.value.role.name) {
          case Roles.Administrator:
              this.userForm.updateValueAndValidity();
              break;
          case Roles.User:
          case Roles.Technician:
              break;
          default:
              break;
      }
  }

  passwordSetChanged(isPasswordSetByAdmin?: boolean, passwordResetType?: any): void {
      if (isPasswordSetByAdmin) {
          this.userForm.controls["isPasswordSetByAdmin"].setValue(true);
          this.userForm.controls["passwordUpdateFlag"].setValue('SET_NEW_PASSWORD');
      } else {
          this.userForm.controls["isPasswordSetByAdmin"].setValue(false);
          this.userForm.controls['password'].clearValidators();
          this.userForm.controls['password'].updateValueAndValidity();
          this.userForm.controls['password'].reset();
      }

      if ((!this.isEditMode && this.userForm.controls["isPasswordSetByAdmin"].value)
          || (this.isEditMode && this.userForm.controls["passwordUpdateFlag"].value === 'SET_NEW_PASSWORD')) {
          this.userForm.controls["password"].enable();
          this.userForm.controls["password"].setValidators([Validators.required, ValidationService.passwordValidator]);
          this.userForm.controls["password"].setValue('');
          this.userForm.controls["password"].updateValueAndValidity();
          this.shoHidePassword = true;

      } else {
          this.userForm.controls["password"].setValue('');
          this.userForm.controls["password"].disable();
          this.userForm.controls["password"].updateValueAndValidity();
          this.shoHidePassword = false;

      }
      if (passwordResetType && passwordResetType !== undefined && passwordResetType !== null && passwordResetType !== '') {
          this.userForm.controls["passwordUpdateFlag"].setValue(passwordResetType);
      }
  }
  ngOnDestroy() {
      this.btnService.enable();
  }
  togglePasswordFieldType() {
      this.isTextFieldTypeSetPassword = !this.isTextFieldTypeSetPassword;
  }
}
