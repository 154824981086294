import isEqual from 'fast-deep-equal';
import { call, put, takeLeading, select } from 'redux-saga/effects';
import { USE_ENTITLEMENTS_V2_ENDPOINT_FF, api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { getFeatureFlags } from '../../helpers';

/**
 * @param oldEntitlements
 * @param newEntitlements
 * @returns true when old and new entitlements are deeply equal
 */
export function isEntitlementsDeeplyEqual(oldEntitlements, newEntitlements) {
  return isEqual(oldEntitlements, newEntitlements);
}

/**
 * Handle fetched entitlements response by deep comparison with the stored entitlements to prevent
 * useless renders due to store update
 * Updating loading state anyway
 * @param newEntitlementsResponse
 */
export function* handleFetchedEntitlements(newEntitlementsResponse) {
  const oldEntitlements = yield select(state => {
    var _state$auth$user;
    return (_state$auth$user = state.auth.user) == null ? void 0 : _state$auth$user.entitlements;
  });
  if (isEntitlementsDeeplyEqual(oldEntitlements, newEntitlementsResponse)) {
    return;
  }
  yield put(actions.setEntitlements(newEntitlementsResponse));
}

/**
 * Load entitlements data for saga action
 * Don't update entitlements data in the store when data is equal (deep check) to the existing store data
 * @param payloadAction saga payload action including a payload with/out a callback
 *                      The callback will be called on request completed with true if succeeded, false if failed
 */
export function* loadEntitlements({
  payload
}) {
  const callback = payload == null ? void 0 : payload.callback;
  const [useEntitlementsV2] = yield call(getFeatureFlags, [USE_ENTITLEMENTS_V2_ENDPOINT_FF]);
  try {
    const entitlements = yield call(useEntitlementsV2 ? api.entitlements.loadEntitlementsV2 : api.entitlements.loadEntitlements);
    yield handleFetchedEntitlements(entitlements);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    callback == null ? void 0 : callback(false);
  } finally {}
}
export function* entitlementsSagas() {
  yield takeLeading(actions.loadEntitlements, loadEntitlements);
}