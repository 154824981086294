import { all, call } from 'redux-saga/effects';
import { subscriptionBillingInformationSagas, subscriptionBillingInformationSagasMock } from './Information/saga';
import { subscriptionsPaymentMethodSagas, subscriptionsPaymentMethodSagasMock } from './PaymentMethod/saga';
import { subscriptionInvoicesSagas, subscriptionInvoicesSagasMock } from './Invoices/saga';
import { subscriptionSagas, subscriptionSagasMock } from './Subscription/saga';
export function* billingSagas() {
  yield all([call(subscriptionBillingInformationSagas), call(subscriptionsPaymentMethodSagas), call(subscriptionInvoicesSagas), call(subscriptionSagas)]);
}
export function* billingSagasMock() {
  yield all([call(subscriptionBillingInformationSagasMock), call(subscriptionsPaymentMethodSagasMock), call(subscriptionInvoicesSagasMock), call(subscriptionSagasMock)]);
}