import _extends from "@babel/runtime/helpers/esm/extends";
export const resetStateByKey = (key, initialState) => state => _extends({}, state, {
  [key]: initialState[key]
});
export const typeReducer = key => (state, {
  payload
}) => _extends({}, state, {
  [key]: payload
});
export const typeReducerForKey = key => ({
  prepare: payload => ({
    payload
  }),
  reducer: (state, {
    payload
  }) => {
    return _extends({}, state, {
      [key]: _extends({}, state[key], payload)
    });
  }
});
export const typeReducerNestedKey = (key, nestedKey) => ({
  prepare: payload => ({
    payload
  }),
  reducer: (state, {
    payload
  }) => {
    var _state$key;
    return _extends({}, state, {
      [key]: _extends({}, state[key], {
        [nestedKey]: _extends({}, state == null ? void 0 : (_state$key = state[key]) == null ? void 0 : _state$key[nestedKey], payload)
      })
    });
  }
});
export const loadersReducerForKey = key => ({
  prepare: payload => ({
    payload
  }),
  reducer: (state, {
    payload
  }) => {
    var _state$key2, _payload$value;
    return _extends({}, state, {
      [key]: _extends({}, state[key], {
        loaders: _extends({}, (_state$key2 = state[key]) == null ? void 0 : _state$key2.loaders, {
          [payload.key]: (_payload$value = payload.value) != null ? _payload$value : true
        })
      })
    });
  }
});
export const errorsReducerForKey = key => ({
  prepare: payload => ({
    payload
  }),
  reducer: (state, {
    payload
  }) => {
    var _payload$value2;
    return _extends({}, state, {
      [key]: _extends({}, state[key], {
        errors: _extends({}, state[key].errors, {
          [payload.key]: (_payload$value2 = payload.value) != null ? _payload$value2 : true
        })
      })
    });
  }
});
export const dialogStateForKey = (key, dialogKey, dialogState) => ({
  prepare: payload => ({
    payload
  }),
  reducer: (state, {
    payload
  }) => {
    var _state$key3;
    return _extends({}, state, {
      [key]: _extends({}, state[key], {
        [dialogKey]: _extends({}, state == null ? void 0 : (_state$key3 = state[key]) == null ? void 0 : _state$key3[dialogKey], payload, dialogState)
      })
    });
  }
});
export const dialogStateForKeyWithoutPayload = (key, dialogKey, dialogState) => state => {
  var _state$key4;
  return _extends({}, state, {
    [key]: _extends({}, state[key], {
      [dialogKey]: _extends({}, state == null ? void 0 : (_state$key4 = state[key]) == null ? void 0 : _state$key4[dialogKey], dialogState)
    })
  });
};
export const delay = (delayTime = 500) => new Promise(resolve => setTimeout(resolve, delayTime));
const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
const lookup = new Uint8Array(256);
for (let i = 0; i < chars.length; i++) {
  lookup[chars.charCodeAt(i)] = i;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const base64urlEncode = arraybuffer => {
  const bytes = new Uint8Array(arraybuffer);
  const len = bytes.length;
  let i;
  let base64url = '';
  for (i = 0; i < len; i += 3) {
    base64url += chars[bytes[i] >> 2];
    base64url += chars[(bytes[i] & 3) << 4 | bytes[i + 1] >> 4];
    base64url += chars[(bytes[i + 1] & 15) << 2 | bytes[i + 2] >> 6];
    base64url += chars[bytes[i + 2] & 63];
  }
  if (len % 3 === 2) {
    base64url = base64url.substring(0, base64url.length - 1);
  } else if (len % 3 === 1) {
    base64url = base64url.substring(0, base64url.length - 2);
  }
  return base64url;
};
export const base64urlDecode = base64string => {
  const bufferLength = base64string.length * 0.75;
  const len = base64string.length;
  let i,
    p = 0,
    encoded1,
    encoded2,
    encoded3,
    encoded4;
  const bytes = new Uint8Array(bufferLength);
  for (i = 0; i < len; i += 4) {
    encoded1 = lookup[base64string.charCodeAt(i)];
    encoded2 = lookup[base64string.charCodeAt(i + 1)];
    encoded3 = lookup[base64string.charCodeAt(i + 2)];
    encoded4 = lookup[base64string.charCodeAt(i + 3)];
    bytes[p++] = encoded1 << 2 | encoded2 >> 4;
    bytes[p++] = (encoded2 & 15) << 4 | encoded3 >> 2;
    bytes[p++] = (encoded3 & 3) << 6 | encoded4 & 63;
  }
  return bytes.buffer;
};
export const publicKeyCredentialToJSON = pubKeyCred => {
  if (pubKeyCred instanceof Array) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const arr = [];
    for (const i of pubKeyCred) {
      arr.push(publicKeyCredentialToJSON(i));
    }
    return arr;
  } else if (pubKeyCred instanceof ArrayBuffer) {
    return base64urlEncode(pubKeyCred);
  } else if (pubKeyCred instanceof Object) {
    const obj = {};
    for (const key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }
    return obj;
  }
  return pubKeyCred;
};