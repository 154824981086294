<ng-container *ngIf="toolTipObj">
    <div *ngIf="!isRoleToolTip && toolTipObj.email" (mouseover)="removePos($event)" class="custom-tooltip-content"
        [ngClass]="{'right' : displayRight}" [ngStyle]="toolTipObj.style">
        <div class="user-profile-block d-flex align-items-center">
            <div class="user-profile-img">
                <img alt="Profile Picture" [src]="toolTipObj.picture || (cdnUrl + 'assets/images/user-image.png')"
                    class="img-responsive">
            </div>
            <div class="user-desc">
                <div class="user-name">{{toolTipObj.name}}</div>
                <a href="javascript:void(0);">{{toolTipObj.email}}</a>
            </div>
        </div>
        <div class="user-profile-detail">
            <div *ngIf="toolTipObj?.employer" class="user-info-lable user-employer d-flex align-items-center">
                <label>Employer:&nbsp;</label>
                <span>{{toolTipObj.employer}}</span>
            </div>
            <div *ngIf="toolTipObj?.provider" class="user-info-lable user-employer  d-flex align-items-center">
                <label>Headquarters:&nbsp;</label>
                <span>{{toolTipObj.provider}}</span>
            </div>
            <div *ngIf="toolTipObj?.work || toolTipObj?.mobile" class=" custom-tooltip-bot d-flex align-items-center">
                <div *ngIf="toolTipObj?.work " class="user-info-lable d-flex align-items-center">
                    <label>Work:&nbsp;</label>
                    <span>{{toolTipObj.work}}</span>
                </div>
                <div *ngIf="toolTipObj?.mobile " class="user-info-lable mobile-info d-flex align-items-center ms-auto">
                    <label>Mobile:&nbsp;</label>
                    <span>{{toolTipObj.mobile}}</span>
                </div>
            </div>


        </div>
    </div>

    <div *ngIf="isRoleToolTip && toolTipObj.name" (mouseover)="removePos($event)"
        class="role-tooltip custom-tooltip-content "
        [ngClass]="{'right' : displayRight, 'left' : displayLeft, 'top': displayTop}" [ngStyle]="toolTipObj.style">
        <p class="role-name">
            <strong>{{toolTipObj.name === 'View All' ? 'View Only'
                :
                toolTipObj.name}}</strong>
            
            ({{toolTipObj.displayRoleType}})
        </p>
        <ul class="role-list">
            <li *ngFor="let item of toolTipObj.newDescription">
                {{item}}
            </li>
        </ul>
        <span class="typical-user">
            <label>Typical User:</label> {{toolTipObj.typicalUser}}
        </span>

    </div>

    <div *ngIf="isCustomerToolTip" (mouseover)="removePos($event)" class="role-tooltip custom-tooltip-content "
        [ngClass]="{'right' : displayRight, 'left' : displayLeft, 'top': displayTop}" [ngStyle]="toolTipObj.style">
        <p class="role-name mb-1" *ngIf="!toolTipObj.customer.isIntegratorUserAdded">No one in
            <strong>{{toolTipObj.provider.name}}</strong> has Administrator access to
            <strong>{{toolTipObj.customer.name}}</strong>. Please contact SiteOwl support at <a
                href="mailto:support@getsiteowl.com">support&#64;getsiteowl.com</a> for assistance.
        </p>
        <p class="role-name mb-1" *ngIf="toolTipObj.customer.isIntegratorUserAdded">You do not have access to
            <strong>{{toolTipObj.customer.name}}</strong>. Please ask one of the following Administrators on
            <strong>{{toolTipObj.customer.name}}</strong> to give you access:
        </p>
        <ul class="user-list" *ngIf="toolTipObj.customer.isIntegratorUserAdded">
            <li *ngFor="let item of toolTipObj.customer.customerAdmins">
                <ng-container *ngIf="item.name">
                    {{item.name}}
                </ng-container>
                (<a href="mailto:{{item.email}}">{{item.email}}</a>)
            </li>
        </ul>
    </div>


    <div *ngIf="noLiveSystemAccess" (mouseover)="removePos($event)" class="role-tooltip custom-tooltip-content "
        [ngClass]="{'right' : displayRight, 'left' : displayLeft, 'top': displayTop}" [ngStyle]="toolTipObj.style">
        <p><span>Live Sites is not enabled for this Account</span> </p>
    </div>
    <div *ngIf="customConnectionToolTip" (mouseover)="removePos($event)" class="role-tooltip custom-tooltip-content info-tooltip"
        [ngClass]="" [ngStyle]="toolTipObj.style">
        <p><span> Click on any point of the connection line to add a draggable Vertex to define a Cable Pathway. Click again on a Vertex to remove it.</span> </p>
    </div>
    <div *ngIf="customMessage" (mouseover)="removePos($event)" class="role-tooltip custom-tooltip-content info-tooltip"
        [ngClass]="" [ngStyle]="toolTipObj.style">
        <p><span> {{toolTipObj.message}}</span> </p>
    </div>

    <div *ngIf="defaultAdminTooltip" (mouseover)="removePos($event)"
        class="role-tooltip custom-tooltip-content table-tooltip" [ngClass]="" [ngStyle]="toolTipObj.style">
        <p><span>{{toolTipObj.message}}</span> </p>
    </div>
</ng-container>


<div *ngIf="isAdminToolTip" (mouseover)="removePos($event)" class="role-tooltip custom-tooltip-content "
    [ngClass]="{'right' : displayRight, 'left' : displayLeft, 'top': displayTop}" [ngStyle]="">
    <p class="role-name"><span>{{adminRole.name}}</span> </p>
    <ul class="role-list">
        <li *ngFor="let roleDesc of adminRole.newDescription">
            {{roleDesc}}
        </li>
    </ul>
    <span class="typical-user">
        <label>Typical User:</label> {{adminRole.typicalUser}}
    </span>
</div>