<div class="creat-new-modal">
    <div class="modal-header justify-content-end">
        <button data-dismiss="modal" aria-label="Close" class="close" type="button" (click)="_action('cancel')">
      <i class="fas fa-close"></i>
    </button>
    </div>
    <div class="modal-body add-new-dialog d-flex align-items-center">
        <div class="row">
            <div class="col-sm-10 offset-sm-1">
                <div class="row">

                    <div class="col-sm-4 text-center">
                        <i class="fal fa-layer-group"></i>
                        <h2>New Site </h2>

                        <p>
                            Sites help to organize your Buildings and Plans. Sites can be regions, campuses, or just groups of similar Buildings.
                        </p>
                        <button class="btn btn-primary" (click)="_action('site')">
              <i class="fas fa-plus"></i> New Site
            </button>
                    </div>
                    <div class="col-sm-4 text-center">
                        <i class="fal fa-building"></i>
                        <h2>New Building</h2>
                        <p>
                            Buildings are collections of Plans. They allow Plans to be organized with a Building name and street address.
                        </p>
                        <button class="btn btn-primary" (click)="_action('building')">
              <i class="fas fa-plus"></i> New Building
            </button>
                    </div>
                    <div class="col-sm-4 text-center">
                        <i class="fal fa-map"></i>
                        <h2> New Plan</h2>
                        <p>
                            Plans are where your Devices and all of your Device information live.</p>
                        <button class="btn btn-primary" (click)="_action('plan')">
              <i class="fas fa-plus"></i> New Plan
            </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class=" modal-footer d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-secondary mrg-0"  (click)="_action('cancel')">Cancel</button>
    </div>
</div>