import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';


import linkifyHtml from 'linkify-html';
// import linkifyHtml = require('linkify-html');

@Pipe({
  name: 'linkify'
})
export class LinkifyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null || value === '' || value === undefined) {
      return value;
    }
    value = _.escape(value)
    return linkifyHtml(value, args);
  }

}
