"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BooleanSanitizersMapper = exports.singleBooleanSanitizer = void 0;
const types_1 = require("../types");
const isBoolean = value => typeof value === 'boolean';
const singleBooleanSanitizer = value => {
  const sanitizedValue = value.boolean !== undefined && isBoolean(value.boolean) ? {
    boolean: value.boolean
  } : undefined;
  return {
    isSanitized: !!sanitizedValue,
    sanitizedValue
  };
};
exports.singleBooleanSanitizer = singleBooleanSanitizer;
exports.BooleanSanitizersMapper = {
  [types_1.OperationEnum.Is]: exports.singleBooleanSanitizer
};
