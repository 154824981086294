<div class="modal-header">
    <h1 class="modal-title pull-left">Add User to Accounts
    </h1>
   
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close" type="button">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body multiple-customer-body" *ngIf="customerList && customerList.length > 0;else noCustomers">
    <p>
        Select which Accounts access you would like to grant to <strong>{{userDetail.name || userDetail.email
            }}</strong>.
    </p>
    <div class="multi-customer-section">

        <form [formGroup]="customerForm">
            <div class="form-group">
                <div class="row">
                    <div class="col-sm-10">
                        <label>Accounts<sup>*</sup></label>
                        <ng-multiselect-dropdown name="customer" [settings]="dropdownSettings"
                            [placeholder]="'Select Account'" [data]="remainCustomerList" formControlName="customer"
                            [disabled]="disabled" (onSelect)="onItemSelectController($event)"
                            (onSelectAll)="onSelectAllController($event)"
                            (onDeSelect)="onItemDeSelectController($event)"
                            (onDeSelectAll)="onDeSelectAllController()">
                        </ng-multiselect-dropdown>
                        <so-control-messages [name]="'Account'"
                            [control]="$any(customerForm).controls.customer"></so-control-messages>
                    </div>
                </div>

            </div>
            <div class="form-group">

                <div class="mt-2">
                    <label for="category_admin" class="role-card align-items-center d-flex"
                        [ngClass]="{'active': customerForm.value.isAdmin, 'd-flex': 'true'}">
                        <div class="role-card-radio checkbox-label action-col pointer-events-none">                        
                            <so-checkbox [isChecked]="customerForm.value.isAdmin" (onChange)="toggleAdmin($event)"
                                [label]="adminRole.name" [id]="'category_admin'" [ngClass]="'lbl-check'">
                            </so-checkbox>
                        </div>
                        <div class="role-card-description">
                            <ul class="role-card-list">
                                <li *ngFor="let roleDesc of adminRole?.newDescription">
                                    {{roleDesc}}
                                </li>
                            </ul>
                        </div>
                        <div class="role-typical-user">
                            <span class="user-title"> Typical User</span>
                            <span class="user">{{adminRole?.typicalUser}}</span>
                        </div>
                    </label>

                </div>
            </div>
        </form>
        <div class="form-group">
            <label>Project Role <sup>*</sup></label>
            <div [hidden]="!canDisplay || userProjectRoles?.length === 0">
                <label for="project_{{itemProject.name}}" class="role-card d-flex align-items-center"
                    *ngFor="let itemProject of userProjectRoles; let i = index;"
                    [ngClass]="{'active': (itemProject.isDefaultSelected || (customerForm.value.isAdmin && itemProject.roleKey === 'project_Manager'))}">
                    <div class="role-card-radio action-col">
                        <so-radio [id]="'project_' + itemProject.name" [name]="'projectRole'" *ngIf="!customerForm.value.isAdmin"
                            (onChange)="onItemChange(itemProject,false)" [isChecked]="itemProject.isDefaultSelected" [label]="itemProject.name === 'View All' ? 'View Only' : itemProject.name">
                        </so-radio>
                         <label *ngIf="customerForm?.value?.isAdmin">{{itemProject.name === 'View All' ? 'View Only' : itemProject.name}}</label>
                    </div>
                    <div class="role-card-description">
                        <ul class="role-card-list">
                            <li *ngFor="let roleDesc of itemProject?.newDescription">
                                {{roleDesc}}
                            </li>
                        </ul>
                    </div>
                    <div class="role-typical-user">
                        <span class="user-title"> Typical User</span>
                        <span class="user"> {{itemProject.typicalUser}}</span>
                    </div>
                </label>
            </div>
            <p *ngIf="!canDisplay" class="label-message">No Account selected.</p>
        </div>
        <div class="form-group" *ngIf="isLiveSystemAccessForCustomer && (canDisplay || userSiteRoles?.length > 0)">
            <label>Live Site Role <sup>*</sup></label>

            <div [hidden]="!canDisplay || userSiteRoles?.length === 0">
                <label for="amt_{{item.name}}" class="role-card align-items-center d-flex"
                    *ngFor="let item of userSiteRoles; let i = index;"
                    [ngClass]="{'active': (item.isDefaultSelected || (customerForm.value.isAdmin && item.roleKey === 'amt_Manager'))}">
                    <div class="role-card-radio action-col">
                        <so-radio [id]="'amt_' + item.name" [name]="'role'" *ngIf="!customerForm.value.isAdmin"
                            (change)="onItemChange(item,true)" [isChecked]="item.isDefaultSelected" [label]="item.name === 'View All' ? 'View Only' : item.name">
                        </so-radio>
                        <label *ngIf="customerForm?.value?.isAdmin">{{item.name === 'View All' ? 'View Only' : item.name}}</label>
                    </div>
                    <div class="role-card-description">
                        <ul class="role-card-list">
                            <li *ngFor="let roleDesc of item.newDescription">
                                {{roleDesc}}
                            </li>
                        </ul>
                    </div>
                    <div class="role-typical-user">
                        <span class="user-title"> Typical User</span>
                        <span class="user"> {{item.typicalUser}}</span>
                    </div>
                </label>

            </div>

            <p *ngIf="!canDisplay" class="label-message">No Account selected.</p>
        </div>
    </div>
</div>
<ng-template #noCustomers>
    <div class="modal-body text-center m-4">
        <p>
            <strong>{{userDetail?.name || userDetail?.email }}</strong> is already associated with all accounts.
        </p>
    </div>
</ng-template>
<div class="modal-footer mt-0 mrg-t-40 ps-0">
    <so-button [className]="'btn btn-secondary'"(onClick)="closeModal()" [text]="'Cancel'"></so-button>
    <so-button [disabled]="btnService.getStatus() || !customerList || customerList.length === 0"
        [style]="{'cursor':(!customerList || customerList.length === 0 || btnService.getStatus()) ? 'not-allowed':'pointer'}"
        [className]="'btn btn-primary m-0'" (click)="addUserToCustomer()" [text]="'Add Accounts'">
    </so-button>

</div>