/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { chartTypeContsants } from '@SiteOwl/core';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { BaseChartComponent, ColorHelper, DataItem, LegendOptions, LegendPosition, ScaleType, ViewDimensions, calculateViewDimensions } from '@swimlane/ngx-charts';

@Component({
  selector: 'so-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
})
export class PieChartComponent extends BaseChartComponent {
  @Input() override width!: any;
  @Input() override height!: any;
  @Input() gradient!: boolean;
  @Input() showLegend!: boolean;
  @Input() showLabels!: boolean;
  @Input() isDoughnut!: boolean;
  @Input() colorScheme!: any;
  @Input() pieChartData: any = [];
  @Input() legendTitle = '';
  @Input() legendPosition: LegendPosition = LegendPosition.Below;
  @Input() explodeSlices = true;
  @Input() arcWidth = 0.70;
  @Input() activeEntries: any[] = [];
  @Input() tooltipDisabled = false;
  @Input() labelFormatting: any;
  @Input() trimLabels = true;
  @Input() maxLabelLength = 10;
  @Input() tooltipText: any;
  @Input() totalDeviceWarrantyCnt = 0;
  @Input() totalLaborWarrantyCnt = 0;
  @Input() totalAging = 0;
  @Input() margins!: number[];
  @Output()  dblclick = new EventEmitter();
  @Output() override select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();

  @ContentChild('tooltipTemplate') tooltipTemplate!: TemplateRef<any>;

  translation!: string;
  outerRadius!: number;
  innerRadius!: number;
  data!: DataItem[];
  colors!: ColorHelper;
  domain!: string[];
  dims!: ViewDimensions;
  legendOptions!: LegendOptions;

  override update(): void {
    super.update();

    if (this.showLabels && this.hasNoOptionalMarginsSet()) {
      this.margins = [30, 80, 30, 80];
    } else if (!this.showLabels && this.hasNoOptionalMarginsSet()) {
      // default value for margins
      this.margins = [20, 20, 20, 20];
    }

    this.dims = calculateViewDimensions({
      width: this.width,
      height: this.height,
      margins: this.margins,
      showLegend: this.showLegend,
      legendPosition: this.legendPosition
    });

    this.formatDates();

    const xOffset = this.margins[3] + this.dims.width / 2;
    const yOffset = this.margins[0] + this.dims.height / 2;
    this.translation = `translate(${xOffset}, ${yOffset})`;
    this.outerRadius = Math.min(this.dims.width, this.dims.height);
    if (this.showLabels) {
      // make room for showLabels
      this.outerRadius /= 3;
    } else {
      this.outerRadius /= 2;
    }
    this.innerRadius = 0;
    if (this.isDoughnut) {
      this.innerRadius = this.outerRadius * (1 - this.arcWidth);
    }

    this.domain = this.getDomain();

    // sort data according to domain
    this.data = this.pieChartData.sort((a: any, b: any) => {
      return this.domain.indexOf(a.name) - this.domain.indexOf(b.name);
    });

    this.setColors();
    this.legendOptions = this.getLegendOptions();
  }

  getDomain(): string[] {
    return this.pieChartData.map((d: any) => d.name);
  }

  onClick(data: DataItem | string): void {
    this.select.emit(data);
  }

  setColors(): void {
    this.colors = new ColorHelper(this.colorScheme, ScaleType.Ordinal, this.domain, this.customColors);
  }

  getLegendOptions(): LegendOptions {
    return {
      scaleType: ScaleType.Ordinal,
      domain: this.domain,
      colors: this.colors,
      title: this.legendTitle,
      position: this.legendPosition
    };
  }

  private hasNoOptionalMarginsSet(): boolean {
    return !this.margins || this.margins.length <= 0;
  }

  labelFormat = (labelName: any) => {
    if (this.pieChartData !== undefined && this.pieChartData.filter((x:any) => x.name === labelName).length > 0) {
      return this.pieChartData.filter((x: any) => x.name === labelName)[0]['value']
    }
    return '';
  }


  getTotalPers(model: any, type: any) {
    if (type === 'warranty') {
      return ((model.value / this.totalDeviceWarrantyCnt) * 100).toFixed(0);
    } else if (type === 'labor') {
      return ((model.value / this.totalLaborWarrantyCnt) * 100).toFixed(0);
    }
    return '';
  }
  getAgingPer(model: any) {
    return ((model.value / this.totalAging) * 100).toFixed(0);
  }

  getAgingColor(element: any) {
    if (element.name === chartTypeContsants.ZER0_Year) {
      return chartTypeContsants.ZER0_Year_Color
    } else if (element.name === chartTypeContsants.ONE_Year) {
      return chartTypeContsants.ONE_Year_Color
    } else if (element.name === chartTypeContsants.TWO_Year) {
      return chartTypeContsants.TWO_Year_Color
    } else if (element.name === chartTypeContsants.THREE_Year) {
      return chartTypeContsants.THREE_Year_Color
    } else if (element.name === chartTypeContsants.FOUR_Year) {
      return chartTypeContsants.FOUR_Year_Color
    } else if (element.name === chartTypeContsants.FIVE_Year) {
      return chartTypeContsants.FIVE_Year_Color
    } else if (element.name === chartTypeContsants.SIX_Year) {
      return chartTypeContsants.SIX_Year_Color
    } else if (element.name === chartTypeContsants.NOINSTALLDATE) {
      return chartTypeContsants.NOINSTALLDATE_Color
    }
    return chartTypeContsants.NOINSTALLDATE_Color
  }
  chartClicked(event: any) {
    this.select.emit(event);
  }
}

