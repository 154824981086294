<form [formGroup]="planForm" class="edit-new-modal" (ngSubmit)="savePlan()" novalidate>
    <div class="modal-header">
        <h1> Plan Preview </h1>
        <button data-dismiss="modal" (click)="cancelPlan()" aria-label="Close" class="close pull-right" type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body" id="modal-body">

        <div class="col-sm-4 p-0">
            <div class="form-group">
                <label>Plan Name
                    <sup>*</sup>
                </label>
                <input maxlength="80" type="text" removeWhiteSpace class="form-control" formControlName="planName"
                    placeholder="Plan Name">
                <so-control-messages [name]="'planName'" [control]="$any(planForm).controls['planName']">
                </so-control-messages>
            </div>
        </div>
        <div class="text-center">
            <img alt="Plan" class="img-preview" [src]="planImage" />
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="cancelPlan()" class="btn btn-secondary">Cancel</button>
        <button type="submit" [disabled]="planForm.invalid"
            [ngStyle]="{ 'cursor': planForm.invalid ?'not-allowed': 'pointer'}"
            class="btn btn-primary me-0">Save</button>
    </div>
</form>