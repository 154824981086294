import { createAction } from '@reduxjs/toolkit';
import { authStoreName } from '../../constants';
import { errorsReducerForKey, loadersReducerForKey, resetStateByKey, typeReducerForKey } from '../utils';
const groupsState = {
  loaders: {},
  errors: {},
  groups: [],
  groupsConfiguration: {}
};
const reducers = {
  setUsersGroupsLoader: loadersReducerForKey('groupsState'),
  setUsersGroupsError: errorsReducerForKey('groupsState'),
  setGroupsState: typeReducerForKey('groupsState'),
  resetUsersGroupsState: resetStateByKey('groupsState', {
    groupsState
  })
};
const actions = {
  loadGroups: createAction(`${authStoreName}/loadUsersGroups`),
  getGroupById: createAction(`${authStoreName}/getGroupById`, payload => ({
    payload
  })),
  createGroup: createAction(`${authStoreName}/createGroup`, payload => ({
    payload
  })),
  updateGroup: createAction(`${authStoreName}/updateGroup`, payload => ({
    payload
  })),
  deleteGroup: createAction(`${authStoreName}/deleteGroup`, payload => ({
    payload
  })),
  addRolesToGroup: createAction(`${authStoreName}/addRolesToGroup`, payload => ({
    payload
  })),
  deleteRolesFromGroup: createAction(`${authStoreName}/deleteRolesFromGroup`, payload => ({
    payload
  })),
  addUsersToGroup: createAction(`${authStoreName}/addUsersToGroup`, payload => ({
    payload
  })),
  deleteUsersFromGroup: createAction(`${authStoreName}/deleteUsersFromGroup`, payload => ({
    payload
  })),
  getGroupConfiguration: createAction(`${authStoreName}/getGroupConfiguration`),
  updateGroupConfiguration: createAction(`${authStoreName}/updateGroupConfiguration`, payload => ({
    payload
  })),
  getTeamUsers: createAction(`${authStoreName}/getUsers`, payload => ({
    payload
  }))
};
// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { groupsState, reducers as groupsReducers, actions as groupsActions };