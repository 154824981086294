/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ProjectService, MessageService, CustomerService, Utilities, AuthService, AccessLevelsWithRoleDetails } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-edit-access-level',
  templateUrl: './edit-access-level.component.html',
  styleUrls: ['./edit-access-level.component.scss'],
})
export class EditAccessLevelComponent implements OnInit {
  customerDetails: any;
  projectDetails: any;
  accessLevelsWithRoleDetails: any = AccessLevelsWithRoleDetails;
  selectedRole: any = AccessLevelsWithRoleDetails[2];

  roles = {
    amt: [],
    project: []
  }

  providerId: any;
  user: any;
  userDetails: any;
  projectAcessRole: any;
  newRoleSelection: any;
  public event: EventEmitter<any> = new EventEmitter();

  constructor(private projectService: ProjectService, private messageService: MessageService, private authService: AuthService,
    private sanitizer: DomSanitizer, private customerService: CustomerService,
    public bsModalRef: BsModalRef
  ) {
    this.accessLevelsWithRoleDetails.forEach((x: any) => {
      x.isDefaultSelected = false;
    })
  }

  ngOnInit() {
    this.user = this.authService.getUserData();
    this.providerId = this.user.providerId;
    this.projectService.getProjectAccessRoles().subscribe({
      next: (res: any) => {
        this.projectAcessRole = res;
        this.projectAcessRole = Utilities.seperateRoleDescription(this.projectAcessRole)
        let temp  = this.accessLevelsWithRoleDetails.find((x: any) => x.key === this.projectDetails.permissionName)?.isDefaultSelected;
        temp = true;
        this.selectedRole = this.accessLevelsWithRoleDetails.find((x: any) => x.key === this.projectDetails.permissionName)
        this.newRoleSelection = this.projectAcessRole.find((x: any) => x.name === this.selectedRole.key).roleKey;
      } 
    })
  }

  onItemChange(role: any) {
    this.selectedRole = role;
    this.newRoleSelection = role.roleKey;
  }
  updateUser() {
    const resourceObj = {
      permissionLevelDTO: {
        emails: [this.userDetails.email],
        permissionLevel: this.selectedRole.name,
      },
      projectIds: [this.projectDetails.projectId],
      customerId: this.projectDetails.customer_id,
    }

    this.customerService.addProjectToCustomer(resourceObj).subscribe({
      next: () => {
        this.event.emit(this.selectedRole)
        this.bsModalRef.hide();
        this.messageService.successMessage(`User: ${this.userDetails.name || this.userDetails.email}`, 'Saved Successfully')
      }  
    })
  }

  closeModal() {
    this.bsModalRef.hide();
  }
  getValue(title: any) {
    return this.sanitizer.bypassSecurityTrustHtml(title);
  }

}

