import { createAction } from '@reduxjs/toolkit';
import { MFAStep } from './interfaces';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const mfaState = {
  step: MFAStep.verify,
  loading: false,
  saving: false
};
const reducers = {
  setMfaState: typeReducerForKey('mfaState'),
  resetMfaState: resetStateByKey('mfaState', {
    mfaState
  })
};
const actions = {
  enrollMfa: createAction(`${authStoreName}/enrollMfa`),
  getMFADevices: createAction(`${authStoreName}/getMFADevices`),
  getMFAStrategies: createAction(`${authStoreName}/getMFAStrategies`),
  verifyMfa: createAction(`${authStoreName}/verifyMfa`, payload => ({
    payload
  })),
  verifyMfaAfterForce: createAction(`${authStoreName}/verifyMfaAfterForce`, payload => ({
    payload
  })),
  disableMfa: createAction(`${authStoreName}/disableMfa`, payload => ({
    payload
  })),
  preDisableMfaSms: createAction(`${authStoreName}/preDisableMfaSms`, payload => ({
    payload
  })),
  disableMfaSms: createAction(`${authStoreName}/disableMfaSms`, payload => ({
    payload
  })),
  preEnrollMfaSms: createAction(`${authStoreName}/preEnrollMfaSms`, payload => ({
    payload
  })),
  enrollMfaSms: createAction(`${authStoreName}/enrollMfaSms`, payload => ({
    payload
  })),
  preEnrollMfaWebAuthn: createAction(`${authStoreName}/preEnrollMfaWebAuthn`, payload => ({
    payload
  })),
  enrollMfaWebAuthn: createAction(`${authStoreName}/enrollMfaWebAuthn`, payload => ({
    payload
  })),
  preDisableMfaWebAuthn: createAction(`${authStoreName}/preDisableMfaWebAuthn`, payload => ({
    payload
  })),
  disableMfaWebAuthn: createAction(`${authStoreName}/disableMfaWebAuthn`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { mfaState, reducers as mfaReducers, actions as mfaActions };