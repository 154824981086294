"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createConditionEvaluator = void 0;
const operation_resolver_1 = require("../operations/components/operation.resolver");
const sanitizers_resolver_1 = require("../operations/components/sanitizers.resolver");
function failingConditionEvaluator() {
  return () => false;
}
function createConditionEvaluator(payload) {
  const sanitizer = (0, sanitizers_resolver_1.useSanitizer)(payload.condition.op);
  if (!sanitizer) {
    return failingConditionEvaluator();
  }
  const {
    isSanitized,
    sanitizedValue
  } = sanitizer(payload.condition.value);
  if (!isSanitized || sanitizedValue === undefined) {
    return failingConditionEvaluator();
  }
  const operation = (0, operation_resolver_1.useOperation)(payload.condition.op, sanitizedValue);
  return attributes => {
    const attributeKey = payload.condition.attribute;
    const value = attributes[attributeKey];
    if (value === undefined || !operation) {
      return false;
    }
    const {
      isValid: result
    } = operation(value);
    return payload.condition.negate ? !result : result;
  };
}
exports.createConditionEvaluator = createConditionEvaluator;
