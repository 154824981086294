<div class="modal-header">
    <h1 class="modal-title pull-left">Change Password</h1>
    <button  aria-label="Close" class="close pull-right" type="button" (click)="closeModal()">
        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="changePasswordForm" novalidate >
        <div class="form-group">
            <label for="currentPassword">Current Password<sup>*</sup></label>
            <so-textbox  [maxLength]="80" [isPassword]="!isTextFieldType" [id]="'currentPassword'" [inputControlName]="$any(changePasswordForm).controls['currentPassword']"
                 [placeHolder]="'Current Password'">
            </so-textbox>
            <so-control-messages [name]="'Current Password'" [isFormSubmitted]="isFormSubmitted"
                [control]="$any(changePasswordForm).controls.currentPassword"></so-control-messages>
            <span (click)="togglePasswordFieldType()" class="toggle-password">
                <span title="Show Password" *ngIf="!isTextFieldType"> <i class="far fa-eye"></i></span>
                <span title="Hide Password" *ngIf="isTextFieldType"><i class="far fa-eye-slash"></i></span>
            </span>
        </div>
        <div class="form-group">
            <label for="newPassword">New Password<sup>*</sup></label>
            <so-textbox [maxLength]="80" [isPassword]="!isTextFieldTypeNew" [id]="'newPassword'" [inputControlName]="$any(changePasswordForm).controls['newPassword']"
                [placeHolder]="'New Password'">
            </so-textbox>
            <so-control-messages [name]="'New Password'" [isFormSubmitted]="isFormSubmitted"
                [control]="$any(changePasswordForm).controls.newPassword"></so-control-messages>
            <span (click)="togglePasswordFieldTypeNew()" class="toggle-password">
                <span title="Show Password" *ngIf="!isTextFieldTypeNew"> <i class="far fa-eye"></i></span>
                <span title="Hide Password" *ngIf="isTextFieldTypeNew"><i class="far fa-eye-slash"></i></span>
            </span>
        </div>
        <div class="form-group">
            <label for="confirmNewPassword">Confirm New Password<sup>*</sup></label>
            <so-textbox [maxLength]="80" [isPassword]="!isTextFieldTypeConfirmed" [id]="'confirmNewPassword'" [inputControlName]="$any(changePasswordForm).controls['confirmNewPassword']"
                [placeHolder]="'Confirm New Password'">
            </so-textbox>
            <so-control-messages [name]="'Confirm New Password'" [isFormSubmitted]="isFormSubmitted"
                [control]="$any(changePasswordForm).controls.confirmNewPassword"></so-control-messages>
            <span (click)="togglePasswordFieldTypeConfirmed()" class="toggle-password">
                <span title="Show Password" *ngIf="!isTextFieldTypeConfirmed"> <i class="far fa-eye"></i></span>
                <span title="Hide Password" *ngIf="isTextFieldTypeConfirmed"><i class="far fa-eye-slash"></i></span>
            </span>
        </div>
        <div class="text-end">
            <so-button  [className]="'btn btn-secondary'" (onClick)="closeModal()" [text]="'Cancel'"></so-button>
            <so-button [disabled]="btnService.getStatus()" [style]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}" 
                [className]="'btn btn-primary m-0'" [text]="'Update Password'" (onClick)="changePassword()">
            </so-button>
        </div>
    </form>
</div>