"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.modifyObjectKeysWithPrefix = exports.defaultFronteggAttributesMapper = exports.prepareAttributes = void 0;
const flatten_utils_1 = require("./flatten.utils");
/**
 *  Merges both `custom` and `jwt` records, map Frontegg attributes and modifies record keys with corrisponding prefixes
 *
 *  Example:
 *  Input: { 'custom': { 'customAttribute': 'someValue' }, 'jwt': { 'email': 'user@email.com', other: 'some-vaule' } }
 *  Output: { 'customAttribute': 'someValue', 'frontegg.email': 'user@email.com', 'jwt.email': 'user@email.com', 'jwt.other': 'some-vaule' }
 */
function prepareAttributes(attributes = {}, customFronteggAttributesMapper) {
  const {
    custom = {},
    jwt = {}
  } = attributes;
  const flatJwtAttributes = (0, flatten_utils_1.flatten)(jwt);
  const fronteggAttributes = customFronteggAttributesMapper ? customFronteggAttributesMapper(jwt) : defaultFronteggAttributesMapper(jwt);
  const fronteggAttributesPrefix = 'frontegg.';
  const jwtAttributesPrefix = 'jwt.';
  return {
    ...custom,
    ...modifyObjectKeysWithPrefix(fronteggAttributes, fronteggAttributesPrefix),
    ...modifyObjectKeysWithPrefix(flatJwtAttributes, jwtAttributesPrefix)
  };
}
exports.prepareAttributes = prepareAttributes;
function defaultFronteggAttributesMapper(jwt) {
  return {
    email: jwt.email,
    emailVerified: jwt.email_verified,
    tenantId: jwt.tenantId,
    userId: jwt.id
  };
}
exports.defaultFronteggAttributesMapper = defaultFronteggAttributesMapper;
function modifyObjectKeysWithPrefix(object, prefix) {
  return Object.keys(object).reduce((modifiedObject, currentKey) => {
    modifiedObject[`${prefix}${currentKey}`] = object[currentKey];
    return modifiedObject;
  }, {});
}
exports.modifyObjectKeysWithPrefix = modifyObjectKeysWithPrefix;
