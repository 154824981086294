import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["dataFilters"],
  _excluded2 = ["templateId", "dataFilters"],
  _excluded3 = ["dataFilters"];
import { Get, Post } from '../fetch';
import { urls } from '../constants';
export async function getReports(body) {
  return Get(`${urls.reports.service.v2}/tenant-reports`, body);
}
export async function getReport(body) {
  return Get(`${urls.reports.service.v2}/${body.id}/tenant-reports`);
}
export async function renderReport(_ref) {
  let {
      dataFilters
    } = _ref,
    body = _objectWithoutPropertiesLoose(_ref, _excluded);
  const html = await Get(`${urls.reports.trigger.v1}/preview-report`, _extends({}, body, {
    dataFilters: btoa(JSON.stringify(dataFilters || {})),
    responseType: 'html'
  }), {
    responseType: 'plain'
  });
  return {
    html
  };
}
export async function scheduleReport(_ref2) {
  let {
      templateId,
      dataFilters
    } = _ref2,
    body = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  return Post(`${urls.reports.service.v2}/${templateId}/tenant-reports`, _extends({}, body, {
    dataFilters: btoa(JSON.stringify(dataFilters || {}))
  }));
}
export async function downloadReport(_ref3) {
  let {
      dataFilters
    } = _ref3,
    body = _objectWithoutPropertiesLoose(_ref3, _excluded3);
  const blob = await Get(`${urls.reports.trigger.v1}/preview-report`, _extends({}, body, {
    dataFilters: btoa(JSON.stringify(dataFilters || {}))
  }), {
    responseType: 'blob'
  });
  let contentType = 'text/html';
  let contentExt = 'html';
  if (body.responseType === 'pdf') {
    contentType = 'application/pdf';
    contentExt = 'pdf';
  } else if (body.responseType === 'image') {
    contentType = 'image/jpeg';
    contentExt = 'jpg';
  }
  const newBlob = new Blob([blob], {
    type: contentType
  });
  const fileURL = URL.createObjectURL(newBlob);
  const tempLink = document.createElement('a');
  tempLink.href = fileURL;
  tempLink.setAttribute('download', `report_${body.name || ''}.${contentExt}`);
  tempLink.click();
  setTimeout(tempLink.remove.bind(tempLink));
}
export async function sendReport(body) {
  return Post(`${urls.reports.trigger.v1}/tenant-reports`, body);
}