/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitterType, EventService, FileItem, FileUploadConstant, FileUploadOptions, FileUploadService, MessageService, Utilities } from '@SiteOwl/core';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, ViewChild } from '@angular/core';
import * as pdfjsLib from 'pdfjs-dist';
// @ts-ignore: Unreachable code error
import * as pdfjsWorker from 'pdfjs-dist/build/pdf.worker';
import * as _ from 'lodash';
import { ReplaceFloorImageCountComponent } from '../replace-floor-image-count/replace-floor-image-count.component';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { SelectPdfPageComponent } from '../select-pdf-page/select-pdf-page.component';
@Component({
  selector: 'so-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnChanges {
  @Input() showFileName = true;
  @Input() fileUploadOptions!: FileUploadOptions;
  @Input() parent!: string;
  @Input() uploadedFiles: any;
  @Input() isFromBuilding = false;
  @Input() profileImage: any;
  @Input() errorMessage: any;
  @Input() acceptFiles: any = '.jpeg, .jpg, .png';
  @Input() textToDisplay: any;
  @Input() selectedFloor: any;
  @Input() isShowFileIcon = false;
  @Input() hideFileValidationMessage = false;
  @Output() uploadedFilesChange = new EventEmitter();
  @Output() errorMessageChange = new EventEmitter();
  @Output() invalidFileDragged = new EventEmitter();
  @Output() invalidFilesChange = new EventEmitter();


  @ViewChild('fileUpload', { static: true }) fileUpload!: ElementRef;
  showLoader = false
  pdfFileByteArray: any;
  uploadedPDFFiles: any = [];
  selectedPDFPage = 1;
  pdfPageArray: any;
  containsInvalidFile = false;
  floorWiseDetails: any = {
    siteActiveCnt: 0,
    siteArchivedCnt: 0,
    projectActiveCnt: 0,
    projectArchivedCnt: 0
  }
  bsModalRefReplacePlan: any;
  bsModalRef: any;
  constructor(
    private eventService: EventService,
    private fileUploadService: FileUploadService,
    private messageService: MessageService,
    private modalService: BsModalService, public bsModelAddPlanRef: BsModalRef,
  ) {
    pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    // The workerSrc property shall be specified.
    // pdfjsLib.GlobalWorkerOptions.workerSrc = '../../../../../../node_modules/pdfjs-dist/build/pdf.worker.entry';
  }
  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    if (this.isFromBuilding) {
      this.acceptFiles = '.jpeg,.jpg,.png,.pdf';
    }
    if (changes['hideFileValidationMessage'] && this.hideFileValidationMessage) {
      this.hideFileValidationMessage = false;
      this.containsInvalidFile = false;
      this.errorMessage = null;
    }
  }

  onchange(event: any) {
    let fileMessage: any
    const target = event.target || event.srcElement;
    const uploadedfiles = target.files;
    if (uploadedfiles.length > 0) {
      this.eventService.broadcast(EventEmitterType.isImageLoadingLocal, { isImageLoadingLocal: true })
      let files: any = []
      files = _.cloneDeep(uploadedfiles);
      const file = uploadedfiles[0];
      let checkExt = '';
      if (file && file.name && file.name !== null) {
        checkExt = file.name.split('.').pop();
      }
      if (this.isFromBuilding && checkExt !== null && checkExt === 'pdf') {
        fileMessage = this.fileUploadService.isValidBuildingPDFFileWithMessage(file);
        if (!fileMessage) {
          this.uploadedPDFFiles = this.fileUploadService.createFromObject(file);
          //normal image replace as pdf replace 
          this.checkNoOfPagesInPDFAndUploadToServer(file);
        } else {
          this.eventService.broadcast(EventEmitterType.isImageLoadingLocal, { isImageLoadingLocal: false })
          this.messageService.errorMessage("Invalid File Size : ", "Please upload an PDF less than 50 MB in size");
        }
      } else {
        //normal image replace 
        if (this.fileUploadOptions.isMultiple) {
          let loopVar = 0;
          for (const fileUpload of uploadedfiles) {
            new Promise<void>((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = (e: any) => {
                const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
                let header = "";
                for (const element of arr) {
                  header += element.toString(16);
                }
                const fileType = this.fileUploadService.checkFileType(header);
                if (!fileMessage) {
                  fileMessage = this.fileUploadService.isInvalidFile(fileUpload, this.fileUploadOptions, fileType);
                }
                if (loopVar === (uploadedfiles.length - 1)) {
                  this.changeAfterFileValidation(fileMessage, uploadedfiles, file, files, target);
                }
                loopVar++;
                resolve();
              }
              reader.readAsArrayBuffer(fileUpload);
            });
          }
        } else {
          new Promise<void>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
              const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
              let header = "";
              for (let i = 0; i < arr.length; i++) {
                header += arr[i].toString(16);
              }
              const fileType = this.fileUploadService.checkFileType(header);
              fileMessage = this.fileUploadService.isValidBuildingFileWithMessage(file, fileType);
              this.changeAfterFileValidation(fileMessage, uploadedfiles, file, files, target);
              resolve();
            }
            reader.readAsArrayBuffer(file);
          });
        }
      }
    }
    this.uploadedFiles = []
  }

  checkNoOfPagesInPDFAndUploadToServer(files: any) {

    const reader: any = new FileReader();
    if (files) {
      this.pdfFileByteArray = files;
      reader.readAsArrayBuffer(files);
      reader.onloadend = async () => {
        if (!Utilities.isEmpty(reader.result)) {
          //file is Ok
          const typedArray = new Uint8Array(reader.result as ArrayBuffer);
          try {
            const pdf = await pdfjsLib.getDocument(typedArray).promise;
            const countPdf = pdf.numPages;
            this.selectedPDFPage = 1;
            if (countPdf > 1) {
              this.pdfPageArray = []
              for (let i = 1; i <= countPdf; i++) {
                this.pdfPageArray.push({ id: i, value: "Page " + i });
              }
              if (this.pdfPageArray.length === countPdf) {
                const initialState: ModalOptions = {
                  initialState: {
                    pdfPageArray: this.pdfPageArray,
                    floorName: this.selectedFloor.name
                  }, ignoreBackdropClick: true, class: 'modal-md'
                };
                this.bsModelAddPlanRef.setClass('newBuildingM d-none')
                this.bsModalRef = this.modalService.show(SelectPdfPageComponent, initialState);
                this.bsModalRef.content.selectedPage.subscribe((r: any) => {
                  this.bsModalRef.setClass('modal-md d-none');
                  this.bsModalRef.hide();
                  this.bsModelAddPlanRef.setClass('newBuildingM')
                  if (r.action !== 'Cancel') {
                    this.checkPdf(this.pdfFileByteArray, r.pageSelect);
                  }
                })
              }
            } else {
              this.uploadPdfToServer();
            }
          } catch (e: any) {
            this.messageService.errorMessage("Error", "File is damaged. Please upload another file.");
          }
        } else {
          //file is corrupted
          this.messageService.errorMessage("Error", "File is damaged. Please upload another file.");
        }
      }
    }

  }

  uploadPdfToServer(event?: any) {
    this.showLoader = true;
    this.checkPdf(this.pdfFileByteArray, this.selectedPDFPage);
  }
  checkPdf(files: any, pageNo: any) {
    const reader: any = new FileReader();
    if (files) {
      reader.readAsDataURL(files);
      reader.onloadend = () => {
        if (!Utilities.isEmpty(reader.result)) {
          const loadingTask = pdfjsLib.getDocument(reader.result);
          loadingTask.promise.then((pdf) => {
            // Fetch the first page
            const pageNumber = pageNo;
            pdf.getPage(pageNumber).then((page) => {

              const desiredWidth = 7000;
              const desiredHeight = 7000;
              const viewNewport: any = page.getViewport({ scale: 1, });
              let scale: any = 1.33;
              let viewport: any = null;
              if (viewNewport.width > viewNewport.height) {
                scale = (desiredWidth / viewNewport.width);
              } else if (viewNewport.width < viewNewport.height) {
                scale = (desiredHeight / viewNewport.height);
              } else if (viewNewport.width === viewNewport.height) {
                scale = (desiredWidth / viewNewport.width);
              }
              viewport = page.getViewport({ scale: scale });

              // Prepare canvas using PDF page dimensions
              const canvas: any = document.createElement('canvas');
              const context: any = canvas.getContext('2d');

              canvas.height = Math.round(viewport.height);
              canvas.width = Math.round(viewport.width);
              canvas.style.display = 'none';

              const body = document.getElementsByTagName("body")[0];
              body.appendChild(canvas);
              // Render PDF page into canvas context
              const renderContext = {
                canvasContext: context,
                viewport: viewport
              };
              const renderTask = page.render(renderContext);
              renderTask.promise.then(async (r: any) => {
                const dataURL = canvas.toDataURL();
                let imgData: any = '';
                imgData = this.dataURLtoFile(dataURL)
                this.uploadedFiles = this.fileUploadService.createFromObject(imgData);
                this.uploadedFilesChange.emit(this.uploadedFiles);
                this.showLoader = false;
              });
            });
          });
        }
      }
    }
  }

  dataURLtoFile(dataurl: any) {
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr: any = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], Utilities.getCurrentDateTime() + '.jpg', { type: mime });
  }

  changeAfterFileValidation(fileMessage: any, uploadedfiles: any, file: any, files: any, target: any) {
    if (!fileMessage) {
      this.containsInvalidFile = false;
      if (this.fileUploadOptions.isMultiple) {
        if (!this.uploadedFiles) {
          this.uploadedFiles = []
        }
        let loopVar = 0;
        const totalFiles = uploadedfiles.length;
        for (const fileUpload of uploadedfiles) {
          new Promise<void>((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = (e: any) => {
              const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
              let header = "";
              for (let j = 0; j < arr.length; j++) {
                header += arr[j].toString(16);
              }
              const fileType = this.fileUploadService.checkFileType(header);
              if (FileUploadConstant.ALLOWEDIMAGEFILES.indexOf(fileType) > -1) {
                (this.uploadedFiles).push(this.convertToFileItem(fileUpload));
              }
              if (loopVar === (totalFiles - 1)) {
                this.emitFiles();
              }
              loopVar++;
              resolve();
            }
            reader.readAsArrayBuffer(fileUpload);
          });
        }
      } else {
        this.uploadedFiles = this.fileUploadService.createFromObject(file);
        this.emitFiles();
      }
      target.value = '';
    } else if (fileMessage && this.fileUploadOptions.isMultiple) {
      if (!this.uploadedFiles) {
        this.uploadedFiles = []
      }
      let loopVar = 0;
      const totalFiles = uploadedfiles.length;
      for (const fileUpload of uploadedfiles) {
        new Promise<void>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = (e: any) => {
            const arr = (new Uint8Array(e.target.result)).subarray(0, 4);
            let header = "";
            for (const element of arr) {
              header += element.toString(16);
            }
            const fileType = this.fileUploadService.checkFileType(header);
            const inValidFiles = [];
            if (FileUploadConstant.ALLOWEDIMAGEFILES.indexOf(fileType) > -1) {
              const fileObject = this.convertToFileItem(fileUpload);
              (this.uploadedFiles).push(fileObject);
            } else {
              const fileObject = this.convertToFileItem(fileUpload);
              (inValidFiles).push(fileObject);
            }
            if (loopVar === (totalFiles - 1)) {
              this.emitFilesOrError(inValidFiles);
            }
            loopVar++;
            resolve();
          }
          reader.readAsArrayBuffer(fileUpload);
        });
      }
      target.value = '';

    }
    else {
      this.uploadedFiles = null;
      this.containsInvalidFile = false;
      if (this.profileImage) {
        this.errorMessage = `The selected file(s) could not be uploaded. Please select images of type JPEG, JPG, or PNG that are less than 5 MB in size.`;
      } else {
        if (this.fileUploadOptions.maxSize) {
          this.errorMessage = `The selected file(s) could not be uploaded. Please select images of type JPEG, JPG, or PNG up to 10 MB in size or PDF up to 50 MB in size.`;
        } else {
          this.errorMessage = `The selected file(s) could not be uploaded. Please select images of type JPEG, JPG, or PNG.`;
        }
      }
      target.value = '';
      this.errorMessageChange.emit(this.errorMessage);
      this.hideFileValidationMessage = false;
      this.invalidFileDragged.emit();

    }
  }
  emitFiles() {
    this.invalidFilesChange.emit({ files: [], allInvalid: false })
    this.uploadedFilesChange.emit(this.uploadedFiles);
    this.eventService.broadcast('imageUploadClick', true)
    this.errorMessage = null;
    this.errorMessageChange.emit(this.errorMessage);
    this.hideFileValidationMessage = false;
    // this.invalidFileDragged.emit();
  }
  emitFilesOrError(invalidFiles: any) {
    if (this.uploadedFiles.length > 0) {
      this.uploadedFilesChange.emit(this.uploadedFiles);
    }
    if (this.fileUploadOptions.maxSize) {
      this.errorMessage = `Some files could not be uploaded. Please select images of type JPEG, JPG, or PNG that are less than 10 MB in size.`;
    } else {
      this.errorMessage = `Some files could not be uploaded. Please select images of type JPEG, JPG, or PNG.`;
    }
    this.invalidFilesChange.emit({ files: invalidFiles, allInvalid: this.uploadedFiles.length === 0 })
    this.errorMessageChange.emit(this.errorMessage);
    this.hideFileValidationMessage = false;
    this.containsInvalidFile = true;

    this.invalidFileDragged.emit();
  }
  convertToFileItem(fileUpload: any) {
    const fileObject: FileItem = new FileItem();
    fileObject.fileData = fileUpload;
    fileObject.size = fileUpload.size;
    fileObject.type = fileUpload.type;
    fileObject.name = fileUpload.name;
    fileObject.tempId = Math.random();
    fileObject.extension = '.' + fileUpload.name.split('.')[fileUpload.name.split('.').length - 1].toLowerCase()
    return fileObject;
  }

  openFileDialog() {
    const index = this.fileUploadOptions.acceptExtensions.indexOf('.pdf');
    if (index > -1) {
      this.fileUploadOptions.acceptExtensions.splice(index, 1);
    }
    this.fileUpload.nativeElement.value = '';
    this.fileUpload.nativeElement.click();
  }

  openFileDialogPDF(type?: string) {
    this.fileUploadOptions.acceptExtensions.push('.pdf');
    this.fileUploadOptions.acceptExtensions = _.uniq(this.fileUploadOptions.acceptExtensions)
    if (!Utilities.isEmpty(type) && type !== undefined && type === 'replaceImage' && this.selectedFloor && ((this.selectedFloor.associatedProject.length > 0 && this.selectedFloor.associatedProject[0].isArchived) || this.selectedFloor.amtActiveDeviceCount > 0 || this.selectedFloor.amtArchivedDeviceCount > 0 || this.selectedFloor.projectActiveDeviceCount > 0 || this.selectedFloor.projectArchivedDeviceCount > 0 || this.selectedFloor.ticketActiveCount > 0 || this.selectedFloor.ticketArchivedCount > 0)) {
      this.checkSpecificFloorDetails();
    } else {
      this.fileUpload.nativeElement.value = '';
      this.fileUpload.nativeElement.click();
    }
  }

  checkSpecificFloorDetails() {
    this.floorWiseDetails.siteArchivedCnt = this.selectedFloor.amtArchivedDeviceCount;
    this.floorWiseDetails.siteActiveCnt = this.selectedFloor.amtActiveDeviceCount;
    this.floorWiseDetails.projectArchivedCnt = this.selectedFloor.projectArchivedDeviceCount;
    this.floorWiseDetails.projectActiveCnt = this.selectedFloor.projectActiveDeviceCount;
    const initialState: ModalOptions = {
      initialState: {
        floorWiseDetails: this.floorWiseDetails,
        floorName: this.selectedFloor.name
      }, ignoreBackdropClick: true, class: 'modal-md'
    };
    this.bsModalRefReplacePlan = this.modalService.show(ReplaceFloorImageCountComponent, initialState);
    this.bsModalRefReplacePlan.content.eventImgSelect.subscribe((r: any) => {
      this.openFileDialog();
      this.bsModalRefReplacePlan.hide();
    });
    // this.replaceCountModal.show();
  }
}
