<div [id]="dialogId" [ngClass]="dialogClass" [ngStyle]="dialogStyle">
    <div class="modal-header">
        <h1 class="modal-title pull-left">{{header}}</h1>
    </div>
    <div class="modal-body">
        <span *ngIf="!details.isProject">You have unsaved changes for <strong>{{details.floorName}}</strong> in
            <strong>{{details.buildingName}} - {{details.name}}</strong> in your Live Sites. Please review your unsaved
            changes before continuing.</span>
        <span *ngIf="details.isProject">You have unsaved changes for <strong>{{details.floorName}}</strong> in
            <strong>{{details.buildingName}}</strong> for <strong>{{details.name}}</strong>. Please review your unsaved
            changes before continuing.</span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn me-0" class="btn btn-primary" [ngStyle]="btnSuccessStyle"
            (click)="_action($event)">{{btnSuccessText}}</button>
    </div>
</div>