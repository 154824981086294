import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Pagination, Utilities } from '@SiteOwl/core';
import { TooltipModel } from '../tool-tip/tool-tip.model';

@Component({
  selector: 'so-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  @Input() records!: any;
  @Input() headerData: any;
  @Input() noRecordsMessage: any;
  @Input() className: any
  @Input() id: any
  @Input() style: any;
  @Input() showPagination = false;
  @Input() searchParams: any = {}
  @Input() showResult: any = {}
  @Input() toolTipObj: any;
  @Input() toolRoleTipObj: any;

  @Output() tableAction = new EventEmitter();

  constructor(private fb: FormBuilder) { }

  pageSizes = Pagination.PAGESIZES;
  listForm!: FormGroup;
  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.listForm = this.fb.group({
      activeCheckBox: [false, []],
      roleInfoCheckBox:[false, []],
    });
  }

  _actionClick(event: Event, actionName: string, item: any, type?: string) {
    this.tableAction.emit({ actionName: actionName, item: item, event: event , type: type})
  }

  pageChanged(event: any) {
    if (this.searchParams.page === event.page) {
      return;
    } else {
      this.searchParams.page = event.page;
      this.tableAction.emit({ actionName: 'pageChanged', item: this.searchParams.page, event: event })
      window.scrollTo(0, 0);
    }
  }

}
