<div class="modal-header">
    <h1 class="modal-title pull-left"> Edit Headquarters License Type</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>
<form [formGroup]="accountForm" (ngSubmit)="updateLicense()" novalidate>
    <div class="modal-body">
        <div class="form-group">
            <p>Select the License type for the Headquarters <strong>{{providerName}}</strong>.</p>
            <label class="mb-1">
                License Type
            </label>
            <div>
                <div *ngFor="let item of accountType; let i = index;" class="action-col">
                    <span class="radio-label">
                        <so-radio [className]="'lbl-check'" [id]="item.accountType" [name]="'accountType'"
                            (change)="accountChanged(item,'provideraccount')"
                            [isChecked]="(item.accountTypeKey === accountSelection)" [label]="item.accountType">
                        </so-radio>
                    </span>
                    <!-- <span class="radio-label">
                        <input type="radio" id="{{item.accountType}}" name="accountType"
                            (change)="accountChanged(item,'provideraccount')"
                            [checked]="item.accountTypeKey === accountSelection">
                        <label for="{{item.accountType}}">{{item.accountType}}</label>
                    </span> -->
                    <span class="action-label">{{item.description}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="closeModal()" class="btn btn-secondary ">Cancel</button>
        <button type="submit" [ngStyle]="{'cursor': showSpinner ? 'not-allowed': 'pointer'}" [disabled]="showSpinner"
            class="btn btn-primary m-0">Update</button>
    </div>

</form>