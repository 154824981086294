/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Directive,
  ElementRef,
  Renderer2,
  HostListener,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

@Directive({
  selector: '[soHover]'
})
export class HoverDirective {
  subscription: any;
  @Input() defaultSite: any;
  @Input() defaultBuilding: any
  @Input() defaultfloor: any
  @Input() isDrag: any
  @Output() hoveredBuildingId = new EventEmitter();
  timer: any;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2) {
  }

  ngOnInit() {

    this.renderer.listen(this.elementRef.nativeElement, 'mouseenter', () => {
      // Do something with 'event'
    })
    this.renderer.listen(this.elementRef.nativeElement, 'mouseover', () => {
      // Do something with 'event'
      this.timer = setTimeout(() => {
        if (this.isDrag) {
          this.defaultSite.isVisible = true;
          if (this.defaultBuilding) {
            if (!this.defaultBuilding.isVisible) {
              this.defaultBuilding.isVisible = true;
            }
            this.hoveredBuildingId.emit(
              {
                buildingId: this.defaultBuilding.id,
                floorId: this.defaultfloor ? this.defaultfloor.id : null,
              }
            )
          }
        }
      }, 200);
    })
  }

  @HostListener('mouseleave') mouseleave() {
    clearTimeout(this.timer);
  }

}