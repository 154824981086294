/* eslint-disable no-prototype-builtins */
import { CommonService, EventService, EventEmitterType } from '@SiteOwl/core';
import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { Subject, Subscription, fromEvent, map, switchMap, takeUntil } from 'rxjs';

@Directive({
    selector: '[soImageScrollDraggable]',
    exportAs: "soImageScrollDraggable"
})
export class ImageScrollDraggableDirective implements OnDestroy, AfterViewInit, OnChanges {
    drag = true;
    timer = null;
    offsetX!: number;
    offsetY!: number;
    coordX!: number;
    coordY!: number;
    parent: any;
    oldZoom = 0;
    parentWidth = 0;
    parentHeight = 0;
    afterViewPercent = 0;
    elemWidth: any;
    elemHeight: any;
    zoomSub!: Subscription;
    equipSub!: Subscription;
    scrollZoom = false;
    scrollZoomX!: number;
    scrollZoomY!: number;

    imageWidth: any;
    imageHeight: any;
    _onmousedown = this.onmousedown.bind(this);
    _onmouseup = this.onmouseup.bind(this);
    _onmousemove = this.onmousemove.bind(this);
    _onmousewheel = this.onmousewheel.bind(this);
    tpCache = [];
    scaling = false;
    moving = false;
    preDiff = 0;
    @Output() imageLoaded = new EventEmitter();
    @Output() imageZoomChanged = new EventEmitter();
    @Output() imageDragged = new EventEmitter();


    @Input() imageScrollDraggable: any;
    @Input() action!: string;
    @Input() imageZoom!: any;
    @Input() fitToScreen: any;
    @Input() fromSetScale: any;
    private readonly destroy$ = new Subject<void>();
    dragging!: boolean;

    constructor(
        private cd: ChangeDetectorRef,
        private commonService: CommonService,
        private elementRef: ElementRef,
        private renderer: Renderer2) { }
    initDrag(): void {
        const dragStart$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown");
        const dragEnd$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseup");
        const mouseout$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mouseout")
        const drag$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousemove").pipe(
            takeUntil(dragEnd$)
        );
        mouseout$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.drag = false;
        })
        dragStart$.pipe(
            takeUntil(this.destroy$),
            switchMap(
                (start) => {
                    this._onmousedown(start)
                    return drag$.pipe(map((move: any) => {
                        this._onmousemove(move);
                        return move;
                    }),
                        takeUntil(dragEnd$));
                }

            )).subscribe()
        dragEnd$.pipe(takeUntil(this.destroy$))
            .subscribe((event: any) => {
                this._onmouseup(event);
            });
    }
    initZoom() {

        let imageMove$: any
        if (this.commonService.checkBrowserIsFireFox()) {
            imageMove$ = fromEvent<WheelEvent>(this.elementRef.nativeElement, "DOMMouseScroll", { passive: true }).pipe(takeUntil(this.destroy$));
        } else if (this.commonService.checkBrowser()) {
            imageMove$ = fromEvent<WheelEvent>(this.elementRef.nativeElement, "wheel", { passive: true }).pipe(takeUntil(this.destroy$));
        } else {
            imageMove$ = fromEvent<WheelEvent>(this.elementRef.nativeElement, "mousewheel", { passive: true }).pipe(takeUntil(this.destroy$));
        }
        imageMove$.subscribe((event: WheelEvent) => {
            this._onmousewheel(event);
        });
    }
    onmousedown(event: any) {
        event.preventDefault();
        const targ = this.elementRef.nativeElement;
        this.offsetX = event.clientX;
        this.offsetY = event.clientY;

        if (!targ.style.left) {
            targ.style.left = '0px'
        }
        if (!targ.style.top) {
            targ.style.top = '0px'
        }

        this.coordX = parseInt(targ.style.left);
        this.coordY = parseInt(targ.style.top);

        this.drag = true;
        this.elementRef.nativeElement.style.cursor = '-webkit-grabbing';
        this.elementRef.nativeElement.style.cursor = 'url(https://ssl.gstatic.com/ui/v1/icons/mail/images/2/closedhand.cur), move'
        this.dragging = false;
    }

    onmousewheel(event: any) {
        event.preventDefault();
        this.scrollZoom = true;
        this.scrollZoomX = event.clientX - 13;
        this.scrollZoomY = event.clientY - 78;

        const condition = this.commonService.checkBrowserIsFireFox() ? event.detail : event.deltaY;
        if (condition < 0) {
            this.imageZoomChanged.emit(true)
        } else if (condition > 0) {
            this.imageZoomChanged.emit(false)
        }
    }

    onmouseup(event: any) {
        if (this.drag) {
            event.stopPropagation();
            this.imageDragged.emit(false);
        }
        // event.preventDefault();
        if (this.fromSetScale) {
            this.elementRef.nativeElement.style.cursor = 'crosshair';
        } else {
            this.elementRef.nativeElement.style.cursor = '-webkit-grab';
            if (this.commonService.checkBrowser()) {
                this.elementRef.nativeElement.style.cursor = 'url(http://ssl.gstatic.com/ui/v1/icons/mail/images/2/openhand.cur), move'
            }
        }
        this.drag = false;
        this.dragging = false;
    }

    onmousemove(event: any): any {
        if (!this.drag) {
            event.preventDefault();
            return false;
        }
        const targ = this.elementRef.nativeElement;

        targ.style.left = this.coordX + event.clientX - this.offsetX + 'px';
        targ.style.top = this.coordY + event.clientY - this.offsetY + 'px';
        this.dragging = true;
    }
    onImageLoad(data: any) {
        if (this.parentHeight < 1 || this.parentWidth < 1) {
            const floorParent: any = document.querySelector('#moveTicketParentContainer');
            this.parentHeight = floorParent.clientHeight;
            this.parentWidth = floorParent.clientWidth;
        }
        const elemNewWidth = this.elemWidth * Math.round(data.zoom === 0 ? this.afterViewPercent : data.zoom) / 100;
        const elemNewHeight = elemNewWidth * this.elemHeight / this.elemWidth;

        if (elemNewWidth > 0 && elemNewHeight > 0) {
            if (data.header) {
                if (data.fit) {

                    this.elementRef.nativeElement.style.top = `0px`;
                    this.elementRef.nativeElement.style.left = `${(this.parentWidth / 2) - (elemNewWidth / 2)}px`
                    if (elemNewHeight < this.parentHeight) {
                        this.elementRef.nativeElement.style.top = `${(this.parentHeight / 2) - (elemNewHeight / 2)}px`;
                    }
                } else {
                    this.calcForScroll(elemNewWidth, elemNewHeight, data);
                }
            } else {
                this.calcForScroll(elemNewWidth, elemNewHeight, data);
            }
            this.oldZoom = data.zoom === 0 ? this.afterViewPercent : data.zoom;
            this.scrollZoom = false;
        }
    }

    private calcForScroll(elemNewWidth: any, elemNewHeight: any, data: any) {
        let modifyLeft, modifyTop;
        const x = Number(this.elementRef.nativeElement.style.left.replace('px', ''));
        const y = Number(this.elementRef.nativeElement.style.top.replace('px', ''));

        if (this.scrollZoom) {
            if (this.oldZoom === 0) {
                modifyLeft = 0;
                modifyTop = 0;
            } else {
                modifyLeft = this.scrollZoomX - ((this.scrollZoomX - x) * data.zoom) / this.oldZoom;
                modifyTop = this.scrollZoomY - ((this.scrollZoomY - y) * data.zoom) / this.oldZoom;
            }
        } else {
            modifyLeft = (this.parentWidth / 2) - (((this.parentWidth / 2) - x) * data.zoom) / this.oldZoom;
            modifyTop = (this.parentHeight / 2) - (((this.parentHeight / 2) - y) * data.zoom) / this.oldZoom;
        }
        if (elemNewWidth < (0 - modifyLeft)) {
            this.elementRef.nativeElement.style.left = `-${(elemNewWidth - this.parentWidth) / 2}px`;
        } else {
            this.elementRef.nativeElement.style.left = `${modifyLeft}px`;
        }
        if (elemNewHeight < (0 - modifyTop)) {
            this.elementRef.nativeElement.style.top = `-${(elemNewHeight - this.parentHeight) / 2}px`;
        } else {
            this.elementRef.nativeElement.style.top = `${modifyTop}px`;
        }
    }

    ngAfterViewInit() {
        const floorParent: any = document.querySelector('#moveTicketParentContainer');
        this.parent = document.querySelector('#moveTicketFloorImageShadow');
        this.parent.addEventListener('load', (e: any) => {
            e.preventDefault();
            setTimeout(() => {
                this.elemWidth = this.parent.naturalWidth;
                this.elemHeight = this.parent.naturalHeight;
                this.parentHeight = floorParent.clientHeight;
                this.parentWidth = floorParent.clientWidth;
                this.imageWidth = this.parent.clientWidth;
                this.imageHeight = this.parent.clientHeight;
                this.afterViewPercent = this.elementRef.nativeElement.clientWidth * 100 / this.elemWidth;
                this.elementRef.nativeElement.style.left = `${(this.parentWidth / 2) - (this.imageWidth / 2)}px`;
                this.elementRef.nativeElement.style.top = `${(this.parentHeight / 2) - (this.imageHeight / 2)}px`;
                this.imageLoaded.emit();
                this.onImageLoad({
                    zoom: this.imageZoom, header: true, fit: true
                })
                this.initDrag();
                this.initZoom();
            }, 1000);
        }, {
            passive: true
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.hasOwnProperty('imageZoom') || changes.hasOwnProperty('fitToScreen')) {
            this.onImageLoad({
                zoom: this.imageZoom, header: this.fitToScreen, fit: this.fitToScreen
            })
        }
    }
    ngOnDestroy() {
        if (this.zoomSub) {
            this.zoomSub.unsubscribe();
        }
        if (this.equipSub) {
            this.equipSub.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }
}

