<ng-container *ngIf="!pageLoader">
    <div class="modal-header">
        <h1 class="modal-title pull-left" *ngIf="!(sites.length === 0 && selectedCustomerId && siteAvailableForCustomer && !isAllManager && !noCuatomers) && !(!siteAvailableForCustomer && selectedCustomerId && !isAllManager && !noCuatomers)">
            Add User to Site(s)</h1>
        <h1 class="modal-title pull-left" *ngIf="(sites.length === 0 && selectedCustomerId && siteAvailableForCustomer && !isAllManager && !noCuatomers) || (!siteAvailableForCustomer && !isAllManager && selectedCustomerId && !noCuatomers)">
            All Sites Already Accessible</h1>

        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>

    <div class="modal-body project-filter" *ngIf="!isAllManager && !noCuatomers && canShow && ((selectedCustomerId && sites.length > 0) || !selectedCustomerId);else NoAddedCustomer">
        <p *ngIf="!selectedCustomerId">Select a Account and the Site(s) that you would like to grant to
            <strong>{{userDetails.name || userDetails.email}}.</strong>
        </p>
        <form [formGroup]="resourceForm" novalidate>
            <div class="form-group" *ngIf="!selectedCustomerId">
                <label>Associated Accounts
                    <sup>*</sup>
                </label>
                <div class="row">
                    <div class="col-sm-6">
                        <select (change)="customerSelected()" formControlName="customer" class="form-control mb-0">
                            <option [ngValue]="null">Select Account</option>
                            <option *ngFor="let customer of customers" [ngValue]="customer">{{customer?.customer?.name}}
                            </option>
                        </select>

                        <!-- <so-dropdown [id]="'select'" [options]="customers" [value]="'customerName'" [key]="'customer'"  [selectString]="'Select Account'"
                            [inputControlName]="$any(resourceForm).controls['customer']" (onChange)="customerSelected()">
                        </so-dropdown> -->
                        <so-control-messages [name]="'Account'" [isFormSubmitted]="isFormSubmitted" [control]="$any(resourceForm).controls.customer"></so-control-messages>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Site(s)
                    <sup>*</sup>
                </label>
                <div class="card projects-card">
                    <p class="label-message" *ngIf="resourceForm.get('customer')?.value === null">
                        Please select a Account to view available Sites. </p>
                    <p class="label-message" *ngIf="resourceForm.get('customer')?.value !== null && !siteAvailableForCustomer">
                        No Sites available. </p>
                    <div class="download-option-list" *ngIf="siteAvailableForCustomer && resourceForm.controls['customer'].valid && sites.length > 0 && !siteLoad ; else noSites">
                        <div class="checkbox-label ">
                            <so-checkbox [isChecked]="selectedAllSites" (onChange)="selectAllSites()"
                                [label]="'Select All (' + (selectedAllSitesCount || 0) + ' of ' + totalSites + (totalSites > 1 ?' Sites ':' Site ') + 'selected)'" 
                                [id]="'selectedAllSites'" class="lbl-check" 
                                [parentClassName]="(selectedAllSitesCount > 0 && !selectedAllSites) ? 'uncheckall':''">
                            </so-checkbox>
                        </div>
                    </div>
                    <div class="project-filter-body">
                        <div *ngFor="let site of sites" class="clearfix ps-4">
                            <div class="expand-inner clearfix">
                                <div class="project-exp">
                                    <so-checkbox [isChecked]="site.isSelectedSite" (onChange)="selectSites($event, site)"
                                        [label]="site.name" [id]="'site_'+ site.id" [ngClass]="'lbl-check'">
                                    </so-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <ng-template #noSites>
                        <p *ngIf="siteAvailableForCustomer && sites.length === 0 && !selectedCustomerId && resourceForm.get('customer')?.value !== null">
                            <strong> {{userDetails.name || userDetails.email}} </strong> already has access to all possible Sites on <strong>{{resourceForm.controls['customer'].value.customer.name}}
                            </strong>.
                        </p>
                    </ng-template>
                </div>
            </div>
        </form>
    </div>

    <ng-template #NoAddedCustomer>
        <p class="m-4" *ngIf="isAllManager">
            <strong>
                {{userDetails?.name === '' || userDetails?.name === null ? "(" + userDetails?.email +")" :
                userDetails?.name}}
            </strong> has the Site role Manager for all Accounts.
        </p>
        <p class="m-4" *ngIf="noCuatomers">
            No Accounts available. Only Accounts where <strong> {{user?.providerName}} </strong> has the Site role Manager and where <strong>
                {{userDetails?.name === '' || userDetails?.name === null ? "(" + userDetails?.email +")" :
                userDetails?.name}}</strong> has been associated will appear here.
        </p>
        <p class="m-4" *ngIf="sites.length === 0 && selectedCustomerId && siteAvailableForCustomer && !isAllManager && !noCuatomers">
            <strong> {{userDetails?.name || userDetails?.email}} </strong> already has access to all possible Sites on <strong>{{resourceForm.controls['customer'].value.customer.name}}
            </strong>.
        </p>
        <p class="m-4" *ngIf="!siteAvailableForCustomer && !isAllManager && !noCuatomers">
            <strong> {{userDetails?.name || userDetails?.email}} </strong> already has access to all possible Sites on <strong>{{customerSelectedName}}
            </strong>. </p>
    </ng-template>
    <div class="modal-footer">
        <so-button (onClick)="closeModal()" [className]="'btn btn-secondary'"
            [text]="((!isAllManager && !noCuatomers && canShow && ((selectedCustomerId && sites.length > 0) || !selectedCustomerId)) ? 'Cancel' : 'Close')">
        </so-button>
        <so-button *ngIf="!isAllManager && !noCuatomers && canShow && ((selectedCustomerId && sites.length > 0) || !selectedCustomerId)"  [disabled]="btnService.getStatus() || disableButton() || selectedAllSitesCount < 1"
            (onClick)="addUserToSites()" [className]="'btn btn-primary m-0'" [style]="{'cursor':btnService.getStatus() || disableButton() || selectedAllSitesCount < 1 ? 'not-allowed'  : 'pointer'}" [text]="'Add Site(s)'">
        </so-button>
    </div>
</ng-container>