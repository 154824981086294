/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-choose-plan-popup',
  templateUrl: './choose-plan-popup.component.html',
  styleUrls: ['./choose-plan-popup.component.scss'],
})
export class ChoosePlanPopupComponent {
  public eventImgSelect: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) { }
  browsePlanLocal() {
    this.eventImgSelect.emit({ data: 'localImage', type: '' })
  }
  browseBlankImage(type: any) {
    this.eventImgSelect.emit({ data: 'siteOwlImage', type: type })
  }
  closeAllModal(){
    this.eventImgSelect.emit({ data: 'closeAll', type: '' })
  }
}