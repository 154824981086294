import { Delete, extractHeadersFromOptions, Get, Patch, Post, Put } from "../fetch";
import { urls } from "../constants";
export async function loadAllUsers(params) {
  return Get(urls.identity.subTenants.v1, params);
}
export async function addUserToTenantAndSubTenants(body) {
  return Post(urls.identity.subTenants.v1, body);
}
export async function removeUserFromTenantAndSubTenants(body, options) {
  return Delete(urls.identity.subTenants.v1, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function addUserRolesForSubTenants(userId, body, options) {
  return Post(`${urls.identity.subTenants.v1}/${userId}/roles`, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function removeUserRolesFromSubTenants(userId, body, options) {
  return Delete(`${urls.identity.subTenants.v1}/${userId}/roles`, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function setUserRolesForSubTenants(userId, body, options) {
  return Patch(`${urls.identity.subTenants.v1}/${userId}/roles`, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function createSubTenant(body, options) {
  return Post(urls.tenants.subTenants.v1, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function deleteSubTenant(tenantId, options) {
  return Delete(`${urls.tenants.subTenants.v1}/${tenantId}`, undefined, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function updateSubTenant({
  tenantId
}, body, options) {
  return Patch(`${urls.tenants.subTenants.v1}/${tenantId}`, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function updateSubAccountAccess(userId, body, options) {
  return Put(`${urls.identity.subTenants.v1}/${userId}/access`, body, {
    headers: extractHeadersFromOptions(options)
  });
}
export async function addUsersToSubTenant(body, options) {
  return Post(`${urls.identity.subTenants.v2}/`, body, {
    headers: extractHeadersFromOptions(options)
  });
}