"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StringSanitizersMapper = exports.sanitizeListString = exports.sanitizeSingleString = void 0;
const types_1 = require("../types");
const isString = value => typeof value === 'string';
const sanitizeSingleString = value => {
  const sanitizedValue = value.string !== undefined && isString(value.string) ? {
    string: value.string
  } : undefined;
  return {
    isSanitized: !!sanitizedValue,
    sanitizedValue
  };
};
exports.sanitizeSingleString = sanitizeSingleString;
const sanitizeListString = value => {
  const sanitizedValue = value.list !== undefined && value.list.every(str => isString(str)) ? {
    list: value.list
  } : undefined;
  return {
    isSanitized: !!sanitizedValue,
    sanitizedValue
  };
};
exports.sanitizeListString = sanitizeListString;
exports.StringSanitizersMapper = {
  [types_1.OperationEnum.Matches]: exports.sanitizeSingleString,
  [types_1.OperationEnum.Contains]: exports.sanitizeListString,
  [types_1.OperationEnum.StartsWith]: exports.sanitizeListString,
  [types_1.OperationEnum.EndsWith]: exports.sanitizeListString,
  [types_1.OperationEnum.InList]: exports.sanitizeListString
};
