<ngx-charts-chart [view]="[width, (heightBase-30)]" [showLegend]="legend" [legendOptions]="legendOptions"
    [activeEntries]="activeEntries" [animations]="animations"
    (legendLabelActivate)="onActivate($event, undefined, true)"
    (legendLabelDeactivate)="onDeactivate($event, undefined, true)" (legendLabelClick)="onClick($event)">
    <svg:g [attr.transform]="transform" class="bar-chart chart">

        <svg:g ngx-charts-x-axis *ngIf="xAxis && showPercentage" [xScale]="xScale" [dims]="dims" [showGridLines]="true"
            [showLabel]="showXAxisLabel" [labelText]="xAxisLabel" [trimTicks]="trimXAxisTicks"
            [rotateTicks]="rotateXAxisTicks" [maxTickLength]="maxXAxisTickLength" [tickFormatting]="formatXAxis"
            [ticks]="xAxisTicks" (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>

        <svg:g ngx-charts-x-axis *ngIf="xAxis && !showPercentage" [xScale]="xScale" [dims]="dims" [showGridLines]="true"
            [showLabel]="showXAxisLabel" [labelText]="xAxisLabel" [trimTicks]="trimXAxisTicks"
            [rotateTicks]="rotateXAxisTicks" [maxTickLength]="maxXAxisTickLength"
            [tickFormatting]="formatWithoutPercentageXAxis" [ticks]="xAxisTicks"
            (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>

        <svg:g [attr.class]="'y axis'">
            <svg:g so-charts-y-axis-ticks *ngIf="yScale" [tickValues]="chartData" [scale]="yScale"
                [orient]="Orientation.Left" [showGridLines]="showGridLines" [gridLineWidth]="dims.width"
                [showRefLines]="false" [showRefLabels]="false" [height]="dims.height"
                [tickFormatting]="xAxisTickFormatting" (dimensionsChanged)="updateYAxisWidth($event)"
                [wrapTicks]="false" />

        </svg:g>

        <svg:g>
            <ng-container *ngFor="let group of chartData; let index = index; trackBy: trackBy">
                <svg:g [@animationState]="'active'" [attr.transform]="groupTransform(group)">
                    <svg:g ngx-charts-series-horizontal [type]="barChartType.Stacked" [xScale]="xScale"
                        [yScale]="yScale" [colors]="colors" [series]="group.series | removeZeroFromChart"
                        [activeEntries]="activeEntries" [dims]="dims" [gradient]="gradient"
                        [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate"
                        [seriesName]="group.name" [animations]="animations" [showDataLabel]="false"
                        [dataLabelFormatting]="dataLabelFormatting" [noBarWhenZero]="true"
                        (select)="onClick($event, group)" (activate)="onActivate($event, group,false)"
                        (deactivate)="onDeactivate($event, group)"
                        (dataLabelWidthChanged)="onDataLabelMaxWidthChanged($event, index)" />
                </svg:g>
            </ng-container>
        </svg:g>
    </svg:g>
</ngx-charts-chart>