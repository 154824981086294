import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'so-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() className!: any;
  @Input() disabled!: boolean;
  @Input() inputControlName!: FormControl;
  @Input() style!: any;
  @Input() options: any;
  @Input() key!: string;
  @Input() id!: string;
  @Input() value!: string;
  @Output() onChange = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Input() showAccount = false;
  _valueChanged($event: any) {
    this.onChange.emit($event)
  }

  _blur($event: Event) {
    this.blur.emit($event)
  }
}
