import { Customer, Role, ButtonEnableDisableService, CustomerService, MessageService, ValidationService, Pattern, accountType, AuthService, Provider } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'so-integrator-create-edit',
    templateUrl: './integrator-create-edit.component.html',
    styleUrls: ['./integrator-create-edit.component.scss'],
})
export class IntegratorCreateEditComponent implements OnInit {

    isEditMode = false;
    nullValue: any = null;
    providerDetail!: Provider;
    providerForm!: FormGroup;
    customers!: Customer[];
    providers!: Provider[];
    roles!: Role[];
    isFormSubmitted = false;
    currentUser: any;
    accountSelection: any = 'Trial';
    accountType = accountType;
    headquatersTypeOptions = [
        {
            "name": "Integrator",
            "id": 1
        },
        {
            "name": "Security Team",
            "id": 2
        }
    ]
    public event: EventEmitter<any> = new EventEmitter();

    constructor(
        public btnService: ButtonEnableDisableService,
        private customerService: CustomerService,
        private fb: FormBuilder, public bsModalRef: BsModalRef,
        private messageService: MessageService,
        private authenticationService: AuthService
    ) {
    }

    private setFormValue() {
        this.providerForm.patchValue(this.providerDetail, { onlySelf: true });
        this.providerForm.controls['headquartersType'].setValue(this.providerDetail.headquartersType.id);
    }

    private createForm() {
        this.providerForm = this.fb.group({
            name: ['', [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
            email: ['', [Validators.pattern(Pattern.EMAIL)]],
            phoneNumber: ['', [Validators.maxLength(80)]],
            accountType: [this.providerDetail.accountType ? this.providerDetail.accountType : 'Trial', []],
            headquartersType:[1]
        });
    }

    private addUser(exit?: boolean) {
        const user = this.providerForm.value;
        user.headquartersType=this.headquatersTypeOptions.filter((x:any)=>x.id===user.headquartersType)[0];
        this.btnService.disable();
        this.customerService.addProvider(user).subscribe({
            next: (result: any) => {
                this.btnService.enable();
                this.messageService.successMessage(`Headquarters: ${result.name === '' || result.name === null ? result.email : result.name}`, 'Saved Successfully');
                this.event.emit(result);
                this.bsModalRef.hide();
            },
            error: (error: any) => {
                this.btnService.enable();
                if (error.status === 409 || error.status === 422) {
                    this.messageService.errorMessage(`User`, error.error.message);
                } else {
                    this.messageService.errorMessage(`Error`, 'Error while saving');
                }
            }
        });
    }

    private updateUser(exit?: boolean) {
        const user: any = this.providerForm.value;
        user.headquartersType=this.headquatersTypeOptions.filter((x:any)=>x.id===user.headquartersType)[0];
      
        user.id = this.providerDetail.id;
        user.isActive = this.providerDetail.isActive;
        this.btnService.disable();

        this.customerService.updateProvider(user).subscribe({
            next: (updatedResult: any) => {
                this.btnService.enable();
                this.messageService.successMessage(`Headquarters: ${updatedResult.name === '' || updatedResult.name === null ? updatedResult.email : updatedResult.name}`, 'Saved Successfully');
                this.event.emit(updatedResult);
                this.bsModalRef.hide();
            },
            error: (updateError: any) => {
                this.btnService.enable();
                if (updateError.status === 409 || updateError.status === 422) {
                    this.messageService.errorMessage(`User`, updateError.error.message);
                } else {
                    this.messageService.errorMessage(`Error`, 'Error while saving');
                }
            }
        });
    }

    ngOnInit(): void {
        this.accountSelection = this.providerDetail.accountType ? this.providerDetail?.accountType : 'Trial';
        this.accountType = this.accountType.filter(e => e.accountType !== 'Non-Billable');
        this.currentUser = this.authenticationService.getUserKeyData();
        this.createForm();
        if (this.isEditMode) {
            this.setFormValue();
        }
    }


    saveUser() {
        if (this.providerForm.invalid) {
            this.isFormSubmitted = true;
            return;
        }
        if (this.isEditMode) {
            this.updateUser();
        } else {
            this.addUser();
        }
    }
    accountChanged(item: any, eventType: any) {
        if (eventType === 'provideraccount') {
            this.providerForm.value.accountType = item.accountTypeKey;
            this.accountSelection = item.accountTypeKey;
            this.providerForm.controls['accountType'].setValue(this.accountType?.find((x: any) => x.id === item.id)?.accountTypeKey, { onlySelf: true });
        }
    }
    closeModal() {
        this.bsModalRef.hide();
    }
    ngOnDestroy() {
        this.btnService.enable();
    }
}

