import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ButtonEnableDisableService {
  public serviceCall: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor() {
    this.enable()
  }

  enable() {
    this.serviceCall.next(false);
  }

  disable() {
    this.serviceCall.next(true);
  }

  getStatus(): boolean {
    return this.serviceCall.value;
  }
}
