<div class="modal-header">
    <h1 class="modal-title pull-left"> Edit Project Access Level
    </h1>
    <so-button [className]="'close pull-right'" (onClick)="closeModal()"
            [showEM]="true" [emClassName]="'fas fa-close'">
    </so-button>
  
  </div>
  <div class="modal-body">
    <p *ngIf="userDetails?.name">
      Select what access level <strong>{{userDetails?.name || userDetails?.email}}</strong> should have on
      <strong>{{projectDetails?.name}}</strong>.
    </p>
    <div>
      <label for="{{item.name}}" class="role-card d-flex align-items-center"
        *ngFor="let item of projectAcessRole let i = index;" [ngClass]="{'active': (item.roleKey === newRoleSelection)}">
        <div class="role-card-radio action-col">
            <so-radio [id]="item.name" [name]="'role'"
                (change)="onItemChange(item)" [isChecked]="item.roleKey === newRoleSelection" [label]="item.name">
            </so-radio>
        </div>
        <div class="role-card-description">
          <ul class="role-card-list">
            <li *ngFor="let roleDesc of item?.newDescription">
              {{roleDesc}}
            </li>
          </ul>
        </div>
        <div class="role-typical-user">
          <span class="user-title"> Typical User</span>
          <span class="user"> {{item.typicalUser}}</span>
        </div>
      </label>
  
    </div>
  </div>
  <div class="modal-footer mrg-t-40 ps-0">
    <so-button [className]="'btn btn-secondary'" [text]="'Cancel'" (onclick)="closeModal()"></so-button>
    <so-button [className]="'btn btn-primary m-0'" [text]="'Apply'" (onclick)="updateUser()"></so-button>

  </div>