import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'so-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() text!: string;
  @Input() type!: string;
  @Input() disabled :any;
  @Input() showEM = false;
  @Input() emClassName: any;
  @Input() className: any;
  @Input() style: any;
  @Input() extraText:any;
  @Output() onClick = new EventEmitter();

  _action(event:any){
    this.onClick.emit(event);
  }
}
