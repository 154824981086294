import { Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-replace-floor-image-count',
  templateUrl: './replace-floor-image-count.component.html',
  styleUrls: ['./replace-floor-image-count.component.scss'],
})
export class ReplaceFloorImageCountComponent {
  floorWiseDetails: any;
  floorName: any;
  eventImgSelect = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) {}
  _action(event: any) {
    this.eventImgSelect.emit();
  }
  _actionCancel(event: any) {
    this.bsModalRef.hide();
  }
}
