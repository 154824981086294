<div *ngIf="isShowLoader">
    <so-loader [smallloader]='true'></so-loader>
</div>
<div class="modal-header pb-0">
    <h4 class="modal-title">Multi-Plan Report (General Configuration)</h4>
    <button aria-label="Close" class="close pull-right" data-dismiss="modal" type="button" (click)="closeModalPopUp()">
        <em class="fas fa-close"></em>
    </button>
</div>
<div class="modal-body  pt-0">
    <p class="mb-4">This report is an export of all Plan images and Device lists from the selected Plans.</p>
    <div class="row">
        <div class="col-md-6">
            <so-multi-select-plan [isUnlimitedPlan]="isUnlimitedPlan" [isFromFloorPlan]="isFromFloorPlan"  [id]="id" [isFromSite]="isFromSite" [defaultbuildings]="buildings"
                (selectedFloor)="downloadPlans($event)"></so-multi-select-plan>
        </div>
        <div class="col-md-6">
            <h4>Multi-Plan Report</h4>
            <div class="generate-pdf-body">
                <div class="equipment-counter-pdf">
                    <div class="counter-tabs equipment-count">
                        <accordion>
                            <accordion-group class="accordin " [isOpen]="true">
                                <div accordion-heading>
                                    <div class="slide-toggle">
                                        Viewing Preferences
                                    </div>
                                </div>
                                <div class="filter-inner">
                                    <label class="device-card mb-1">Optimized For</label>
                                    <div *ngFor="let item of optimizedReportOptions; let i = index;"
                                        class="action-col ms-0">
                                        <so-radio [className]="'lbl-check'" [id]="'amt_' + item.name" [name]="'optimizedFor'"
                                        (change)="optimizedChanged(item)" [isChecked]="(i === 0)" [label]="item.name">
                                        </so-radio>
                                        <!-- <span class="radio-label">
                                            <input type="radio" id="amt_{{item.name}}" name="optimized"
                                                (change)="optimizedChanged(item)" [checked]="i === 0">
                                            <label for="amt_{{item.name}}">{{item.name}}</label>
                                        </span> -->
                                    </div>
                                    <ul class="list-unstyled">
                                        <label class="device-card mb-1">Pin Appearance</label>
                                        <li class="d-flex pin-slider-wrapper">
                                            <div class="pin-slider-card device-card">
                                                <label class="label-width">
                                                    Pin Color
                                                </label>
                                                <div class="form-group pin-color-filter-group">
                                                    <select class="form-control" [(ngModel)]="pinColor" name="active">
                                                        <option value="Device Color"
                                                            [selected]="(pinColor === 'Device Color'?true:false)">Device
                                                            Color
                                                        </option>
                                                        <option *ngIf="!isFromSite" value="Install Status"
                                                            [selected]="(pinColor === 'Install Status'?true:false)">
                                                            Install
                                                            Status
                                                        </option>
                                                        <option value="Operational Status"
                                                            [selected]="(pinColor === 'Operational Status'?true:false)">
                                                            Operational
                                                            Status</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-flex pin-slider-wrapper" *ngIf="projectId">
                                            <div class="pin-slider-card">
                                                <label class="label-width">
                                                    Assigned To
                                                </label>
                                                <div class="form-group pin-color-filter-group">
                                                    <select class="form-control" [(ngModel)]="assignedTo" name="active">
                                                        <option value="null"
                                                            [selected]="(assignedTo === null?true:false)">
                                                            Anyone
                                                        </option>
                                                        <!-- <option value="{{user?.user?.id}}"
                                                        [selected]="(assignedTo === user?.user?.id?true:false)">Me</option> -->
                                                        <option *ngFor="let item of installByUserItems"
                                                            [ngStyle]="{'display': item.isRemoved? 'none': 'block'}"
                                                            [ngValue]="item.key">{{item.value}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </li>

                                        <ng-container
                                            *ngFor="let item of filterCategory | multiPlanFilter:  {type: 'vp'};let index = index">
                                            <li [ngClass]="filterLabel(item.category)">
                                                <span *ngIf="item.category.id===5">
                                                    <label class="label-width device-card mt-2 mb-1">
                                                        Device List
                                                    </label>
                                                </span>
                                                <so-checkbox [isChecked]="item.isChecked"
                                                    (onChange)="filterCategoryChanged(item)"
                                                    [label]="item.category.displayName"
                                                    [id]="'category_multiplan_dv_' + item.category.id"
                                                    [ngClass]="'lbl-check'"></so-checkbox>
                                            </li>
                                        </ng-container>


                                    </ul>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin">
                                <div accordion-heading>
                                    <div class="slide-toggle">
                                        System Type
                                    </div>
                                </div>
                                <div class="filter-inner">
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of filterCategory | multiPlanFilter:  {type: 'st'}; let i=index"
                                            [ngClass]="filterLabel(item.category)">
                                            <so-checkbox *ngIf="item.category.name !== 'Show Plan Markup'"
                                                [isChecked]="item.isChecked" (onChange)="filterCategoryChanged(item)"
                                                [label]="item.category.name"
                                                [id]="'category_multiplan_st' + item.category.id"
                                                [ngClass]="'lbl-check'"></so-checkbox>

                                        </li>
                                    </ul>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin ">
                                <div accordion-heading>
                                    <div class="slide-toggle">
                                        Operational Status
                                    </div>
                                </div>
                                <div class="filter-inner">
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of filterCategory | multiPlanFilter:  {type: 'op'}; let i=index"
                                            [ngClass]="filterLabel(item.category)">
                                            <so-checkbox [isChecked]="item.isChecked"
                                                (onChange)="filterCategoryChanged(item)"
                                                [label]="item.category.displayName"
                                                [id]="'category_multiplan_op_' + item.category.id"
                                                [ngClass]="'lbl-check'"></so-checkbox>
                                        </li>
                                    </ul>
                                </div>
                            </accordion-group>
                            <accordion-group class="accordin " *ngIf="!isFromSite">
                                <div accordion-heading>
                                    <div class="slide-toggle">
                                        Install Status
                                    </div>
                                </div>
                                <div class="filter-inner">
                                    <ul class="list-unstyled">
                                        <li *ngFor="let item of filterCategory | multiPlanFilter:  {type: 'is'}; let i=index"
                                            [ngClass]="filterLabel(item.category)">
                                            <so-checkbox [isChecked]="item.isChecked"
                                                (onChange)="filterCategoryChanged(item)"
                                                [label]="item.category.displayName"
                                                [id]="'category_multiplan_is_' + item.category.id"
                                                [ngClass]="'lbl-check'"></so-checkbox>
                                        </li>
                                    </ul>
                                </div>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer ps-0">
    <div class="row p-0 align-items-center" style="width: 100%">
        <div class="col-auto note-text">
            <sup class="asterik">*</sup> This may take several minutes for larger {{!isProject ? 'Sites' : 'Projects'}}.
            The PDF will be generated in the background while you continue working on other pages in SiteOwl.
        </div>
        <div class="col justify-content-end d-flex">
            <button class="btn btn-secondary" data-dismiss="modal" type="button"
                (click)="closeModalPopUp()">Cancel</button>
            <button type="button" class="btn btn-primary"
                title="{{floorIds.length === 0?'Select at least 1 Plan to include in the report': ''}}"
                [disabled]="floorIds.length === 0" (click)="openColumnConfig()">
                Next
            </button>
        </div>
    </div>
</div>