import { Component, HostListener, OnDestroy, Renderer2 } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Roles, Customer, AuthService, UserService, MixpanelObjectService, CommonService, urlForGloalDashboard, LocalStorageKeys, Utilities, projectRoles, Environment, EventService, EventEmitterType, FloorDataTransferService, ListService } from '@SiteOwl/core';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'so-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
})
export class HeaderBarComponent implements OnDestroy {
  showLoader = false;
  isExpand!: boolean;
  formSub!: Subscription;
  userSub!: Subscription;
  userDetails: any;
  user: any;
  roles: any = Roles;
  activeCustomers: Customer[] = [];
  customerName!: string;
  customerId = 0;
  isCustomer!: boolean;
  title!: string;
  globalSearchData: any = {
    serviceTicketSearchDTOs: [],
    floorEquipmentSearchDTOs: [],
    projectFloorEquipmentSearchDTOs: []
  }
  timeout: any = null;
  isAdministrator!: boolean;
  isCustomerTechnician!: boolean;
  isInviteOnly!: boolean;
  request: any;
  staffMember = 'Staff Member';
  providerTech = 'Technician';
  isSearchStart!: boolean;

  private menuSub!: Subscription;
  redirectFloorPlanSub!: Subscription;
  // @ViewChild('user') el: ElementRef;
  projectSub!: Subscription;
  getCustomersSub!: Subscription;
  profilePicSub!: Subscription;
  profilePictureSub!: Subscription;
  thumnailPicture!: any;
  reqCustomerListSub!: Subscription;
  isSaveOnGoing = false;
  preserveNewCustomerToken: any;
  preserveNewCustomerId: any;
  checkHeaderClick = false;
  logOutSub!: Subscription;
  isUserProvider = false;
  dashboardName = 'Global Dashboard';
  userToken: any;
  userCustomerDetailSub!: Subscription;
  userUpdatedDataSub!: Subscription;
  userSwitchSub!: Subscription;
  cdnUrl = this.env.cdnUrl;
  searchCustomerText = '';
  recentCustomerList = []
  activeRemainCustomer: Customer[] = [];
  showSearch = false;
  floorData: any;
  floorDataReciverSub!: Subscription;
  isOnFloorPlan!: boolean;
  searchSub!: Subscription;
  searchForm = new FormGroup({
    searchText: new FormControl()
  });
  isLiveSystemAccessForCustomer: any;
  isLiveDevicesAvailable = false;
  isTrue = true;
  userUpdateDetailSub!: Subscription;
  @HostListener('window:click', ['$event'])
  handleKeyboardEvent(event: any) {
    const validDOM = document.getElementById('searchResultBlock')
    if (validDOM && validDOM !== event.target && !validDOM.contains(event.target)) {
      this.globalSearchData['serviceTicketSearchDTOs'] = [];
      this.globalSearchData['floorEquipmentSearchDTOs'] = [];
      this.globalSearchData['projectFloorEquipmentSearchDTOs'] = [];
      this.searchForm.controls['searchText'].setValue(null);
    }

  }

  constructor(private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
    private eventService: EventService,
    private mixpanelService: MixpanelObjectService,
    // private mixpanel: MixpanelService,
    private floorDataService: FloorDataTransferService,
    private commonService: CommonService,
    private listSerivce: ListService,
    private renderer: Renderer2,
    private readonly env: Environment) {

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        if (this.showLoader) {
          this.showLoader = false;
        }
        urlForGloalDashboard.forEach(element => {
          if (this.router.url.includes(element.url) && this.customerId !== 0) {
            this.selectCustomer(undefined, 'header');
          }
        });
      }
    });
    this.route.queryParams.subscribe((e: any) => {
      if (e.customerId && !this.router.url.includes('sharable')) {
        this.authService.setCustomerId({ customerId: e.customerId });
      }
    })

    this.redirectFloorPlanSub = this.eventService.subscribe(EventEmitterType.searchReset, (payload: any) => {
      this.isOnFloorPlan = false;
      this.isLiveDevicesAvailable = false
    })
    this.redirectFloorPlanSub = this.eventService.subscribe(EventEmitterType.floorPlanSaveOnGoing, (payload: any) => {
      this.isSaveOnGoing = payload.data;
      if (payload.customerId === 0 && payload.isCallPending !== undefined && !payload.isCallPending) {
        if (this.checkHeaderClick && (this.preserveNewCustomerId === 0 || this.preserveNewCustomerId === undefined)) {
          this.selectCustomer(this.preserveNewCustomerId);
        }
      }
    });
    this.floorDataReciverSub = this.floorDataService.getData().subscribe(res => {
      if (this.floorDataService.isOnFloorPlan) {
        this.isOnFloorPlan = true;
        if (!Utilities.isNull(res.liveDevicesShow)) {
          this.isLiveDevicesAvailable = res.liveDevicesShow;
        }
        this.floorData = {
          floorEquipments: [],
          serviceTicket: [],
          isFromProject: res.isFromProject
        };
        res.floorEquipments.forEach((element: any) => {
          this.floorData.floorEquipments.push(element);
          element.childFloorEquipments.forEach((elementChild: any) => {
            this.floorData.floorEquipments.push(elementChild);
          });
        });
        res.serviceTicket.forEach((elementTicket: any) => {
          this.floorData.serviceTicket.push(elementTicket);
        });
      } else {
        this.isOnFloorPlan = false;
        this.isLiveDevicesAvailable = false
      }
    })
    this.showSearch = false;
  }

  private getUserData() {
    this.user = this.authService.getUserKeyData();
    if (!Utilities.isNull(this.user.user.providerId) && this.user.user.providerId > 0) {
      this.isUserProvider = true;
      this.dashboardName = 'Headquarters';
    }
  }
  private getupdatedUseDAta() {
    this.userService.getUser().subscribe({
      next: (result: any) => {
        if (!Utilities.isNull(this.user)) {
          this.user.name = result.name;
          this.commonService.setLocalStorageObject(LocalStorageKeys.USER, this.user);
        }

      }
    })
  }


  private menuSubscribe() {
    // this.menuSub = this.eventService.subscribe(EventEmitterType.RouteChanged, (payload: Menu) => {
    //   this.title = payload.title;
    //   this.user = this.authService.getUserKeyData();
    //   this.checkCustomerRole();
    // });
    // this.formSub = this.eventService.subscribe(EventEmitterType.invalidForm, (payload) => {
    //   this.isExpand = payload;
    //   if (this.el) {
    //     if (!payload) {
    //       this.renderer.addClass(this.el.nativeElement, 'open');
    //     } else {
    //       this.renderer.removeClass(this.el.nativeElement, 'open');
    //     }
    //   }

    // })
  }

  private checkCustomerRole() {
    this.isAdministrator = this.authService.getUserKeyData().userRole === Roles.Administrator;
    this.isCustomerTechnician = this.user.customerRoleName === Roles.Technician && !this.isAdministrator;
    this.isInviteOnly = this.user.projectCustomerRoleName === projectRoles.Technician && !this.isAdministrator;
    this.isLiveSystemAccessForCustomer = this.user.isLiveSystemAccessForCustomer;
  }

  ngOnInit(): void {
    this.userToken = this.authService.getDecodedJWTToken();
    this.user = this.authService.getUserKeyData();
    this.getUserData();
    this.menuSubscribe();
    this.getCustomerDetails();
    this.subscription();
    this.checkCustomerRole();
    this.logOutSub = this.eventService.subscribe(EventEmitterType.logOutAction, res => {
      this.commonService.removeItem('loginType')
      this.authService.clearUserData();
      if (this.env.fronteggLogin) {
      window.location.href = `${this.env.fronteggBaseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}/public/login`;
      } else {
        this.router.navigate(['public/login']);
      }
    });
    this.userUpdateDetailSub = this.eventService.subscribe(EventEmitterType.userUpdatedDetails, (provider: any) => {
      if (provider && provider.user.providerId === null) {
        this.isUserProvider = false;
        this.dashboardName = 'Global Dashboard';
      } else {
        this.isUserProvider = true;
        this.dashboardName = 'Headquarters';
      }
      this.user = provider;
    })
    this.searchSub = this.searchForm.controls.searchText.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged()
    ).subscribe(res => {
      this.serviceCall(res)
    });

    document.body.addEventListener("click", function () { });
  }
  subscription() {
    // this.userUpdatedDataSub = this.eventService.subscribe(EventEmitterType.userUpdated, () => {
    //   this.getupdatedUseDAta();
    // })
    this.userCustomerDetailSub = this.eventService.subscribe(EventEmitterType.addOrEditCustomer, () => {
      this.getCustomerDetails();
    })
    // this.reqCustomerListSub = this.eventService.subscribe(EventEmitterType.reqactiveCustomers, (payload) => {
    //   this.eventService.broadcast(EventEmitterType.activeCustomers, this.activeCustomers);
    // })

    this.reqCustomerListSub = this.eventService.subscribe(EventEmitterType.ProfilePicChange, (payload) => {
      if (payload && payload.isImageUpdated) {
        this.thumnailPicture = payload.profilePic;
        this.user.user.thumbnailAwsImageURL = payload.profilePic;
      } else {
        this.user.user.name = payload.userData.name;
        this.user.user.email = payload.userData.email;
        this.user.user.cellNumber = payload.userData.cellNumber;
        this.user.user.workNumber = payload.userData.workNumber;
        this.user = _.cloneDeep(this.user);
      }
    })
    // if (this.userSub) {
    //   this.userSub.unsubscribe();
    // }
    this.userSwitchSub = this.eventService.subscribe(EventEmitterType.SwitchCustomer, (cust) => {
      if (cust.id) {
        this.authService.setCustomerId({ customerId: cust.id, user: this.authService.getUserKeyData().user.email });
        this.userService.getUserDetails().subscribe({
          next: (result: any) => {
            this.authService.saveUserData(result);
            cust.notRedirect = (cust.notRedirect === true);
            this.user = this.authService.getUserKeyData();
            this.checkCustomerRole();
            if (!cust.notRedirect) {
              this.router.navigate(['secure/customer/list']);
            }
            if (cust.name === undefined) {
              cust.name = result.customerName
            }
            this.customerName = cust.name
            this.customerId = cust.id;
            this.triggerMixPanelEvent();
            this.eventService.broadcast(EventEmitterType.CustomerChanged, this.customerId);
          }
        })
      } else {
        this.authService.setCustomerId({ customerId: cust, user: this.authService.getUserKeyData().user.email });
        this.userService.getUserDetails().subscribe({
          next: (result: any) => {
            this.listSerivce.getAdminSpecificRoleList().subscribe({
              next: (result: any) => {
                const adminRole: any = Utilities.seperateRoleDescription(result);
                this.commonService.setLocalStorageObject('adminRole', adminRole);
              }
            })
            this.authService.saveUserData(result);
            this.user = this.authService.getUserKeyData();
            this.checkCustomerRole();
            this.customerName = result.customerName;
            this.customerId = cust;
            this.triggerMixPanelEvent();
            this.eventService.broadcast(EventEmitterType.CustomerChanged, this.customerId);
          }
        });
      }
    })
    this.projectSub = this.eventService.subscribe(EventEmitterType.createProjectFromDashboard, (cust) => {
      const customerId = cust.customer.id || cust.customer;
      const customerName = cust.customer.name || cust.customerName;

      if (customerId) {
        this.customerId = customerId;
        this.authService.setCustomerId({ customerId: customerId, user: this.authService.getUserKeyData().user.email });
        this.userService.getUserDetails().subscribe(result => {

          this.authService.saveUserData(result);
          this.user = this.authService.getUserKeyData();
          this.checkCustomerRole();
          this.eventService.broadcast(EventEmitterType.CustomerChanged, this.customerId);
          if (cust.stage === 'EditProject') {
            if (cust.isReport === 'reports') {
              this.router.navigate([`secure/project/edit/${cust.projectId}`], { queryParams: { selectedTab: 'reports' } });
            } else if (cust.isReport === 'progress') {
              this.router.navigate([`secure/project/edit/${cust.projectId}`], { queryParams: { selectedTab: 'progress' } });
            } else {
              this.router.navigate([`secure/project/edit/${cust.projectId}`]);
            }
          }
          else if (cust.stage === 'EditTicket') {
            this.router.navigate([`secure/customer/ticket/edit/${cust.tickettId}`]);

          } else if (cust.stage === 'ticket') {
            this.router.navigate(['secure/customer/ticket/list'], { state: { ticket: 'create' } });
          } else if (cust.stage === 'Site') {
            this.router.navigate([`/secure/site/${cust.siteId}/building/${cust.buildingId}/floor/${cust.floorId}/details`])
          }
          else if (cust.stage === 'building') {
            this.router.navigate([`secure/project/${cust.projectId}/building/${cust.building.buildingId}/floor/${cust.building.floorDTOS[0].id}`], { queryParams: { comp: cust.comp } });
          } else if (cust.stage === 'floorPlan') {
            this.router.navigate([`secure/project/${cust.projectId}/building/${cust.buildingId}/floor/${cust.floorId}/details`], { queryParams: { comp: cust.comp, canProjectEdit: cust.canProjectEdit } });
          } else if (cust.stage === 'deviceList') {
            this.commonService.removeItem(LocalStorageKeys.PROJECTLISTFILTERS);
            const data = {
              project: cust.projectId,
              status: cust.status,
              deviceOrTaskType: cust.deviceOrTaskType,
              floor: cust.floorId,
              building: cust.buildingId,
              state: cust.state,
              installstatus: cust.installStatus,
              isFromList: true,
              flagstatus: cust.flagstatus
            };
            this.router.navigate(['secure/project/devices'], { queryParams: data });
          }
          else {
            this.router.navigate(['secure/project/' + cust.stage], { queryParams: { stage: cust.stage }, state: { project: 'create' } });
          }
          this.customerName = customerName;
          this.customerId = customerId;
        },
          error => { });
      }
    })
    // this.userSub = this.eventService.subscribe(EventEmitterType.updateLocalStorege, () => {
    //   this.user = this.authService.getUserKeyData();
    //   this.checkCustomerRole();
    // });
    // this.getCustomersSub = this.eventService.subscribe(EventEmitterType.getActiveCustomers, (payload) => {
    //   this.eventService.broadcast(EventEmitterType.checkActivatedCustomerLength, this.activeCustomers.length);
    // })
    // this.profilePictureSub = this.eventService.subscribe(EventEmitterType.updateLocalStoregeForProfilePic, (payload) => {
    //   this.thumnailPicture = payload;
    // })
    // this.profilePicSub = this.eventService.subscribe(EventEmitterType.updateLocalStoregeForProfilePic, (payload) => {
    //     const userData = this.commonService.getLocalStorageObject('userData')
    //     userData.user = payload;
    //     this.commonService.setLocalStorageObject('userData', userData);
    // })
  }

  // get the list of activated customers
  getActiveCustomers() {
    this.recentCustomerList = [];
    this.getUpdatedCustomer();
  }

  getUpdatedCustomer() {
    const customersRecords = []
    this.recentCustomerList = []
    this.userService.getMostRecentCustomers()
      .subscribe({
        next: (resultRec: any) => {
          this.recentCustomerList = resultRec
          resultRec.forEach((element: any) => {
            customersRecords.push(element);
          });
          this.userService.getActiveCustomers()
            .subscribe({
              next: (result: any) => {
                this.activeCustomers = []
                this.activeRemainCustomer = []
                this.showSearch = result.length > 4;
                result.forEach((element: any) => {
                  this.activeCustomers.push(element);
                });
                this.activeRemainCustomer = this.activeCustomers;
                this.route.queryParams.subscribe((e: any) => {
                  if (e && e.customerId) {
                    this.customerId = e.customerId;
                    this.eventService.broadcast(EventEmitterType.SwitchCustomer, e.customerId)
                  }
                })
                if (this.activeCustomers.length === 0) {
                  this.authService.setCustomerId({ customerId: 0, user: this.authService.getUserKeyData().user.email });
                }
                // this.eventService.broadcast(EventEmitterType.checkActivatedCustomerLength, this.activeCustomers.length);
                // this.eventService.broadcast(EventEmitterType.activeCustomers, this.activeCustomers);

                this.switchCustomer();
                if (!Utilities.isEmpty(this.customerId)) {
                  this.customerName = this.activeCustomers.find(x => x.id === this.customerId)?.name || '';
                }
              }
            });
        }
      })
  }
  switchCustomer() {
    if (this.activeCustomers.length === 1 && !this.isUserProvider) {
      this.eventService.broadcast(EventEmitterType.SwitchCustomer, this.activeCustomers[0].id)
    }
  }
  // get the list of activated customers by User
  getActiveCustomersByUser() {
    const customersRecords = []
    this.recentCustomerList = []
    this.userService.getMostRecentCustomers()
      .subscribe({
        next: (resultRec: any) => {
          this.recentCustomerList = resultRec
          resultRec.forEach((element: any) => {
            customersRecords.push(element);
          });
          this.userService.getActiveCustomersByUser().subscribe({
            next: (result: any) => {
              this.activeCustomers = []
              this.activeRemainCustomer = []
              this.showSearch = result.length > 4;
              result.forEach((element: any) => {
                this.activeCustomers.push(element);
              });
              this.activeRemainCustomer = this.activeCustomers;
              this.route.queryParams.subscribe((e: any) => {
                if (e && e.customerId) {
                  this.customerId = e.customerId;
                  this.eventService.broadcast(EventEmitterType.SwitchCustomer, e.customerId);
                }
              })
              if (this.activeCustomers.length === 0) {
                this.authService.setCustomerId({ customerId: 0, user: this.authService.getUserKeyData().user.email });
              }
              // this.eventService.broadcast(EventEmitterType.checkActivatedCustomerLength, this.activeCustomers.length);
              // this.eventService.broadcast(EventEmitterType.activeCustomers, this.activeCustomers);
              if (Utilities.isNull(this.user.user.providerId)) {
                this.switchCustomer();
              }

              if (!Utilities.isEmpty(this.customerId)) {
                this.customerName = this.activeCustomers.find(x => x.id === this.customerId)?.name || '';
              }
            }
          });
        }
      })
  }
  customerChanged(event?: any, customer?: Customer, isFromHeader?: any) {
    if (isFromHeader && isFromHeader === 'header') {
      this.checkHeaderClick = true;
    }
    let customerId;
    if (event && event.target && event.target.id === 'global-dashboard') {
      customerId = customer ? customer.id : 0;
    } else {
      customerId = customer ? customer.id : -1;
    }
    this.preserveNewCustomerId = customerId === -1 ? 0 : customerId;
    this.eventService.broadcast(EventEmitterType.customerChangeAttemp, customerId);
  }
  selectCustomer(customer?: any, isFromHeader?: any) {
    const timeOut = setTimeout(() => {
      if (customer === null || (customer && (customer.name !== 'No matching accounts found' || customer.name !== 'No matching customers found'))) {
        this.checkHeaderClick = false;
        if (isFromHeader && isFromHeader === 'header') {
          this.checkHeaderClick = true;
          if (this.preserveNewCustomerId === undefined) {
            this.preserveNewCustomerId = 0;
          }
        }
        if (!this.isUserProvider) {
          this.dashboardName = 'Global Dashboard';
          this.customerName = customer ? customer.name : this.dashboardName;
        } else {
          this.dashboardName = 'Headquarters';
          this.customerName = customer ? customer.name : this.dashboardName;
        }
        const customerId = customer ? customer.id : 0;
        if (!this.isSaveOnGoing && this.preserveNewCustomerId !== undefined) {
          this.commonService.removeItem(LocalStorageKeys.LASTASSIGNEE)


          this.authService.setCustomerId({ customerId: customerId, user: this.authService.getUserKeyData().user.email });
          this.userService.getUserDetails().subscribe({
            next: (result: any) => {
              if ((this.authService.getUserKeyData().userRole === Roles.Administrator)) {
                this.getActiveCustomers();
              } else {
                this.getActiveCustomersByUser();
              }
              this.authService.saveUserData(result);


              this.customerId = customerId || 0;
              this.triggerMixPanelEvent();
              this.commonService.removeItem('menu')
              if (this.router.url.includes('/details') && this.isSaveOnGoing) {

                this.preserveNewCustomerToken = result
              } else {
                this.preserveNewCustomerToken = null
                this.authService.saveUserData(result);
              }
              this.user = this.authService.getUserKeyData();
              this.checkCustomerRole();
              this.eventService.broadcast(EventEmitterType.CustomerChanged, this.customerId);
              if (this.authService.getUserKeyData().userRole === Roles.User && this.user.customerRoleName === Roles.Technician) {
                this.router.navigate(['/secure/dashboard/customer'], { relativeTo: this.route });
              } else if (this.customerId === 0) {
                let dashboardTabSelection = 'design'
                if (result.user.dashboardTab) {
                  dashboardTabSelection = result.user.dashboardTab;
                }
                this.router.navigate(['/secure', 'dashboard', dashboardTabSelection], { relativeTo: this.route });
              } else if (window.location.href.indexOf('/secure/dashboard/customer') === -1) {
                this.router.navigate(['/secure/dashboard/customer'], { relativeTo: this.route });
              } else {
                this.router.navigate(['/secure/dashboard/customer'], { relativeTo: this.route });
              }
            }
          });
          this.searchCustomerText = ''
        } else {
          if (!Utilities.isEmpty(this.preserveNewCustomerToken)) {
            this.authService.saveUserData(this.preserveNewCustomerToken);
          }
          this.searchCustomerText = ''
        }
        this.searchCustomerText = ''
      }
      clearTimeout(timeOut)
    }, 0);
    this.searchCustomerText = ''
  }
  redirectToProvider(event: any) {
    this.commonService.removeItem('menu')
    if (this.user.user.profileCompleted) {
      this.router.navigate([`/secure/provider/${this.user.user.providerId}`], {
        queryParams: { isFromHeader: true }
      });
    } else {
      event.preventDefault();
    }
  }
  redirectToOther(data: any, redirectTo: any) {

    if (redirectTo === 'site') {
      this.trackSearchMixPanelEvent({
        'Action': 'Result Selected',
        'Category': 'Site Devices',
        'Selection ID': data.id,
        'Selection Name': data.name
      }, 'Account Search');
      if (this.isOnFloorPlan) {
        this.eventService.broadcast(EventEmitterType.floorEquipmentSelectionFromHeader, data);
      } else {
        this.showLoader = true;
        this.router.navigate([`/secure/site/${data.siteId}/building/${data.buildingId}/floor/${data.floorId}/details`], {
          queryParams: {
            siteid: data.siteId,
            equipmentid: data.id,
            floorid: data.floorId,
            from: 'search'
          },
          relativeTo: this.route,
          queryParamsHandling: ''
        });
      }

    } else if (redirectTo === 'ticket') {
      this.showLoader = true;
      let isRedirect = true
      this.trackSearchMixPanelEvent({
        'Action': 'Result Selected',
        'Category': 'Tickets',
        'Selection ID': data.id,
        'Selection Name': data.subject
      }, 'Account Search');

      if (this.router.url.includes('secure/customer/ticket/edit/')) {
        const dataVal = this.router.url.split('edit/');
        const dataval = dataVal[1].split('?');
        if (data.id === parseInt(dataval[0])) {
          isRedirect = false
          this.showLoader = false;
        }
      }
      if (isRedirect) {
        this.router.navigate(['secure', 'customer', 'ticket', 'edit', data.id],
          {
            queryParams: {
              floorid: data.floorId,
              equipmentid: data.equipmentId || null,
              site: data.siteId,
            }
          });
      }

    } else if (redirectTo === 'project') {
      this.trackSearchMixPanelEvent({
        'Action': 'Result Selected',
        'Category': Utilities.isNull(data.projectId) ? 'Site Devices' : 'Project Devices/Tasks',
        'Selection ID': data.id,
        'Selection Name': data.name
      }, 'Account Search');
      if (this.isOnFloorPlan) {
        this.eventService.broadcast(EventEmitterType.floorEquipmentSelectionFromHeader, data);
      } else {
        this.showLoader = true;
        this.router.navigate([`/secure/project/${data.projectId}/building/${data.buildingId}/floor/${data.floorId}/details`], {
          queryParams: {
            projectid: data.projectId,
            equipmentid: data.id,
            floorid: data.floorId,
            from: 'search'
          },
          relativeTo: this.route,
          queryParamsHandling: ''
        });
      }

    }
    this.globalSearchData['serviceTicketSearchDTOs'] = [];
    this.globalSearchData['floorEquipmentSearchDTOs'] = [];
    this.globalSearchData['projectFloorEquipmentSearchDTOs'] = [];
    this.searchForm.controls['searchText'].setValue(null);
    // setTimeout(() => {
    //     this.showLoader = false;
    // }, 1);
  }

  logout() {
    this.mixpanelService.trackEvent('Logged-Out', {}, 'User Logged Out ');
    // this.mixpanel.resetMixPanel();
    if (this.router.url.includes('building') && this.router.url.includes('floor') && this.router.url.includes('details')) {
      this.authService.isApiCalling = true;
      this.eventService.broadcast(EventEmitterType.beforeLogoutAction, true)
      // this.authService.clearUserData();
      // this.router.navigateByUrl('/public/login');
    } else {
      // this.authService.isApiCalling = true;
      this.authService.clearUserData();
      this.commonService.removeItem('loginType')
      if (this.env.fronteggLogin) {
        window.location.href = `${this.env.fronteggBaseUrl}/oauth/logout?post_logout_redirect_uri=${window.location.origin}/public/login`;
      } else {
        this.router.navigate(['public/login']);
      }

    }
  }

  serviceCall(token: any) {
    if (token === null || token === undefined) {
      return;
    }
    token = _.cloneDeep(token.trim());
    const startTimer = new Date().getTime();
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      if (token && token.length > 0) {
        const regex = /"/gi;
        token = (token.replace(regex, '\\"'));
        const searchData = {
          searchText: token
        }
        this.globalSearchData['serviceTicketSearchDTOs'] = [];
        this.globalSearchData['floorEquipmentSearchDTOs'] = [];
        this.globalSearchData['projectFloorEquipmentSearchDTOs'] = [];
        if (this.isOnFloorPlan) {
          const accentToken = token.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          if (this.floorData.isFromProject) {
            this.globalSearchData.floorEquipmentSearchDTOs = _.sortBy(this.floorData.floorEquipments.filter((e: any) => ((e.name.toLowerCase().includes(token.toLowerCase()) || e.name.toLowerCase().includes(accentToken.toLowerCase())) ||
              (e.partNumber && (e.partNumber.toLowerCase().includes(token.toLowerCase()) || e.partNumber.toLowerCase().includes(accentToken)))
              || (e.serialNumber && (e.serialNumber.toLowerCase().includes(token.toLowerCase()) || e.serialNumber.toLowerCase().includes(accentToken.toLowerCase())))
              || (e.macAddress && (e.macAddress.toLowerCase().includes(token.toLowerCase()) || e.macAddress.toLowerCase().includes(accentToken.toLowerCase())))
              || (e.ipAddress && (e.ipAddress.toLowerCase().includes(token.toLowerCase()) || e.ipAddress.toLowerCase().includes(accentToken.toLowerCase()))))
              && !e.isArchived
              && Utilities.isNull(e.projectId)), ['name']);
            this.globalSearchData.projectFloorEquipmentSearchDTOs = _.sortBy(this.floorData.floorEquipments.filter((e: any) => ((e.name.toLowerCase().includes(token.toLowerCase()) || e.name.toLowerCase().includes(accentToken.toLowerCase())) ||
              (e.partNumber && (e.partNumber.toLowerCase().includes(token.toLowerCase()) || e.partNumber.toLowerCase().includes(accentToken)))
              || (e.serialNumber && (e.serialNumber.toLowerCase().includes(token.toLowerCase()) || e.serialNumber.toLowerCase().includes(accentToken.toLowerCase())))
              || (e.macAddress && (e.macAddress.toLowerCase().includes(token.toLowerCase()) || e.macAddress.toLowerCase().includes(accentToken.toLowerCase())))
              || (e.ipAddress && (e.ipAddress.toLowerCase().includes(token.toLowerCase()) || e.ipAddress.toLowerCase().includes(accentToken.toLowerCase()))))
              && !e.isArchived
              && !Utilities.isNull(e.projectId)), ['name']);
            this.globalSearchData.serviceTicketSearchDTOs = [];
          } else {
            this.globalSearchData.floorEquipmentSearchDTOs = _.sortBy(this.floorData.floorEquipments.filter((e: any) => ((e.name.toLowerCase().includes(token.toLowerCase()) || e.name.toLowerCase().includes(accentToken.toLowerCase())) ||
              (e.partNumber && (e.partNumber.toLowerCase().includes(token.toLowerCase()) || e.partNumber.toLowerCase().includes(accentToken)))
              || (e.serialNumber && (e.serialNumber.toLowerCase().includes(token.toLowerCase()) || e.serialNumber.toLowerCase().includes(accentToken.toLowerCase())))
              || (e.macAddress && (e.macAddress.toLowerCase().includes(token.toLowerCase()) || e.macAddress.toLowerCase().includes(accentToken.toLowerCase())))
              || (e.ipAddress && (e.ipAddress.toLowerCase().includes(token.toLowerCase()) || e.ipAddress.toLowerCase().includes(accentToken.toLowerCase()))))
              && !e.isArchived), ['name']);
            this.globalSearchData.projectFloorEquipmentSearchDTOs = [];
            this.globalSearchData.serviceTicketSearchDTOs = _.sortBy(this.floorData.serviceTicket.filter((e: any) => (e.subject.toLowerCase().includes(token.toLowerCase()) || e.subject.toLowerCase().includes(accentToken.toLowerCase()))), ['subject']);
          }
          this.isSearchStart = false;
          this.trackSearchMixPanelEvent({
            'Action': 'Search',
            'Scope': this.isOnFloorPlan ? 'Plan' : 'Account',
            'Query': token,
            'Search Time': new Date().getTime() - startTimer,
            'Search Result Count': this.globalSearchData.floorEquipmentSearchDTOs.length + this.globalSearchData.projectFloorEquipmentSearchDTOs.length + this.globalSearchData.serviceTicketSearchDTOs.length
          }, 'Account Search');
        } else {
          this.request = this.userService.globalSearch(searchData).subscribe({
            next: (response: any) => {

              if (response['floorEquipmentSearchDTOs'] && response['floorEquipmentSearchDTOs'].length > 0) {
                this.globalSearchData['floorEquipmentSearchDTOs'] = _.orderBy(response['floorEquipmentSearchDTOs'],
                  ['name', 'siteName', 'buildingName', 'floorName', 'id'], ['asc']);
              } else {
                this.globalSearchData['floorEquipmentSearchDTOs'] = [];
              }
              if (response['serviceTicketSearchDTOs'] && response['serviceTicketSearchDTOs'].length > 0) {
                this.globalSearchData['serviceTicketSearchDTOs'] = _.orderBy(response['serviceTicketSearchDTOs'],
                  ['subject', 'siteName', 'buildingName', 'floorName', 'id'], ['asc']);
              } else {
                this.globalSearchData['serviceTicketSearchDTOs'] = [];
              }
              if (response['projectFloorEquipmentSearchDTOs'] && response['projectFloorEquipmentSearchDTOs'].length > 0) {
                this.globalSearchData['projectFloorEquipmentSearchDTOs'] = _.orderBy(response['projectFloorEquipmentSearchDTOs'],
                  ['subject', 'siteName', 'buildingName', 'floorName', 'id'], ['asc']);
              } else {
                this.globalSearchData['projectFloorEquipmentSearchDTOs'] = [];
              }

              this.isSearchStart = false;
              this.trackSearchMixPanelEvent({
                'Action': 'Search',
                'Scope': this.isOnFloorPlan ? 'Plan' : 'Account',
                'Query': token,
                'Search Time': new Date().getTime() - startTimer,
                'Search Result Count': this.globalSearchData.floorEquipmentSearchDTOs.length + this.globalSearchData.projectFloorEquipmentSearchDTOs.length + this.globalSearchData.serviceTicketSearchDTOs.length
              }, 'Account Search');
            }
          });
        }

      } else {
        this.globalSearchData['serviceTicketSearchDTOs'] = [];
        this.globalSearchData['floorEquipmentSearchDTOs'] = [];
        this.globalSearchData['projectFloorEquipmentSearchDTOs'] = [];
        this.searchForm.controls['searchText'].setValue(null);
        this.isSearchStart = false;
      }
      clearTimeout(this.timeout)
    });
  }

  getCustomerDetails() {
    if ((this.authService.getUserKeyData().userRole === Roles.Administrator)) {
      this.customerId = this.authService.getCustomerId() ? this.authService.getCustomerId().customerId : 0;
      this.getActiveCustomers();
    } else if (this.authService.getUserKeyData().userRole === Roles.User) {
      this.customerId = this.authService.getCustomerId() ? this.authService.getCustomerId().customerId : 0;
      this.getActiveCustomersByUser();
    }
  }

  ngOnDestroy(): void {
    if (this.reqCustomerListSub) {
      this.reqCustomerListSub.unsubscribe();
    }
    if (this.userSwitchSub) {
      this.userSwitchSub.unsubscribe();
    }
    if (this.userCustomerDetailSub) {
      this.userCustomerDetailSub.unsubscribe();
    }
    if (this.logOutSub) {
      this.logOutSub.unsubscribe();
    }
    if (this.redirectFloorPlanSub) {
      this.redirectFloorPlanSub.unsubscribe();
    }
    if (this.projectSub) {
      this.projectSub.unsubscribe();
    }
    if (this.floorDataReciverSub) {
      this.floorDataReciverSub.unsubscribe();
    }
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }

    if (this.userUpdateDetailSub) {
      this.userUpdateDetailSub.unsubscribe()
    }
  }
  userProfileRedirect() {
    this.commonService.removeItem('menu')
    this.router.navigate(['/secure/profile']);
  }

  showDp() {
    const timeOut = setTimeout(() => {
      document.getElementById('show-sub-menu')?.classList.add('show');
      clearTimeout(timeOut)
    }, 0);
  }
  triggerMixPanelEvent() {
    const trackMixPanel: any = {
      'Account Id': this.customerId,
      'Account Name': this.customerId !== 0 ? this.customerName : this.dashboardName
    }
    this.mixpanelService.trackEvent('Account', trackMixPanel, 'Account');
  }
  trackSearchMixPanelEvent(object: any, eventName: any) {
    const userKeyData = this.authService.getUserKeyData()
    const trackMixPanel: any = {
      'Account Id': userKeyData.customerId,
      'Account Name': userKeyData.customerName
    }
    for (const property in object) {
      trackMixPanel[property] = object[property];
    }
    this.mixpanelService.trackEvent(eventName, trackMixPanel);
  }
  setLoader() {
    this.isSearchStart = true;
  }
}
