import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback", "deviceId"],
  _excluded2 = ["callback", "deviceId"];
import { call, put } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { errorHandler } from '../../../utils';
import { actions } from '../../reducer';
import { base64urlDecode, publicKeyCredentialToJSON } from '../../utils';
import { handleVerifyMFAResponse } from './handleVerifyMFAResponse.saga';

/**
 * Shared logic for MFA Webauthn pre-verify step
 * @param payload.callback callback function to be called after the verification is done
 * @param payload.deviceId
 * @param payload.mfaToken
 * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
 */
export function* preVerifyMFAWebAuthn(_ref, setLoadingAction) {
  let {
      callback,
      deviceId
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref, _excluded);
  yield put(setLoadingAction({
    loading: true
  }));
  try {
    var _data$options$allowCr;
    const data = yield call(api.auth.preVerifyMFAWebAuthnForLogin, deviceId, payload);
    const options = _extends({}, data.options, {
      challenge: base64urlDecode(data.options.challenge),
      allowCredentials: (_data$options$allowCr = data.options.allowCredentials) == null ? void 0 : _data$options$allowCr.map(credentials => _extends({}, credentials, {
        id: base64urlDecode(credentials.id)
      }))
    });
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback({
      options,
      webauthnToken: data.webauthnToken
    });
  } catch (e) {
    yield put(setLoadingAction({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}

/**
 * Shared logic for MFA Webauthn verify step
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.webauthnToken
 * @param payload.mfaToken
 * @param payload.publicKey public key object from browser navigator credentials
 * @param payload.code 6 digits code input by the user
 * @param setLoadingAction loading setter action (e.g. actions.setLoginState)
 * @param isStepUp true when it's step up flow
 */
export function* verifyMFAWebAuthn(_ref2, setLoadingAction, isStepUp) {
  let {
      callback,
      deviceId
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2, _excluded2);
  yield put(setLoadingAction({
    loading: true
  }));
  try {
    const publicKey = publicKeyCredentialToJSON(payload.publicKey);
    const data = yield call(api.auth.verifyMFAWebAuthnForLoginV2, deviceId, _extends({}, payload, {
      options: publicKey
    }));
    yield handleVerifyMFAResponse(data, isStepUp);
    yield put(setLoadingAction({
      loading: false,
      error: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(setLoadingAction({
      loading: false,
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null);
  }
}

/**
 * Pre verify step for MFA Webauthn login
 * @param payload.callback callback function to be called after the verification is done
 * @param payload.deviceId
 * @param payload.mfaToken
 */
export function* preVerifyMFAWebAuthnForLogin({
  payload
}) {
  yield preVerifyMFAWebAuthn(payload, actions.setLoginState);
}

/**
 * Verify step for MFA Webauthn login
 * @param payload.callback callback function to be called after the verification is done with true for success, o.w false
 * @param payload.deviceId
 * @param payload.webauthnToken
 * @param payload.mfaToken
 * @param payload.publicKey public key object from browser navigator credentials
 * @param payload.code 6 digits code input by the user
 */
export function* verifyMFAWebAuthnForLogin({
  payload
}) {
  yield verifyMFAWebAuthn(payload, actions.setLoginState, false);
}