/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { Utilities } from '../utilities';
import { FileUploadConstant } from '../constants/common.constant';
import { FileItem } from '../model/file-item';
import { FileUploadOptions } from '../model';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {


  isInvalidFile(file: any, options: FileUploadOptions, fileType?: any): any {
    const fileExtension = fileType ? fileType : file.type;
    if (!Utilities.isEmpty(options.acceptExtensions) && options.acceptExtensions !== undefined && options.acceptExtensions.indexOf(fileExtension) === -1) {
      return FileUploadConstant.FILETYPEERRORMESSAGE;
    }
  }

  isValidFileWithMessageArr(files: FileItem[], options: FileUploadOptions): any {
    for (const file of files) {
      this.isValidFileWithMessage(file, options);
    }
    return null;
  }
  isValidFileWithMessage(file: any, options: FileUploadOptions): any {
    const fileExtension = '.' + file.name.split('.')[file.name.split('.').length - 1].toLowerCase();
    if ((!Utilities.isEmpty(options.acceptExtensions) && options.acceptExtensions.indexOf(fileExtension) === -1) || (options.maxSize && file.size > (options.maxSize * 1048576))) {
      return FileUploadConstant.FILEERORMESSAGE;
    }
    return null;
  }

  isValidBuildingFileWithMessage(file: any, type?: any): any {
    const fileExtension = type ? type : file.type;
    if (FileUploadConstant.ALLOWEDIMAGEFILES.indexOf(fileExtension) > -1) {
      if (file.size === 0 || file.size > (FileUploadConstant.ALLOWEDFILESIZE * 1048576)) {
        return FileUploadConstant.FILESIZEERRORMESSAGE;
      }
      return null;
    } else if (fileExtension === 'application/pdf') {
      if (file.size === 0 || file.size > (50 * 1048576)) {
        return FileUploadConstant.BUILDINGFILESIZEERRORMESSAGE;
      }
      return null;
    } else {
      return FileUploadConstant.FILETYPEERRORMESSAGE;
    }
  }

  isValidBuildingFile(file: any): any {
    const fileExtension = file.type;
    if (FileUploadConstant.ALLOWEDIMAGEFILES.indexOf(fileExtension) > -1 && (fileExtension !== 'application/pdf')) {
      return 'image';
    } else if (fileExtension === 'application/pdf') {
      return 'pdf';
    } else {
      return null;
    }
  }

  createFromArr(file: any[], options?: FileUploadOptions, isMessage?: any): FileItem[] {
    const arr = []
    if (file && file.length > 0) {

      for (const element of file) {

        const fileObject: FileItem = new FileItem();
        fileObject.fileData = element;
        fileObject.size = element.size;
        fileObject.type = element.type;
        fileObject.name = element.name;
        fileObject.extension = '.' + element.name.split('.')[element.name.split('.').length - 1].toLowerCase()

        if (options && isMessage) {
          if (!((!Utilities.isEmpty(options.acceptExtensions) && options.acceptExtensions.indexOf(fileObject.extension) === -1) || fileObject.size > (options.maxSize * 1048576))) {
            arr.push(fileObject)
          }
        } else {
          arr.push(fileObject)
        }
      }
    }
    return arr;
  }
  createInvalidFileArr(file: any[], options?: FileUploadOptions, isMessage?: any): any {
    const arr = []
    if (file && file.length > 0) {

      for (const element of file) {

        const fileObject: FileItem = new FileItem();
        fileObject.fileData = element;
        fileObject.size = element.size;
        fileObject.type = element.type;
        fileObject.name = element.name;
        fileObject.extension = '.' + element.name.split('.')[element.name.split('.').length - 1].toLowerCase()

        if (options) {
          if ((!Utilities.isEmpty(options.acceptExtensions) && options.acceptExtensions.indexOf(fileObject.extension) === -1) || (options.maxSize && element.size > (options.maxSize * 1048576))) {
            arr.push(fileObject)
          }
        }
      }
    }
    if (arr.length === file.length) {

      return { files: arr, allInvalid: true };
    } else {
      return { files: arr, allInvalid: false };

    }
  }
  createFromObject(file: any): FileItem {
    const fileObject: FileItem = new FileItem();
    fileObject.fileData = file;
    fileObject.size = file.size;
    fileObject.type = file.type;
    fileObject.name = file.name;
    fileObject.tempId = Math.random()
    fileObject.extension = '.' + file.name.split('.')[file.name.split('.').length - 1].toLowerCase()
    return fileObject;
  }

  dataURLtoFile(dataurl: any, filename: any) {
    const arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  getOrientation(file: any, callback: any) {
    const reader: any = new FileReader();

    reader.onload = function (e: any) {
      const view = new DataView(e.target['result']);

      // check for jpeg marker '0xffd8', return if not img
      if (view.getUint16(0, false) !== 0xFFD8) {
        return callback(-2)
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        // check for EXIF marker '0xFFE1'
        if (marker === 0xFFE1) {
          if (view.getUint32(offset += 2, false) !== 0x45786966) {
            return callback(-1)
          }
          const little = view.getUint16(offset += 6, false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;
          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + (i * 12), little) == 0x0112) {
              return callback(view.getUint16(offset + (i * 12) + 8, little));
            }
          }
        } else if ((marker && 0xFF00) !== 0xFF00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };
    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }

  isValidBuildingPDFFileWithMessage(file: any): any {
    const fileExtension = file.type;
    if (fileExtension === 'application/pdf') {
      if (file.size === 0 || file.size > (50 * 1048576)) {
        return FileUploadConstant.FILESIZEERRORMESSAGE;
      }
      return null;
    } else {
      return FileUploadConstant.FILETYPEERRORMESSAGE;
    }
  }
  checkFileType(header: any) {
    let type = '';
    switch (header) {
      case "89504e47":
        type = "image/png";
        break;
      case "47494638":
        type = "image/gif";
        break;
      case "ffd8ffe0":
      case "ffd8ffe1":
      case "ffd8ffe2":
      case "ffd8ffe3":
      case "ffd8ffe8":
        type = "image/jpeg";
        break;
      case '25504446':
        type = "application/pdf";
        break;
      case '00018':
        type = 'image/heic';
        break;
      case 'd0cf11e0':
        type = 'application/vnd.ms-excel';
        break;
      case 'efbbbf50':
        type = 'text/csv';
        break;
      case '504b34':
        type = 'application/vnd.openxmlformats';
        break;
      case '41433130':
        type = 'application/acad';
        break;
      case '51756f64':
        type = 'text/plain';
        break;
      default:
        type = "unknown"; // Or you can use the blob.type as fallback
        break;
    }
    return type;
  }

  getMagicCode(file: any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (event: any) => {
        const arr = (new Uint8Array(event.target.result)).subarray(0, 4);
        let header = "";
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        resolve(header)
      };
      reader.readAsArrayBuffer(file)
    });
  }
}
