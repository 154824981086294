export let ProfilePageFields;
(function (ProfilePageFields) {
  ProfilePageFields["Name"] = "name";
  ProfilePageFields["PhoneNumber"] = "phoneNumber";
  ProfilePageFields["Address"] = "address";
  ProfilePageFields["JobTitle"] = "jobTitle";
})(ProfilePageFields || (ProfilePageFields = {}));
export let PrivacyPageFields;
(function (PrivacyPageFields) {
  PrivacyPageFields["LoginSessions"] = "loginSessions";
  PrivacyPageFields["Mfa"] = "mfa";
})(PrivacyPageFields || (PrivacyPageFields = {}));
export let InviteUserModalFields;
(function (InviteUserModalFields) {
  InviteUserModalFields["Name"] = "name";
  InviteUserModalFields["PhoneNumber"] = "phoneNumber";
})(InviteUserModalFields || (InviteUserModalFields = {}));
export let AccountPageFields;
(function (AccountPageFields) {
  AccountPageFields["CompanyName"] = "companyName";
  AccountPageFields["Address"] = "address";
  AccountPageFields["Website"] = "website";
  AccountPageFields["Timezone"] = "timezone";
  AccountPageFields["Currency"] = "currency";
})(AccountPageFields || (AccountPageFields = {}));
export let SubscriptionsPageFields;
(function (SubscriptionsPageFields) {
  SubscriptionsPageFields["Invoices"] = "invoices";
})(SubscriptionsPageFields || (SubscriptionsPageFields = {}));
export let SecurityPageTabs;
(function (SecurityPageTabs) {
  SecurityPageTabs["SessionManagement"] = "sessionManagement";
  SecurityPageTabs["GeneralSettings"] = "generalSettings";
  SecurityPageTabs["IpRestrictions"] = "ipRestrictions";
  SecurityPageTabs["DomainRestrictions"] = "domainRestrictions";
})(SecurityPageTabs || (SecurityPageTabs = {}));
export let SessionManagementTabFields;
(function (SessionManagementTabFields) {
  SessionManagementTabFields["IdleSessionTimeout"] = "idleSessionTimeout";
  SessionManagementTabFields["ForceReLogin"] = "forceReLogin";
  SessionManagementTabFields["MaximumConcurrentSessions"] = "maximumConcurrentSessions";
})(SessionManagementTabFields || (SessionManagementTabFields = {}));
export let GeneralSettingsTabFields;
(function (GeneralSettingsTabFields) {
  GeneralSettingsTabFields["Mfa"] = "mfa";
  GeneralSettingsTabFields["UserLockout"] = "userLockout";
  GeneralSettingsTabFields["PasswordHistory"] = "passwordHistory";
})(GeneralSettingsTabFields || (GeneralSettingsTabFields = {}));
export let IpRestrictionsTabFields;
(function (IpRestrictionsTabFields) {
  IpRestrictionsTabFields["IpAddressRestrictions"] = "ipAddressRestrictions";
})(IpRestrictionsTabFields || (IpRestrictionsTabFields = {}));
export let DomainRestrictionsTabFields;
(function (DomainRestrictionsTabFields) {
  DomainRestrictionsTabFields["RestrictSignupByEmailDomain"] = "restrictSignupByEmailDomain";
})(DomainRestrictionsTabFields || (DomainRestrictionsTabFields = {}));
export let AccountDetailsPageTabs;
(function (AccountDetailsPageTabs) {
  AccountDetailsPageTabs["Settings"] = "settings";
  AccountDetailsPageTabs["CustomLogin"] = "customLogin";
})(AccountDetailsPageTabs || (AccountDetailsPageTabs = {}));