import { createAction } from '@reduxjs/toolkit';
import { authStoreName } from '../../../constants';
import { errorsReducerForKey, loadersReducerForKey, resetStateByKey, typeReducerForKey } from '../../utils';
const securityCenterState = {
  loaders: {},
  errors: {},
  recommendations: undefined,
  insights: undefined,
  score: 0,
  breachedPasswordUsersTable: {
    users: [],
    usersPageOffset: 0,
    totalUsersItems: 0,
    totalUsersPages: 0,
    queryParams: {
      _offset: 0,
      _limit: 10
    }
  },
  unenrolledMfaUsersTable: {
    users: [],
    usersPageOffset: 0,
    totalUsersItems: 0,
    totalUsersPages: 0,
    queryParams: {
      _offset: 0,
      _limit: 10
    }
  },
  inactiveUsersTable: {
    users: [],
    usersPageOffset: 0,
    totalUsersItems: 0,
    totalUsersPages: 0,
    queryParams: {
      _offset: 0,
      _limit: 10,
      _maxInactiveSeconds: 7776000
    }
  }
};
const reducers = {
  setSecurityCenterState: typeReducerForKey('securityCenterState'),
  resetSecurityCenterState: resetStateByKey('securityCenterState', {
    securityCenterState
  }),
  setSecurityCenterStateLoader: loadersReducerForKey('securityCenterState'),
  setSecurityCenterStateError: errorsReducerForKey('securityCenterState')
};
const actions = {
  loadRecommendations: createAction(`${authStoreName}/loadRecommendations`),
  loadInsights: createAction(`${authStoreName}/loadInsights`),
  sendResetBreachedPasswordEmail: createAction(`${authStoreName}/sendResetBreachedPasswordEmail`, payload => ({
    payload
  })),
  sendBulkResetBreachedPasswordEmails: createAction(`${authStoreName}/sendBulkResetBreachedPasswordEmails`, payload => ({
    payload
  })),
  loadBreachedPasswordUsers: createAction(`${authStoreName}/loadBreachedPasswordUsers`, payload => ({
    payload
  })),
  loadUnenrolledMfaUsers: createAction(`${authStoreName}/loadUnenrolledMfaUsers`, payload => ({
    payload
  })),
  loadInactiveUsers: createAction(`${authStoreName}/loadInactiveUsers`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { securityCenterState, reducers as securityCenterReducers, actions as securityCenterActions };