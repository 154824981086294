export let PaymentProvider;
(function (PaymentProvider) {
  PaymentProvider["STRIPE"] = "Stripe";
})(PaymentProvider || (PaymentProvider = {}));
export let SubscriptionStatus;
(function (SubscriptionStatus) {
  SubscriptionStatus["ACTIVE"] = "ACTIVE";
  SubscriptionStatus["CANCELED"] = "CANCELED";
  SubscriptionStatus["INCOMPLETE"] = "INCOMPLETE";
  SubscriptionStatus["EXPIRED"] = "EXPIRED";
  SubscriptionStatus["TRIALING"] = "TRIALING";
})(SubscriptionStatus || (SubscriptionStatus = {}));
export let SubscriptionCancellationPolicy;
(function (SubscriptionCancellationPolicy) {
  SubscriptionCancellationPolicy["AT_PERIOD_END"] = "atPeriodEnd";
})(SubscriptionCancellationPolicy || (SubscriptionCancellationPolicy = {}));