<ng-container *ngIf="!errorMovingProject">
    <div class="modal-header">
        <h1 class="modal-title pull-left">Move Building</h1>
        <button data-dismiss="modal" aria-label="Close" class="close pull-right" type="button"
            (click)="moveModelRef.hide()">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Projects<sup>*</sup></label>
            <select class="form-control select-control" [(ngModel)]="selectedSiteIdForMove">
                <option style="display:none" [ngValue]="null">Select Project</option>
                <option *ngFor="let item of projects" [ngValue]="item.projectId"
                    [disabled]="currentProject?.projectId === item.projectId"
                    [hidden]="currentProject?.projectId === item.projectId || item.isArchived">{{item.projectName}}
                </option>
            </select>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="moveModelRef.hide()">Cancel</button>
        <button type="button" class="btn btn-primary m-0"
            [ngStyle]="{'cursor': (!selectedSiteIdForMove) ? 'not-allowed': 'pointer'}"
            [disabled]="!selectedSiteIdForMove" (click)="moveBuidlingToOtherProject()">Move</button>

    </div>
</ng-container>


<ng-container *ngIf="errorMovingProject">
    <div class="modal-header">
        <h1 class="modal-title pull-left">Cannot Move Building</h1>
        <button data-dismiss="modal" aria-label="Close" class="close pull-right" type="button"
            (click)="moveModelRef.hide()">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body">
        <p>The Building <strong>{{errorMoveBuilding.buildingName}}</strong> cannot be moved from Project
            <strong>{{errorMoveBuilding.sourceProjectName}}</strong> to
            <strong>{{errorMoveBuilding.targetProjectName}}</strong> due to conflicts on the following Plans:
        </p>
        <ul>
            <li *ngFor="let floorName of errorMoveBuilding.floorName">
                {{floorName}}
            </li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal"
            (click)="moveModelRef.hide()">Cancel</button>
    </div>
</ng-container>