import { call, put, select } from 'redux-saga/effects';
import { api, ContextHolder } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { getMfaStepForEnrolledUsers } from '../LoginState/utils';
import { MFAStep } from '../MfaState/interfaces';
import { afterStepUpAuthNavigation } from '../LoginState/sagas';
import { afterAuthenticationStateUpdate } from '../LoginState/saga.utils';
import { SHOULD_STEP_UP_KEY } from './consts';

/**
 * Error returned from the BE when the user is not enrolled to MFA and logged in with email magic code/link
 */
const MFA_IS_NOT_ENROLLED_ERROR = 'MFA is not enrolled';

/**
 * @param error API error
 * @returns true when the error is MFA is not enrolled error
 */
const isMfaIsNotEnrolledError = error => (error == null ? void 0 : error['message']) === MFA_IS_NOT_ENROLLED_ERROR;

/**
 * Handle an edge case when the user should NOT step up, so the response including the generated stepped-up jwt
 * Relevant for scenarios like login with magic code, no other mfa enrolled, allowed to skip mfa and max age is valid
 * @param generateResponse
 */
function* handleNoNeedToStepUpFlow(generateResponse) {
  yield call(afterAuthenticationStateUpdate, generateResponse);
  yield call(afterStepUpAuthNavigation);
}

/**
 * Handle the common scenario when the user should step up by the mfa token and devices returned from the generate req
 * @param generateStepUpSessionResponse
 */
function* handleNeedToStepUpFlow({
  mfaToken,
  mfaDevices
}) {
  yield put(actions.setStepUpState({
    mfaDevices,
    mfaToken
  }));

  // to handle scenarios when sms, email or authenticator are the only options - show immediately the enrolled one
  const step = yield call(getMfaStepForEnrolledUsers, mfaDevices);
  yield put(actions.setMfaState({
    step
  }));
}

/**
 * Handle step up generation request error
 * @param error
 */
function* handleError(error) {
  if (isMfaIsNotEnrolledError(error)) {
    yield put(actions.setMfaState({
      step: MFAStep.noMFAEnrolledStepUp
    }));
    return;
  }
  window.localStorage.setItem(SHOULD_STEP_UP_KEY, 'true');
  const {
    routes
  } = yield select(state => state.auth);
  ContextHolder.onRedirectTo(routes.logoutUrl, {
    preserveQueryParams: true
  });
}

/**
 * Generate step up session
 * Logout on error and redirecting to the login page
 * @param payload.maxAge - The max age of the session in seconds
 * @param payload.callback - The callback function to be called after the request is done
 */
export function* generateStepUpSession({
  payload: {
    maxAge,
    callback
  }
}) {
  yield put(actions.setStepUpState({
    mfaDevices: undefined,
    mfaToken: ''
  }));
  try {
    const response = yield call(api.auth.generateStepupSession, {
      maxAge
    });
    if (response['user']) {
      // got stepped up jwt, no need to continue step up by a second factor
      yield call(handleNoNeedToStepUpFlow, response);
    } else {
      // regular step up flow
      yield call(handleNeedToStepUpFlow, response);
    }
    callback == null ? void 0 : callback(true);
  } catch (e) {
    callback == null ? void 0 : callback(false);
    yield call(handleError, e);
  }
}