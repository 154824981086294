import _extends from "@babel/runtime/helpers/esm/extends";
import { vendorStoreName } from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import { vendorActions, vendorReducers } from './VendorState';
export const initialState = {
  loading: false
};
const {
  reducer,
  actions: sliceActions
} = createSlice({
  name: vendorStoreName,
  initialState,
  reducers: _extends({}, vendorReducers)
});
const actions = _extends({}, sliceActions, vendorActions);
export { reducer, actions };