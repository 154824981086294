import { Get, Post, Put } from '../fetch';
import { urls } from '../constants';
export async function getNotifications(params) {
  return Get(urls.notifications.v1, {
    params
  });
}
export async function updateNotificationStatus(params) {
  return Put(`${urls.notifications.v1}/status`, {
    params
  });
}
export async function updateNotificationIsPinned(params) {
  const {
    notificationId,
    pinStatus
  } = params;
  return Put(`${urls.notifications.v1}/${pinStatus}`, {
    notificationId
  });
}
export async function markAllAsRead() {
  return Post(`${urls.notifications.v1}/status/mark-all-read`);
}