import _extends from "@babel/runtime/helpers/esm/extends";
import { connectivityStoreName } from '../constants';
import { createSlice } from '@reduxjs/toolkit';
import { connectivityActions, connectivityReducers } from './ConnectivityState';
export const initialState = {
  isLoading: false,
  isSaving: false,
  list: [],
  processIds: [],
  slackChannels: {
    isLoading: false
  }
};
const {
  reducer,
  actions: sliceActions
} = createSlice({
  name: connectivityStoreName,
  initialState,
  reducers: _extends({}, connectivityReducers)
});
const actions = _extends({}, sliceActions, connectivityActions);
export { reducer, actions };