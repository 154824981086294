import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { ResetPhoneNumberStep } from './interfaces';
import { authStoreName } from '../../constants';
const resetPhoneNumberState = {
  step: ResetPhoneNumberStep.ResetPhoneNumber,
  loading: false
};
const reducers = {
  setResetPhoneNumberState: typeReducerForKey('resetPhoneNumberState'),
  resetResetPhoneNumberState: resetStateByKey('resetPhoneNumberState', {
    resetPhoneNumberState
  })
};
const actions = {
  resetPhoneNumber: createAction(`${authStoreName}/resetPhoneNumber`, payload => ({
    payload
  })),
  verifyResetPhoneNumber: createAction(`${authStoreName}/verifyResetPhoneNumber`, payload => ({
    payload
  })),
  changePhoneNumber: createAction(`${authStoreName}/changePhoneNumber`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { resetPhoneNumberState, reducers as resetPhoneNumberReducers, actions as resetPhoneNumberActions };