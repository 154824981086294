import { CustomerService, MessageService } from '@SiteOwl/core';
import { Component, EventEmitter } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-edit-responsible-integrator',
  templateUrl: './edit-responsible-integrator.component.html',
  styleUrls: ['./edit-responsible-integrator.component.scss'],
})
export class EditResponsibleIntegratorComponent {
  responsibleIntegratorId: any = null;
  providerDP: any = [];
  responsibleIntegratorNewId: any;
  customerDetail: any
  responsibleIntegratorName!: any
  public save: EventEmitter<any> = new EventEmitter();
  public close: EventEmitter<any> = new EventEmitter();
  constructor(
    private customerService: CustomerService, public bsModalRef: BsModalRef,
    private messageService: MessageService,) { }

  closeModal() {
    this.bsModalRef.hide();
  }

  setSelectedStatus(id: any) {
    this.responsibleIntegratorNewId = parseInt(id);
  }

  responsibleIntergratorSave(isSave: any) {
    if (isSave) {
      this.responsibleIntegratorId = this.responsibleIntegratorNewId === null ? 0 : _.cloneDeep(this.responsibleIntegratorNewId);
      if (this.responsibleIntegratorId>0) {
        this.responsibleIntegratorName = _.cloneDeep(this.providerDP.filter((x: any) => x.id === this.responsibleIntegratorId)[0].name);
      } else {
        this.responsibleIntegratorId = 0
        this.responsibleIntegratorName = _.cloneDeep('None');
      }
      this.customerService.setConfiguration(this.customerDetail.id, 'responsibleIntegratorId', this.responsibleIntegratorId).subscribe({
        next: () => {
          this.save.emit({ responsibleIntegratorName: this.responsibleIntegratorName, responsibleIntegratorId: this.responsibleIntegratorId })
          this.messageService.successMessage('Account: ' + this.customerDetail.name, 'Saved successfully');
          this.bsModalRef.hide();
        }
      })
    } else {
      this.close.emit()
      this.bsModalRef.hide();
    }
  }

}
