import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey, loadersReducerForKey, errorsReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const apiTokensState = {
  apiTokenType: null,
  searchValue: '',
  showAddTokenDialog: false,
  createdByUserIdColumn: 'show',
  deleteTokenDialog: {
    open: false,
    clientId: ''
  },
  successDialog: {
    open: false,
    secret: '',
    clientId: ''
  },
  loaders: {},
  apiTokensDataTenant: [],
  apiTokensDataUser: [],
  roles: [],
  permissions: [],
  errors: {}
};
const reducers = {
  setApiTokensLoader: loadersReducerForKey('apiTokensState'),
  setApiTokensError: errorsReducerForKey('apiTokensState'),
  setApiTokensState: typeReducerForKey('apiTokensState'),
  resetApiTokensState: resetStateByKey('apiTokensState', {
    apiTokensState
  })
};
const actions = {
  loadApiTokens: createAction(`${authStoreName}/loadApiTokens`, payload => ({
    payload
  })),
  loadUserApiTokens: createAction(`${authStoreName}/loadUserApiTokens`, payload => ({
    payload
  })),
  loadTenantApiTokens: createAction(`${authStoreName}/loadTenantApiTokens`, payload => ({
    payload
  })),
  initApiTokensData: createAction(`${authStoreName}/initApiTokensData`, payload => ({
    payload
  })),
  addTenantApiToken: createAction(`${authStoreName}/addTenantApiToken`, payload => ({
    payload
  })),
  addUserApiToken: createAction(`${authStoreName}/addUserApiToken`, payload => ({
    payload
  })),
  deleteUserApiToken: createAction(`${authStoreName}/deleteUserApiToken`, payload => ({
    payload
  })),
  deleteTenantApiToken: createAction(`${authStoreName}/deleteTenantApiToken`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { apiTokensState, reducers as apiTokensReducers, actions as apiTokensActions };