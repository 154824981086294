import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Injector, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Customer } from '@SiteOwl/core';

@Pipe({
  name: 'filterActive',
  pure: false
})
export class FilterActivePipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): Customer[] | [] {
    return value.filter((x: any) => x.isActive);
  }
}
