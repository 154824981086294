"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shouldContinue = exports.getResult = void 0;
const types_1 = require("../types");
function getResult(entitlementResults) {
  let hasExpired = false;
  for (const entitlementResult of entitlementResults) {
    if (entitlementResult.isEntitled) {
      return entitlementResult;
    }
    if (entitlementResult.justification === types_1.NotEntitledJustification.BUNDLE_EXPIRED) {
      hasExpired = true;
    }
  }
  return {
    isEntitled: false,
    justification: hasExpired ? types_1.NotEntitledJustification.BUNDLE_EXPIRED : types_1.NotEntitledJustification.MISSING_FEATURE
  };
}
exports.getResult = getResult;
function shouldContinue(entitlementResults) {
  return entitlementResults.every(({
    isEntitled
  }) => !isEntitled);
}
exports.shouldContinue = shouldContinue;
