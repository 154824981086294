import { ISubscriptionStatus, ProviderType } from '@frontegg/rest-api';
import { SubscriptionCancellationPolicy, SubscriptionStatus } from './general.interfaces';
export function toApiPaymentProviderType(paymentProvider) {
  return ProviderType.Stripe;
}
export function toSubscriptionCancellation({
  policy
}) {
  return {
    policy: toSubscriptionCancellationPolicy(policy)
  };
}
export function toSubscriptionCancellationPolicy(policy) {
  return SubscriptionCancellationPolicy.AT_PERIOD_END;
}
export function toSubscriptionStatus(status) {
  switch (status) {
    case ISubscriptionStatus.ACTIVE:
      return SubscriptionStatus.ACTIVE;
    case ISubscriptionStatus.INCOMPLETE:
      return SubscriptionStatus.INCOMPLETE;
    case ISubscriptionStatus.CANCELED:
      return SubscriptionStatus.CANCELED;
    case ISubscriptionStatus.EXPIRED:
      return SubscriptionStatus.EXPIRED;
    case ISubscriptionStatus.TRIALING:
      return SubscriptionStatus.TRIALING;
    default:
      return SubscriptionStatus.EXPIRED;
  }
}