import { dialogStateForKey, dialogStateForKeyWithoutPayload, resetStateByKey, typeReducerForKey } from '../utils';
const groupsDialogsState = {
  addGroupDialogState: {
    loading: false,
    open: false
  },
  editGroupDialogState: {
    loading: false,
    open: false
  },
  deleteGroupDialogState: {
    loading: false,
    open: false
  },
  addMembersToGroupDialogState: {
    loading: false,
    open: false,
    userIds: []
  },
  deleteMemberFromGroupDialogState: {
    loading: false,
    open: false
  }
};
const reducers = {
  setGroupsDialogsState: typeReducerForKey('groupsDialogsState'),
  resetGroupsDialogsState: resetStateByKey('groupsState', {
    groupsDialogsState
  }),
  // Add group dialog reducers
  openAddGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'addGroupDialogState', {
    open: true
  }),
  closeAddGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'addGroupDialogState', {
    open: false
  }),
  // Edit group dialog reducers
  openEditGroupDialog: dialogStateForKey('groupsDialogsState', 'editGroupDialogState', {
    open: true
  }),
  closeEditGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'editGroupDialogState', {
    open: false,
    loading: false,
    group: undefined
  }),
  // Delete group dialog reducers
  openDeleteGroupDialog: dialogStateForKey('groupsDialogsState', 'deleteGroupDialogState', {
    open: true
  }),
  closeDeleteGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'deleteGroupDialogState', {
    open: false,
    loading: false,
    group: undefined
  }),
  // Add members to group dialog reducers
  openAddMembersToGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'addMembersToGroupDialogState', {
    open: true
  }),
  closeAddMembersToGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'addMembersToGroupDialogState', {
    open: false,
    userIds: []
  }),
  // Delete member from group dialog reducers
  openDeleteMemberFromGroupDialog: dialogStateForKey('groupsDialogsState', 'deleteMemberFromGroupDialogState', {
    open: true
  }),
  closeDeleteMemberFromGroupDialog: dialogStateForKeyWithoutPayload('groupsDialogsState', 'deleteMemberFromGroupDialogState', {
    open: false,
    userId: undefined
  })
};
const actions = {};
// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { groupsDialogsState, reducers as groupsDialogsReducers, actions as groupsDialogsActions };