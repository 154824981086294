/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as _ from "lodash";
import { CommonService, DBDevicePinSizeValue, ListService, Utilities } from '@SiteOwl/core';


@Component({
  selector: 'so-report-config-column',
  templateUrl: './report-config-column.component.html',
  styleUrls: ['./report-config-column.component.scss'],
})
export class ReportConfigColumnComponent implements OnInit {
  isShowLoader = true;
  downloadData!: any;
  isProject!: any;
  headerName!: any;
  id!: any
  reportName!:any;
  private downloadEvent: EventEmitter<any> = new EventEmitter();
  private backEvent: EventEmitter<any> = new EventEmitter();

  private closeAllModalEvent: EventEmitter<any> = new EventEmitter();

  reportConfigFieldForm!: FormGroup<any>;
  data!: any;
  copyDefaultValue = [];
  backData!: any;
  selectedCheckBox = 0;
  columnList: any = [];
  configColumn: any;
  columnConfig: any;
  constructor(public bsModalRef: BsModalRef, private listService: ListService) { }
  ngOnInit() {
    this.backData = this.columnConfig;
    if (this.backData && this.backData.length > 0) {
      this.columnList = this.backData;
      const tempArray: any = [];
      this.backData.forEach((res: any) => {
        res.value.forEach((val: any) => {
          tempArray.push(val);
        })
        this.selectedCheckBox += res.value.filter((a: any) => a.isDefault).length;
      })
      this.getConfigColumnsList();
      this.createForm(tempArray);
    }
    else {
      this.listService.getConfigDeviceFields().subscribe((result: any) => {
        if (this.isProject) {
          if (result.projectReportField != null) {
            this.configColumn = this.splitString(result.projectReportField);
          }
        } else {
          if (result.liveSiteReportField != null) {
            this.configColumn = this.splitString(result.liveSiteReportField);
          }
        }
        this.getConfigColumnsList();
      })
    }
  }
  closeModal() {
    this.closeAllModalEvent.emit();
  }

  createForm(param: any) {
    const obj: any = {};
    param.forEach((deviceField: any) => {
      obj[deviceField.name] = new FormControl({ value: deviceField.isDefault, disabled: false });
    });
    this.reportConfigFieldForm = new FormGroup(obj);
  }
  getConfigColumnsList() {
    this.data = [];
    this.listService.getPrintOutConfigurationColumn(this.isProject).subscribe((response: any) => {
      this.isShowLoader = false
      if (response) {
        this.data = _.cloneDeep(response);
        this.copyDefaultValue = _.cloneDeep(response);
        if (this.backData === undefined || this.backData.length === 0) this.prepareData();
      }
    })
  }
  setNoneColumns() {
    this.selectedCheckBox = 0;
    this.copyDefaultValue.forEach((item: any) => {
      if (!this.isProject && item.name == 'Name') {
        item.name = 'Device Name';
      }
      this.reportConfigFieldForm.controls[item.name].setValue(false);
    });
    this.columnList.forEach((res: any) => {
      res.value.forEach((val: any) => {
        val.isDefault = false;
      })
      this.selectedCheckBox += _.cloneDeep(res.value).filter((a: any) => a.isDefault).length;
    });
  }

  setDefaultColumns() {
    this.selectedCheckBox = 0;
    this.columnList.forEach((res: any) => {
      this.copyDefaultValue.forEach((item: any) => {
        if (!this.isProject && item.name == 'Name') {
          item.name = 'Device Name';
        }
        this.reportConfigFieldForm.controls[item.name].setValue(item.isDefault);
        res.value.forEach((val: any) => {
          if (val.name === item.name) {
            val.isDefault = item.isDefault;
          }
        })
      });
      this.selectedCheckBox += _.cloneDeep(res.value).filter((a: any) => a.isDefault).length;
    });
  }
  splitString(configColumnString: any) {
    const re = /\|/;
    const list = configColumnString.split(re);
    return list;
  }
  prepareData() {
    const group = _.groupBy(this.data, (item) => { return item.categories; });
    Object.keys(group).forEach((res: any) => {
      this.columnList.push({ key: res, value: [] })
    })
    this.data.forEach((val: any) => {
      this.columnList.forEach((res: any) => {
        if (val.categories === res.key) {
          if (!this.isProject && val.name == 'Name') {
            val.name = 'Device Name';
          }
          val['displayName'] = val.name;
          res.value.push(val);
        }
      })
    })
    this.createForm(this.data);

    this.columnList.forEach((res: any) => {
      this.data.forEach((item: any) => {
        if (this.configColumn) {
          if (this.configColumn.some((x: any) => x === item.name)) {
            this.reportConfigFieldForm.controls[item.name].setValue(true);
          } else {
            this.reportConfigFieldForm.controls[item.name].setValue(false);
          }
          res.value.forEach((e: any) => {
            if (this.configColumn.some((x: any) => x === e.name)) {
              e.isDefault = true;
            } else {
              e.isDefault = false;
            }
          })
        }
      })
      this.selectedCheckBox += res.value.filter((a: any) => a.isDefault).length;
    })
  }
  onChange(item: any, event: any) {
    if (this.selectedCheckBox > 7 && event.target.checked) {
      event.target.checked = false;
      this.reportConfigFieldForm.controls[item.displayName].setValue(false);
      return;
    }
    this.selectedCheckBox = 0;

    this.columnList.forEach((res: any) => {
      res.value.forEach((val: any) => {
        if (val.name === item.name) {
          val.isDefault = event.target.checked
        }
      })
      this.selectedCheckBox += res.value.filter((a: any) => a.isDefault).length;
    })
  }


  downloadPdf() {
    const tempObj: any = {};
    let str = '';

    for (const field in this.reportConfigFieldForm.value) {
      // eslint-disable-next-line no-prototype-builtins
      if (this.reportConfigFieldForm.value.hasOwnProperty(field) && this.reportConfigFieldForm.value[field]) {
        tempObj[field] = field;
        str = str ? `${str}|${field}` : field;
      }
    }
    this.backData = [];
    if (this.isProject) {
      this.downloadData.projectReportField = _.cloneDeep(str);
    } else {
      this.downloadData.liveSiteReportField = _.cloneDeep(str);
    }
    this.downloadData.equipmentSize = parseFloat(DBDevicePinSizeValue[this.downloadData.equipmentSize]);
    const filterDataDownload: any = Utilities.setDownloadData(this.downloadData);
    this.downloadData.viewingPreference = _.cloneDeep(filterDataDownload['viewingPreference']);
    this.downloadData.systemTypeFilter = _.cloneDeep(filterDataDownload['systemTypeFilterArr']);
    this.downloadData.deviceStatus = _.cloneDeep(filterDataDownload['deviceStatusFilterArr']);
    this.downloadData.installStatus = _.cloneDeep(filterDataDownload['installStatusArr']);
    this.downloadEvent.emit({ downloadData: this.downloadData, columnList: this.columnList });
  }

  backClick(e: any) {
    this.backData = this.columnList;
    this.backEvent.emit(this.backData);
  }
}
