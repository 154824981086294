import _extends from "@babel/runtime/helpers/esm/extends";
import { typeReducerForKey } from '../utils';
import { createAction } from '@reduxjs/toolkit';
import { auditsStoreName } from '../../constants';
const auditLogsState = {
  loading: true,
  pageOffset: 0,
  pageSize: 20,
  logs: [],
  sort: [],
  filter: [],
  totalPages: 0,
  isDownloadingCsv: false
};
const reducers = {
  setAuditLogsState: typeReducerForKey('auditLogsState'),
  resetAuditLogsState: state => _extends({}, state, {
    auditLogsState
  })
};
const actions = {
  exportAuditsCsv: createAction(`${auditsStoreName}/exportAuditsCsv`),
  loadAuditLogs: createAction(`${auditsStoreName}/loadAuditLogs`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { auditLogsState, reducers as auditLogsReducers, actions as auditLogsActions };