/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChipsOptions, ButtonEnableDisableService, Utilities, Pattern } from '@SiteOwl/core';
import { Component, OnInit, Renderer2, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'so-invite-provider-user',
    templateUrl: './invite-provider-user.component.html',
    styleUrls: ['./invite-provider-user.component.scss'],
})
export class InviteProviderUserComponent implements OnInit {
    providerRoles!: any[];
    nullValue: any = null;
    userDetail: any;
    inviteUserForm!: FormGroup<any>;
    isFormSubmitted = false;
    currentUser: any;
    isEdit!: boolean;
    private sub: any;
    isInvalidEmail = false;
    setData: any;
    chipsOption = ChipsOptions;
    roleSelection: any = 'provider_Staff Member';
    newIntegratorRoleSelection: any;
    techRole: any;
    businessRole!: any[];
    public event: EventEmitter<any> = new EventEmitter();

    constructor(
        public bsModalRef: BsModalRef,
        private fb: FormBuilder,
        public btnService: ButtonEnableDisableService,
        private renderer: Renderer2
    ) { }

    private createForm() {
        this.inviteUserForm = this.fb.group({
            email: [null],
            emails: [''],
            role: [11, [Validators.maxLength(80)]],
        });
        let tmpIntegrateRoleId: any = 11;
        if (!Utilities.isEmpty(this.userDetail.role) && this.userDetail.role !== undefined && this.userDetail.role.id > 0) {
            tmpIntegrateRoleId = this.userDetail.role.id
        }
        this.newIntegratorRoleSelection = this.providerRoles.find(x => x.id === tmpIntegrateRoleId).roleKey;
        if (this.isEdit) {
            this.inviteUserForm.controls['email'].setValue(this.userDetail.email);
            this.inviteUserForm.controls['role'].setValue(this.providerRoles.find(x => x.id === this.userDetail.role.id), { onlySelf: true });
        }
    }

  saveUser() {
      this.btnService.disable();
      const email = this.inviteUserForm.get('emails')?.value || [];
      this.isInvalidEmail = email.some((x: any) => this.toCheckInvalidInput(x));
      if (this.inviteUserForm.invalid || (!this.isEdit && email.length < 1) || this.isInvalidEmail) {
          this.isFormSubmitted = true;
          return;
      }
      const user = this.inviteUserForm.value;
      if (this.isEdit) {
          delete user.emails;
      }
      if (user.role && user.role === 11) {
          user.role = this.providerRoles.filter(x => x.id === user.role)[0];
      }
      this.btnService.enable();
      this.event.emit({ user: user })
      this.bsModalRef.hide();
  }
    ngOnInit(): void {
        this.providerRoles = Utilities.seperateRoleDescription(this.providerRoles);
        if (this.userDetail.role) {
            const selection = this.providerRoles.find(x => x.id === this.userDetail.role.id);
            if (selection) {
                this.roleSelection = selection.roleKey
            }
        }
        this.businessRole = _.cloneDeep(this.providerRoles.filter(e => e.roleKey !== 'provider_Technician'));
        this.techRole = _.cloneDeep(this.providerRoles.filter(e => e.roleKey === 'provider_Technician')[0]);
        this.createForm();
    }
    closeModal() {
        this.bsModalRef.hide();
    }
    onInputBlurred() {
        const data = this.inviteUserForm.get('emails')?.value;
        if (!Utilities.isNull(this.setData)) {
            const datas = this.inviteUserForm.get('emails')?.value;
            datas.splice(data.findIndex((x: any) => x === this.setData), 1);
            this.inviteUserForm.get('emails')?.setValue(data);
            this.setData = null;
        }
        this.isInvalidEmail = data.some((x: any) => this.toCheckInvalidInput(x));
        if (!this.isInvalidEmail) {
            this.btnService.enable();
        } else {
            this.btnService.disable();
        }
    }
    toCheckInvalidInput(item: any) {
        if (!item || item.match(Pattern.EMAIL)) {
            return false;
        } else {
            return true;
        }
    }

    toAddBorderOnInvaidSpanTag(data: any) {
        if (document.querySelectorAll('tag').length > 0) {
            for (let index = 0; index < document.querySelectorAll('tag').length; index++) {
                const element = document.querySelectorAll('tag')[index];
                if (this.toCheckInvalidInput(data)) {
                    this.renderer.setStyle(element, 'border-color', '#e00b0b');
                } else {
                    this.renderer.setStyle(element, 'border-color', '#003F77');
                }
            }
        }
    }

    editItem(item: any) {
        const timer = setTimeout(() => {
            this.setData = item;
            clearTimeout(timer);
        }, 100);
    }

    roleChanged(item?: any, type?: any): void {
        if (type === 'role') {
            this.inviteUserForm.value.role = item;
            this.inviteUserForm.controls['role'].setValue(this.providerRoles.find(x => x.id === item.id), { onlySelf: true });
            this.newIntegratorRoleSelection = this.providerRoles.find(x => x.id === item.id).roleKey;
        }
    }

    ngOnDestroy() {
        this.btnService.enable();
    }
}

