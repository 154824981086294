"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flatten = void 0;
function flatten(target, opts) {
  opts = opts || {};
  const delimiter = (opts === null || opts === void 0 ? void 0 : opts.delimiter) || '.';
  const maxDepth = opts === null || opts === void 0 ? void 0 : opts.maxDepth;
  const transformKey = (opts === null || opts === void 0 ? void 0 : opts.transformKey) || keyIdentity;
  const output = {};
  function step(object, prev, currentDepth) {
    currentDepth = currentDepth || 1;
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const isarray = (opts === null || opts === void 0 ? void 0 : opts.safe) && Array.isArray(value);
      const type = Object.prototype.toString.call(value);
      const isbuffer = isBuffer(value);
      const isobject = type === '[object Object]' || type === '[object Array]';
      const newKey = prev ? prev + delimiter + transformKey(key) : transformKey(key);
      if (!isarray && !isbuffer && isobject && Object.keys(value).length && (!(opts === null || opts === void 0 ? void 0 : opts.maxDepth) || maxDepth && currentDepth < maxDepth)) {
        return step(value, newKey, currentDepth + 1);
      }
      output[newKey] = value;
    });
  }
  step(target);
  return output;
}
exports.flatten = flatten;
function isBuffer(obj) {
  return obj && obj.constructor && typeof obj.constructor.isBuffer === 'function' && obj.constructor.isBuffer(obj);
}
function keyIdentity(key) {
  return key;
}
