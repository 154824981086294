import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey, typeReducerNestedKey } from '../utils';
import { ActivateAccountStep } from './interfaces';
import { authStoreName } from '../../constants';
const activateState = {
  step: ActivateAccountStep.activating,
  loading: false,
  resentEmail: false,
  activationStrategy: {
    loading: false
  }
};
const reducers = {
  setActivateState: typeReducerForKey('activateState'),
  resetActivateState: resetStateByKey('activateState', {
    activateState
  }),
  setActivateStrategyState: typeReducerNestedKey('activateState', 'activationStrategy')
};
const actions = {
  activateAccount: createAction(`${authStoreName}/activateAccount`, payload => ({
    payload
  })),
  preActivateAccount: createAction(`${authStoreName}/preActivateAccount`, payload => ({
    payload
  })),
  getActivateAccountStrategy: createAction(`${authStoreName}/getActivateAccountStrategy`, payload => ({
    payload
  })),
  resendActivationEmail: createAction(`${authStoreName}/resendActivationEmail`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { activateState, reducers as activateAccountReducers, actions as activateAccountActions };