<form [formGroup]="editBuildingForm" (ngSubmit)="startEditingBuilding()" [ngClass]="viewOnlyClass" novalidate>
    <div class="modal-header">
        <h1 class="modal-title pull-left">{{title}}</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body select-plan-modal">

        <div class="row">
            <div class="col-sm-11">
                <p>{{message}}</p>
            </div>
            <div class="col-sm-5">
                <div class="form-group">
                    <label>Site</label>
                    <select class="form-control" formControlName="site" name="roleName" (change)="siteChanged()">
                        <option [ngValue]="''">Select Site</option>
                        <option *ngFor="let site of sites" [ngValue]="site.id">{{site.name}}</option>
                    </select>
                    <so-control-messages class="select-plan-validation-block site-validation-block" [name]="'Site'"
                        [isFormSubmitted]="isFormSubmitted" [control]="$any(editBuildingForm).controls['site']">
                    </so-control-messages>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="form-group">
                    <label>&nbsp;</label>
                    <p>or <a (click)="createSite()" >Create New
                            Site</a></p>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="isPlan">
            <div class="col-sm-5">
                <div class="form-group">
                    <label>Building</label>
                    <select *ngIf="editBuildingForm.controls['site'].value!==''" class="form-control" formControlName="building" (change)="buildingChange()" name="roleName">
                        <option [ngValue]="''">Select Building</option>
                        <option *ngFor="let building of selectedSite.buildings" [ngValue]="building.id">
                            {{building.name}}
                        </option>
                    </select>
                    <so-control-messages class="select-plan-validation-block" [name]="'Building'"
                        [isFormSubmitted]="isFormSubmitted" [control]="$any(editBuildingForm).controls['building']">
                    </so-control-messages>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="form-group">
                    <label>&nbsp;</label>
                    <p (click)="addBuilding()"  [ngStyle]="{'opacity':editBuildingForm.controls['site'].value !== '' ? '1' : '0.5','cursor':editBuildingForm.controls['site'].value !== '' ? 'pointer' : 'not-allowed'}">
                        or <a
                            [ngStyle]="{'cursor':editBuildingForm.controls['site'].value !== '' ? 'pointer' : 'not-allowed'}"
                            >Create New
                            Building</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cancel</button>
        <button [disabled]="canGoNext" [ngStyle]="{'cursor': canGoNext ? 'not-allowed': 'pointer'}" type="submit"
            class="btn btn-primary m-0">Next</button>
    </div>
</form>