"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationEnum = void 0;
var OperationEnum;
(function (OperationEnum) {
  // String Operations
  OperationEnum["InList"] = "in_list";
  OperationEnum["StartsWith"] = "starts_with";
  OperationEnum["EndsWith"] = "ends_with";
  OperationEnum["Contains"] = "contains";
  OperationEnum["Matches"] = "matches";
  // Numeric Operations
  OperationEnum["Equal"] = "equal";
  OperationEnum["GreaterThan"] = "greater_than";
  OperationEnum["GreaterThanEqual"] = "greater_than_equal";
  OperationEnum["LesserThan"] = "lower_than";
  OperationEnum["LesserThanEqual"] = "lower_than_equal";
  OperationEnum["BetweenNumeric"] = "between_numeric";
  // Boolean Operations
  OperationEnum["Is"] = "is";
  // Date Operations
  OperationEnum["On"] = "on";
  OperationEnum["BetweenDate"] = "between_date";
  OperationEnum["OnOrAfter"] = "on_or_after";
  OperationEnum["OnOrBefore"] = "on_or_before";
})(OperationEnum = exports.OperationEnum || (exports.OperationEnum = {}));
