<ngx-charts-chart [view]="[width, height]" [showLegend]="legend" [legendOptions]="legendOptions"
  [activeEntries]="activeEntries" [animations]="animations" (legendLabelActivate)="onActivate($event, undefined, true)"
  (legendLabelDeactivate)="onDeactivate($event, undefined, true)" (legendLabelClick)="onClick($event)">
  <svg:g [attr.transform]="transform" class="bar-chart chart" [attr.width]="width">
    <!-- For Live Planning chart x-axis start-->
    <svg:g *ngIf="isLivePlanning">
      <svg:g *ngFor="let tick of results" class="tick" [attr.transform]="tickTransform(tick)">
        <title>{{ tick.name }}</title>
        <svg:text stroke-width="0.01" [attr.text-anchor]="textAnchor" [style.font-size]="'12px'">
          <svg:tspan x="0" dy="1.2em">
            {{ getXaxisLabel(tick.name,0)}}
          </svg:tspan>

          <svg:tspan x="0" dy="1.2em">{{ getXaxisLabel(tick.name,2)}}</svg:tspan>
        </svg:text>
      </svg:g>
    </svg:g>
    <!-- For Live Planning chart x-axis end-->

    <!-- For installation chart x-axis start-->
    <svg:g *ngIf="!isLivePlanning">
      <svg:g ngx-charts-x-axis *ngIf="xAxis" [xScale]="xScale" [dims]="dims" [showLabel]="showXAxisLabel"
        [labelText]="xAxisLabel" [trimTicks]="trimXAxisTicks" [rotateTicks]="rotateXAxisTicks"
        [maxTickLength]="maxXAxisTickLength" [tickFormatting]="xAxisTickFormatting" [ticks]="xAxisTicks"
        [xAxisOffset]="dataLabelMaxHeight.negative" (dimensionsChanged)="updateXAxisHeight($event)"></svg:g>
    </svg:g>
    <!-- For installation chart x-axis End-->
    <svg:g ngx-charts-y-axis *ngIf="yAxis" [yScale]="yScale" [dims]="dims" [yOrient]="Orientation.Left"
      [showGridLines]="showGridLines" [showLabel]="showYAxisLabel" [labelText]="yAxisLabel" [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength" [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    <svg:g ngx-charts-y-axis *ngIf="yAxis" [yScale]="yScale" [dims]="dims"  [yOrient]="Orientation.Right"
      [showGridLines]="showGridLines" [showLabel]="showYAxisLabel" [labelText]="yAxisLabel" [trimTicks]="trimYAxisTicks"
      [maxTickLength]="maxYAxisTickLength" [tickFormatting]="yAxisTickFormatting" [ticks]="yAxisTicks"
      (dimensionsChanged)="updateYAxisWidth($event)"></svg:g>
    <svg:g *ngFor="let group of results; let index = index; trackBy: trackBy" [@animationState]="'active'"
      [attr.transform]="groupTransform(group)">
      <svg:g ngx-charts-series-vertical [xScale]="xScale" [yScale]="yScale" [type]="BarChartType.Stacked"
        [activeEntries]="activeEntries" [colors]="colors" [series]="group.series" [dims]="dims" [gradient]="gradient"
        [tooltipDisabled]="tooltipDisabled" [tooltipTemplate]="tooltipTemplate" [showDataLabel]="showDataLabel"
        [dataLabelFormatting]="dataLabelFormatting" [seriesName]="group.name" [animations]="animations"
        [noBarWhenZero]="true" (select)="onClick($event, group)" (activate)="onActivate($event, group)"
        (deactivate)="onDeactivate($event, group)"
        (dataLabelHeightChanged)="onDataLabelMaxHeightChanged($event, index)" />
    </svg:g>
  </svg:g>
</ngx-charts-chart>
