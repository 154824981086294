import { ValidationService } from '@SiteOwl/core';
import { Component, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-edit-plan-on-listing',
  templateUrl: './edit-plan-on-listing.component.html',
  styleUrls: ['./edit-plan-on-listing.component.scss'],
})
export class EditPlanOnListingComponent {
  typeOfDetail: any;
  formValue: any;
  detailForm!: FormGroup;
  label: any;
  titleBaseOnType!: string;
  update = new EventEmitter();
  constructor(public bsModalRef: BsModalRef, private fb: FormBuilder) {

  }

  ngOnInit() {
    if (this.typeOfDetail === 'site') {
      this.titleBaseOnType = 'Rename Site';
    } else if (this.typeOfDetail === 'building') {
      this.titleBaseOnType = 'Rename Building';
    } else if (this.typeOfDetail === 'floor') {
      this.titleBaseOnType = 'Rename Plan';
    }
    this.label = this.typeOfDetail === 'site' ? 'Site Name' : this.typeOfDetail === 'building' ? 'Building Name' : 'Plan Name'
    this.detailForm = this.fb.group({
      name: [this.formValue.name, [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
      id: [this.formValue.id]
    });
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  renameDetail() {
    if (!this.detailForm.invalid) {
      this.update.emit({
        id: this.detailForm.controls['id'].value,
        name: this.detailForm.controls['name'].value
      })
    }
  }
}
