import { Metadata } from '@frontegg/types';

/**
 * To prevent XSS attack, this function check for gtm key validity
 * XSS attack may happen if values are injected by postman because we don't have validity check in the BE
 *
 * @param key gtm key
 * @returns true if gtm key is valid: starts with GTM- and contains letters and digits only
 */
var isValidGTMKey = function isValidGTMKey(key) {
  return /^GTM-[a-zA-Z0-9]+$/.test(key);
};

/**
 * load gtm scripts by using metadata gtm keys
 * @param fronteggAppName
 */
export var loadGTM = function loadGTM(fronteggAppName) {
  var _metadata$integration;
  var metadata = Metadata.getInstance(fronteggAppName);
  var gtmKeysConfig = metadata == null ? void 0 : (_metadata$integration = metadata.integrations) == null ? void 0 : _metadata$integration.gtm;
  gtmKeysConfig == null ? void 0 : gtmKeysConfig.filter(function (_ref) {
    var id = _ref.id,
      enabled = _ref.enabled;
    return enabled && isValidGTMKey(id);
  }).forEach(function (_ref2) {
    var id = _ref2.id;
    // Load GTM container script dynamically
    var script = document.createElement('script');
    script.innerHTML = "\n        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':\n        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],\n        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=\n        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);\n        })(window,document,'script','dataLayer','".concat(id, "');\n      ");
    document.body.appendChild(script);
  });
};