import { Get, Put } from "../fetch";
import { urls } from "../constants";
export async function getManagedSubscription(subscriptionId) {
  return Get(`${urls.subscriptions.managedSubscriptions.v1}/${subscriptionId}`);
}
export async function getManagedSubscriptions() {
  return Get(`${urls.subscriptions.managedSubscriptions.v1}`);
}
export async function cancelManagedSubscription(subscriptionId) {
  return Put(`${urls.subscriptions.managedSubscriptions.v1}/${subscriptionId}/cancellations/`, {});
}
export async function renewManagedSubscription(subscriptionId) {
  return Put(`${urls.subscriptions.managedSubscriptions.v1}/${subscriptionId}/renewals/`, {});
}
export async function updateManagedSubscription(subscriptionId, {
  paymentMethodId,
  planId
}) {
  return Put(`${urls.subscriptions.managedSubscriptions.v1}/${subscriptionId}`, {
    paymentMethodId,
    planId
  });
}