import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-preview-import-csv',
  templateUrl: './preview-import-csv.component.html',
  styleUrls: ['./preview-import-csv.component.scss'],
})
export class PreviewImportCsvComponent implements OnInit {

  previewData: any;
  isCompleted: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() { 
    if (this.isCompleted) {
      this.previewData.deviceImportErrorList = this.previewData.deviceImportErrorList.filter((x: any) => x.severity === 0);
    }
  }

  closeModal(event: any, isImport: boolean) {
      event.preventDefault();
      event.stopPropagation();
      this.event.emit(isImport);
      this.bsModalRef.hide();
  }

}

