/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'so-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnChanges {
  
  @Input() id!: string;
  @Input() name!: string;
  @Input() isChecked!: boolean;
  @Input() label = '&nbsp;';
  @Input() disabled = false;
  @Input() className: any = {};
  @Input() style: any = {};
  @Input() inputControlName!: FormControl;
  @Input() title!: string;
  @Input() parentClassName: any = {};
  @Input() showSubTitle = false;
  @Input() extraParams!: any;
  @Input() parentTitle: any;
  @Output() onChange = new EventEmitter();
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['extraParams']) {
      this.extraParams = changes['extraParams'].currentValue;
    }
  }

  _valueChanged($event: any) {
    this.onChange.emit($event);
  }
}
