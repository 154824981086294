import _extends from "@babel/runtime/helpers/esm/extends";
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { actions } from '../reducer';
import { api } from '@frontegg/rest-api';
import { delay } from '../utils';
import { errorHandler } from '../../utils';
function* saveAccountSettings({
  payload
}) {
  try {
    var _payload$callback;
    yield put(actions.setAccountSettingsState({
      loading: true
    }));
    const {
      accountSettingsState
    } = yield select(state => state.auth);
    const {
      address,
      timezone,
      dateFormat,
      timeFormat,
      currency,
      logo
    } = accountSettingsState;
    const body = yield call(api.accountSettings.updateSettings, _extends({
      address,
      timezone,
      dateFormat,
      timeFormat,
      currency,
      logo
    }, payload));
    yield put(actions.setAccountSettingsState(_extends({}, body, {
      loading: false
    })));
    (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, body);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setAccountSettingsState({
      loading: false,
      error: errorHandler(e)
    }));
    (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
  }
}
function* loadAccountSettings({
  payload
}) {
  yield put(actions.setAccountSettingsState({
    loading: !(payload != null && payload.silentLoading),
    error: null
  }));
  try {
    const body = yield call(api.accountSettings.getSettings);
    yield put(actions.setAccountSettingsState(_extends({}, body, {
      loading: false
    })));
  } catch (e) {
    yield put(actions.setAccountSettingsState({
      loading: false,
      error: errorHandler(e)
    }));
  }
}
export function* accountSettingsSaga() {
  yield takeLeading(actions.saveAccountSettings, saveAccountSettings);
  yield takeLeading(actions.loadAccountSettings, loadAccountSettings);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* saveAccountSettingsMock({
  payload
}) {
  var _payload$callback3;
  yield put(actions.setAccountSettingsState({
    loading: true
  }));
  yield delay();
  const {
    accountSettingsState
  } = yield select(state => state.auth);
  const {
    address,
    timezone,
    dateFormat,
    timeFormat,
    currency,
    logo
  } = accountSettingsState;
  const body = _extends({
    address,
    timezone,
    dateFormat,
    timeFormat,
    currency,
    logo
  }, payload);
  yield put(actions.setAccountSettingsState(_extends({}, body, {
    loading: false
  })));
  (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, body);
}
function* loadAccountSettingsMock({
  payload
}) {
  yield put(actions.setAccountSettingsState({
    loading: !(payload != null && payload.silentLoading),
    error: null
  }));
  yield delay();
  yield put(actions.setAccountSettingsState({
    address: 'Tel-aviv',
    timezone: 'Asia/Jerusalem',
    dateFormat: 'DD/MM/YYYY',
    timeFormat: 'HH:mm',
    currency: 'USD',
    loading: false
  }));
}
export function* accountSettingsSagaMock() {
  yield takeLeading(actions.saveAccountSettings, saveAccountSettingsMock);
  yield takeLeading(actions.loadAccountSettings, loadAccountSettingsMock);
}