import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const tenantsState = {
  tenants: [],
  subTenants: [],
  loading: true,
  tenantTree: null
};
const reducers = {
  setTenantsState: typeReducerForKey('tenantsState'),
  resetTenantsState: resetStateByKey('tenantsState', {
    tenantsState
  })
};
const actions = {
  switchTenant: createAction(`${authStoreName}/switchTenant`, payload => ({
    payload
  })),
  loadTenants: createAction(`${authStoreName}/loadTenants`, payload => ({
    payload
  })),
  loadSubTenants: createAction(`${authStoreName}/loadSubTenants`, payload => ({
    payload
  })),
  loadSubTenantsTree: createAction(`${authStoreName}/loadSubTenantsTree`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { tenantsState, reducers as tenantsReducers, actions as tenantsActions };