/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CreatePlanComponent } from './create-plan/create-plan.component';
import { CreateSiteComponent } from './create-site/create-site.component';
import { CreateBuildingComponent } from './create-building/create-building.component';
import { SelectSiteBuildingPopupComponent } from './select-site-building-popup/select-site-building-popup.component';

@Component({
  selector: 'so-add-new-popup',
  templateUrl: './add-new-popup.component.html',
  styleUrls: ['./add-new-popup.component.scss'],
})
export class AddNewPopupComponent {

  sites!: any;
  bsModalRefSite!: BsModalRef;

  bsModalRefBuilding!: BsModalRef;

  bsModalRefPlan!: BsModalRef;
  // public event: EventEmitter<any> = new EventEmitter();
  // public cancel: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalRef: BsModalRef, private modalService: BsModalService) { }

  _action(event: any) {
    if (event !== 'cancel') {
      if (event === 'site') {
        this.createSite();
      } else if (event === 'building') {
        this.createBuilding()
      }
      else if (event === 'plan') {
        this.createPlan();
      }
    }
    this.bsModalRef.hide();
  }
  createBuilding() {
    const initialState: ModalOptions = {
      initialState: {
        sites: this.sites,
        isBuilding: true,
        isPlan: false,
      }, ignoreBackdropClick: true, class: ''
    };
    this.bsModalRefBuilding = this.modalService.show(SelectSiteBuildingPopupComponent, initialState);

  }
  createSite() {
    const initialState: ModalOptions = {
      initialState: {}, ignoreBackdropClick: true, class: 'newplanM'
    };
    this.bsModalRefSite = this.modalService.show(CreateSiteComponent, initialState);

  }

  createPlan() {
    const initialState: ModalOptions = {
      initialState: {
        sites: this.sites,
        isBuilding: false,
        isPlan: true,
      }, ignoreBackdropClick: true, class: ''
    };
    this.bsModalRefPlan = this.modalService.show(SelectSiteBuildingPopupComponent, initialState);

  }
}
