/* eslint-disable @typescript-eslint/no-explicit-any */
import { CameraDeviceError, CommonService, EventEmitterType, EventService, Utilities } from '@SiteOwl/core';
import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-take-picture-from-camera',
  templateUrl: './take-picture-from-camera.component.html',
  styleUrls: ['./take-picture-from-camera.component.scss'],
})
export class TakePictureFromCameraComponent implements OnInit, OnDestroy {
  @ViewChild('cameraStream', { static: true }) cameraStream!: ElementRef;
  @ViewChild('cameraCanvas', { static: true }) cameraCanvas!: ElementRef;
  @ViewChild('cameraImage', { static: true }) cameraImage!: ElementRef;
  @ViewChild('defaultImage') defaultImage!: ElementRef;
  @ViewChild('fileName', { static: true }) fileName!: ElementRef;
  @ViewChild('fileUpload', { static: true }) fileUpload!: ElementRef;
  // @ViewChild(ImageEditorComponent) imageEditor: ImageEditorComponent;
  @Output() event: EventEmitter<any> = new EventEmitter();
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  isOpen!: any;
  readyImage: any = null;
  errorText: any = {};
  imageSrc: any = null;
  stream: any = null;
  equipmentDetails: any;
  imageStage: any;
  pixie?: any;
  showPixie = false;
  bsModalRef?: BsModalRef;
  atTimeOfError(error: any): void {
    this.isOpen = 'default';
    if (CameraDeviceError[error]) {
      this.errorText = CameraDeviceError[error];
    } else {
      this.errorText = CameraDeviceError['DevicesNotFoundError']
    }
  }

  constructor(
    private commonService: CommonService, private eventService: EventService,
    public Options: ModalOptions,
  ) {
    this.equipmentDetails = this.Options.initialState?.["equipmentDetails"];
    this.imageStage = this.Options.initialState?.["stage"];
    this.isOpen = this.Options.initialState?.["isOpen"];
  }

  ngOnInit() {
    this.show();
  }


  show() {
    this.isOpen = 'camera';
    this.readyImage = null
    // this.fileName.nativeElement.value = null;

    if (navigator.mediaDevices && !this.commonService.checkIsMobileDevice()) {
      navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false
      }).then((stream) => {
        this.isOpen = 'camera';
        this.cameraStream.nativeElement.srcObject = stream;
        this.stream = stream.getTracks()[0];
        if (this.cameraStream.nativeElement.paused)
          this.cameraStream.nativeElement.play();
      }).catch((err) => {
        this.atTimeOfError(err.name);
      });
    } else if (this.commonService.checkIsMobileDevice()) {
      this.isOpen = 'defaultImage';
      this.fileUpload.nativeElement.click();
    } else {
      this.atTimeOfError('BrowserError');
    }
  }

  takePicture(e: any) {
    this.eventService.broadcast(EventEmitterType.cameraOpen, {});
    e.preventDefault();
    const canvas = this.cameraCanvas.nativeElement;
    const context = canvas.getContext('2d');
    canvas.width = 568;
    canvas.height = 400;
    context.drawImage(this.cameraStream.nativeElement, 0, 0, 568, 400);
    const snap = canvas.toDataURL('image/png', 1);
    this.cameraImage.nativeElement.setAttribute('src', snap);
    if (canvas.toBlob) {
      canvas.toBlob((blob: any) => {
        const form = new File([blob], `picture-from-camera-${Math.round(Math.random() * 10000)}.png`);
        const fileObject = {
          fileData: form,
          size: form.size,
          type: form.type,
          name: form.name,
          extension: '.' + form.name.split('.')[form.name.split('.').length - 1].toLowerCase()
        };
        this.readyImage = {
          awsImageURL: snap,
          fileData: fileObject,
          fileName: '',
          isDeleted: false,
          isNewFile: true,
          stage: this.imageStage
        }
        const timeOut = setTimeout(() => {
          this.imageSrc = snap;
          this.isOpen = 'editImage';
          this.getImagePopupDetails()
          clearTimeout(timeOut)
        }, 0);
      }, 'image/png', 0.7);
    } else {
      const imgData = canvas.msToBlob("image/png");
      this.readyImage = {
        awsImageURL: snap,
        fileData: imgData,
        fileName: '',
        isDeleted: false,
        isNewFile: true,
        stage: this.imageStage
      }

      const timeOut = setTimeout(() => {
        this.imageSrc = snap;
        this.isOpen = 'editImage';
        this.getImagePopupDetails()
        clearTimeout(timeOut);
      }, 0);
    }
  }
  getImagePopupDetails() {
    const imgObj = {
      file: this.readyImage.fileData,
      caption: '',
      image: this.readyImage.awsImageURL,
      isDefaultViewOnly: false,
      canUpdate: true,
      isSliderShow: false,
      equipment: this.equipmentDetails,
      isFromCapture: true,
      isCanEdit: true,
      isFromFloorPlan: true,
      showImageEditorForBuilding: false,
      canResizePlanMarkup: true
    }
    this.event.emit(imgObj);
  }

  onchange(event: any): void {
    const target = event.target || event.srcElement;
    const file = target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const context: any = canvas.getContext('2d');
          context.drawImage(img, 0, 0, 568, 400);
          this.cameraImage.nativeElement.setAttribute('src', reader.result as string);
          const fileObject = {
            fileData: file,
            size: file.size,
            type: file.type,
            name: `picture-from-camera-${Math.round(Math.random() * 10000)}.png`,
            extension: '.png'
          };
          this.readyImage = {
            awsImageURL: reader.result,
            fileData: fileObject,
            fileName: '',
            isDeleted: false,
            isNewFile: true,
            stage: this.imageStage
          }

          const timeOut = setTimeout(() => {
            this.imageSrc = reader.result as string;
            this.isOpen = 'editImage';
            clearTimeout(timeOut);
          }, 0);
        };
      }
      reader.readAsDataURL(file);
    } else {
      this.dismiss();
    }
  }

  dismiss() {
    this.cancel.emit(null);
  }

  ngOnDestroy() {
    if (!Utilities.isNull(this.stream)) {
      this.stream.stop();
    }
  }
}
