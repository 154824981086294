import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"];
import { call, delay, put, takeEvery, select } from 'redux-saga/effects';
import { actions } from '../../reducer';
import { errorHandler } from '../../../utils';
import { api, GetUsersFilterPreset } from '@frontegg/rest-api';
import { SecurityCenterStateKeys } from './types';
import { getFeatureFlags } from '../../../helpers';
import { securityCenterBreachedPasswordUsersMock, securityCenterInactivityPasswordUsersMock, securityCenterInsightsMock, securityCenterRecommendationsMock, securityCenterUnenrolledMfaUsersMock } from '../../dummy';
/**
 * This function is used to wrap sagas of the security page.
 * This function returns function,
 * that execute the saga it gets as a parameter,
 * and after it execute loadRecommendations and loadInsights sagas.
 * We need to call loadRecommendations and loadInsights after all change in the security page,
 * in order to keep the recommendations and insights updated.
 * @param action - saga to execute
 */
export function securityCenterSagaWrapper(action) {
  return function* (props) {
    const [securityCenterFeatureFlag] = yield call(getFeatureFlags, ['security-center-admin-portal-major-version']);
    yield action(props);
    if (securityCenterFeatureFlag) {
      yield loadRecommendations();
      yield loadInsights();
    }
  };
}
export function* loadRecommendations() {
  const key = SecurityCenterStateKeys.RECOMMENDATIONS;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  try {
    var _scoring$score;
    const {
      recommendations,
      scoring
    } = yield call(api.securityCenter.getRecommendations);
    yield put(actions.setSecurityCenterState({
      recommendations: recommendations.items,
      score: (_scoring$score = scoring == null ? void 0 : scoring.score) != null ? _scoring$score : 0
    }));
  } catch (e) {
    yield put(actions.setSecurityCenterStateError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setSecurityCenterStateLoader({
      key,
      value: false
    }));
  }
}
export function* loadInsights() {
  const key = SecurityCenterStateKeys.INSIGHTS;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  try {
    const {
      insights
    } = yield call(api.securityCenter.getInsights);
    yield put(actions.setSecurityCenterState({
      insights: insights.items
    }));
  } catch (e) {
    yield put(actions.setSecurityCenterStateError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setSecurityCenterStateLoader({
      key,
      value: false
    }));
  }
}
function* sendResetBreachedPasswordEmail(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  const key = SecurityCenterStateKeys.SEND_BREACHED_PASSWORD_EMAIL;
  yield put(actions.setSecurityCenterStateError({
    key,
    value: false
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  try {
    yield call(api.auth.forgotPassword, payload);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSecurityCenterStateError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false, e);
  } finally {
    yield put(actions.setSecurityCenterStateLoader({
      key,
      value: false
    }));
  }
}
export function* sendBulkResetBreachedPasswordEmails({
  payload: {
    callback
  }
}) {
  const key = SecurityCenterStateKeys.SEND_BULK_RESET_BREACHED_PASSWORD_EMAILS;
  yield put(actions.setSecurityCenterStateError({
    key,
    value: false
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  try {
    yield call(api.users.sendResetBreachedPasswordEmails);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSecurityCenterStateError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setSecurityCenterStateLoader({
      key,
      value: false
    }));
  }
}

/**
 * This function gets users as we got from getUsersV3,
 * and returns the combined object of those users with their roles.
 * @param users - array of users
 */
export function* getUsersWithRoles(users) {
  const usersIds = users.map(user => user.id);
  const allRoles = yield call(api.roles.getRoles);
  const usersRoles = yield call(api.users.getUsersRoles, {
    ids: usersIds
  });
  const usersWithRoles = users.map(user => {
    var _usersRoles$find, _ref2;
    const userRolesIds = usersRoles == null ? void 0 : (_usersRoles$find = usersRoles.find(role => role.userId === user.id)) == null ? void 0 : _usersRoles$find.roleIds;
    return _extends({}, user, {
      roles: (_ref2 = userRolesIds == null ? void 0 : userRolesIds.map(roleId => {
        var _allRoles$find;
        return (_allRoles$find = allRoles == null ? void 0 : allRoles.find(role => role.id === roleId)) != null ? _allRoles$find : [];
      })) != null ? _ref2 : []
    });
  });
  return usersWithRoles;
}

/**
 * This function is doing the logic needed in order to display a table in a generic way.
 * It calls getUsersV3 based on the params, combined the response of users with roles,
 * and load it to the correct state by the updateStateKey.
 * @param key - key for loaders / errors
 * @param tableState - the state of the specific table
 * @param updateStateKey - the key of the specific table to be updated in the state
 * @param _offset - page offset
 * @param _limit - limit per page
 * @param _preset - preset to be send according to the table type
 * @param _email - email input as searched
 *
 */
export function* loadUsersTableSecurityCenter({
  key,
  tableState,
  updateStateKey,
  _offset: offset,
  _limit: limit,
  _preset,
  _email,
  _maxInactiveSeconds
}) {
  var _ref3, _tableState$queryPara, _ref4, _tableState$queryPara2;
  yield put(actions.setSecurityCenterStateError({
    key,
    value: false
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  const _offset = (_ref3 = offset != null ? offset : tableState == null ? void 0 : (_tableState$queryPara = tableState.queryParams) == null ? void 0 : _tableState$queryPara._offset) != null ? _ref3 : 0;
  const _limit = (_ref4 = limit != null ? limit : tableState == null ? void 0 : (_tableState$queryPara2 = tableState.queryParams) == null ? void 0 : _tableState$queryPara2._limit) != null ? _ref4 : 10;
  const tableStateQueryParams = _extends({
    _offset,
    _limit,
    _includeSubTenants: false,
    _preset,
    _maxInactiveSeconds
  }, !!_email && {
    _email
  });
  try {
    const {
      items: usersItems,
      _metadata: {
        totalItems,
        totalPages
      }
    } = yield call(api.users.getUsersV3, tableStateQueryParams);
    const partialTableObj = {
      totalUsersItems: totalItems,
      totalUsersPages: totalPages,
      usersPageOffset: _offset,
      queryParams: _extends({
        _offset,
        _limit,
        _maxInactiveSeconds
      }, !!_email && {
        _email
      })
    };
    if (usersItems.length) {
      const usersWithRoles = yield getUsersWithRoles(usersItems);
      yield put(actions.setSecurityCenterState({
        [updateStateKey]: _extends({
          users: usersWithRoles
        }, partialTableObj)
      }));
    } else {
      yield put(actions.setSecurityCenterState({
        [updateStateKey]: _extends({
          users: []
        }, partialTableObj)
      }));
    }
  } catch (e) {
    yield put(actions.setSecurityCenterStateError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setSecurityCenterStateLoader({
      key,
      value: false
    }));
  }
}
export function* loadBreachedPasswordUsers({
  payload
}) {
  var _select;
  const key = SecurityCenterStateKeys.BREACHED_PASSWORD_USERS_TABLE;
  const breachedPasswordUsersTable = yield (_select = select(state => {
    var _state$auth$securityC;
    return (_state$auth$securityC = state.auth.securityCenterState) == null ? void 0 : _state$auth$securityC.breachedPasswordUsersTable;
  })) != null ? _select : {};
  yield loadUsersTableSecurityCenter(_extends({
    key,
    updateStateKey: 'breachedPasswordUsersTable',
    _preset: GetUsersFilterPreset.BREACHED_PASSWORDS,
    tableState: breachedPasswordUsersTable
  }, payload));
}
export function* loadUnenrolledMfaUsers({
  payload
}) {
  var _select2;
  const key = SecurityCenterStateKeys.UNENROLLED_MFA_USERS_TABLE;
  const unenrolledMfaUsersTable = yield (_select2 = select(state => {
    var _state$auth$securityC2;
    return (_state$auth$securityC2 = state.auth.securityCenterState) == null ? void 0 : _state$auth$securityC2.unenrolledMfaUsersTable;
  })) != null ? _select2 : {};
  yield loadUsersTableSecurityCenter(_extends({
    key,
    updateStateKey: 'unenrolledMfaUsersTable',
    _preset: GetUsersFilterPreset.MFA_UNENROLLED,
    tableState: unenrolledMfaUsersTable
  }, payload));
}
export function* loadInactiveUsers({
  payload
}) {
  var _select3;
  const key = SecurityCenterStateKeys.INACTIVE_USERS_TABLE;
  const inactiveUsersTable = yield (_select3 = select(state => {
    var _state$auth$securityC3;
    return (_state$auth$securityC3 = state.auth.securityCenterState) == null ? void 0 : _state$auth$securityC3.inactiveUsersTable;
  })) != null ? _select3 : {};
  yield loadUsersTableSecurityCenter(_extends({
    key,
    updateStateKey: 'inactiveUsersTable',
    _preset: GetUsersFilterPreset.INACTIVE,
    tableState: inactiveUsersTable
  }, payload));
}
export function* securityCenterSagas() {
  yield takeEvery(actions.loadRecommendations, loadRecommendations);
  yield takeEvery(actions.loadInsights, loadInsights);
  yield takeEvery(actions.sendResetBreachedPasswordEmail, sendResetBreachedPasswordEmail);
  yield takeEvery(actions.sendBulkResetBreachedPasswordEmails, sendBulkResetBreachedPasswordEmails);
  yield takeEvery(actions.loadBreachedPasswordUsers, loadBreachedPasswordUsers);
  yield takeEvery(actions.loadUnenrolledMfaUsers, loadUnenrolledMfaUsers);
  yield takeEvery(actions.loadInactiveUsers, loadInactiveUsers);
}

//MOCK SAGAS

export function* loadRecommendationsMock() {
  const key = SecurityCenterStateKeys.RECOMMENDATIONS;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  yield delay(500);
  yield put(actions.setSecurityCenterState({
    recommendations: securityCenterRecommendationsMock.recommendations.items,
    score: securityCenterRecommendationsMock.scoring.score
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: false
  }));
}
export function* loadInsightsMock() {
  const key = SecurityCenterStateKeys.INSIGHTS;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  yield delay(500);
  yield put(actions.setSecurityCenterState({
    insights: securityCenterInsightsMock.insights.items
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: false
  }));
}
export function* loadBreachedPasswordUsersMock() {
  const key = SecurityCenterStateKeys.BREACHED_PASSWORD_USERS_TABLE;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  yield delay(500);
  yield put(actions.setSecurityCenterState({
    breachedPasswordUsersTable: securityCenterBreachedPasswordUsersMock
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: false
  }));
}
export function* loadUnenrolledMfaUsersMock() {
  const key = SecurityCenterStateKeys.UNENROLLED_MFA_USERS_TABLE;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  yield delay(500);
  yield put(actions.setSecurityCenterState({
    unenrolledMfaUsersTable: securityCenterUnenrolledMfaUsersMock
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: false
  }));
}
export function* loadInactiveUsersMock() {
  const key = SecurityCenterStateKeys.INACTIVE_USERS_TABLE;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  yield delay(500);
  yield put(actions.setSecurityCenterState({
    inactiveUsersTable: securityCenterInactivityPasswordUsersMock
  }));
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: false
  }));
}
function* sendResetBreachedPasswordEmailMock({
  payload: {
    callback
  }
}) {
  const key = SecurityCenterStateKeys.SEND_BREACHED_PASSWORD_EMAIL;
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: true
  }));
  yield delay(500);
  callback == null ? void 0 : callback(true);
  yield put(actions.setSecurityCenterStateLoader({
    key,
    value: false
  }));
}
export function* securityCenterSagasMock() {
  yield takeEvery(actions.loadRecommendations, loadRecommendationsMock);
  yield takeEvery(actions.loadInsights, loadInsightsMock);
  yield takeEvery(actions.loadBreachedPasswordUsers, loadBreachedPasswordUsersMock);
  yield takeEvery(actions.loadUnenrolledMfaUsers, loadUnenrolledMfaUsersMock);
  yield takeEvery(actions.loadInactiveUsers, loadInactiveUsersMock);
  yield takeEvery(actions.sendResetBreachedPasswordEmail, sendResetBreachedPasswordEmailMock);
}