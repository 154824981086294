import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const accountSettingsState = {
  loading: false
};
const reducers = {
  setAccountSettingsState: typeReducerForKey('accountSettingsState'),
  resetAccountSettingsState: resetStateByKey('accountSettingsState', {
    accountSettingsState
  })
};
const actions = {
  loadAccountSettings: createAction(`${authStoreName}/loadAccountSettings`, payload => ({
    payload
  })),
  saveAccountSettings: createAction(`${authStoreName}/saveAccountSettings`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { accountSettingsState, reducers as accountSettingsReducers, actions as accountSettingsActions };