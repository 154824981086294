<div *ngIf="showLoader">
    <so-loader [mainpageloader]='true'></so-loader>
</div>
<div class="form-group filename-block position-relative">
    <div>
        <div class="filename" *ngIf="showFileName && !fileUploadOptions.isMultiple && parent !=='device'">
            {{ (uploadedFiles?.name | wrap: 20) || 'Select File'}}
        </div>
        <div class="filename" *ngIf="fileUploadOptions.isMultiple &&  parent !== 'device'">{{'Select File(s)'}}
        </div>
        <input multiple type="file" class="fileUpload" #fileUpload style="display:none" (change)="onchange($event)"
            [accept]="acceptFiles" />
        <a *ngIf="showFileName && !isShowFileIcon && parent !== 'device'" class="upload-link" href="javascript:void(0);"
            title="Upload Image" (click)="openFileDialog()">{{textToDisplay?'Browse':'Replace'}}
        </a>
        <a *ngIf="!showFileName && !isShowFileIcon && parent !== 'device'" class="upload-link"
            href="javascript:void(0);" title="{{profileImage?'Upload Image':'Replace Image'}}"
            (click)="openFileDialogPDF('replaceImage')">
            <em class="fas fa-image"></em> {{textToDisplay?'Browse':'Replace Image'}}
        </a>
        <a *ngIf="isShowFileIcon" href="javascript:void(0);" title="Upload Image" [ngClass]="{'inline-upload-img': parent === 'device', 'btn-upload fas fa-cloud-upload-alt': parent !==
            'device'}" (click)="openFileDialog()">
        </a>
        <span class="text-danger multip-upload-tooltip" *ngIf="!hideFileValidationMessage && !containsInvalidFile  && parent !== 'device'" [hidden]="!errorMessage"
            [ngClass]="{'help-block': errorMessage}">{{errorMessage}}
        </span>
        <span class="text-danger multip-upload-tooltip" *ngIf="containsInvalidFile && parent !== 'device'"
            [hidden]="!errorMessage" [ngClass]="{'help-block': errorMessage}">{{errorMessage}}
        </span>
    </div>
</div>