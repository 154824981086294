"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RuleEvaluationResultEnum = exports.TreatmentEnum = exports.ConditionLogicEnum = void 0;
var ConditionLogicEnum;
(function (ConditionLogicEnum) {
  ConditionLogicEnum["And"] = "and";
})(ConditionLogicEnum = exports.ConditionLogicEnum || (exports.ConditionLogicEnum = {}));
var TreatmentEnum;
(function (TreatmentEnum) {
  TreatmentEnum["True"] = "true";
  TreatmentEnum["False"] = "false";
})(TreatmentEnum = exports.TreatmentEnum || (exports.TreatmentEnum = {}));
var RuleEvaluationResultEnum;
(function (RuleEvaluationResultEnum) {
  RuleEvaluationResultEnum["Treatable"] = "treatable";
  RuleEvaluationResultEnum["Insufficient"] = "insufficient";
})(RuleEvaluationResultEnum = exports.RuleEvaluationResultEnum || (exports.RuleEvaluationResultEnum = {}));
