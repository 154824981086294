import _extends from "@babel/runtime/helpers/esm/extends";
import { authActions } from '../auth';
import { configureStore, getDefaultMiddleware, combineReducers, createSlice } from '@reduxjs/toolkit';
import { createSagaMiddleware } from './redux-saga-tools';
import { all, call } from 'redux-saga/effects';
import { ContextHolder } from '@frontegg/rest-api';
import authStore from '../auth';
import auditsStore from '../audits';
import * as oldAuditsStore from '../audits/backward-compatibility';
import connectivityStore from '../connectivity';
import subscriptionsStore from '../subscriptions';
import vendorStore from '../vendor';
export * from './redux';
export { default as FronteggNativeModule } from './FronteggNativeModule';
export * from './redux-saga-tools';
export { bindActionCreators } from '@reduxjs/toolkit';
const initialState = {
  context: undefined,
  urlStrategy: 'path',
  previewMode: false,
  appName: 'default'
};
const {
  reducer: rootReducer
} = createSlice({
  name: 'root',
  initialState,
  reducers: {
    setContext: {
      prepare: context => ({
        payload: context
      }),
      reducer: (state, {
        payload
      }) => {
        ContextHolder.setContext(payload);
        return _extends({}, state, {
          context: payload
        });
      }
    }
  }
});
export const createFronteggStore = (rootInitialState, storeHolder, previewMode = false, authInitialState, overrideInitialState, builderMode = false, urlStrategy = 'path') => {
  const isSSR = typeof window === 'undefined';
  let holder = storeHolder;
  if (isSSR && storeHolder == null) {
    throw Error('createFronteggStore(initState, storeHolder) failed, storeHolder must not be null in Server-Side rendering');
  }
  if (!holder) {
    holder = window;
  }
  if (!holder.store) {
    var _overrideInitialState, _authInitialState$rou, _overrideInitialState2, _overrideInitialState3, _overrideInitialState4, _overrideInitialState5, _overrideInitialState6, _overrideInitialState7, _overrideInitialState8, _overrideInitialState9;
    if (!previewMode && !builderMode) {
      ContextHolder.setContext(rootInitialState.context);
    }
    const sagaMiddleware = createSagaMiddleware();
    const middleware = [...getDefaultMiddleware({
      thunk: false,
      immutableCheck: false,
      serializableCheck: false
    }), sagaMiddleware];
    holder.store = configureStore({
      middleware,
      preloadedState: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        root: _extends({}, rootInitialState, {
          previewMode,
          urlStrategy: urlStrategy
        }),
        [authStore.storeName]: _extends({}, authStore.initialState, authInitialState, (_overrideInitialState = overrideInitialState == null ? void 0 : overrideInitialState.auth) != null ? _overrideInitialState : {}, {
          routes: _extends({}, authStore.initialState.routes, (_authInitialState$rou = authInitialState == null ? void 0 : authInitialState.routes) != null ? _authInitialState$rou : {}, (_overrideInitialState2 = overrideInitialState == null ? void 0 : (_overrideInitialState3 = overrideInitialState.auth) == null ? void 0 : _overrideInitialState3.routes) != null ? _overrideInitialState2 : {})
        }),
        [auditsStore.storeName]: _extends({}, auditsStore.initialState, (_overrideInitialState4 = overrideInitialState == null ? void 0 : overrideInitialState.auditLogs) != null ? _overrideInitialState4 : {}, {
          auditLogsState: _extends({}, auditsStore.initialState.auditLogsState, (_overrideInitialState5 = overrideInitialState == null ? void 0 : (_overrideInitialState6 = overrideInitialState.auditLogs) == null ? void 0 : _overrideInitialState6.auditLogsState) != null ? _overrideInitialState5 : {}),
          auditsMetadataState: _extends({}, auditsStore.initialState.auditsMetadataState, (_overrideInitialState7 = overrideInitialState == null ? void 0 : (_overrideInitialState8 = overrideInitialState.auditLogs) == null ? void 0 : _overrideInitialState8.auditsMetadataState) != null ? _overrideInitialState7 : {})
        }),
        [oldAuditsStore.storeName]: _extends({}, oldAuditsStore.initialState, (_overrideInitialState9 = overrideInitialState == null ? void 0 : overrideInitialState.audits) != null ? _overrideInitialState9 : {}),
        [connectivityStore.storeName]: connectivityStore.initialState,
        [subscriptionsStore.storeName]: subscriptionsStore.initialState,
        [vendorStore.storeName]: vendorStore.initialState
      },
      reducer: combineReducers({
        root: rootReducer,
        [authStore.storeName]: authStore.reducer,
        [auditsStore.storeName]: auditsStore.reducer,
        [oldAuditsStore.storeName]: oldAuditsStore.reducer,
        [connectivityStore.storeName]: connectivityStore.reducer,
        [subscriptionsStore.storeName]: subscriptionsStore.reducer,
        [vendorStore.storeName]: vendorStore.reducer
      })
    });
    const rootSaga = function* () {
      yield all([call(authStore.sagas), call(auditsStore.sagas), call(oldAuditsStore.sagas), call(connectivityStore.sagas), call(subscriptionsStore.sagas), call(vendorStore.sagas)]);
    };
    const rootMockSaga = function* () {
      yield all([call(authStore.mockSagas), call(auditsStore.mockSagas), call(subscriptionsStore.mockSagas)]);
    };
    if (previewMode) {
      holder.store.destroy = sagaMiddleware.run(rootMockSaga).cancel;
      holder.store.dispatch(authActions.requestAuthorize(true));
    } else {
      holder.store.destroy = sagaMiddleware.run(rootSaga).cancel;
    }

    /**
     * Using redux store subscribe causes unpredictable callback calls: https://redux.js.org/api/store#subscribelistener
     *
     * This function aim to prevent unnecessary update by calling the callback with the updated frontegg state only on state reference change.
     * This function protect from redux unnecessary updates by reference comparison
     * Developed for vanilla js SDK
     *
     * @param callback called with the updated frontegg state for every store refresh
     * @returns redux unsubscribe function
     */
    holder.store.subscribeStateChanged = callback => {
      let lastStoreState = holder.store.getState();
      return holder.store.subscribe(() => {
        const updatedState = holder.store.getState();
        if (lastStoreState != updatedState) {
          lastStoreState = updatedState;
          callback(updatedState);
        }
      });
    };
  }
  return holder.store;
};