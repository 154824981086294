import { createAction } from '@reduxjs/toolkit';
import { impersonationStoreName } from '../../constants';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { ImpersonateStep } from './interfaces';
const impersonateState = {
  step: ImpersonateStep.impersonating,
  loading: false
};
const reducers = {
  setImpersonateState: typeReducerForKey('impersonateState'),
  resetImpersonateState: resetStateByKey('impersonateState', {
    impersonateState
  })
};
const actions = {
  impersonate: createAction(`${impersonationStoreName}/impersonate`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { impersonateState, reducers as impersonateReducers, actions as impersonateActions };