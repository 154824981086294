/* eslint-disable @typescript-eslint/no-explicit-any */
import { Utilities } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'providerUserfilter',
  pure: false
})
export class ProviderUserfilterPipe implements PipeTransform {
  transform(items: any[], filter: any): any[] {
      if (Utilities.isNull(items) || Utilities.isNull(filter)) {
          return items || [];
      }

      if (!Utilities.isEmpty(filter.user)) {
          items = items.filter(x => {

              if (Utilities.isEmpty(x.name) && !Utilities.isEmpty(x.email)) {
                  return x.email.toLowerCase().indexOf(filter.user.toLowerCase()) > -1;
              } else if (!Utilities.isEmpty(x.name) && Utilities.isEmpty(x.email)) {
                  return x.name.toLowerCase().indexOf(filter.user.toLowerCase()) > -1;
              } else {
                  return x.name.toLowerCase().indexOf(filter.user.toLowerCase()) > -1 ||
                      x.email.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
              }

          })
      }

      if (!Utilities.isEmpty(filter.customer)) {
          items = items.filter(x => {

              if (Utilities.isEmpty(x.customer.name) && !Utilities.isEmpty(x.customer.email)) {
                  return x.customer.email.toLowerCase().indexOf(filter.customer.toLowerCase()) > -1;
              } else if (!Utilities.isEmpty(x.customer.name) && Utilities.isEmpty(x.customer.email)) {
                  return x.customer.name.toLowerCase().indexOf(filter.customer.toLowerCase()) > -1;
              } else {
                  return x.customer.name.toLowerCase().indexOf(filter.customer.toLowerCase()) > -1 ||
                      x.customer.email.toLowerCase().indexOf(filter.customer.toLowerCase()) > -1
              }

          })
      }

      if (!Utilities.isEmpty(filter.roleName)) {
          items = items.filter(x => Utilities.isNull(x.role) ? x.amtRole.name.toLowerCase().indexOf(filter.roleName.toLowerCase()) > -1 : x.role.name.toLowerCase().indexOf(filter.roleName.toLowerCase()) > -1);
      }

      const noData = [{ name: 'No users found', hideButton: true }]
      return items.length === 0 ? noData : items;
  }
}
