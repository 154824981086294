import _extends from "@babel/runtime/helpers/esm/extends";
/* eslint-disable @typescript-eslint/no-explicit-any */
import { lighten, darken, rgbToHex } from './colorManipulator';
const generateMainColorObject = color => {
  if (color) {
    return {
      main: color,
      light: rgbToHex(lighten(color, 0.4)),
      dark: rgbToHex(darken(color, 0.15)),
      hover: rgbToHex(darken(color, 0.15)),
      active: rgbToHex(darken(color, 0.2))
    };
  }
};
const generateSubColorObject = color => {
  if (color) {
    return {
      main: color,
      light: lighten(color, 0.7),
      dark: darken(color, 0.2)
    };
  }
};
export const getPalette = (theme, defaultTheme) => {
  var _theme$palette, _theme$palette2, _theme$palette$primar, _theme$palette3, _theme$palette4, _theme$palette$second, _theme$palette5, _theme$palette6, _theme$palette7, _theme$palette8, _theme$palette9, _theme$palette10;
  if (!(theme != null && theme.palette) || typeof (theme == null ? void 0 : (_theme$palette = theme.palette) == null ? void 0 : _theme$palette.primary) !== 'string') {
    return {};
  }
  return _extends({}, defaultTheme, {
    palette: {
      primary: _extends({}, generateMainColorObject(theme == null ? void 0 : (_theme$palette2 = theme.palette) == null ? void 0 : _theme$palette2.primary), {
        contrastText: (_theme$palette$primar = theme == null ? void 0 : (_theme$palette3 = theme.palette) == null ? void 0 : _theme$palette3.primaryText) != null ? _theme$palette$primar : '#000000'
      }),
      secondary: _extends({}, generateMainColorObject(theme == null ? void 0 : (_theme$palette4 = theme.palette) == null ? void 0 : _theme$palette4.secondary), {
        contrastText: (_theme$palette$second = theme == null ? void 0 : (_theme$palette5 = theme.palette) == null ? void 0 : _theme$palette5.secondaryText) != null ? _theme$palette$second : '#000000'
      }),
      danger: _extends({}, generateMainColorObject(theme == null ? void 0 : (_theme$palette6 = theme.palette) == null ? void 0 : _theme$palette6.error), {
        contrastText: '#FFF'
      }),
      success: _extends({}, generateSubColorObject(theme == null ? void 0 : (_theme$palette7 = theme.palette) == null ? void 0 : _theme$palette7.success), {
        contrastText: '#FFF'
      }),
      error: _extends({}, generateSubColorObject(theme == null ? void 0 : (_theme$palette8 = theme.palette) == null ? void 0 : _theme$palette8.error), {
        contrastText: '#FFF'
      }),
      warning: _extends({}, generateSubColorObject(theme == null ? void 0 : (_theme$palette9 = theme.palette) == null ? void 0 : _theme$palette9.warning), {
        contrastText: '#FFF'
      }),
      info: _extends({}, generateSubColorObject(theme == null ? void 0 : (_theme$palette10 = theme.palette) == null ? void 0 : _theme$palette10.info), {
        contrastText: '#FFF'
      })
    }
  });
};