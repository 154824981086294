import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["callback"],
  _excluded2 = ["callback"],
  _excluded3 = ["callback", "phoneId"],
  _excluded4 = ["callback", "phoneId"],
  _excluded5 = ["callback"];
import { actions } from '../reducer';
import { api } from '@frontegg/rest-api';
import { call, takeEvery, put } from 'redux-saga/effects';
import { errorHandler } from '../../utils';
export function* loadPhoneNumbers() {
  yield put(actions.setSmsState({
    loading: true
  }));
  const queryParams = {
    _limit: 10,
    _offset: 0
  };
  try {
    const {
      items
    } = yield call(api.userPhoneNumbers.getUserPhoneNumbers, queryParams);
    yield put(actions.setSmsState({
      phoneNumbers: items
    }));
  } catch (e) {
    yield put(actions.setSmsState({
      error: errorHandler(e)
    }));
  } finally {
    yield put(actions.setSmsState({
      loading: false
    }));
  }
}
export function* setUpPhoneNumber(_ref) {
  let {
      payload: {
        callback
      }
    } = _ref,
    payload = _objectWithoutPropertiesLoose(_ref.payload, _excluded);
  yield put(actions.setSmsState({
    loading: true
  }));
  try {
    const res = yield call(api.userPhoneNumbers.createPhoneNumber, payload);
    yield put(actions.setSmsState({
      otcToken: res.otcToken
    }));
    yield call(loadPhoneNumbers);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSmsState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setSmsState({
      loading: false
    }));
  }
}
export function* verifyPhoneNumber(_ref2) {
  let {
      payload: {
        callback
      }
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setSmsState({
    loading: true
  }));
  try {
    yield call(api.userPhoneNumbers.verifyPhoneNumber, payload);
    yield call(loadPhoneNumbers);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSmsState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setSmsState({
      loading: false
    }));
  }
}
export function* deletePhoneNumber(_ref3) {
  let {
      payload: {
        callback,
        phoneId
      }
    } = _ref3,
    payload = _objectWithoutPropertiesLoose(_ref3.payload, _excluded3);
  yield put(actions.setSmsState({
    loading: true
  }));
  try {
    const res = yield call(api.userPhoneNumbers.deletePhoneNumber, phoneId);
    yield put(actions.setSmsState({
      otcToken: res.otcToken
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSmsState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setSmsState({
      loading: false
    }));
  }
}
export function* verifyDeletePhoneNumber(_ref4) {
  let {
      payload: {
        callback,
        phoneId
      }
    } = _ref4,
    payload = _objectWithoutPropertiesLoose(_ref4.payload, _excluded4);
  yield put(actions.setSmsState({
    loading: true
  }));
  try {
    yield call(api.userPhoneNumbers.verifyDeletePhoneNumber, phoneId, payload);
    yield call(loadPhoneNumbers);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSmsState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setSmsState({
      loading: false
    }));
  }
}
export function* preVerifyPhoneNumber(_ref5) {
  let {
      payload: {
        callback
      }
    } = _ref5,
    payload = _objectWithoutPropertiesLoose(_ref5.payload, _excluded5);
  yield put(actions.setSmsState({
    loading: true
  }));
  try {
    const res = yield call(api.userPhoneNumbers.preVerifyPhoneNumber, payload);
    yield put(actions.setSmsState({
      otcToken: res.otcToken
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSmsState({
      error: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setSmsState({
      loading: false
    }));
  }
}
export function* smsSagas() {
  yield takeEvery(actions.loadPhoneNumbers, loadPhoneNumbers);
  yield takeEvery(actions.setUpPhoneNumber, setUpPhoneNumber);
  yield takeEvery(actions.verifyPhoneNumber, verifyPhoneNumber);
  yield takeEvery(actions.deletePhoneNumber, deletePhoneNumber);
  yield takeEvery(actions.verifyDeletePhoneNumber, verifyDeletePhoneNumber);
  yield takeEvery(actions.preVerifyPhoneNumber, preVerifyPhoneNumber);
}