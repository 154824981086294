<div class="modal-header">
    <h1 class="modal-title pull-left">Device Import {{!isCompleted ? 'Preview' : 'Completed'}}</h1>
    <button data-dismiss="modal" (click)="closeModal($event, false)" aria-label="Close" class="close pull-right" type="button">
        <i class="fas fa-close"></i>
    </button>
</div>
<div class="modal-body preview-modal-body">

    <div *ngIf="isCompleted;else mainText">
        <p><strong>{{previewData?.successRows}}</strong> Devices out of <strong>{{previewData?.totalRows}}</strong> Devices were successfully imported.
        </p>

        <div *ngIf="previewData?.errorRows > 0">
            <p>
                <span class="text-danger">
                    <strong>{{previewData?.errorRows}}</strong>
                </span> Devices were not imported and are listed below.
            </p>
            <br>
            <p><strong>Not Imported Devices</strong></p>
        </div>
    </div>

    <ng-template #mainText>
        <div>
            <p><strong>{{previewData?.successRows}}</strong> Devices out of <strong>{{previewData?.totalRows}}</strong> Devices were formatted correctly and can be successfully imported.</p>
            <br>
            <p><strong>{{previewData?.issueCount}}</strong> Devices in your input file had issues:
            </p>
            <p><span class="text-warning"><strong>{{previewData?.warningRows}}</strong></span> issues are warnings. These Devices will be imported but some fields may be modified.</p>
            <p><span class="text-danger"><strong>{{previewData?.errorRows}}</strong></span> issues are errors. If you don't correct these values, these Devices will not be imported.</p>
        </div>
        <div *ngIf="previewData?.errorMessage !== '' && previewData?.deviceImportErrorList !== null && previewData?.deviceImportErrorList.length > 0">
            <br>
            <p><strong>Issue</strong></p>
            <p class="text-danger">{{previewData?.errorMessage}}</p>
        </div>
        <div *ngIf="previewData?.deviceImportErrorList !== null && previewData?.errorMessage === '' && previewData?.deviceImportErrorList.length > 0">
            <br>
            <p><strong>Issues</strong></p>
        </div>
    </ng-template>

    <div *ngIf="previewData?.deviceImportErrorList !== null && previewData?.deviceImportErrorList.length > 0 && previewData?.errorMessage === ''">
        <div class="preview-table">
            <table class="table" aria-describedby="">
                <thead>
                    <tr>
                        <th style="min-width: 60px">Row #</th>
                        <th>Issue</th>
                        <th *ngIf="!isCompleted">Severity</th>
                        <th>Explanation</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of previewData?.deviceImportErrorList">
                        <td style="min-width: 60px"> {{item.rowNumber}}</td>
                        <td> {{item.issueKey}}</td>
                        <td *ngIf="!isCompleted">
                            <span *ngIf="item.severity === 0;else noError" class="text-danger">Error</span>
                            <ng-template #noError>
                                <span class="text-warning">Warning</span>
                            </ng-template>
                        </td>
                        <td>{{item.message}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn" [ngClass]="{'btn-primary': isCompleted, 'btn-secondary':!isCompleted}" (click)="closeModal($event,false)" data-dismiss="modal">{{!isCompleted ? 'Cancel' : 'Close'}}</button>
    <button *ngIf="!isCompleted && previewData?.successRows > 0" type="button" class="btn btn-primary m-0" (click)="closeModal($event,true)">Import
        {{previewData?.successRows}}
        Devices</button>

</div>