<div class="modal-header">
    <h1 class="modal-title pull-left"> Edit {{isSiteRole? 'Live Sites': 'Project'}} Role
    </h1>
    <so-button [className]="'close pull-right'" (onClick)="closeModal()"
        [showEM]="true" [emClassName]="'fas fa-close'">
    </so-button>
</div>
<div class="modal-body">
    <p>
        Select what {{isSiteRole ? 'Live Sites': 'project'}} role <strong>{{userDetail.name}}</strong> should have for
        <strong>{{customerDetails?.customer?.name}}</strong>.
    </p>

    <div class="action-col" *ngIf="userRoles" [hidden]="!canDisplay">
        <label for="amt_{{item.name}}" class="role-card d-flex align-items-center"
            *ngFor="let item of userRoles; let i = index;" [ngClass]="{'active':item.isDefaultSelected}">
            <div class="role-card-radio action-col">
                <so-radio [id]="'amt_' + item.name" [name]="'role'"
                    (change)="onItemChange(item)" [isChecked]="item.isDefaultSelected" [label]="item.name === 'View All' ? 'View Only' : item.name">
                </so-radio>
            </div>
            <div class="role-card-description">
                <ul class="role-card-list">
                    <li *ngFor="let roleDesc of item?.newDescription">
                        {{roleDesc}}
                    </li>
                </ul>
            </div>
            <div class="role-typical-user">
                <span class="user-title"> Typical User</span>
                <span class="user"> {{item.typicalUser}}</span>
            </div>
        </label>

    </div>
</div>
<div class="modal-footer mrg-t-40 ps-0">
    <so-button [className]="'btn btn-secondary'" (onClick)="closeModal()" [text]="'Cancel'"></so-button>
    <so-button [disabled]="btnService.getStatus()" [style]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}"
       [className]="'btn btn-primary m-0'" (onClick)="updateUser()" [text]="'Apply'">
    </so-button>

</div>