<form [formGroup]="providerForm" novalidate>
  <div class="modal-header">
    <h1 class="modal-title pull-left">{{isEditMode ? 'Edit' : 'Create'}} Headquarters</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
      <i class="fas fa-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Headquarters Name
        <sup>*</sup>
      </label>
      <so-textbox [placeHolder]="'Headquarters Name'" [maxLength]="80" [id]="'name'"
        [inputControlName]="$any(providerForm).controls['name']">
      </so-textbox>

      <so-control-messages [name]="'Headquarters Name'" [isFormSubmitted]="isFormSubmitted"
        [control]="$any(providerForm).controls.name"></so-control-messages>
    </div>
    <div class="form-group">
      <label>Headquarters Type</label>
      <so-dropdown [id]="'Headquarters_type'" [options]="headquatersTypeOptions" [value]="'name'" [key]="'id'"
        [inputControlName]="$any(providerForm).controls['headquartersType']">
      </so-dropdown>
    </div>
    <div class="form-group">
      <label>Email Address
      </label>
      <so-textbox [placeHolder]="'Email Address'" [maxLength]="80" [id]="'email'"
        [inputControlName]="$any(providerForm).controls['email']">
      </so-textbox>
      <so-control-messages [name]="'Email Address'" [isFormSubmitted]="isFormSubmitted"
        [control]="$any(providerForm).controls.email"></so-control-messages>
    </div>
    <div class="form-group">
      <label>Phone Number</label>
      <so-textbox [placeHolder]="'Phone Number'" [maxLength]="20" [id]="'phoneNumber'"
        [inputControlName]="$any(providerForm).controls['phoneNumber']">
      </so-textbox>
      <so-control-messages [name]="'Phone Number'" [isFormSubmitted]="isFormSubmitted"
        [control]="$any(providerForm).controls.phoneNumber"></so-control-messages>
    </div>
    <div class="form-group">
      <label class="mb-1">Select the License type
      </label>
      <div>
        <div *ngFor="let item of accountType; let i = index;" class="action-col">
          <span class="radio-label">
            <so-radio [className]="'lbl-check'" [id]="'int_' + item.accountType" [name]="'accountType'"
              (change)="accountChanged(item,'provideraccount')" [isChecked]="item.accountTypeKey ===  accountSelection"
              [label]="item.accountType">
            </so-radio>
          </span>
          <span class="action-label">{{item.description}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <so-button (onClick)="closeModal()" [className]="'btn btn-secondary'" [text]="'Cancel'"></so-button>
    <so-button [type]="'submit'" (onClick)="saveUser()" [disabled]="btnService.getStatus()"
      [style]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}" [className]="'btn btn-primary m-0'"
      [text]="isEditMode ? 'Update' : 'Create'">
    </so-button>

  </div>

</form>