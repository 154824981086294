<div class="modal-header">
    <h1 class="modal-title pull-left"> Import Favorites</h1>
    <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <em class="fas fa-close"></em>
    </button>
</div>

<div class="modal-body">
    <form [formGroup]="favImportForm" (ngSubmit)="favImportToCustomer()" novalidate class="add-resource associated-admin">
        <div class="add-resource-block">
            <div class="form-group import-fav-block" *ngIf="customersList.length > 0">
                <p>Choose a Account to import Favorites into <strong>{{customerName}}</strong> from. You can import and create additional Favorites later.</p>
                <div class="row">
                    <div class="col-4">
                        <div class="form-group" *ngIf="customersList.length > 0">
                            <label>Account</label>
                            <select class="form-control" formControlName="customerId">
                                <option [ngValue]="0">Don’t import Favorites</option>
                                <option *ngFor="let item of customersList" [ngValue]="item?.customer?.id">
                                    {{item?.customer?.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <div class="row ">
                <div class="col-sm-6">
                    <div class="progress-container">
                        <ul class="progressbar">
                            <li class="active">Account Information</li>
                            <li class="active">Assign Admins</li>
                            <li class="active">Import Favorites</li>
                        </ul>
                    </div>
                </div>
                <div class="col-sm-6 d-flex align-items-center justify-content-end">

                    <button type="button" class="btn btn-secondary" (click)="backClick()">Back</button>
                    <button type="button" class="btn btn-primary m-0"  (click)="favImportToCustomer()">Complete</button>
                </div>
            </div>
        </div>
    </form>
</div>