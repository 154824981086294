import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { createModuleCaseReducers } from '../utils';
import { subscriptionsStoreName } from '../../constants';
export const initialSubscriptionStripeState = {
  loading: false,
  error: null,
  cardSetupIntentSecret: null
};
const reducers = _extends({}, createModuleCaseReducers());
const {
  reducer,
  actions: reducerActions,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/stripe`,
  initialState: initialSubscriptionStripeState,
  reducers
});
const actions = _extends({
  loadCustomer: createAction(`${name}/loadCustomer`),
  createCardSetupIntentSecret: createAction(`${name}/createCardSetupIntentSecret`)
}, reducerActions);
export { reducer as stripeReducer, actions as stripeActions };