<div *ngIf="sites?.length > 0">
    <div class="mb-2 mt-3">Select the Sites, Buildings, and Plans that you would like to include in your Project.
    </div>
    <div class="download-option-list">
        <div class="checkbox-label">
            <so-checkbox [isChecked]="isSelectedAll" (onChange)="selectAllPlan($event)" [label]="'Select All'"
                [id]="'building_all_sites'" [ngClass]="'lbl-check'" [showSubTitle]="true"  [parentClassName]="(selectedAllPlans > 0 && !isSelectedAll) ? 'uncheckall' : ''"
                [extraParams]="{'totalBuildingFloorCnt' : totalPlans, 'buildingFloorCnt':selectedAllPlans || 0}"></so-checkbox>
        </div>
    </div>

    <div class="project-filter-body">
        <div>
            <accordion>
                <accordion-group *ngFor="let site of sites" accordion-heading class="accordin">
                    <div accordion-heading class="clearfix slide-head-panel">
                        <div class="checkbox-label" (change)="selectAllSitePlan(site,$event)" >
                            <so-checkbox [disabled]="!canEditFloor(null,null,site)"
                                [isChecked]="site?.isSelectedAllBuilding" (onChange)="selectAllSitePlan(site,$event)"
                                [label]="site.name" [id]="'site_building_all_' + site?.id" [ngClass]="'lbl-check'"
                                [showSubTitle]="true"  [parentClassName]="(site?.siteFloorcnt > 0 && !site?.isSelectedAllBuilding) ? 'uncheckall' : ''"
                                [extraParams]="{'totalBuildingFloorCnt' : site?.totalSiteFloorCnt, 'buildingFloorCnt':site?.siteFloorcnt || 0}"></so-checkbox>
                        </div>
                    </div>
                    <div *ngFor="let building of site?.buildings" class="expand-body clearfix">
                        <accordion-group class="accordin">
                            <div accordion-heading class="clearfix slide-head-panel">
                                <div class="checkbox-label" >
                                    <so-checkbox [disabled]="!canEditFloor(null,building)"
                                        [isChecked]="building?.isSelectedAllFloor" [parentClassName]="(building?.buildingFloorcnt > 0 && !building?.isSelectedAllFloor) ? 'uncheckall' : ''"
                                        (onChange)="selectAllBuildingPlan(site,building,$event)" [label]="building.name"
                                        [id]="'site_building_select_build_all_' + building?.id" [ngClass]="'lbl-check'"
                                        [showSubTitle]="true"
                                        [extraParams]="{'totalBuildingFloorCnt' :building?.floors.length || 0 , 'buildingFloorCnt': building?.buildingFloorcnt || 0}">
                                    </so-checkbox>
                                </div>
                            </div>
                            <div *ngFor="let floor of building.floors" class="expand-body clearfix">
                                <div class="expand-inner clearfix">
                                    <div class="project-exp">
                                        <div class="checkbox-label">
                                            <so-checkbox [disabled]="!canEditFloor(floor)"
                                                [isChecked]="floor?.isFloorSelected"
                                                (onChange)="selectPlan(site,building,floor,$event)" [label]="floor.name"
                                                [id]="'building_Floor_' + floor?.id" [ngClass]="'lbl-check'">
                                            </so-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </accordion-group>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>