<div class="row">
    <!-- card view start -->
    <div class="col-xl-12">
        <div class="card-main">
            <accordion [closeOthers]="false" id="card-list" class="card-accordion">
                <accordion-group id="card-{{card[cardList.id]}}" [isOpen]="card[cardList.id]===selectedId"
                    (isOpenChange)="detach($event,card, 0, i);"
                    *ngFor="let card of data; let i = index;">
                    <div accordion-heading class="clearfix slide-head-panel main-panel d-flex align-items-center">
                        <div class="me-auto">
                            <h2 class="project-name project-name-title"
                                [ngClass]="{'quick-edit': cardType === 'planlibrary'}"> {{card[cardList.name] |
                                wrap:80}}
                                <span class="live-system-lable" *ngIf="cardType === 'site'">Live Site</span>
                                <em *ngIf="cardType === 'planlibrary'  && !card.isArchived"
                                    [ngClass]="{'hidden': checkIsViewOnly}"
                                    (click)="editSiteDetail(card, 'site', undefined, undefined, i)"
                                    class="fas fa-pen quick-edit-icon" title="Rename Site"></em>
                            </h2>
                            <ul class="project-info-list">
                                <li *ngIf="showCustomer" title="Account: {{card.customerName}}">Account:
                                    {{card.customerName}}</li>
                                <li class="project-assigner"
                                    *ngIf="isProject && card.projectStage === stage.Installation">Project Manager:
                                    {{card.projectManagerName}}</li>
                                <li class="project-assigner" *ngIf="isProject && card.projectStage === stage.Design">
                                    Designer:
                                    {{card.designerName}}</li>
                            </ul>
                        </div>
                        <div class="pe-0">
                            <div class="status-col ">
                                <span class="icon-information-val"><a href="javascript:void(0)"
                                        (click)="goToInfoPage(card,'info');" class="icon-information"
                                        title="{{(card.canEdit && card.canView)?'View Details':'View & Edit'}}">
                                        <em class="fa fa-info-circle"></em>
                                        Information
                                    </a></span>

                                <span *ngIf="cardType !== 'planlibrary' && !card.isArchived"
                                    class="icon-information-val"><a href="javascript:void(0)"
                                        (click)="goToInfoPage(card,'reports');" class="icon-information"
                                        title="Reports">

                                        <em class="fas fa-file-chart-pie"></em>
                                        Reports
                                    </a></span>
                                <span *ngIf="isProject && !card.isArchived && card.projectStage === stage.Installation"
                                    class="icon-information-val"><a href="javascript:void(0)"
                                        (click)="goToInfoPage(card,'progress');" class="icon-information"
                                        title="Progress">
                                        <em class="fa-solid fa-bars-progress"></em>
                                        Progress
                                    </a></span>
                                <span *ngIf="isProject  && !card.isArchived" class="icon-information-val"><a
                                        href="javascript:void(0)" (click)="_navActionClick(card,false,false)"
                                        class="icon-information" title="Planned and In Progress Devices and Tasks">
                                        <em class="fas fa-clipboard-list-check"></em>
                                        Punch List
                                    </a></span>
                                <span *ngIf="isProject  && !card.isArchived" class="icon-information-val"><a
                                        href="javascript:void(0)" (click)="_navActionClickCompelted(card)"
                                        class="icon-information" title="Completed Devices and Tasks">
                                        <em class="fas fa-clipboard-check"></em>
                                        Completed Work
                                    </a></span>

                                <span *ngIf="isProject && card.projectStage === stage.Installation  && !card.isArchived"
                                    class="design-val" title="Installation Status"
                                    [ngClass]="{'open':card.installationStatus === 'Open','inprogress':card.installationStatus === 'InProgress','done':card.installationStatus === 'Done'}">
                                    {{card.installationStatus === 'InProgress'? 'In Progress':card.installationStatus
                                    }}
                                </span>
                                <span *ngIf="isProject && card.projectStage === stage.Design  && !card.isArchived"
                                    class="design-val" title="Design Status"
                                    [ngClass]="{'open':card.designStatus === 'Open','inprogress':card.designStatus === 'InProgress','done':card.designStatus === 'Done'}">
                                    {{card.designStatus === 'InProgress'? 'In Progress':card.designStatus
                                    }}
                                </span>
                                <span *ngIf="card.isPublish" class="design-val published" title="Installation Status">
                                    Published
                                </span>
                                <span *ngIf="card.isArchived && !card.isPublish" class="design-val archive"
                                    title="Installation Status">
                                    Archived
                                </span>
                            </div>
                            <div class="count-col">
                                <ul class="count-list ">
                                    <li *ngIf="cardType === 'planlibrary'">{{card.buildings.length}}
                                        {{card.buildings.length === 1 ?'Building':'Buildings'}}</li>
                                    <li>{{card.floorCount}} {{card.floorCount === 1 ?'Plan':'Plans'}}</li>
                                    <li *ngIf="isProject && card && card.flagCount && card.flagCount !== null && card.flagCount > 0"
                                        class="red-text" [ngClass]="{'cursor-pointer':!card.isArchived}"
                                        (click)="!card.isArchived ? _navActionFlagClick(card) : ''">
                                        <em class="fas fa-flag"></em> {{card.flagCount}} {{card.flagCount === 1
                                        ?'Flag':'Flags'}}
                                    </li>
                                    <li *ngIf="isProject">{{card.totalHours || 0}} Hours</li>
                                    <li *ngIf="cardType !== 'planlibrary'" [ngClass]="{'link':!card.isArchived}"
                                        (click)="!card.isArchived ? _navActionClick(card,true,false) : ''">{{
                                        card[cardList.deviceCount]}} {{ card[cardList.deviceCount] === 1
                                        ?'Device':'Devices' }}
                                    </li>
                                    <li *ngIf="isProject" [ngClass]="{'link':!card.isArchived}"
                                        (click)="!card.isArchived ?_navActionClick(card,false,true):''">{{
                                        card.taskCount}}
                                        {{ card.taskCount === 1 ?'Task':'Tasks' }}</li>
                                    <li *ngIf="cardType === 'site'">
                                        <span title="(Number of Operational devices*100)/Total number of Devices">
                                            {{card.percentageCount}}%


                                        </span>
                                        Operational
                                    </li>
                                    <li class="progress-col"
                                        *ngIf="cardType === 'site' || (isProject && card.projectStage === stage.Installation)">
                                        <span class="project-progress-val" title=""
                                            (mouseover)='progressTooltip(card, $event)'
                                            (mouseleave)='progressTooltipRemove(card, $event)'
                                            (click)="_onCardClick($event, 'tooltip')">
                                            <div class="progress">
                                                <div *ngIf="isProject &&  card.projectStage === stage.Installation"
                                                    class="progress-tooltip"
                                                    id="progress-tooltip-{{card[cardList.id]}}">
                                                    <p>
                                                        <span class="blue">{{card.deviceCompleted}}</span> of
                                                        {{card[cardList.deviceCount]}} Devices Installed
                                                    </p>
                                                    <p>
                                                        <span class="blue"> {{card.taskCompleted}}</span> of
                                                        {{card.taskCount}} Tasks Completed
                                                    </p>
                                                </div>
                                                <div *ngIf="cardType === 'site'" class="progress-tooltip tooltip-sm"
                                                    id="progress-tooltip-{{card.id}}">
                                                    <p *ngIf="card[cardList.deviceCount] === 0">
                                                        <span>0 Devices</span>
                                                    </p>
                                                    <p *ngIf="tooltipClass.showDangerClass"> <span
                                                            class="red">{{card.notWorkingCount}}</span>
                                                        {{updateDeviceStatusName('NotWorking')}}
                                                        Device{{card.notWorkingCount === 1?'':'s'}}
                                                    </p>

                                                    <p *ngIf="tooltipClass.showWarningClass"> <span
                                                            class="yellow">{{card.operationalWithIssueCount}}</span>
                                                        {{updateDeviceStatusName('OperationalWithIssue')}}
                                                        Device{{card.operationalWithIssueCount === 1?'':'s'}}
                                                    </p>

                                                    <p *ngIf="tooltipClass.showInfoClass"> <span
                                                            class="blue">{{card.operationalCount}}</span> Operational
                                                        Device{{card.operationalCount === 1?'':'s'}}
                                                    </p>

                                                </div>
                                                <progressbar [value]="card[cardList.progressBarData] || 0">
                                                </progressbar>
                                            </div>
                                        </span>

                                    </li>
                                    <li *ngIf="isProject && card.projectStage === stage.Installation"
                                        class="progress-percent">{{card.percentCompleted || 0}}% Devices/Tasks
                                        Completed
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>
                    <div *ngIf="isProject && card.projectStage === stage.Installation"
                        class="project-chart-bar d-flex justify-content-end">

                        <div class="project-chart-bar-progressbars d-flex align-items-end flex-column">
                            <ul class="bar-list device-bar">
                                <li class="progress-status">{{card.deviceCompleted}} of {{card[cardList.deviceCount]}}
                                    Devices
                                    Installed
                                </li>
                                <li class="progress-col"><span class="project-progress-val" title="">
                                        <div class="progress">

                                            <progressbar [value]="card.deviceInstalledPercentage || 0">
                                            </progressbar>
                                        </div>
                                    </span>

                                </li>
                                <li class="progress-status">{{card.deviceInstalledPercentage || 0}}%
                                </li>
                            </ul>
                            <ul class="bar-list task-bar">
                                <li class="progress-status">{{card.taskCompleted}} of {{card.taskCount}} Tasks Completed
                                </li>
                                <li class="progress-col"><span class="project-progress-val" title="">
                                        <div class="progress">
                                            <progressbar [value]="card.taskCompletedPercentage || 0">
                                            </progressbar>
                                        </div>
                                    </span>

                                </li>
                                <li class="progress-status">{{card.taskCompletedPercentage || 0}}%
                                </li>
                            </ul>
                            <ul class="bar-list hours-bar">
                                <li class="progress-status">{{card.hoursCompleted}} of {{card.totalHours}} Total Hours
                                    Completed
                                </li>
                                <li class="progress-col"><span class="project-progress-val" title="">
                                        <div class="progress">
                                            <progressbar [value]="card.hoursCompletedPercentage || 0">
                                            </progressbar>
                                        </div>
                                    </span>

                                </li>
                                <li class="progress-status">{{card.hoursCompletedPercentage || 0}}%
                                </li>
                            </ul>
                        </div>




                    </div>
                    <div *ngIf="isProject && !card.isArchived && card.projectStage === stage.Installation && card.chartData && chartRender"
                        class="project-chart-bar d-flex justify-content-start">
                        <div class="project-chart-bar-progresscharts d-flex align-items-center">
                            <div class="card project-chart-bar-card">
                                <h3>Devices Installed and Tasks Completed</h3>
                                <div class="chart-card">
                                    <so-bar-vertical-stacked [scheme]="colorScheme" [results]="card.chartData"
                                        [gradient]="false" [xAxis]="showXAxis" [yAxis]="showYAxis"
                                        [xAxisLabel]="xAxisLabel" [yAxisLabel]="yAxisLabel"
                                        [yScaleMax]="card.chartDataMaxValue"
                                        [barPadding]="card.daysFilterChart === 30? 45: 246" [showGridLines]="true">
                                        <ng-template #tooltipTemplate let-model="model">
                                            <div class="chart-bar-tooltip">
                                                <p *ngIf="customizeTooltip(card,model.series, i) !== null">
                                                    <span class="tooltip-count" [ngStyle]="{'color':'#A4A1FB'}">
                                                        {{ customizeTooltip(card,model.series, i)[1].value }}</span> {{
                                                    customizeTooltip(card,model.series, i)[1].tooltipKey }}
                                                </p>
                                                <p *ngIf="customizeTooltip(card,model.series, i) !== null">
                                                    <span class="tooltip-count blue-text">
                                                        {{ customizeTooltip(card,model.series, i)[0].value }}</span> {{
                                                    customizeTooltip(card,model.series, i)[0].tooltipKey }}
                                                </p>
                                            </div>

                                        </ng-template>
                                    </so-bar-vertical-stacked>
                                </div>
                            </div>
                            <div class="chart-bar-info">
                                <select class="chart-select-menu" (change)="changeDaysOfChart($event, card, i)"
                                    [(ngModel)]="card.daysFilterChart">
                                    <option [value]="7">Last 7 Days</option>
                                    <option [value]="30">Last 30 Days</option>
                                </select>
                                <ul class="chart-color-indicator">
                                    <li class="devices">Devices Installed</li>
                                    <li class="task">Tasks Completed</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="(cardType==='site' || cardType==='planlibrary') && card[cardList.buildings].length === 0">
                        <p class="no-plan">
                            No Building created.</p>
                    </div>
                    <div *ngIf="(cardType==='project') && card[cardList.buildings].length === 0">
                        <p class="no-plan"> No Plan added.</p>
                    </div>
                    <div class="project-buidling-heading clearfix" *ngIf="card[cardList.buildings].length > 0">
                        <div class="building-name-col">Building Name</div>
                        <div class="plans-col">Plans</div>
                        <div *ngIf="isProject" class="hours-left-col">Hours </div>
                        <div *ngIf="cardType !== 'planlibrary'" class="devices-col">Devices</div>
                        <div *ngIf="isProject" class="task-col">Tasks</div>
                        <div *ngIf="isProject" class="punch-list-col">Punch List </div>
                        <div *ngIf="cardType === 'site'" class="operational-col">Operational</div>
                        <div *ngIf="cardType === 'site' || (isProject && card.projectStage === stage.Installation)"
                            class="progress-col">{{ isProject ?'Progress' : 'Operational Status'}}</div>
                    </div>
                    <div class="expand-body">
                        <accordion [closeOthers]="false" id="card_building" class="card-building"
                            *ngFor="let building of card[cardList.buildings]; let buildingIndex = index">
                            <accordion-group (isOpenChange)="detach()">
                                <div accordion-heading class="clearfix slide-head-panel" id="building_{{card[cardList.id]}}_{{buildingIndex}}">
                                    <div class="building-name building-name-col btn-group" dropdown>
                                        <span class="building-listing-floor clearfix"
                                            [ngClass]="{'quick-edit': cardType==='planlibrary'}">
                                            <a href="javascript:void(0)" title="{{building.buildingName}}"
                                                (click)="goToFloorPage(card, building)">
                                                {{building[cardList.buildingName]}}</a>
                                            <em *ngIf="cardType === 'planlibrary' && !card.isArchived"
                                                [ngClass]="{'hidden': checkIsViewOnly}"
                                                (click)="editSiteDetail(building, 'building', card, undefined, i, buildingIndex)"
                                                class="fas fa-pen quick-edit-icon" title="Rename Building"></em>

                                            <div class="building-dropdown"
                                                *ngIf="(isProject && data.length > 1 && !checkIsViewOnly) || (cardType === 'site' && isGlobalAdmin && building.floors && building.floors.length > 0)">
                                                <div class="dropdown" dropdown *ngIf="showAction">
                                                    <a dropdownToggle
                                                        (click)="toggleEvent($event,card,building[cardList.buildingIdKey])"
                                                        class="dropdown-toggle" href="javascript:void(0);">

                                                    </a>
                                                    <ul *dropdownMenu class="dropdown-menu proj-dp-menu" role="menu"
                                                        id="dp_tree_{{card[cardList.id]}}_{{building[cardList.buildingIdKey]}}">
                                                        <li role="menuitem" *ngIf="isProject">
                                                            <a class="dropdown-item pt-2 pb-2"
                                                                (click)="moveProjectBuildings($event,card,building)"
                                                                href=" javascript:void(0); ">Move
                                                                Building
                                                            </a>
                                                        </li>
                                                        <li role="menuitem">
                                                            <a class="dropdown-item btn-equipments"
                                                                (click)="manageDevice($event,card,building)"
                                                                *ngIf="building.awsImageExist && building.floorCount > 0">Manage
                                                                Devices
                                                            </a>
                                                        </li>
                                                        <li *ngIf="!checkIsViewOnly" role="menuitem">
                                                            <a class="dropdown-item" href=" javascript:void(0);"
                                                                (click)="migrateToProject($event,card,building)"
                                                                *ngIf="canMigrateProject && building.managedCount > 0">Migrate
                                                                to Project</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </span>
                                    </div>

                                    <div class="plans-col">
                                        <span>{{building.floorCount}}</span>
                                    </div>
                                    <div *ngIf="isProject" class="hours-left-col">
                                        <span>{{building.totalHours || 0}}</span>
                                        <span></span>
                                    </div>

                                    <div *ngIf="cardType !== 'planlibrary'" class="devices-col"
                                        [ngClass]="{'link':!card.isArchived}"
                                        (click)="!card.isArchived ? _navActionClick(card,true,false,building[cardList.buildingIdKey]) : ''">
                                        {{building[cardList.devicedCount]}}
                                    </div>

                                    <div *ngIf="isProject" class="task-col" [ngClass]="{'link':!card.isArchived}"
                                        (click)="!card.isArchived ? _navActionClick(card,false,true,building[cardList.buildingIdKey]) : ''">

                                        {{building.taskCount}}
                                    </div>


                                    <div *ngIf="isProject" class="punch-list-col punch-list-count"
                                        [ngClass]="{'link':!card.isArchived}"
                                        title="Planned and In Progress Devices and Tasks"
                                        (click)="!card.isArchived ? _navActionClick(card,false,false,building[cardList.buildingIdKey]) : ''">
                                        <em class="fas fa-clipboard-list-check"></em>
                                        <span>{{building.webPunchListCount || 0}}</span>
                                    </div>
                                    <div *ngIf="cardType === 'site'" class="operational-col"><span
                                            title="(Number of Operational devices*100)/Total number of Devices">{{building.percentageCount?building.percentageCount:0}}%</span>
                                    </div>
                                    <div class="progress-col"
                                        *ngIf="cardType === 'site' || (isProject && card.projectStage === stage.Installation)">

                                        <span *ngIf="isProject && card.projectStage === stage.Installation"
                                            class="project-progress-val" title=""
                                            (mouseover)='progressTooltip(building.floorDTOS[0].id, card.projectId, true)'
                                            (mouseleave)='progressTooltipRemove(building.floorDTOS[0].id, card.projectId, true)'
                                            (click)="tootipSelect($event)">
                                            <div class="progress-tooltip"
                                                id="progress-tooltip-building{{building.floorDTOS[0].id}}{{card[cardList.id]}}">
                                                <p>
                                                    <span class="blue">{{building.deviceCompleted}}</span> of
                                                    {{building.deviceCount}} Devices Installed
                                                </p>
                                                <p>
                                                    <span class="blue"> {{building.taskCompleted}}</span> of
                                                    {{building.taskCount}} Tasks Completed
                                                </p>
                                            </div>
                                            <div class="progress">
                                                <progressbar [value]="building.percentCompleted || 0">
                                                </progressbar>
                                            </div>
                                        </span>
                                        <span *ngIf="cardType === 'site'" class="project-progress-val"
                                            (mouseover)='progressTooltip(building,$event, true)'
                                            (mouseleave)='progressTooltipRemove(building,$event, true)'
                                            (click)="tootipSelect($event)">
                                            <span class="no-device-message" [hidden]="building.managedCount > 0">No
                                                Device added.</span>
                                            <div class="progress" [hidden]="building.managedCount === 0">
                                                <div class="progress-tooltip tooltip-md"
                                                    id="progress-tooltip-building-{{building.id}}">
                                                    <p *ngIf="tooltipClass.showBuildingDangerClass"> <span
                                                            class="red">{{building.notWorkingCount}}</span>
                                                        {{updateDeviceStatusName('NotWorking')}}
                                                        Device{{building.notWorkingCount === 1?'':'s'}}
                                                    </p>

                                                    <p *ngIf="tooltipClass.showBuildingWarningClass"> <span
                                                            class="yellow">{{building.operationalWithIssueCount}}</span>
                                                        {{updateDeviceStatusName('OperationalWithIssue')}}
                                                        Device{{building.operationalWithIssueCount === 1?'':'s'}}
                                                    </p>

                                                    <p *ngIf="tooltipClass.showBuildingInfoClass"> <span
                                                            class="blue">{{building.operationalCount}}</span>
                                                        {{updateDeviceStatusName('Operational')}}
                                                        Device{{building.operationalCount === 1?'':'s'}}
                                                    </p>

                                                </div>
                                                <progressbar [value]="building.progressBarData">
                                                </progressbar>
                                            </div>
                                        </span>
                                        <span
                                            *ngIf="isProject && card.projectStage === stage.Installation">{{building.percentCompleted
                                            || 0}}%</span>
                                    </div>
                                </div>
                                <div *ngIf="building[cardList.floors].length === 0">
                                    <p class="no-plan">
                                        No Plan added.
                                    </p>
                                </div>
                                <ng-container *ngIf="building[cardList.floors].length > 0">
                                    <ng-container *ngIf="showGrid">
                                        <div class="project-plan project-plan-grid">
                                            <div *ngFor="let floor of building[cardList.floors];let last = last ;let floorIndex = index"
                                                id="project-plan"
                                                [ngClass]="{'equipment-thumb-img': showGrid, 'card': showGrid}">
                                                <a href="javascript:void(0)" title="{{floor.name}}">
                                                    <!-- [routerLink]="['../',site.id,'building', building.id, 'floor',floor.id]"
                                            [queryParams]="{floorid: floor.id}" -->
                                                    <div>
                                                        <div *ngIf="!floor.isLoadingDone">
                                                            <so-loader [smallloader]='true'></so-loader>
                                                        </div>
                                                        <img [src]="getImage(floor)" alt=""
                                                            class="fallback img-responsive" [image]="getImage(floor)"
                                                            soImgOrientation
                                                            (click)="goToFloorPage(card, building,floor.id)"
                                                            (loaderDone)="floor.isLoadingDone = true" [last]=" last"
                                                            [parent]="'equipment-thumb-img card'"
                                                            [index]="floorIndex" />
                                                        <div class="d-flex p-0">
                                                            <label title="{{floor.name}}"
                                                                class="text-truncate flex-fill"
                                                                [ngClass]="{'quick-edit': cardType === 'planlibrary'}"
                                                                (click)="goToFloorPage(card, building,floor.id)">{{floor.name

                                                                }}
                                                                <em *ngIf="cardType === 'planlibrary' && !card.isArchived"
                                                                    [ngClass]="{'hidden': checkIsViewOnly}"
                                                                    (click)="$event.stopPropagation();editSiteDetail(floor, 'floor', card, building, i, buildingIndex, floorIndex)"
                                                                    class="fas fa-pen quick-edit-icon quick-edit-icon-grid-plan"
                                                                    title="Rename Plan"></em>
                                                            </label>
                                                        </div>
                                                    </div>

                                                </a>


                                                <!-- <ng-container *ngIf="!showGrid">
                                            
                                        </ng-container> -->
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="!showGrid">
                                        <div class="project-plan" id="project-plan"
                                            *ngFor="let floor of building[cardList.floors];let last = last ;let floorIndex = index">

                                            <div class="building-name-col"
                                                [ngClass]="{'quick-edit': cardType === 'planlibrary'}">
                                                <a href="javascript:void(0)"
                                                    (click)="goToFloorPage(card, building,floor.id)"
                                                    title="{{building[cardList.buildingName]}}">
                                                    {{floor.name}} </a>
                                                <em *ngIf="cardType === 'planlibrary' && !card.isArchived"
                                                    [ngClass]="{'hidden': checkIsViewOnly}"
                                                    (click)="editSiteDetail(floor, 'floor', card, building, i, buildingIndex, floorIndex)"
                                                    class="fas fa-pen quick-edit-icon" title="Rename Plan"></em>
                                            </div>
                                            <div class="plans-col">&nbsp;</div>
                                            <div *ngIf="isProject" class="hours-left-col">{{floor.totalHours}}
                                            </div>
                                            <div *ngIf="cardType !== 'planlibrary'" class="devices-col"
                                                [ngClass]="{'link':!card.isArchived}"
                                                (click)="!card.isArchived ? _navActionClick(card,true,false,building[cardList.buildingIdKey],floor.id) : ''">
                                                {{isProject ? floor.deviceCount : (floor.notWorkingCount +
                                                floor.operationalWithIssueCount + floor.operationalCount)}}
                                            </div>
                                            <div *ngIf="isProject" class="task-col"
                                                [ngClass]="{'link':!card.isArchived}"
                                                (click)="!card.isArchived ? _navActionClick(card,false,true,building[cardList.buildingIdKey],floor.id) : ''">
                                                {{floor.taskCount}}</div>
                                            <div *ngIf="isProject" class="punch-list-col punch-list-count"
                                                [ngClass]="{'link':!card.isArchived}"
                                                (click)="!card.isArchived ? _navActionClick(card,false,false,building[cardList.buildingIdKey],floor.id) : ''">
                                                <em class="fas fa-clipboard-list-check"></em>{{floor.webPunchListCount}}
                                            </div>
                                            <div class="operational-col " *ngIf="cardType === 'site'"><span
                                                    title="(Number of Operational devices*100)/Total number of Devices">{{(floor.notWorkingCount
                                                    +
                                                    floor.operationalWithIssueCount + floor.operationalCount) >
                                                    0 ?
                                                    ((floor.operationalWithIssueCount + floor.operationalCount)
                                                    /
                                                    (floor.notWorkingCount +
                                                    floor.operationalWithIssueCount + floor.operationalCount) *
                                                    100 |
                                                    number: '1.0-2') : 0
                                                    }}%</span>
                                            </div>
                                            <div *ngIf="(isProject && card.projectStage === stage.Installation)"
                                                class="progress-col">
                                                <span class="project-progress-val project-status-count">
                                                    <div class="building-working-not col" title="Planned">
                                                        {{floor.plannedCount || 0}}
                                                    </div>
                                                    <div class="building-working-opwith col" title="In Progress">
                                                        {{floor.inProgressCount || 0}}
                                                    </div>
                                                    <div class="building-working-op col" title="Installed">
                                                        {{floor.insatllCount|| 0}}
                                                    </div>

                                                </span>
                                                <span *ngIf="(isProject && card.projectStage === stage.Installation)"
                                                    title="">{{floor.percentComplete}} %</span>
                                            </div>
                                            <div *ngIf="cardType === 'site'" class="progress-col">
                                                <span class="project-progress-val project-status-count">
                                                    <div class="building-working-not col cursor-pointer"
                                                        title="Non-Operational"
                                                        (click)="navigateToCustomerDevice(card, building[cardList.buildingIdKey], floor?.id, 'no')">
                                                        {{floor.notWorkingCount || 0}}
                                                    </div>
                                                    <div class="building-working-opwith col cursor-pointer"
                                                        title="Semi-Operational"
                                                        (click)="navigateToCustomerDevice(card, building[cardList.buildingIdKey], floor?.id, 'oi')">
                                                        {{floor.operationalWithIssueCount || 0}}
                                                    </div>
                                                    <div class="building-working-op col cursor-pointer"
                                                        title="Operational"
                                                        (click)="navigateToCustomerDevice(card, building[cardList.buildingIdKey], floor?.id, 'op')">
                                                        {{floor.operationalCount|| 0}}
                                                    </div>

                                                </span>
                                                <span *ngIf="(isProject && card.projectStage === stage.Installation)"
                                                    title="">{{floor.percentComplete}} %</span>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </accordion-group>
                        </accordion>
                        <!-- <p class="no-plan" *ngIf="building[cardList.floors].length === 0">
                            No Plan added.</p> -->
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>