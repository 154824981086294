/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter } from '@angular/core';

@Component({
  selector: 'so-necessary-popup',
  templateUrl: './necessary-popup.component.html',
  styleUrls: ['./necessary-popup.component.scss'],
})
export class NecessaryPopupComponent {
  details!: any;
  btnSuccessText!: any;
  btnSuccessClass!: any;
  btnSuccessStyle!: any;
  header!: any;
  dialogClass!: any;
  dialogStyle!: any;
  dialogId!: any;
  public eventForFloorPlan: EventEmitter<any> = new EventEmitter();
  _action(e: any) {
    this.eventForFloorPlan.emit();
  }
}
