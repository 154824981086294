import { urls } from '../constants';
import { Delete, Get, Patch, Post } from '../fetch';
export async function getConfigs() {
  return Get(`${urls.directory.v1}`);
}
export async function updateConfiguration(id, body) {
  return Patch(`${urls.directory.v1}/${id}`, body);
}
export async function createConfiguration(body) {
  return Post(`${urls.directory.v1}`, body);
}
export async function deleteConfiguration(id) {
  return Delete(`${urls.directory.v1}/${id}`);
}
export async function countConfigurationUsers(id) {
  return Get(`${urls.directory.temp}/${id}/Users/count`);
}