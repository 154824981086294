import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { createModuleCaseReducers } from '../utils';
import { subscriptionsStoreName } from '../../constants';
export const checkoutInitialState = {
  fetching: true,
  loading: false,
  error: null,
  confirmed: false
};
const reducers = _extends({}, createModuleCaseReducers());
const {
  actions: checkoutActions,
  reducer,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/checkout`,
  initialState: checkoutInitialState,
  reducers
});
const actions = _extends({
  loadCheckout: createAction(`${name}/loadCheckout`),
  resetCheckout: createAction(`${name}/resetCheckout`),
  submitCheckout: createAction(`${name}/submitCheckout`),
  confirmCheckout: createAction(`${name}/confirmCheckout`, payload => ({
    payload
  })),
  errorCheckout: createAction(`${name}/errorCheckout`, payload => ({
    payload
  }))
}, checkoutActions);
export { reducer as checkoutReducer, actions as checkoutActions };