import _extends from "@babel/runtime/helpers/esm/extends";
import { all, call, delay, put, select, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { api, MachineToMachineAuthStrategy } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { ApiStateKeys } from './interfaces';
import { apiTokensDataDemo, apiTokensDataTenantDemo } from '../dummy';
import { v4 as uuidv4 } from 'uuid';
import { errorHandler } from '../../utils';
function* addUserApiToken({
  payload
}) {
  const {
    description,
    expires,
    callback
  } = payload;
  const {
    apiTokensDataUser
  } = yield select(state => state.auth.apiTokensState);
  const {
    policy
  } = yield select(state => state.auth.securityPolicyState.publicPolicy);
  const isAccessToken = (policy == null ? void 0 : policy.machineToMachineAuthStrategy) === MachineToMachineAuthStrategy.AccessToken;
  try {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: true
    }));
    let data;
    let expiresInMinutes = null;
    if (expires) {
      expiresInMinutes = expires * 1440;
    }
    if (isAccessToken) {
      data = yield call(api.auth.createUserAccessToken, {
        description,
        expiresInMinutes
      });
    } else {
      data = yield call(api.auth.updateUserApiTokensData, {
        description,
        expiresInMinutes
      });
    }
    yield put(actions.setApiTokensState({
      showAddTokenDialog: false
    }));
    yield delay(200);
    yield put(actions.setApiTokensState({
      apiTokensDataUser: [_extends({}, data, {
        type: isAccessToken ? MachineToMachineAuthStrategy.AccessToken : MachineToMachineAuthStrategy.ClientCredentials
      }), ...apiTokensDataUser],
      successDialog: {
        open: true,
        secret: data.secret,
        clientId: !isAccessToken ? data.clientId : undefined
      }
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: false
    }));
    callback == null ? void 0 : callback(null);
  } catch (e) {
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* addTenantApiToken({
  payload
}) {
  const {
    description,
    roleIds,
    expires,
    callback
  } = payload;
  const {
    apiTokensDataTenant
  } = yield select(state => state.auth.apiTokensState);
  const {
    policy
  } = yield select(state => state.auth.securityPolicyState.publicPolicy);
  const isAccessToken = (policy == null ? void 0 : policy.machineToMachineAuthStrategy) === MachineToMachineAuthStrategy.AccessToken;
  try {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: true
    }));
    let data;
    let expiresInMinutes = null;
    if (expires) {
      expiresInMinutes = expires * 1440;
    }
    if (isAccessToken) {
      data = yield call(api.auth.createTenantAccessToken, {
        description,
        expiresInMinutes,
        roleIds
      });
    } else {
      data = yield call(api.auth.updateTenantApiTokensData, {
        description,
        roleIds,
        expiresInMinutes
      });
    }
    yield put(actions.setApiTokensState({
      showAddTokenDialog: false
    }));
    yield delay(200);
    yield put(actions.setApiTokensState({
      apiTokensDataTenant: [_extends({}, data, {
        type: isAccessToken ? MachineToMachineAuthStrategy.AccessToken : MachineToMachineAuthStrategy.ClientCredentials
      }), ...apiTokensDataTenant],
      successDialog: {
        open: true,
        secret: data.secret,
        clientId: !isAccessToken ? data.clientId : undefined
      }
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: false
    }));
    callback == null ? void 0 : callback(null);
  } catch (e) {
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.ADD_API_TOKEN,
      value: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteUserApiToken({
  payload
}) {
  const {
    apiTokensDataUser
  } = yield select(state => state.auth.apiTokensState);
  const isAccessToken = apiTokensDataUser.some(token => token.id === payload);
  try {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: true
    }));
    if (isAccessToken) {
      yield call(api.auth.deleteUserAccessToken, {
        id: payload
      });
      yield put(actions.setApiTokensState({
        apiTokensDataUser: apiTokensDataUser.filter(token => token.id !== payload),
        deleteTokenDialog: {
          open: false,
          id: payload
        }
      }));
    } else {
      yield call(api.auth.deleteUserApiToken, {
        tokenId: payload
      });
      yield put(actions.setApiTokensState({
        apiTokensDataUser: apiTokensDataUser.filter(token => token.clientId !== payload),
        deleteTokenDialog: {
          open: false,
          clientId: payload
        }
      }));
    }
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: false
    }));
  } catch (e) {
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: false
    }));
  }
}
function* deleteTenantApiToken({
  payload
}) {
  const {
    apiTokensDataTenant
  } = yield select(state => state.auth.apiTokensState);
  const isAccessToken = apiTokensDataTenant.some(token => token.id === payload);
  let filteredApiTokensDataTenant = apiTokensDataTenant;
  try {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: true
    }));
    if (isAccessToken) {
      yield call(api.auth.deleteTenantAccessToken, {
        id: payload
      });
      filteredApiTokensDataTenant = apiTokensDataTenant.filter(token => token.id !== payload);
    } else {
      yield call(api.auth.deleteTenantApiToken, {
        tokenId: payload
      });
      filteredApiTokensDataTenant = apiTokensDataTenant.filter(token => token.clientId !== payload);
    }
    yield put(actions.setApiTokensState({
      apiTokensDataTenant: filteredApiTokensDataTenant,
      deleteTokenDialog: {
        open: false,
        id: payload
      }
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: false
    }));
  } catch (e) {
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.DELETE_API_TOKEN,
      value: false
    }));
  }
}
function* loadApiTokens({
  payload
}) {
  if (!(payload != null && payload.silentLoading)) {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: true
    }));
  }
  try {
    var _payload$callback;
    const [apiTokensDataUser = [], apiTokensDataTenant = []] = yield all([call(api.auth.getUserApiTokensData), call(api.auth.getTenantApiTokensData)]);
    yield put(actions.setApiTokensState({
      apiTokensDataUser,
      apiTokensDataTenant
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    }));
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, true);
  } catch (e) {
    var _payload$callback2;
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    }));
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
  }
}
function* loadUserApiTokens({
  payload
}) {
  if (!(payload != null && payload.silentLoading)) {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: true
    }));
  }
  try {
    var _call, _call2, _accessTokensData$acc, _payload$callback3;
    const apiTokensData = yield (_call = call(api.auth.getUserApiTokensData)) != null ? _call : [];
    const accessTokensData = yield (_call2 = call(api.auth.getUserAccessTokensData)) != null ? _call2 : [];
    const accessTokens = (_accessTokensData$acc = accessTokensData.accessTokens) != null ? _accessTokensData$acc : [];
    const allTokens = accessTokens.map(item => _extends({}, item, {
      type: MachineToMachineAuthStrategy.AccessToken
    })).concat(apiTokensData.map(item => _extends({}, item, {
      type: MachineToMachineAuthStrategy.ClientCredentials
    })));
    const sortedTokensByDate = allTokens.sort((date1, date2) => new Date(date2.createdAt) - new Date(date1.createdAt));
    yield put(actions.setApiTokensState({
      apiTokensDataUser: sortedTokensByDate != null ? sortedTokensByDate : []
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    }));
    payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, true);
  } catch (e) {
    var _payload$callback4;
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    }));
    payload == null ? void 0 : (_payload$callback4 = payload.callback) == null ? void 0 : _payload$callback4.call(payload, null, e);
  }
}
function* loadTenantApiTokens({
  payload
}) {
  if (!(payload != null && payload.silentLoading)) {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: true
    }));
  }
  try {
    var _call3, _call4, _accessTokensData$acc2, _payload$callback5;
    const apiTokensData = yield (_call3 = call(api.auth.getTenantApiTokensData)) != null ? _call3 : [];
    const accessTokensData = yield (_call4 = call(api.auth.getTenantAccessTokensData)) != null ? _call4 : [];
    const accessTokens = (_accessTokensData$acc2 = accessTokensData == null ? void 0 : accessTokensData.accessTokens) != null ? _accessTokensData$acc2 : [];
    const allTokens = accessTokens.map(item => _extends({}, item, {
      type: MachineToMachineAuthStrategy.AccessToken
    })).concat(apiTokensData.map(item => _extends({}, item, {
      type: MachineToMachineAuthStrategy.ClientCredentials
    })));
    const sortedTokensByDate = allTokens.sort((date1, date2) => new Date(date2.createdAt) - new Date(date1.createdAt));
    yield put(actions.setApiTokensState({
      apiTokensDataTenant: sortedTokensByDate != null ? sortedTokensByDate : []
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    }));
    payload == null ? void 0 : (_payload$callback5 = payload.callback) == null ? void 0 : _payload$callback5.call(payload, true);
  } catch (e) {
    var _payload$callback6;
    yield put(actions.setApiTokensError({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: errorHandler(e)
    }));
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: false
    }));
    payload == null ? void 0 : (_payload$callback6 = payload.callback) == null ? void 0 : _payload$callback6.call(payload, null, e);
  }
}
export function* apiTokensSaga() {
  yield takeLeading(actions.loadApiTokens, loadApiTokens);
  yield takeLeading(actions.loadUserApiTokens, loadUserApiTokens);
  yield takeLeading(actions.loadTenantApiTokens, loadTenantApiTokens);
  yield takeEvery(actions.addUserApiToken, addUserApiToken);
  yield takeEvery(actions.addTenantApiToken, addTenantApiToken);
  yield takeLatest(actions.deleteTenantApiToken, deleteTenantApiToken);
  yield takeLatest(actions.deleteUserApiToken, deleteUserApiToken);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* addTenantApiTokenMock({
  payload
}) {
  const {
    description,
    roleIds,
    callback
  } = payload;
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.ADD_API_TOKEN,
    value: true
  }));
  const {
    apiTokensDataTenant
  } = yield select(state => state.auth.apiTokensState);
  const newToken = _extends({}, apiTokensDataTenantDemo, {
    description,
    roleIds,
    clientId: `CLIENT_ID_${uuidv4()}`
  });
  yield put(actions.setApiTokensState({
    showAddTokenDialog: false
  }));
  yield delay(200);
  yield put(actions.setApiTokensState({
    apiTokensDataTenant: [...apiTokensDataTenant, newToken],
    successDialog: {
      open: true,
      secret: newToken.secret,
      clientId: newToken.clientId
    }
  }));
  yield delay(200);
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.ADD_API_TOKEN,
    value: false
  }));
  callback == null ? void 0 : callback(null);
}
function* deleteTenantApiTokenMock({
  payload
}) {
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.DELETE_API_TOKEN,
    value: true
  }));
  yield delay(200);
  const apiTokensDataTenant = [apiTokensDataTenantDemo];
  yield put(actions.setApiTokensState({
    apiTokensDataTenant: apiTokensDataTenant.filter(i => i.clientId !== payload),
    deleteTokenDialog: {
      open: false,
      clientId: payload
    }
  }));
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.DELETE_API_TOKEN,
    value: false
  }));
}
function* loadApiTokensMock({
  payload
}) {
  var _payload$callback7;
  if (!(payload != null && payload.silentLoading)) {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: true
    }));
  }
  const apiTokensDataUser = [apiTokensDataDemo];
  const apiTokensDataTenant = [apiTokensDataTenantDemo];
  yield put(actions.setApiTokensState({
    apiTokensDataUser,
    apiTokensDataTenant
  }));
  yield delay(200);
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.LOAD_API_TOKENS,
    value: false
  }));
  payload == null ? void 0 : (_payload$callback7 = payload.callback) == null ? void 0 : _payload$callback7.call(payload, true);
}
function* loadUserApiTokensMock({
  payload
}) {
  var _payload$callback8;
  if (!(payload != null && payload.silentLoading)) {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: true
    }));
  }
  const apiTokensDataUser = [apiTokensDataDemo];
  yield put(actions.setApiTokensState({
    apiTokensDataUser
  }));
  yield delay(200);
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.LOAD_API_TOKENS,
    value: false
  }));
  payload == null ? void 0 : (_payload$callback8 = payload.callback) == null ? void 0 : _payload$callback8.call(payload, true);
}
function* loadTenantApiTokensMock({
  payload
}) {
  var _payload$callback9;
  if (!(payload != null && payload.silentLoading)) {
    yield put(actions.setApiTokensLoader({
      key: ApiStateKeys.LOAD_API_TOKENS,
      value: true
    }));
  }
  const apiTokensDataTenant = [apiTokensDataTenantDemo];
  yield put(actions.setApiTokensState({
    apiTokensDataTenant
  }));
  yield delay(200);
  yield put(actions.setApiTokensLoader({
    key: ApiStateKeys.LOAD_API_TOKENS,
    value: false
  }));
  payload == null ? void 0 : (_payload$callback9 = payload.callback) == null ? void 0 : _payload$callback9.call(payload, true);
}
export function* apiTokensSagaMock() {
  yield takeLeading(actions.loadApiTokens, loadApiTokensMock);
  yield takeLeading(actions.loadUserApiTokens, loadUserApiTokensMock);
  yield takeLeading(actions.loadTenantApiTokens, loadTenantApiTokensMock);
  yield takeEvery(actions.addTenantApiToken, addTenantApiTokenMock);
  yield takeLatest(actions.deleteTenantApiToken, deleteTenantApiTokenMock);
}