import _extends from "@babel/runtime/helpers/esm/extends";
import { call, put, takeEvery, delay } from 'redux-saga/effects';
import { subscriptionsPaymentMethodActions } from './index';
import { api } from '@frontegg/rest-api';
import { paymentMethodsMock } from '../../dummy';
import { errorHandler } from '../../../utils';
export function* subscriptionsPaymentMethodSagas() {
  yield takeEvery(subscriptionsPaymentMethodActions.loadPaymentMethod, loadPaymentMethod);
  yield takeEvery(subscriptionsPaymentMethodActions.submitPaymentMethod, submitPaymentMethod);
  yield takeEvery(subscriptionsPaymentMethodActions.submitPaymentMethodError, submitPaymentMethodError);
  yield takeEvery(subscriptionsPaymentMethodActions.submitPaymentMethodSuccess, submitPaymentMethodSuccess);
  yield takeEvery(subscriptionsPaymentMethodActions.updatePaymentMethodBillingDetails, updateBillingDetails);
}
export function* loadPaymentMethod() {
  yield put(subscriptionsPaymentMethodActions.setLoading(true));
  try {
    const paymentMethods = yield call(api.subscriptions.getPaymentMethods);
    const paymentMethod = paymentMethods[0];
    yield put(subscriptionsPaymentMethodActions.setState({
      paymentMethod,
      loading: false,
      fetching: false
    }));
  } catch (e) {
    yield put(subscriptionsPaymentMethodActions.setError(errorHandler(e)));
  }
}
function* updateBillingDetails({
  payload
}) {
  yield put(subscriptionsPaymentMethodActions.setLoading(true));
  const {
    id,
    email,
    address,
    callback
  } = payload;
  try {
    yield call(api.subscriptions.updatePaymentMethodBillingDetails, id, _extends({
      email
    }, address));
    yield call(loadPaymentMethod);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(subscriptionsPaymentMethodActions.setError(errorHandler(e)));
    callback == null ? void 0 : callback(false);
  }
  yield put(subscriptionsPaymentMethodActions.setLoading(false));
}
function* submitPaymentMethod() {
  yield put(subscriptionsPaymentMethodActions.setLoading(true));
}
function* submitPaymentMethodError({
  payload: error
}) {
  yield put(subscriptionsPaymentMethodActions.setError(error));
}
function* submitPaymentMethodSuccess() {
  yield put(subscriptionsPaymentMethodActions.loadPaymentMethod());
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadPaymentMethodMock() {
  yield put(subscriptionsPaymentMethodActions.setLoading(true));
  yield delay(500);
  const paymentMethod = paymentMethodsMock[0];
  yield put(subscriptionsPaymentMethodActions.setState({
    paymentMethod,
    loading: false,
    fetching: false
  }));
}
export function* subscriptionsPaymentMethodSagasMock() {
  yield takeEvery(subscriptionsPaymentMethodActions.loadPaymentMethod, loadPaymentMethodMock);
}