import { FormControl, FormGroup } from "@angular/forms";
import { Pattern } from '../constants/pattern.constant';
import { Utilities } from "../utilities";

export class ValidationService {
    static getValidatorErrorMessage(validatorName: string, validatorControl: string, validatorValue?: any) {
        const config: any = {
            'required': `${validatorControl} is required`,
            'invalidEmailAddress': 'Invalid Email Address',
            'invalidIPAddress': 'Invalid IP address',
            'invalidPassword': 'Invalid password. Password must be between 10 to 64 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.',
            'minlength': validatorControl == 'Confirmation Code' ? `Should Contain at least ${validatorValue.requiredLength} numbers` : `Should Contain at least ${validatorValue.requiredLength} numbers/characters`,
            'maxlength': `Maximum length ${validatorValue.requiredLength} allowed`,
            'pattern': `Invalid ${validatorControl}`,
            'duplicateValue': `Duplicate ${validatorControl}`,
            'confirmPassword': 'New password and Confirm new password does not match',
            'dropdownValidation': `${validatorControl} Required`,
            'negativeHeight': 'Heightest must be greater than or equal to zero',
            'negativeCost': 'Cost must be greater than or equal to zero',
            'negativePort': 'Port must be greater than or equal to zero',
            'negativeInstallHours': 'Install Hours must be greater than or equal to zero',
            'negativePrice': 'Price must be greater than or equal to zero',
            'invalidNumber': 'This field must be Numeric value',
            'coneDegree': 'Coverage Direction should greater than or equal to 0 and less or equal than 360',
            'invalidMACAddress': 'Invalid MAC address',
            'email': 'Invalid Email Address',
            'greaterThanZero':`${validatorControl} must be a number > 0`,
        };

        return config[validatorName];
    }

    static confirmPassword(control: FormControl, group: FormGroup, matchPassword: string) {
        if (!control.value || Utilities.isEmpty(group.controls[matchPassword].value) || group.controls[matchPassword].value === control.value) {
            return null;
        }
        return { 'confirmPassword': true }
    }

    static dropdownValidation(control: FormControl) {
        if (!control.touched || !Utilities.isEmpty(control.value)) {
            return null;
        }
        return { 'confirmPassword': true }
    }


    static duplicateValue(control: FormControl, values: string[]) {
        if (!control.value || Utilities.isEmpty(values) || values.indexOf(control.value.toLowerCase())) {
            return null;
        }
        return { 'duplicateValue': true }
    }

    static emailValidator(control: FormControl) {
        // RFC 2822 compliant regex
        if (!control.value || control.value.match(Pattern.EMAIL)) {
            return null;
        }
        return { 'invalidEmailAddress': true };
    }

    static IPValidator(control: FormControl) {
        if (Utilities.isEmpty(control.value) ||
            control.value.toString().match(Pattern.IP)) {
            return null;
        }
        return { 'invalidIPAddress': true };
    }
    static MACAddressValidator(control: FormControl) {
        if (Utilities.isEmpty(control.value) ||
            control.value.toString().match(Pattern.MAC)) {
            return null;
        }
        return { 'invalidMACAddress': true };
    }
    static passwordValidator(control: any) {
        // {8,64}           - Assert password is between 8 and 64 characters
        // (?=.*[a-zA-Z])      - Assert a string has at least one alphabet
        // (?=.*[^a-zA-Z0-9])     - Assert a password has at least one number and one lowercase alfhabet or one uppercase alphabet
        // (?=.*\d)     -Assert that password has alteast one digit
        // (?!.*\s)     - Assert that password has atleas one special symbol
        if (Utilities.isEmpty(control.value) || control.value.match(Pattern.PASSWORD)) {
            return null;
        }
        return { 'invalidPassword': true };
    }

    static numberValidator(control: FormControl) {
        if (!control.value || control.value.toString().match(Pattern.NUMBER)) {
            return null;
        }
        return { 'invalidNumber': true };
    }

    static numberMinMaxValidator(control: FormControl) {
        if (!control.value || control.value.toString().match(Pattern.NUMBER) && control.value >= 0 && control.value <= 360) {
            return null;
        }
        return { 'coneDegree': true };
    }

    static onlyWhiteSpaceValidator(control: FormControl) {
        if (!control.value || control.value.toString().trim().length !== 0) {
            return null;
        }
        return { 'required': true };
    }

    static negativeValueValidator(control: FormControl) {
        if (control.value >= 0) {
            return null;
        }
        return { 'negativeHeight': true }
    }

    static negativeValueCostValidator(control: FormControl) {
        if (control.value >= 0) {
            return null;
        }
        return { 'negativeCost': true }
    }
    static negativeValuePortValidator(control: FormControl) {
        if (control.value >= 0) {
            return null;
        }
        return { 'negativePort': true }
    }

    static negativeValuePrice(control: FormControl) {
        if (control.value >= 0) {
            return null;
        }
        return { 'negativePrice': true }
    }

    static negativeValueInstallHoursValidator(control: FormControl) {
        if (control.value >= 0) {
            return null;
        }
        return { 'negativeInstallHours': true }
    }

    static negativeValueDueDateHoursValidator(control: FormControl) {
        if (control.value >= 0) {
            return null;
        }
        return { 'dueDate': true }
    }

    static checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
        return (group: FormGroup) => {
            const passwordInput = group.controls[passwordKey],
                passwordConfirmationInput = group.controls[passwordConfirmationKey];
            if (passwordInput.value !== passwordConfirmationInput.value) {
                return passwordConfirmationInput.setErrors({ 'confirmPassword': true });
            }
            else {
                return passwordConfirmationInput.setErrors(null);
            }
        }
    }
    static greaterThanZeroValidator(control: FormControl) {
        if (!control.value || Utilities.isEmpty(control.value) || isNaN(control.value) || control.value <= 0) {
            return { 'greaterThanZero': true };
        }
        return null;
    }
}
