/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[soNumbers5DecimalOnly]'
})
export class Numbers5DecimalOnlyDirective {

  // private regex: RegExp = new RegExp(/^[0-9]{1,2}([,.][0-9]{1,2})?$/);
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
    e.preventDefault();
  }
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private regex: RegExp = /^[0-9]{0,5}([.][0-9]{0,2})?$/;


  // Allow key codes for special events
  // Backspace, tab, end, home
  // eslint-disable-next-line @typescript-eslint/member-ordering
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];

  constructor(private el: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {

    // Allow Backspace, tab, end, and home keys

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }


    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}