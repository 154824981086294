/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, ButtonEnableDisableService, EventEmitterType, EventService, MessageService, Roles, Utilities, projectRoles } from '@SiteOwl/core';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { UiService } from '../../service/ui.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
})
export class SelectCustomerComponent implements OnInit {
  customers!: any;
  isFromDashBoard!: any;
  tab!: any;
  isSpinner!: any;
  selectedCustomer!: any;
  user!: any
  userObject: any = null;
  constructor(private messageService: MessageService, public bsModalTicketRef: BsModalRef, public btnService: ButtonEnableDisableService, private authService: AuthService, private eventService: EventService, private uiService: UiService) { }

  ngOnInit() {
    this.user = this.authService.getUserKeyData();

    this.getFilteredCustomers();
  }
  closeModal(e: any) {
    this.bsModalTicketRef.hide();
  }

  create() {
    if (this.selectedCustomer && this.selectedCustomer.id > 0) {
      this.eventService.broadcast(EventEmitterType.createProjectFromDashboard, {
        customer: this.selectedCustomer,
        stage: "ticket",
      });
      this.bsModalTicketRef.hide();
    } else {
      this.messageService.errorMessage("Create New Ticket", "Please select Account");
    }
  }

  getFilteredCustomers() {
    this.uiService.getUser().subscribe((res: any) => {
      this.userObject = res;
      if (this.tab === 'Ticket') {
        this.userObject.customerUserList.forEach((element: any) => {
          if (element.customer.isLiveSystemAccessForCustomer && (element.role.name !== Roles.Technician && element.role.name !== Roles.ViewOnly) || this.authService.getUserKeyData().userRole === Roles.Administrator) {
            if (this.customers.filter((x: any) => x.id === element.customer.id).length === 0) {
              this.customers.push(element.customer);
            }
          }
        });
      } else {
        this.userObject.customerUserList.forEach((element: any) => {
          if (element.projectRole.name === projectRoles.Manager) {
            if (this.customers.filter((x: any) => x.id === element.customer.id).length === 0) {
              this.customers.push(element.customer);
            }
          }
        });
      }
      if (this.customers.length > 0) {
        this.customers = _.sortBy(this.customers, [function (a) {
          if (Utilities.isEmpty(a.name)) {
            return a.name
          } else {
            return a.name.toLowerCase()
          }
        }]);
        this.selectedCustomer = '';
      }
    }, () => {
      this.isSpinner = false;
    })
  }
}
