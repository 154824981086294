import { createAction } from '@reduxjs/toolkit';
import { authStoreName } from '../../../constants';
import { errorsReducerForKey, loadersReducerForKey, resetStateByKey, typeReducerForKey, typeReducerNestedKey } from '../../utils';
import { SortByEnum, PaginationOrderEnum } from '@frontegg/rest-api';
export * from './types';
export const allAccountsInitialState = {
  loaders: {},
  errors: {},
  accounts: {
    tenantId: '',
    children: []
  },
  accountsQueryParams: {
    _limit: 50,
    _offset: 0,
    _filter: '',
    _subTenantId: ''
  },
  searchAccountsData: [],
  rootAccount: {
    tenantId: ''
  },
  selectedAccount: {
    tenantId: '',
    userJwt: {
      accessToken: '',
      expiresIn: 0,
      expires: ''
    },
    users: [],
    usersQueryParams: {
      _offset: 0,
      _limit: 10,
      _order: PaginationOrderEnum.DESC,
      _sortBy: SortByEnum.name
    },
    usersPageOffset: 0,
    accountSettings: {}
  }
};
const reducers = {
  setAllAccountsLoader: loadersReducerForKey('allAccountsState'),
  setAllAccountsError: errorsReducerForKey('allAccountsState'),
  setAllAccountsState: typeReducerForKey('allAccountsState'),
  setSelectedAccountState: typeReducerNestedKey('allAccountsState', 'selectedAccount'),
  resetAllAccountsState: resetStateByKey('allAccountsState', {
    allAccountsState: allAccountsInitialState
  })
};
const actions = {
  loadAccounts: createAction(`${authStoreName}/loadAccounts`, payload => ({
    payload
  })),
  searchAccounts: createAction(`${authStoreName}/searchAccounts`, payload => ({
    payload
  })),
  updateSubAccountAccessForUser: createAction(`${authStoreName}/updateSubAccountAccessForUser`, payload => ({
    payload
  })),
  createSubAccount: createAction(`${authStoreName}/createSubAccount`, payload => ({
    payload
  })),
  updateSubAccount: createAction(`${authStoreName}/updateAccount`, payload => ({
    payload
  })),
  deleteSubAccount: createAction(`${authStoreName}/deleteAccount`, payload => ({
    payload
  })),
  updateSubAccountSettings: createAction(`${authStoreName}/updateSubAccountSettings`, payload => ({
    payload
  })),
  addUsersToAccount: createAction(`${authStoreName}/addUsersToAccount`, payload => ({
    payload
  })),
  getAccountUsers: createAction(`${authStoreName}/getAccountUsers`, payload => ({
    payload
  })),
  deleteUsersFromAccount: createAction(`${authStoreName}/deleteUsersFromAccount`, payload => ({
    payload
  })),
  setUserRolesForSubAccount: createAction(`${authStoreName}/setUserRolesForSubAccount`, payload => ({
    payload
  })),
  getUserJwtForSubAccount: createAction(`${authStoreName}/getUserJwtForSubAccount`, payload => ({
    payload
  })),
  setRootAccountData: createAction(`${authStoreName}/setRootAccountData`),
  setSelectedSubAccountData: createAction(`${authStoreName}/setSelectedSubAccountData`, payload => ({
    payload
  })),
  loadNumberOfUsersForSelectedSubAccount: createAction(`${authStoreName}/loadNumberOfUsersForSelectedSubAccount`, payload => ({
    payload
  }))
};
// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { allAccountsInitialState as allAccountsState, reducers as allAccountsReducers, actions as allAccountsActions };