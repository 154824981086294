/* eslint-disable @typescript-eslint/no-explicit-any */
import { Utilities } from '@SiteOwl/core';
import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'fileSort'
})
export class FileSortPipe implements PipeTransform {

  transform(items: any[]): any[] {
    if (Utilities.isNull(items)) {
      return items || [];
    }
    const sortedFiles = _.sortBy(items, [function (a) {
      if (a.fileName === "(empty)") {
        a.fileName = "";
      }
      if (Utilities.isEmpty(a.fileName.toLowerCase())) {
        return a.fileName
      } else {
        return a.fileName.toLowerCase()
      }
    }]);
    return sortedFiles;
  }

}
