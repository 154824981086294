import { ContextHolder } from '@frontegg/rest-api';
import { delay, put, select, call } from 'redux-saga/effects';
import { loadCustomLoginRoutes } from '../../CustomLoginState/saga';
import { actions } from '../../reducer';
import { getPathAndSearchParamsFromUrl, getRedirectUrl, isAbsoluteUrl } from '../utils';
import { FRONTEGG_AFTER_AUTH_REDIRECT_URL } from '../../../constants';
import { isSteppedUp } from '../../StepUpState';
import { SHOULD_STEP_UP_KEY } from '../../StepUpState/consts';

/**
 * @param url
 * @returns url without the origin if it's the same origin as the current window origin
 */
function cleanUrlIfSameOrigin(url) {
  var _window, _window2;
  if (!url.startsWith((_window = window) == null ? void 0 : _window.location.origin)) {
    return url;
  }
  return url.replace((_window2 = window) == null ? void 0 : _window2.location.origin, '');
}

/**
 * @param customLoginAuthenticatedUrl custom login authenticated url if exists
 * @returns the authenticated url to redirect to after auth navigation
 */
function* getUrlForAfterAuthNavigation(customLoginAuthenticatedUrl) {
  const {
    routes,
    includeQueryParam,
    enforceRedirectToSameSite = false,
    allowedRedirectOrigins = []
  } = yield select(state => state.auth);
  if (customLoginAuthenticatedUrl) {
    return getPathAndSearchParamsFromUrl(customLoginAuthenticatedUrl);
  }
  const {
    authenticatedUrl,
    loginUrl,
    logoutUrl,
    socialLoginCallbackUrl,
    activateUrl
  } = routes;
  let finalUrl = window.localStorage.getItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
  if (!finalUrl || [loginUrl, logoutUrl, socialLoginCallbackUrl, activateUrl].includes(finalUrl)) {
    finalUrl = authenticatedUrl;
  }
  const redirectUrl = getRedirectUrl({
    authenticatedUrl: finalUrl,
    includeQueryParam,
    enforceRedirectToSameSite,
    allowedRedirectOrigins
  });

  // clean origin if it's the same origin as the current window origin to avoid refresh in afterAuthNavigationUtil
  return cleanUrlIfSameOrigin(redirectUrl);
}
/**
 * Utility to share after auth navigation flow between login and step up
 * @param resetStateAction reset state action
 * @param customLoginAuthenticatedUrl custom login authenticated url if exists
 * @param shouldStepUpDuringLogin true when it's login after step up flow
 */
export function* afterAuthNavigationUtil(resetStateAction, {
  customLoginAuthenticatedUrl,
  forceStepUpUrl,
  shouldStepUpDuringLogin
} = {}) {
  const onRedirectTo = ContextHolder.onRedirectTo;
  let redirectUrl = undefined;
  if (forceStepUpUrl) {
    // scenario to get to here: invalid max age, try to step up -> logout, login with magic code/link -> redirect to step up page for email code as the second factor
    // we don't want to remove the FRONTEGG_AFTER_AUTH_REDIRECT_URL when we are in the step up flow
    redirectUrl = forceStepUpUrl;
  } else {
    var _window3;
    if (shouldStepUpDuringLogin) {
      // getUrlForAfterAuthNavigation give priority to the redirectUrl
      // avoiding use of getUrlForAfterAuthNavigation because we don't want to use the redirectUrl for magic link for example
      const localStorageRedirectUrl = window.localStorage.getItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
      if (localStorageRedirectUrl && !isAbsoluteUrl(localStorageRedirectUrl)) {
        redirectUrl = localStorageRedirectUrl;
      }
    }
    if (!redirectUrl) {
      redirectUrl = yield call(getUrlForAfterAuthNavigation, customLoginAuthenticatedUrl);
    }
    (_window3 = window) == null ? void 0 : _window3.localStorage.removeItem(FRONTEGG_AFTER_AUTH_REDIRECT_URL);
  }
  yield delay(200);
  put(resetStateAction());
  onRedirectTo(redirectUrl, {
    refresh: redirectUrl.startsWith('http')
  });
}

/**
 * After auth navigation for login flow
 * Handling also step up scenario when user silently logout to continue to step up
 */
export function* afterAuthNavigation() {
  var _window4;
  const {
    routes: {
      customLoginAuthenticatedUrl,
      stepUpUrl
    }
  } = yield select(state => state.auth);

  // login with magic code, try to step up, no other mfa, invalid max age, force_enroll -> logout, login with first factor, not-stepped up jwt -> navigate to step up
  const shouldStepUp = (_window4 = window) == null ? void 0 : _window4.localStorage.getItem(SHOULD_STEP_UP_KEY);
  const user = yield select(({
    auth
  }) => auth.user);
  if (shouldStepUp) {
    var _window5;
    (_window5 = window) == null ? void 0 : _window5.localStorage.removeItem(SHOULD_STEP_UP_KEY);
  }
  if (stepUpUrl && shouldStepUp && !isSteppedUp(user)) {
    yield call(afterAuthNavigationUtil, actions.resetLoginState, {
      forceStepUpUrl: stepUpUrl
    });
    return;
  }
  let customLoginURL = customLoginAuthenticatedUrl;
  if (!customLoginAuthenticatedUrl) {
    yield call(loadCustomLoginRoutes);
    customLoginURL = yield select(state => {
      var _state$auth$routes;
      return (_state$auth$routes = state.auth.routes) == null ? void 0 : _state$auth$routes.customLoginAuthenticatedUrl;
    });
  }
  yield call(afterAuthNavigationUtil, actions.resetLoginState, {
    customLoginAuthenticatedUrl: customLoginURL,
    shouldStepUpDuringLogin: !!shouldStepUp
  });
}

/**
 * After auth navigation for step up flow
 */
export function* afterStepUpAuthNavigation() {
  yield call(afterAuthNavigationUtil, actions.resetStepUpState);
}