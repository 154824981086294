import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { subscriptionsStoreName } from '../../constants';
import { createModuleCaseReducers } from '../utils';
export const configInitialState = {
  loading: false,
  error: null,
  fetching: true,
  config: null
};
const reducers = _extends({}, createModuleCaseReducers());
const {
  actions: configActions,
  reducer,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/config`,
  initialState: configInitialState,
  reducers
});
const actions = _extends({
  loadPaymentConfiguration: createAction(`${name}/loadPaymentConfiguration`)
}, configActions);
export { reducer as configReducer, actions as configActions };