<form [formGroup]="userForm"  novalidate>
    <div class="modal-header">
        <h1 class="modal-title pull-left">{{isEditMode ? 'Edit' : 'Create'}} User</h1>
        <button (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Name</label>
            <so-textbox [placeHolder]="'Name'" [maxLength]="80" [id]="'name'"
              [inputControlName]="$any(userForm).controls['name']">
            </so-textbox>
            <so-control-messages [name]="'Name'" [isFormSubmitted]="isFormSubmitted" [control]="$any(userForm).controls.name">
            </so-control-messages>
        </div>
        <div class="form-group">
            <label>Email Address
              <sup>*</sup>
            </label>
            <so-textbox [placeHolder]="'Email Address'" [maxLength]="80" [id]="'email'"
              [inputControlName]="$any(userForm).controls['email']">
            </so-textbox>
            <so-control-messages [name]="'Email Address'" [isFormSubmitted]="isFormSubmitted" [control]="$any(userForm).controls.email"></so-control-messages>
        </div>

        <div class="form-group">
            <label class="mb-1"> Role </label>
            <div>
                <div *ngFor="let item of roles; let i = index;" class="action-col d-flex">
                    <span class="radio-label">
                      <so-radio [className]="'lbl-check'" [id]="'amt_' + item.name" [name]="'role'"
                        (change)="roleChanged(item,'role')" [isChecked]="item.roleKey === roleSelection" [label]="item.name">
                      </so-radio>
                  </span>
                  <span class="action-label">{{item.description}}</span>
                </div>
            </div>
        </div>
        <div class="form-group">
          <label class="mb-1"> Employer </label>
            <so-textbox [placeHolder]="'Employer'" [maxLength]="80" [id]="'employer'"
              [inputControlName]="$any(userForm).controls['employer']">
            </so-textbox>
            <so-control-messages [name]="'Employer'" [isFormSubmitted]="isFormSubmitted" [control]="$any(userForm).controls.employer">
            </so-control-messages>
        </div>
        <div class="form-group mb-0" *ngIf="!isEditMode">
            <label class="mb-1">Password</label>
            <div class="action-col">
              <span class="radio-label">
                <so-radio [className]="'lbl-check'" [id]="'user-set-pass'" [name]="'isPasswordSetByAdmin'"
                  (change)="passwordSetChanged(false,'')" [isChecked]="!isTextFieldType" [label]="'Let the user set a password'">
                </so-radio>      
              </span>
            </div>
            <div class="action-col d-flex">
              <span class="radio-label d-flex">
                <so-radio [className]="'lbl-check'" [id]="'adming-set-pass'" [name]="'isPasswordSetByAdmin'"
                  (change)="passwordSetChanged(true,'')" [isChecked]="isTextFieldType" [label]="'Set password and email to user'">
                </so-radio> 
              </span>
              <div class="set-password ms-2 position-relative">
                    <so-textbox [placeHolder]="'****'" [maxLength]="80" [isPassword]="!isTextFieldTypeSetPassword"
                      [inputControlName]="$any(userForm).controls['password']" [id]="'password'">
                    </so-textbox>
                    <so-control-messages [name]="'Password'" [isFormSubmitted]="isFormSubmitted" [control]="$any(userForm).controls['password']">
                    </so-control-messages>
                    <span *ngIf="shoHidePassword" (click)="togglePasswordFieldType()" class="toggle-password">
                      <span title="Show Password" *ngIf="!isTextFieldTypeSetPassword"> <i class="far fa-eye"></i></span>
                      <span title="Hide Password" *ngIf="isTextFieldTypeSetPassword"><i class="far fa-eye-slash"></i></span>
                    </span>
              </div>
            </div>
        </div>
        <div class="form-group" *ngIf="isEditMode">
            <label class="mb-1">Password</label>
            <div class="action-col d-flex">
              <span class="radio-label">
                <so-radio [className]="'lbl-check'" [id]="'dont-change-pass'" [name]="'passwordUpdateFlag'" [value]="'DONT_CHANGE_PASSWORD'"
                  (change)="passwordSetChanged(false,'DONT_CHANGE_PASSWORD')" [isChecked]="true" [label]="strMessage">
                </so-radio> 
              </span>
            </div>
            <div class="action-col d-flex">
              <span class="radio-label d-flex">
                <so-radio [className]="'lbl-check'" [id]="'set-new-password'" [name]="'passwordUpdateFlag'"  [value]="'SET_NEW_PASSWORD'"
                  (change)="passwordSetChanged(true,'SET_NEW_PASSWORD')" [label]="'Set a new password:'">
                </so-radio>
                <div class="set-password ms-2 position-relative">
                  <so-textbox [placeHolder]="'****'" [maxLength]="80" [isPassword]="!isTextFieldTypeSetPassword"
                    [inputControlName]="$any(userForm).controls['password']" [id]="'password'">
                  </so-textbox>
                  <so-control-messages [name]="'Password'" [isFormSubmitted]="isFormSubmitted"
                    [control]="$any(userForm).controls['password']"></so-control-messages>
                  <span *ngIf="shoHidePassword" (click)="togglePasswordFieldType()" class="toggle-password">
                    <span title="Show Password" *ngIf="!isTextFieldTypeSetPassword "> <i class="far fa-eye"></i></span>
                    <span title="Hide Password" *ngIf="isTextFieldTypeSetPassword "><i class="far fa-eye-slash"></i></span>
                  </span>
                </div>
              </span>
            </div>
            <div class="action-col">
              <span class="radio-label">
              <so-radio [className]="'lbl-check'" [id]="'invalid-pass'" [name]="'passwordUpdateFlag'"  [value]="'INVALIDATE_PASSWORD_SEND_NEW_PASSWORD'"
                      (change)="passwordSetChanged(false,'INVALIDATE_PASSWORD_SEND_NEW_PASSWORD')" [label]="'Invalidate password and send password reset email'">
              </so-radio>
            </span>
            </div>
        </div>

        <div class="form-group" *ngIf="!isEditMode">
            <p>The user will receive an email notification about their new account.</p>
        </div>
    </div>
    <div class="modal-footer">
        <so-button (onClick)="closeModal()" [className]="'btn btn-secondary'" [text]="'Cancel'"> </so-button>
        <so-button  [type]="'submit'" (onClick)="saveUser()" [style]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}"  
          [className]="'btn btn-primary m-0'" [text]="isEditMode ? 'Update' : 'Create'" [disabled]="btnService.getStatus()"> 
        </so-button>
    </div>
</form>