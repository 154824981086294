<div class="creat-new-modal" (click)="$event.stopPropagation();">
    <div class="modal-header">
      <h1 [hidden]="isFromProject || isFromTicket || isFromDevice || isFromDeviceList" class="modal-title pull-left">Shareable Link to
        {{customerName}}</h1>
      <h1 [hidden]="!isFromProject || isFromDeviceList" class="modal-title pull-left">Shareable Link to {{projectName}}</h1>
      <h1 [hidden]="!isFromTicket" class="modal-title pull-left">Shareable Link to {{ticketName}}</h1>
      <h1 [hidden]="!isFromDevice && !isFromDeviceList" class="modal-title pull-left">Shareable Link to {{deviceName}}</h1>
  
      <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
        <em class="fas fa-close"></em>
      </button>
    </div>
    <div class="modal-body mt-2">
  
      <p [hidden]="isFromProject || isFromTicket || isFromDevice || isFromDeviceList">Copy the link below to allow easy access to
        <strong>{{customerName}}</strong>. Users
        that do not have access to <strong>{{customerName}}</strong> will not be able to use this link.
      </p>
  
      <p [hidden]="!isFromTicket">Copy the link below to allow easy access to
        <strong>{{ticketName}}</strong>. Users
        that do not have access to <strong>{{ticketName}}</strong> will not be able to use this link.
  
      <p [hidden]="!isFromProject || isFromDeviceList">Copy the link below to allow easy access to
        <strong>{{projectName}}</strong>. Users
        that do not have access to <strong>{{projectName}}</strong> will not be able to use this link.
      </p>
  
      <p [hidden]="!isFromDevice && !isFromDeviceList">Copy the link below to allow easy future access to <strong>{{deviceName}}</strong>.
        Users
        that do not have access to <strong>{{deviceName}}</strong> will not be able to use this link.
      </p>
      <div class="d-flex align-items-center mt-4 mb-5">
        <div class="url-link d-flex align-items-center" title="{{urlToCopy}}"><em
            class="fas fa-link icon-shareable-link"></em>
          <span id="urlToCopy" class="shareable-link"> {{urlToCopy}}</span>
        </div>
        <button (click)="copyLink()" class="btn btn-primary me-0" type="button">
          Copy Link
        </button>
      </div>
    </div>
    <div class="modal-footer">
      <span>
        <button type="button" (click)="closeModal()" class="btn btn-secondary me-0">Close</button>
  
      </span>
    </div>
  </div>