/* eslint-disable @typescript-eslint/no-explicit-any */
import { Customer, ChipsOptions, ListService, CustomerService, ButtonEnableDisableService, MessageService, Utilities, ValidationService, Roles, User, Pattern, AuthService, Environment, ProviderRoles, CommonService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit, Renderer2, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'so-invite-user',
    templateUrl: './invite-user.component.html',
    styleUrls: ['./invite-user.component.scss'],
    animations: [
        trigger('fromUserPicker', [
            transition(':enter', [
                style({ position: 'fixed' }),
            ]),
            transition(':leave', [
                group([
                    animate('0.5s ease', style({
                        transform: 'translateY(300px)'
                    })),
                    animate('0.9s 0.3s ease', style({
                        opacity: 0
                    }))
                ])
            ])
        ]),
        trigger('fromTagList', [
            transition(':enter', [
                style({ position: 'fixed' }),
            ]),
            transition(':enter', [
                group([
                    query('.ng2-tag-input.foundation-theme tag', animate('0.7s ease', style({
                        transform: 'translateY(-300px)'
                    }))),
                    query('.ng2-tag-input.foundation-theme tag', animate('0.9s 0.3s ease', style({
                        opacity: 0
                    })))
                ])
            ])
        ])
    ]
})
export class InviteUserComponent implements OnInit {

    isEditMode = false;
    isEditProject!: boolean
    nullValue: any = null;
    userDetail: any;
    userForm!: FormGroup;
    customers!: Customer[];
    providerUser!: any[];
    providerEmployer: any;
    customerId!: number;
    isFormSubmitted = false;
    currentUser: any;
    roles: any = {
        siteRole: [],
        projectRole: []
    };
    isInvalidEmail = false;
    setData: any;
    chipsOption = ChipsOptions;
    users!: any[];
    usersForList!: any[];
    defaultUsersForList!: any[];
    usersForAutoComplete!: any[];
    defaultUsersAutoComplete!: any[];
    providerRoles = ProviderRoles;
    isAnimationStart = false;
    emailList: any = [];
    addAnimationIndex = {
        ui: -1,
        li: -1
    }
    amtRoleSelection: any = 'amt_Administrator';
    projectRoleSelection: any = 'project_Manager';

    @ViewChild('input') input: any;

    emailRequired = false;
    byDefaultUserObj: any
    userData: any;
    customerDetail: any;
    newRoleSelection: any;
    newProjectRoleSelection: any;
    isIntegraotAdminOrCoordinator = false;
    isLiveSystemAccessForCustomer: any;
    cdnUrl = this.env.cdnUrl;
    adminRole: any;
    isSettingPage = false;
    public event: EventEmitter<any> = new EventEmitter();

    constructor(
        private listSerivce: ListService,
        private customerService: CustomerService,
        private fb: FormBuilder, private commanService: CommonService,
        public btnService: ButtonEnableDisableService, public bsModalRef: BsModalRef,
        private authService: AuthService, private readonly env: Environment,
        private messageService: MessageService, private renderer: Renderer2) { }

    filterAndSortUsers() {
        for (let k = 0; k < this.providerRoles.length; k++) {
            const level = this.providerRoles[k];
            if (this.users?.filter(user => user.role.name === level && user.user.isActive === 1 && user.status === 'accept').length > 0) {
                this.usersForList.push({ key: level, value: this.sortUserList(this.users.filter(user => user.role.name === level && user.user.isActive === 1 && user.status === 'accept')) });
            }
        }
    }
    private sortUserList(items: any) {
        return _.sortBy(items, [function (a) {
            if (Utilities.isEmpty(a.name)) {
                return a.email.toLowerCase();
            } else {
                return a.name.toLowerCase();
            }
        }]);
    }

    private getRoles() {
        this.userForm.controls['role'].setValue(this.roles.siteRole.find((x: any) => x.id === this.userDetail.role.id), { onlySelf: true });
    }
    private getProjectRoles() {
        if (!this.roles.projectRole.find((x: any) => this.userDetail.projectRole && x.id === this.userDetail.projectRole.id)) {
            this.userForm.controls['projectRole'].setValue('');
            return;
        }
        this.userForm.controls['projectRole'].setValue(this.roles.projectRole.find((x: any) => x.id === this.userDetail.projectRole.id)
            , { onlySelf: true });
    }

    private setFormValue() {
        this.userForm.patchValue(this.userDetail, { onlySelf: true });
        this.userForm.controls['name'].disable();
        if (this.customerId === null) {
            this.userForm.controls['email'].disable();
        }
        this.userForm.controls['employer'].disable();
    }

    private createForm() {
        this.userForm = this.fb.group({
            name: ['', [Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
            employer: ['', [Validators.maxLength(80)]],
            email: [null],
            emails: [[]],
            role: ['', [Validators.required]],
            projectRole: ['', [Validators.required]]
        });
        if (!this.providerEmployer || (!this.providerEmployer.projectId)) {

            this.userForm.controls['projectRole'].setValue(this.roles.projectRole.find((x: any) => x.id === 4), { onlySelf: true });
            if (this.roles.projectRole.find((x: any) => x.id === 5) && this.newProjectRoleSelection === undefined) {
                this.newProjectRoleSelection = Utilities.selectByDefaultRole(this.roles.projectRole, 4);
                this.projectRoleSelection = Utilities.selectByDefaultRole(this.roles.projectRole, 4);
            }
            this.userForm.controls['role'].setValue(this.roles.siteRole.find((x: any) => x.id === 4), { onlySelf: true });
            if (this.roles.siteRole.find((x: any) => x.id === 4) && this.newRoleSelection === undefined) {
                this.newRoleSelection = Utilities.selectByDefaultRole(this.roles.siteRole, 'null');
                this.amtRoleSelection = Utilities.selectByDefaultRole(this.roles.siteRole, 'null');
            }
        } else {
            if (!this.isEditMode) {
                this.roles.projectRole.forEach((pr: any, index: number) => {
                    if (index === this.providerEmployer.projectId) {
                        this.userForm.controls['projectRole'].setValue(pr, { onlySelf: true });
                        this.newProjectRoleSelection = this.roles.projectRole.find((e: any) => e.id === pr.id).roleKey;
                        this.projectRoleSelection = this.roles.projectRole.find((e: any) => e.id === pr.id).roleKey;
                    }
                })
                this.roles.siteRole.forEach((sr: any, index: number) => {
                    if (index === this.providerEmployer.amtId) {
                        this.userForm.controls['role'].setValue(sr, { onlySelf: true });
                        this.newRoleSelection = this.roles.siteRole.find((e: any) => e.id === sr.id).roleKey;
                        this.amtRoleSelection = this.roles.siteRole.find((e: any) => e.id === sr.id).roleKey;
                    }
                })
            }
        }
    }

    addUser(isUt?: boolean) {
        const user = this.userForm.value;
        if (!this.isLiveSystemAccessForCustomer) {
            const keyForRole = user.projectRole.roleKey.split('_')[1];
            if (keyForRole === 'Lead Technician') {
                user.role = this.roles.siteRole.find((x: any) => x.id === 8);
            } else {
                user.role = this.roles.siteRole.find((x: any) => x.roleKey.includes(keyForRole));
            }
        }
        const emails = this.userForm.get('emails')?.value;
        if (this.providerUser.length !== 0) {
            this.btnService.enable()
            const providerUserData = {
                user: { id: user.info.user.id },
                customer: { id: Number(this.customerId) },
                projectRole: user.projectRole,
                role: user.role,
                isAdmin: this.userDetail.isAdmin
            };

            this.customerService.addCustomerUser(providerUserData).subscribe({
                next: (result: any) => {
                    this.btnService.disable()
                    this.messageService.successMessage(`User: ${user.info.name === '' || user.info.name === null ? user.info.email : user.info.name}`, 'Added Successfully');
                    this.messageService.successMessage('User(s):', 'Invited Successfully');
                    this.event.emit(providerUserData);
                    this.bsModalRef.hide();
                },
                error: (error: any) => {
                    this.btnService.disable()
                    if (error.status === 409 || error.status === 422) {
                        this.messageService.errorMessage(`User`, error.error.message);
                    } else {
                        this.bsModalRef.hide();
                        this.messageService.errorMessage(`Error`, 'Error while saving');
                    }
                }
            });


        } else {
            if (Utilities.isNull(this.customerId)) {
                user['customerId'] = this.authService.getCustomerId().customerId;
            } else {
                user['customerId'] = Number(this.customerId);
            }

            if (this.users.length > 0) {
                user.emails = [];
                emails.forEach((element: any) => {
                    if (element.email) {
                        user.emails.push(element.email);
                    } else {
                        user.emails.push(element);
                    }
                });
                user.emails.forEach((v: any, k: any) => {
                    if (!this.users.some(x => x.email === v) && !(this.currentUser.isAdmin || this.authService.getDecodedJWTToken().userRole === Roles.Administrator)) {
                        user.emails.splice(k, 1);
                    }
                });
            }
            this.emailList = _.cloneDeep(user.emails);
            user.isAdmin = this.userDetail.isAdmin;
            if (user.emails.length < 1 && !isUt && !(this.currentUser.isAdmin || this.authService.getDecodedJWTToken().userRole === Roles.Administrator)) {
                this.messageService.errorMessage(`Error`, 'User not found');
                return false;
            }
            if (!isUt) {
                this.btnService.disable()
                const customerId = user.customerId;
                delete user.customerId;
                if (this.isSettingPage) {
                    this.customerService.addSpecificCustomerUser(user).subscribe({
                        next: (result: any) => {
                            this.btnService.enable()
                            this.messageService.successMessage('Account User(s):', 'Invited Successfully');
                            this.event.emit(result);
                            this.bsModalRef.hide();
                        },
                        error: (error: any) => {
                            this.btnService.enable()
                            if (error.status === 409 || error.status === 422) {
                                this.messageService.errorMessage(`User`, error.error.message);
                            } else {
                                if (error.status !== 422) {
                                    this.messageService.errorMessage(`Error`, 'Error while saving');
                                }
                            }
                        }
                    });
                } else {
                    this.customerService.addCustomerUserFromManage(user, customerId).subscribe({
                        next: (result: any) => {
                            this.btnService.enable()
                            this.messageService.successMessage('Account User(s):', 'Invited Successfully');
                            this.event.emit(result);
                            this.bsModalRef.hide();
                        },
                        error: (error: any) => {
                            this.btnService.enable()
                            if (error.status === 409 || error.status === 422) {
                                this.messageService.errorMessage(`User`, error.error.message);
                            } else {
                                if (error.status !== 422) {
                                    this.messageService.errorMessage(`Error`, 'Error while saving');
                                }
                            }
                        }
                    });
                }
            }
        }
        return null;

    }

    private updateUser(exit?: boolean) {
        const user: any = this.userForm.value;
        user.id = this.userDetail.id;
        user.name = this.userDetail.name;
        user.email = this.userDetail.email;
        user.employer = this.userDetail.employer;
        user.emailReport = this.userDetail.emailReport;
        user.isAdmin = this.userDetail.isAdmin;
        this.btnService.disable()
        if (this.providerUser.length !== 0) {
            this.customerService.updateCustomerUser(user?.id, user).subscribe({
                next: (result: any) => {
                    this.messageService.successMessage(`User: ${user.name === '' || user.name === null ? user.email : user.name}`, 'Saved Successfully');
                    this.btnService.enable()
                    this.event.emit(result);
                    this.bsModalRef.hide();
                },
                error: (updateError: any) => {
                    this.btnService.enable()
                    if (updateError.status === 409 || updateError.status === 422) {
                        this.messageService.errorMessage(`User`, updateError.error.message);
                    } else {
                        this.messageService.errorMessage(`Error`, 'Error while saving');
                    }
                }
            });
        } else {
            this.customerService.updateCustomerSpecificUser(user).subscribe({
                next: (result: any) => {
                    this.btnService.enable();
                    this.messageService.successMessage(`User: ${user.name === '' || user.name === null ? user.email : user.name}`, 'Saved Successfully');
                    this.event.emit(result);
                    this.bsModalRef.hide();
                },
                error: (error: any) => {
                    this.btnService.enable();
                    if (error.status === 409 || error.status === 422) {
                        this.messageService.errorMessage(`User`, error.error.message);
                    } else {
                        this.messageService.errorMessage(`Error`, 'Error while saving');
                    }
                }
            });
            // this.customerService.addSpecificCustomerUser(user).subscribe({
            //     next: (result: any) => {
            //         this.btnService.enable();
            //         this.messageService.successMessage(`User: ${user.name === '' || user.name === null ? user.email : user.name}`, 'Saved Successfully');
            //         this.event.emit(result);
            //         this.bsModalRef.hide();
            //     },
            //     error: (error: any) => {
            //         this.btnService.enable();
            //         if (error.status === 409 || error.status === 422) {
            //             this.messageService.errorMessage(`User`, error.error.message);
            //         } else {
            //             this.messageService.errorMessage(`Error`, 'Error while saving');
            //         }
            //     }
            // });
        }
    }

    ngOnInit() {
        this.roles.siteRole = _.cloneDeep(Utilities.seperateRoleDescription(this.roles.siteRole));
        this.roles.projectRole = _.cloneDeep(Utilities.seperateRoleDescription(this.roles.projectRole));
        this.byDefaultUserObj = _.cloneDeep(this.userDetail);
        if (this.isEditMode && this.userDetail.role) {
            const keySelection = this.roles.siteRole.filter((z: any) => z.id === this.userDetail.role.id)[0];
            if (keySelection) {
                this.userDetail.role = keySelection;
                this.amtRoleSelection = keySelection.roleKey;
                this.newRoleSelection = this.amtRoleSelection;
            }
        }
        if (this.isEditMode && this.userDetail.projectRole) {
            const keySelection = this.roles.projectRole.filter((z: any) => z.id === this.userDetail.projectRole.id)[0];
            if (keySelection) {
                this.userDetail.projectRole = keySelection;
                this.projectRoleSelection = keySelection.roleKey
                this.newProjectRoleSelection = Utilities.selectByDefaultRole(this.roles.projectRole, keySelection.id)
            }
        }
        this.usersForAutoComplete = [];
        this.usersForList = [];
        this.filterAndSortUsers();
        this.defaultUsersForList = _.cloneDeep(this.usersForList);
        if (this.users) {
            this.users.forEach(x => {
                if (x.user.isActive === 1 && x.status === 'accept') {
                    this.usersForAutoComplete.push({ value: x, display: x.email });
                }
            });
        }
        this.defaultUsersAutoComplete = _.cloneDeep(this.usersForAutoComplete);
        this.userData = this.authService.getUserKeyData();
        this.getAdminRole()
        this.currentUser = this.authService.getUserKeyData();
        this.createForm();
        if (this.isEditMode) {
            this.setFormValue();
            this.getRoles();
            if (this.isEditProject) {
                this.getProjectRoles();
            }
        }
    }


    saveUser() {
        const timeOut = setTimeout(() => {
            const email = this.userForm.get('emails')?.value || [];
            this.isInvalidEmail = email.some((x: any) => Utilities.isNull(x.email) ? this.toCheckInvalidInput(x) : this.toCheckInvalidInput(x.email));
            if (this.userForm.invalid || this.isInvalidEmail || email.length === 0) {
                this.isFormSubmitted = true;
                return false;
            }
            if (this.isEditMode) {
                this.updateUser();
            } else {
                this.addUser();
            }
            clearTimeout(timeOut)
            return null;
        }, 100);
    }

    closeModal() {
        if (this.byDefaultUserObj.role && this.byDefaultUserObj.role.id > 0 && this.userDetail && this.userDetail.role && this.userDetail.role.id !== this.byDefaultUserObj.role.id) {
            this.roleChanged(this.byDefaultUserObj.role, 'siteRole');
        }
        if (this.byDefaultUserObj.projectRole && this.byDefaultUserObj.projectRole.id > 0 && this.userDetail && this.userDetail.projectRole && this.userDetail.projectRole.id !== this.byDefaultUserObj.projectRole.id) {
            this.roleChanged(this.byDefaultUserObj.projectRole, 'projectRole');
        }
        this.bsModalRef.hide();
    }
    onInputBlurred(event: any, isRemove?: any) {
        const data = this.userForm.get('emails')?.value;
        this.isInvalidEmail = data.some((x: any) => Utilities.isNull(x.email) ? this.toCheckInvalidInput(x) : this.toCheckInvalidInput(x.email));

        if (!Utilities.isEmpty(this.setData)) {
            const datas = this.userForm.get('emails')?.value;

            data.forEach((x: any, k: any) => {
                if (Utilities.isNull(x.email)) {
                    if (x === this.setData) {
                        datas.splice(k, 1);
                    }
                } else {
                    if (x.email === this.setData) {
                        datas.splice(k, 1);
                    }
                }
            });

            this.userForm.get('emails')?.setValue(datas);
            this.setData = '';
        }
        this.toUpdateAutoCompleteData();

        this.isInvalidEmail = data.some((x: any) => Utilities.isNull(x.email) ? this.toCheckInvalidInput(x) : this.toCheckInvalidInput(x.email));
        if (isRemove && isRemove === 'remove') {
            this.isAnimationStart = true;
        } else {
            this.isAnimationStart = false;
            if (this.users.length > 0 && Utilities.isNull(event) &&
                this.isIntegraotAdminOrCoordinator && (!this.users.some(x => x.email === event) &&
                    !(this.currentUser.isAdmin || this.authService.getDecodedJWTToken().userRole === Roles.Administrator))) {
                this.messageService.errorMessage(`Error`, 'User not found');
            }
        }
    }

    addUserToEmails(lIndex: number, uIndex: number, user: any) {
        if (lIndex > -1 && uIndex > -1) {
            this.addAnimationIndex.li = lIndex;
            this.addAnimationIndex.ui = uIndex;
            const data = this.userForm.get('emails')?.value || [];
                data.push(user);
                this.toUpdateAutoCompleteData();
                this.addAnimationIndex.li = -1;
                this.addAnimationIndex.ui = -1;
        }
    }

    toUpdateAutoCompleteData() {
        const realData = this.userForm.get('emails')?.value || [];
        this.usersForAutoComplete = _.cloneDeep(this.defaultUsersAutoComplete);
        this.usersForList = _.cloneDeep(this.defaultUsersForList);

        realData.forEach((element: any) => {
            if (Utilities.isNull(element.email)) {
                const realDataIndex = this.defaultUsersAutoComplete.findIndex(x => x.value.email === element);
                if (realDataIndex > -1) {
                    this.usersForAutoComplete.forEach((v, i) => {
                        if (v.value.email === element) {
                            this.usersForAutoComplete.splice(i, 1);
                        }
                    });
                    const levelIndex = this.usersForList.findIndex(x => x.key === this.defaultUsersAutoComplete[realDataIndex].value.role.name);
                    if (levelIndex > -1) {
                        const listIndex = this.usersForList[levelIndex].value.findIndex((x: any) => x.email === element);
                        this.usersForList[levelIndex].value.splice(listIndex, 1);
                    }
                }
            } else {
                const realDataIndex = this.defaultUsersAutoComplete.findIndex(x => x.value.email === element.email);
                if (realDataIndex > -1) {
                    this.usersForAutoComplete.forEach((v, i) => {
                        if (v.value.email === element.email) {
                            this.usersForAutoComplete.splice(i, 1);
                        }
                    });
                    const levelIndex = this.usersForList.findIndex(x => x.key === this.defaultUsersAutoComplete[realDataIndex].value.role.name);
                    if (levelIndex > -1) {
                        const listIndex = this.usersForList[levelIndex].value.findIndex((x: any) => x.email === element.email);
                        this.usersForList[levelIndex].value.splice(listIndex, 1);
                    }
                }
            }
        });
    }

    toCheckInvalidInput(item: any) {
        if (!item || item.match(Pattern.EMAIL)) {
            return false;
        } else {
            return true;
        }
    }

    toCheckEmailFormList(item: any) {
        return this.users.some(z => item.indexOf(z.email) > -1);
    }

    toAddBorderOnInvaidSpanTag(data: any) {
        if (document.querySelectorAll('tag').length > 0) {
            for (let index = 0; index < document.querySelectorAll('tag').length; index++) {
                const element: any = document.querySelectorAll('tag')[index];

                const text = element['innerText'].replace(/(\r\n\t|\n|\r\t)/gm, '');
                if (this.toCheckInvalidInput(data)) {
                    this.renderer.setStyle(element, 'border-color', '#e00b0b');
                } else {
                    if (this.users.length > 0 && (!this.toCheckEmailFormList(text) && !(this.currentUser.isAdmin || this.authService.getDecodedJWTToken().userRole === Roles.Administrator))) {
                        this.renderer.setStyle(element, 'border-color', '#e00b0b');
                    } else {
                        this.renderer.setStyle(element, 'border-color', '#003F77');
                    }
                }
            }
        }
    }

    editItem(item: any) {
        const timeOut = setTimeout(() => {
            this.setData = item;
            clearTimeout(timeOut)
        }, 100);
    }

    removeTags(elem: any, item: any, i: any) {
        const email = item && item.email ? item.email : item;
        if (this.usersForList.length > 0 && this.users.some(x => x.email === email)) {
            this.renderer.addClass(elem.tags.last.element.nativeElement, 'removeAnimation');
            const timeOut = setTimeout(() => {
                elem.removeItem(item, i);
                clearTimeout(timeOut)
            }, 400);
        } else {
            elem.removeItem(item, i);
        }
    }

    roleChanged(item?: any, type?: any, evt?: any): void {
        if (type === 'siteRole') {
            this.userForm.value.role = item;
            this.userDetail.role = item;
            this.userForm.controls['role'].setValue(this.roles.siteRole.find((x: any) => x.id === this.userDetail.role.id), { onlySelf: true });
            if (evt !== undefined) {
                this.newRoleSelection = evt.target.id;
            }
        }
        if (type === 'projectRole') {
            this.userForm.value.projectRole = item;
            this.userDetail.projectRole = item;
            this.userForm.controls['projectRole'].setValue(this.roles.projectRole.find((x: any) => x.id === this.userDetail.projectRole.id), { onlySelf: true });
            if (evt !== undefined) {
                this.newProjectRoleSelection = evt.target.id;
            }
        }
    }
    isAdminChanged(event: any) {
        if (event.target.checked) {
            this.userDetail.isAdmin = true;
            this.newRoleSelection = '';
            this.amtRoleSelection = '';
            this.projectRoleSelection = '';
            this.newProjectRoleSelection = '';
        } else {
            this.userDetail.isAdmin = false;
            this.newRoleSelection = 'amt_Manager';
            this.amtRoleSelection = 'amt_Manager';
            this.projectRoleSelection = 'project_Manager';
            this.newProjectRoleSelection = 'project_Manager';
        }
        this.userForm.value.role = this.roles.siteRole.filter((e: any) => e.roleKey === 'amt_Manager')[0];
        this.userDetail.role = this.roles.siteRole.filter((e: any) => e.roleKey === 'amt_Manager')[0];
        this.userForm.controls['role'].setValue(this.roles.siteRole.find((x: any) => x.id === this.userDetail.role.id), { onlySelf: true });
        this.userForm.value.projectRole = this.roles.projectRole.filter((e: any) => e.roleKey === 'project_Manager')[0];
        this.userDetail.projectRole = this.roles.projectRole.filter((e: any) => e.roleKey === 'project_Manager')[0];
        this.userForm.controls['projectRole'].setValue(this.roles.projectRole.find((x: any) => x.id === this.userDetail.projectRole.id), { onlySelf: true });
    }
    ngOnDestroy() {
        this.btnService.enable();
    }
    getAdminRole() {
        this.adminRole = this.commanService.getLocalStorageObject('adminRole')
        if (this.adminRole === undefined || this.adminRole === null) {
            this.listSerivce.getAdminSpecificRoleList().subscribe({
                next: (result: any) => {
                    this.adminRole = Utilities.seperateRoleDescription(result);
                    this.commanService.setLocalStorageObject('adminRole', this.adminRole);
                }
            })
        }
    }
}


