import _extends from "@babel/runtime/helpers/esm/extends";
import { ContextHolder } from './ContextHolder';
import { fronteggHeaders } from './interfaces';
import { FronteggApiError } from './error';
import { GENERIC_ERROR_MESSAGE } from './constants';
export function getBaseUrl(context, url, withFronteggPrefix = true) {
  let baseUrl;
  if (typeof context.baseUrl === 'function') {
    baseUrl = context.baseUrl(url);
  } else {
    baseUrl = context.baseUrl;
  }
  const prefix = context.urlPrefix || 'frontegg';
  const removePrefix = prefix === 'frontegg' && !withFronteggPrefix;
  if (!baseUrl.endsWith('/')) {
    baseUrl += '/';
  }
  if (!baseUrl.endsWith(prefix) && !removePrefix) {
    baseUrl += prefix;
  }
  return baseUrl;
}
async function prepareUrl(context, url, params) {
  const baseUrl = getBaseUrl(context, url);
  const paramsToSend = await buildQueryParams(context, params);
  let finalUrl = url.startsWith('http') ? url : `${baseUrl}${url}`;
  const hasKeys = Object.keys(paramsToSend).length > 0;
  if (paramsToSend && hasKeys) {
    const urlParams = new URLSearchParams(paramsToSend);
    finalUrl += `?${urlParams}`;
  }
  return finalUrl;
}
export function getMetadataHeaders(context) {
  var _context$metadataHead, _context$metadataHead2;
  const headers = {};
  if (context != null && (_context$metadataHead = context.metadataHeaders) != null && _context$metadataHead.fronteggSdkVersion) {
    headers[fronteggHeaders.fronteggSdkVersion] = context.metadataHeaders.fronteggSdkVersion;
  }
  if (context != null && (_context$metadataHead2 = context.metadataHeaders) != null && _context$metadataHead2.framework) {
    headers[fronteggHeaders.frameWork] = context.metadataHeaders.framework;
  }
  return headers;
}
export function getScopedTenant() {
  const urlParams = new URLSearchParams(window.location.search);
  const scopedTenant = urlParams.get('tenantId');
  if (scopedTenant) {
    return scopedTenant;
  }
  return null;
}
async function buildRequestHeaders(context, contentType) {
  var _context$tokenResolve;
  const headers = getMetadataHeaders(context);
  const authToken = await ((_context$tokenResolve = context == null ? void 0 : context.tokenResolver) != null ? _context$tokenResolve : ContextHolder.getAccessToken)();
  const requestSource = ContextHolder.getRequestSource();
  const scopedTenant = getScopedTenant();
  if (contentType) {
    headers[fronteggHeaders.contentType] = contentType;
  }
  if (requestSource) {
    headers[fronteggHeaders.source] = requestSource;
  }
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }
  if (scopedTenant) {
    headers[fronteggHeaders.scopedTenant] = scopedTenant;
  }
  for (const additionalHeader of await getAdditionalHeaders(context)) {
    headers[`${additionalHeader.key}`] = `${additionalHeader.value}`;
  }
  return headers;
}
async function buildQueryParams(context, params) {
  if (!params) {
    params = {};
  }
  const additionalQueryParams = await getAdditionalQueryParams(context);
  for (const queryParam of additionalQueryParams) {
    params[queryParam.key] = queryParam.value;
  }
  const keys = Object.keys(params);
  for (const key of keys) {
    const value = params[key];
    params[key] = typeof value === 'object' ? JSON.stringify(value) : value;
  }
  return params;
}
async function getAdditionalQueryParams(context) {
  let output = [];
  if (context.additionalQueryParamsResolver) {
    output = await context.additionalQueryParamsResolver();
  }
  return output;
}
async function getAdditionalHeaders(context) {
  let output = [];
  if (context.additionalHeadersResolver) {
    output = await context.additionalHeadersResolver();
  }
  if (context.tenantResolver) {
    const resolvedTenantResult = await context.tenantResolver();
    if (!!(resolvedTenantResult != null && resolvedTenantResult.tenant)) {
      output.push({
        key: 'frontegg-login-alias',
        value: resolvedTenantResult.tenant
      });
    }
  }
  return output;
}
const sendRequest = async opts => {
  var _opts$method, _ref, _opts$credentials;
  const context = ContextHolder.getContext();
  const headers = await buildRequestHeaders(context, opts.contentType);
  const url = await prepareUrl(context, opts.url, opts.params);
  const response = await fetch(url, {
    body: opts.body ? opts.contentType === 'application/json' ? JSON.stringify(opts.body) : opts.body : null,
    method: (_opts$method = opts.method) != null ? _opts$method : 'GET',
    headers: _extends({}, headers, opts.headers),
    credentials: (_ref = (_opts$credentials = opts.credentials) != null ? _opts$credentials : context.requestCredentials) != null ? _ref : 'same-origin'
  });
  if (response.status === 302) {
    window.location.href = await response.text();
    return new Promise(() => {});
  }
  if (!response.ok) {
    var _context$logLevel, _context$logLevel2;
    if (response.status === 413) {
      throw new FronteggApiError('Error request is too large', response.status);
    }
    let errorMessage;
    let isJsonResponse = true;
    try {
      errorMessage = await response.text();
      errorMessage = JSON.parse(errorMessage);
    } catch (e) {
      isJsonResponse = false;
    }
    if (errorMessage.errors) {
      errorMessage = errorMessage.errors.join(', ');
    } else if (typeof errorMessage !== 'string') {
      errorMessage = `Error ${response.status} - ${response.statusText}`;
    }
    if (response.status >= 400 && response.status < 500 && ['warn'].includes((_context$logLevel = context.logLevel) != null ? _context$logLevel : '')) console.warn(errorMessage);else if (response.status === 500 && ['warn', 'error'].includes((_context$logLevel2 = context.logLevel) != null ? _context$logLevel2 : '')) console.error(errorMessage);
    throw new FronteggApiError(isJsonResponse ? errorMessage : GENERIC_ERROR_MESSAGE, response.status);
  }
  if (!opts.responseType || opts.responseType === 'json') {
    try {
      return await response.json();
    } catch (e) {
      return {};
    }
  } else if (opts.responseType === 'blob') {
    const {
      outputFileName
    } = opts.params;
    return await response.blob().then(blob => URL.createObjectURL(blob)).then(url => {
      const tempLink = document.createElement('a');
      tempLink.href = url;
      tempLink.setAttribute('download', outputFileName || 'output');
      tempLink.click();
    });
  } else {
    return await response.text();
  }
};
export const Get = async (url, params, opts) => sendRequest(_extends({
  url,
  method: 'GET',
  contentType: 'application/json',
  params
}, opts));
export const Post = async (url, body, opts) => sendRequest(_extends({
  url,
  method: 'POST',
  contentType: 'application/json',
  body
}, opts));
export const Patch = async (url, body, opts) => sendRequest(_extends({
  url,
  method: 'PATCH',
  contentType: 'application/json',
  body
}, opts));
export const Put = async (url, body, opts) => sendRequest(_extends({
  url,
  method: 'PUT',
  contentType: 'application/json',
  body
}, opts));
export const Delete = async (url, body, opts) => sendRequest(_extends({
  url,
  method: 'DELETE',
  contentType: 'application/json',
  body
}, opts));
export const PostDownload = async (url, body, params, opts) => sendRequest(_extends({
  url,
  method: 'POST',
  contentType: 'application/json',
  responseType: 'blob',
  body,
  params
}, opts));
export const extractHeadersFromOptions = (options = {}) => _extends({}, options.jwt ? {
  Authorization: options.jwt
} : {});