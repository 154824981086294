/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'multiPlanFilter'
})
export class MultiPlanFilterPipe implements PipeTransform {

  transform(items: any[], args: any): any[] {
    if (!items || !args) {
      return items.filter(x => x.category.name !== 'Favorites');
    }
    items = items.filter(item => {
      return item.type && item.type === args.type;
    });
    if (args.showPlanMarkupFilter === false) {
      items = items.filter(item => {
        return item.category.id !== -11;
      });
    }
    return items.filter(x => x.category.name !== 'Favorites');
  }

}
