import _extends from "@babel/runtime/helpers/esm/extends";
import { actions } from '../../reducer';
import { authStoreName } from '../../../constants';
import { AllAccountsStateKeys } from './types/stateTypes';
import { put, takeLatest, select as sagaSelect, call } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { errorHandler } from '../../../utils';
import { updateNodeName } from './utils/updateNodeName';
import { removeNodeFromTree } from './utils/removeNodeFromTree';
import { appendChildrenToNode } from './utils/appendChildrenToNode';
import { getAccountsWithUsersCount } from './utils/getAccountsWithUsersCount';
export const selectTenantsState = () => sagaSelect(_ => _[authStoreName].tenantsState);
export const selectAllAccountsState = () => sagaSelect(_ => _[authStoreName].allAccountsState);
function* loadAccounts({
  payload
}) {
  const key = AllAccountsStateKeys.GET_ACCOUNTS;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    accountsQueryParams
  } = yield selectAllAccountsState();
  const {
    jwt,
    _limit,
    _offset
  } = payload;
  const searchSubTenantsQueryParams = {
    _offset: _offset != null ? _offset : accountsQueryParams._offset,
    _limit: _limit != null ? _limit : accountsQueryParams._limit,
    _subTenantId: payload == null ? void 0 : payload._subTenantId
  };
  try {
    const isSelectedAccountChange = !!jwt;
    if (isSelectedAccountChange) {
      var _payload$_subTenantId;
      const {
        selectedAccount
      } = yield selectAllAccountsState();
      const {
        items,
        _links
      } = yield call(api.tenants.searchSubTenants, searchSubTenantsQueryParams, {
        jwt
      });
      const tenantsUsersCountArray = yield call(api.tenants.getTenantsUsersCount, {
        tenantIds: items.map(tenant => tenant.tenantId)
      }, {
        jwt
      });
      const accountsWithUsersCount = getAccountsWithUsersCount({
        items
      }, tenantsUsersCountArray);
      const updatedSingleAccountViewAccounts = appendChildrenToNode(selectedAccount.accounts ? selectedAccount.accounts : selectedAccount, (_payload$_subTenantId = payload == null ? void 0 : payload._subTenantId) != null ? _payload$_subTenantId : '', accountsWithUsersCount.map(account => ({
        name: account.name,
        tenantId: account.tenantId,
        numberOfUsers: account.numberOfUsers,
        createdAt: account.createdAt,
        metadata: account.metadata,
        children: [],
        loadMoreLink: _links == null ? void 0 : _links.next
      })));
      yield put(actions.setSelectedAccountState({
        accounts: updatedSingleAccountViewAccounts
      }));
    } else {
      var _payload$_subTenantId2;
      const {
        accounts
      } = yield selectAllAccountsState();
      const {
        items,
        _links
      } = yield call(api.tenants.searchSubTenants, searchSubTenantsQueryParams);
      const tenantsUsersCountArray = yield call(api.tenants.getTenantsUsersCount, {
        tenantIds: items.map(i => i.tenantId)
      });
      const accountsWithUsersCount = getAccountsWithUsersCount({
        items
      }, tenantsUsersCountArray);
      const updatedAccounts = appendChildrenToNode(accounts, (_payload$_subTenantId2 = payload == null ? void 0 : payload._subTenantId) != null ? _payload$_subTenantId2 : '', accountsWithUsersCount.map(account => ({
        name: account.name,
        tenantId: account.tenantId,
        numberOfUsers: account.numberOfUsers,
        createdAt: account.createdAt,
        metadata: account.metadata,
        children: [],
        loadMoreLink: _links == null ? void 0 : _links.next
      })));
      yield put(actions.setAllAccountsState({
        accounts: updatedAccounts
      }));
    }
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}
function* searchAccounts({
  payload
}) {
  const key = AllAccountsStateKeys.SEARCH_ACCOUNTS;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    rootAccount
  } = yield selectAllAccountsState();
  const {
    jwt
  } = payload;
  try {
    if (payload != null && payload._subTenantId) {
      var _payload$_filter;
      const {
        items
      } = yield call(api.tenants.searchSubTenants, {
        _offset: 0,
        _limit: 50,
        _filter: (_payload$_filter = payload == null ? void 0 : payload._filter) != null ? _payload$_filter : '',
        _subTenantId: payload._subTenantId
      }, {
        jwt
      });
      yield put(actions.setAllAccountsState({
        searchAccountsData: [...items, {
          name: rootAccount.name,
          tenantId: rootAccount.tenantId
        }]
      }));
    }
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

// when we in single account we should get new JWT for each sub-account(tenant) separately.
function* getUserJwtForSubAccount({
  payload
}) {
  const key = AllAccountsStateKeys.GET_USER_JWT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    tenantId,
    callback
  } = payload;
  try {
    const userJwt = yield call(api.users.GetUserJwt, {
      tenantId
    });
    yield put(actions.setSelectedAccountState({
      userJwt,
      accessPermission: true
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    yield put(actions.setSelectedAccountState({
      accessPermission: false
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}
function* createSubAccount({
  payload
}) {
  const key = AllAccountsStateKeys.CREATE_SUB_ACCOUNT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    name,
    parentTenantId,
    tenantId,
    jwt,
    callback
  } = payload;
  try {
    const isSelectedAccountChange = !!jwt;
    if (isSelectedAccountChange) {
      const {
        accounts
      } = yield selectAllAccountsState();
      const {
        selectedAccount
      } = yield selectAllAccountsState();
      const createdSubTenant = yield call(api.subTenants.createSubTenant, {
        name,
        parentTenantId,
        tenantId
      }, {
        jwt
      });
      const updatedAllAccountPageAccounts = appendChildrenToNode(accounts, parentTenantId != null ? parentTenantId : '', [{
        name: createdSubTenant.name,
        tenantId: createdSubTenant.tenantId,
        numberOfUsers: 0,
        createdAt: new Date()
      }]);
      const updatedSingleAccountViewAccounts = appendChildrenToNode(_extends({
        name: '',
        tenantId: ''
      }, selectedAccount == null ? void 0 : selectedAccount.accounts), parentTenantId != null ? parentTenantId : '', [{
        name: createdSubTenant.name,
        tenantId: createdSubTenant.tenantId,
        numberOfUsers: 0,
        createdAt: new Date()
      }]);
      yield put(actions.setAllAccountsState({
        accounts: updatedAllAccountPageAccounts
      }));
      yield put(actions.setSelectedAccountState({
        accounts: updatedSingleAccountViewAccounts
      }));
    } else {
      const {
        accounts
      } = yield selectAllAccountsState();
      const createdSubTenant = yield call(api.subTenants.createSubTenant, {
        name,
        tenantId,
        parentTenantId
      });
      const updatedAccounts = appendChildrenToNode(accounts, parentTenantId != null ? parentTenantId : '', [{
        name: createdSubTenant.name,
        tenantId: createdSubTenant.tenantId,
        numberOfUsers: 0,
        createdAt: new Date()
      }]);
      yield put(actions.setAllAccountsState({
        accounts: updatedAccounts
      }));
    }
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

// delete sub account
function* deleteSubAccount({
  payload
}) {
  const key = AllAccountsStateKeys.DELETE_SUB_ACCOUNT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    jwt,
    tenantId,
    callback
  } = payload;
  try {
    const isSelectedAccountChange = !!jwt;
    if (isSelectedAccountChange) {
      const {
        selectedAccount,
        accounts
      } = yield selectAllAccountsState();
      yield call(api.subTenants.deleteSubTenant, tenantId, {
        jwt
      });
      const udpatedAccounts = removeNodeFromTree(accounts, tenantId);
      const updatedAccountsForSelectedAccount = removeNodeFromTree(selectedAccount == null ? void 0 : selectedAccount.accounts, tenantId);
      yield put(actions.setAllAccountsState({
        accounts: udpatedAccounts
      }));
      yield put(actions.setSelectedAccountState({
        accounts: updatedAccountsForSelectedAccount
      }));
    } else {
      const {
        accounts
      } = yield selectAllAccountsState();
      yield call(api.subTenants.deleteSubTenant, tenantId);
      const udpatedAccounts = removeNodeFromTree(accounts, tenantId);
      yield put(actions.setAllAccountsState({
        accounts: udpatedAccounts
      }));
    }
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

//update account settings
function* updateSubAccountSettings({
  payload
}) {
  const key = AllAccountsStateKeys.UPDATE_SUB_ACCOUNT_SETTINGS;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    jwt,
    name,
    callback,
    tenantId
  } = payload;
  try {
    const isSelectedAccountChange = !!jwt;
    if (isSelectedAccountChange) {
      const {
        selectedAccount,
        accounts
      } = yield selectAllAccountsState();
      yield call(api.subTenants.updateSubTenant, {
        tenantId
      }, {
        name
      }, {
        jwt
      });
      const udpatedAccs = updateNodeName(selectedAccount.accounts, tenantId, name);
      yield put(actions.setSelectedAccountState({
        name,
        accounts: udpatedAccs
      }));
      const udpatedAccsForAllAccountsPage = updateNodeName(accounts, tenantId, name);
      yield put(actions.setAllAccountsState({
        accounts: udpatedAccsForAllAccountsPage
      }));
    } else {
      const {
        accounts
      } = yield selectAllAccountsState();
      yield call(api.subTenants.updateSubTenant, {
        tenantId
      }, {
        name
      });
      const udpatedAccs = updateNodeName(accounts, tenantId, name);
      yield put(actions.setAllAccountsState({
        accounts: udpatedAccs
      }));
    }
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

//delete user from tenants and subtenants
function* deleteUserFromAccount({
  payload
}) {
  const key = AllAccountsStateKeys.DELETE_USERS_FROM_ACCOUNT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    userId,
    subTenants,
    jwt,
    callback
  } = payload;
  try {
    yield call(api.subTenants.removeUserFromTenantAndSubTenants, {
      userId,
      subTenants
    }, {
      jwt
    });
    yield put(actions.getAccountUsers({
      jwt,
      _tenantId: subTenants[0].tenantId
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

// set/edit user roles for sub account
function* setUserRolesForSubAccount({
  payload
}) {
  const key = AllAccountsStateKeys.SET_USER_ROLES_FOR_SUB_ACCOUNT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    userId,
    subTenantsRoles,
    jwt,
    callback
  } = payload;
  const {
    selectedAccount
  } = yield selectAllAccountsState();
  try {
    yield call(api.subTenants.setUserRolesForSubTenants, userId, {
      subTenantsRoles
    }, {
      jwt
    });
    yield put(actions.getAccountUsers({
      jwt,
      _tenantId: selectedAccount.tenantId
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

// invite user to account
function* addUsersToAccount({
  payload
}) {
  const key = AllAccountsStateKeys.ADD_USERS_TO_ACCOUNT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    users,
    tenantId,
    jwt,
    callback,
    allowSubAccountAccess = false,
    roleIds
  } = payload;
  try {
    yield call(api.subTenants.addUsersToSubTenant, {
      users,
      tenantId,
      allowSubAccountAccess,
      roleIds
    }, {
      jwt
    });
    yield put(actions.getAccountUsers({
      jwt,
      _tenantId: tenantId
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

// update sub account access for user
function* updateSubAccountAccessForUser({
  payload
}) {
  const key = AllAccountsStateKeys.UPDATE_SUB_ACCOUNT_ACCESS_FOR_USER;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    selectedAccount
  } = yield selectAllAccountsState();
  const {
    userId,
    allowAccess,
    jwt,
    callback
  } = payload;
  try {
    var _selectedAccount$user;
    yield call(api.subTenants.updateSubAccountAccess, userId, {
      allowAccess
    }, {
      jwt
    });
    yield put(actions.setSelectedAccountState({
      users: selectedAccount == null ? void 0 : (_selectedAccount$user = selectedAccount.users) == null ? void 0 : _selectedAccount$user.map(user => {
        if (user.id === userId) {
          return _extends({}, user, {
            subAccountAccessAllowed: allowAccess
          });
        }
        return user;
      })
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false);
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

// get account users
function* getAccountUsers({
  payload
}) {
  var _ref, _payload$_offset, _allAccountsState$sel, _allAccountsState$sel2, _ref2, _payload$_limit, _allAccountsState$sel3, _allAccountsState$sel4;
  const key = AllAccountsStateKeys.GET_ACCOUNT_USERS;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const allAccountsState = yield selectAllAccountsState();
  const {
    jwt,
    _tenantId,
    _email
  } = payload;
  const _offset = (_ref = (_payload$_offset = payload == null ? void 0 : payload._offset) != null ? _payload$_offset : allAccountsState == null ? void 0 : (_allAccountsState$sel = allAccountsState.selectedAccount) == null ? void 0 : (_allAccountsState$sel2 = _allAccountsState$sel.usersQueryParams) == null ? void 0 : _allAccountsState$sel2._offset) != null ? _ref : 0;
  const _limit = (_ref2 = (_payload$_limit = payload == null ? void 0 : payload._limit) != null ? _payload$_limit : allAccountsState == null ? void 0 : (_allAccountsState$sel3 = allAccountsState.selectedAccount) == null ? void 0 : (_allAccountsState$sel4 = _allAccountsState$sel3.usersQueryParams) == null ? void 0 : _allAccountsState$sel4._limit) != null ? _ref2 : 10;
  const getAccountUsersQueryParams = _extends({
    _offset,
    _limit,
    _tenantId,
    _includeSubTenants: false
  }, !!_email && {
    _email
  });
  try {
    const {
      items: usersItems,
      _metadata: {
        totalItems,
        totalPages
      }
    } = yield call(api.users.getUsersV3, getAccountUsersQueryParams, {
      jwt
    });
    const selectedAccountStateObj = {
      totalUsersItems: totalItems,
      totalUsersPages: totalPages,
      usersQueryParams: _extends({
        _offset: _offset != null ? _offset : 0,
        _limit: _limit != null ? _limit : 10
      }, !!_email && {
        _email
      })
    };
    if (usersItems.length) {
      const usersIds = usersItems.map(user => user.id);
      const allRoles = yield call(api.roles.getRoles);
      const usersRoles = yield call(api.users.getUsersRoles, {
        ids: usersIds
      }, {
        jwt
      });
      const usersWithRoles = usersItems.map(user => {
        var _usersRoles$find, _ref3;
        const userRolesIds = usersRoles == null ? void 0 : (_usersRoles$find = usersRoles.find(role => role.userId === user.id)) == null ? void 0 : _usersRoles$find.roleIds;
        return _extends({}, user, {
          roles: (_ref3 = userRolesIds == null ? void 0 : userRolesIds.map(roleId => {
            var _allRoles$find;
            return (_allRoles$find = allRoles == null ? void 0 : allRoles.find(role => role.id === roleId)) != null ? _allRoles$find : [];
          })) != null ? _ref3 : []
        });
      });
      yield put(actions.setSelectedAccountState(_extends({
        users: usersWithRoles
      }, selectedAccountStateObj)));
    } else {
      yield put(actions.setSelectedAccountState(_extends({
        users: []
      }, selectedAccountStateObj)));
    }
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

/**
 * Used to set selected account data.
 * name, tenantId, users, parentsAccounts, numberOfUsers, subAccountsAmount.
 * @param payload: { jwt }
 */
function* setSelectedSubAccountData({
  payload
}) {
  const key = AllAccountsStateKeys.SET_SELECTED_SUB_ACCOUNT_DATA;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    jwt
  } = payload;
  try {
    var _accountSettings$tena, _accountSettings$tena2, _accountSettings$tena3, _subAccountsAmount$, _accountSettings$tena4, _numberOfUsersArray$, _accountSettings$tena5;
    const accountSettings = yield call(api.accountSettings.getSettings, {
      jwt
    });
    const numberOfUsersArray = yield call(api.tenants.getTenantsUsersCount, {
      tenantIds: [(_accountSettings$tena = accountSettings == null ? void 0 : accountSettings.tenantId) != null ? _accountSettings$tena : '']
    }, {
      jwt
    });
    const parentsAccounts = yield call(api.tenants.getParentTenants, {
      jwt
    });
    const subAccountsAmount = yield call(api.tenants.getHierarchyMetadata, {
      _tenantIds: [(_accountSettings$tena2 = accountSettings == null ? void 0 : accountSettings.tenantId) != null ? _accountSettings$tena2 : '']
    }, {
      jwt
    });
    yield put(actions.setSelectedAccountState({
      tenantId: (_accountSettings$tena3 = accountSettings.tenantId) != null ? _accountSettings$tena3 : '',
      name: accountSettings.name,
      numberOfUsersObject: numberOfUsersArray[0],
      parentsAccounts,
      numberOfSubAccounts: (_subAccountsAmount$ = subAccountsAmount[0]) == null ? void 0 : _subAccountsAmount$.totalSubTenants,
      accountSettings,
      accounts: {
        name: accountSettings.name,
        tenantId: (_accountSettings$tena4 = accountSettings.tenantId) != null ? _accountSettings$tena4 : '',
        numberOfUsers: (_numberOfUsersArray$ = numberOfUsersArray[0]) == null ? void 0 : _numberOfUsersArray$.totalUsers,
        //Not implemented on backend yet
        createdAt: undefined,
        children: []
      }
    }));
    yield put(actions.getAccountUsers({
      jwt,
      _tenantId: (_accountSettings$tena5 = accountSettings == null ? void 0 : accountSettings.tenantId) != null ? _accountSettings$tena5 : ''
    }));
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}

/**
 * Used to set root account data.
 */
function* setRootAccountData() {
  const key = AllAccountsStateKeys.SET_ROOT_ACCOUNT_DATA;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    activeTenant
  } = yield selectTenantsState();
  const {
    accounts
  } = yield selectAllAccountsState();
  if (!activeTenant) return;
  try {
    var _tenantUsersCount$;
    const tenantUsersCount = yield call(api.tenants.getTenantsUsersCount, {
      tenantIds: [activeTenant == null ? void 0 : activeTenant.tenantId]
    });
    const rootAccount = {
      name: activeTenant == null ? void 0 : activeTenant.name,
      tenantId: activeTenant == null ? void 0 : activeTenant.tenantId,
      numberOfUsers: (_tenantUsersCount$ = tenantUsersCount[0]) == null ? void 0 : _tenantUsersCount$.totalUsers,
      createdAt: activeTenant == null ? void 0 : activeTenant.createdAt
    };
    yield put(actions.setAllAccountsState({
      rootAccount,
      accounts: _extends({}, accounts, rootAccount)
    }));
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}
function* loadNumberOfUsersForSelectedSubAccount({
  payload
}) {
  const key = AllAccountsStateKeys.LOAD_NUMBER_OF_USERS_FOR_ACCOUNT;
  yield put(actions.setAllAccountsLoader({
    key,
    value: true
  }));
  const {
    tenantId,
    jwt
  } = payload;
  try {
    const numberOfUsersArray = yield call(api.tenants.getTenantsUsersCount, {
      tenantIds: [tenantId]
    }, {
      jwt
    });
    yield put(actions.setSelectedAccountState({
      numberOfUsersObject: numberOfUsersArray[0]
    }));
  } catch (e) {
    yield put(actions.setAllAccountsError({
      key,
      value: errorHandler(e)
    }));
  } finally {
    yield put(actions.setAllAccountsLoader({
      key,
      value: false
    }));
  }
}
export function* allAccountsSagas() {
  yield takeLatest(actions.loadAccounts, loadAccounts);
  yield takeLatest(actions.searchAccounts, searchAccounts);
  yield takeLatest(actions.updateSubAccountAccessForUser, updateSubAccountAccessForUser);
  yield takeLatest(actions.getUserJwtForSubAccount, getUserJwtForSubAccount);
  yield takeLatest(actions.createSubAccount, createSubAccount);
  yield takeLatest(actions.deleteSubAccount, deleteSubAccount);
  yield takeLatest(actions.updateSubAccountSettings, updateSubAccountSettings);
  yield takeLatest(actions.addUsersToAccount, addUsersToAccount);
  yield takeLatest(actions.deleteUsersFromAccount, deleteUserFromAccount);
  yield takeLatest(actions.setUserRolesForSubAccount, setUserRolesForSubAccount);
  yield takeLatest(actions.getAccountUsers, getAccountUsers);
  yield takeLatest(actions.setRootAccountData, setRootAccountData);
  yield takeLatest(actions.setSelectedSubAccountData, setSelectedSubAccountData);
  yield takeLatest(actions.loadNumberOfUsersForSelectedSubAccount, loadNumberOfUsersForSelectedSubAccount);
}