/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserService, ButtonEnableDisableService, MessageService, ValidationService } from '@SiteOwl/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {

  changePasswordForm!: FormGroup<any>;
  isFormSubmitted = false;
  isTextFieldType!: boolean;

  isTextFieldTypeNew!: boolean;
  isTextFieldTypeConfirmed!: boolean;

  constructor(private userService: UserService, private fb: FormBuilder,
      public btnService: ButtonEnableDisableService,public bsModalRef: BsModalRef,
      private messageService: MessageService) {
  }

  ngOnInit() {
      this.createForm();
  }
  createForm() {
      this.changePasswordForm = this.fb.group({
          currentPassword: ['', [Validators.required]],
          newPassword: ['', [Validators.required, ValidationService.passwordValidator]],
          confirmNewPassword: ['', [Validators.required, ((control: any) => ValidationService.confirmPassword(control, this.changePasswordForm, 'newPassword'))]]
      }, {
        validator: ValidationService.checkIfMatchingPasswords( 'newPassword','confirmNewPassword',)
      });
  }
 
  changePassword() {
      const formData = this.changePasswordForm.value;
      if (this.changePasswordForm.invalid) {
          this.isFormSubmitted = true;
          return;
      }
      this.btnService.disable()
      this.userService.changePassword(formData).subscribe({
          next: () => {
              this.btnService.enable()
              this.messageService.successMessage(`Change Password`, 'Password changed Successfully');
              this.bsModalRef.hide();
          }, error: (error: any) => {
              this.btnService.enable();
              if (error.status === 422) {
                  this.messageService.errorMessage('Change Password', error.error.message);
              } else {
                  this.messageService.errorMessage(`Error`, error.error.message);
              }
            }   
          });
  }

  closeModal() {
    this.bsModalRef.hide();
  }
  ngOnDestroy() {
      this.btnService.enable()
  }
  togglePasswordFieldType() {
      this.isTextFieldType = !this.isTextFieldType;
  }
  togglePasswordFieldTypeNew() {
      this.isTextFieldTypeNew = !this.isTextFieldTypeNew;
  }
  togglePasswordFieldTypeConfirmed() {
      this.isTextFieldTypeConfirmed = !this.isTextFieldTypeConfirmed;
  }
}

