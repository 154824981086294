import { NotEntitledJustification, ContextHolder } from '@frontegg/rest-api';
import { evaluateIsEntitledToFeature, evaluateIsEntitledToPermissions } from '@frontegg/entitlements-javascript-commons';
/**
 * NOTE: This file including the usage of user entitlements API V1 and V2. The BE API response is diffrent.
 * V1 is the initial implementation.
 * V2 is using the entitlenents package for Rule Based entitlements.
 *
 * V1 is still supported with feature flag but it's depracated.
 *
 * The entitlements query functions supports both APIs by an optional argument of [isV2] to decide what version to choose.
 * The function returns the same type.
 */

const ENTITLEMENTS_NOT_ENABLED_EXCEPTION_TEXT = 'You must first enable entitlements via Frontegg options to use this function';

/**
 * Guard entitlements feature by checking if it is enabled by the vendor
 * @throws when entitlement is not enabled via frontegg options
 */
const guardEntitlementsUsage = () => {
  if (ContextHolder.shouldLoadEntitlements()) return;
  throw new Error(ENTITLEMENTS_NOT_ENABLED_EXCEPTION_TEXT);
};

/**
  For user entitlements API V1
 
  @param keyEntitlements permission or feature data object
  @param missingKeyEnum missing key for scenario that the key does not exist in entitlementsValuePerKeys
  @returns if the user is entitled to the given key. Attaching the justification if not
  @throws when entitlement is not enabled via frontegg options
 */
const getEntitlementsHelperV1 = (keyEntitlements, missingKeyEnum) => {
  if (!keyEntitlements) {
    return {
      isEntitled: false,
      justification: missingKeyEnum
    };
  }
  if (keyEntitlements.isEntitled) {
    return {
      isEntitled: true
    };
  }
  return {
    isEntitled: false,
    justification: keyEntitlements.justification
  };
};

/**
  @param entitlements
  @param key permission key
  @param attributes entitlements query attributes including comnsumer and frontegg attributes
  @param isV2 true when need to use entitlements API V2
  @returns if the user is entitled to the given permission. Attaching the justification if not
  @throws when entitlement is not enabled via frontegg options
 */
export const getPermissionEntitlements = (entitlements, key, attributes, isV2) => {
  var _permissions;
  guardEntitlementsUsage();
  if (isV2) {
    return evaluateIsEntitledToPermissions(key, entitlements, attributes);
  }
  return getEntitlementsHelperV1(entitlements == null ? void 0 : (_permissions = entitlements.permissions) == null ? void 0 : _permissions[key], NotEntitledJustification.MISSING_PERMISSION);
};

/**
  @param entitlements
  @param key feature key
  @param attributes entitlements query attributes including comnsumer and frontegg attributes
  @param isV2 true when need to use entitlements API V2
  @returns if the user is entitled to the given feature. Attaching the justification if not
  @throws when entitlement is not enabled via frontegg options
 */
export const getFeatureEntitlements = (entitlements, key, attributes, isV2) => {
  var _features;
  guardEntitlementsUsage();
  if (isV2) {
    return evaluateIsEntitledToFeature(key, entitlements, attributes);
  }
  return getEntitlementsHelperV1(entitlements == null ? void 0 : (_features = entitlements.features) == null ? void 0 : _features[key], NotEntitledJustification.MISSING_FEATURE);
};

/**
  @param entitlements
  @param options including permission or feature
  @param attributes entitlements query attributes including comnsumer and frontegg attributes
  @param isV2 true when need to use entitlements API V2
  @returns if the user is entitled to the given feature or permission (check only one). Attaching the justification if not
  @throws when entitlement is not enabled via frontegg options
 */
export const getEntitlements = (entitlements, options, attributes, isV2) => {
  if ('permissionKey' in options) {
    return getPermissionEntitlements(entitlements, options.permissionKey, attributes, isV2);
  }
  return getFeatureEntitlements(entitlements, options.featureKey, attributes, isV2);
};