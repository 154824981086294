import { accountType, UserService, MessageService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-provider-account-type',
  templateUrl: './provider-account-type.component.html',
  styleUrls: ['./provider-account-type.component.scss'],
})
export class ProviderAccountTypeComponent implements OnInit {

  accountForm!: FormGroup;
  providerId: any;
  providerName: any;
  showSpinner!: boolean;
  accountType = accountType;
  accountSelection: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(
    private userService: UserService,public bsModalRef: BsModalRef,
    private messageService: MessageService,) {}

  ngOnInit() {
    this.accountForm = new FormGroup({
      accountType: new FormControl(this.accountSelection, Validators.required)
    })
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  updateLicense() {
    this.userService.setConfiguration(this.providerId, "accountType", this.accountForm.value.accountType).subscribe({
      next: () => {
        this.event.emit(this.accountForm.value.accountType);
        this.bsModalRef.hide();
        this.showSpinner = false;
        this.messageService.successMessage('Headquarters: ' + this.providerName, 'Saved successfully');
      }
    });
  }
  accountChanged(item: any, eventType: any) {
    if (eventType === 'provideraccount') {
      this.accountSelection = item.accountTypeKey;
      this.accountForm.value.accountType = item.accountTypeKey;
      this.accountForm.controls['accountType'].setValue(this.accountType.find(x => x.id === item.id), { onlySelf: true });
    }
  }
}

