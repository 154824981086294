import { select, put, call } from 'redux-saga/effects';
import { MFAStep } from '../../MfaState/interfaces';
import { actions } from '../../reducer';
import { LoginFlow, LoginStep } from '../interfaces';
import { shouldShowPromptPasskeys } from '../saga.utils';
import { afterStepUpAuthNavigation, afterAuthNavigation } from './afterAuthNavigation.saga';
import { getFeatureFlags } from '../../../helpers';

/**
 * Additional steps for after MFA authentication with authenticator app handler for step up flow
 */
export function* postHandleVerifyMFAResponseForStepUp() {
  yield call(afterStepUpAuthNavigation);
}

/**
 * Additional steps for after MFA authentication with authenticator app handler for login flow
 * @param isAuthenticated
 */
export function* postHandleVerifyMFAResponseForLogin(isAuthenticated) {
  const {
    loginState
  } = yield select(state => state.auth);
  const {
    step: mfaStep
  } = yield select(state => state.auth.mfaState);
  const [securityCenterLoginFlows] = yield call(getFeatureFlags, ['security-center-show-login-flows']);
  if (loginState.flow === LoginFlow.Login) {
    if (securityCenterLoginFlows && loginState.isBreachedPassword && !isAuthenticated) {
      yield put(actions.setLoginState({
        step: LoginStep.breachedPassword,
        loading: false
      }));
    } else {
      const shouldShowPrompt = yield call(shouldShowPromptPasskeys);
      if (mfaStep === MFAStep.smsVerifyCode && shouldShowPrompt) {
        yield put(actions.setLoginState({
          step: LoginStep.promptPasskeys,
          loading: false
        }));
      } else {
        yield afterAuthNavigation();
      }
    }
  }
}

/**
 * Handle after MFA authentication with authenticator app
 * @param payload.user
 * @param payload.tenants
 * @param payload.activeTenant
 * @param isStepUp
 *
 * When using this saga, you should wrap it with try/catch block and handle according to your logic
 */
export function* handleVerifyMFAResponse({
  user,
  tenants,
  activeTenant
}, isStepUp = false) {
  yield put(actions.setUser(user));
  yield put(actions.setTenantsState({
    tenants,
    activeTenant,
    loading: false
  }));
  if (user.id) {
    localStorage.setItem('userId', user.id);
  }
  const isAuthenticated = !!user.accessToken;
  yield put(actions.setState({
    isAuthenticated
  }));
  if (isStepUp) {
    yield call(postHandleVerifyMFAResponseForStepUp);
    return;
  }
  yield call(postHandleVerifyMFAResponseForLogin, isAuthenticated);
}