export let RecommendationCode;
(function (RecommendationCode) {
  RecommendationCode["FORCE_MFA"] = "FORCE_MFA";
  RecommendationCode["BREACHED_PASSWORDS_EXIST"] = "BREACHED_PASSWORDS_EXIST";
  RecommendationCode["ENABLE_USER_LOCKOUT"] = "ENABLE_USER_LOCKOUT";
  RecommendationCode["STRENGTHEN_USER_LOCKOUT"] = "STRENGTHEN_USER_LOCKOUT";
  RecommendationCode["ENABLE_PASSWORD_HISTORY"] = "ENABLE_PASSWORD_HISTORY";
  RecommendationCode["STRENGTHEN_PASSWORD_HISTORY"] = "STRENGTHEN_PASSWORD_HISTORY";
  RecommendationCode["ENABLE_FORCE_RELOGIN"] = "ENABLE_FORCE_RELOGIN";
  RecommendationCode["SET_FORCE_RELOGIN"] = "SET_FORCE_RELOGIN";
  RecommendationCode["ENABLE_SESSION_CONCURRENCY"] = "ENABLE_SESSION_CONCURRENCY";
  RecommendationCode["SET_SESSION_CONCURRENCY"] = "SET_SESSION_CONCURRENCY";
  RecommendationCode["ENABLE_IDLE_SESSION"] = "ENABLE_IDLE_SESSION";
  RecommendationCode["SET_IDLE_SESSION"] = "SET_IDLE_SESSION";
  RecommendationCode["ENABLE_IP_RESTRICTIONS"] = "ENABLE_IP_RESTRICTIONS";
  RecommendationCode["CHANGE_IP_TO_ALLOWLIST"] = "CHANGE_IP_TO_ALLOWLIST";
  RecommendationCode["ENABLE_DOMAIN_RESTRICTIONS"] = "ENABLE_DOMAIN_RESTRICTIONS";
  RecommendationCode["CHANGE_DOMAIN_TO_ALLOWLIST"] = "CHANGE_DOMAIN_TO_ALLOWLIST";
  RecommendationCode["CONFIGURE_SSO"] = "CONFIGURE_SSO";
})(RecommendationCode || (RecommendationCode = {}));
export let InsightCode;
(function (InsightCode) {
  InsightCode["MFA_FORCED"] = "MFA_FORCED";
  InsightCode["PARTIALLY_FORCED_MFA"] = "PARTIALLY_FORCED_MFA";
  InsightCode["PARTIAL_MFA_USAGE"] = "PARTIAL_MFA_USAGE";
  InsightCode["FULL_MFA_USAGE"] = "FULL_MFA_USAGE";
  InsightCode["BREACHED_PASSWORD_USERS_EXIST"] = "BREACHED_PASSWORD_USERS_EXIST";
  InsightCode["IMPROVE_LOCKOUT_POLICY"] = "IMPROVE_LOCKOUT_POLICY";
  InsightCode["IMPROVE_PASSWORD_HISTORY_POLICY"] = "IMPROVE_PASSWORD_HISTORY_POLICY";
  InsightCode["NO_SESSION_SETTINGS"] = "NO_SESSION_SETTINGS";
  InsightCode["PARTIAL_SESSION_SETTINGS"] = "PARTIAL_SESSION_SETTINGS";
  InsightCode["FULL_SESSION_SETTINGS"] = "FULL_SESSION_SETTINGS";
  InsightCode["NO_IP_RESTRICTIONS"] = "NO_IP_RESTRICTIONS";
  InsightCode["IP_ALLOWLIST"] = "IP_ALLOWLIST";
  InsightCode["IP_DENYLIST"] = "IP_DENYLIST";
  InsightCode["NO_DOMAIN_RESTRICTIONS"] = "NO_DOMAIN_RESTRICTIONS";
  InsightCode["DOMAIN_ALLOWLIST"] = "DOMAIN_ALLOWLIST";
  InsightCode["DOMAIN_DENYLIST"] = "DOMAIN_DENYLIST";
  InsightCode["FULL_PASSWORD_SETTINGS"] = "FULL_PASSWORD_SETTINGS";
})(InsightCode || (InsightCode = {}));
export let RecommendationActionKey;
(function (RecommendationActionKey) {
  RecommendationActionKey["SEND_RESET_BREACHED_PASSWORD_EMAIL"] = "SEND_RESET_BREACHED_PASSWORD_EMAIL";
  RecommendationActionKey["ADJUST_FORCE_RELOGIN"] = "ADJUST_FORCE_RELOGIN";
  RecommendationActionKey["ADJUST_MAX_CONCURRENT_SESSIONS"] = "ADJUST_MAX_CONCURRENT_SESSIONS";
  RecommendationActionKey["ADJUST_IDLE_TIMEOUT"] = "ADJUST_IDLE_TIMEOUT";
})(RecommendationActionKey || (RecommendationActionKey = {}));
export let RecommendationSeverity;
(function (RecommendationSeverity) {
  RecommendationSeverity["SEVERE"] = "SEVERE";
  RecommendationSeverity["WARNING"] = "WARNING";
  RecommendationSeverity["INFO"] = "INFO";
})(RecommendationSeverity || (RecommendationSeverity = {}));
export let SecurityCategory;
(function (SecurityCategory) {
  SecurityCategory["MFA"] = "MFA";
  SecurityCategory["SESSIONS"] = "SESSIONS";
  SecurityCategory["PASSWORD"] = "PASSWORD";
  SecurityCategory["INACTIVITY"] = "INACTIVITY";
  SecurityCategory["IP"] = "IP";
  SecurityCategory["DOMAIN"] = "DOMAIN";
  SecurityCategory["SSO"] = "SSO";
})(SecurityCategory || (SecurityCategory = {}));