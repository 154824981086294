import { Get, Put } from "../fetch";
import { urls } from "../constants";
export async function getSubscriptions() {
  return Get(urls.subscriptions.billing.subscriptions.v1);
}
export async function getSubscription(subscriptionId) {
  return Get(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}`);
}
export async function cancelSubscription(subscriptionId) {
  return Put(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}/cancellations/`, {});
}
export async function renewSubscription(subscriptionId) {
  return Put(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}/renewals/`, {});
}
export async function updateSubscription(subscriptionId, {
  paymentMethodId,
  planId
}) {
  return Put(`${urls.subscriptions.billing.subscriptions.v1}/${subscriptionId}`, {
    paymentMethodId,
    planId
  });
}