/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventEmitter, Injectable, Injector } from "@angular/core";
import { catchError, filter, map, Observable, of, tap, throwError } from "rxjs";
import { RequestParams } from "../model/request-params";
import { CommonService } from "./common.service";
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { AuthService } from "./auth.service";
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { EventEmitterType, LocalStorageKeys, RequestMethod } from "../constants/common.constant";
import { Environment } from "../constants/api.constant";
import { MessageService } from "./message.service";
import { EventService } from "./event.service";
import { FronteggAuthService } from "@frontegg/angular";

export enum Action {
  QueryStart,
  QueryStop
}

@Injectable({
  providedIn: 'root'
})
export class AjaxService {
  process: EventEmitter<any> = new EventEmitter<any>();
  authFailed: EventEmitter<any> = new EventEmitter<any>();
  isIE = false;

  constructor(
    private readonly env: Environment,
    private location: Location,
    private _http: HttpClient,
    private commonService: CommonService,
    private route: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private eventService: EventService,
    private injector: Injector
  ) {
    this.hasIE();
  }

  private hasIE() {
    const trident = !!navigator.userAgent.match(/Trident\/7.0/);
    const net = !!navigator.userAgent.match(/.NET4.0E/);
    const IE11 = trident && net;
    const IEold = navigator.userAgent.match(/MSIE/i) ? true : false;
    this.isIE = IE11 || IEold;
  }

  private _buildAuthHeader(): string {
    return (
      "Bearer " + this.commonService.getLocalStorageString(LocalStorageKeys.TOKEN)
    );
  }
  private _buildCustHeader(): string {
    return '0';
  }
  public get(
    url: string,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    return this._request(RequestMethod.Get, url, null, options, extraParams);
  }

  public post(
    url: string,
    body: any,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    return this._request(RequestMethod.Post, url, body, options, extraParams);
  }

  public put(
    url: string,
    body: any,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    return this._request(RequestMethod.Put, url, body, options, extraParams);
  }

  public delete(
    url: string,
    body?: any,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    return this._request(RequestMethod.Delete, url, body, options, extraParams);
  }

  public patch(
    url: string,
    body: any,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    if (!extraParams) {
      extraParams = new RequestParams();
    }
    extraParams.type = "application/merge-patch+json";
    return this._request(RequestMethod.Patch, url, body, options, extraParams);
  }

  public options(
    url: string,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    return this._request(
      RequestMethod.Options,
      url,
      null,
      options,
      extraParams
    );
  }

  public head(
    url: string,
    options?: any,
    extraParams?: RequestParams
  ): Observable<Response> {
    return this._request(RequestMethod.Head, url, null, options, extraParams);
  }

  private _request(
    method: string,
    url: string,
    body?: any,
    options?: any,
    params?: RequestParams
  ): Observable<Response> {
    if (!options) {
      options = {};
    }
    const httpParams = new HttpParams();
    if (!options.headers) {
      options.headers = {};
    }
    if (!options.observe) {
      options.observe = 'body';
    }
    if (params && params.type) {
      options.headers["Content-Type"] = params.type;
    }

    if (!params || !params.removeAuthToken) {
      options.headers["Authorization"] = this._buildAuthHeader();
    }
    if (!options.headers["customerId"]) {
      if (this.authService.getCustomerId()) {
        options.headers["customerId"] = this.authService.getCustomerId().customerId.toString();
      } else {
        options.headers["customerId"] = '0';
        this.authService.setCustomerId({ customerId: 0, user: '' });
      }
    }
    if (this.isIE) {
      options.headers["Cache-Control"] = "no-cache";
      options.headers["Pragma"] = "no-cache";
      options.headers["Expires"] = "Sat, 01 Jan 2000 00:00:00 GMT";
    }
    const requestHeader = new HttpHeaders(options.headers);
    let requestOptions: any;
    if (url.indexOf("overview_pdf") > -1 || url.indexOf("resetpasswordwith2FA") > -1 || url.indexOf("stub/login") > -1 || url.indexOf("verify/2fa") > -1 || url.indexOf("generatenewtoken") > -1) {
      requestOptions = new HttpRequest(method, `${this.env.apiPath}${url}`, body, {
        headers: requestHeader,
        params: httpParams,
        responseType: "text"
      });
    } else {
      let apiEndPoint = `${this.env.apiPath}${url}`;
      if (url.match("http")) {
        apiEndPoint = url
      }
      requestOptions = new HttpRequest(method, `${apiEndPoint}`, body, {
        headers: requestHeader,
        params: httpParams,
      });
    }
    return this._http.request(requestOptions)
      .pipe(
        filter((res: any) => res instanceof HttpResponse), // request get all events we do need the response only
        map((res: any) => {
          if ((res.url && res.url.indexOf("overview_pdf") > -1) || url.indexOf("resetpasswordwith2FA") > -1 || url.indexOf("verify/2fa") > -1 || url.indexOf("generatenewtoken") > -1) {
            return res;
          } else {
            return res.body;
          }
        }),
        catchError((error: any) => {
          if (error) {
            if (error.error instanceof ErrorEvent) {
              // console.error("Error Event");
            } else {
              console.log(`error status : ${error.status} ${error.statusText}`);
              switch (error.status) {
                case 401:      //login
                  if (!error.url || (error.url.indexOf("verify/2fa") === -1 && error.url.indexOf("resendcode/2fa") === -1)) {
                    if (this.env.fronteggLogin) {
                      this.authService.clearUserData();
                      this.commonService.removeItem('loginType')
                      this.injector.get(FronteggAuthService).loginWithRedirect();
                      const url = location.pathname;
                      if (error.url.indexOf('equipments?isIncludeArchived=true')) {
                        localStorage.setItem('fromFloorPlan', url)
                        this.route.navigate(['']);
                      }
                    } else {
                      this.route.navigate(['public/login'])
                    }
                  }
                  break;
                case 402:
                  break;
                case 400:
                  break;
                case 409:
                  break;
                case 504:
                  break;
                case 422:
                  break;
                case 0:
                  this.messageService.errorMessage('Error', 'Please check your internet connection');
                  this.eventService.broadcast(EventEmitterType.internetConnectionGone, '')
                  break;
                default:
                  this.route.navigate(["secure", "error", error.status], { skipLocationChange: true }).then(() => {
                    // this.location.replaceState(url);
                  });
              }
            }
          } else {
            console.error("some thing else happened");
          }
          return throwError(error);
        })
      );
  }
}
