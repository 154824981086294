import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction } from '@reduxjs/toolkit';
import { vendorStoreName } from '../../constants';
const reducers = {
  setVendorState: (state, {
    payload
  }) => _extends({}, state, payload)
};
const actions = {
  loadVendorPublicInfo: createAction(`${vendorStoreName}/loadVendorPublicInfo`)
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Matcher = {};
export { reducers as vendorReducers, actions as vendorActions };