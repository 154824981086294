<div class="date-format project-date clearfix">
    <div class="created-user">
        <label>{{title}}:</label>
        <div class="created-by" *ngIf="details" title="{{date | date:'MM/dd/yy, h:mm a'}}">
            {{date | date : 'MM/dd/yyyy'}}
        </div>
        <div class="created-by" *ngIf="details" (mouseout)="out()" (mouseover)="fillToolTipObj($event,details)">
            <div class="custom-tooltip">
                {{details?.name}}
                <so-tool-tip [toolTipObj]="toolTipObj"></so-tool-tip>
            </div>
        </div>
    </div>
</div>