import { Pipe, PipeTransform } from '@angular/core';
import { Utilities } from '@SiteOwl/core';

@Pipe({
  name: 'wrap'
})
export class WrapPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if (!value) {
      return value;
    }
    if (!Utilities.isNull(value)) {
        if (args && value.trim().length > args) {
            return value.substr(0, args) + '...';
        }
        else if (!args && value.length > 30) {
            return value.trim().substr(0, 30) + '...';
        }
    }
    return value;
  }

}
