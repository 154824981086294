/**
 * The required ACR (Authorization Context Reference) value for the step up flow
 */
export const ACR_VALUE = 'http://schemas.openid.net/pape/policies/2007/06/multi-factor';

/**
 * One of the required AMR (Authentication Methods References) values for the step up flow
 */
export const AMR_MFA_VALUE = 'mfa';

/**
 * One of the required AMR (Authentication Methods References) values should be from the array for the step up flow
 */
export const AMR_ADDITIONAL_VALUE = ['otp', 'sms', 'hwk'];

/**
 * SHOULD_STEP_UP_KEY local storage key
 * Used for scenarios when we logout for re-login and then should redirect to step up page
 */
export const SHOULD_STEP_UP_KEY = 'SHOULD_STEP_UP';

/**
 * The name of the query param that contains the max age of the step up
 */
export const STEP_UP_MAX_AGE_PARAM_NAME = 'maxAge';