import { Pipe, PipeTransform } from '@angular/core';
import { DefaultValues, Utilities } from '@SiteOwl/core';

@Pipe({
  name: 'userFilter',
})
export class UserFilterPipe implements PipeTransform { 

  transform(items: any[], filter: any): any[] {
    if (Utilities.isNull(items) || Utilities.isNull(filter)) {
        return items || [];
    }

    if (!Utilities.isEmpty(filter.user)) {
        items = items.filter(x => {

            if (!Utilities.isEmpty(x.provider)) {
                if (Utilities.isEmpty(x.provider.name) && !Utilities.isEmpty(x.provider.email)) {
                    return x.provider.email.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
                } else if (!Utilities.isEmpty(x.provider.name) && Utilities.isEmpty(x.provider.email)) {
                    return x.provider.name.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
                } else {
                    return x.provider.name.toLowerCase().indexOf(filter.user.toLowerCase()) > -1 ||
                        x.provider.email.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
                }
            } else {
                if (Utilities.isEmpty(x.name) && !Utilities.isEmpty(x.email)) {
                    return x.email.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
                } else if (!Utilities.isEmpty(x.name) && Utilities.isEmpty(x.email)) {
                    return x.name.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
                } else {
                    return x.name.toLowerCase().indexOf(filter.user.toLowerCase()) > -1 ||
                        x.email.toLowerCase().indexOf(filter.user.toLowerCase()) > -1
                }
            }

        })
    }

    if (!Utilities.isEmpty(filter.roleName)) {
        items = items.filter(x => Utilities.isNull(x.role) ? x.amtRole.name.toLowerCase().indexOf(filter.roleName.toLowerCase()) > -1 : x.role.name.toLowerCase().indexOf(filter.roleName.toLowerCase()) > -1);
    }
    if (!Utilities.isEmpty(filter.customerId)) {
        if (filter.customerId < 0) {
            items = items.filter(x => x.customerId === null);
        } else {
            items = items.filter(x => Utilities.isNull(x.customerId) ? true : x.customerId === filter.customerId);
        }
    }
    if (!Utilities.isNull(filter.userStatus)) {
        items = items.filter(x => Utilities.isNull(x.isActive) ? true : x.isActive === filter.userStatus);
    }
    if (!Utilities.isEmpty(filter.projectRoleName)) {
        items = items.filter(x => x.projectRole.name == filter.projectRoleName);
    }
    if (!Utilities.isEmpty(filter.administrator)) {
        items = items.filter(x => x.isAdmin == filter.administrator);
    }
    if (!Utilities.isEmpty(filter.employerName)) {
        if (filter.employerName === DefaultValues.NoEmployer) {
            items = items.filter((x) => {
                return Utilities.isEmpty(x.employer)
            });
        } else {
            items = items.filter((x: any) => {
                if (filter.isProvider) {
                    if (!Utilities.isNull(x.employer)) {
                        return x.employer === filter.employerName
                    }
                } else {
                    if (!Utilities.isNull(x.employer)) {
                        return x.employer === filter.employerName
                    } else {
                        return x.employer === filter.employerName
                    }
                }
                return;
            });
        }
    }
    return  items;
  }

}
