import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const sessionsState = {
  loading: false,
  sessions: []
};
const reducers = {
  setSessionsState: typeReducerForKey('sessionsState'),
  resetSessionsState: resetStateByKey('sessionsState', {
    sessionsState
  })
};
const actions = {
  loadUserSessions: createAction(`${authStoreName}/loadUserSessions`),
  loadCurrentUserSession: createAction(`${authStoreName}/loadCurrentUserSession`),
  deleteUserSession: createAction(`${authStoreName}/deleteUserSession`, payload => ({
    payload
  })),
  deleteAllUserSessions: createAction(`${authStoreName}/deleteAllUserSessions`),
  revokeUserSessions: createAction(`${authStoreName}/revokeUserSessions`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { sessionsState, reducers as sessionsReducers, actions as sessionsActions };