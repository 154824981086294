"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.evaluateFeatureFlag = void 0;
const rules_1 = require("../rules");
function evaluateFeatureFlag(featureFlag, attributes) {
  if (!featureFlag.on) {
    return {
      treatment: featureFlag.offTreatment
    };
  }
  const treatableRule = findTreatableRule(featureFlag, attributes);
  if (treatableRule) {
    return {
      treatment: treatableRule.treatment
    };
  }
  return {
    treatment: featureFlag.defaultTreatment
  };
}
exports.evaluateFeatureFlag = evaluateFeatureFlag;
function findTreatableRule(featureFlag, attributes) {
  var _a;
  return (_a = featureFlag.rules) === null || _a === void 0 ? void 0 : _a.find(rule => {
    const evaluator = (0, rules_1.createRuleEvaluator)({
      rule
    });
    const result = evaluator(attributes);
    return result === rules_1.RuleEvaluationResultEnum.Treatable;
  });
}
