/* eslint-disable @typescript-eslint/no-explicit-any */
export class FileItem {
    name: string;
    type?: string;
    size: number;
    fileData?: any;
    url?: string;
    extension?: string;
    height?: number;
    width?: number;
    id?: any;
    fileType?: any;
    fileName?: string;
    isImageEditFromEditor?: boolean;
    tempId?: any;
    fileTypeInvalid?: any;
    fileSizeInvalid?:any;
    constructor() {
        this.name = '';
        this.size = 0;
    }
}