import { createAction } from '@reduxjs/toolkit';
import { authStoreName } from '../../constants';
import { resetStateByKey, typeReducerForKey } from '../utils';
const passkeysState = {
  devices: [],
  loading: false,
  error: null
};
const reducers = {
  setPasskeysState: typeReducerForKey('passkeysState'),
  resetPasskeysState: resetStateByKey('passkeysState', {
    passkeysState
  })
};
const actions = {
  loadWebAuthnDevices: createAction(`${authStoreName}/loadWebAuthnDevices`),
  deleteWebAuthnDevice: createAction(`${authStoreName}/deleteWebAuthnDevice`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { passkeysState, reducers as passkeysReducers, actions as passkeysActions };