import _extends from "@babel/runtime/helpers/esm/extends";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
const _excluded = ["phoneNumber"],
  _excluded2 = ["events", "url"];
import { call, put, select, takeLeading } from 'redux-saga/effects';
import { api, ContextHolder } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { SignUpStage } from './interfaces';
import { AuthenticationTypes } from '../interfaces';
import { getMfaRequiredState } from '../LoginState/sagas/mfaRequiredState.saga';
import { GTMEventAction, errorHandler, reportGTMEvent } from '../../utils';
import { isMfaRequired } from '../LoginState/utils';
import { getSecurityPolicyPublicStateWithCustomLogin } from '../Security/SecurityPolicyState/saga';
export function* loadAllowSignUps() {
  yield put(actions.setSignUpState({
    loading: true
  }));
  try {
    const policy = yield call(api.auth.getVendorConfig);
    const policyWithCustomLoginAuthStrategy = yield getSecurityPolicyPublicStateWithCustomLogin(policy);
    const {
      allowSignups: allowSignUps,
      allowNotVerifiedUsersLogin
    } = policy;
    yield put(actions.setSignUpState({
      loading: false,
      allowSignUps,
      allowNotVerifiedUsersLogin,
      firstLoad: false
    }));
    yield put(actions.setSecurityPolicyPublicState({
      policy: policyWithCustomLoginAuthStrategy,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSignUpState({
      loading: false,
      error: errorHandler(e),
      firstLoad: false
    }));
  }
}
const extractPhoneNumber = _ref => {
  let rest = _objectWithoutPropertiesLoose(_ref, _excluded);
  return rest;
};
export function* signUpUser(_ref2) {
  let {
      payload: {
        events,
        url
      }
    } = _ref2,
    payload = _objectWithoutPropertiesLoose(_ref2.payload, _excluded2);
  yield put(actions.setSignUpState({
    loading: true
  }));
  try {
    const {
      routes,
      onRedirectTo
    } = yield select(state => state.auth);
    const {
      shouldActivate,
      user,
      tenantId,
      userId,
      tenants = [],
      activeTenant
    } = yield call(api.auth.signUpUser,
    //Currently we are not supporting phone number in signup in the backend
    //Remove this line when we will support it (also make sure when field is optional no empty string is sent)
    extractPhoneNumber(payload));
    if (!payload.invitationToken) {
      var _events$signUpComplet;
      const {
        email,
        name,
        companyName,
        phoneNumber
      } = payload;
      const signUpCompletePayload = {
        email,
        name,
        companyName,
        url,
        phoneNumber: phoneNumber || undefined,
        authenticationType: AuthenticationTypes.PASSWORD,
        id: userId,
        tenantId,
        createdAt: new Date()
      };
      events == null ? void 0 : (_events$signUpComplet = events.signUpComplete) == null ? void 0 : _events$signUpComplet.call(events, signUpCompletePayload);
      reportGTMEvent(GTMEventAction.SIGNUP_COMPLETED, signUpCompletePayload);
    }
    if (!shouldActivate && user && isMfaRequired(user)) {
      const mfaRequiredState = yield getMfaRequiredState(user);
      yield put(actions.setState(mfaRequiredState));
      onRedirectTo(routes.loginUrl);
    } else {
      const {
        tenantsState
      } = yield select(state => state.auth);
      yield put(actions.setSignUpState({
        loading: false,
        shouldActivate,
        stage: SignUpStage.SignUpSuccess
      }));
      yield put(actions.setState({
        user,
        isAuthenticated: !!(user != null && user.accessToken),
        tenantsState: _extends({}, tenantsState, {
          tenants,
          activeTenant
        })
      }));
    }
  } catch (e) {
    ContextHolder.setAccessToken(null);
    ContextHolder.setUser(null);
    yield put(actions.setSignUpState({
      loading: false,
      error: errorHandler(e)
    }));
  }
  yield put(actions.setSignUpState({
    loading: false
  }));
}
export function* resetSignUpStateSoft() {
  const {
    allowSignUps,
    allowNotVerifiedUsersLogin
  } = yield select(state => state.auth.signUpState);
  yield put(actions.resetSignUpState());
  yield put(actions.setSignUpState({
    allowSignUps,
    allowNotVerifiedUsersLogin
  }));
}
export function* signUpSaga() {
  yield takeLeading(actions.signUpUser, signUpUser);
  yield takeLeading(actions.resetSignUpStateSoft, resetSignUpStateSoft);
}