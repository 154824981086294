import { dialogStateForKey, dialogStateForKeyWithoutPayload, resetStateByKey, typeReducerForKey } from '../../utils';
const allAccountsDialogsState = {
  createSubAccountDialog: {
    loading: false,
    open: false,
    error: false,
    jwt: '',
    canSelectParentAccount: false
  },
  updateSubAccountDialog: {
    accountId: '',
    accountName: '',
    loading: false,
    open: false,
    error: false,
    jwt: ''
  },
  deleteSubAccountDialog: {
    accountId: '',
    accountName: '',
    isParentAccount: false,
    loading: false,
    open: false,
    error: false,
    jwt: ''
  },
  addUsersToAccountDialog: {
    loading: false,
    open: false,
    accountName: '',
    accountId: '',
    permissionToGiveAccessToSubAccounts: false,
    jwt: ''
  },
  deleteUserFromAccountDialog: {
    userId: '',
    userName: '',
    accountId: '',
    loading: false,
    open: false,
    jwt: ''
  },
  editUserRolesDialog: {
    loading: false,
    open: false,
    jwt: '',
    accountId: '',
    userId: '',
    userName: '',
    userRoles: []
  }
};
const reducers = {
  setAllAccountDialogsState: typeReducerForKey('allAccountsDialogsState'),
  resetAllAccountDialogsState: resetStateByKey('allAccountsDialogsState', {
    allAccountsDialogsState
  }),
  // Create new account dialog
  openCreateSubAccountDialog: dialogStateForKey('allAccountsDialogsState', 'createSubAccountDialog', {
    open: true
  }),
  closeCreateSubAccountDialog: dialogStateForKeyWithoutPayload('allAccountsDialogsState', 'createSubAccountDialog', allAccountsDialogsState.createSubAccountDialog),
  // Update account dialog
  openUpdateSubAccountDialog: dialogStateForKey('allAccountsDialogsState', 'updateSubAccountDialog', {
    open: true
  }),
  closeUpdateSubAccountDialog: dialogStateForKeyWithoutPayload('allAccountsDialogsState', 'updateSubAccountDialog', allAccountsDialogsState.updateSubAccountDialog),
  // Delete account dialog
  openDeleteSubAccountDialog: dialogStateForKey('allAccountsDialogsState', 'deleteSubAccountDialog', {
    open: true
  }),
  closeDeleteSubAccountDialog: dialogStateForKeyWithoutPayload('allAccountsDialogsState', 'deleteSubAccountDialog', allAccountsDialogsState.deleteSubAccountDialog),
  // Add users to account dialog
  openAddUsersToAccountDialog: dialogStateForKey('allAccountsDialogsState', 'addUsersToAccountDialog', {
    open: true
  }),
  closeAddUsersToAccountDialog: dialogStateForKeyWithoutPayload('allAccountsDialogsState', 'addUsersToAccountDialog', allAccountsDialogsState.addUsersToAccountDialog),
  // Delete user from account dialog
  openDeleteUserFromAccountDialog: dialogStateForKey('allAccountsDialogsState', 'deleteUserFromAccountDialog', {
    open: true
  }),
  closeDeleteUserFromAccountDialog: dialogStateForKeyWithoutPayload('allAccountsDialogsState', 'deleteUserFromAccountDialog', allAccountsDialogsState.deleteUserFromAccountDialog),
  // Edit user roles dialog
  openEditUserRolesDialog: dialogStateForKey('allAccountsDialogsState', 'editUserRolesDialog', {
    open: true
  }),
  closeEditUserRolesDialog: dialogStateForKeyWithoutPayload('allAccountsDialogsState', 'editUserRolesDialog', allAccountsDialogsState.editUserRolesDialog)
};
export { allAccountsDialogsState, reducers as allAccountsDialogsReducers };