/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[soNumbersDecimalOnly]'
})
export class NumbersDecimalOnlyDirective {
  private regex: RegExp = /^\d{0,11}([.]\d{0,2})?$/;
  private QuantityRegex: RegExp = /^\d{0,3}([.]\d{0,2})?$/;
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Delete'];
  constructor(private el: ElementRef) { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: any) {

    // Allow Backspace, tab, end, and home keys

    if (this.specialKeys.indexOf(event.key) !== -1) {
      return;
    }

    if (event.target.selectionStart + event.target.selectionEnd === event.target.value.length) {
      this.el.nativeElement.value = '';
    }
    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);
    if (event.target.id.includes('quantity_')) {
      if (next && !String(next).match(this.QuantityRegex)) {
        event.preventDefault();
      }
    } else {
      if (next && !String(next).match(this.regex)) {
        event.preventDefault();
      }
    }
  }
}
