import _extends from "@babel/runtime/helpers/esm/extends";
import { loginState } from './LoginState';
import { activateState } from './ActivateState';
import { acceptInvitationState } from './AcceptInvitationState';
import { forgotPasswordState } from './ForgotPasswordState';
import { resetPhoneNumberState } from './ResetPhoneNumberState';
import { ssoState } from './SSOState';
import { profileState } from './ProfileState';
import { mfaState } from './MfaState';
import { teamState } from './TeamState';
import { socialLoginState } from './SocialLogins';
import { signUpState } from './SignUp';
import { apiTokensState } from './ApiTokensState';
import { securityPolicyState } from './Security/SecurityPolicyState';
import { accountSettingsState } from './AccountSettingsState';
import { tenantsState } from './TenantsState';
import { rolesState } from './RolesState';
import { sessionsState } from './SessionsState';
import { sessionsPolicyState } from './Security/SessionsPolicyState';
import { restrictionsState } from './Security/RestrictionsState';
import { provisioningState } from './Provisioning';
import { passkeysState } from './PasskeysState';
import { groupsState } from './GroupsState';
import { groupsDialogsState } from './GroupsState/groupsDialogsState';
import { customLoginState } from './CustomLoginState';
import { allAccountsState } from './MSP/AllAccountsState';
import { allAccountsDialogsState } from './MSP/AllAccountsState/allAccountsDialogsState';
import { securityCenterState } from './Security/SecurityCenterState';
import { defaultFronteggRoutes } from './LoginState/consts';
import { smsState } from './SmsState';
import { stepUpState } from './StepUpState';
export const reinitializeState = {
  isAuthenticated: false,
  isLoading: true,
  isSSOAuth: false,
  user: null,
  signUpState,
  loginState,
  stepUpState,
  activateState,
  acceptInvitationState,
  forgotPasswordState,
  ssoState,
  profileState,
  customLoginState,
  mfaState,
  teamState,
  groupsState,
  groupsDialogsState,
  socialLoginState,
  apiTokensState,
  securityPolicyState,
  accountSettingsState,
  tenantsState,
  rolesState,
  resetPhoneNumberState,
  sessionsState,
  sessionsPolicyState,
  restrictionsState,
  provisioningState,
  passkeysState,
  allAccountsState,
  allAccountsDialogsState,
  securityCenterState,
  smsState
};
export const initialState = _extends({
  routes: defaultFronteggRoutes,
  onRedirectTo: () => {}
}, reinitializeState);