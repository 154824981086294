export var formatName = function formatName(name) {
  return name.replace(/\W+/g, ' ').split(/ |\B(?=[A-Z])/).map(function (word) {
    return word.toLowerCase();
  }).join('');
};
export var createElement = function createElement(container, type) {
  var attrs = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var el = document.createElement(type);
  Object.keys(attrs).forEach(function (key) {
    return el.setAttribute(key, attrs[key]);
  });
  container.appendChild(el);
  return el;
};
export var waitThemeSetter = function waitThemeSetter(instance) {
  return new Promise(function (resolve, reject) {
    var timeout = 4000;
    var interval = setInterval(function () {
      if (instance.themeSetter != null) {
        clearInterval(interval);
        resolve(true);
        return;
      }
      if (timeout < 0) {
        reject('Failed to load Renderer with themeSetter');
        clearInterval(interval);
        return;
      }
      timeout -= 50;
    }, 50);
  });
};
export var restoreSearchParams = function restoreSearchParams(searchParams) {
  var stringifiedSearchParams = searchParams.toString();
  if (!stringifiedSearchParams) {
    return;
  }
  var url = new URL(window.location.href);
  url.search = stringifiedSearchParams;
  window.history.pushState(null, '', url.toString());
};