// export store

import { subscriptionsStoreName as storeName } from '../constants';
import { actions, initialState, reducer } from './reducer';
import { sagas, mockSagas } from './saga';
export * from './interfaces';
export { sagas as subscriptionSagas, mockSagas as subscriptionSagasMock, reducer as subscriptionReducers, actions as subscriptionActions, initialState as subscriptionInitialState, storeName as subscriptionsStoreName };

// export store
export default {
  sagas,
  mockSagas,
  reducer,
  actions,
  initialState,
  storeName
};