import { urls } from "../constants";
import { Delete, Get, Post } from "../fetch";
export async function getUserPhoneNumbers(queryParams) {
  return Get(`${urls.identity.phoneNumbers.v1}`, queryParams);
}
export async function createPhoneNumber(body) {
  return Post(`${urls.identity.phoneNumbers.v1}`, body);
}
export async function deletePhoneNumber(phoneId) {
  return Delete(`${urls.identity.phoneNumbers.v1}/${phoneId}`);
}
export async function preVerifyPhoneNumber(body) {
  return Post(`${urls.identity.phoneNumbers.v1}/preverify`, body);
}
export async function verifyPhoneNumber(body) {
  return Post(`${urls.identity.phoneNumbers.v1}/verify`, body);
}
export async function verifyDeletePhoneNumber(phoneId, body) {
  return Post(`${urls.identity.phoneNumbers.v1}/${phoneId}/delete/verify`, body);
}