/* eslint-disable @typescript-eslint/no-explicit-any */
import { ChangeDetectorRef, Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-new-plan-preview',
  templateUrl: './new-plan-preview.component.html',
  styleUrls: ['./new-plan-preview.component.scss'],
})
export class NewPlanPreviewComponent implements OnInit {
  planForm!: FormGroup;
  planImage!: any;
  data: any;
  public planNameEvent: EventEmitter<any> = new EventEmitter();

  constructor(public bsModalPlanRef: BsModalRef, private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.planForm = new FormGroup({
      planName: new FormControl('', [Validators.required, Validators.maxLength(80)])
    });
  }

  cancelPlan() {
    this.bsModalPlanRef.hide();
  }
  savePlan() {
    if (this.planForm.controls['planName'].value.trim() === '') {
      this.planForm.controls['planName'].setValue(this.planForm.controls['planName'].value.trim())
      return;
    }
    this.planNameEvent.emit({ values: this.planForm.controls['planName'].value })
  }
}
