/* eslint-disable @typescript-eslint/no-explicit-any */
import { OptimizedReport } from '@SiteOwl/core';
import { Options } from 'ngx-slider-v2';
import { ChangeDetectionStrategy, Component, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-multiplan-report',
  templateUrl: './multiplan-report.component.html',
  styleUrls: ['./multiplan-report.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiplanReportComponent {
  isShowLoader = false;
  pinSizeValue: any;
  pinSizeOptions: Options = {
    floor: 18,
    ceil: 60,
    step: 6,
    showTicks: true
  };
  floorId!: any;
  pinColor: any = 'Device Color';
  assignedToFilter!: any
  assignedTo!: any;
  installByUserItems!: any;
  filterCategory!: any;
  projectId!: any;
  optimizedReportOptions = OptimizedReport
  optimizedFor: any = OptimizedReport[0].value;
  selectedAllPlans: any = []
  isProject: any;
  building!: any;
  isUnlimitedPlan!: any;
  totalPlans!: any;
  id!: any;
  isFromSite!: any;
  isFromFloorPlan!: any;
  buildings!: any;
  public event: EventEmitter<any> = new EventEmitter();
  floorIds: any = [];
  constructor(public bsModalRef: BsModalRef) { }


  closeModalPopUp() {
    this.bsModalRef.hide();
  }
  downloadPlans(e: any) {
    this.floorIds = e
  }
  openColumnConfig() {
    const reportViewPrefrence: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'vp');
    const systemTypeFilter: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'st');
    const deviceStatus: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'op');
    const installStatus: any = this.filterCategory.filter((x: any) => x.isChecked && x.type === 'is');
    const downloadConfig: any = {
      floorId: this.floorId,
      viewingPreference: reportViewPrefrence,
      systemTypeFilter: systemTypeFilter,
      deviceStatus: deviceStatus,
      installStatus: installStatus,
      equipmentSize: this.pinSizeValue,
      pinColor: this.pinColor,
      assignedTo: this.assignedTo !== null && this.assignedTo !== undefined ? this.assignedTo : null,
      optimizedFor: this.optimizedFor,
      liveSiteReportField: "",
      projectReportField: "",
      floorIds: this.floorIds
    }
    this.event.emit(downloadConfig);
  }

  pinSizeChange(e: any) {
    this.pinSizeValue = e;
  }
  optimizedChanged(item: any) {
    this.optimizedFor = item.value
  }
  filterCategoryChanged(item: any) {
    item.isChecked = !item.isChecked;
  }
  filterLabel(filter: any) {
    return {
      'show-part-number': filter.id === 2,
      'show-device-status': filter.id === 8,
      'show-install-status': filter.id === 11
    }
  }
}
