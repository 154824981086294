/* eslint-disable no-prototype-builtins */
import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import {ValidationService} from '@SiteOwl/core'

@Component({
  selector: 'so-control-messages',
  templateUrl: './control-messages.component.html',
  styleUrls: ['./control-messages.component.scss'],
})
export class ControlMessagesComponent {
  @Input() control!: FormControl;
  @Input() name = '';
  @Input() isFormSubmitted = false;
  @Input() fromContext = false;
  @Input() className: any

  get errorMessage() {
      for (const propertyName in this.control.errors) {
          if (this.control.touched || this.isFormSubmitted)
            if ((this.control.errors.hasOwnProperty(propertyName)) || (this.control.root.invalid)) {
                return ValidationService.getValidatorErrorMessage(propertyName, this.name, this.control.errors[propertyName]);
            }
      }
      return null;
  }
}
