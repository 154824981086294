/* eslint-disable @typescript-eslint/no-explicit-any */
import { AuthService, ButtonEnableDisableService, CommonService, CompareRoles, Customer, CustomerService, ListService, MessageService, Pattern, Utilities, ValidationService, compareProjectRoles } from '@SiteOwl/core';
import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FormBuilder, Validators } from "@angular/forms";
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'so-invite-integrator',
    templateUrl: './invite-integrator.component.html',
    styleUrls: ['./invite-integrator.component.scss'],
})
export class InviteIntegratorComponent implements OnInit, OnDestroy {

    isEditMode = false;
    isProjectRole!: boolean
    isProvider!: boolean
    nullValue: any = null;
    providerDetail!: any;
    providers!: any[];
    users!: any[];
    providerForm!: any;
    customers!: Customer[];
    roles: any = {
        siteRole: [],
        projectRole: []
    };
    isFormSubmitted = false;
    currentUser: any;
    userCount!: number;
    isSelected = true
    tempProviderProjectName!: string;
    tempProviderAMTName!: string
    amtRoleSelection: any = 'amt_Administrator';
    projectRoleSelection: any = 'project_Manager';
    previouslyCustomerId!: number;
    newProjectRoleSelection: any;
    newRoleSelection: any;
    isAdminChecked = false;
    isLiveSystemAccessForCustomer: any;
    adminRole: any;
    public event: EventEmitter<any> = new EventEmitter();
    isFromSettingPage: any = false;
    isSecurityTeam: any = false;
    constructor(
        private listSerivce: ListService,
        private customerService: CustomerService,
        private fb: FormBuilder, private commanService: CommonService,
        private messageService: MessageService,
        private authenticationService: AuthService,
        public btnService: ButtonEnableDisableService, public bsModalRef: BsModalRef
    ) { }

    private getRoles() {
        this.providerForm.controls['amtRole'].setValue(this.roles.siteRole.find((x: any) => x.id === this.providerDetail.amtRole.id), { onlySelf: true });
        this.providerForm.controls['projectRole'].setValue(this.roles.projectRole.find((x: any) => x.id === this.providerDetail.projectRole.id)
            , { onlySelf: true });
    }

    private setFormValue() {
        this.providerForm.controls['provider'].setValue(this.providerDetail);
        this.providerForm.controls['email'].setValue(this.providerDetail.email);
        this.providerForm.controls['phoneNumber'].setValue(this.providerDetail.phoneNumber);
        this.tempProviderProjectName = Utilities.isNull(this.providerDetail.projectRole) ? '' : this.providerDetail.projectRole.name;
        this.tempProviderAMTName = Utilities.isNull(this.providerDetail.amtRole) ? '' : this.providerDetail.amtRole.name;
    }

    private createForm() {
        this.providerForm = this.fb.group({
            provider: ['', [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
            phoneNumber: ['', [Validators.maxLength(80)]],
            email: ['', [Validators.pattern(Pattern.EMAIL)]],
        });
        
    }

    private addProvider(exit?: boolean) {
        const data: any = this.providerForm.value;
        data.providerId = this.providerForm.value.provider;
        data.customerId = this.previouslyCustomerId;
        data.isAdmin = this.isAdminChecked;
        this.btnService.disable();
        data['provider'] = this.providers.find((x: any) => x.id === this.providerForm.value.provider);

        this.customerService.inviteProvider(data).subscribe({
            next: (result: any) => {
                this.btnService.enable();
                this.messageService.successMessage(`Headquarters : ${Utilities.isEmpty(data.provider.name) ? data.provider.email : data.provider.name}`, 'Invite Successfully');
                this.event.emit(result);
                this.bsModalRef.hide();
            },
            error: (error: any) => {
                this.btnService.enable();
                if (error.status === 409) {
                    this.messageService.errorMessage(`User`, error.error.message);
                } else {
                    if (error.status === 422) {
                        this.messageService.errorMessage('Headquarters', error.error.message);
                    } else {
                        this.messageService.errorMessage(`Error`, 'Error while saving');
                    }
                }
            }
        });
    }

    private updateProvider(exit?: boolean) {
        this.btnService.disable();
        const data: any = this.providerForm.value;
        data.id = this.providerDetail.id;
        
        if (data.provider.headquartersType === 2) {
            data.provider.headquartersType = {
                id: 2, name: 'Security Team'
            }
        }else{
            data.provider.headquartersType = {
                id:1, name: 'Integrator'
            }
        }
        this.customerService.updateCustomerProvider(data).subscribe({
            next: (result: any) => {
                this.btnService.enable();
                this.messageService.successMessage(`Headquarters : ${Utilities.isEmpty(data.provider.name) ? data.provider.email : data.provider.name}`, 'Updated Successfully');
                this.event.emit(result);
                this.bsModalRef.hide();
            }, error: (providerError: any) => {
                this.btnService.enable();
                if (providerError.status === 409 || providerError.status === 422) {
                    this.messageService.errorMessage(`Headquarters`, providerError.error.message);
                } else {
                    this.messageService.errorMessage(`Error`, 'Error while saving');
                }
            }
        });

    }

    changeStatus() {
        this.isSelected = !this.isSelected;
    }
    ngOnInit(): void {
        if (!(this.providers.find((res: any) => res.id === ''))) {
            if (this.isSecurityTeam) {
                this.providers.unshift({ id: '', name: 'Select Security Team' })
            } else {
                this.providers.unshift({ id: '', name: this.isFromSettingPage ? 'Select Integrator' : 'Select Headquarters' })
            }
        }
        this.currentUser = this.authenticationService.getUserKeyData();
        this.createForm();
        if (this.isEditMode) {
            this.setFormValue();
        }

        this.providerForm.controls['email'].disable();
        this.providerForm.controls['phoneNumber'].disable();
    }


    saveProvider() {
        if (this.providerForm.invalid) {
            this.isFormSubmitted = true;
            return;
        }

        if (this.isEditMode) {
            this.updateProvider();
        } else {
            this.addProvider();
        }
    }

    closeModal() {
        this.bsModalRef.hide();
    }

    providerChange(provider: any) {
        this.providerDetail = this.providers.find((x: any) => x.id === provider);
    }

    roleChanged(item?: any, type?: any, evt?: any): void {
        if (type === 'siteRole') {
            this.providerForm.value.amtRole = item;
            this.tempProviderAMTName = item.name
            this.providerForm.controls['amtRole'].setValue(this.roles.siteRole.find((x: any) => x.id === item.id), { onlySelf: true });
            if (evt !== undefined) {
                this.newRoleSelection = evt.target.id;
            }
        }
        if (type === 'projectRole') {
            this.providerForm.value.projectRole = item;
            this.tempProviderProjectName = item.name;
            this.providerForm.controls['projectRole'].setValue(this.roles.projectRole.find((x: any) => x.id === item.id), { onlySelf: true });
            if (evt !== undefined) {
                this.newProjectRoleSelection = evt.target.id;
            }
        }
    }
    isAdminChanged(event: any) {
        if (event.target.checked) {
            this.isAdminChecked = true;
            this.newRoleSelection = '';
            this.projectRoleSelection = '';
            this.newProjectRoleSelection = '';
            this.amtRoleSelection = '';
        } else {
            this.isAdminChecked = false;
            this.newRoleSelection = 'amt_Manager';
            this.amtRoleSelection = 'amt_Manager';
            this.projectRoleSelection = 'project_Manager';
            this.newProjectRoleSelection = 'project_Manager';
        }
        this.providerForm.controls['amtRole'].setValue(this.roles.siteRole.filter((e: any) => e.roleKey === 'amt_Manager')[0]);
        this.providerForm.controls['projectRole'].setValue(this.roles.projectRole.filter((e: any) => e.roleKey === 'project_Manager')[0]);
    }
    ngOnDestroy() {
        this.btnService.enable();
    }

    getAdminRole() {
        this.adminRole = this.commanService.getLocalStorageObject('adminRole')
        if (this.adminRole === undefined || this.adminRole === null) {
            this.listSerivce.getAdminSpecificRoleList().subscribe({
                next: (r: any) => {
                    this.adminRole = Utilities.seperateRoleDescription(r);
                    this.commanService.setLocalStorageObject('adminRole', this.adminRole);
                }
            })
        }
    }
}

