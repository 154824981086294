import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _extends from "@babel/runtime/helpers/esm/extends";
const _excluded = ["callback"],
  _excluded2 = ["roles"],
  _excluded3 = ["callback"],
  _excluded4 = ["callback", "profileImage"],
  _excluded5 = ["callback"],
  _excluded6 = ["callback", "userId"],
  _excluded7 = ["callback"],
  _excluded8 = ["callback"],
  _excluded9 = ["callback"],
  _excluded10 = ["callback"],
  _excluded11 = ["callback"],
  _excluded12 = ["callback"],
  _excluded13 = ["callback"],
  _excluded14 = ["callback"],
  _excluded15 = ["callback"],
  _excluded16 = ["callback", "profileImage"],
  _excluded17 = ["callback"],
  _excluded18 = ["callback"],
  _excluded19 = ["callback"],
  _excluded20 = ["callback"],
  _excluded21 = ["callback"],
  _excluded22 = ["callback"],
  _excluded23 = ["callback"],
  _excluded24 = ["callback"];
import { takeLatest, put, call, all, takeEvery, select as sagaSelect } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { TeamStateKeys } from './interfaces';
import { authStoreName } from '../../constants';
import { delay } from '../utils';
import { allUsersDemo, permissionsDemo, rolesDemo, usersDemo, userTeamDemo } from '../dummy';
import { v4 as uuidv4 } from 'uuid';
import { GroupRelations } from '../GroupsState/interfaces';
import { errorHandler } from '../../utils';
const selectTeamState = () => sagaSelect(_ => _[authStoreName].teamState);
function* getGroupsForUsers() {
  try {
    const {
      groups
    } = yield call(api.groups.getGroups, {
      _groupsRelations: GroupRelations.roles
    });
    return groups;
  } catch (e) {
    return [];
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loadUsersV2({
  payload
}) {
  var _payload$pageSize, _payload$pageOffset, _payload$filter, _payload$sort, _payload$shouldShowSu;
  const {
    silentLoading,
    callback
  } = payload;
  const teamState = yield selectTeamState();
  const pageSize = (_payload$pageSize = payload.pageSize) != null ? _payload$pageSize : teamState.pageSize;
  const pageOffset = (_payload$pageOffset = payload.pageOffset) != null ? _payload$pageOffset : teamState.pageOffset;
  const filter = (_payload$filter = payload.filter) != null ? _payload$filter : teamState.filterV2;
  const sort = (_payload$sort = payload.sort) != null ? _payload$sort : teamState.sortV2;
  const shouldIncludeSubTenants = (_payload$shouldShowSu = payload == null ? void 0 : payload.shouldShowSubTenantUsersIfReseller) != null ? _payload$shouldShowSu : teamState == null ? void 0 : teamState.shouldShowSubTenantUsersIfReseller;
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: !silentLoading
  }));
  yield put(actions.setTeamState({
    pageSize,
    pageOffset,
    filterV2: filter,
    sortV2: sort
  }));
  try {
    const [{
      items: users,
      _metadata: {
        totalPages,
        totalItems
      }
    }, {
      items: roles
    }, {
      items: permissions
    }, groups] = yield all([call(api.users.getUsersV2, _extends({}, (filter == null ? void 0 : filter.length) && {
      _filter: filter
    }, sort && {
      _sortBy: sort
    }, payload.order && {
      _order: payload.order
    }, {
      _offset: pageOffset,
      _limit: pageSize,
      _includeSubTenants: shouldIncludeSubTenants
    })), call(api.teams.loadAvailableRoles), call(api.teams.loadAvailablePermissions), call(getGroupsForUsers)]);

    //TODO: extend users type with groups in rest-api
    const usersWithGroups = users.map(user => {
      var _user$groups;
      const userGroupsFullData = user == null ? void 0 : (_user$groups = user.groups) == null ? void 0 : _user$groups.map(group => groups.filter(g => g.id === group.id)).flat();
      return _extends({}, user, {
        groups: userGroupsFullData
      });
    });
    yield put(actions.setTeamState({
      users: usersWithGroups.map(user => _extends({}, user, {
        roleIds: user.roles.map(role => role.id)
      })),
      totalPages,
      totalItems,
      roles,
      permissions
    }));
    callback == null ? void 0 : callback(users);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.USERS,
      value: errorHandler(e)
    }));
    yield put(actions.setTeamState({
      totalPages: 0,
      users: []
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: false
  }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loadUsers({
  payload
}) {
  var _payload$pageSize2, _payload$pageOffset2, _payload$filter2, _payload$sort2;
  const {
    silentLoading,
    callback
  } = payload;
  const teamState = yield selectTeamState();
  const pageSize = (_payload$pageSize2 = payload.pageSize) != null ? _payload$pageSize2 : teamState.pageSize;
  const pageOffset = (_payload$pageOffset2 = payload.pageOffset) != null ? _payload$pageOffset2 : teamState.pageOffset;
  const filter = (_payload$filter2 = payload.filter) != null ? _payload$filter2 : teamState.filter;
  const sort = (_payload$sort2 = payload.sort) != null ? _payload$sort2 : teamState.sort;
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: !silentLoading
  }));
  yield put(actions.setTeamState({
    pageSize,
    pageOffset,
    filter: filter,
    sort: sort
  }));
  try {
    const [{
      items: users,
      totalPages,
      totalItems
    }, {
      items: roles
    }, {
      items: permissions
    }] = yield all([call(api.teams.loadUsers, {
      pageSize,
      pageOffset,
      filter,
      sort
    }), call(api.teams.loadAvailableRoles), call(api.teams.loadAvailablePermissions)]);
    yield put(actions.setTeamState({
      users,
      totalPages,
      totalItems,
      roles,
      permissions
    }));
    callback == null ? void 0 : callback(users);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.USERS,
      value: e.message
    }));
    yield put(actions.setTeamState({
      totalPages: 0,
      users: []
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: false
  }));
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* loadAllSubTenantsUsers({
  payload
}) {
  var _payload$_limit, _payload$_offset, _payload$_filter, _payload$_sortBy, _payload$_order;
  const {
    silentLoading,
    callback
  } = payload;
  const teamState = yield selectTeamState();
  const _limit = (_payload$_limit = payload._limit) != null ? _payload$_limit : teamState.allUsersQueryParams._limit;
  const _offset = (_payload$_offset = payload._offset) != null ? _payload$_offset : teamState.allUsersQueryParams._offset;
  const _filter = (_payload$_filter = payload._filter) != null ? _payload$_filter : teamState.allUsersQueryParams._filter;
  const _sortBy = (_payload$_sortBy = payload._sortBy) != null ? _payload$_sortBy : teamState.allUsersQueryParams._sortBy;
  const _order = (_payload$_order = payload._order) != null ? _payload$_order : teamState.allUsersQueryParams._order;
  const allUsersQueryParams = {
    _limit: _limit || 20,
    _offset: _offset || 0,
    _filter: _filter || '',
    _sortBy: _sortBy || 'name',
    _order: _order || 'DESC'
  };
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: !silentLoading
  }));
  yield put(actions.setTeamState({
    allUsersQueryParams
  }));
  try {
    const [{
      items: users,
      _metadata: {
        totalPages,
        totalItems
      }
    }, {
      items: roles
    }, {
      items: permissions
    }] = yield all([call(api.subTenants.loadAllUsers, _extends({}, allUsersQueryParams)), call(api.teams.loadAvailableRoles), call(api.teams.loadAvailablePermissions)]);
    yield put(actions.setTeamState({
      allUsers: users,
      totalPages,
      totalItems,
      roles,
      permissions
    }));
    callback == null ? void 0 : callback(users);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.USERS,
      value: errorHandler(e)
    }));
    yield put(actions.setTeamState({
      totalPages: 0,
      users: []
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: false
  }));
}
function* loadRoles({
  payload
}) {
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.ROLES_AND_PERMISSIONS,
    value: true
  }));
  try {
    var _payload$callback;
    const [{
      items: roles
    }, {
      items: permissions
    }] = yield all([call(api.teams.loadAvailableRoles), call(api.teams.loadAvailablePermissions)]);
    yield put(actions.setTeamState({
      roles,
      permissions
    }));
    payload == null ? void 0 : (_payload$callback = payload.callback) == null ? void 0 : _payload$callback.call(payload, {
      roles,
      permissions
    });
  } catch (e) {
    var _payload$callback2;
    payload == null ? void 0 : (_payload$callback2 = payload.callback) == null ? void 0 : _payload$callback2.call(payload, null, e);
    yield put(actions.setTeamError({
      key: TeamStateKeys.ROLES_AND_PERMISSIONS,
      value: errorHandler(e)
    }));
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.ROLES_AND_PERMISSIONS,
    value: true
  }));
}
function* addUser({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  try {
    var _roles$map;
    const res = yield call(api.teams.addUser, body);
    const {
        roles
      } = res,
      userWithoutRoleIds = _objectWithoutPropertiesLoose(res, _excluded2);
    const roleIds = (_roles$map = roles == null ? void 0 : roles.map(role => role.id)) != null ? _roles$map : [];
    const newUser = _extends({}, userWithoutRoleIds, {
      roleIds
    });
    callback == null ? void 0 : callback(newUser);
    yield put(actions.setTeamState({
      users: [newUser, ...teamState.users],
      addUserDialogState: {
        open: false,
        loading: false
      }
    }));
  } catch (e) {
    yield put(actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: false,
        error: errorHandler(e)
      })
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* addUsersBulk({
  payload
}) {
  const {
    callback
  } = payload;
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  const allowedEmails = [];
  const unallowedEmails = [];
  const bodies = payload.emails.map(email => _extends({
    email,
    roleIds: payload.roleIds
  }, payload.expirationInSeconds && {
    expirationInSeconds: payload.expirationInSeconds
  }));
  yield put(actions.setTeamState({
    addUserDialogState: {
      open: true,
      loading: true
    }
  }));
  for (let i = 0; i < bodies.length; i++) {
    const body = bodies.at(i);
    try {
      yield call(api.teams.addUser, body);
      allowedEmails.push(body.email);
    } catch (e) {
      unallowedEmails.push(body.email);
    }
  }
  const queryObject = {
    pageOffset: 0,
    pageSize: 10,
    filter: '',
    silentLoading: payload.emails.length > 0
  };
  yield put(actions.loadUsersV2(queryObject));
  yield put(actions.setTeamState({
    addUserDialogState: {
      loading: false
    }
  }));
  callback == null ? void 0 : callback({
    unallowedEmails,
    allowedEmails
  });
}
function* addUserToSubTenants({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded3);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  try {
    yield call(api.subTenants.addUserToTenantAndSubTenants, body);
    const {
      items: users,
      _metadata: {
        totalPages,
        totalItems
      }
    } = yield call(api.subTenants.loadAllUsers, {
      _limit: 20,
      _offset: 0,
      _filter: '',
      _sortBy: 'name',
      _order: 'DESC'
    });
    yield put(actions.setTeamState({
      allUsers: users,
      totalPages,
      totalItems,
      addUserDialogState: {
        open: false,
        loading: false
      }
    }));
    callback == null ? void 0 : callback(null);
  } catch (e) {
    yield put(actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: false,
        error: errorHandler(e)
      })
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* updateUser({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded4);
  const {
    id: userId
  } = body;
  const teamState = yield selectTeamState();
  const oldUserData = teamState.users.find(user => user.id === body.id);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER,
    value: userId || ''
  }));
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    }),
    users: teamState.users.map(user => {
      if (user.id === body.id) {
        return _extends({}, user, body);
      }
      return user;
    })
  }));
  try {
    var _body$roleIds;
    if (oldUserData.roleIds.length > 0 && ((_body$roleIds = body.roleIds) == null ? void 0 : _body$roleIds.length) === 0) {
      body.roleIds = [''];
    }
    const {
      item: newUser
    } = yield call(api.teams.updateUser, body);
    callback == null ? void 0 : callback(newUser);
    yield put(actions.setTeamState({
      users: teamState.users.map(user => {
        return user.id === newUser.id ? _extends({}, user, newUser, {
          groups: user.groups,
          createdAt: user.createdAt,
          customData: user.customData,
          lastLogin: user.lastLogin
        }) : user;
      })
    }));
    yield put(actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER,
      value: false
    }));
  } catch (e) {
    yield put(actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: false,
        error: errorHandler(e)
      }),
      users: teamState.users.map(user => user.id === body.id ? _extends({}, user, oldUserData) : user)
    }));
    yield put(actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER,
      value: false
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteUser({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded5);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
      loading: true
    })
  }));
  try {
    yield call(api.teams.deleteUser, body);
    callback == null ? void 0 : callback(true);
    yield put(actions.setTeamState({
      users: teamState.users.filter(user => user.id !== body.userId),
      deleteUserDialogState: {
        open: false,
        loading: false
      }
    }));
  } catch (e) {
    yield put(actions.setTeamState({
      deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
        loading: false,
        error: errorHandler(e)
      })
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* setUserRolesForSubTenants({
  payload
}) {
  const {
      callback,
      userId
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded6);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  try {
    yield call(api.subTenants.setUserRolesForSubTenants, userId, body);
    const updatedUser = teamState.allUsers.find(user => user.id === userId);
    let newTenants = [];
    if (updatedUser) {
      newTenants = updatedUser.tenants.map(tenant => {
        var _body$subTenantsRoles;
        return _extends({}, tenant, {
          roles: ((_body$subTenantsRoles = body.subTenantsRoles.find(roleUpdate => roleUpdate.tenantId === tenant.tenantId)) == null ? void 0 : _body$subTenantsRoles.roleIds.map(roleId => {
            const role = teamState.roles.find(({
              id
            }) => roleId === id);
            return role;
          }).filter(role => role)) || tenant.roles
        });
      });
    }
    yield put(actions.setTeamState({
      allUsers: [...teamState.allUsers.filter(user => user.id !== userId), ...(updatedUser ? [_extends({}, updatedUser, {
        tenants: newTenants
      })] : [])],
      addUserDialogState: {
        open: false,
        loading: false
      }
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamState({
      addUserDialogState: _extends({}, teamState.addUserDialogState, {
        loading: false,
        error: errorHandler(e)
      })
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* deleteUserFromSubTenants({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded7);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
      loading: true
    })
  }));
  try {
    yield call(api.subTenants.removeUserFromTenantAndSubTenants, body);
    callback == null ? void 0 : callback(true);
    yield put(actions.setTeamState({
      allUsers: teamState.allUsers.filter(user => user.id !== body.userId && user.tenants.length === body.subTenants.length),
      deleteUserDialogState: {
        open: false,
        loading: false
      }
    }));
  } catch (e) {
    yield put(actions.setTeamState({
      deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
        loading: false,
        error: errorHandler(e)
      })
    }));
    callback == null ? void 0 : callback(null, e);
  }
}
function* resendActivationLink({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded8);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_ACTIVATE_LINK,
    value: body.userId
  }));
  try {
    yield call(api.teams.resendActivationLink, body);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.RESEND_ACTIVATE_LINK,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_ACTIVATE_LINK,
    value: false
  }));
}
function* resendInvitationLink({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded9);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: body.email
  }));
  try {
    yield call(api.teams.resendInvitationLink, body);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: false
  }));
}
function* resendInvitationEmail({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded10);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: body.email
  }));
  try {
    yield call(api.auth.resendInvitationEmail, body);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: false
  }));
}
function* resendInvitationLinkToAllSubTenants({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded11);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: body.email
  }));
  try {
    yield call(api.teams.resendInvitationLinkToAllTenants, body);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.RESEND_INVITATION_LINK,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  }
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: false
  }));
}
function* getInvitationLinkConfig() {
  yield put(actions.setTeamError({
    key: TeamStateKeys.CONFIG_TOKEN_LINK,
    value: false
  }));
  try {
    const invitationLinkConfig = yield call(api.teams.getInviteLinkConfiguration);
    yield put(actions.setTeamState({
      inviteTokenState: _extends({}, invitationLinkConfig)
    }));
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.CONFIG_TOKEN_LINK,
      value: errorHandler(e)
    }));
  }
}
function* getTemporaryUsersConfig() {
  yield put(actions.setTeamError({
    key: TeamStateKeys.CONFIG_TEMPORARY_USERS,
    value: false
  }));
  try {
    const temporaryUsersConfig = yield call(api.teams.getTemporaryUserConfiguration);
    yield put(actions.setTeamState({
      temporaryUsersConfig
    }));
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.CONFIG_TEMPORARY_USERS,
      value: errorHandler(e)
    }));
  }
}
function* updateUserExpirationTime({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded12);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
    value: true
  }));
  try {
    const res = yield call(api.teams.updateUserExpirationTime, body);
    yield put(actions.setTeamState({
      users: teamState.users.map(user => {
        return user.id === body.userId ? _extends({}, user, {
          temporaryExpirationDate: new Date(res.temporaryExpirationDate)
        }) : user;
      })
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: false
    }));
  }
}
function* setUserAsPermanent({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded13);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
    value: true
  }));
  try {
    yield call(api.teams.setPermanentUser, body.userId);
    yield put(actions.setTeamState({
      users: teamState.users.map(user => {
        return user.id === body.userId ? _extends({}, user, {
          temporaryExpirationDate: undefined
        }) : user;
      })
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(null, e);
  } finally {
    yield put(actions.setTeamLoader({
      key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
      value: false
    }));
  }
}
function* getInvitationLink() {
  yield put(actions.setTeamError({
    key: TeamStateKeys.GET_TOKEN_LINK,
    value: false
  }));
  try {
    yield call(getInvitationLinkConfig);
    const data = yield call(api.teams.getInviteUserLink);
    const {
      inviteTokenState
    } = yield selectTeamState();
    yield put(actions.setTeamState({
      inviteTokenState: _extends({}, inviteTokenState, data)
    }));
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.GET_TOKEN_LINK,
      value: errorHandler(e)
    }));
  }
}
function* createInvitationLink({
  payload: {
    callback
  }
}) {
  yield put(actions.setTeamError({
    key: TeamStateKeys.CREATE_TOKEN_LINK,
    value: false
  }));
  const {
    inviteTokenState
  } = yield selectTeamState();
  try {
    const data = yield call(api.teams.createInviteUserLink, {
      expiresInMinutes: 43200
    });
    yield put(actions.setTeamState({
      inviteTokenState: _extends({}, inviteTokenState, data)
    }));
    callback == null ? void 0 : callback(data.token);
  } catch (e) {
    callback == null ? void 0 : callback(null, e);
    yield put(actions.setTeamError({
      key: TeamStateKeys.CREATE_TOKEN_LINK,
      value: errorHandler(e)
    }));
  }
}
function* updateInvitationLink({
  payload: {
    callback,
    expiresInMinutes,
    shouldSendEmail
  }
}) {
  const {
    inviteTokenState
  } = yield selectTeamState();
  yield put(actions.setTeamError({
    key: TeamStateKeys.UPDATE_TOKEN_LINK,
    value: false
  }));
  try {
    const data = yield call(api.teams.updateInviteUserLink, {
      expiresInMinutes,
      shouldSendEmail
    });
    yield put(actions.setTeamState({
      inviteTokenState: _extends({}, inviteTokenState, data)
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    callback == null ? void 0 : callback(null, e);
    yield put(actions.setTeamError({
      key: TeamStateKeys.UPDATE_TOKEN_LINK,
      value: errorHandler(e)
    }));
  }
}
function* deleteInvitationLink({
  payload
}) {
  const {
    callback
  } = payload != null ? payload : {};
  yield put(actions.setTeamError({
    key: TeamStateKeys.DELETE_TOKEN_LINK,
    value: false
  }));
  try {
    yield call(api.teams.deleteInviteUserLink);
    yield put(actions.setTeamState({
      inviteTokenState: undefined
    }));
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setTeamError({
      key: TeamStateKeys.DELETE_TOKEN_LINK,
      value: errorHandler(e)
    }));
    callback == null ? void 0 : callback(false, e);
  }
}
function* openAddUserDialog({
  payload
}) {
  yield put(actions.setTeamState({
    addUserDialogState: _extends({
      open: true,
      loading: false,
      error: false
    }, payload)
  }));
}
function* closeAddUserDialog({
  payload
}) {
  const teamState = yield selectTeamState();
  const {
    addUserDialogState: {
      onClose
    }
  } = teamState;
  onClose == null ? void 0 : onClose(payload);
  yield put(actions.setTeamState({
    addUserDialogState: {
      loading: false,
      error: false,
      open: false
    }
  }));
}
function* openDeleteUserDialog({
  payload
}) {
  yield put(actions.setTeamState({
    deleteUserDialogState: _extends({
      open: true,
      loading: false,
      error: false
    }, payload)
  }));
}
function* closeDeleteUserDialog({
  payload
}) {
  const teamState = yield selectTeamState();
  const {
    deleteUserDialogState: {
      onClose
    }
  } = teamState;
  onClose == null ? void 0 : onClose(payload);
  yield put(actions.setTeamState({
    deleteUserDialogState: {
      loading: false,
      error: false,
      open: false
    }
  }));
}
export function* teamSagas() {
  yield takeLatest(actions.loadUsers, loadUsers);
  yield takeLatest(actions.loadUsersV2, loadUsersV2);
  yield takeLatest(actions.loadAllSubTenantsUsers, loadAllSubTenantsUsers);
  yield takeLatest(actions.loadRoles, loadRoles);
  yield takeEvery(actions.addUser, addUser);
  yield takeEvery(actions.addUsersBulk, addUsersBulk);
  yield takeEvery(actions.addUserToSubTenants, addUserToSubTenants);
  yield takeEvery(actions.updateUser, updateUser);
  yield takeEvery(actions.setUserRolesForSubTenants, setUserRolesForSubTenants);
  yield takeEvery(actions.deleteUser, deleteUser);
  yield takeEvery(actions.deleteUserFromSubTenants, deleteUserFromSubTenants);
  yield takeEvery(actions.resendActivationLink, resendActivationLink);
  yield takeEvery(actions.resendInvitationLink, resendInvitationLink);
  yield takeEvery(actions.resendInvitationEmail, resendInvitationEmail);
  yield takeEvery(actions.resendInvitationLinkToAllSubTenants, resendInvitationLinkToAllSubTenants);
  yield takeEvery(actions.getTemporaryUsersConfig, getTemporaryUsersConfig);
  yield takeEvery(actions.updateUserExpirationTime, updateUserExpirationTime);
  yield takeEvery(actions.setUserAsPermanent, setUserAsPermanent);
  yield takeEvery(actions.getInvitationLink, getInvitationLink);
  yield takeEvery(actions.createInvitationLink, createInvitationLink);
  yield takeEvery(actions.updateInvitationLink, updateInvitationLink);
  yield takeEvery(actions.deleteInvitationLink, deleteInvitationLink);
  yield takeEvery(actions.openAddUserDialog, openAddUserDialog);
  yield takeEvery(actions.closeAddUserDialog, closeAddUserDialog);
  yield takeEvery(actions.openDeleteUserDialog, openDeleteUserDialog);
  yield takeEvery(actions.closeDeleteUserDialog, closeDeleteUserDialog);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadUsersMock({
  payload
}) {
  var _payload$pageSize3, _payload$pageOffset3, _payload$filter3, _payload$sort3;
  const {
    silentLoading,
    callback
  } = payload;
  const teamState = yield selectTeamState();
  const pageSize = (_payload$pageSize3 = payload.pageSize) != null ? _payload$pageSize3 : teamState.pageSize;
  const pageOffset = (_payload$pageOffset3 = payload.pageOffset) != null ? _payload$pageOffset3 : teamState.pageOffset;
  const filter = (_payload$filter3 = payload.filter) != null ? _payload$filter3 : teamState.filterV2;
  const sort = (_payload$sort3 = payload.sort) != null ? _payload$sort3 : teamState.sortV2;
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: !silentLoading
  }));
  yield put(actions.setTeamState({
    pageSize,
    pageOffset,
    filterV2: filter,
    sortV2: sort
  }));
  const totalPages = 2;
  const totalItems = 10;
  yield delay();
  yield put(actions.setTeamState({
    users: usersDemo,
    totalPages,
    totalItems,
    roles: rolesDemo,
    permissions: permissionsDemo
  }));
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: false
  }));
  callback == null ? void 0 : callback(usersDemo);
}
function* loadAllSubTenantsUsersMock({
  payload
}) {
  var _payload$_limit2, _payload$_offset2, _payload$_filter2, _payload$_sortBy2, _payload$_order2;
  const {
    silentLoading,
    callback
  } = payload;
  const teamState = yield selectTeamState();
  const _limit = (_payload$_limit2 = payload._limit) != null ? _payload$_limit2 : teamState.allUsersQueryParams._limit;
  const _offset = (_payload$_offset2 = payload._offset) != null ? _payload$_offset2 : teamState.allUsersQueryParams._offset;
  const _filter = (_payload$_filter2 = payload._filter) != null ? _payload$_filter2 : teamState.allUsersQueryParams._filter;
  const _sortBy = (_payload$_sortBy2 = payload._sortBy) != null ? _payload$_sortBy2 : teamState.allUsersQueryParams._sortBy;
  const _order = (_payload$_order2 = payload._order) != null ? _payload$_order2 : teamState.allUsersQueryParams._order;
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: !silentLoading
  }));
  yield put(actions.setTeamState({
    allUsersQueryParams: {
      _limit,
      _offset,
      _filter,
      _sortBy,
      _order
    }
  }));
  const totalPages = 2;
  const totalItems = 10;
  yield delay();
  yield put(actions.setTeamState({
    allUsers: allUsersDemo,
    totalPages,
    totalItems,
    roles: rolesDemo,
    permissions: permissionsDemo
  }));
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.USERS,
    value: false
  }));
  callback == null ? void 0 : callback(allUsersDemo);
}
function* loadRolesMock({
  payload
}) {
  var _payload$callback3;
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.ROLES_AND_PERMISSIONS,
    value: true
  }));
  yield delay();
  yield put(actions.setTeamState({
    roles: rolesDemo,
    permissions: permissionsDemo
  }));
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.ROLES_AND_PERMISSIONS,
    value: true
  }));
  payload == null ? void 0 : (_payload$callback3 = payload.callback) == null ? void 0 : _payload$callback3.call(payload, {
    roles: rolesDemo,
    permissions: permissionsDemo
  });
}
function* addUserMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded14);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  yield delay();
  const date = new Date();
  const newUser = _extends({}, userTeamDemo, {
    groups: []
  }, body, {
    id: `${uuidv4()}`,
    temporaryExpirationDate: body.expirationInSeconds ? new Date(date.setSeconds(date.getSeconds() + body.expirationInSeconds)) : undefined
  });
  callback == null ? void 0 : callback(newUser);
  yield put(actions.setTeamState({
    users: [newUser, ...teamState.users],
    addUserDialogState: {
      open: false,
      loading: false
    }
  }));
}
function* addUserToSubTenantsMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded15);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  yield delay();
  const newUser = _extends({}, userTeamDemo, body, {
    id: `${uuidv4()}`
  });
  yield put(actions.setTeamState({
    users: [newUser, ...teamState.users],
    addUserDialogState: {
      open: false,
      loading: false
    }
  }));
  callback == null ? void 0 : callback(null);
}
function* updateUserMock({
  payload
}) {
  var _body$roleIds2;
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded16);
  const {
    id: userId
  } = body;
  const teamState = yield selectTeamState();
  const oldUserData = teamState.users.find(user => user.id === body.id);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER,
    value: userId || ''
  }));
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    })
  }));
  yield put(actions.setTeamState({
    addUserDialogState: _extends({}, teamState.addUserDialogState, {
      loading: true
    }),
    users: teamState.users.map(user => {
      if (user.id === body.id) {
        return _extends({}, user, body);
      }
      return user;
    })
  }));
  if (oldUserData.roleIds.length > 0 && ((_body$roleIds2 = body.roleIds) == null ? void 0 : _body$roleIds2.length) === 0) {
    body.roleIds = [''];
  }
  yield delay();
  const newUser = _extends({}, oldUserData, body);
  callback == null ? void 0 : callback(newUser);
  yield put(actions.setTeamState({
    users: teamState.users.map(user => user.id === newUser.id ? _extends({}, user, newUser, {
      createdAt: user.createdAt,
      customData: user.customData,
      lastLogin: user.lastLogin
    }) : user)
  }));
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER,
    value: false
  }));
}
function* deleteUserMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded17);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
      loading: true
    })
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamState({
    users: teamState.users.filter(user => user.id !== body.userId),
    deleteUserDialogState: {
      open: false,
      loading: false
    }
  }));
}
function* deleteUserFromSubTenantsMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded18);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamState({
    deleteUserDialogState: _extends({}, teamState.deleteUserDialogState, {
      loading: true
    })
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamState({
    allUsers: teamState.allUsers.filter(user => user.id !== body.userId),
    deleteUserDialogState: {
      open: false,
      loading: false
    }
  }));
}
function* resendActivationLinkMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded19);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_ACTIVATE_LINK,
    value: body.userId
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_ACTIVATE_LINK,
    value: false
  }));
}
function* resendInvitationLinkMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded20);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: body.email
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: false
  }));
}
function* resendInvitationEmailMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded21);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: body.email
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: false
  }));
}
function* resendInvitationLinkToAllSubTenantsMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded22);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: body.email
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.RESEND_INVITATION_LINK,
    value: false
  }));
}
function* updateUserExpirationTimeMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded23);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
    value: true
  }));
  const date = new Date();
  yield put(actions.setTeamState({
    users: teamState.users.map(user => {
      return user.id === body.userId ? _extends({}, user, {
        temporaryExpirationDate: body.expirationInSeconds ? new Date(date.setSeconds(date.getSeconds() + body.expirationInSeconds)) : undefined
      }) : user;
    })
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
    value: false
  }));
}
function* setUserAsPermanentMock({
  payload
}) {
  const {
      callback
    } = payload,
    body = _objectWithoutPropertiesLoose(payload, _excluded24);
  const teamState = yield selectTeamState();
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
    value: true
  }));
  yield put(actions.setTeamState({
    users: teamState.users.map(user => {
      return user.id === body.userId ? _extends({}, user, {
        temporaryExpirationDate: undefined
      }) : user;
    })
  }));
  yield delay();
  callback == null ? void 0 : callback(true);
  yield put(actions.setTeamLoader({
    key: TeamStateKeys.UPDATE_USER_EXPIRATION_TIME,
    value: false
  }));
}
export function* teamSagasMock() {
  yield takeLatest(actions.loadUsersV2, loadUsersMock);
  yield takeLatest(actions.loadAllSubTenantsUsers, loadAllSubTenantsUsersMock);
  yield takeLatest(actions.loadRoles, loadRolesMock);
  yield takeEvery(actions.addUser, addUserMock);
  yield takeEvery(actions.addUserToSubTenants, addUserToSubTenantsMock);
  yield takeEvery(actions.updateUser, updateUserMock);
  yield takeEvery(actions.deleteUser, deleteUserMock);
  yield takeEvery(actions.updateUserExpirationTime, updateUserExpirationTimeMock);
  yield takeEvery(actions.setUserAsPermanent, setUserAsPermanentMock);
  yield takeEvery(actions.deleteUserFromSubTenants, deleteUserFromSubTenantsMock);
  yield takeEvery(actions.resendActivationLink, resendActivationLinkMock);
  yield takeEvery(actions.resendInvitationLink, resendInvitationLinkMock);
  yield takeEvery(actions.resendInvitationEmail, resendInvitationEmailMock);
  yield takeEvery(actions.resendInvitationLinkToAllSubTenants, resendInvitationLinkToAllSubTenantsMock);
  yield takeEvery(actions.openAddUserDialog, openAddUserDialog);
  yield takeEvery(actions.closeAddUserDialog, closeAddUserDialog);
  yield takeEvery(actions.openDeleteUserDialog, openDeleteUserDialog);
  yield takeEvery(actions.closeDeleteUserDialog, closeDeleteUserDialog);
}