<ngx-charts-pie-chart [view]="[width, height]" [legendTitle]="legendTitle" [scheme]="colorScheme"
    [results]="pieChartData" [gradient]="gradient" [legend]="showLegend" [legendPosition]="legendPosition"
    [labels]="showLabels" [doughnut]="isDoughnut" [arcWidth]="arcWidth" [labelFormatting]="labelFormat"
    (select)="chartClicked($event)">
    <ng-template #tooltipTemplate let-model="model" let-index="i">
        <div class="chart-bar-tooltip left live-planning-tooltip" *ngIf="totalAging > 0">
            <span class="tooltip-label">{{model.name}}</span>
            <p>
                <span [ngStyle]="{'color': getAgingColor(model)}">{{model.value}}</span>
                {{model.value===1?'Device':'Devices'}}
            </p>
            <p><strong>{{getAgingPer(model)}}%</strong> of Total Devices</p>
        </div>
        <div class="chart-bar-tooltip left live-planning-tooltip" *ngIf="totalDeviceWarrantyCnt > 0">
            <span class="tooltip-label">{{model.name}}</span>
            <p>
                <span *ngIf="model.name==='Under Device Warranty'"
                   class="blue-text" >{{model.value}}</span>
                <span *ngIf="model.name==='Device Warranty Expired'" class="yellow-text">{{model.value}}</span>
                <span *ngIf="model.name==='No Device Warranty'" class="gray-light-text">{{model.value}}</span>
                {{model.value===1?'Device':'Devices'}}
            </p>
            <p><strong>{{getTotalPers(model,'warranty')}}%</strong> of Total Devices</p>
        </div>
        <div class="chart-bar-tooltip left live-planning-tooltip" *ngIf="totalLaborWarrantyCnt > 0">
            <span class="tooltip-label">{{model.name}}</span>
            <p><span *ngIf="model.name==='Under Labor Warranty'" class="blue-text">{{model.value}}</span>
                <span *ngIf="model.name==='Labor Warranty Expired'" class="yellow-text">{{model.value}}</span>
                <span *ngIf="model.name==='No Labor Warranty'" class="gray-light-text">{{model.value}}</span>
                {{model.value===1?'Device':'Devices'}}
            </p>
            <p><strong>{{ getTotalPers(model,'labor')}}%</strong> of Total Devices</p>
        </div>
    </ng-template>
</ngx-charts-pie-chart>