import { PaymentMethodType, ISubscriptionStatus } from '@frontegg/rest-api';
export const subscriptionResponseMock = {
  id: 'sub_1JbhYVEwsu4qiqnnfMhYAdY6',
  externalId: 'sub_1JbhYVEwsu4qiqnnfMhYAdY6',
  startDate: '2021-09-20T08:08:51.000Z',
  status: ISubscriptionStatus.ACTIVE,
  externallyManaged: false,
  cancellation: null,
  currentPeriodStart: '2021-09-20T08:08:51.000Z',
  currentPeriodEnd: '2021-10-20T08:08:51.000Z',
  plan: {
    // TODO: fix dummy data
    slug: 'test'
  }
  // items: [
  //   {
  //     id: 'si_KGE0p5himocPXH',
  //     externalId: 'prod_J60fUEvI7qV1eL',
  //     planId: 'prod_J60fUEvI7qV1eL',
  //     price: {
  //       id: 'price_1IToe8Ewsu4qiqnndAV76J69',
  //       externalId: 'price_1IToe8Ewsu4qiqnndAV76J69',
  //       currency: 'usd',
  //       amount: 10000,
  //     },
  //   },
  // ],
};
export const planResponseMock = [{
  id: 'prod_J60fUEvI7qV1eL',
  externalId: 'prod_J60fUEvI7qV1eL',
  name: 'Premium',
  description: '',
  price: {
    id: 'price_1IToe8Ewsu4qiqnndAV76J69',
    externalId: 'price_1IToe8Ewsu4qiqnndAV76J69',
    currency: 'usd',
    amount: 10000
  },
  slug: 'premium'
}, {
  id: 'prod_J60duauCpXfcur',
  externalId: 'prod_J60duauCpXfcur',
  name: 'Free',
  description: 'Totally free plan. Nothing to pay.',
  price: {
    id: 'price_1ITocfEwsu4qiqnnnBHDx9fQ',
    externalId: 'price_1ITocfEwsu4qiqnnnBHDx9fQ',
    currency: 'usd',
    amount: 0
  },
  slug: 'free'
}];
export const invoicesMock = [{
  id: 'in_1JbhYWEwsu4qiqnnrUh6hsHa',
  externalId: 'in_1JbhYWEwsu4qiqnnrUh6hsHa',
  subscriptionId: 'sub_1JbhYVEwsu4qiqnnfMhYAdY6',
  paymentDate: '2021-09-20T08:08:51.000Z',
  totalAmount: 10000,
  currency: 'usd',
  paid: true,
  receiptNumber: ''
}, {
  id: 'in_1JbhYWEwsu4qiqnnrUh6hsHa',
  externalId: 'in_1JbhYWEwsu4qiqnnrUh6hsHa',
  subscriptionId: 'sub_1JbhYVEwsu4qiqnnfMhYAdY6',
  paymentDate: '2021-08-20T08:08:51.000Z',
  totalAmount: 10000,
  currency: 'usd',
  paid: true,
  receiptNumber: ''
}];
export const paymentMethodsMock = [{
  id: 'id',
  externalId: 'externalId',
  type: PaymentMethodType.CARD,
  isDefault: true,
  last4: '4242',
  expMonth: 11,
  expYear: 25,
  brand: 'visa',
  billingDetails: {
    name: 'Dummy',
    email: 'dummy@email.com'
  }
}];
export const vendorPublicConfigurationResponseMock = {
  allowPlanDowngrade: true,
  allowPlanCancellation: true
};