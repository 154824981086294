import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const provisioningState = {
  loading: false
};
const reducers = {
  setProvisioningState: typeReducerForKey('provisioningState'),
  resetProvisioningState: resetStateByKey('provisioningState', {
    provisioningState
  })
};
const actions = {
  loadProvisionConnections: createAction(`${authStoreName}/loadProvisionConnections`),
  createProvisionConnection: createAction(`${authStoreName}/createProvisionConnection`, payload => ({
    payload
  })),
  deleteProvisionConnection: createAction(`${authStoreName}/deleteProvisionConnection`, payload => ({
    payload
  })),
  updateProvisionConnection: createAction(`${authStoreName}/updateProvisionConnection`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { provisioningState, reducers as provisioningReducers, actions as provisioningActions };