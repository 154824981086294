/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppService, CommonService, Environment, EventEmitterType, EventService, LoaderService, ProjectStage, Utilities } from '@SiteOwl/core';

@Component({
  selector: 'so-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
})
export class CardListComponent implements OnInit {
  @Input() cardList: any;
  @Input() cardType!: string;
  @Input() isProject!: boolean;
  @Input() showGrid = false;
  @Input() showCustomer = false;
  @Input() showProgressBar = false;
  @Input() showBarChart = false;
  @Input() showBuildingProgress = false;
  @Input() showBuildingPercentage = false;
  @Input() showAction = false;
  @Input() showArchives = false;
  @Input() data!: any;
  @Input() selectedId!: number;
  @Input() checkIsViewOnly = false;
  @Input() canMigrateProject = false;
  @Input() isGlobalAdmin = false;
  @Input() tooltipClass: any;
  @Input() floorPlanImageThumbArr: any;
  @Output() cardClick = new EventEmitter();
  @Output() navActionClick = new EventEmitter();
  @Output() progressTooltipAction = new EventEmitter();
  @Output() editSiteDetailEmit = new EventEmitter();
  @Output() moveProjectBuilding = new EventEmitter();
  @Output() migrateSiteToProject = new EventEmitter();
  stage = ProjectStage;
  buildingIdKey = 'id'
  deviceStatusData: any;
  chartRender!: any;
  colorScheme: any = {
    domain: ['#00B2F8', '#A4A1FB']
  };
  showXAxis = true;
  showYAxis = true;
  xAxisLabel = true;
  yAxisLabel = true;
  constructor(private readonly env: Environment,
    private http: HttpClient,
    private cd: ChangeDetectorRef,
    public loaderService: LoaderService,
    private router: Router,
    private appService: AppService,
    private commonService: CommonService,
    private eventService: EventService) {
    if (this.isProject) {
      this.buildingIdKey = 'buildingId'
    }
  }

  ngOnInit(): void {
    this.appService.getStatusData().subscribe({
      next: r => {
        this.deviceStatusData = r
      }
    })
  }
  _onCardClick($event: Event, clickType: string) {
    this.cardClick.emit({ event: $event, clickType: clickType });
  }
  progressTooltip(card: any, $event: any, isBuilding?: boolean) {
    this.progressTooltipAction.emit({
      action: 'add',
      card: card,
      event: $event,
      isBuilding: isBuilding
    })
  }
  progressTooltipRemove(card: any, $event: any, isBuilding?: boolean) {
    this.progressTooltipAction.emit({
      action: 'remove',
      card: card,
      event: $event,
      isBuilding: isBuilding
    })
  }
  _navActionClick(card: any, isDeviceOnly?: boolean, isTaskOnly?: boolean, buildingId?: number, floorId?: number) {
    this.navActionClick.emit({ card: card, isDeviceOnly: isDeviceOnly, isTaskOnly: isTaskOnly, buildingId: buildingId, floorId: floorId });
  }
  _navActionFlagClick(card: any) {
    this.navActionClick.emit({ card: card, flagged: true, archivedStatus: card.archived ? 'Archived' : 'Active' });
  }
  _navActionClickCompelted(card: any) {
    this.navActionClick.emit({ card: card, fromCompleted: true });
  }
  detach($event?: any, card?: any, buildingId?: number, index?: any) {
    if (this.isProject && card && card.projectStage === 'Installation' && index >= 0) {
      if (!card['chartData']) {
        card['daysFilterChart'] = 30;
        card['chartData'] = undefined
      }
      this.getProjectChartData(card, index)
    }
    if (card) {
      document.getElementById('building_' + card[this.cardList.id] + '_0')?.click();
    }
  }
  goToFloorPage(card: any, building: any, floorId?: number) {
    if (this.showCustomer) {
      this.eventService.broadcast(EventEmitterType.createProjectFromDashboard, {
        buildingId: building?.buildingId,
        customer: card.customer_id,
        stage: "floorPlan",
        projectId: card.projectId,
        floorId: floorId ? floorId : building?.floorDTOS[0].id,
        customerName: card.customerName,
        canProjectEdit: card.canEdit,
        comp: ProjectStage.Installation
      });
    } else {
      if (this.isProject) {
        if (floorId) {
          this.router.navigate(['/secure/project', card?.projectId, 'building', building?.buildingId, 'floor', floorId, 'details'], { queryParams: { canProjectEdit: card.canEdit, comp: card.projectStage } })
        } else {
          if (building?.floorDTOS && building?.floorDTOS.length > 0) {
            this.router.navigate(['/secure/project', card?.projectId, 'building', building?.buildingId, 'floor', building?.floorDTOS[0].id, 'details'], { queryParams: { canProjectEdit: card.canEdit, comp: card.projectStage } })
          }
        }
      } else if (this.cardType === 'site') {
        if (building?.floors && building?.floors.length > 0) {
          this.router.navigate(['secure/site', card.id, 'building', building?.id, 'floor', floorId ? floorId : building?.floors[0].id, 'details']);
        }
      } else if (this.cardType === 'planlibrary' && !this.showArchives) {
        if (floorId) {
          this.router.navigate([`/secure/live-site/${card.id}/building/edit/${building.id}`], {
            queryParams: {
              floorId: floorId,
              isFromPlanLib: true
            }
          })
        } else {
          this.router.navigate([`/secure/live-site/${card.id}/building/edit/${building.id}`], { queryParams: { isFromPlanLib: true } })
        }
      }
    }
  }
  goToInfoPage(card: any, tab: any) {
    if (this.showCustomer) {
      this.eventService.broadcast(EventEmitterType.createProjectFromDashboard, {
        customer: card.customer_id,
        stage: "EditProject",
        projectId: card.projectId,
        customerName: card.customerName,
        isReport: tab,
      });
    } else {
      if (this.isProject) {
        if (tab === 'reports') {
          this.router.navigate(['secure/project/edit', card.projectId], { queryParams: { selectedTab: 'reports' } })
        } else if (tab === 'progress') {
          this.router.navigate(['secure/project/edit', card.projectId], { queryParams: { selectedTab: 'progress' } })
        } else {
          this.router.navigate(['secure/project/edit', card.projectId])
        }
      } else if (this.cardType === 'site') {
        if (tab === 'reports') {
          this.router.navigate(['secure/live-site/edit', card.id], { queryParams: { isFromSite: 'true', selectedTab: 'reports' } })
        } else {
          this.router.navigate(['secure/live-site/edit', card.id], { queryParams: { isFromSite: 'true' } })
        }
      } else if (this.cardType === 'planlibrary') {
        this.router.navigate(['secure/live-site/library', card.id], { queryParams: { isFromLibrary: 'true' } })
      }
    }
  }
  tootipSelect($event: any) {
  }
  toggleEvent($event: any, card: any, building: any) {
    $event.preventDefault();
    $event.stopPropagation();
  }
  calculatePercent(value1: number, value2: number): number {
    return Number(((value1 / value2) * 100).toFixed(2)) || 0;
  }
  updateDeviceStatusName(status: any) {
    if (this.deviceStatusData !== undefined) {
      return Utilities.updateDisplayStatus(this.deviceStatusData, status);
    }
  }
  getImage(floor: any) {
    const imgUrl = floor.floorThumbnailImage;
    let uncertainThumbnails: never[] = [];
    if (sessionStorage.getItem('uncertainThumbnails')) {
      uncertainThumbnails = JSON.parse(sessionStorage.getItem('uncertainThumbnails') || '');
    }
    if (uncertainThumbnails && uncertainThumbnails.filter(e => imgUrl && imgUrl.includes(e)).length > 0) {
      if (floor.imageCheckCnt === undefined || floor.imageCheckCnt === null || floor.imageCheckCnt === 0) {
        floor.imageCheckCnt = 1
      }
      if (!floor.isImageLoading) {
        floor.isImageLoading = true;
        const url = imgUrl;
        this.http.get(url, { responseType: 'blob' }).subscribe((r: any) => {
          uncertainThumbnails.splice(uncertainThumbnails.findIndex(e => e === imgUrl), 1);
          sessionStorage.setItem('uncertainThumbnails', JSON.stringify(uncertainThumbnails));
          const timeOut = setTimeout(() => {
            floor.isImageLoading = false;
            floor.isLoadingDone = true;
            clearTimeout(timeOut)
          }, 2000);
        }, (e: any) => {
          floor.imageCheckCnt = floor.imageCheckCnt + 1
          if (floor.imageCheckCnt < 31) {
            const timeOut = setTimeout(() => {
              floor.isImageLoading = false;
              clearTimeout(timeOut)
            }, 2000);
          }
        });
      }
      return
    } else {
      return imgUrl;
    }
  }


  getProjectChartData(project: any, index: any) {
    if (project['chartData'] === undefined) {
      this.appService.getProjectChartData(project.projectId, project.daysFilterChart).subscribe((res: any) => {
        project['chartData'] = res;
        this.scaleValueForChart(project, index);
        this.chartRender = true;
        const timeOut = setTimeout(() => {
          this.cd.detectChanges();
          clearTimeout(timeOut)
        }, 0);
      });
    } else {
      this.chartRender = true;
      const timeOut = setTimeout(() => {
        this.cd.detectChanges();
        clearTimeout(timeOut)
      }, 500);
    }
  }
  changeDaysOfChart(event: any, project: any, index: any) {
    project['chartData'] = undefined;
    project['daysFilterChart'] = event.target.value;
    this.appService.getProjectChartData(project.projectId, event.target.value).subscribe((res: any) => {
      project['chartData'] = res;
      this.scaleValueForChart(project, index);
      this.cd.detectChanges();
      const timeOut = setTimeout(() => {
        this.cd.detectChanges();
        clearTimeout(timeOut)
      }, 0);
    });
  }

  scaleValueForChart(project: any, index: any) {
    let longestChartValue = 0;
    project['chartData'].forEach((chartParent: any) => {
      let seriesChartValue = 0;
      chartParent.series.forEach((elementSeriesValues: any, indexForValue: any) => {
        if (elementSeriesValues.value !== 1) {
          if (elementSeriesValues.name === 'Device Installed') {
            elementSeriesValues['tooltipKey'] = 'Devices Installed';
          } else {
            elementSeriesValues['tooltipKey'] = 'Tasks Completed';
          }
        } else {
          if (elementSeriesValues.name === 'Device Installed') {
            elementSeriesValues['tooltipKey'] = 'Device Installed';
          } else {
            elementSeriesValues['tooltipKey'] = 'Task Completed';
          }
        }
        seriesChartValue += elementSeriesValues.value;
        if ((chartParent.series.length - 1) === indexForValue) {
          if (longestChartValue < seriesChartValue) {
            longestChartValue = seriesChartValue;
          }
        }
      });
    });
    longestChartValue = Math.ceil(longestChartValue / 10) * 10;
    project['chartDataMaxValue'] = longestChartValue;
    const timeOut = setTimeout(() => {
      this.cd.detectChanges();
      clearTimeout(timeOut);
    }, 0);
  }

  customizeTooltip(project: any, series: any, index: any) {
    if (project['chartData'].filter((e: any) => e.name === series).length > 0) {
      return project['chartData'].filter((e: any) => e.name === series)[0].series;
    } else {
      return null;
    }
  }
  editSiteDetail(detail: any, type: any, siteDetail?: any, buildingDetail?: any, siteIndex?: any, buildIndex?: any, floorIndex?: any) {
    this.editSiteDetailEmit.emit({
      detail: detail, type: type, siteDetail: siteDetail, buildingDetail: buildingDetail, siteIndex: siteIndex, buildIndex: buildIndex, floorIndex: floorIndex
    })
  }

  moveProjectBuildings(event: any, card: any, building: any) {
    this.moveProjectBuilding.emit({ building: building, currentProject: card, projects: this.data });
  }

  manageDevice(event: any, card: any, building: any) {
    this.router.navigate(['secure/site/' + card.id + '/building/' + building.id + '/floor/' + building['floors'][0]['id'] + '/details'])
  }

  migrateToProject(event: any, card: any, building: any) {
    this.migrateSiteToProject.emit({ building: building, currentProject: card, projects: [] });
  }
  navigateToCustomerDevice(details: any, buildingId?: number, floorId?: number, status?: any) {
    let detailsBuildingId = 0;
    let detailsFloorId = 0;
    if (buildingId && buildingId > 0) {
      detailsBuildingId = buildingId;
    }
    if (floorId && floorId > 0) {
      detailsFloorId = floorId;
    }
    let data: any;
    if (status) {
      data = {
        site: details.id,
        building: detailsBuildingId,
        floor: detailsFloorId,
        status: status,
        state: 'all'
      };
    } else {
      data = {
        site: details.id,
        building: detailsBuildingId,
        floor: detailsFloorId,
        state: 'all'
      };
    }
    this.commonService.resetFilters('site');
    this.router.navigate(['/secure/live-site/devices'], {
      queryParams: data
    });
  }
}
