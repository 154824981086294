/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { document, window } from 'ngx-bootstrap/utils';

@Component({
  selector: 'so-shareable-link',
  templateUrl: './shareable-link.component.html',
  styleUrls: ['./shareable-link.component.scss'],
})
export class ShareableLinkComponent implements OnInit {

  customerId: any;
  customerName: any;
  urlToCopy: any;
  isFromProject = false;
  isFromTicket = false;
  isFromSiteDevice = false;
  isFromProjectDevice = false;
  isFromDevice: any;
  deviceId: any;
  deviceName: any;
  projectName: any;
  ticketName: any;
  canProjectEdit = false;
  isFromDeviceList = false;
  device: any;

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit(): void {
    if (this.isFromDevice) {
      if (window.location.href.includes("details?")) {
        this.urlToCopy = window.location.href.split("details?")[0] + "sharable?customerId=" + this.customerId + "&equipmentid=" + this.deviceId;
      } else {
        this.urlToCopy = window.location.href.split("details")[0] + "sharable?customerId=" + this.customerId + "&equipmentid=" + this.deviceId;
      }
    } else if (this.isFromDeviceList && this.isFromProject) {
      this.urlToCopy = window.location.origin + '/secure/project/' + this.device.projectId + '/building/' + this.device.buildingId + '/floor/' + this.device.floorId + '/sharable?customerId=' + this.customerId + '&equipmentid=' + this.deviceId
    } else if (this.isFromDeviceList && !this.isFromProject) {
      this.urlToCopy = window.location.origin + '/secure/site/' + this.device.siteId + '/building/' + this.device.buildingId + '/floor/' + this.device.floorId + '/sharable?customerId=' + this.customerId + '&equipmentid=' + this.deviceId
    } else if (!this.isFromProject && !this.isFromTicket) {
      this.urlToCopy = window.location.href.split("?")[0] + "?customerId=" + this.customerId;
    } else if (this.isFromProject) {
      const url: any = window.location.href.split("/edit");
      const url1: any = url[1].split("?");
      this.urlToCopy = url[0] + "/link" + url1[0] + "/customerId/" + this.customerId;
    } else if (this.isFromTicket) {
      let copyUrl: any
      if (window.location.href.includes("/ticket/link/")) {
        copyUrl = window.location.href
      } else {
        const url: any = window.location.href.split("/edit");
        const url1: any = url[1].split("?");
        copyUrl = url[0] + "/link" + url1[0] + "/customerId/" + this.customerId;
      }
      this.urlToCopy = copyUrl;
    }
    const range = document.createRange();
    range.selectNode(document.getElementById("urlToCopy"));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
  closeModal() {
    this.bsModalRef.hide();
  }
  copyLink() {
    const el = document.createElement("textarea");
    el.value = this.urlToCopy;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    return true;
  }

}
