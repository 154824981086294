/* eslint-disable @angular-eslint/no-output-native */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'so-textarea',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent {
  @Input() placeHolder!: string;
  @Input() maxLength!: number;
  @Input() className: any = {};
  @Input() style: any = {};
  @Input() id!: string;
  @Input() disabled = false;
  @Input() rows!: number;
  @Input() cols!: number;
  @Input() inputControlName!: FormControl;
  @Output() focus = new EventEmitter();
  @Output() blur = new EventEmitter();
  @Output() input = new EventEmitter();
  @Output() keyUp = new EventEmitter();
  _keyPress($event: Event) {
    this.keyUp.emit($event);
  }
  _focusChanged($event: Event) {
    this.focus.emit($event)
  }
  _blur($event: Event) {
    this.blur.emit($event)
  }
  _onInput($event: Event) {
    this.input.emit($event)
  }
}
