/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { AuditAPIPath, BrowserKeys, BuildingAPIPath, CustomerAPIPath, Environment, PreSignedUrl, ProjectAPIPath, ReportAPIPath, SiteAPIPath, UserAPIPath } from '../constants';
import { Utilities } from '../utilities';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { CommonService } from './common.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private ajaxService: AjaxService, private http: HttpClient, private commonService: CommonService,
    private authService: AuthService, private readonly env: Environment) { }

  getDevicefields(): any {
    return this.ajaxService.get(`${ReportAPIPath.devicesFields}/field`);
  }
  setDevicefields(columns: any) {
    return this.ajaxService.put(`${ReportAPIPath.devicesFields}/field`, columns)
  }

  getCustomerDevice(seachParams: any): any {
    return this.ajaxService.post(ReportAPIPath.devices, seachParams);
  }

  getPresignedUrl(reqData: any): any {
    return this.ajaxService.get(`${PreSignedUrl + Utilities.objectToParams(reqData)}`);
  }

  uploadFile(url: string, formData: any) {
    const uploadContentType = 'multipart/form-data';
    return this.ajaxService.put(url, formData, { headers: { 'Content-Type': uploadContentType } }, { removeAuthToken: true });
  }

  inlineChangeDevicefields(data: any, page: any): any {
    const url = page === 'site' ? ReportAPIPath.changeDevicesFields : page === 'ticket' ? (ReportAPIPath.inlineTicket + '/' + data.id) :
      page === 'audit' ? (AuditAPIPath.getAuditDetails + '/' + data.auditId + '/device/inline-edit') : ReportAPIPath.projectChangeDevicesFields;
    return this.ajaxService.put(url, data)
  }

  getNetworkSwitchDevice(id: number, isSite: boolean, buildingId?: any) {
    if (isSite) {
      return this.ajaxService.get(BuildingAPIPath.newtWorkSwitches + '?buildingId=' + id);
    } else {
      return this.ajaxService.get(BuildingAPIPath.newtWorkSwitches + '?projectId=' + id + '&&buildingId=' + buildingId);
    }
  }

  setGlobalTicket(columns: any) {
    return this.ajaxService.put(`${CustomerAPIPath.devicesFields}/field/update`, columns)
  }

  getTicketFeilds() {
    return this.ajaxService.get(`${CustomerAPIPath.devicesFields}/field`);
  }

  getAssignedSitesUser(id: any) {
    return this.ajaxService.get(SiteAPIPath.site + '/' + id + '/assignees');
  }


  downloadInventoryReport() {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
    };

    httpOptions['responseType'] = 'blob'
    const req = new HttpRequest('GET', `${this.env.apiPath}${ReportAPIPath.inventoryReport}`, httpOptions);
    return this.http.request(req);

  }

  agingAndWarrantyReport() {
    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);
    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/octet-stream',
        'Authorization': data,
        'customerId': this.authService.getCustomerId().customerId.toString()
      }),
      reportProgress: true,
    };

    httpOptions['responseType'] = 'blob'

    const req = new HttpRequest('GET', `${this.env.apiPath}${ReportAPIPath.agingAndWarrantyReport}`, httpOptions);

    return this.http.request(req);

  }
  uploadCsvDevice(file: any, isPreview: boolean): any {
    const formdata = new FormData();
    formdata.append("file", file);
    return this.ajaxService.post(`${ReportAPIPath.deviceCsvUpload}?isPreview=${isPreview ? 'true' : 'false'}`, formdata);
  }

  uploadProfilePicture(a: any) {
    return this.ajaxService.put(UserAPIPath.profilePicture, a);
  }
  getFilePresignedURL(reqData: any) {
    return this.ajaxService.get(PreSignedUrl + Utilities.objectToParams(reqData));
  }
  updateFloorImageFromList(isProject: any, data: any): any {
    return isProject ? this.ajaxService.put(ProjectAPIPath.image, data) : this.ajaxService.put(SiteAPIPath.image, data);
  }
  removeFloorImageFromList(id: any): any {
    return this.ajaxService.delete(ReportAPIPath.removeFloorImageFromList + '/' + id);
  }
}


