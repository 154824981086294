import { NgOptimizedImage, CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonComponent } from './component/button/button.component';
import { CardListComponent } from './component/card-list/card-list.component';
import { CheckboxComponent } from './component/checkbox/checkbox.component';
import { CommonPopupComponent } from './component/common-popup/common-popup.component';
import { ControlMessagesComponent } from './component/control-messages/control-messages.component';
import { CreatedUpdatedDateTimeComponent } from './component/created-updated-date-time/created-updated-date-time.component';
import { CustomDropdownComponent } from './component/custom-dropdown/custom-dropdown.component';
import { DatepickerComponent } from './component/datepicker/datepicker.component';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { FileUploadComponent } from './component/file-upload/file-upload.component';
import { FilterComponent } from './component/filter/filter.component';
import { HeaderBarComponent } from './component/header-bar/header-bar.component';
import { LeftBarComponent } from './component/left-bar/left-bar.component';
import { ListComponent } from './component/list/list.component';
import { RadioComponent } from './component/radio/radio.component';
import { RoleComponent } from './component/role/role.component';
import { SubHeaderComponent } from './component/sub-header/sub-header.component';
import { TagsComponent } from './component/tags/tags.component';
import { TextAreaComponent } from './component/text-area/text-area.component';
import { TextboxComponent } from './component/textbox/textbox.component';
import { DraggableImageDirective } from './directive/draggable-image.directive';
import { ImageOrientationDirective } from './directive/img-orientation.directive';
import { CustomerFilterPipe } from './pipe/customer-filter.pipe';
import { FilterActivePipe } from './pipe/filter-active.pipe';
import { UserFilterPipe } from './pipe/user-filter.pipe';
import { WrapPipe } from './pipe/wrap.pipe';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { LoaderComponent } from './component/loader/loader.component';
import { ToolTipComponent } from './component/tool-tip/tool-tip.component';
import { HeaderComponent } from './component/header/header.component';
import { TicketStatusPipe } from './pipe/ticket-status.pipe';
import { ShareableLinkComponent } from './component/shareable-link/shareable-link.component';
import { SliderComponent } from './component/slider/slider.component';
import { NumberDirective } from './directive/number.directive';
import { InlineEditComponent } from './component/inline-edit/inline-edit.component';
import { FilterColumnComponent } from './modal/filter-column/filter-column.component';
import { TakePictureFromCameraComponent } from './component/take-picture-from-camera/take-picture-from-camera.component';
import { PreviewImportCsvComponent } from './modal/preview-import-csv/preview-import-csv.component';
import { DeviceImportComponent } from './modal/device-import/device-import.component';
import { TagInputModule } from 'ngx-chips';
import { PixieComponent } from './component/pixie/pixie.component';
import { AddNewPopupComponent } from './component/add-new-popup/add-new-popup.component';
import { MultiplanReportComponent } from './component/multiplan-report/multiplan-report.component';
import { MultiPlanFilterPipe } from './pipe/multi-plan-filter.pipe';
import { MultiSelectPlanComponent } from './component/multi-select-plan/multi-select-plan.component';
import { UserCreateEditComponent } from './modal/user/user-create-edit/user-create-edit.component';
import { IntegratorCreateEditComponent } from './modal/integrator/integrator-create-edit/integrator-create-edit.component';
import { CustomerCreateEditComponent } from './modal/customer/customer-create-edit/customer-create-edit.component';
import { InviteUserComponent } from './modal/user/invite-user/invite-user.component';
import { InviteIntegratorComponent } from './modal/integrator/invite-integrator/invite-integrator.component';
import { DragDropFileUploadDirective } from './directive/drag-drop-file-upload.directive';
import { DatexPipe } from './pipe/datex.pipe';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { NecessaryPopupComponent } from './component/necessary-popup/necessary-popup.component';
import { PieChartComponent } from './component/chart/pie-chart/pie-chart.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BarVerticalStackedComponent } from './component/chart/bar-vertical-stacked/bar-vertical-stacked.component';
import { FileSortPipe } from './pipe/file-sort.pipe';
import { QuantityPipe } from './pipe/quantity.pipe';
import { AutoFocusDirective } from './directive/auto-focus.directive';
import { NumbersDecimalOnlyDirective } from './directive/numbers-decimal-only.directive';
import { FlooractivefilterPipe } from './pipe/flooractivefilter.pipe';
import { ReportConfigColumnComponent } from './component/report-config-column/report-config-column.component';
import { EditProviderRoleComponent } from './modal/edit-provider-role/edit-provider-role.component';
import { EditAccessLevelComponent } from './modal/edit-access-level/edit-access-level.component';
import { AddProviderUserToCustomerComponent } from './modal/add-provider-user-to-customer/add-provider-user-to-customer.component';
import { UserProjectAccessComponent } from './modal/user-project-access/user-project-access.component';
import { UserSiteAccessComponent } from './modal/user-site-access/user-site-access.component';
import { ChangePasswordComponent } from './modal/change-password/change-password.component';
import { ProviderUserfilterPipe } from './pipe/provider-userfilter.pipe';
import { TimeAgoPipe } from './pipe/time-ago.pipe';
import { FloorActiveInactivePipe } from './pipe/floor-active-inactive.pipe';
import { InviteProviderUserComponent } from './modal/user/invite-provider-user/invite-provider-user.component';
import { ProviderAccountTypeComponent } from './modal/provider-account-type/provider-account-type.component';
import { MultiSelectSiteComponent } from './component/multi-select-site/multi-select-site.component';
import { AssociatedAdminComponent } from './modal/provider-customer/associated-admin/associated-admin.component';
import { ProviderCreateEditComponent } from './modal/provider-customer/provider-create-edit/provider-create-edit.component';
import { ImportFavoritesComponent } from './modal/provider-customer/import-favorites/import-favorites.component';
import { HoverDirective } from './directive/hover.directive';
import { CustomSlicePipe } from './pipe/custom-slice.pipe';
import { CreatePlanComponent } from './component/add-new-popup/create-plan/create-plan.component';
import { CreateBuildingComponent } from './component/add-new-popup/create-building/create-building.component';
import { CreateSiteComponent } from './component/add-new-popup/create-site/create-site.component';
import { SelectSiteBuildingPopupComponent } from './component/add-new-popup/select-site-building-popup/select-site-building-popup.component';
import { HorizontalBarChartComponent } from './component/chart/horizontal-bar-chart/horizontal-bar-chart.component';
import { DragulaModule } from 'ng2-dragula';
import { ViewImageComponent } from './component/view-image/view-image.component';
import { ImageScrollDraggableDirective } from './directive/image-scroll-draggable.directive';
import { ChoosePlanPopupComponent } from './component/add-new-popup/choose-plan-popup/choose-plan-popup.component';
import { NewPlanPreviewComponent } from './component/add-new-popup/new-plan-preview/new-plan-preview.component';
import { ReplaceFloorImageCountComponent } from './component/replace-floor-image-count/replace-floor-image-count.component';
import { EditPlanOnListingComponent } from './component/edit-plan-on-listing/edit-plan-on-listing.component';
import { MoveBuildingComponent } from './component/move-building/move-building.component';
import { MigrateProjectComponent } from './component/migrate-project/migrate-project.component';
import { MonitoringComponent } from './modal/monitoring/monitoring.component';
import { LicenseAccountComponent } from './modal/license-account/license-account.component';
import { EditResponsibleIntegratorComponent } from './modal/edit-responsible-integrator/edit-responsible-integrator.component';
import { SelectPdfPageComponent } from './component/select-pdf-page/select-pdf-page.component';
import { RangePickerComponent } from './component/range-picker/range-picker.component';
import { BarHorizontalStackedComponent } from './component/chart/bar-horizontal-stacked/bar-horizontal-stacked.component';
import { YAxisTicksComponentComponent } from './component/chart/yaxis-ticks-component/yaxis-ticks-component.component';
import { RemoveZeroFromChartPipe } from './pipe/remove-zero-from-chart.pipe';
import { SelectCustomerComponent } from './component/select-customer/select-customer.component';
import { Numbers5DecimalOnlyDirective } from './directive/numbers5-decimal-only.directive';
import { LinkifyPipe } from './pipe/linkify.pipe';

const dbConfig: any = {
  name: 'SiteOwl',
  version: 1,
  objectStoresMeta: [
    {
      store: 'commonDetails',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [],
    },
    {
      store: 'floorDevices',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [],
    },
    {
      store: 'favDevices',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [],
    },
    {
      store: 'syncDetails',
      storeConfig: { keyPath: 'id', autoIncrement: false },
      storeSchema: [],
    },
    {
      store: 'deviceImage',
      storeConfig: { keyPath: 'imageId', autoIncrement: false },
      storeSchema: [],
    },
  ],
};
@NgModule({
  imports: [
    NgOptimizedImage,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgMultiSelectDropDownModule.forRoot(),
    AccordionModule.forRoot(),
    ProgressbarModule.forRoot(),
    RouterModule,
    BsDropdownModule,
    TabsModule,
    PaginationModule.forRoot(),
    ModalModule,
    BsDatepickerModule.forRoot(),
    TagInputModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    NgxChartsModule,
    DragulaModule.forRoot(),
  ],
  declarations: [
    TextboxComponent,
    ControlMessagesComponent,
    ButtonComponent,
    ListComponent,
    TextAreaComponent,
    RadioComponent,
    CheckboxComponent,
    RoleComponent,
    DropdownComponent,
    CustomDropdownComponent,
    CardListComponent,
    WrapPipe,
    DatepickerComponent,
    TagsComponent,
    FileUploadComponent,
    HeaderBarComponent,
    LeftBarComponent,
    FilterActivePipe,
    ImageOrientationDirective,
    CustomerFilterPipe,
    FilterComponent,
    SubHeaderComponent,
    DraggableImageDirective,
    CreatedUpdatedDateTimeComponent,
    UserFilterPipe,
    CommonPopupComponent,
    LoaderComponent,
    ToolTipComponent,
    HeaderComponent,
    TicketStatusPipe,
    ShareableLinkComponent,
    SliderComponent,
    NumberDirective,
    InlineEditComponent,
    FilterColumnComponent,
    TakePictureFromCameraComponent,
    PreviewImportCsvComponent,
    DeviceImportComponent,
    PixieComponent,
    AddNewPopupComponent,
    MultiplanReportComponent,
    MultiPlanFilterPipe,
    MultiSelectPlanComponent,
    UserCreateEditComponent,
    IntegratorCreateEditComponent,
    CustomerCreateEditComponent,
    InviteUserComponent,
    InviteIntegratorComponent,
    DragDropFileUploadDirective,
    DatexPipe,
    NecessaryPopupComponent,
    PieChartComponent,
    BarVerticalStackedComponent,
    FileSortPipe,
    QuantityPipe,
    AutoFocusDirective,
    NumbersDecimalOnlyDirective,
    FlooractivefilterPipe,
    ReportConfigColumnComponent,
    AddProviderUserToCustomerComponent,
    EditProviderRoleComponent,
    EditAccessLevelComponent,
    UserProjectAccessComponent,
    UserSiteAccessComponent,
    ChangePasswordComponent,
    ProviderUserfilterPipe,
    TimeAgoPipe,
    FloorActiveInactivePipe,
    InviteProviderUserComponent,
    ProviderAccountTypeComponent,
    MultiSelectSiteComponent,
    ProviderCreateEditComponent,
    AssociatedAdminComponent,
    ImportFavoritesComponent,
    HoverDirective,
    CustomSlicePipe,
    CreatePlanComponent,
    CreateBuildingComponent,
    CreateSiteComponent,
    SelectSiteBuildingPopupComponent,
    HorizontalBarChartComponent,
    ViewImageComponent,
    ImageScrollDraggableDirective,
    ChoosePlanPopupComponent,
    NewPlanPreviewComponent,
    ReplaceFloorImageCountComponent,
    EditPlanOnListingComponent,
    MoveBuildingComponent,
    MigrateProjectComponent,
    MonitoringComponent,
    LicenseAccountComponent,
    EditResponsibleIntegratorComponent,
    SelectPdfPageComponent,
    RangePickerComponent,
    BarHorizontalStackedComponent,
    YAxisTicksComponentComponent,
    RemoveZeroFromChartPipe,
    SelectCustomerComponent,
    Numbers5DecimalOnlyDirective,
    LinkifyPipe
  ],
  exports: [
    TextboxComponent,
    ControlMessagesComponent,
    ButtonComponent,
    ListComponent,
    TextAreaComponent,
    RadioComponent,
    CheckboxComponent,
    RoleComponent,
    DropdownComponent,
    CustomDropdownComponent,
    CardListComponent,
    WrapPipe,
    DatepickerComponent,
    TagsComponent,
    FileUploadComponent,
    HeaderBarComponent,
    LeftBarComponent,
    TabsModule,
    ImageOrientationDirective,
    CustomerFilterPipe,
    FilterComponent,
    SubHeaderComponent,
    DraggableImageDirective,
    CreatedUpdatedDateTimeComponent,
    UserFilterPipe,
    CommonPopupComponent,
    ModalModule,
    BsDatepickerModule,
    AccordionModule,
    LoaderComponent,
    HeaderComponent,
    TicketStatusPipe,
    ShareableLinkComponent,
    SliderComponent,
    NumberDirective,
    ToolTipComponent,
    InlineEditComponent,
    FilterColumnComponent,
    TakePictureFromCameraComponent,
    PreviewImportCsvComponent,
    DeviceImportComponent,
    ProgressbarModule,
    PixieComponent,
    AddNewPopupComponent,
    MultiplanReportComponent,
    MultiPlanFilterPipe,
    MultiSelectPlanComponent,
    IntegratorCreateEditComponent,
    CustomerCreateEditComponent,
    InviteUserComponent,
    InviteIntegratorComponent,
    DragDropFileUploadDirective,
    DatexPipe,
    NgxIndexedDBModule,
    NecessaryPopupComponent,
    PieChartComponent,
    NgxChartsModule,
    BarVerticalStackedComponent,
    BsDropdownModule,
    FileSortPipe,
    QuantityPipe,
    AutoFocusDirective,
    NumbersDecimalOnlyDirective,
    EditProviderRoleComponent,
    EditAccessLevelComponent,
    AddProviderUserToCustomerComponent,
    UserProjectAccessComponent,
    UserSiteAccessComponent,
    ProviderUserfilterPipe,
    TagInputModule,
    TimeAgoPipe,
    FlooractivefilterPipe,
    ReportConfigColumnComponent,
    FloorActiveInactivePipe,
    InviteProviderUserComponent,
    ProviderAccountTypeComponent,
    MultiSelectSiteComponent,
    ProviderCreateEditComponent,
    AssociatedAdminComponent,
    ImportFavoritesComponent,
    HoverDirective,
    CustomSlicePipe,
    CreatePlanComponent,
    CreateBuildingComponent,
    CreateSiteComponent,
    SelectSiteBuildingPopupComponent,
    HorizontalBarChartComponent,
    ViewImageComponent,
    ChoosePlanPopupComponent,
    NewPlanPreviewComponent,
    ReplaceFloorImageCountComponent,
    EditPlanOnListingComponent,
    MoveBuildingComponent,
    MigrateProjectComponent,
    SelectPdfPageComponent,
    RangePickerComponent,
    BarHorizontalStackedComponent,
    YAxisTicksComponentComponent,
    RemoveZeroFromChartPipe,
    SelectCustomerComponent,
    NgMultiSelectDropDownModule,
    Numbers5DecimalOnlyDirective,
    LinkifyPipe,
    NgOptimizedImage,
    ImageScrollDraggableDirective
  ],
  providers: [BsModalService, DatexPipe, FileSortPipe,LinkifyPipe],
})
export class UiModule {}
