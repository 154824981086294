<div>
    <div class="modal-header">
        <h1 class="modal-title pull-left">Device Import</h1>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body">
        Import new Devices or update existing Devices. For help building an import file, download our
         <a *ngIf="!isFromSite && unitOfMeasurements!=='Feet'" href="{{cdnUrl}}assets/images/site-owl-project-device-import-template-meter.csv"  download>sample template</a>
         <a *ngIf="isFromSite && unitOfMeasurements!=='Feet'" href="{{cdnUrl}}assets/images/site-owl-site-device-import-template-meter.csv"  download>sample template</a>
         <a *ngIf="!isFromSite && unitOfMeasurements==='Feet'" href="{{cdnUrl}}assets/images/site-owl-project-device-import-template.csv"  download>sample template</a>
         <a *ngIf="isFromSite && unitOfMeasurements==='Feet'" href="{{cdnUrl}}assets/images/site-owl-site-device-import-template.csv"  download>sample template</a>.
    
        </div>
    <div class="modal-footer">
        <button type="button" (click)="_actionCancel($event)" class="btn btn-secondary">Cancel</button>
        <button type="button" href="javascript:void(0);" [disabled]="buttonEnableDisableService.getStatus()"
            class="btn btn-primary  me-0 mb-0"  (click)="uploadCSVModalBrowse()">
            <em class="fas fa-upload"></em>Import
        </button>
        <input type="file" accept=".csv" class="fileUpload" #fileUpload style="display:none"
            (change)="_action($event)" />
    </div>
</div>