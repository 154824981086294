"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.evaluateIsEntitledToPermissions = void 0;
const is_entitled_to_feature_evaluator_1 = require("../is-entitled-to-feature/is-entitled-to-feature.evaluator");
const types_1 = require("../types");
const utils_1 = require("../utils");
const permissions_utils_1 = require("../utils/permissions.utils");
function evaluateIsEntitledToPermissions(permissionKey, userEntitlementsContext, attributes) {
  if (!userEntitlementsContext) {
    return {
      isEntitled: false,
      justification: types_1.NotEntitledJustification.MISSING_PERMISSION
    };
  }
  const hasPermission = (0, permissions_utils_1.checkPermission)(userEntitlementsContext.permissions, permissionKey);
  if (!hasPermission) {
    return {
      isEntitled: false,
      justification: types_1.NotEntitledJustification.MISSING_PERMISSION
    };
  }
  const linkedFeatures = getLinkedFeatures(permissionKey, userEntitlementsContext);
  if (!linkedFeatures.length) {
    return {
      isEntitled: true
    };
  }
  const entitlementResults = [];
  for (const featureKey of linkedFeatures) {
    entitlementResults.push((0, is_entitled_to_feature_evaluator_1.evaluateIsEntitledToFeature)(featureKey, userEntitlementsContext, attributes));
    if (!(0, utils_1.shouldContinue)(entitlementResults)) {
      break;
    }
  }
  return (0, utils_1.getResult)(entitlementResults);
}
exports.evaluateIsEntitledToPermissions = evaluateIsEntitledToPermissions;
function getLinkedFeatures(permissionKey, userEntitlementsContext) {
  return Object.keys(userEntitlementsContext.features).filter(featureKey => userEntitlementsContext.features[featureKey].linkedPermissions.includes(permissionKey));
}
