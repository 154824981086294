import _extends from "@babel/runtime/helpers/esm/extends";
import { call, put, select } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { getPasskeysVendorPolicy } from '../PasskeysState/helpers';
import { getFeatureFlags } from '../../helpers';
export function* shouldShowPromptPasskeys() {
  const {
    publicAuthStrategyPolicy
  } = yield select(state => state.auth.securityPolicyState);
  const {
    policy
  } = publicAuthStrategyPolicy;
  const isPasskeysEnabledByVendor = getPasskeysVendorPolicy(policy);
  const isLoggedInWithPasskeys = localStorage.getItem('preferred-login-method') === 'Passkeys';
  const isMarkedDontShowAgainPrompt = localStorage.getItem('dont-show-again-prompt-passkeys') === 'true';
  const [showPasskeys] = yield call(getFeatureFlags, ['show-passkeys-new']);
  if (!showPasskeys || !isPasskeysEnabledByVendor || isLoggedInWithPasskeys || isMarkedDontShowAgainPrompt) {
    return false;
  } else {
    const {
      devices
    } = yield call(api.auth.getWebAuthnDevices);
    yield put(actions.setPasskeysState({
      devices: devices != null ? devices : []
    }));
    const numOfDevices = !(devices != null && devices.length) ? 0 : devices.length;
    return numOfDevices === 0;
  }
}
export function* afterAuthenticationStateUpdate({
  user,
  tenants = [],
  activeTenant
}, additionalUpdate = {}) {
  const {
    tenantsState: currentTenantsState
  } = yield select(state => state.auth);
  yield put(actions.setState(_extends({
    user,
    tenantsState: _extends({}, currentTenantsState, {
      tenants,
      activeTenant,
      loading: false
    })
  }, additionalUpdate)));
}