export let TeamStateKeys;
(function (TeamStateKeys) {
  TeamStateKeys["USERS"] = "USERS";
  TeamStateKeys["STATS"] = "STATS";
  TeamStateKeys["UPDATE_USER"] = "UPDATE_USER";
  TeamStateKeys["DELETE_USER"] = "DELETE_USER";
  TeamStateKeys["RESEND_ACTIVATE_LINK"] = "RESEND_ACTIVATE_LINK";
  TeamStateKeys["RESEND_INVITATION_LINK"] = "RESEND_INVITATION_LINK";
  TeamStateKeys["ROLES_AND_PERMISSIONS"] = "ROLES_AND_PERMISSIONS";
  TeamStateKeys["CREATE_TOKEN_LINK"] = "CREATE_TOKEN_LINK";
  TeamStateKeys["GET_TOKEN_LINK"] = "GET_TOKEN_LINK";
  TeamStateKeys["UPDATE_TOKEN_LINK"] = "UPDATE_TOKEN_LINK";
  TeamStateKeys["DELETE_TOKEN_LINK"] = "DELETE_TOKEN_LINK";
  TeamStateKeys["CONFIG_TOKEN_LINK"] = "CONFIG_TOKEN_LINK";
  TeamStateKeys["CONFIG_TEMPORARY_USERS"] = "CONFIG_TEMPORARY_USERS";
  TeamStateKeys["UPDATE_USER_EXPIRATION_TIME"] = "UPDATE_USER_EXPIRATION_TIME";
})(TeamStateKeys || (TeamStateKeys = {}));