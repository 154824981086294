import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeZeroFromChart'
})
export class RemoveZeroFromChartPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): any {
    if (!value || value.length === 0) {
      return value;
    }
    return value.filter((x: any) => x.value !== 0);
  }

}
