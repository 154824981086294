import { combineReducers } from '@reduxjs/toolkit';
import { informationActions, informationReducer, initialBillingInformationState } from './Information';
import { initialPaymentMethodState, subscriptionsPaymentMethodActions, subscriptionsPaymentMethodReducer } from './PaymentMethod';
import { initialInvoicesState, invoicesActions, invoicesReducer } from './Invoices';
import { initialSubscriptionState, subscriptionActions, subscriptionReducer } from './Subscription';
export const billingInitialState = {
  information: initialBillingInformationState,
  invoices: initialInvoicesState,
  paymentMethod: initialPaymentMethodState,
  subscription: initialSubscriptionState
};
export const billingActions = {
  invoices: invoicesActions,
  information: informationActions,
  paymentMethod: subscriptionsPaymentMethodActions,
  subscription: subscriptionActions
};
export const billingReducer = combineReducers({
  invoices: invoicesReducer,
  information: informationReducer,
  paymentMethod: subscriptionsPaymentMethodReducer,
  subscription: subscriptionReducer
});