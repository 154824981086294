import { call, put } from 'redux-saga/effects';
import { subscriptionActions } from './index';
import { api } from '@frontegg/rest-api';

// Separated folder due to circular dependency

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* loadSubscription() {
  yield put(subscriptionActions.setLoading(true));
  try {
    const [subscription] = yield call(api.subscriptions.getManagedSubscriptions);
    yield put(subscriptionActions.setState({
      subscription,
      fetching: false,
      loading: false,
      error: null
    }));
  } catch (e) {
    yield put(subscriptionActions.setError(e));
  }
}