import _extends from "@babel/runtime/helpers/esm/extends";
import { call, select } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { getMfaStepForEnrolledUsers, getMfaStepForNotEnrolledUsers } from '../utils';
import { LoginStep, LoginFlow } from '../interfaces';
// Separated folder due to circular dependency

export function* getMfaRequiredState(user) {
  let step = LoginStep.loginWithTwoFactor;
  let mfaState = {};
  const {
    loginState
  } = yield select(state => state.auth);
  const {
    isAllowedToRemember,
    mfaDeviceExpiration
  } = yield call(api.auth.checkIfAllowToRememberMfaDevice, user.mfaToken);
  const isEnrolled = !(user.hasOwnProperty('mfaEnrolled') && !user.mfaEnrolled);
  if (isEnrolled) {
    mfaState = {
      mfaDevices: user.mfaDevices,
      step: getMfaStepForEnrolledUsers(user.mfaDevices)
    };
  } else {
    mfaState = {
      step: getMfaStepForNotEnrolledUsers(user.mfaStrategies),
      qrCode: user.qrCode,
      recoveryCode: user.recoveryCode,
      loading: false,
      mfaToken: user.mfaToken,
      mfaStrategies: user.mfaStrategies
    };
    step = LoginStep.forceTwoFactor;
  }
  let quickLoginState = {};
  const quickLoginToRegister = localStorage.getItem('register-quick-login');
  if (quickLoginToRegister) {
    quickLoginState = {
      quickLoginToRegister,
      flow: LoginFlow.RegisterQuickLogin
    };
  }
  return {
    user: undefined,
    isAuthenticated: false,
    mfaState,
    loginState: _extends({}, loginState, quickLoginState, {
      mfaToken: user.mfaToken,
      mfaRequired: user.mfaRequired,
      loading: false,
      error: undefined,
      step,
      tenantsLoading: true,
      email: user.userEmail,
      tenants: [],
      allowRememberMfaDevice: isAllowedToRemember,
      mfaDeviceExpiration,
      isBreachedPassword: user.isBreachedPassword
    })
  };
}