import { Get, Post } from '../fetch';
async function getMetadata(body) {
  var _data$rows, _data$rows2;
  const data = await Get('/metadata', body);
  if (data != null && (_data$rows = data.rows) != null && _data$rows[0]) return data == null ? void 0 : (_data$rows2 = data.rows) == null ? void 0 : _data$rows2[0];
  throw new Error(`metadata not found: ${body.entityName}`);
}
export async function getAdminBoxMetadata() {
  return Get('/metadata/admin-box');
}
export async function updateAdminBoxMetadata(body) {
  return Post('/metadata/admin-box', body);
}
async function getIpMetadata(ip) {
  const data = await Get(`/metadata/ip/${ip}`);
  if (data) return data;
  throw new Error(`ip metadata not found`);
}
export async function getCurrentUserIpMetadata() {
  const data = await Get(`/metadata/ipme`);
  if (data) return data;
  throw new Error(`ip metadata not found`);
}
export const getNotificationsMetadata = async () => getMetadata({
  entityName: 'notifications'
});
export const getSamlMetadata = async () => getMetadata({
  entityName: 'saml'
});
export const getAuditsMetadata = async () => getMetadata({
  entityName: 'audits'
});
export const getIpAdressMetadata = async ip => getIpMetadata(ip);