/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { AjaxService } from './ajax.service';
import { BrowserKeys, CommonListAPIPath, CustomerAPIPath, CustomerUserAPIPath, Environment, ProjectAPIPath, ProviderAPIPath, SerivceTicketAPIPath } from '../constants';
import { Customer, Provider, User } from '../model';
import { CommonService } from './common.service';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private ajaxService: AjaxService,
    private commonService: CommonService,
    private readonly env: Environment,
    private http: HttpClient) { }
  getAmtUsers(): any {
    return this.ajaxService.get(`${CustomerUserAPIPath.amtUsers}`);
  }
  getUsers(): any {
    return this.ajaxService.get(CustomerUserAPIPath.customerSpecificUsers);
  }

  getActiveUsers(): any {
    return this.ajaxService.get(`${CustomerUserAPIPath.activeUsers}`);
  }

  getCustomers(): any {
    return this.ajaxService.get(CustomerAPIPath.customers);
  }

  updateUser(site: User): any {
    return this.ajaxService.put(CustomerUserAPIPath.user, site);
  }
  addUser(site: User): any {
    return this.ajaxService.post(CustomerUserAPIPath.user, site);
  }

  addProvider(provider: Provider): any {
    return this.ajaxService.post(ProviderAPIPath.provider, provider);
  }
  updateProvider(provider: Provider): any {
    return this.ajaxService.put(ProviderAPIPath.provider, provider);
  }

  addCustomer(site: Customer): any {
    return this.ajaxService.post(CustomerAPIPath.customer, site);
  }
  updateCustomer(site: Customer): any {
    return this.ajaxService.put(CustomerAPIPath.customer, site);
  }

  addCustomerUser(user: any): any {
    return this.ajaxService.post(`${CustomerAPIPath.customer}/add-user`, user);
  }
  addCustomerUserFromManage(site: User, customerId: number): any {
    return this.ajaxService.post(`${CustomerAPIPath.customer}/${customerId}/adduser`, site);
  }
  updateCustomerUser(id: number, data: any): any {
    return this.ajaxService.put(`${CustomerAPIPath.customer}/${id}/user`, data);
  }

  inviteProvider(provider: any): any {
    return this.ajaxService.post(`${ProviderAPIPath.provider}/invite`, provider);
  }
  updateCustomerProvider(data: any): any {
    return this.ajaxService.put(`${CustomerAPIPath.customer}/provider`, data);
  }
  updateCustomerSpecificUser(site: User): any {
    return this.ajaxService.put(CustomerUserAPIPath.customerSpecificUser, site);
  }
  addSpecificCustomerUser(site: any) {
    return this.ajaxService.post(CustomerUserAPIPath.customerSpecificUser, site);
  }
  getAssociatedProviderById(id: any) {
    return this.ajaxService.get(`${CustomerAPIPath.customer}/${id}/associatedprovider`);
  }
  getCustomerSpecificRoleList(): any {
    return this.ajaxService.get(CommonListAPIPath.customerSpecificRole);
  }
  getCustomerSpecificProjectRoleList(): any {
    return this.ajaxService.get(CommonListAPIPath.customerSpecificProjectRole);
  }

  addProjectToCustomer(resource: any) {
    return this.ajaxService.post(`${CustomerUserAPIPath.customerProject}`, resource);
  }

  addMultipleCustomerToUser(customerUsers: any) {
    return this.ajaxService.post(CustomerUserAPIPath.addMultipleCustomerToUser, customerUsers);
  }

  getCustomerProjects(customerId: any, userId: any) {
    return this.ajaxService.get(`${CustomerAPIPath.customer}/${customerId}/user/${userId}/projects`);
  }

  addSitesToUser(resource: any) {
    return this.ajaxService.post(`${CustomerUserAPIPath.customerSite}`, resource);
  }
  getCustomerSites(customerId: any, userId: any) {
    return this.ajaxService.get(`${CustomerAPIPath.customer}/${customerId}/user/${userId}/sites`);
  }

  integrateCustomer(provider: any): any {
    return this.ajaxService.post(`${CustomerUserAPIPath.integratorAssociateCustomer}`, provider);
  }
  importFavToCustomer(fromCustomerId: any, toCustomerId: any) {
    return this.ajaxService.get(CustomerAPIPath.favImportToCustomer + `/${fromCustomerId}/to/customer/${toCustomerId}`);
  }
  globalDashboardTab(globalActiveTab: string) {
    return this.ajaxService.put(`${SerivceTicketAPIPath.triggerBy}dashboardTab=${globalActiveTab}`, {});
  }
  projectDashboadDataDesign(filter: any) {
    return this.ajaxService.post(`${ProjectAPIPath.dashboardProjects}`, filter);
  }
  projectDashboadDataDesignInstallHeader(filter: any) {
    return this.ajaxService.post(`${ProjectAPIPath.dashboardProjects}/header`, filter);
  }
  // getProjectChartData(projectId: any, days: any): any {
  //   return this.ajaxService.get(`${ProjectAPIPath.projectChartData}?projectId=${projectId}&days=${days}`);
  // }
  getdailyReport(userId: any) {

    const data = 'Bearer ' + this.commonService.getLocalStorageString(BrowserKeys.token);

    const httpOptions: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': data
      }),
      reportProgress: true,
      // responseType: 'text'
    };

    httpOptions['responseType'] = 'text'

    const req = new HttpRequest('GET', `${this.env.apiPath}${CustomerUserAPIPath.user}/${userId}/dailyreport`, httpOptions);

    return this.http.request(req);
  }
  domotzSave(data: any) {
    return this.ajaxService.put(`${CustomerAPIPath.domotzSave}`, data);
  }
  domotzTest(data: any) {
    return this.ajaxService.post(`${CustomerAPIPath.domotzTest}`, data);
  }
  setConfiguration(customerId: any, param: any, value: any): any {
    return this.ajaxService.get(`${CustomerAPIPath.customer}/${customerId}/configuration?${param}=${value}`);
  }
}
