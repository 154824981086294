/* eslint-disable */
import { api } from '@frontegg/rest-api';
export const type2ApiGet = {
  slack: api.connectivity.getSlackConfiguration,
  email: api.connectivity.getEmailConfiguration,
  sms: api.connectivity.getSMSConfiguration,
  webhook: api.connectivity.getWebhooksConfigurations,
  categories: api.connectivity.getCategories,
  channelMap: api.connectivity.getChannelMaps
};
export const type2ApiPost = {
  slack: api.connectivity.postSlackConfiguration,
  email: api.connectivity.postEmailConfiguration,
  sms: api.connectivity.postSMSConfiguration,
  webhook: api.connectivity.postWebhooksConfiguration
};
export const defaultRootPath = '/connectivity';
export const channels = ['email', 'slack', 'sms', 'webhook'];
export const channels2Platform = {
  sms: {
    title: 'connectivity.sms',
    events: data => (data == null ? void 0 : data.length) || 0,
    isActive: data => {
      var _some;
      return (_some = data == null ? void 0 : data.some(({
        enabled
      }) => enabled)) != null ? _some : false;
    },
    image: 'sms'
  },
  email: {
    title: 'common.email',
    events: data => (data == null ? void 0 : data.length) || 0,
    isActive: data => {
      var _some2;
      return (_some2 = data == null ? void 0 : data.some(({
        enabled
      }) => enabled)) != null ? _some2 : false;
    },
    image: 'email'
  },
  slack: {
    title: 'connectivity.slack',
    events: data => {
      var _slackSubscriptions;
      return (data == null ? void 0 : (_slackSubscriptions = data.slackSubscriptions) == null ? void 0 : _slackSubscriptions.length) || 0;
    },
    isActive: data => {
      var _slackSubscriptions$s;
      return (_slackSubscriptions$s = !!(data != null && data.slackSubscriptions.some(({
        isActive
      }) => isActive))) != null ? _slackSubscriptions$s : false;
    },
    image: 'slack'
  },
  webhook: {
    title: 'connectivity.webhook',
    events: data => (data == null ? void 0 : data.length) || 0,
    isActive: data => {
      var _some3;
      return (_some3 = data == null ? void 0 : data.some(({
        isActive
      }) => isActive)) != null ? _some3 : false;
    },
    image: 'webhook'
  }
};