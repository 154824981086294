import { EventService, CustomerService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'so-import-favorites',
  templateUrl: './import-favorites.component.html',
  styleUrls: ['./import-favorites.component.scss'],
})
export class ImportFavoritesComponent implements OnInit {
  providerName!: string;
  customersList!: any;
  customerId!: number;
  customerName!: string;
  favImportForm!: FormGroup;
  private backEvent: EventEmitter<any> = new EventEmitter();
  private closeAllModalEvent: EventEmitter<any> = new EventEmitter();

  constructor(public fb: FormBuilder,
              public eventService: EventService,
              public customerService: CustomerService) {}

  ngOnInit(): void {
    this.favImportForm = this.fb.group({
      customerId: 0,
    });
  }
  favImportToCustomer() {
    if (this.favImportForm.value.customerId !== 0) {
      this.customerService.importFavToCustomer(this.favImportForm.value.customerId, this.customerId).subscribe(res => {
        this.closeAllModalEvent.emit();
      });
    } else {
      this.closeAllModalEvent.emit();
    }
  }
  backClick() {
    this.backEvent.emit();
  }
  closeModal() {
    this.closeAllModalEvent.emit();
  }
}

