import { NavigationExtras } from "@angular/router";
import { List } from "./list";

export enum HeaderActionType {
    Route,
    Event
}

export enum HeaderTitleType {
    Text,
    Dropdown
}
export class popUpMenu {
    event?: string;
}

export class saveMenu {
    save!: string;
    cancle!: string;
}
export class DropdownMenu {
    value: any;
    list!: List[];
    event!: string;
}
export class EditableLabelMenu {
    value: any;
    event!: string;
}
export class DeleteButtonMenu {
    value?: any;
    name?: string;
    event?: string;
}
export class SubMenu {
    hideHeader?: boolean = true;
    hideActionButton?: boolean = false;
    hideBackButton?: boolean = false;
    showTicketControls?: boolean;
    backUrl?: string[];
    isEditProject?: boolean;
    showProjectControl?: boolean;
    backButtonName?: string;
    backButtonActionType?: HeaderActionType = HeaderActionType.Route;
    backUrlOptions?: NavigationExtras;

    title?: any;
    hideTitle?: boolean = false;
    titleType?: HeaderTitleType = HeaderTitleType.Text;
    showSaveButton?: boolean = false;
    saveButtonName?: string;
    cancelButtonName?: string;
    showCancelButton?: boolean = false;
    showDropdown?: boolean = false;
    showZoomDropdown?: boolean = false;
    showEditableLabel?: boolean = false;
    showEditButton?: boolean = false;
    isViewOnly?: boolean = false;
    showPopup?: boolean = false;
    showDeleteButton?: boolean = false;
    dropdown?: DropdownMenu;
    editableLabel?: EditableLabelMenu;
    deleteButton?: DeleteButtonMenu;
    popup?: popUpMenu;
    eventData?: any;
    actionUrl?: string[];
    showTicketSaveButton?: boolean = false;
    showTicketCancelButton?: boolean = false;
    actionType?: HeaderActionType = HeaderActionType.Route;
    actionButtonName?: string;
    deleteButtonName?: string;
    showFloorEditButton?: boolean = false;
    disablePointerEvent?: boolean = false;
    isFloorViewOnly?: boolean = false;
    floorPlanStatus?: string;

    showUserControl?: boolean;
    showProviderControl?: boolean;
    showCustomerControl?: boolean;

    constructor() {
        this.showUserControl = false;
        this.showProviderControl = false;
        this.showCustomerControl = false;
    }
}

export class Menu {
    title?: string;
    hideLeftMenu?: boolean = false;
    subMenu!: SubMenu;
    showProvider?: boolean = false;
}