import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const rolesState = {
  loading: false,
  roles: [],
  permissions: [],
  permissionCategories: []
};
const reducers = {
  setRolesState: typeReducerForKey('rolesState'),
  resetRolesState: resetStateByKey('rolesState', {
    rolesState
  })
};
const actions = {
  loadRolesAndPermissions: createAction(`${authStoreName}/loadRoles`, payload => ({
    payload
  })),
  addRole: createAction(`${authStoreName}/addRole`, payload => ({
    payload
  })),
  updateRole: createAction(`${authStoreName}/updateRole`, payload => ({
    payload
  })),
  deleteRole: createAction(`${authStoreName}/deleteRole`, payload => ({
    payload
  })),
  attachPermissionsToRole: createAction(`${authStoreName}/attachPermissionsToRole`, payload => ({
    payload
  })),
  attachPermissionToRoles: createAction(`${authStoreName}/attachPermissionToRoles`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { rolesState, reducers as rolesReducers, actions as rolesActions };