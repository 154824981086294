import { Customer, CustomerService, MessageService, UserService, EventService, ValidationService, Pattern, EventEmitterType, ApplicationNotification, AuthService } from '@SiteOwl/core';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'so-provider-create-edit',
  templateUrl: './provider-create-edit.component.html',
  styleUrls: ['./provider-create-edit.component.scss'],
})
export class ProviderCreateEditComponent implements OnInit {

  isEditMode = false;
  customerDetail!: Customer;
  customerForm!: FormGroup<any>;
  isFormSubmitted = false;
  saveBtnDisable = false;
  isSaveRemain = true;
  reference: any;
  tmpProviderId!: number;
  providerName = "";
  selectedAdminData: any = [];
  customers: any;
  public event: EventEmitter<any> = new EventEmitter();
  constructor(private customerService: CustomerService,
      private fb: FormBuilder, private messageService: MessageService, private userService: UserService,
      private authService: AuthService,public bsModalRef: BsModalRef) {  
  }

  private setFormValue() {
      this.customerForm.patchValue(this.customerDetail);
  }

  private createForm() {
      this.customerForm = this.fb.group({
          name: [null, [Validators.required, Validators.maxLength(80), ValidationService.onlyWhiteSpaceValidator]],
          email: [null, [Validators.pattern(Pattern.EMAIL)]],
          phoneNumber: [null, []]
      });
  }

  private addCustomer(exit?: boolean) {
      const customer = this.customerForm.value;
      if (this.authService.getUserKeyData().user.providerId > 0) {
          customer.responsibleIntegratorId = this.authService.getUserKeyData().user.providerId;
      }

      customer.isLiveSystemAccessForCustomer = false;

      this.customerService.addCustomer(customer).subscribe({
            next: (result: any) => {
                if (result.status === "error") {
                    this.saveBtnDisable = false;
                    this.messageService.errorMessage(`Error`, `Account: Account ${result.name === '' || result.name === null ? result.email : result.name} already exists in your Headquarters account.`);

                } else {
                    this.customerDetail = result;
                    this.reference = Object.assign({}, result);
                    let providerId = this.authService.getUserKeyData().user.providerId;
                    if (providerId === null || providerId === undefined) {
                        providerId = this.tmpProviderId;
                    }
                    if (providerId && result.id) {
                        this.tmpProviderId = providerId;
                        const inviteProviderObj: any = {
                            amtRole: {
                                id: 5,
                                name: "Manager",
                                description: "Create, edit, and remove Plans in the Plan Library|Create, edit, and remove Live Sites Devices|Create, edit, assign and remove Tickets",
                                roleKey: "amt_Manager"
                            },
                            projectRole: {
                                id: 5,
                                name: "Manager",
                                description: "create, delete, and fully manage projects",
                                roleKey: "project_Manager"
                            },
                            isAdmin: true,
                            providerId: providerId,
                            customerId: result.id,
                            status: "accept"
                        }
                        this.customerService.integrateCustomer(inviteProviderObj).subscribe({
                            next: (r: any) => {
                                this.saveBtnDisable = false;
                                this.isSaveRemain = false;
                                if (exit) {
                                    this.event.emit(true);
                                } else {
                                    this.openAssociatedAdmins(result);
                                }
                                this.messageService.successMessage(`Account: ${result.name === '' || result.name === null ? result.email : result.name}`, 'Saved Successfully');
                            }, error: () => {
                                this.saveBtnDisable = false;
                            }
                        })

                    }
                }
            },error: (error: any) => {
                this.saveBtnDisable = false;
                if (error.status === 422) {
                    this.messageService.errorMessage('Account', error.error.message);
                }else{
                    this.messageService.errorMessage(`Error`, 'Error while saving');
                }
            }  
          });
  }


  private updateCustomer(exit?: boolean) {
      const customer: Customer = this.customerForm.value;
      customer.id = this.customerDetail.id;
      customer.isActive = this.customerDetail.isActive;

      this.customerService.updateCustomer(customer).subscribe({
          next: (result: any) => {
              if (result.status === "error") {
                  this.saveBtnDisable = false;
                  this.messageService.errorMessage(`Error`, `Account: ${result.name === '' || result.name === null ? result.email : result.name} already exsits.`);

              } else {
                  this.reference = Object.assign({}, result);
                  this.saveBtnDisable = false;
                  this.isSaveRemain = false;
                  this.messageService.successMessage(`Account: ${result.name === '' || result.name === null ? result.email : result.name}`, 'Saved Successfully');
                  if (exit) {
                      this.bsModalRef.hide();
                  } else {
                      this.openAssociatedAdmins(result);
                  }
              }
          },error: (error: any) => {
              this.saveBtnDisable = false;
              if (error.status === 422) {
                  this.messageService.errorMessage('Account', error.error.message);
              } else {
                  this.messageService.errorMessage(`Error`, 'Error while saving');
              }
          }    
        });
  }

  ngOnInit(): void {
      this.createForm();
      if (this.isEditMode) {
          this.setFormValue();
      }
  }

  saveCustomer(isFromClose?: boolean) {
      if (this.customerForm.invalid) {
          this.isFormSubmitted = true;
          return;
      }
      this.saveBtnDisable = true;

      if (this.customerDetail.id && this.customerDetail.id > 0) {
          if (this.checkValues()) {
              this.updateCustomer(isFromClose);
          } else {
              this.openAssociatedAdmins(this.customerDetail);
          }
      } else {
          this.addCustomer(isFromClose);
      }

  }

  closeModal() {
      if (this.customerForm.dirty && this.checkValues() && this.isSaveRemain) {
          if (window.confirm(ApplicationNotification.pageReload)) {
              this.saveCustomer(true);
          } else {
              this.bsModalRef.hide();
          }
      } else {
          this.bsModalRef.hide();
      }
  }

  openAssociatedAdmins(customer?: any) {
      this.isSaveRemain = false;
      this.saveBtnDisable = false;
      let providerUsers: any = []
      this.userService.getProviderUserById(this.tmpProviderId).subscribe((r: any) => {
          providerUsers = r;
          this.selectedAdminData.forEach((selectedUser: any) => {
              providerUsers.forEach((element: any, i: any) => {
                  if (selectedUser.user.id === element.user.id) {
                      providerUsers.splice(i, 1);
                  }
              });
          });
          const modalData: any = {
              customerId: customer.id,
              customerName: customer.name,
              providerId: this.tmpProviderId,
              providerName: this.providerName,
              selectedData: this.selectedAdminData,
              filteredUsers: providerUsers,
              customers: this.customers
          };
          this.event.emit(modalData);
      });
  }

  checkValues() {
      const formValue = this.customerForm.value;
      if (!this.reference) {
          return true;
      }
      if (this.reference.name != formValue.name) {
          return true;
      } else if (this.reference.email != formValue.email) {
          return true;
      } else if (this.reference.phoneNumber != formValue.phoneNumber) {
          return true;
      } else {
          return false;
      }

  }
}

