<ng-container *ngIf="!pageLoader">
    <div class="modal-header">
        <h1 class="modal-title pull-left"
            *ngIf="!(projects.length === 0 && selectedCustomerId && projectAvailableForCustomer && !isAllManager && !noCuatomers) && !(!projectAvailableForCustomer && selectedCustomerId && !isAllManager && !noCuatomers)">
            Add User to Project(s)</h1>
        <h1 class="modal-title pull-left"
            *ngIf="(projects.length === 0 && selectedCustomerId && projectAvailableForCustomer && !isAllManager && !noCuatomers) || (!projectAvailableForCustomer && !isAllManager && selectedCustomerId && !noCuatomers)">
            All Projects Already Accessible</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close" type="button">
            <em class="fas fa-close"></em>
        </button>
    </div>
    <div class="modal-body project-filter"
        *ngIf="!isAllManager && !noCuatomers && canShow && ((selectedCustomerId && projects.length > 0) || !selectedCustomerId);else NoAddedCustomer">
        <p *ngIf="!selectedCustomerId">Select a Account and the Project(s) that you would like to grant to
            <strong>{{userDetails?.name || userDetails?.email}}.</strong>
        </p>
        <form [formGroup]="resourceForm" novalidate>
            <div class="form-group" *ngIf="!selectedCustomerId">
                <label>Associated Accounts
                    <sup>*</sup>
                </label>
                <div class="row">
                    <div class="col-sm-6">
                        <select (change)="customerSelected()" formControlName="customer" class="form-control mb-0">
                            <option [ngValue]="null">Select Account</option>
                            <option *ngFor="let customer of customers" [ngValue]="customer">{{customer?.customer?.name}}
                            </option>
                        </select>

                        <!-- <so-dropdown [id]="'select'" [options]="customers" [value]="'customerName'" [key]="'customer'" 
                            [inputControlName]="$any(resourceForm).controls['customer']" (onChange)="customerSelected()">
                        </so-dropdown> -->
                        <so-control-messages [name]="'Account'" [isFormSubmitted]="isFormSubmitted"
                            [control]="$any(resourceForm).controls.customer"></so-control-messages>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>Project(s)
                    <sup>*</sup>
                </label>
                <div class="card projects-card">
                    <p class="label-message" *ngIf="resourceForm.get('customer')?.value === null">
                        Please select a Account to view available Projects. </p>
                    <p class="label-message"
                        *ngIf="resourceForm?.get('customer')?.value !== null && !projectAvailableForCustomer">
                        No Projects available. </p>
                    <div class="download-option-list"
                        *ngIf="projectAvailableForCustomer && resourceForm.controls['customer'].valid && projects && projects.length > 0 && !projectLoad ; else noProjects">
                        <div class="checkbox-label">
                            <so-checkbox [isChecked]="selectedAllProjects" (onChange)="selectAllProjects()"
                                [label]="'Select All ( ' + (selectedAllProjectsCount || 0) + ' of ' + totalProjects + (totalProjects > 1 ? '': ' Project ') + ' selected )'"
                                [id]="'selectedAllProjects'" class="'lbl-check'" [parentClassName]="(selectedAllProjectsCount > 0 && !selectedAllProjects) ? 'uncheckall':''">
                            </so-checkbox>
                            
                        </div>

                    </div>
                    <div class="project-filter-body">
                        <accordion *ngIf="resourceForm.controls['customer'].valid && projects && projects.length > 0">
                            <accordion-group accordion-heading class="accordin" [isOpen]="true"
                                *ngIf="totalDesignProjectCount > 0">
                                <div accordion-heading class="clearfix slide-head-panel">
                                    <div class="checkbox-label">
                                        <so-checkbox [isChecked]="isSelectedAllDesignProjects"
                                            (onChange)="selectAllDesignProjects()"
                                            [label]="'Design Projects ( ' + (selectedDesignProjectCount || 0) + ' of ' + (totalDesignProjectCount || 0) + ' Design ' + (totalDesignProjectCount > 1 ? ' Projects ': ' Project ') + 'selected )'"
                                            [id]="'design_projects'" class="'lbl-check'" [parentClassName]="(selectedDesignProjectCount > 0 && !isSelectedAllDesignProjects) ? 'uncheckall':''">
                                        </so-checkbox>
                                    </div>
                                </div>
                                <div *ngFor="let project of projects" class="expand-body clearfix">
                                    <div *ngIf="project.stage === projectDesign" class="expand-inner clearfix">
                                        <div class="project-exp">
                                            <div class="checkbox-label">
                                                <so-checkbox [isChecked]="project.isSelectedProject"
                                                    [label]="project.name"
                                                    (onChange)="selectDesignProjects($event, project)"
                                                    [id]="'project_'+ project.id" [ngClass]="'lbl-check'">
                                                </so-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                            <accordion-group accordion-heading class="accordin" [isOpen]="totalDesignProjectCount === 0"
                                *ngIf="totalInstallProjectCount > 0">
                                <div accordion-heading class="clearfix slide-head-panel">
                                    <div class="checkbox-label">
                                        <so-checkbox [isChecked]="isSelectedAllInstallProjects"
                                            (onChange)="selectAllInstallProjects()" [id]="'install_projects'"
                                            class="'lbl-check'" [parentClassName]="(selectedInstallProjectCount > 0 && !isSelectedAllInstallProjects) ? 'uncheckall':''"
                                            [label]="'Installation Projects ( ' + (selectedInstallProjectCount || 0) + ' of ' + (totalInstallProjectCount || 0) + ' Installation ' + (totalInstallProjectCount > 1 ? ' Projects ':' Project ') + 'selected )' ">
                                        </so-checkbox>
                                    </div>
                                </div>
                                <div *ngFor="let project of projects" class="expand-body clearfix">
                                    <div class="expand-inner clearfix" *ngIf="project?.stage === projectInstallation">
                                        <div class="project-exp">
                                            <div class="checkbox-label">
                                                <so-checkbox [isChecked]="project.isSelectedProject"
                                                    [label]="project.name"
                                                    (onChange)="selectInstallProjects($event, project)"
                                                    [id]="'project_'+ project.id" [ngClass]="'lbl-check'">
                                                </so-checkbox>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <ng-template #noProjects>
                        <p
                            *ngIf="projectAvailableForCustomer && projects &&  projects.length === 0 && !selectedCustomerId && $any(resourceForm).get('customer').value !== null">
                            <strong> {{userDetails?.name || userDetails?.email}} </strong> already has access to all
                            possible Projects on <strong>{{resourceForm.controls['customer'].value.customer?.name}}
                            </strong>.
                        </p>
                    </ng-template>
                </div>
            </div>
        </form>


    </div>


    <ng-template #NoAddedCustomer>
        <p class="m-4" *ngIf="isAllManager">
            <strong>
                {{userDetails?.name === '' || userDetails?.name === null ? "(" + userDetails?.email +")" :
                userDetails?.name}}
            </strong> has the Project role Manager for all Accounts.
        </p>
        <p class="m-4" *ngIf="noCuatomers">
            No Accounts available. Only Accounts where <strong> {{user?.providerName}} </strong> has the Project role
            Manager and where <strong>
                {{userDetails?.name === '' || userDetails?.name === null ? "(" + userDetails?.email +")" :
                userDetails?.name}}</strong> has been associated will appear here.
        </p>
        <p class="m-4"
            *ngIf="projects.length === 0 && selectedCustomerId && projectAvailableForCustomer && !isAllManager && !noCuatomers">
            <strong> {{userDetails?.name || userDetails?.email}} </strong> already has access to all possible Projects
            on <strong>{{resourceForm.controls['customer'].value.customer.name}}
            </strong>.
        </p>
        <p class="m-4" *ngIf="!projectAvailableForCustomer && !isAllManager && !noCuatomers">
            <strong> {{userDetails?.name || userDetails?.email}} </strong> already has access to all possible Projects
            on <strong>{{customerSelectedName}}
            </strong>.
        </p>
    </ng-template>
    <div class="modal-footer">
        <so-button (onClick)="closeModal()" [className]="'btn btn-secondary'"
            [text]="((!isAllManager && !noCuatomers && canShow && ((selectedCustomerId && projects.length > 0) || !selectedCustomerId)) ? 'Cancel' : 'Close')">
        </so-button>
        <so-button
            *ngIf="!isAllManager && !noCuatomers && canShow && ((selectedCustomerId && projects.length > 0) || !selectedCustomerId)"
            [disabled]="btnService.getStatus() || isAllManager || noCuatomers || resourceForm.invalid || selectedAllProjectsCount < 1"
            (onClick)="addUserToProject()" [className]="'btn btn-primary m-0'"
            [style]="{'cursor':btnService.getStatus() || isAllManager || noCuatomers || resourceForm.invalid || selectedAllProjectsCount < 1 ? 'not-allowed'  : 'pointer'}"
            [text]="'Add Project(s)'">
        </so-button>
    </div>
</ng-container>