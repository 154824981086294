import _extends from "@babel/runtime/helpers/esm/extends";
import { createAction, createSlice } from '@reduxjs/toolkit';
import { subscriptionsStoreName } from '../../../constants';
import { createKeyCaseErrorReducer, createKeyCaseLoadingReducer, createModuleCaseReducers } from '../../utils';
export const initialSubscriptionState = {
  loading: false,
  error: null,
  fetching: true,
  cancellation: {
    loading: false,
    error: null
  },
  renewal: {
    loading: false,
    error: null
  }
};
const reducers = _extends({}, createModuleCaseReducers(), {
  setCancellationLoading: createKeyCaseLoadingReducer('cancellation'),
  setCancellationError: createKeyCaseErrorReducer('cancellation'),
  setRenewalLoading: createKeyCaseLoadingReducer('renewal'),
  setRenewalError: createKeyCaseErrorReducer('renewal')
});
const {
  reducer,
  actions: overviewActions,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/billing/subscription`,
  initialState: initialSubscriptionState,
  reducers
});
const actions = _extends({
  load: createAction(`${name}/loadSubscription`),
  cancelSubscription: createAction(`${name}/cancelSubscription`),
  renewSubscription: createAction(`${name}/renewSubscription`)
}, overviewActions);
export { reducer as subscriptionReducer, actions as subscriptionActions };