"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getIsEntitledEvaluators = void 0;
const direct_entitlement_evaluator_1 = require("./direct-entitlement.evaluator");
const feature_flag_evaluator_1 = require("./feature-flag.evaluator");
const plan_targeting_rules_evaluator_1 = require("./plan-targeting-rules.evaluator");
function getIsEntitledEvaluators() {
  return [direct_entitlement_evaluator_1.directEntitlementEvalutor, feature_flag_evaluator_1.featureFlagEvaluator, plan_targeting_rules_evaluator_1.planTargetingRulesEvalutor];
}
exports.getIsEntitledEvaluators = getIsEntitledEvaluators;
