import { createAction } from '@reduxjs/toolkit';
import { resetStateByKey, typeReducerForKey } from '../utils';
import { authStoreName } from '../../constants';
const stepUpState = {
  loading: false,
  mfaDevices: undefined,
  mfaToken: ''
};
const reducers = {
  setStepUpState: typeReducerForKey('stepUpState'),
  resetStepUpState: resetStateByKey('stepUpState', {
    stepUpState
  })
};
const actions = {
  stepUpHostedLogin: createAction(`${authStoreName}/stepUpHostedLogin`, payload => ({
    payload
  })),
  generateStepUpSession: createAction(`${authStoreName}/generateStepUpSession`, payload => ({
    payload
  })),
  stepUpWithAuthenticator: createAction(`${authStoreName}/stepUpWithAuthenticator`, payload => ({
    payload
  })),
  preVerifyMFASMSForStepUp: createAction(`${authStoreName}/preVerifyMFASMSForStepUp`, payload => ({
    payload
  })),
  verifyMFASMSForStepUp: createAction(`${authStoreName}/verifyMFASMSForStepUp`, payload => ({
    payload
  })),
  preVerifyMFAWebAuthnForStepUp: createAction(`${authStoreName}/preVerifyMFAWebAuthnForStepUp`, payload => ({
    payload
  })),
  verifyMFAWebAuthnForStepUp: createAction(`${authStoreName}/verifyMFAWebAuthnForStepUp`, payload => ({
    payload
  })),
  preVerifyMFAEmailCodeForStepUp: createAction(`${authStoreName}/preVerifyMFAEmailCodeForStepUp`, payload => ({
    payload
  })),
  verifyMFAEmailCodeForStepUp: createAction(`${authStoreName}/verifyMFAEmailCodeForStepUp`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { stepUpState, reducers as stepUpReducers, actions as stepUpActions };
export * from './utils';
export { STEP_UP_MAX_AGE_PARAM_NAME, SHOULD_STEP_UP_KEY } from './consts';