import { AsyncPipe } from '@angular/common';
import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { Customer, Utilities } from '@SiteOwl/core';

@Pipe({
  name: 'customerFilter',
  pure: false
})
export class CustomerFilterPipe implements PipeTransform {

  transform(items: Customer[], filter: string, isFromHeader: boolean = false, isFromRecent: boolean = false) {
    if (Utilities.isNull(items) || Utilities.isNull(filter)) {
      return items || [];
    }
    if (isFromHeader && !Utilities.isEmpty(filter)) {
      const newfilter = filter.split(' ').map(v => v.toLowerCase());

      const newCustomers: any = [];

      items.forEach(element => {
        if (!Utilities.isEmpty(element.name) && !Utilities.isNull(element.name)) {
          const str3 = element.name.toLowerCase();
          if (newfilter.reduce((a: any, c: any) => a + str3.includes(c), 0) == newfilter.length) {
            if (newCustomers.filter((y: any) => y.id === element.id).length === 0) {
              newCustomers.push(element)
            }
          }
        }
      });
      if (isFromRecent) {
        return newCustomers;
      } else {
        const noData = [{ name: 'No matching accounts found', hideButton: true }]
        return newCustomers.length === 0 ? noData : newCustomers;
      }
    } else if (!isFromHeader && !Utilities.isEmpty(filter)) {
      items = items.filter(x => x.name.toLowerCase().indexOf(filter.toLowerCase()) > -1 || x.email.toLowerCase().indexOf(filter.toLowerCase()) > -1);
      const noData = [{ name: 'No accounts found', hideButton: true }]
      return items.length === 0 ? noData : items;
    }
  }
}
