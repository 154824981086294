/* eslint-disable @typescript-eslint/no-explicit-any */
import { AjaxService, UIAPIPath } from '@SiteOwl/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  constructor(private ajaxHttp: AjaxService) { }

  getBuildingChangeData(id: any, isFromSite: any): any {
    if (isFromSite) {
      return this.ajaxHttp.get(UIAPIPath.buildings + '?siteId=' + id);
    }
  }

  getUser(): any {
    return this.ajaxHttp.get(`${UIAPIPath.user}/profile`);
  }
}
