import { Get } from "../fetch";
import { urls } from "../constants";
export async function getSubscriptionInvoices() {
  return Get(urls.subscriptions.billing.invoices.v1);
}
export async function getSubscriptionInvoice(invoiceId) {
  return Get(`${urls.subscriptions.billing.invoices.v1}/${invoiceId}`);
}
export async function getSubscriptionInvoicePdf(invoiceId, outputFileName) {
  return Get(`${urls.subscriptions.billing.invoices.v1}/${invoiceId}/pdf`, {
    outputFileName
  }, {
    responseType: "blob"
  });
}