import { createAction } from '@reduxjs/toolkit';
import { typeReducerForKey, typeReducerNestedKey } from '../../utils';
import { authStoreName } from '../../../constants';
import { RestrictionType } from '@frontegg/rest-api';
const restrictionsState = {
  ipRestrictions: {
    loading: false,
    pagination: {
      _offset: 0,
      _limit: 4,
      _filter: RestrictionType.ALLOW
    },
    totalPages: 1,
    userIpValid: false
  },
  emailDomainRestrictions: {
    loading: true
  }
};
const reducers = {
  setRestrictionsState: typeReducerForKey('restrictionsState'),
  setIpRestrictionsState: typeReducerNestedKey('restrictionsState', 'ipRestrictions'),
  setEmailDomainRestrictionsState: typeReducerNestedKey('restrictionsState', 'emailDomainRestrictions')
};
const actions = {
  checkIfUserIpValid: createAction(`${authStoreName}/checkIfUserIpValid`),
  addCurrentUserIpAndActivate: createAction(`${authStoreName}/addCurrentUserIpAndActivate`, payload => ({
    payload
  })),
  loadIpRestrictions: createAction(`${authStoreName}/loadIpRestrictions`),
  saveIpRestriction: createAction(`${authStoreName}/saveIpRestriction`, payload => ({
    payload
  })),
  saveIpRestrictionsConfig: createAction(`${authStoreName}/saveIpRestrictionsConfig`, payload => ({
    payload
  })),
  deleteIpRestriction: createAction(`${authStoreName}/deleteIpRestriction`, payload => ({
    payload
  })),
  loadEmailDomainRestrictions: createAction(`${authStoreName}/loadEmailDomainRestrictions`),
  saveEmailDomainRestriction: createAction(`${authStoreName}/saveEmailDomainRestriction`, payload => ({
    payload
  })),
  saveEmailDomainRestrictionsConfig: createAction(`${authStoreName}/saveEmailDomainRestrictionsConfig`, payload => ({
    payload
  })),
  deleteEmailDomainRestriction: createAction(`${authStoreName}/deleteEmailDomainRestriction`, payload => ({
    payload
  }))
};

/**
 *  To be used for actions types after dispatch, and should contains
 *  the reducers and actions as standalone function
 */

// noinspection JSUnusedLocalSymbols
/**
 *  if you see error in matcher that's mean the DispatchAction does not
 *  contains the same functions in reducers and actions
 */
const Matcher = {};
export { restrictionsState, reducers as restrictionsReducers, actions as restrictionsActions };