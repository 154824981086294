import _extends from "@babel/runtime/helpers/esm/extends";
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { checkoutActions } from './index';
import { api, ISubscriptionStatus } from '@frontegg/rest-api';
import { subscriptionActions } from '../Billing/Subscription';
import { loadBillingInformation } from '../Billing/Information/saga';
import { loadPaymentMethod } from '../Billing/PaymentMethod/saga';
import { loadInvoices } from '../Billing/Invoices/saga';
import { errorHandler } from '../../utils';
export function* checkoutSagas() {
  yield takeEvery(checkoutActions.loadCheckout, loadCheckout);
  yield takeEvery(checkoutActions.resetCheckout, resetCheckout);
  yield takeEvery(checkoutActions.submitCheckout, submitCheckout);
  yield takeEvery(checkoutActions.confirmCheckout, confirmPlan);
  yield takeEvery(checkoutActions.errorCheckout, errorCheckout);
}
function* loadCheckout() {
  yield put(checkoutActions.setState({
    fetching: false,
    loading: false,
    error: null,
    confirmed: false
  }));
}
function* resetCheckout() {
  yield put(checkoutActions.setState({
    loading: false,
    error: null,
    confirmed: false
  }));
}

/**
 * Based on payment provider type
 */
function* submitCheckout() {
  yield put(checkoutActions.setState({
    loading: true,
    error: null
  }));
}
function* confirmPlan({
  payload: {
    paymentMethodId,
    planId
  }
}) {
  const subscription = yield select(state => state.subscriptions.billing.subscription.subscription);
  const summary = yield select(state => state.subscriptions.billing.information.summary);
  const isTrialing = (subscription == null ? void 0 : subscription.status) === ISubscriptionStatus.TRIALING;
  const hasPaymentMethod = !!(summary != null && summary.paymentMethodId);
  if (!subscription) {
    yield put(checkoutActions.setState({
      loading: false,
      error: 'Subscription not found'
    }));
    return;
  }
  yield put(checkoutActions.setState({
    loading: true,
    error: null
  }));
  if (isTrialing && hasPaymentMethod) {
    yield confirmCheckout();
    yield put(subscriptionActions.setState({
      subscription: _extends({}, subscription, {
        status: ISubscriptionStatus.ACTIVE
      })
    }));
    return;
  }
  try {
    yield call(api.subscriptions.updateManagedSubscription, subscription.id, {
      paymentMethodId,
      planId
    });
    yield all([loadBillingInformation(), loadPaymentMethod(), loadInvoices()]);
    yield confirmCheckout();
  } catch (e) {
    yield put(checkoutActions.setState({
      loading: false,
      error: errorHandler(e)
    }));
  }
}
function* confirmCheckout() {
  yield put(checkoutActions.setState({
    loading: false,
    error: null,
    confirmed: true
  }));
}
function* errorCheckout({
  payload
}) {
  yield put(checkoutActions.setState({
    loading: false,
    error: payload
  }));
}

/*********************************
 *  Preview Sagas
 *********************************/

export function* checkoutSagasMock() {
  yield takeEvery(checkoutActions.resetCheckout, resetCheckout);
}