<form [formGroup]="addBuildingForm" class="" (ngSubmit)="startEditingBuilding()" novalidate>

    <div class="modal-header">
        <h1> {{site.name}} <span class="seprator">|</span> Buildings
        </h1>
        <button data-dismiss="modal" (click)="showconfirmation(true)" aria-label="Close" class="close pull-right"
            type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-11">
                <p>Enter in the name and address of the Buildings that are within your Site
                    <strong>{{site.name}}</strong>.
                </p>
            </div>
        </div>

        <a *ngIf="canExpanBuilding('view')" (click)="showHideBuilding(true)" >{{message}}</a>
        <a *ngIf="canExpanBuilding('hide')" (click)="showHideBuilding(false)" >{{message}}</a>

        <div *ngIf="isExistingDeviceShow">
            <div *ngFor="let building of site.buildings; let i = index">
                <div class="row">
                    <div class="col-sm-3">
                        <div class="form-group">
                            <label>Building Name </label>
                            <p>{{building.name}}</p>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div *ngIf="(building.buildingAddress || building.address)" class="form-group">
                            <label>Building Address</label>
                            <p>{{building.buildingAddress || building.address}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div formArrayName="buildings"
            *ngFor="let building of ($any(addBuildingForm).get('buildings').controls) as any; let i = index">
            <div [formGroupName]="i">
                <div class="row">
                    <div class="col-sm-3">

                        <div class="form-group">
                            <label>Building Name <sup>*</sup> </label>
                            <input class="form-control" removeWhiteSpace placeholder="Building Name"
                                formControlName="name" maxlength="80">


                            <so-control-messages [name]="'Name'" [isFormSubmitted]="isFormSubmitted"
                                [control]="$any(building).controls['name']">
                            </so-control-messages>
                        </div>
                    </div>
                    <div class="col-sm-5">
                        <div class="form-group">

                            <label>Building Address</label>
                            <input class="form-control" placeholder="Building Address" formControlName="address">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label> &nbsp; </label>
                            <p *ngIf="$any(addBuildingForm).get('buildings').controls.length > 1"><a class="red-link"
                                    (click)="deleteBuilding(i)">Delete  Building</a></p>
                        </div>
                    </div>


                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <a (click)="addBuilding()"> +Add Building</a>
            </div>
        </div>

    </div>


    <div class="modal-footer mrg-t-40 ps-0">
        <div class="row p-0" style="width: 100%">

            <div class="col-sm-6 p-0">

                <div class="progress-container">
                    <ul class="progressbar">
                        <li class="active">Site Information</li>
                        <li class="active">Buildings</li>
                        <li>Plans</li>

                    </ul>
                </div>
            </div>

            <div class="col-sm-6 d-flex align-items-center justify-content-end">
                <button type="button" (click)="showconfirmation(false)" class="btn btn-secondary">Back</button>
                <button type="submit" [ngStyle]="{cursor:btnService.getStatus() ? 'not-allowed':'pointer'}"
                    [disabled]="btnService.getStatus()" class="btn btn-primary m-0">Save and Continue</button>
            </div>

        </div>


    </div>

</form>