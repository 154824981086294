import { call, put, takeEvery } from 'redux-saga/effects';
import { stripeActions } from './index';
import { api } from '@frontegg/rest-api';
import { errorHandler } from '../../utils';
export function* subscriptionStripeSagas() {
  yield takeEvery(stripeActions.createCardSetupIntentSecret, createCardSetupIntentSecret);
}
function* createCardSetupIntentSecret({
  payload
}) {
  yield put(stripeActions.setState({
    loading: true,
    error: null,
    cardSetupIntentSecret: null
  }));
  try {
    const request = {};
    if (payload) {
      request.paymentMethodId = payload;
    }
    const {
      setupIntentSecret
    } = yield call(api.subscriptions.createStripePaymentMethodSetupIntentSecret, request);
    yield put(stripeActions.setState({
      cardSetupIntentSecret: setupIntentSecret,
      loading: false
    }));
  } catch (e) {
    yield put(stripeActions.setError(errorHandler(e)));
  }
}