export let GroupRelations;
(function (GroupRelations) {
  GroupRelations["roles"] = "roles";
  GroupRelations["users"] = "users";
  GroupRelations["rolesAndUsers"] = "rolesAndUsers";
})(GroupRelations || (GroupRelations = {}));
export let GroupManagedByEnum;
(function (GroupManagedByEnum) {
  GroupManagedByEnum["FRONTEGG"] = "frontegg";
  GroupManagedByEnum["SCIM2"] = "scim2";
})(GroupManagedByEnum || (GroupManagedByEnum = {}));