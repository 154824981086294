import _extends from "@babel/runtime/helpers/esm/extends";
import { createModuleCaseReducers } from '../../utils';
import { subscriptionsStoreName } from '../../../constants';
import { createAction, createSlice } from '@reduxjs/toolkit';
export const initialPaymentMethodState = {
  loading: false,
  error: null,
  fetching: true
};
const reducers = _extends({}, createModuleCaseReducers(), {
  setState: {
    prepare: payload => ({
      payload
    }),
    reducer: (state, action) => _extends({}, state, action.payload)
  }
});
const {
  reducer,
  actions: paymentActions,
  name
} = createSlice({
  name: `${subscriptionsStoreName}/billing/payment`,
  initialState: initialPaymentMethodState,
  reducers
});
const actions = _extends({
  loadPaymentMethod: createAction(`${name}/loadPaymentMethod`),
  submitPaymentMethod: createAction(`${name}/submitPaymentMethod`),
  submitPaymentMethodError: createAction(`${name}/submitPaymentMethodError`),
  submitPaymentMethodSuccess: createAction(`${name}/submitPaymentMethodSuccess`),
  updatePaymentMethodBillingDetails: createAction(`${name}/updateBillingDetails`, payload => ({
    payload
  }))
}, paymentActions);
//TODO: refactor to general code-style like export type ConnectivityActions = DispatchedActions;
export { reducer as subscriptionsPaymentMethodReducer, actions as subscriptionsPaymentMethodActions };