/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApplicationNotification, ButtonEnableDisableService, SiteService, ValidationService } from '@SiteOwl/core';
import { Component, EventEmitter, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CreatePlanComponent } from '../create-plan/create-plan.component';

@Component({
  selector: 'so-create-building',
  templateUrl: './create-building.component.html',
  styleUrls: ['./create-building.component.scss'],
})
export class CreateBuildingComponent implements OnInit, OnDestroy {
  sites!: any;
  siteId!: any;
  buildingsOrderNo!: any;
  buildingObj!: any;
  isEdit!: boolean;
  addBuildingForm!: FormGroup;
  siteBuildings!: any;
  canGoNext = true;
  isFormSubmitted!: boolean;
  selectedSite!: any;
  message = `Click to View Existing Buildings`;
  buildings!: FormArray;
  site: any;
  oldBuildingCount!: number;
  isFromCreateSite!: boolean;
  isExistingDeviceShow = false;
  orderNumber!: any;
  isCreateSiteFromProject!: any;
  newBuildings!: any;
  newFloors!: any;
  PreviousModalDTO: any = {
    closeAllModal: false,
    newFloors: [],
    buildings: [],
    site: {},
    buildingId: 0
  };
  bsModalRefPlan!: BsModalRef;
  isCreateDirectBuilding = false;
  public backToSite: EventEmitter<any> = new EventEmitter();

  public closeAll: EventEmitter<any> = new EventEmitter();

  @HostListener('window:beforeunload', ['$event'])
  doSomething($event: any) {
    if ((this.addBuildingForm.dirty)) {
      $event.returnValue = false;
    }
  }
  constructor(public bsModalRefBuilding: BsModalRef, private modalService: BsModalService, private siteService: SiteService, public btnService: ButtonEnableDisableService, private fb: FormBuilder,) {

  }
  createForm() {
    this.addBuildingForm = this.fb.group({
      buildings: this.fb.array([
        this.addeBuildingArr()
      ]),
    });
  }

  addeBuildingArr(isNew?: boolean) {

    if (this.buildingsOrderNo !== undefined) {
      this.orderNumber = this.buildingsOrderNo
    }
    if (isNew) {
      this.orderNumber = this.orderNumber + 1;
    } else if (this.orderNumber === undefined || this.orderNumber === null) {
      this.orderNumber = 1;
    }
    return this.fb.group({
      name: ['', [Validators.required,ValidationService.onlyWhiteSpaceValidator]],
      address: '',
      orderNumber: this.orderNumber,
    })
  }
  addBuilding() {
    this.buildings = this.addBuildingForm.get('buildings') as FormArray;
    this.buildings.push(this.addeBuildingArr(true));
  }
  canExpanBuilding(state: any) {
    if (state === 'view') {
      return !this.isExistingDeviceShow && this.site.buildings.length > 0;
    } else if (state === 'hide') {
      return this.isExistingDeviceShow && this.site.buildings.length > 0;
    }
    return false;
  }

  showHideBuilding(view: any) {
    if (view) {
      this.message = `Click to Hide Existing Buildings`
      this.isExistingDeviceShow = true;
    } else {
      this.message = `Click to View Existing Buildings`

      this.isExistingDeviceShow = false;
    }
  }
  startEditingBuilding() {
    this.isFormSubmitted = true;
    this.buildings = this.addBuildingForm.get('buildings') as FormArray;
    if (!this.buildingObj) {
      this.addBuildingCall();
    } else {
      if (this.addBuildingForm.touched) {
          this.addBuildingCall();
      } else {
        this.openPlan(this.buildingObj);
      }
  }
  }

  openPlan(tempBuildings: any) {
    const initialState: ModalOptions = {
      initialState: tempBuildings, ignoreBackdropClick: true, class: 'newBuildingM'
    };
    this.bsModalRefPlan = this.modalService.show(CreatePlanComponent, initialState);
    this.bsModalRefBuilding?.setClass('newBuildingM d-none');
    this.bsModalRefPlan.content.backToSite.subscribe((r: any) => {
      this.bsModalRefBuilding.setClass('newBuildingM');
      this.isFormSubmitted = false;
      this.bsModalRefPlan?.hide();
    })
    this.bsModalRefPlan.content.closeAll.subscribe((r: any) => {
      this.bsModalRefPlan?.hide();
      this.bsModalRefBuilding?.hide();
    })
    this.bsModalRefPlan.content.closeAllModal.subscribe((r: any) => {
      this.bsModalRefPlan?.hide();
      this.bsModalRefBuilding?.hide();  
      this.closeAll.emit();
    })
  }
  addBuildingCall() {
    if (this.addBuildingForm.invalid) {
      return;
    }
    this.btnService.disable();
    this.siteService.addBuildings(this.buildings.value, this.siteId).subscribe({
      next: (res: any) => {
        this.btnService.enable();
        res.forEach((element: any) => {
          if (this.site.buildings.filter((x: any) => x.id === element.id).length === 0) {
            this.site.buildings.push(element);
          }
        });
        this.createForm();
        const data: any = {
          sites: this.site,
          buildings: res,
          siteId: this.site.id,
          buildingId: res[0].id,
          isFromBuilding: true,
        };
        this.buildingObj = data;
        this.openPlan(data);
      },
      error: (err: any) => {
        this.btnService.enable()
      }
    })
  }

  showconfirmation(flag: any) {
    this.PreviousModalDTO.closeAllModal = flag
    if (this.addBuildingForm.dirty) {
      if (window.confirm(ApplicationNotification.pageReload)) {
        if (this.addBuildingForm.valid) {
          this.siteService.addBuildings(this.buildings.value, this.siteId).subscribe((res: any) => {
            const tempBuildings = res;
            if (tempBuildings && tempBuildings.length > 0) {
              tempBuildings.forEach((x: any) => {
                const building = {
                  awsImageExist: false,
                  equipments: 0,
                  equipmentsInDesignStage: 0,
                  equipmentsInIntallStage: 0,
                  floorCount: 0,
                  floors: [],
                  id: x.id,
                  managedCount: 0,
                  name: x.name,
                  nopEquipments: 0,
                  opEquipments: 0,
                  opWithIssueEquipments: 0,
                  percentOnlineDevices: 0,
                  totalFloor: 0,
                }
                x.floors = [];
                x.floorCount = 0;
                this.site.buildings.push(x);
                // this.eventService.broadcast(EventEmitterType.siteUpdate, { siteId: this.siteId, updatedBuildingDetails: building })
              })
            }
            this.addBuildingForm.markAsPristine();
            this.backToSite.emit();
          })
        } else {
          this.isFormSubmitted = true;
        }
      } else {
        this.addBuildingForm.markAsPristine();
        if (flag) {
          this.closeAll.emit(flag)
        } else {
          this.backToSite.emit({ flag: flag, buildingObj: this.buildingObj });
        }
      }
    } else {
      this.addBuildingForm.markAsPristine();
      if (flag) {
        this.closeAll.emit(flag)
      } else {
        this.backToSite.emit({ flag: flag, buildingObj: this.buildingObj });
      }
    }
  }
  goToSite() {
    this.backToSite.emit(this.site);
    this.bsModalRefBuilding.hide();
    this.bsModalRefPlan?.hide();
  }
  deleteBuilding(index: any) {
    this.buildings.value.forEach((item: any, i: any) => {
      if (i === index) {
        this.buildings.value.splice(i, 1)
      }
    })
    this.buildings.controls.forEach((item, i) => {
      if (i === index) {
        this.buildings.controls.splice(i, 1)
      }
    })
    this.orderNumber = this.orderNumber - 1;
    this.buildings.updateValueAndValidity();
    this.addBuildingForm.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.createForm();
    this.buildings = this.addBuildingForm.get('buildings') as FormArray;
  }

  ngOnDestroy() {
    this.btnService.enable();
    this.PreviousModalDTO = {}
    this.newBuildings = []
    this.newFloors = []
  }
}
