export * from "./secutiry-poilicy/interfaces";
export { USE_ENTITLEMENTS_V2_ENDPOINT_FF } from './constants';
export let SecondaryAuthStrategy;
(function (SecondaryAuthStrategy) {
  SecondaryAuthStrategy["WebAuthnPlatform"] = "WebAuthnPlatform";
  SecondaryAuthStrategy["WebAuthnCrossPlatform"] = "WebAuthnCrossPlatform";
  SecondaryAuthStrategy["SmsCode"] = "SmsCode";
  SecondaryAuthStrategy["SmsCodeV2"] = "SmsCodeV2";
  SecondaryAuthStrategy["Passkeys"] = "Passkeys";
})(SecondaryAuthStrategy || (SecondaryAuthStrategy = {}));
export let WebAuthnDeviceType;
(function (WebAuthnDeviceType) {
  WebAuthnDeviceType["Platform"] = "Platform";
  WebAuthnDeviceType["CrossPlatform"] = "CrossPlatform";
})(WebAuthnDeviceType || (WebAuthnDeviceType = {}));
export let MFAStrategyEnum;
(function (MFAStrategyEnum) {
  MFAStrategyEnum["AuthenticatorApp"] = "AuthenticatorApp";
  MFAStrategyEnum["WebAuthnPlatform"] = "WebAuthnPlatform";
  MFAStrategyEnum["WebAuthnCrossPlatform"] = "WebAuthnCrossPlatform";
  MFAStrategyEnum["SMS"] = "SMS";
  MFAStrategyEnum["EmailCode"] = "EmailCode";
})(MFAStrategyEnum || (MFAStrategyEnum = {}));
export const LOAD_AUTHORIZATION_FF = "admin_portal_should_load_authorization";