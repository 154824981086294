<div class="modal-body plan-select-body">
    <button data-dismiss="modal" (click)="closeAllModal();" aria-label="Close" class="close pull-right" type="button">
    <i class="fas fa-close"></i>
  </button>
    <div class="d-flex mx-auto col-sm-10 p-0 justify-content-center align-items-center plan-select-col">

        <div class="col">
            <div class="card image-card">

                <em class="icon fal fa-upload"></em>
                <h2>Existing Image</h2>
                <p>Already have the image for this Plan? Upload a PDF, PNG, or JPG below. This image can be changed later.
                </p>
                <div class="text-center">
                    <a href="javascript:void(0);" class="btn btn-primary mrg-b-15" (click)="browsePlanLocal()"> <em class="fas fa-upload"></em> Browse
                    </a>
                </div>


            </div>
        </div>
        <div class="col">
            <div class="card image-card">
                <em class="icon fal fa-map"></em>
                <h2>Blank Plan</h2>
                <p>Don't have the image for this Plan? Choose a shape for a blank Plan to start working on. This image can be changed later.
                </p>
                <div class="download-dropdown">
                    <div class="dropdown" dropdown>
                        <a dropdownToggle class="btn btn-primary dropdown-toggle m-0" href="javascript:void(0);">
              Select Shape
            </a>
                        <ul *dropdownMenu class="dropdown-menu" role="menu">
                            <li role="menuitem" class="text-center">
                                <a href="javascript:void(0)" (click)="browseBlankImage('square')" class="dropdown-item">
                  Square</a>
                            </li>
                            <li role="menuitem" class="text-center">
                                <a href="javascript:void(0)" (click)="browseBlankImage('wideRectangle')" class="dropdown-item">
                  Wide Rectangle</a>
                            </li>
                            <li role="menuitem" class="text-center">
                                <a href="javascript:void(0)" (click)="browseBlankImage('tallRectangle')" class="dropdown-item">
                  Tall Rectangle</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="d-flex justify-content-end">
        <button type="button" (click)="closeAllModal();" class="btn btn-secondary">Cancel</button>
    </div>
</div>