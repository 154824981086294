import { ssoSagas, ssoSagasMock } from './SSOState/saga';
import { ssoSagas as ssoSagasV2 } from './SSOState/saga.v2';
import { profileSagas, profileSagasMock } from './ProfileState/saga';
import { mfaSagas, mfaSagasMock } from './MfaState/saga';
import { forgotPasswordSagas } from './ForgotPasswordState/saga';
import { activateSagas } from './ActivateState/saga';
import { acceptInvitationSagas } from './AcceptInvitationState/saga';
import { loginSagas, loginSagasMock } from './LoginState/saga';
import { teamSagas, teamSagasMock } from './TeamState/saga';
import { socialLoginsSaga } from './SocialLogins/saga';
import { signUpSaga } from './SignUp/saga';
import { all, call } from 'redux-saga/effects';
import { apiTokensSaga, apiTokensSagaMock } from './ApiTokensState/saga';
import { securityPolicySagas, securityPolicySagasMock } from './Security/SecurityPolicyState/saga';
import { accountSettingsSaga, accountSettingsSagaMock } from './AccountSettingsState/saga';
import { tenantsSagas, tenantsSagasMock } from './TenantsState/saga';
import { rolesSagas, rolesSagasMock } from './RolesState/saga';
import { resetPhoneNumberSagas } from './ResetPhoneNumberState/saga';
import { sessionsSaga, sessionsSagaMock } from './SessionsState/saga';
import { sessionsPolicySaga, sessionsPolicySagaMock } from './Security/SessionsPolicyState/saga';
import { restrictionsSagaMock, restrictionsSagas } from './Security/RestrictionsState/saga';
import { provisionSagas } from './Provisioning/saga';
import { impersonateSagas } from './ImpersonationState/saga';
import { passkeysSagas } from './PasskeysState/saga';
import { groupsSagas, groupsSagasMock } from './GroupsState/saga';
import { customLoginSagas } from './CustomLoginState/saga';
import { allAccountsSagas } from './MSP/AllAccountsState/saga';
import { entitlementsSagas } from './Entitlements/saga';
import { securityCenterSagas, securityCenterSagasMock } from './Security/SecurityCenterState/saga';
import { smsSagas } from './SmsState/saga';
import { stepUpSagas } from './StepUpState/saga';
export function* sagas() {
  yield all([call(loginSagas), call(stepUpSagas), call(activateSagas), call(acceptInvitationSagas), call(forgotPasswordSagas), call(resetPhoneNumberSagas), call(ssoSagas), call(ssoSagasV2), call(profileSagas), call(customLoginSagas), call(mfaSagas), call(teamSagas), call(groupsSagas), call(socialLoginsSaga), call(signUpSaga), call(apiTokensSaga), call(securityPolicySagas), call(accountSettingsSaga), call(tenantsSagas), call(rolesSagas), call(sessionsSaga), call(sessionsPolicySaga), call(restrictionsSagas), call(provisionSagas), call(impersonateSagas), call(passkeysSagas), call(allAccountsSagas), call(entitlementsSagas), call(securityCenterSagas), call(smsSagas)]);
}
export function* mockSagas() {
  yield all([call(loginSagasMock),
  // call(activateSagas),
  // call(acceptInvitationSagas),
  // call(forgotPasswordSagas),
  // call(socialLoginsSaga),
  // call(signUpSaga),
  call(ssoSagasMock), call(profileSagasMock), call(mfaSagasMock), call(teamSagasMock), call(apiTokensSagaMock), call(securityPolicySagasMock), call(sessionsSagaMock), call(accountSettingsSagaMock), call(tenantsSagasMock), call(sessionsPolicySagaMock), call(restrictionsSagaMock), call(rolesSagasMock), call(groupsSagasMock), call(securityCenterSagasMock)]);
}