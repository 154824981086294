import { ContextHolder } from '../ContextHolder';
import { USE_CURRENT_USER_TENANTS_V1_ENDPOINT_FF } from './constants';
import { FeatureFlags } from '../feature-flags';
import { getCurrentUserTenantsV1, getCurrentUserTenantsV3 } from '../users';
import { FRONTEGG_SEPARATE_TABS_BY_TENANT } from './constants';
;
export async function executeConditionalPromise({
  shouldLoad,
  action
}) {
  if (!shouldLoad) return;
  return await action();
}
export function setTabTenantInSessionStorage(tenantId) {
  if (!tenantId) {
    return;
  }
  sessionStorage.setItem(FRONTEGG_SEPARATE_TABS_BY_TENANT, tenantId);
}
export function getTabTenantFromSessionStorage() {
  if (!ContextHolder.isSessionPerTenantEnabled()) {
    return null;
  }
  return sessionStorage.getItem(FRONTEGG_SEPARATE_TABS_BY_TENANT);
}
export function getCurrentUserTenantsFunction() {
  const [useCurrentUserTenantsV1] = FeatureFlags.getFeatureFlags([USE_CURRENT_USER_TENANTS_V1_ENDPOINT_FF], ContextHolder.getAppName() || '');
  if (ContextHolder.isSessionPerTenantEnabled()) {
    return getCurrentUserTenantsV1;
  }
  return useCurrentUserTenantsV1 ? getCurrentUserTenantsV1 : getCurrentUserTenantsV3;
}