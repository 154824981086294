import { Component, Input } from '@angular/core';

@Component({
  selector: 'so-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

  @Input() header!: string;
  @Input() subHeader!: string;
  @Input() partialHeader!: string;
  @Input() subTitleHeader!: string;
  @Input() text!: string;
}
