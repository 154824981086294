import { Floor } from "./floor";

export class Building {
    buildingId?: number;
    buildingName?: string;
    InstalledDevice?: number;
    name?: string;
    equipments?: number;
    equipmentsInDesignStage?= 0;
    equipmentsInIntallStage?= 0;
    opEquipments?: number;
    projectName?: string;
    projectId?: number;
    opWithIssueEquipments?: number;
    nopEquipments?: number;
    totalBuildingFloorCnt?: any;
    pendingTickets?: number;
    address?: string;
    installedDeviceCount?: number;
    floors: Floor[];
    floorDTOS?: any;
    selectedBuilding?: any;
    percentageInstalledDevices?= 0;
    buildingFloorcnt?: any;
    totalFloor?: number;
    totalDevices?= 0;
    plannedHours?: any;
    inProgressDevice?: number;
    isSelectedAllFloor?: any;
    planneddDevice?: number;
    leftHours?= 0;
    InstalledDevices?= 0
    id?: number;
    percentOnlineDevices?: number;
    OnlineDevices?: number;
    awsImageURL?: string;
    siteName?: string;
    siteId?: number
    projectFloorIdList?: any[]
    stage?: string;
    permissionLevels?: any[];
    canEdit?: boolean;
    canManage?: boolean;
    canUpdateDevice?: boolean;
    domotzUserId?: any;
    notWorkingCount?: number;
    operationalCount?: number;
    operationalWithIssueCount?: number;
    percentageCount?: number;
    designCount?: number;
    installCount?: number;
    floorCount?: number;
    managedCount?: number;
    awsImageExist?: boolean;
    orderNumber?: any;
    isSiteArchived?: boolean;
    isArchived?: boolean;
    projectFloorsDTO?: any;
    progressBarData?: any[];
    isTechnicianView?: any;
    isImageExsits?: boolean = false
    isImageLoading?: boolean = false
    imageCheckCnt?: any = 0
    constructor() {
        this.name = "";
        this.floors = [];
        this.domotzUserId = null;
        this.notWorkingCount = 0;
        this.operationalCount = 0;
        this.operationalWithIssueCount = 0;
        this.percentageCount = 0;
        this.designCount = 0;
        this.installCount = 0;
        this.managedCount = 0;
        this.floorCount = 0;
        this.isSiteArchived = false;
        this.progressBarData = [];
        this.floors = []
    }
}

