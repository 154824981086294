import { takeLatest, put, call, select, takeEvery } from 'redux-saga/effects';
import { api } from '@frontegg/rest-api';
import { actions } from '../reducer';
import { sessionsMock } from '../dummy';
function* loadCurrentUserSessions() {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  try {
    const sessions = yield call(api.auth.getCurrentUserSessions);
    yield put(actions.setSessionsState({
      sessions,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSessionsState({
      error: e,
      loading: false
    }));
  }
}
function* loadCurrentUserSession() {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  try {
    const session = yield call(api.auth.getCurrentUserSession);
    yield put(actions.setSessionsState({
      currentSession: session,
      loading: false
    }));
  } catch (e) {
    yield put(actions.setSessionsState({
      error: e,
      loading: false
    }));
  }
}
function* deleteUserSession({
  payload: {
    id
  }
}) {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  try {
    yield call(api.auth.deleteSessionForUser, id);
    yield put(actions.loadUserSessions());
  } catch (e) {
    yield put(actions.setSessionsState({
      error: e,
      loading: false
    }));
  }
}
function* revokeUserSessions({
  payload: {
    callback,
    userId
  }
}) {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  try {
    yield call(api.auth.revokeSessionsForUser, userId);
    callback == null ? void 0 : callback(true);
  } catch (e) {
    yield put(actions.setSessionsState({
      error: e,
      loading: false
    }));
    callback == null ? void 0 : callback(false);
  }
}
function* deleteAllUserSessions() {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  try {
    yield call(api.auth.deleteAllSessionsForUser);
    yield put(actions.loadUserSessions());
  } catch (e) {
    yield put(actions.setSessionsState({
      error: e,
      loading: false
    }));
  }
}
export function* sessionsSaga() {
  yield takeLatest(actions.loadUserSessions, loadCurrentUserSessions);
  yield takeLatest(actions.deleteUserSession, deleteUserSession);
  yield takeLatest(actions.deleteAllUserSessions, deleteAllUserSessions);
  yield takeLatest(actions.loadCurrentUserSession, loadCurrentUserSession);
  yield takeEvery(actions.revokeUserSessions, revokeUserSessions);
}

/*********************************
 *  Preview Sagas
 *********************************/

function* loadCurrentUserSessionsMock() {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  yield put(actions.setSessionsState({
    sessions: sessionsMock,
    loading: false
  }));
}
function* deleteUserSessionMock({
  payload: {
    id
  }
}) {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  const currentSessions = yield select(state => {
    var _state$auth$sessionsS;
    return (_state$auth$sessionsS = state.auth.sessionsState.sessions) != null ? _state$auth$sessionsS : [];
  });
  const newSessions = currentSessions.filter(s => s.id !== id);
  yield put(actions.setSessionsState({
    sessions: newSessions,
    loading: false
  }));
}
function* deleteAllUserSessionsMock() {
  yield put(actions.setSessionsState({
    loading: true,
    error: null
  }));
  const newSessions = sessionsMock.filter(s => s.current);
  yield put(actions.setSessionsState({
    sessions: newSessions,
    loading: false
  }));
}
export function* sessionsSagaMock() {
  yield takeLatest(actions.loadUserSessions, loadCurrentUserSessionsMock);
  yield takeLatest(actions.deleteUserSession, deleteUserSessionMock);
  yield takeLatest(actions.deleteAllUserSessions, deleteAllUserSessionsMock);
}