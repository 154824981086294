import _extends from "@babel/runtime/helpers/esm/extends";
import { createSlice, createAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
export const defaultItemsPerPage = 20;
const {
  name: storeName,
  actions: lifeCycleActions,
  reducer
} = createSlice({
  name: 'audits',
  initialState,
  reducers: {
    setContext: (state, {
      payload
    }) => {
      state.context = payload;
    },
    startLoading: state => {
      state.isLoading = true;
    },
    setVirtualScroll: (state, {
      payload
    }) => {
      state.virtualScroll = payload;
    },
    startRefresh: state => {
      state.isLoading = true;
    },
    startFetching: state => {
      state.isFetchMore = true;
    },
    finishLoading: state => {
      state.isLoading = false;
    },
    startDownloadingCsv: state => {
      state.isDownloadingCsv = true;
    },
    stopDownloadingCsv: state => {
      state.isDownloadingCsv = false;
    },
    loadStatsSuccess: (state, {
      payload
    }) => {
      state.error = undefined;
      state.totalToday = payload.totalToday;
      state.severeThisWeek = payload.severeThisWeek;
    },
    loadItemFailedAction: (state, {
      payload
    }) => {
      state.error = _extends({}, state.error, {
        [payload.name]: payload.error
      });
    },
    loadMetadataSuccess: (state, {
      payload
    }) => {
      state.error = {};
      state.headerProps = payload.properties;
      state.themeAudits = payload.theme ? payload.theme.styles : {};
    },
    loadAuditsSuccess: (state, {
      payload
    }) => {
      state.error = {};
      state.rowsData = payload.rowsData;
      state.lastUpdated = new Date();
      state.total = payload.total;
    },
    fetchMoreSuccess: (state, {
      payload
    }) => {
      state.error = {};
      state.offset = payload.offset;
      state.currentPage = payload.currentPage;
      state.isFetchMore = false;
    },
    setFilterData: (state, {
      payload
    }) => {
      state.filters = payload;
      state.currentPage = 0;
      state.offset = 0;
      state.isLoading = true;
    },
    textSearch: (state, {
      payload
    }) => {
      state.filter = payload;
      state.currentPage = 0;
      state.offset = 0;
      state.isLoading = true;
    },
    onPageChange: (state, {
      payload
    }) => {
      state.currentPage = payload - 1;
      state.offset = state.currentPage * defaultItemsPerPage;
      state.isLoading = true;
    },
    setDataSorting: (state, {
      payload
    }) => {
      state.sortBy = payload.sortBy;
      state.currentPage = 0;
      state.sortDirection = payload.sortDirection === 'asc' ? 'desc' : 'asc';
      state.offset = 0;
      state.isLoading = true;
    },
    setPredefinedFilters: (state, {
      payload
    }) => {
      state.predefinedFilters = payload;
      state.filters = Object.keys(payload).map(key => ({
        key,
        value: payload[key]
      }));
    }
  }
});
export { reducer, storeName };
export const actions = _extends({}, lifeCycleActions, {
  initData: createAction(`${storeName}/initData`),
  loadAudits: createAction(`${storeName}/loadAudits`),
  removeFilter: createAction(`${storeName}/removeFilter`),
  filterData: createAction(`${storeName}/filterData`),
  exportCSV: createAction(`${storeName}/exportCSV`),
  deleteAudits: createAction(`${storeName}/deleteAudits`)
});