<form [formGroup]="customerForm" (ngSubmit)="saveCustomer()" novalidate>
    <div class="modal-header">
        <h1 class="modal-title pull-left">Account Information</h1>
        <button data-dismiss="modal" (click)="closeModal()" aria-label="Close" class="close pull-right" type="button">
            <i class="fas fa-close"></i>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-5">
                <div class="form-group">
                    <label>Account Name
                        <sup>*</sup>
                    </label>
                    <input maxlength="80" type="text" removeWhiteSpace class="form-control" formControlName="name" placeholder="Account">
                    <so-control-messages [name]="'Account Name'" [isFormSubmitted]="isFormSubmitted" [control]="$any(customerForm).controls.name"></so-control-messages>
                </div>
                <div class="form-group">
                    <label>Email Address</label>
                    <input maxlength="80" type="text" class="form-control" formControlName="email" placeholder="Email Address">
                    <so-control-messages [name]="'Email Address'" [isFormSubmitted]="isFormSubmitted" [control]="$any(customerForm).controls.email">
                    </so-control-messages>
                </div>
                <div class="form-group">
                    <label>Phone Number</label>
                    <input maxlength="20" type="text" class="form-control" formControlName="phoneNumber" placeholder="Phone Number">
                    <so-control-messages [name]="'Phone Number'" [isFormSubmitted]="isFormSubmitted" [control]="$any(customerForm).controls.phoneNumber"></so-control-messages>
                </div>
            </div>
        </div>

    </div>


    <div class="modal-footer ps-0">
        <div class="row p-0" style="width: 100%">
            <div class="col-sm-6 p-0">
                <div class="progress-container">
                    <ul class="progressbar">
                        <li class="active">Account Information</li>
                        <li>Assign Admins</li>
                        <li *ngIf="customers.length > 0">Import Favorites</li>
                    </ul>
                </div>
            </div>
            <div class="col-sm-6 d-flex align-items-center justify-content-end">
                <button type="button" class="btn btn-secondary" (click)="closeModal()" data-dismiss="modal">Cancel</button>
                <button type="submit" class="btn btn-primary m-0" [disabled]="saveBtnDisable">Save and Continue</button>
            </div>
        </div>
    </div>

</form>