<div class="project-filter">
    <h4 *ngIf="!showFromImageDeviceExport">Add Plans</h4>
    <div *ngIf="buildings?.length > 0" class="mb-2">
        {{isUnlimitedPlan ? 'Select the Plans that you would like to include in the report. Large reports may take
        several minutes to generate.' : 'Select up to 20 Plans that you would like
        to include in the report.'}}</div>
    <div *ngIf="!showFromImageDeviceExport && (buildings?.length === 0 || totalPlans === 0)"> This {{isFromSite ? 'site' : 'project'}}
        doesn’t have any Floor Plans.
        <ng-container *ngIf="!showFromImageDeviceExport && !isFromSite">Add Floor Plans on the Status tab to print them.</ng-container>
        <ng-container *ngIf="!showFromImageDeviceExport && isFromSite"> Add Floor Plans from the Plan Library screen to print them.
        </ng-container>
    </div>
    <div *ngIf="buildings?.length > 0" class="download-option-list">
        <so-checkbox [isChecked]="selectedAllPlans > 0" (onChange)="selectAllPlan($event)" [label]="'Select All'"
            [id]="'building_all_'" [ngClass]="'lbl-check'" [showSubTitle]="true" [parentClassName]="(selectedAllPlans > 0 && totalPlans!==selectedAllPlans) ? 'uncheckall':''"
            [extraParams]="{'totalBuildingFloorCnt' : totalPlans, 'buildingFloorCnt':selectedAllPlans}"></so-checkbox>
    </div>
    <!-- Accordian Start -->
    <div *ngIf="buildings && totalPlans > 0" class="project-filter-body" style="height:442px" >
        <div *ngFor="let project of buildings">
            <accordion *ngIf="project.totalBuildingFloorCnt > 0">
                <accordion-group class="accordin">
                    <div accordion-heading class="clearfix slide-head-panel">
                        <div title="{{selectedAllPlans > 19 && !project.isSelectedAllBuilding && !isUnlimitedPlan && project.buildingFloorcnt === 0 ? 'You cannot select more than 20 Plans at a time' : '' }}">
                            <so-checkbox [isChecked]="(project.buildingFloorcnt > 0) ?true : false"
                                (onChange)="selectAllBuildingPlan(project,$event)" [label]="project.name"
                                [showSubTitle]="true" [parentClassName]="(project.buildingFloorcnt > 0 && !project.isSelectedAllBuilding) ? 'uncheckall':''"
                                [extraParams]="{'totalBuildingFloorCnt' : project.totalBuildingFloorCnt, 'buildingFloorCnt':project.buildingFloorcnt}"
                                [disabled]="selectedAllPlans > 19 && !isUnlimitedPlan && !project.isSelectedAllBuilding && project.buildingFloorcnt === 0"
                                [id]="'building_' + project.id" [ngClass]="'lbl-check'"></so-checkbox>
                        </div>
                    </div>
                    <div *ngFor="let floor of project?.floors" class="expand-body clearfix">
                        <div class="expand-inner clearfix">
                            <div class="project-exp">
                                <so-checkbox [isChecked]="floor.isFloorSelected"
                                    (onChange)="selectPlan(project,floor,$event)" [label]="floor?.floorName"
                                    [disabled]="selectedAllPlans > 19 && !isUnlimitedPlan && !floor.isFloorSelected"
                                    [id]="'buildingFloor_' + floor.floorId"
                                    [ngClass]="{'disabled-label': selectedAllPlans > 19 && !isUnlimitedPlan && !floor.isFloorSelected}"></so-checkbox>
                            </div>
                        </div>
                    </div>
                </accordion-group>
            </accordion>
        </div>
    </div>
    <!-- Accordian End -->
</div>