"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMatchesOperation = exports.useInListOperation = exports.useContainsOperation = exports.useEndsWithOperation = exports.useStartsWithOperation = void 0;
function useStartsWithOperation(payload) {
  return attribute => ({
    isValid: payload.list.some(value => attribute.startsWith(value))
  });
}
exports.useStartsWithOperation = useStartsWithOperation;
function useEndsWithOperation(payload) {
  return attribute => ({
    isValid: payload.list.some(value => attribute.endsWith(value))
  });
}
exports.useEndsWithOperation = useEndsWithOperation;
function useContainsOperation(payload) {
  return attribute => ({
    isValid: payload.list.some(value => attribute.includes(value))
  });
}
exports.useContainsOperation = useContainsOperation;
function useInListOperation(payload) {
  return attribute => ({
    isValid: payload.list.includes(attribute)
  });
}
exports.useInListOperation = useInListOperation;
function useMatchesOperation(payload) {
  return attribute => {
    let expression;
    try {
      expression = new RegExp(payload.string);
    } catch (e) {
      return {
        isValid: false
      };
    }
    return {
      isValid: expression.test(attribute)
    };
  };
}
exports.useMatchesOperation = useMatchesOperation;
